import { Card, CardBody, Spinner } from '@chakra-ui/react'
import Empty from '../../../components/Empty'
import PageHeader from '../../../components/PageHeader'
import useSWR from 'swr'
import { useSnapshot } from 'valtio'
import tableStore from '../../../lib/store/affiliate_transaction_table'
import { IDataResponse, serialize } from '../../../network/request'
import { useNavigate, useParams } from 'react-router-dom'
import AffiliateCommissionTable from './AffiliateCommissionTable'
import AffiliateCommissionService from '../../../network/services/affiliateCommission'
import { Commission } from '../../../network/services/adminCommission'

const AffiliateCommissionsPage = () => {
  const navigate = useNavigate()

  return (
    <PageHeader title='紹介料履歴'>
      <Card>
        <CardBody>
          <AffiliateCommissionTableWrapper />
        </CardBody>
      </Card>
    </PageHeader>
  )
}

const AffiliateCommissionTableWrapper = () => {
  const { state } = useSnapshot(tableStore)

  // use this to preload
  const { data: response, error } = useSWR<IDataResponse<Commission>>(
    serialize(AffiliateCommissionService.getAll, {
      page: state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters,
    })
  )

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response) {
    return <Spinner />
  }

  const { total } = AffiliateCommissionService.toPaginate(response)

  return (
    <>
      <AffiliateCommissionTable />
      {/* preload next page */}
      {state.currentPage * state.pageSize < total && (
        <div style={{ display: 'none' }}>
          <AffiliateCommissionTable overridePage={state.currentPage + 1} />
        </div>
      )}
    </>
  )
}

export default AffiliateCommissionsPage
