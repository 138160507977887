import { Dispatch, FC, SetStateAction, useRef, useState, useMemo } from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  Heading,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  useToast,
  HStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Link,
} from '@chakra-ui/react'
import { ColumnDef, SortingState } from '@tanstack/react-table'
import CustomTable from '../../../components/CustomTable'
import CopyableText from '../../../components/CopyableText'
import { useForm } from 'react-hook-form'

import Gaps from '../../../components/Gaps'
import _ from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import { IDataResponse, serialize } from '../../../network/request'
import OrderService, { Order } from '../../../network/services/order'
import useSWR, { KeyedMutator } from 'swr'
import ReadOnlyFormItem from '../../../components/ReadOnlyFormItem'
import {
  AffiliateCampaign,
  AffiliateUrl,
  affiliateCampaignStatusText,
} from '../../../network/services/affiliateCampaign'
import { campaignStatusText } from '../../../network/services/adminCampaign'
import AffiliateCommissionService from '../../../network/services/affiliateCommission'
import {
  Commission,
  commissionStatusText,
} from '../../../network/services/adminCommission'
import toJapaneseDate from '../../../functions/toJapaneseDate'
import AffiliateDiscountCodeService, {
  DiscountCodeCreateState,
} from '../../../network/services/affiliate_discount_code'

const AffiliateCampaignDetail: FC<{
  initialValue?: AffiliateCampaign
  refresh: () => any
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
}> = ({ initialValue, refresh }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <CreateCouponModal isOpen={isOpen} onClose={onClose} refresh={refresh} />
      <Card>
        <CardBody>
          {initialValue?.status != 'pending' && (
            <>
              <HStack spacing='4'>
                {/* <ReadOnlyFormItem
                  title='紹介料総額'
                  content={`￥${initialValue?.total_commission}`}
                  enableCopy={false}
                /> */}

                <ReadOnlyFormItem
                  title='紹介した注文数'
                  content={initialValue?.total_orders}
                  enableCopy={false}
                />

                <ReadOnlyFormItem
                  title='売上総額'
                  content={`￥${initialValue?.total_sales}`}
                  enableCopy={false}
                />
              </HStack>
              <Gaps />
            </>
          )}

          <ReadOnlyFormItem
            variant='unstyled'
            title='キャンペーン名'
            content={initialValue?.campaign.name}
            enableCopy={false}
          />

          <ReadOnlyFormItem
            variant='unstyled'
            title='個人キャンペーンID'
            content={initialValue?.id}
            enableCopy={false}
          />

          <ReadOnlyFormItem
            variant='unstyled'
            title='説明'
            content={initialValue?.campaign.description}
            enableCopy={false}
          />

          {initialValue?.campaign.commission_amount && (
            <ReadOnlyFormItem
              variant='unstyled'
              title='紹介料'
              content={`￥${initialValue?.campaign.commission_amount}`}
              enableCopy={false}
            />
          )}

          {initialValue?.campaign.commission_rate && (
            <ReadOnlyFormItem
              variant='unstyled'
              title='紹介料レート'
              content={`${initialValue?.campaign.commission_rate * 100}%`}
              enableCopy={false}
            />
          )}

          <ReadOnlyFormItem
            variant='unstyled'
            title='参加状態'
            content={
              initialValue?.status ? (
                <Text
                  style={{
                    color:
                      affiliateCampaignStatusText[initialValue.status].color,
                  }}
                >
                  {affiliateCampaignStatusText[initialValue.status].text}
                </Text>
              ) : null
            }
            enableCopy={false}
          />

          {
            <ReadOnlyFormItem
              variant='unstyled'
              title='開催状態'
              content={
                initialValue ? (
                  <Text
                    style={{
                      color:
                        campaignStatusText[initialValue.campaign.status].color,
                    }}
                  >
                    {campaignStatusText[initialValue.campaign.status].text}
                  </Text>
                ) : null
              }
              enableCopy={false}
            />
          }

          <ReadOnlyFormItem
            variant='unstyled'
            title='売上総額'
            content={`￥${initialValue?.total_sales}`}
            enableCopy={false}
          />

          {initialValue?.status != 'pending' &&
            initialValue?.campaign.enable_discount &&
            initialValue.campaign.shopify_price_rule_id && (
              <Card>
                <CardHeader>
                  <Heading size='sm'>
                    {initialValue.discount_code
                      ? 'クーポン情報'
                      : 'クーポンコード生成可能'}
                  </Heading>
                </CardHeader>
                <CardBody>
                  {initialValue.discount_code ? (
                    <ReadOnlyFormItem
                      variant='unstyled'
                      title='クーポンコード'
                      content={initialValue.discount_code.code}
                    />
                  ) : (
                    <div>
                      <Button onClick={() => onOpen()}>
                        クーポンコードを生成する
                      </Button>
                      <Gaps />
                    </div>
                  )}
                  <ReadOnlyFormItem
                    variant='unstyled'
                    title='値引き'
                    enableCopy={false}
                    content={
                      initialValue.campaign.discount_value_type ==
                      'fixed_amount'
                        ? `￥${initialValue.campaign.discount_fixed_amount}`
                        : `${
                            (initialValue.campaign.discount_percentage ?? 0) *
                            100
                          }%`
                    }
                  />
                </CardBody>
              </Card>
            )}
        </CardBody>
      </Card>
      <Gaps />

      {initialValue?.status != 'pending' && (
        <Card>
          <CardBody>
            <Tabs isLazy>
              <TabList>
                <Tab>商品一覧</Tab>
                <Tab>紹介料詳細</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <CampaignProductUrlTable
                    data={initialValue?.affiliate_urls ?? []}
                  />
                </TabPanel>
                <TabPanel>
                  <CampaignCommissionTable />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </CardBody>
        </Card>
      )}
    </>
  )
}

const CampaignProductUrlTable = ({ data }: { data: AffiliateUrl[] }) => {
  const columns = useMemo<ColumnDef<AffiliateUrl, any>[]>(
    () => [
      {
        id: 'product_name',
        accessorKey: 'product_name',
        header: '日付',
        cell: (info) => (
          <Link
            href={info.row.original.campaign_product.product.page_url}
            target='blank'
          >
            {info.row.original.campaign_product.product.name}
          </Link>
        ),
        enableSorting: false,
      },
      {
        id: 'product_url',
        accessorKey: 'product_url',
        header: '商品URL',
        cell: (info) => (
          <CopyableText>
            {info.row.original.campaign_product.product.page_url}
          </CopyableText>
        ),
        enableSorting: false,
      },
      {
        id: 'affiliate_url',
        accessorKey: 'url',
        header: 'アフィリエイトURL',
        cell: (info) => <CopyableText>{info.getValue()}</CopyableText>,
      },
    ],
    []
  )

  return (
    <CustomTable
      {...{
        data: data ?? [],
        columns,
      }}
    />
  )
}

const CampaignCommissionTable = () => {
  const { id } = useParams()
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(50)
  const [sorting, setSorting] = useState<SortingState>([])

  const { data: result } = useSWR(
    serialize(AffiliateCommissionService.getAll, {
      limit: size,
      page,
      campaign: id,
      sort:
        sorting.length > 0
          ? `${sorting[0].id}:${sorting[0].desc ? 'desc' : 'asc'}`
          : 'created_at:desc',
    })
  )
  const commissions = AffiliateCommissionService.toRow(result)
  const lastPage = result?.meta?.last_page ? result?.meta?.last_page : 1
  const { total } = AffiliateCommissionService.toPaginate(result)

  const columns = useMemo<ColumnDef<Commission, any>[]>(
    () => [
      {
        id: 'product_full_name',
        accessorKey: 'product_full_name',
        header: '商品',
        cell: (info) =>
          `${info.getValue()}　【${info.row.original.quantity}個】`,
        enableSorting: false,
      },
      {
        id: 'status',
        accessorKey: 'status',
        header: 'ステータス',
        cell: (info) => (
          <Text
            style={{
              color:
                commissionStatusText[
                  info.getValue() as keyof typeof commissionStatusText
                ].color,
            }}
          >
            {
              commissionStatusText[
                info.getValue() as keyof typeof commissionStatusText
              ].text
            }
          </Text>
        ),
        enableSorting: false,
      },
      {
        id: 'should_issue_at',
        accessorKey: 'should_issue_at',
        header: '発行予定日',
        cell: (info) =>
          info.getValue() ? toJapaneseDate(new Date(info.getValue())) : '-',
        enableSorting: false,
      },
      {
        id: 'issued_at',
        accessorKey: 'issued_at',
        header: '発行日',
        cell: (info) =>
          info.getValue() ? toJapaneseDate(new Date(info.getValue())) : '-',
        enableSorting: false,
      },
      {
        id: 'amount',
        accessorKey: 'amount',
        header: '金額',
        cell: (info) => (
          <Text style={{ color: info.getValue() > 0 ? 'green' : 'red' }}>
            {info.getValue() > 0 ? '+' : '-'}￥{Math.abs(info.getValue())}
          </Text>
        ),
      },
    ],
    []
  )

  return (
    <CustomTable
      {...{
        data: commissions ?? [],
        columns,
        lastPage,
        page,
        setPage,
        size,
        setSize,
        sorting,
        setSorting,
      }}
    />
  )
}

const CreateCouponModal = ({
  isOpen,
  onClose,
  refresh,
}: {
  isOpen: boolean
  onClose: () => void
  refresh: () => void
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [termsAccepted, setTermsAccepted] = useState(false)
  const { id } = useParams()

  const toast = useToast()
  const formRef = useRef() as any
  let {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    shouldUseNativeValidation: false,
  })

  const submit = async (values: DiscountCodeCreateState) => {
    setIsLoading(true)
    if (!id) return
    try {
      const { data: result } = await AffiliateDiscountCodeService.createCode(
        id,
        values
      )
      if (result.success) {
        toast({
          description: 'クーポンコードが生成されました',
          status: 'success',
          variant: 'subtle',
          position: 'top',
        })
        refresh()
        onClose()
      }
    } catch (error: any) {
      toast({
        description: 'エラーが発生しました ' + error.message,
        status: 'error',
        variant: 'subtle',
        position: 'top',
      })
    }

    setIsLoading(false)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>クーポンコード生成</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Card>
            <CardBody as='form' ref={formRef} onSubmit={handleSubmit(submit)}>
              <FormControl isInvalid={Boolean(errors.code_prefix)} isRequired>
                <FormLabel>
                  クーポンコードプレフィックス（例：YAMAMOTO001の場合はYAMAMOTO）
                </FormLabel>
                <Input
                  {...register('code_prefix', {
                    required: '必要なフィールドです',
                  })}
                />
                <FormErrorMessage>
                  {errors.code_prefix ? String(errors.code_prefix.message) : ''}
                </FormErrorMessage>
              </FormControl>
            </CardBody>
          </Card>
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={isLoading}
            onClick={onClose}
            mr={3}
            variant='outline'
            loadingText='やめる'
          >
            やめる
          </Button>
          <Button
            isLoading={isLoading}
            loadingText='クーポンコードを生成する'
            onClick={() => {
              if (formRef.current)
                formRef.current.dispatchEvent(
                  new Event('submit', { cancelable: true, bubbles: true })
                )
            }}
          >
            クーポンコードを生成する
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default AffiliateCampaignDetail
