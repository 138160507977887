import { IDataResponse } from '../request'
import { Commission } from './adminCommission'
import { Withdraw } from './adminWithdraw'

export interface CommissionSummary {
  total_earned: number
  balance: number
}

const getAll = `/affiliate/commissions`
const getSummary = `/affiliate/commission-summary`

const get = (id: string | number) => {
  return `/affiliate/commissions/${id}`
}

const toRow = (data: IDataResponse<Commission>) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id,
      }
    })
  }

  return []
}

const toPaginate = (data: IDataResponse<Commission>) => {
  return {
    total: data?.meta?.total ?? 0,
  }
}

const AffiliateCommissionService = {
  getAll,
  getSummary,
  get,
  toRow,
  toPaginate,
}

export default AffiliateCommissionService
