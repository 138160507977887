import { defineStyleConfig } from "@chakra-ui/react"

const Menu = defineStyleConfig({
  baseStyle: {
    borderRadius: '8px',
  },
  variants: {
    select: {
      list: {
        minW: 0,
      }, 
      item: {
        flexDir: 'row-reverse',
        gap: '10px',
        '& span.chakra-menu__icon-wrapper': {
          margin: 0,
          color: 'brand.purple',
        },
        _checked: {
          bg: '#F9FAFB'
        }
      }
    },
  },
  defaultProps: {
    size: 'sm',
  }
})

export default Menu
