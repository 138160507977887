import { FC } from 'react'
import { getToken } from '../lib/store/auth'
import { Navigate, useLocation, useSearchParams } from 'react-router-dom'

const RequireAuth: FC<{ children: JSX.Element }> = ({ children }) => {
  const location = useLocation()
  const [searchParams] = useSearchParams()

  const isLoggedIn = getToken()
  return isLoggedIn ? (
    children
  ) : (
    <Navigate
      to={{
        pathname: '/',
        search: `?redirect_uri=${location.pathname}?${encodeURIComponent(
          searchParams.toString()
        )}`,
      }}
    />
  )
}

export default RequireAuth
