import client, { IDataResponse, serialize } from '../request'
import { Affiliate } from './adminAffiliate'

export type Admin = {
  id: string
  user?: User
}

export type User = {
  id: string
  email: string
  admin?: Admin
  affiliate?: Affiliate
}

export type LoginState = {
  email: string
  password: string
}

export interface AffiliateSignupState {
  token: string
  password: string
  first_name: string
  last_name: string
  phone: string
  twitter_account_url?: string
  youtube_account_url?: string
  facebook_account_url?: string
  instagram_account_url?: string
  country: string
  province: string
  city: string
  zip: string
  address1: string
  address2?: string
  company?: string
}

/// swr
const findMyself = '/auth/me'

const verifySignupToken = (token: string) => {
  return serialize('/invitation-validity', {
    token,
  })
}

const login = (data: LoginState) => {
  return client.post('/auth/login', data)
}

const affiliateSignUp = (data: AffiliateSignupState) => {
  return client.post('/verify-invitation', data)
}
/// axios

// processing
const toRow = (data: IDataResponse<User>): User[] => {
  if (data?.data && data?.data?.length > 0) {
    return data?.data?.map((element) => {
      return {
        ...element,
        key: element.id,
      }
    })
  }

  return []
}

// processing
const toPaginate = (data: IDataResponse<User>) => {
  return {
    total: data?.meta?.total ?? 0,
  }
}

const AuthService = {
  login,
  findMyself,
  verifySignupToken,
  affiliateSignUp,
  toRow,
  toPaginate,
}

export default AuthService
