import { User } from './auth'
import client, { IDataResponse } from '../request'
import { Product } from './product'
import { Affiliate } from './adminAffiliate'
import { DiscountCode } from './affiliate_discount_code'

export const campaignStatusText = {
  ongoing: {
    color: 'green',
    text: '開催中',
  },
  inactive: {
    color: 'red',
    text: '終了',
  },
}

export type AdminCampaign = {
  id: string
  name: string
  status: 'ongoing' | 'inactive'
  complete_after_days: number
  budget?: string
  commission_rate?: number
  commission_amount?: number
  currency: string
  ends_at?: Date
  created_at: Date
  updated_at: Date
  internal_description?: string
  description?: string
  total_orders: number
  total_affiliates: number
  total_sales: string
  products?: CampaignProduct[]
  shopify_price_rule_id?: string
  enable_discount?: boolean
  discount_value_type?: 'percentage' | 'fixed_amount'
  discount_fixed_amount?: number
  discount_percentage?: number
  enable_referral?: boolean
}

export type CampaignProduct = {
  id: number
  product: Product
}

export type CampaignAffiliateInviteState = {
  affiliate_id: number | string
  remark?: string
}

export type CampaignAddProductState = {
  product_id: string | number
}

export type CampaignUpdateDiscountState = {
  discount_value_type?: 'percentage' | 'fixed_amount'
  discount_fixed_amount?: number
  discount_percentage?: number
}

export type CampaignAffiliate = {
  id: string
  campaign_id: number
  affiliate_id: number
  status: 'pending' | 'activated' | 'inactivated'
  created_at: Date
  updated_at: Date
  affiliate: Affiliate
  total_orders: number
  total_sales: number
  total_commission: number
  discount_code?: DiscountCode
}

export type AdminCampaignCreateState = {
  name: string
  status: 'ongoing' | 'inactive'
  complete_after_days: number
  budget?: string
  commission_rate?: number
  commission_amount?: number
  currency: string
  ends_at?: Date
  internal_description?: string
  description?: string
  enable_discount?: boolean
  discount_value_type?: 'percentage' | 'fixed_amount'
  discount_fixed_amount?: number
  discount_percentage?: number
}

//swr
const getAll = `/admin/campaigns`

const get = (id: string | number) => {
  return `/admin/campaigns/${id}`
}

const getAffiliates = (id: string | number) => {
  return `/admin/campaigns/${id}/affiliates`
}

const create = (data: AdminCampaignCreateState) => {
  return client.post(`/admin/campaigns`, data)
}

const update = (id: string | number, data: AdminCampaignCreateState) => {
  return client.put(`/admin/campaigns/${id}`, data)
}

const updateDiscount = (
  id: string | number,
  data: CampaignUpdateDiscountState
) => {
  return client.put(`/admin/campaigns/${id}/discount`, data)
}

const removeDiscount = (id: string | number) => {
  return client.delete(`/admin/campaigns/${id}/discount`)
}

const inviteAffiliate = (
  id: string | number,
  data: CampaignAffiliateInviteState
) => {
  return client.post(`/admin/campaigns/${id}/affiliates`, data)
}

const addProduct = (id: string | number, data: CampaignAddProductState) => {
  return client.post(`/admin/campaigns/${id}/products`, data)
}

// const remove = (appClientId: string, id: string | number) => {
//   return client.delete(`/admin/affiliates/${id}`)
// }

const toRow = (data: IDataResponse<AdminCampaign>) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id,
      }
    })
  }

  return []
}

const toPaginate = (data: IDataResponse<AdminCampaign>) => {
  return {
    total: data?.meta?.total ?? 0,
  }
}

const affiliateToRow = (data: IDataResponse<CampaignAffiliate>) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id,
      }
    })
  }

  return []
}

const affiliateToPaginate = (data: IDataResponse<CampaignAffiliate>) => {
  return {
    total: data?.meta?.total ?? 0,
  }
}

const AdminCampaignService = {
  update,
  getAll,
  get,
  getAffiliates,
  inviteAffiliate,
  addProduct,
  updateDiscount,
  removeDiscount,
  create,
  toRow,
  toPaginate,
  affiliateToRow,
  affiliateToPaginate,
}

export default AdminCampaignService
