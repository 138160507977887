import { 
  HStack,
  Text,
  IconButton,
  VStack,
  Spacer,
  Icon,
} from '@chakra-ui/react'
import { ArrowSmallLeftIcon } from '@heroicons/react/24/outline'
import { FC, PropsWithChildren } from 'react'

const PageHeader: FC<PropsWithChildren<{
  title?: string,
  onBack?: () => any,
  extra?: any[]
  
}>> = ({ title, onBack, extra, children }) => {

  return (
    <VStack align='stretch' style={{padding: '6px 16px'}}>
      <HStack>
        <HStack style={{margin: '4px 0'}}>
          {onBack && 
          <IconButton 
            aria-label='back' 
            icon={<Icon as={ArrowSmallLeftIcon} />} 
            variant='ghost'
            onClick={onBack}
          />
          }
          
          <Text fontSize='xl' as='b'>{title}</Text>
        </HStack>

        <Spacer />

        <HStack>
          {extra && extra.length > 0 &&
          extra.map((action) => {
            return action
          })
          }
        </HStack>
      </HStack>
      <>
        {children}
      </>
    </VStack>
  )
}

export default PageHeader
