import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useContext,
  useState,
  useMemo,
} from 'react'
import { useForm } from 'react-hook-form'
import {
  VStack,
  Card,
  CardBody,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
  HStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Textarea,
  Checkbox,
  Button,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Tag,
  Wrap,
  WrapItem,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
  Icon,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { ColumnDef } from '@tanstack/react-table'
import CustomTable from '../../../components/CustomTable'
import Gaps from '../../../components/Gaps'
import _ from 'lodash'
import AdminCampaignService, {
  AdminCampaign,
  AdminCampaignCreateState,
  CampaignAffiliate,
  CampaignProduct,
  CampaignUpdateDiscountState,
} from '../../../network/services/adminCampaign'
import { useNavigate, useParams } from 'react-router-dom'
import { IDataResponse, serialize } from '../../../network/request'
import OrderService, { LineItem, Order } from '../../../network/services/order'
import useSWR from 'swr'
import ReadOnlyFormItem from '../../../components/ReadOnlyFormItem'
import { affiliateCampaignStatusText } from '../../../network/services/affiliateCampaign'
import CopyableText from '../../../components/CopyableText'

const adminCampaignContext = createContext<AdminCampaign | null | undefined>(
  null
)

const AdminCampaignDetail: FC<{
  formRef: any
  initialValue?: AdminCampaign
  refresh: () => any
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
}> = ({ formRef, initialValue, refresh, isLoading, setIsLoading }) => {
  const { id } = useParams()
  const {
    isOpen: isCouponSetupOpen,
    onOpen: onCouponSetupOpen,
    onClose: onCouponSetupClose,
  } = useDisclosure()

  const toast = useToast()
  let {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<AdminCampaignCreateState>({
    shouldUseNativeValidation: false,
    defaultValues: {
      ...initialValue,
      commission_rate: initialValue?.commission_rate
        ? initialValue.commission_rate * 100
        : undefined,
      currency: initialValue?.currency ?? 'JPY',
      status: initialValue?.status ?? 'ongoing',
    },
  })

  const enableDiscount = watch('enable_discount')
  const discountValueType = watch('discount_value_type')

  const navigate = useNavigate()
  const tabItems = [
    {
      key: '1',
      label: '商品一覧',
      children: <CampaignProductTable />,
    },
    {
      key: '2',
      label: '注文一覧',
      children: <CampaignOrderTable />,
    },
    {
      key: '3',
      label: 'アフィリエイト一覧',
      children: <CampaignAffiliateTable />,
    },
  ]

  const submit = async (values: AdminCampaignCreateState) => {
    if (isLoading) return
    setIsLoading(true)

    if (initialValue?.id) {
      try {
        const { data: result } = await AdminCampaignService.update(
          initialValue.id,
          {
            ...values,
            commission_rate: values.commission_rate
              ? values.commission_rate / 100
              : undefined,
          }
        )
        if (result.success) {
          toast({
            status: 'success',
            description: 'データが保存されした',
            variant: 'subtle',
            position: 'top',
          })
          refresh()
        }
      } catch (error: any) {
        toast({
          status: 'error',
          description: 'エラーが発生しました。' + error.message,
          variant: 'subtle',
          position: 'top',
        })
      }
    } else {
      try {
        const { data: result } = await AdminCampaignService.create(values)
        if (result.success) {
          toast({
            status: 'success',
            description: '保存が完了しました',
            variant: 'subtle',
            position: 'top',
          })
          navigate(`/admin/dashboard/campaigns/${result.data.id}`)
        }
      } catch (error: any) {
        toast({
          status: 'error',
          description: 'エラーが発生しました。' + error.message,
          variant: 'subtle',
          position: 'top',
        })
      }
    }

    setIsLoading(false)
  }

  return (
    <>
      <Card>
        <CardBody>
          <VStack
            align='start'
            spacing='16px'
            as='form'
            onSubmit={handleSubmit(submit)}
            ref={formRef}
          >
            {id != 'new' && (
              <>
                <HStack spacing='16px'>
                  {/* <ReadOnlyFormItem
                    title='紹介料総額'
                    content={`￥${initialValue?.total_commission}`}
                    enableCopy={false}
                  /> */}

                  <ReadOnlyFormItem
                    title='連携した注文数'
                    content={initialValue?.total_orders}
                    enableCopy={false}
                  />

                  <ReadOnlyFormItem
                    title='売上総額'
                    content={`￥${initialValue?.total_sales}`}
                    enableCopy={false}
                  />
                </HStack>

                <Gaps />
              </>
            )}

            <FormControl isInvalid={Boolean(errors.name)} isRequired>
              <FormLabel>キャンペーン名</FormLabel>
              <Input
                autoComplete='off'
                {...register('name', {
                  required: '必要なフィールドです',
                })}
              />

              <FormErrorMessage>
                {errors.name ? String(errors.name.message) : ''}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={Boolean(errors.complete_after_days)}>
              <FormLabel>
                ○日後で紹介料を配布する（０にする場合は、注文が配送した後すぐに配布します）
              </FormLabel>
              <NumberInput w='fit-content'>
                <NumberInputField
                  {...register('complete_after_days', {
                    min: { value: 0, message: '0以上に設定してください' },
                  })}
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>

              <FormErrorMessage>
                {errors.complete_after_days
                  ? String(errors.complete_after_days.message)
                  : ''}
              </FormErrorMessage>
            </FormControl>

            <FormControl>
              <FormLabel>開催状態</FormLabel>
              <Menu closeOnSelect={true} autoSelect={false} variant='select'>
                <MenuButton
                  as={Button}
                  rightIcon={<Icon as={ChevronDownIcon} />}
                  variant='select'
                >
                  {watch('status') == 'ongoing' ? '開催中' : '終了'}
                </MenuButton>
                <MenuList>
                  <MenuOptionGroup
                    type='radio'
                    value={watch('status')}
                    onChange={(e) => {
                      setValue('status', e as 'ongoing' | 'inactive')
                    }}
                  >
                    <MenuItemOption
                      {...register('status')}
                      id='ongoing'
                      value={'ongoing'}
                    >
                      開催中
                    </MenuItemOption>
                    <MenuItemOption
                      {...register('status')}
                      id='Inactive'
                      value={'inactive'}
                    >
                      終了
                    </MenuItemOption>
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
            </FormControl>

            {/* <Form.Item name='budget' label='予算'>
              <InputNumber />
            </Form.Item> */}

            <FormControl isInvalid={Boolean(errors.commission_rate)}>
              <FormLabel>
                紹介料レート（紹介料を設定した場合、このフィールドのデータを０にしてください）
              </FormLabel>

              <InputGroup>
                <NumberInput w='fit-content'>
                  <NumberInputField
                    {...register('commission_rate', {
                      min: {
                        value: 1,
                        message:
                          '紹介料レートは１～１００までに設定してください',
                      },
                      max: {
                        value: 100,
                        message:
                          '紹介料レートは１～１００までに設定してください',
                      },
                    })}
                    borderRightRadius={0}
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <InputRightAddon children='%' />
              </InputGroup>

              <FormErrorMessage>
                {errors.commission_rate
                  ? String(errors.commission_rate.message)
                  : ''}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={Boolean(errors.commission_amount)}>
              <FormLabel>
                紹介料（紹介料レートを設定した場合、このフィールドのデータを０にしてください）
              </FormLabel>
              <NumberInput w='fit-content'>
                <NumberInputField
                  {...register('commission_amount', {
                    min: {
                      value: 0,
                      message: '紹介料は０以上に設定してください',
                    },
                  })}
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>

              <FormErrorMessage>
                {errors.commission_amount
                  ? String(errors.commission_amount.message)
                  : ''}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={Boolean(errors.currency)} isRequired>
              <FormLabel>通貨</FormLabel>
              <Menu closeOnSelect={true} autoSelect={false} variant='select'>
                <MenuButton
                  as={Button}
                  rightIcon={<Icon as={ChevronDownIcon} />}
                  variant='select'
                  isDisabled
                >
                  {watch('currency')}
                </MenuButton>
                <MenuList>
                  <MenuOptionGroup
                    type='radio'
                    value={watch('currency')}
                    onChange={(e) => {
                      setValue('currency', String(e))
                    }}
                  >
                    <MenuItemOption
                      {...register('currency', {
                        required: '必要なフィールドです',
                      })}
                      value={'JPY'}
                    >
                      開催中
                    </MenuItemOption>
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
              <FormErrorMessage>
                {errors.currency ? String(errors.currency.message) : ''}
              </FormErrorMessage>
            </FormControl>

            {/* <Form.Item name='ends_at' label='開催終了日'>
              <DatePicker />
            </Form.Item> */}

            <FormControl>
              <FormLabel>社内用説明</FormLabel>
              <Textarea rows={5} {...register('internal_description')} />
            </FormControl>

            <FormControl>
              <FormLabel>公開説明</FormLabel>
              <Textarea rows={5} {...register('description')} />
            </FormControl>

            {id == 'new' && (
              <>
                <FormControl>
                  <Checkbox {...register('enable_discount')}>
                    クーポンコードを適用する
                  </Checkbox>
                </FormControl>

                {enableDiscount && (
                  <Card w='full'>
                    <CardBody>
                      <VStack spacing='16px'>
                        <FormControl
                          isInvalid={Boolean(errors.discount_value_type)}
                          isRequired
                        >
                          <FormLabel>値引きタイプ</FormLabel>
                          <Menu
                            closeOnSelect={true}
                            autoSelect={false}
                            variant='select'
                          >
                            <MenuButton
                              as={Button}
                              rightIcon={<Icon as={ChevronDownIcon} />}
                              variant='select'
                            >
                              {watch('discount_value_type')
                                ? watch('discount_value_type') == 'percentage'
                                  ? '百分率'
                                  : '固定金額'
                                : ''}
                            </MenuButton>
                            <MenuList>
                              <MenuOptionGroup
                                type='radio'
                                value={watch('discount_value_type')}
                                onChange={(e) => {
                                  setValue(
                                    'discount_value_type',
                                    e as
                                      | 'percentage'
                                      | 'fixed_amount'
                                      | undefined
                                  )
                                }}
                              >
                                <MenuItemOption
                                  {...register('discount_value_type', {
                                    required: '必要なフィールドです',
                                  })}
                                  value={'percentage'}
                                >
                                  百分率
                                </MenuItemOption>
                                <MenuItemOption
                                  {...register('discount_value_type', {
                                    required: '必要なフィールドです',
                                  })}
                                  value={'fixed_amount'}
                                >
                                  固定金額
                                </MenuItemOption>
                              </MenuOptionGroup>
                            </MenuList>
                          </Menu>

                          <FormErrorMessage>
                            {errors.discount_value_type
                              ? String(errors.discount_value_type.message)
                              : ''}
                          </FormErrorMessage>
                        </FormControl>

                        {discountValueType == 'percentage' && (
                          <FormControl
                            isInvalid={Boolean(errors.discount_percentage)}
                          >
                            <FormLabel>値引きレート</FormLabel>
                            <NumberInput w='fit-content'>
                              <NumberInputField
                                {...register('discount_percentage', {
                                  min: {
                                    value: 0,
                                    message:
                                      '紹介料レートは０～１までに設定してください',
                                  },
                                  max: {
                                    value: 1,
                                    message:
                                      '紹介料レートは０～１までに設定してください',
                                  },
                                })}
                              />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>

                            <FormErrorMessage>
                              {errors.discount_percentage
                                ? String(errors.discount_percentage.message)
                                : ''}
                            </FormErrorMessage>
                          </FormControl>
                        )}

                        {discountValueType == 'fixed_amount' && (
                          <FormControl
                            isInvalid={Boolean(errors.discount_fixed_amount)}
                          >
                            <FormLabel>値引きレート</FormLabel>
                            <NumberInput w='fit-content'>
                              <NumberInputField
                                {...register('discount_fixed_amount', {
                                  min: {
                                    value: 0,
                                    message: '紹介料は０以上に設定してください',
                                  },
                                })}
                              />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>

                            <FormErrorMessage>
                              {errors.discount_fixed_amount
                                ? String(errors.discount_fixed_amount.message)
                                : ''}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </VStack>
                    </CardBody>
                  </Card>
                )}
              </>
            )}
          </VStack>
        </CardBody>
      </Card>
      <Gaps />

      {initialValue?.enable_discount == true && id !== 'new' && (
        <DiscountForm refresh={refresh} initialValue={initialValue} />
      )}

      {initialValue?.enable_discount == false && id !== 'new' && (
        <>
          <Button variant='outline' onClick={() => onCouponSetupOpen()}>
            クーポンを設定する
          </Button>
          <Gaps />
        </>
      )}

      <DiscountSetupModal
        isOpen={isCouponSetupOpen}
        onClose={onCouponSetupClose}
        refresh={refresh}
      />

      <adminCampaignContext.Provider value={initialValue}>
        {initialValue && (
          <Card>
            <CardBody>
              <Tabs defaultIndex={0} isLazy>
                <TabList>
                  {tabItems.map((item) => (
                    <Tab key={item.key}>{item.label}</Tab>
                  ))}
                </TabList>
                <TabPanels>
                  {tabItems.map((item) => (
                    <TabPanel key={item.key}>{item.children}</TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            </CardBody>
          </Card>
        )}
      </adminCampaignContext.Provider>
    </>
  )
}

const DiscountSetupModal = ({
  isOpen,
  onClose,
  refresh,
}: {
  isOpen: boolean
  onClose: () => void
  refresh: () => void
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { id } = useParams()

  const toast = useToast()
  let {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CampaignUpdateDiscountState>({
    shouldUseNativeValidation: false,
    defaultValues: {
      discount_value_type: 'percentage',
    },
  })
  const discountValueType = watch('discount_value_type')

  const submit = async (values: CampaignUpdateDiscountState) => {
    if (isLoading) return
    if (!id) return
    setIsLoading(true)

    try {
      const { data: result } = await AdminCampaignService.updateDiscount(id, {
        ...values,
        discount_percentage: values.discount_percentage
          ? values.discount_percentage / 100
          : values.discount_percentage,
      })
      if (result.success) {
        toast({
          status: 'success',
          description: 'データが保存されした',
          variant: 'subtle',
          position: 'top',
        })
        refresh()
        onClose()
      }
    } catch (error: any) {
      toast({
        status: 'error',
        description: 'エラーが発生しました。' + error.message,
        variant: 'subtle',
        position: 'top',
      })
    }

    setIsLoading(false)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>クーポン設定</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack as='form' onSubmit={handleSubmit(submit)} spacing='16px'>
            <FormControl
              isInvalid={Boolean(errors.discount_value_type)}
              isRequired
            >
              <FormLabel>値引きタイプ</FormLabel>
              <Menu closeOnSelect={true} autoSelect={false} variant='select'>
                <MenuButton
                  as={Button}
                  rightIcon={<Icon as={ChevronDownIcon} />}
                  variant='select'
                >
                  {watch('discount_value_type')
                    ? watch('discount_value_type') == 'percentage'
                      ? '百分率'
                      : '固定金額'
                    : ''}
                </MenuButton>
                <MenuList>
                  <MenuOptionGroup
                    type='radio'
                    value={watch('discount_value_type')}
                    onChange={(e) => {
                      setValue(
                        'discount_value_type',
                        e as 'percentage' | 'fixed_amount' | undefined
                      )
                    }}
                  >
                    <MenuItemOption
                      {...register('discount_value_type', {
                        required: '必要なフィールドです',
                      })}
                      value={'percentage'}
                    >
                      百分率
                    </MenuItemOption>
                    <MenuItemOption
                      {...register('discount_value_type', {
                        required: '必要なフィールドです',
                      })}
                      value={'fixed_amount'}
                    >
                      固定金額
                    </MenuItemOption>
                  </MenuOptionGroup>
                </MenuList>
              </Menu>

              <FormErrorMessage>
                {errors.discount_value_type
                  ? String(errors.discount_value_type.message)
                  : ''}
              </FormErrorMessage>
            </FormControl>

            {discountValueType == 'percentage' && (
              <FormControl
                isInvalid={Boolean(errors.discount_percentage)}
                isRequired
              >
                <FormLabel>値引きレート</FormLabel>
                <InputGroup>
                  <NumberInput w='fit-content'>
                    <NumberInputField
                      {...register('discount_percentage', {
                        required: '必要なフィールドです',
                        min: {
                          value: 1,
                          message: '値引きレートは1～100までに設定してください',
                        },
                        max: {
                          value: 100,
                          message: '値引きレートは1～100までに設定してください',
                        },
                      })}
                      borderRightRadius={0}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <InputRightAddon children='%' />
                </InputGroup>

                <FormErrorMessage>
                  {errors.discount_percentage
                    ? String(errors.discount_percentage.message)
                    : ''}
                </FormErrorMessage>
              </FormControl>
            )}
            {discountValueType == 'fixed_amount' && (
              <FormControl
                isInvalid={Boolean(errors.discount_fixed_amount)}
                isRequired
              >
                <FormLabel>値引き金額</FormLabel>
                <NumberInput w='fit-content'>
                  <NumberInputField
                    {...register('discount_fixed_amount', {
                      required: '必要なフィールドです',
                      min: {
                        value: 0,
                        message: '紹介料は０以上に設定してください',
                      },
                    })}
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>

                <FormErrorMessage>
                  {errors.discount_fixed_amount
                    ? String(errors.discount_fixed_amount.message)
                    : ''}
                </FormErrorMessage>
              </FormControl>
            )}
            <Gaps />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={isLoading}
            onClick={onClose}
            mr={3}
            variant='outline'
            loadingText='やめる'
          >
            やめる
          </Button>
          <Button
            isLoading={isLoading}
            onClick={handleSubmit(submit)}
            loadingText='保存する'
          >
            保存する
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const DiscountForm = ({
  initialValue,
  refresh,
}: {
  initialValue: AdminCampaign
  refresh: () => void
}) => {
  const toast = useToast()
  let {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CampaignUpdateDiscountState>({
    shouldUseNativeValidation: false,
    defaultValues: {
      ...initialValue,
      discount_percentage: (initialValue.discount_percentage ?? 0) * 100,
    },
  })

  const [isLoading, setIsLoading] = useState(false)
  const discountValueType = watch('discount_value_type')

  const submit = async (values: CampaignUpdateDiscountState) => {
    if (isLoading) return
    setIsLoading(true)

    try {
      const { data: result } = await AdminCampaignService.updateDiscount(
        initialValue.id,
        {
          ...values,
          discount_percentage: values.discount_percentage
            ? values.discount_percentage / 100
            : values.discount_percentage,
        }
      )
      if (result.success) {
        toast({
          status: 'success',
          description: 'データが保存されした',
          variant: 'subtle',
          position: 'top',
        })
        refresh()
      }
    } catch (error: any) {
      toast({
        status: 'error',
        description: 'エラーが発生しました。' + error.message,
        variant: 'subtle',
        position: 'top',
      })
    }

    setIsLoading(false)
  }
  return (
    <>
      <Card>
        <CardBody>
          <VStack
            align='start'
            spacing='16px'
            as='form'
            onSubmit={handleSubmit(submit)}
          >
            <Text fontSize='2xl' as='b'>
              クーポン設定
            </Text>

            <FormControl
              isInvalid={Boolean(errors.discount_value_type)}
              isRequired
            >
              <FormLabel>値引きタイプ</FormLabel>
              <Menu closeOnSelect={true} autoSelect={false} variant='select'>
                <MenuButton
                  as={Button}
                  rightIcon={<Icon as={ChevronDownIcon} />}
                  variant='select'
                >
                  {watch('discount_value_type')
                    ? watch('discount_value_type') == 'percentage'
                      ? '百分率'
                      : '固定金額'
                    : ''}
                </MenuButton>
                <MenuList>
                  <MenuOptionGroup
                    type='radio'
                    value={watch('discount_value_type')}
                    onChange={(e) => {
                      setValue(
                        'discount_value_type',
                        e as 'percentage' | 'fixed_amount' | undefined
                      )
                    }}
                  >
                    <MenuItemOption
                      {...register('discount_value_type', {
                        required: '必要なフィールドです',
                      })}
                      value={'percentage'}
                    >
                      百分率
                    </MenuItemOption>
                    <MenuItemOption
                      {...register('discount_value_type', {
                        required: '必要なフィールドです',
                      })}
                      value={'fixed_amount'}
                    >
                      固定金額
                    </MenuItemOption>
                  </MenuOptionGroup>
                </MenuList>
              </Menu>

              <FormErrorMessage>
                {errors.discount_value_type
                  ? String(errors.discount_value_type.message)
                  : ''}
              </FormErrorMessage>
            </FormControl>

            {discountValueType == 'percentage' && (
              <FormControl
                isInvalid={Boolean(errors.discount_percentage)}
                isRequired
              >
                <FormLabel>値引きレート</FormLabel>
                <InputGroup>
                  <NumberInput w='fit-content'>
                    <NumberInputField
                      {...register('discount_percentage', {
                        required: '必要なフィールドです',
                        min: {
                          value: 1,
                          message: '値引きレートは1～100までに設定してください',
                        },
                        max: {
                          value: 100,
                          message: '値引きレートは1～100までに設定してください',
                        },
                      })}
                      borderRightRadius={0}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <InputRightAddon children='%' />
                </InputGroup>

                <FormErrorMessage>
                  {errors.discount_percentage
                    ? String(errors.discount_percentage.message)
                    : ''}
                </FormErrorMessage>
              </FormControl>
            )}

            {discountValueType == 'fixed_amount' && (
              <FormControl
                isInvalid={Boolean(errors.discount_fixed_amount)}
                isRequired
              >
                <FormLabel>値引き金額</FormLabel>
                <NumberInput w='fit-content'>
                  <NumberInputField
                    {...register('discount_fixed_amount', {
                      required: '必要なフィールドです',
                      min: {
                        value: 0,
                        message: '値引き金額は０以上に設定してください',
                      },
                    })}
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>

                <FormErrorMessage>
                  {errors.discount_fixed_amount
                    ? String(errors.discount_fixed_amount.message)
                    : ''}
                </FormErrorMessage>
              </FormControl>
            )}

            <Button
              alignSelf='end'
              onClick={handleSubmit(submit)}
              isLoading={isLoading}
            >
              保存する
            </Button>
          </VStack>
        </CardBody>
      </Card>
      <Gaps />
    </>
  )
}

const CampaignProductTable: FC = () => {
  const campaign = useContext(adminCampaignContext)

  const columns = useMemo<ColumnDef<CampaignProduct, any>[]>(
    () => [
      {
        id: 'id',
        accessorKey: 'id',
        header: 'ID',
      },
      {
        id: 'external_id',
        accessorKey: 'external_id',
        header: 'Shopify ID',
        cell: (info) => info.row.original.product.external_id,
      },
      {
        id: 'name',
        accessorKey: 'name',
        header: '商品名',
        cell: (info) => info.row.original.product.name,
        enableSorting: false,
      },
    ],
    []
  )

  return (
    <CustomTable
      {...{
        data: campaign?.products ?? [],
        columns,
      }}
    />
  )
}

const CampaignOrderTable: FC = () => {
  const campaign = useContext(adminCampaignContext)
  const { data: response } = useSWR<IDataResponse<Order>>(
    serialize(OrderService.getAll, {
      campaign: campaign?.id,
    })
  )
  const rows = response ? OrderService.toRow(response) : []
  const { total } = response ? OrderService.toPaginate(response) : { total: 0 }

  const columns = useMemo<ColumnDef<Order, any>[]>(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        header: '注文',
      },
      {
        id: 'item',
        accessorKey: 'name',
        header: '商品',
        cell: (info) => (
          <Wrap spacing='5px'>
            {info.row.original.line_items.map((item: LineItem) => (
              <WrapItem key={item.id}>
                <Tag>{`${item.variant.fullname} (${item.quantity}個)`}</Tag>
              </WrapItem>
            ))}
          </Wrap>
        ),
        enableSorting: false,
      },
      {
        id: 'total_price',
        accessorKey: 'subtotal_price',
        header: '総額',
        cell: (info) => `￥${info.getValue()}`,
      },
      {
        id: 'total_commission',
        accessorKey: 'total_commission',
        header: '紹介料総額',
        cell: (info) => `￥${info.getValue()}`,
      },
    ],
    []
  )

  return (
    <CustomTable
      {...{
        data: rows ?? [],
        columns,
      }}
    />
  )
}

const CampaignAffiliateTable: FC = () => {
  const campaign = useContext(adminCampaignContext)
  const { data: response } = useSWR<IDataResponse<CampaignAffiliate>>(
    campaign?.id ? AdminCampaignService.getAffiliates(campaign.id) : null
  )
  const rows = response ? AdminCampaignService.affiliateToRow(response) : []
  const { total } = response
    ? AdminCampaignService.affiliateToPaginate(response)
    : { total: 0 }

  const columns = useMemo<ColumnDef<CampaignAffiliate, any>[]>(
    () => [
      {
        id: 'id',
        accessorKey: 'id',
        header: 'ID',
      },
      {
        id: 'external_id',
        accessorFn: (row) =>
          `${row.affiliate.last_name ?? ''} ${row.affiliate.first_name ?? ''}`,
        header: '名前',
        cell: (info) =>
          `${info.row.original.affiliate.last_name ?? ''} ${
            info.row.original.affiliate.first_name ?? ''
          }`,
      },
      {
        id: 'type',
        accessorFn: (row) => row.affiliate.type,
        header: 'タイプ',
        cell: (info) => info.row.original.affiliate.type,
      },
      {
        id: 'total_sales',
        accessorFn: (row) => row.affiliate.user?.email,
        header: 'メールアドレス',
        cell: (info) => info.row.original.affiliate.user?.email,
      },
      {
        id: 'status',
        accessorKey: 'status',
        header: '参加状態',
        cell: (info) => (
          <Text
            style={{
              color:
                affiliateCampaignStatusText[
                  info.getValue() as keyof typeof affiliateCampaignStatusText
                ].color,
            }}
          >
            {
              affiliateCampaignStatusText[
                info.getValue() as keyof typeof affiliateCampaignStatusText
              ].text
            }
          </Text>
        ),
      },
      {
        id: 'total_sales',
        accessorKey: 'total_sales',
        header: '売上総額',
        cell: (info) => `￥${info.getValue()}`,
      },
      {
        id: 'total_commission',
        accessorKey: 'total_commission',
        header: '紹介料総額',
        cell: (info) => `￥${info.getValue()}`,
      },
      {
        id: 'total_orders',
        accessorKey: 'total_orders',
        header: '連携した注文数',
      },
      {
        id: 'discount_code',
        accessorFn: (row) => row.discount_code?.code ?? '-',
        header: 'クーポンコード',
        cell: (info) => (
          <>
            {!!info.getValue()?.code ? (
              <CopyableText>{info.getValue()?.code}</CopyableText>
            ) : (
              <Text>-</Text>
            )}
          </>
        ),
        enableSorting: false,
      },
    ],
    []
  )

  return (
    <CustomTable
      {...{
        data: rows ?? [],
        columns,
      }}
    />
  )
}

export default AdminCampaignDetail
