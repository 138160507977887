import { Box, Heading, Text, Link } from '@chakra-ui/react'

const TNC = () => {
  return (
    <>
      <Heading>利用規約</Heading>
      <br />

      <Text as='b'>
        第１条（総則）
      </Text>
      <Box paddingY='7.5px'></Box>

      <Text textStyle='p'>
        １．本規約は、ケースフィニット株式会社（以下「当社」といいます）が提供するケースフィニットパートナーズプログラムに参加する会員が、本サービスの参加及び利用に関して遵守すべき事項を定めるものです。
      </Text>

      <Text textStyle='p'>
        ２．当社が当社ウェブサイト上で掲載する本サービス利用に関するルール（
        <Link href='http://partners.casefinite.jp'>
          http://partners.casefinite.jp
        </Link>
        ）は、本規約の一部を構成するものとします。
      </Text>

      <Text textStyle='p'>
        ３．本サービスに関して、本規約に規定のない事項については、CASEFINITE会員規約（以下「会員規約等」といいます）が適用されます。
      </Text>

      <Text textStyle='p'>
        ４．パートナーが本サービスを利用された場合、パートナーが本規約等に同意したものとみなします。
      </Text>

      <Text textStyle='p'>
        ５．本規約の内容と、前項のルールその他の本規約外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。
      </Text>

      <Text as='b'>
        第２条（定義）
      </Text>
      <Box paddingY='7.5px'></Box>

      <Text textStyle='p'>
        本規約において、以下の用語は以下に定める意味を有するものとします。
      </Text>

      <Text textStyle='p'>
        1.「本サービス」とは、当社が提供するケースフィニットパートナーズプログラムをいいます。
      </Text>

      <Text textStyle='p'>
        2.「会員」とは、会員規約等に基づき会員登録をした者をいいます。
      </Text>

      <Text textStyle='p'>
        3.「パートナー」とは、本サービスに参加する会員をいいます。
      </Text>

      <Text textStyle='p'>
        4.「公式サイト等」とは、当社又はサービス提供者が運営するウェブサイトのうち本サービスによるリンク対象として当社が指定したものをいいます。
      </Text>

      <Text textStyle='p'>
        5.「本契約」とは、本規約等に基づき生ずる当社とパートナーとの間の契約をいいます。
      </Text>

      <Text textStyle='p'>
        6.「アフィリエイトメディア」とは、パートナーが自ら運営管理するウェブサイト、アプリケーション、その他本サービスの対象となるものとして当社が指定する媒体をいいます。
      </Text>

      <Text textStyle='p'>
        7.「アフィリエイトリンク」とは本サービスの利用を通じて当社所定の方法でパートナーが発行し、アフィリエイトメディアに設置する、商品等に関するハイパーリンクをいいます。
      </Text>

      <Text textStyle='p'>
        8.「サービス提供者」とは、商品又はサービス（以下「商品等」といいます）の販売、予約受付、その他当社が指定するサービスを提供する者をいいます。
      </Text>

      <Text textStyle='p'>
        9.「リンク作成用コンテンツ」とは、当社またはサービス提供者が作成したバナー用のHTMLコード、アプリケーション・プログラミング・インターフェイス等を通じて提供される情報その他当社所定の専用コンテンツをいいます。
      </Text>

      <Text textStyle='p'>
        10.「専用クーポンコード」とは、当社またはサービス提供者がパートナーに付与するコードを言います。
      </Text>

      <Text textStyle='p'>
        11.「ユーザ」とは、アフィリエイトメディアの閲覧者をいいます。
      </Text>

      <Text textStyle='p'>
        12.「本規約等」とは、本規約、ケースフィニットパートナーズプログラムガイドライン、及び成果報酬のお支払いのルールをいいます。
      </Text>

      <Text textStyle='p'>
        13.「反社会的勢力等」とは暴力団、暴力団員、暴力団準構成員、総会屋、社会運動等標榜ゴロ、特殊知能暴力集団その他これに準じる者、及びこれらの者と密接な関わり（資金その他の便益提供行為を含みます）を有する者並びにこれらに準じる者をいいます。
      </Text>

      <Text as='b'>
        第３条（パートナー登録）
      </Text>
      <Box paddingY='7.5px'></Box>
      <Text textStyle='p'>
        １．本サービスの利用は会員のうち次項の登録を行った者に限るものとします。
      </Text>
      <Text textStyle='p'>
        ２．会員がパートナーとして本サービスに参加しようとする場合は、当社所定の登録フォームに当社が定める登録情報を登録するものとします。なお、当社は、その裁量により登録情報として登録すべき事項（以下「登録事項」といいます）を変更することができるものとし、新たに登録事項が追加された場合、パートナーは当社が指定する期間内に当社所定の方法によって登録情報の登録を行うものとします。
      </Text>
      <Text textStyle='p'>
        ３．当社は、当社の基準に従って、第１項に基づいて登録申請を行った登録希望者（以下「登録申請者」といいます。）の登録の可否を判断し、当社が登録を認める場合にはその旨を登録申請者に通知します。登録申請者のユーザとしての登録は、当社が本項の通知を行ったことをもって完了したものとします。
      </Text>
      <Text textStyle='p'>
        ４．前項に定める登録の完了時に、サービス利用契約がユーザと当社の間に成立し、ユーザは本サービスを本規約に従い利用することができるようになります。
      </Text>
      <Text textStyle='p'>
        ５．当社は、登録申請者が、以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあり、またその理由について一切開示義務を負いません。
      </Text>
      <Text textStyle='p'>
        （１）当社に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合
      </Text>
      <Text textStyle='p'>
        （２）未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合
      </Text>
      <Text textStyle='p'>
        （３）反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合
      </Text>
      <Text textStyle='p'>
        （４）過去当社との契約に違反した者またはその関係者であると当社が判断した場合
      </Text>
      <Text textStyle='p'>
        （５）第16条に定める措置を受けたことがある場合
      </Text>
      <Text textStyle='p'>
        （６）その他、登録を適当でないと当社が判断した場合
      </Text>
      <Text textStyle='p'>
        ６．第2項の登録情報に変更があった場合は、パートナーは、速やかに変更登録を行わなければなりません。
      </Text>
      <Text textStyle='p'>
        ７．当社は、本条により登録されたパートナーの情報およびパートナーによる本サービスの利用に関して知り得たパートナーの情報を別途定める「プライバシーポリシー」に従い取り扱うものとし、パートナーはこのことに同意するものとします。
      </Text>
      <Text textStyle='p'>
        ８．パートナーは、当社から登録情報の確認、証明のための資料の提出を求められた場合には当社が指定する期間内にこれに応じるものとします。
      </Text>

      <Text as='b'>
        第４条（パスワード及びユーザIDの管理）
      </Text>
      <Box paddingY='7.5px'></Box>
      <Text textStyle='p'>
        １．ユーザは、自己の責任において、本サービスに関するパスワード及びユーザIDを適切に管理及び保管するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更、売買等をしてはならないものとします。
      </Text>
      <Text textStyle='p'>
        ２．パスワードまたはユーザIDの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任はユーザが負うものとします。
      </Text>

      <Text as='b'>
        第５条（本サービスの内容）
      </Text>
      <Box paddingY='7.5px'></Box>
      <Text textStyle='p'>
        １．本サービスは、パートナーがアフィリエイトメディアにアフィリエイトリンクを設置し、ユーザが当該アフィリエイトリンクを通じて公式サイト等にアクセスし（以下このようなアクセスを「経由アクセス」といいます）、又はパートナーがユーザに対して専用クーポンコードを伝達し、ユーザが当該クーポンコードを入力したうえで商品等を購入し、これによってサービス提供者に生じた売上、販売件数、入会件数、予約受付件数、入会申込件数、資料請求受付件数、その他当社が規定する成果（以下「付与対象成果」といいます）に応じて、当社がパートナーに対して別途定める方法で報酬（以下「成果報酬」といいます）を支払うことを内容とします。
      </Text>
      <Text textStyle='p'>
        ２．前項の成果報酬は、当社所定の方法で支払われるものとします。なお、当社は、成果報酬の支払方法について、その裁量により変更することができるものとします。
      </Text>
      <Text textStyle='p'>
        ３．本サービスの提供に関する業務の一部は、当社の委託先に委託する場合がございます。
      </Text>

      <Text as='b'>
        第６条（アフィリエイトリンクの掲示）
      </Text>
      <Box paddingY='7.5px'></Box>
      <Text textStyle='p'>
        １．パートナーは、当社所定の方法により、アフィリエイトリンクを設置するアフィリエイトメディアの登録を行うものとします。また、パートナーはアフィリエイトメディアに追加または変更があった場合は、直ちに追加・変更登録を行うものとします。
      </Text>
      <Text textStyle='p'>
        ２．パートナーは、前項により登録したアフィリエイトメディアに、当社所定の方法により、アフィリエイトリンクを設置することができます。ただし、当社またはリンク先となるサービス提供者が、アフィリエイトリンクの設置について制限または条件を付しているときは、これに従うものとします。
      </Text>
      <Text textStyle='p'>
        ３．パートナーは、アフィリエイトリンクを設置するにあたり、当社またはサービス提供者が作成したリンク作成用コンテンツを使用するものとします。
      </Text>
      <Text textStyle='p'>
        ４．パートナーは、リンク作成用コンテンツを、本規約等に基づく本サービス利用のためのアフィリエイトリンク設置以外の目的に使用することはできません。
      </Text>
      <Text textStyle='p'>
        ５．当社は、以下各号に該当すると判断した場合、パートナーが運営するアフィリエイトメディアの全部または一部からのリンクを拒否できるものとします。
      </Text>
      <Text textStyle='p'>
        （１）アフィリエイトリンクが設置されたアフィリエイトメディアが、そのコンテンツ等からふさわしくない場合
      </Text>
      <Text textStyle='p'>
        （２）アフィリエイトリンクが、第8条の禁止事項に利用されている場合
      </Text>
      <Text textStyle='p'>
        （３）その他パートナーによるアフィリエイトメディアの運営及びアフィリエイトリンクの設置を継続させることが相当でない場合
      </Text>
      <Text textStyle='p'>
        ６．当社及びサービス提供者は、前項各号に該当すると判断した場合、パートナーの設置したアフィリエイトリンクについて削除または変更を求めることができるものとします。この場合、パートナーは、ただちに当該アフィリエイトリンクを削除または変更しなければなりません。
      </Text>

      <Text as='b'>
        第７条（アフィリエイトメディアの内容）
      </Text>
      <Box paddingY='7.5px'></Box>
      <Text textStyle='p'>
        １．パートナーがアフィリエイトリンクを設置するアフィリエイトメディアは、以下のいずれかの内容を含んではなりません。
      </Text>
      <Text textStyle='p'>
        （1）本規約等（第8条の禁止事項を含む）、会員規約等その他の規約、ルール、ガイドライン等に反する又はそのおそれのある内容
      </Text>
      <Text textStyle='p'>
        （2）犯罪行為にかかわる内容、差別的表現、その他公序良俗に反する表現・内容
      </Text>
      <Text textStyle='p'>
        （3）アダルトコンテンツ、不潔またはグロテスクなコンテンツ等の一般人が不快感を覚える内容、その他青少年も含めた不特定多数のユーザによる閲覧に適さない内容
      </Text>
      <Text textStyle='p'>
        （4）景品表示法その他広告規制（官公庁又は業界団体が制定したガイドラインを含む）に違反する表示
      </Text>
      <Text textStyle='p'>
        （5）日本通信販売協会（
        <Link href='https://www.jadma.or.jp/'>
          https://www.jadma.or.jp/
        </Link>
        ）が定める広告に関する自主基準に違反する不当な表示
      </Text>
      <Text textStyle='p'>
        （6）ユーザに誤解を与えるおそれのある内容
      </Text>
      <Text textStyle='p'>
        （7）「サイト運営のためにクリックして下さい」等、ユーザに対しクリックを依頼または嘆願する文言
      </Text>
      <Text textStyle='p'>
        （8）当社がユーザとサービス提供者との取引を代理、媒介、あっせん、介入、保証等するかのような内容、当社がパートナーを代理、保証等するかのような内容
      </Text>
      <Text textStyle='p'>
        （9）別途当社が定める広告掲載基準に反する内容
      </Text>
      <Text textStyle='p'>
        ２．当社は、前項に該当すると判断した場合には、その内容及び表示を変更するよう
      </Text>

      <Text as='b'>
        第８条（禁止事項）
      </Text>
      <Box paddingY='7.5px'></Box>
      <Text textStyle='p'>
        １．パートナーは、以下の行為を行ってはならないものとします。
      </Text>
      <Text textStyle='p'>
        （１）前条第1項各号に定める表示を行う行為
      </Text>
      <Text textStyle='p'>
        （２）法令に違反する行為または犯罪行為に関連する行為
      </Text>
      <Text textStyle='p'>
        （３）当社、サービス提供者またはその他の第三者に対する詐欺または脅迫行為
      </Text>
      <Text textStyle='p'>（４）公序良俗に反する行為</Text>
      <Text textStyle='p'>
        （５）当社、サービス提供者またはその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為
      </Text>
      <Text textStyle='p'>
        （６）本サービスを通じ、以下に該当し、または該当すると当社が判断する情報を当社またはユーザその他の第三者に送信すること
      </Text>
      <Text textStyle='p'>
        ・過度に暴力的または残虐な表現を含む情報
      </Text>
      <Text textStyle='p'>
        ・コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報
      </Text>
      <Text textStyle='p'>
        ・当社、本サービスの他の利用者またはその他の第三者の名誉または信用を毀損する表現を含む情報
      </Text>
      <Text textStyle='p'>
        ・過度にわいせつな表現を含む情報
      </Text>
      <Text textStyle='p'>
        ・差別を助長する表現を含む情報
      </Text>
      <Text textStyle='p'>
        ・自殺、自傷行為を助長する表現を含む情報
      </Text>
      <Text textStyle='p'>
        ・薬物の不適切な利用を助長する表現を含む情報
      </Text>
      <Text textStyle='p'>・反社会的な表現を含む情報</Text>
      <Text textStyle='p'>
        ・チェーンメール等の第三者への情報の拡散を求める情報
      </Text>
      <Text textStyle='p'>
        ・他人に不快感を与える表現を含む情報
      </Text>
      <Text textStyle='p'>
        （７）本サービスのネットワークまたはシステム等に過度な負荷をかける行為
      </Text>
      <Text textStyle='p'>
        （８）当社が提供するソフトウェアその他のシステムに対するリバースエンジニアリングその他の解析行為
      </Text>
      <Text textStyle='p'>
        （９）本サービスの運営を妨害するおそれのある行為
      </Text>
      <Text textStyle='p'>
        （１０）当社のネットワークまたはシステム等への不正アクセス
      </Text>
      <Text textStyle='p'>
        （１１）第三者に成りすます行為
      </Text>
      <Text textStyle='p'>
        （１２）本サービスの他の利用者のIDまたはパスワードを利用する行為
      </Text>
      <Text textStyle='p'>
        （１３）本サービスの他の利用者の情報の収集
      </Text>
      <Text textStyle='p'>
        （１４）当社、本サービスの他の利用者またはその他の第三者に不利益、損害、不快感を与える行為
      </Text>
      <Text textStyle='p'>
        （１５）当社ウェブサイト上で掲載する本サービス利用に関するルール（http://.......）に抵触する行為
      </Text>
      <Text textStyle='p'>
        （１６）反社会的勢力等への利益供与
      </Text>
      <Text textStyle='p'>
        （１７）面識のない異性との出会いを目的とした行為
      </Text>
      <Text textStyle='p'>
        （１８）前各号の行為を直接または間接に惹起し、または容易にする行為
      </Text>
      <Text textStyle='p'>
        （１９）前各号の行為を試みること
      </Text>
      <Text textStyle='p'>
        （２０）その他、当社が不適切と判断する行為
      </Text>
      <Text textStyle='p'>
        （２１）その他本規約において禁止されている行為
      </Text>
      <Text textStyle='p'>
        ２．前項の禁止行為により、当社又はサービス提供者を含む第三者が何らかの損害を被った場合、当該行為を行ったパートナーは、損害を負った者に対して、当該損害（旧日本弁護士連合会弁護士報酬基準にて算定した弁護士
      </Text>

      <Text as='b'>
        第９条（パートナーの義務等）
      </Text>
      <Box paddingY='7.5px'></Box>
      <Text textStyle='p'>
        １．パートナーは、アフィリエイトメディアにおいて恣意的なアフィリエイトリンクのクリックが発生しないよう、監視の義務を負うものとします。
      </Text>
      <Text textStyle='p'>
        ２．パートナーは、アフィリエイトリンクが指定されたリンク先へ正しくリンクされているか否かを定期的に確認し、リンク切れまたはリンク先の過誤を発見した場合は、直ちにアフィリエイトリンクの更新あるいは削除を行わなければならないものとします。
      </Text>
      <Text textStyle='p'>
        ３．パートナーは、当社が所定のネットワーク巡回システム等を利用してアフィリエイトメディアによる不正行為等の監視を行うことにつき、異議なく承諾するものとします。
      </Text>

      <Text as='b'>
        第１０条（成果報酬の支払）
      </Text>
      <Box paddingY='7.5px'></Box>
      <Text textStyle='p'>
        １．ユーザが経由アクセスを経て商品等を購入した場合又はユーザが専用クーポンコードを入力して商品等を購入した場合、当社は、パートナーに対し、購入額に当社が定める料率を乗じた成果報酬を付与します。ただし、一部のサービス提供者に関しては、購入額ではなく付与対象成果の件数に応じた定額の成果報酬（以下「固定報酬」といいます）を付与するものとします。（各サービス提供者の成果報酬およびその詳細は、本サービスのウェブサイト内（https://partners.casefinite.jp/）の各サービス提供者が定めるガイドライン、成果報酬に関する詳細ページで告知するものとします)
      </Text>
      <Text textStyle='p'>
        ２．前項の料率および固定報酬は、販売方法、対象となる商品等、アフィリエイトリンクの種類、その他の事由により、当社の定めるところにより異なる料率または固定報酬が適用されることがあります。これら特別の料率および固定報酬については、前項のサイト内に掲示されるか、またはパートナーに対して個別に通知されます。
      </Text>
      <Text textStyle='p'>
        ３．第1項の購入額には、サービス提供者が登録した商品等の代金を基準とし、消費税および送料等の付帯料金は含まれないものとします。
      </Text>
      <Text textStyle='p'>
        ４．第1項の成果報酬の対象となる取引は、モール等における取引のうち、本サービスの対象として当社が指定したものとします。ただし、当社は、その判断により、対象となる取引を制限または追加することがあります。
      </Text>
      <Text textStyle='p'>
        ５．当社は、端末の種類に応じてクッキー等の技術(以下「識別技術」といいます）を使用しており、識別技術によって識別された購入のみが第１項の成果報酬の支払対象となります。また、パートナーは、識別技術について当社の別途定める条件を承認するものとします。
      </Text>
      <Text textStyle='p'>
        ６．パートナーがアフィリエイトリンクのリンク先としたサービス提供者又は商品等と、成果発生行為の対象となるサービス提供者又は商品等とは必ずしも一致しません。パートナーに付与される成果報酬は、当社が別途定める成果承認基準に従い、ユーザによる成果発生行為の対象となる商品等に設定された料率又は固定報酬に基づいて、計算されます。
      </Text>
      <Text textStyle='p'>
        ７．当社は、パートナーに付与する成果報酬を当社が別途定める算出方法に従い算出した上で、別途定める期日に付与します。
      </Text>
      <Text textStyle='p'>
        ８．当社は、パートナーに対し、成果報酬の対象となった購入額、サービス提供者、適用料率および固定報酬を開示しますが、それ以外の情報（ユーザ名など）は開示しません。
      </Text>
      <Text textStyle='p'>
        ９．成果報酬のうち、当社所定の金額を越える支払いについては、当社所定の方法による本人確認手続が完了しているパートナーに支払われるものとします。パートナーが当社所定の期日までに本人確認手続が完了していない場合、パートナーは当社所定の金額を越える支払いの成果報酬を受領する権利を放棄したものとみなし、当社は当該成果報酬を支払義務を負わないものとします。
      </Text>
      <Text textStyle='p'>
        10．当社は、前項の本人確認手続として、パートナーが選択した銀行またはクレジットカード会社（以下「銀行等」と総称します）に対して、当社が保有するパートナーの住所、氏名、生年月日、その他会員に登録されている情報（以下「パートナー情報」と総称します）を提供し、もしくは銀行等からその保有するパートナー情報の提供を受けて、当社が保有するパートナー情報と銀行等が保有するパートナー情報との間に矛盾がないことを照合、確認することができるものとし、パートナーはこのことを了承するものとします。パートナーが選択した銀行等の銀行口座またはクレジットカードが、パートナー本人のものと確認できない場合、ならびに本サービス、その他当社及びその関連会社が提供するサービスに関連して、規約違反等を理由として、サービスの利用制限もしくは利用停止またはID取り消し等の措置（パートナーの資格喪失または停止を含むものとします）を受けた者と、関連性を有すると当社が判断した場合、当社は、当該パートナーの口座登録を拒否するとともに、当該パートナーに対して成果報酬を支払わないものとします。
      </Text>

      <Text as='b'>
        第１１条（権利の非許諾等）
      </Text>
      <Box paddingY='7.5px'></Box>
      <Text textStyle='p'>
        1.
        当社は、本規約等で明文をもって許諾されたもの以外の権利をパートナーに対し付与するものではありません。パートナーは、当社の商号、商標、標章等の利用を許諾された等、本規約等の明文で許諾された以外の権利を付与されたものと、本契約を解釈してはなりません。
      </Text>
      <Text textStyle='p'>
        2.
        パートナーは、アフィリエイトメディアに投稿するデータ等について、自らが投稿その他送信することについての適法な権利を有していること、及び投稿データが第三者の権利を侵害していないことについて、当社に対し表明し、保証するものとします。
      </Text>
      <Text textStyle='p'>
        3.
        パートナーは、当社及び当社から権利を承継しまたは許諾された者に対して著作者人格権を行使しないことに同意するものとします。
      </Text>

      <Text as='b'>
        第１２条（税金及び費用）
      </Text>
      <Box paddingY='7.5px'></Box>
      <Text textStyle='p'>
        アフィリエイトリンクの設置、アフィリエイトメディアの運営および管理、あるいは成果報酬の支払にともない税金や付帯費用が発生する場合には、パートナーがこれらを負担するものとします。
      </Text>

      <Text as='b'>
        第１３条（秘密保持）
      </Text>
      <Box paddingY='7.5px'></Box>
      <Text textStyle='p'>
        パートナーは、本契約期間中または契約終了後にかかわらず、本契約および本契約に関連して知り得たユーザのIPアドレスその他ユーザの特定につながる情報、アフィリエイトリンクの設置または成果報酬の支払のために当社より開示された情報、その他本サービスの利用に際して知り得た本サービス、当社、サービス提供者または利用者に関する情報を、他に開示・漏洩してはならないものとします。
      </Text>

      <Text as='b'>
        第１４条（通知等）
      </Text>
      <Box paddingY='7.5px'></Box>
      <Text textStyle='p'>
        １．当社は、本サービスに関する連絡その他の通知を、原則として、パートナーが登録した電子メールアドレス（以下「登録アドレス」といいます）宛に電子メールを送信する方法または当社が指定するウェブサイト（以下「通知用サイト」といいます）に掲示する方法で行うものとします。さらに電子メールの内容には当社及びその他の企業の情報が含まれる場合もございます。
      </Text>
      <Text textStyle='p'>
        ２．パートナーは、定期的に登録アドレス宛の電子メールおよび通知用サイトを確認し、その内容を閲読するものとし、対応が必要とされる場合には直ちに当社の指示に従い適切な措置（当社への返信が求められている場合には、直ちに返信を行うことを含みます）を講じるものとします。なお、当社からパートナーに対して返信を行うことを求めたにもかかわらず、当社が指定する期間内にパートナーから返信がない場合、当社は次条各項に定める措置を講じることができるものとします。
      </Text>
      <Text textStyle='p'>
        ３．当社が登録事項に含まれるメールアドレスその他の連絡先に連絡または通知を行った場合、ユーザは当該連絡または通知を受領したものとみなします。
      </Text>

      <Text as='b'>
        第１５条（本規約等の違反）
      </Text>
      <Box paddingY='7.5px'></Box>
      <Text textStyle='p'>
        １．当社がパートナーが本規約等に違反していると認めた場合は、当社は事前の通知・催告を行うことなく、次の各号の措置をとることができ、パートナーはこれについて異議を述べることはできません。
      </Text>
      <Text textStyle='p'>
        （１）パートナーの設置したアフィリエイトリンクからのリンクの拒否
      </Text>
      <Text textStyle='p'>
        （２）パートナーの資格喪失または停止
      </Text>
      <Text textStyle='p'>（３）成果報酬の支払停止</Text>
      <Text textStyle='p'>
        （４）その他本サービスの全部または一部の提供の中止
      </Text>
      <Text textStyle='p'>
        ２．当社は、本規約等に違反していると当社が認めるパートナーに対して、前項各号の措置とあわせて、過去に取得した分を含め成果報酬の返金をもとめることができ、この場合パートナーは、その全額について直ちに当社が指示する方法で支払うものとします。
      </Text>
      <Text textStyle='p'>
        ３．前２項の措置を講じたことにより、パートナーに損害または不利益が生じたとしても、当社は一切責任を負わないものとします。
      </Text>

      <Text as='b'>
        第１６条（登録抹消等）
      </Text>
      <Box paddingY='7.5px'></Box>
      <Text textStyle='p'>
        １．当社は、パートナーが、以下の各号のいずれかの事由に該当する場合は、事前に通知または催告することなく、パートナーについて本サービスの利用を一時的に停止し、前条第1項各号の措置をとることができます。
      </Text>
      <Text textStyle='p'>
        （１）本規約のいずれかの条項に違反した場合
      </Text>
      <Text textStyle='p'>
        （２）登録事項に虚偽の事実があることが判明した場合
      </Text>
      <Text textStyle='p'>
        （３）支払停止もしくは支払不能となり、または破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合
      </Text>
      <Text textStyle='p'>
        （４）6ヶ月以上本サービスの利用がない場合
      </Text>
      <Text textStyle='p'>
        （５）当社からの問い合わせその他の回答を求める連絡に対して30日間以上応答がない場合
      </Text>
      <Text textStyle='p'>
        （６）第3条第5項各号に該当する場合
      </Text>
      <Text textStyle='p'>
        （７）その他、当社が本サービスの利用またはユーザとしての登録の継続を適当でないと判断した場合
      </Text>
      <Text textStyle='p'>
        ２．前項各号のいずれかの事由に該当した場合、パートナーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
      </Text>

      <Text as='b'>
        第１７条（退会）
      </Text>
      <Box paddingY='7.5px'></Box>
      <Text textStyle='p'>
        １．パートナーは、当社所定の手続の完了により、本サービスから退会し、自己のパートナーとしての登録を抹消することができます。
      </Text>
      <Text textStyle='p'>
        ２．退会にあたり、当社に対して負っている債務がある場合は、パートナーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
      </Text>
      <Text textStyle='p'>
        ３．退会後のパートナーの情報の取扱いについては、第3条第6項の規定に従うものとします。
      </Text>

      <Text as='b'>
        第１８条（損害賠償等）
      </Text>
      <Box paddingY='7.5px'></Box>
      <Text textStyle='p'>
        １．パートナーは、パートナーによる本サービスの利用に関し、ユーザ、その他の第三者との間でクレーム、紛争等が生じた場合は、すべてパートナーの責任と負担において解決するものとします。
      </Text>
      <Text textStyle='p'>
        ２．パートナーの本サービスの利用が原因で当社に損害が生じたときは、パートナーは損害賠償の義務を負うとともに、その解決のために要した弁護士費用その他一切の諸経費を当社に支払うものとします。
      </Text>
      <Text textStyle='p'>
        ３．当社は、パートナーとユーザ、サービス提供者その他の第三者との間の紛争について、パートナーの同意を得ることなく、当該ユーザ、サービス提供者または第三者に対し当該紛争に関する情報提供その他の援助を行うことができるものとします。
      </Text>
      <Text textStyle='p'>
        ４．当社は、当社の故意または重過失による場合を除き、本サービスに関してユーザが被った損害につき、過去6ヶ月間に当社がユーザに支払った対価の金額を超えて賠償する責任を負わないものとし、また、付随的損害、間接損害、特別損害、将来の損害及び逸失利益にかかる損害については、賠償する責任を負わないものとします。
      </Text>

      <Text as='b'>
        第１９条（プレスリリース等）
      </Text>
      <Box paddingY='7.5px'></Box>
      <Text textStyle='p'>
        パートナーは、当社の事前の書面による承諾がない限り、本サービスの利用により実現する機能・サービス等についてプレスリリースその他広告等を公表してはならないものとします。
      </Text>

      <Text as='b'>
        第２０条（仕様の変更）
      </Text>
      <Box paddingY='7.5px'></Box>
      <Text textStyle='p'>
        当社は、本サービスの仕様の全部または一部をいつでも変更することができるものとします。また、当社は、当該仕様変更に関連してパートナーに生じた損害につき一切責任を負わないものとします。
      </Text>

      <Text as='b'>
        第２１条（本サービスの停止等）
      </Text>
      <Box paddingY='7.5px'></Box>
      <Text textStyle='p'>
        １．当社は、以下のいずれかに該当する場合には、パートナーに事前に通知することなく、本サービスの全部または一部の提供を停止または中断することができるものとします。
      </Text>
      <Text textStyle='p'>
        （１）本サービスに係るコンピュータ・システムの点検または保守作業を緊急に行う場合
      </Text>
      <Text textStyle='p'>
        （２）コンピュータ、通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキング等により本サービスの運営ができなくなった場合
      </Text>
      <Text textStyle='p'>
        （３）地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合
      </Text>
      <Text textStyle='p'>
        （４）当社と第三者との間で紛争が発生した場合
      </Text>
      <Text textStyle='p'>
        （５）その他、当社が停止または中断を必要と判断した場合
      </Text>
      <Text textStyle='p'>
        ２．前項に基づき、本サービスの全部または一部の提供を停止または中断の措置をとった場合においても、当社は、パートナーに対し、利用料金の返金、損害賠償等の一切の責任を負わないものとします。
      </Text>

      <Text as='b'>
        第２２条（本契約の終了等）
      </Text>
      <Box paddingY='7.5px'></Box>
      <Text textStyle='p'>
        １．パートナーが退会または会員資格の取消等により会員資格を喪失したときは、本契約は会員資格の喪失時をもって終了するものとします。
      </Text>
      <Text textStyle='p'>
        ２．当社は、全てのパートナーまたは特定のパートナーについて、理由のいかんを問わず、いつでも本サービスの提供を中断または終了させることができるものとします。また、当社は当該中断または終了に関してパートナーに生じた損害につき一切責任を負わないものとします。
      </Text>
      <Text textStyle='p'>
        ３．本契約が終了した場合は、パートナーはアフィリエイトメディアからアフィリエイトリンクを直ちに削除するものとします。
      </Text>

      <Text as='b'>
        第２３条（免責）
      </Text>
      <Box paddingY='7.5px'></Box>
      <Text textStyle='p'>
        通信回線やコンピュータなどの障害によるシステムの中断・遅滞・中止・データの消失、経由アクセス識別に関する障害、データへの不正アクセスにより生じた損害、その他本サービスに関してパートナーに生じた損害について、当社は一切責任を負わないものとします。
      </Text>

      <Text as='b'>
        第２４条（本規約の改定）
      </Text>
      <Box paddingY='7.5px'></Box>
      <Text textStyle='p'>
        当社は、本規約等を任意に改定できるものとします。本規約を変更する場合、変更後の本規約の施行時期及び内容を当社ウェブサイト上での掲示その他の適切な方法により周知し、またはユーザに通知します。ただし、法令上ユーザの同意が必要となるような内容の変更の場合は、当社所定の方法でユーザの同意を得るものとします。
      </Text>

      <Text as='b'>
        第２５条（反社会的勢力の排除）
      </Text>
      <Box paddingY='7.5px'></Box>
      <Text textStyle='p'>
        当社は、反社会的勢力による本サービスの利用を禁止します。当社は、パートナーが反社会勢力等に該当する又は該当するおそれがあると判断した場合、事前にパートナーに通知することなく、本サービスの提供を停止することができます。当社は、この提供停止によってパートナーに生じた損害や不利益について、責任を負いません。
      </Text>

      <Text as='b'>
        第２６条（分離可能性）
      </Text>
      <Box paddingY='7.5px'></Box>
      <Text textStyle='p'>
        本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
      </Text>

      <Text as='b'>
        第２７条（準拠法及び管轄裁判所）
      </Text>
      <Box paddingY='7.5px'></Box>
      <Text textStyle='p'>
        １．本規約及びサービス利用契約の準拠法は日本法とします。
      </Text>
      <Text textStyle='p'>
        ２．本規約またはサービス利用契約に起因し、または関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
      </Text>

      <Text as='b'>
        第２８条（存続条項）
      </Text>
      <Box paddingY='7.5px'></Box>
      <Text textStyle='p'>
        理由の如何を問わず、本規約に基づく当社とパートナー間の契約が終了した場合であっても、第8条、第11条、第13条、第14条、第18条、第19条、第23条、第25条、第26条、第27条及び本条の規定は有効に存続するものとする。
      </Text>

      <Text as='b'>
        【2023年 9月 13日制定】
      </Text>
      <Box paddingY='7.5px'></Box>
    </>
  )
}

export default TNC
