import { proxy } from 'valtio'
import { User } from '../../network/services/auth'

const initialValue: {
  token?: string | null
  profile?: User | null
} = {
  token: localStorage.getItem('token'),
  profile: localStorage.getItem('profile')
    ? JSON.parse(localStorage.getItem('profile')!)
    : null,
}

const authStore = proxy({
  state: initialValue,
  reset() {
    authStore.state = { ...initialValue }
  },
})

export const checkAuthorization = () => {
  const token = getToken()
  const profile = getProfile()
  if (token != null) {
    login({ token, profile })
    return true
  } else {
    console.log('not authenticated')
    return false
  }
}

export const login = ({ token, profile }: { token: string; profile: User }) => {
  if (token) {
    localStorage.setItem('token', token)
    localStorage.setItem('profile', JSON.stringify(profile))
    authStore.state.token = token
    authStore.state.profile = profile
  } else {
    console.log('login error, missing token')
    // login error
  }
}

export const logout = () => {
  clearToken()
  clearProfile()

  authStore.state.token = null
  authStore.state.profile = null

  window.location.reload()
}

export function getToken() {
  try {
    const token = localStorage.getItem('token')
    return token
  } catch (err) {
    clearToken()
    return null
  }
}

export function getProfile() {
  try {
    const profile = localStorage.getItem('profile')
    if (!profile) return null
    return JSON.parse(profile)
  } catch (err) {
    clearProfile()
    return null
  }
}

export function clearToken() {
  localStorage.removeItem('token')
}

export function clearProfile() {
  localStorage.removeItem('profile')
}

export default authStore
