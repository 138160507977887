import { User } from './auth'
import client, { IDataResponse } from '../request'
export type AffiliateInvitation = {
  id: number
  token: string
  email: string
  name: string
  accepted: boolean
  expires_at: Date
  created_at: Date
  updated_at: Date
}

export type AffiliateInvitationCreateState = {
  is_test?: boolean
  email: string
  name: string
  campaign_id?: number
}

//swr
const getAll = `/admin/invitations`

const get = (id: string | number) => {
  return `/admin/invitations/${id}`
}

const create = (data: AffiliateInvitationCreateState) => {
  return client.post(`/admin/invitations/`, data)
}

const toRow = (data: IDataResponse<AffiliateInvitation>) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id,
      }
    })
  }

  return []
}

const toPaginate = (data: IDataResponse<AffiliateInvitation>) => {
  return {
    total: data?.meta?.total ?? 0,
  }
}

const AffiliateInvitationService = {
  create,
  getAll,
  get,
  toRow,
  toPaginate,
}

export default AffiliateInvitationService
