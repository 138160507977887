import React from 'react'
import logo from './logo.svg'
import './App.css'
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom'
import Login from './routes/Login'
import ErrorPage from './pages/ErrorPage'
import RequireAuth from './layouts/RequireAuth'
import AuthLayout from './layouts/AuthLayout'
import theme from './theme'
import RequireAdmin from './layouts/RequireAdmin'
import AdminAffiliatesPage from './routes/AdminRoutes/AdminAffiliates'
import AdminAffiliatePage from './routes/AdminRoutes/AdminAffiliateDetail'
import AdminCampaignsPage from './routes/AdminRoutes/AdminCampaigns'
import AdminCampaignPage from './routes/AdminRoutes/AdminCampaign'
import InvitationPage from './routes/AdminRoutes/Invitation'
import OrdersPage from './routes/AdminRoutes/Orders'
import ProductsPage from './routes/AdminRoutes/Products'
import AdminWithdrawsPage from './routes/AdminRoutes/AdminWithdraws'
import AdminWithdrawPage from './routes/AdminRoutes/AdminWithdraw'
import AffiliateCampaignsPage from './routes/AffiliateRoutes/AffiliateCampaigns'
import AffiliateCampaignPage from './routes/AffiliateRoutes/AffiliateCampaign'
import AffiliateWithdrawsPage from './routes/AffiliateRoutes/AffiliateWithdraws'
import AffiliateWithdrawPage from './routes/AffiliateRoutes/AffiliateWithdraw'
import AffiliateTransactionsPage from './routes/AffiliateRoutes/AffiliateTransactions'
import AffiliateSignUp from './routes/AffiliateSignUp'
import OrderPage from './routes/AdminRoutes/Order'
import AffiliateCommissionsPage from './routes/AffiliateRoutes/AffiliateCommissions'
import AffiliateDashboardPage from './routes/AffiliateRoutes/AffiliateDashboardPage'

const router = createBrowserRouter([
  // authenticated layout
  {
    path: 'admin/dashboard',
    element: (
      <RequireAuth>
        <AuthLayout />
      </RequireAuth>
    ),

    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <RequireAdmin />,
        children: [
          {
            path: 'affiliates',
            children: [
              {
                path: '',
                element: <AdminAffiliatesPage />,
              },
              {
                path: ':id',
                element: <AdminAffiliatePage />,
              },
            ],
          },
          {
            path: 'campaigns',
            children: [
              {
                path: '',
                element: <AdminCampaignsPage />,
              },
              {
                path: ':id',
                element: <AdminCampaignPage />,
              },
            ],
          },
          {
            path: 'orders',
            children: [
              {
                path: '',
                element: <OrdersPage />,
              },
              {
                path: ':id',
                element: <OrderPage />,
              },
            ],
          },
          {
            path: 'withdraws',
            children: [
              {
                path: '',
                element: <AdminWithdrawsPage />,
              },
              {
                path: ':id',
                element: <AdminWithdrawPage />,
              },
            ],
          },
          {
            path: 'products',
            children: [
              {
                path: '',
                element: <ProductsPage />,
              },
              {
                path: ':id',
                element: <InvitationPage />,
              },
            ],
          },

          {
            path: 'invitations',
            children: [
              {
                path: '',
                element: <AdminCampaignsPage />,
              },
              {
                path: ':id',
                element: <InvitationPage />,
              },
            ],
          },
          {
            //for invalid path, redirect to /
            path: ':any',
            element: <Navigate to='/' />,
          },
        ],
      },
    ],
  },

  {
    path: 'affiliate/dashboard',
    element: (
      <RequireAuth>
        <AuthLayout />
      </RequireAuth>
    ),

    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <AffiliateDashboardPage />,
      },
      {
        path: 'campaigns',
        children: [
          {
            path: '',
            element: <AffiliateCampaignsPage />,
          },
          {
            path: ':id',
            element: <AffiliateCampaignPage />,
          },
        ],
      },
      {
        path: 'withdraws',
        children: [
          {
            path: '',
            element: <AffiliateWithdrawsPage />,
          },
          {
            path: ':id',
            element: <AffiliateWithdrawPage />,
          },
        ],
      },
      {
        path: 'transactions',
        children: [
          {
            path: '',
            element: <AffiliateTransactionsPage />,
          },
        ],
      },
      {
        path: 'commissions',
        children: [
          {
            path: '',
            element: <AffiliateCommissionsPage />,
          },
        ],
      },
      {
        path: '',
        children: [
          {
            //for invalid path, redirect to /
            path: ':any',
            element: <Navigate to='/' />,
          },
        ],
      },
    ],
  },

  // public layout
  {
    path: '',
    children: [
      {
        path: 'affiliate-signup',
        element: <AffiliateSignUp />,
      },
      {
        path: '',
        element: <Login />,
      },
    ],
    errorElement: <ErrorPage />,
  },
])

function App() {
  return (
    <RouterProvider router={router} />
  )
}

export default App
