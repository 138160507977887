import { Button, Card, CardBody, Spinner } from '@chakra-ui/react'
import Empty from '../../../components/Empty'
import PageHeader from '../../../components/PageHeader'
import useSWR from 'swr'
import { useSnapshot } from 'valtio'

import tableStore from '../../../lib/store/admin_affiliate_table'
import { serialize } from '../../../network/request'
import AdminAffiliateTable from './AdminAffiliateTable'
import { useNavigate, useParams } from 'react-router-dom'
import AdminAffiliateService from '../../../network/services/adminAffiliate'
const AdminAffiliatesPage = () => {
  const navigate = useNavigate()
  const primaryAction = (
    <Button
      key='add'
      onClick={() => {
        navigate('/admin/dashboard/invitations/new')
      }}
    >
      アフィリエイトを招待する
    </Button>
  )

  return (
    <PageHeader title='アフィリエイト管理' extra={[primaryAction]}>
      <Card>
        <CardBody>
          <AdminAffiliatesTableWrapper />
        </CardBody>
      </Card>
    </PageHeader>
  )
}

const AdminAffiliatesTableWrapper = () => {
  const { state } = useSnapshot(tableStore)

  // use this to preload
  const { data: response, error } = useSWR(
    serialize(AdminAffiliateService.getAll, {
      page: 1,
      limit: state.pageSize,
    })
  )

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response) {
    return <Spinner />
  }

  const { total } = AdminAffiliateService.toPaginate(response)

  return (
    <>
      <AdminAffiliateTable total={total} />
      {/* preload next page */}
      {state.currentPage * state.pageSize < total && (
        <div style={{ display: 'none' }}>
          <AdminAffiliateTable
            total={total}
            overridePage={state.currentPage + 1}
          />
        </div>
      )}
    </>
  )
}

export default AdminAffiliatesPage
