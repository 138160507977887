import { FC, useEffect, useState, useMemo } from 'react'
import { ColumnDef, SortingState } from '@tanstack/react-table'
import { Text, Grid, GridItem } from '@chakra-ui/react'
import CustomTable from '../../../components/CustomTable'
import Empty from '../../../components/Empty'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'

import tableStore from '../../../lib/store/affiliate_commission_table'
import TableFilter, {
  IDropDown,
  ITableFilter,
} from '../../../components/TableFilter'
import { IDataResponse, serialize } from '../../../network/request'
import Gaps from '../../../components/Gaps'
import { CommissionSummary } from '../../../network/services/adminAffiliate'
import AffiliateCommissionService from '../../../network/services/affiliateCommission'
import ReadOnlyFormItem from '../../../components/ReadOnlyFormItem'
import toJapaneseDate from '../../../functions/toJapaneseDate'
import {
  Commission,
  commissionStatusText,
} from '../../../network/services/adminCommission'

const AffiliateCommissionTable: FC<{
  overridePage?: number
}> = ({ overridePage }) => {
  const navigate = useNavigate()
  const { state } = useSnapshot(tableStore)
  const [currentFilters, setCurrentFilters] = useState<any>(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([])
  const [visible, setVisible] = useState(false)

  const setPage = (page: number) => tableStore.state.currentPage = page
  const setSize = (size: number) => tableStore.state.pageSize = size

  const [sorting, setSorting] = useState<SortingState>([])
  if (sorting.length > 0) 
    tableStore.state.sort = `${sorting[0].id}:${sorting[0].desc ? 'desc': 'asc'}`

  const { data: response } = useSWR<IDataResponse<Commission>>(
    serialize(AffiliateCommissionService.getAll, {
      page: overridePage ?? state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters,
    })
  )
  const rows = response ? AffiliateCommissionService.toRow(response) : []
  const lastPage = response?.meta?.last_page ? response?.meta?.last_page : 1

  const rowSelection = {
    selectedRowKeys,
    onChange: (keySelected: any[]) => {
      setSelectedRowKeys(keySelected)
    },
  }

  const columns = useMemo<ColumnDef<Commission, any>[]>(
    () => [
      {
        id: 'product_full_name',
        accessorKey: 'product_full_name',
        header: '商品',
        cell: (info) => 
          `${info.getValue()}　【${info.row.original.quantity}個】`,
        enableSorting: false,
      },
      {
        id: 'status',
        accessorKey: 'status',
        header: 'ステータス',
        cell: (info) => (
          <Text style={{ 
            color: commissionStatusText[info.getValue() as keyof typeof commissionStatusText].color
          }}>
            {commissionStatusText[info.getValue() as keyof typeof commissionStatusText].text}
          </Text>
        ),
        enableSorting: false,
      },
      {
        id: 'should_issue_at',
        accessorKey: 'should_issue_at',
        header: '発行予定日',
        cell: (info) => 
          info.getValue()
            ? toJapaneseDate(new Date(info.getValue()))
            : '-',
        enableSorting: false,
      },
      {
        id: 'issued_at',
        accessorKey: 'issued_at',
        header: '発行日',
        cell: (info) => 
          info.getValue() ? toJapaneseDate(new Date(info.getValue())) : '-',
        enableSorting: false,
      },
      {
        id: 'amount',
        accessorKey: 'amount',
        header: '金額',
        cell: (info) => (
          <Text style={{ color: info.getValue() > 0 ? 'green' : 'red' }}>
            {info.getValue() > 0 ? '+' : '-'}￥{Math.abs(info.getValue())}
          </Text>
        ),
      },
    ],
    []
  )

  // content={
  //   initialValue.variants.length > 0
  //     ? initialValue.variants.map((item) => item.name).join(',')
  //     : '-'
  // }

  const filterColumns: ITableFilter[] = [
    { key: 'title', name: 'タイトル' },
    { key: 'description', name: '説明' },
  ]

  filterColumns.forEach((item) => {
    if (
      tableStore.state?.filters &&
      Object.keys(tableStore.state.filters).includes(item.key)
    ) {
      item.default_value = tableStore.state.filters[item.key]
      item.default = true
    }
  })

  const filterDropDowns: IDropDown[] = [
    {
      default: '',
      key: 'status',
      width: 120,
      value: [
        {
          key: 'pending',
          name: '審査中',
        },
        {
          key: 'approved',
          name: '承認された',
        },
        {
          key: 'rejected',
          name: '拒否された',
        },
        {
          key: '',
          name: '全部',
        },
      ],
    },
  ]

  filterDropDowns.forEach((item) => {
    if (
      tableStore.state?.filters &&
      Object.keys(tableStore.state.filters).includes(item.key)
    ) {
      const currentValue = tableStore.state.filters[item.key]
      item['default'] = currentValue
    }
  })

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state.filters = { ...currentFilters }
      tableStore.state.currentPage = 1
    }
  }, [currentFilters])

  const handleMenuClick = (e: any) => {
    setVisible(true)
    // setModalType(e.key)
  }

  const batchDeleteRows = async () => {
    // try {
    //   for (const id of selectedRowKeys) {
    //     const { data } = await ReviewService.remove(id)
    //     if (data?.success === true) {
    //       message.success(`Delete source success`)
    //     } else {
    //       message.error(`Delete source ${id} failed`)
    //     }
    //     tableStore.state.refresh = Math.random()
    //   }
    //   setVisible(false)
    // } catch (e) {
    //   message.error({ content: e.message, className: 'message-error' })
    // }
  }

  // const ActionModal: FC = () => {
  //   return (
  //     <Modal
  //       title={`Remove ${selectedRowKeys.length} reviews`}
  //       open={visible}
  //       onOk={() => {
  //         switch(modalType){
  //           case 'delete':
  //             batchDeleteRows()
  //             break;
  //           case 'publish':

  //         }
  //       }}
  //       onCancel={() => {
  //         setVisible(false)
  //       }}
  //       okText='Delete'
  //       cancelText='Cancel'
  //     >
  //       This can't be undone
  //     </Modal>
  //   )
  // }

  return (
    <>
      {/* <ActionModal /> */}

      <Grid templateColumns='repeat(3, 1fr)' gap={2}>
        <GridItem>
        {/* <Col span={12}>
          {selectedRowKeys?.length > 0 ? (
            <Dropdown
              menu={{
                items: [
                  {
                    label: 'Delete',
                    onClick: handleMenuClick,
                    key: 'delete',
                  },
                ],
              }}
              trigger={['click']}
            >
              <Button type='primary'>
                More Actions <CaretDownOutlined />
              </Button>
            </Dropdown>
          ) : (
            <></>
          )}
        </Col> */}
        </GridItem>
        <GridItem colSpan={2}>
          <TableFilter
            dropdowns={filterDropDowns}
            columns={filterColumns}
            hasDate={false}
            setCurrentFilters={setCurrentFilters}
          />
        </GridItem>
      </Grid>

      <Gaps level={3} />

      {rows?.length > 0 ? (
        <CustomTable
          {...{
            data: rows,
            columns,
            lastPage,
            page: state.currentPage,
            setPage,
            size: state.pageSize,
            setSize,
            sorting,
            setSorting,
          }}
        />
      ) : (
        <Empty description={'データが見つかりませんでした'} />
      )}
    </>
  )
}

export default AffiliateCommissionTable
