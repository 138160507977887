import { defineStyleConfig } from "@chakra-ui/react"

const Button = defineStyleConfig({
  baseStyle: {
    borderRadius: '8px',
  },
  variants: {
    outline: {
      border: '2px solid',
      borderColor: 'brand.primary',
      color: 'brand.primary',
    },
    solid: {
      bg: 'brand.primary',
      color: 'white',
      _hover: {
        bg: 'brand.hover',
      }
    },
    ghost: {
    },
    copyable: {
      border: '1px solid #E8EAED',
      height: 'auto',
      marginLeft: '6px',
      paddingY: '2px !important',
    },
    select: {
      border: '1px solid',
      borderColor: 'gray.200',
      bg: 'none',
      color: 'black',
      fontWeight: 'normal',
      textAlign: 'left',
    }
  },
  defaultProps: {
    size: 'sm'
  }
})

export default Button
