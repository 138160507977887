import { useNavigate, useParams } from 'react-router-dom'
import { 
  Button, 
  Spinner, 
} from '@chakra-ui/react'
import PageHeader from '../../../components/PageHeader'
import Empty from '../../../components/Empty'
import useSWR from 'swr'
import { useSnapshot } from 'valtio'
import { Dispatch, SetStateAction, useRef, useState } from 'react'
import { IDataResponse, serialize } from '../../../network/request'
import AffiliateWithdrawDetail from './AffiliateWithdrawDetail'
import AffiliateWithdrawService, {
  WithdrawCreateState,
} from '../../../network/services/affiliateWithdraw'
import { Withdraw } from '../../../network/services/adminWithdraw'

const AffiliateWithdrawPage = () => {
  const navigate = useNavigate()

  const formRef = useRef() as any

  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  // use this to preload
  const {
    data: response,
    error,
    mutate,
  } = useSWR<Withdraw>(id !== 'new' ? AffiliateWithdrawService.get(id!) : null)

  if (error) {
    console.log(error)
    return <Empty description={`エラーが発生しました。${error?.message}`} />
  }

  if (!response && id !== 'new') {
    return (
      <PageHeader>
        <Spinner />
      </PageHeader>
    )
  }

  const refresh = () => {
    if (id != null) {
      if (id === 'new') {
      } else {
        mutate()
      }
    }
  }

  const saveAction =
    id == 'new' ? (
      <Button
        key={'申し込む'}
        onClick={() => {
          if(formRef.current)
            formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
        }}
        isLoading={isLoading}
      >
        申し込む
      </Button>
    ) : null

  return (
    <PageHeader
      title={'振込申請'}
      onBack={() => navigate('/affiliate/dashboard/withdraws')}
      extra={[saveAction]}
    >
      <AffiliateWithdrawDetail
        formRef={formRef}
        initialValue={response}
        refresh={refresh}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </PageHeader>
  )
}

export default AffiliateWithdrawPage
