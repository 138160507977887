import { 
  Card, 
  CardBody, 
  Spinner,
} from '@chakra-ui/react'
import PageHeader from '../../../components/PageHeader'
import Empty from '../../../components/Empty'
import useSWR from 'swr'
import { useSnapshot } from 'valtio'
import tableStore from '../../../lib/store/admin_withdraw_table'
import { serialize } from '../../../network/request'
import { useNavigate, useParams } from 'react-router-dom'
import AdminWithdrawService from '../../../network/services/adminWithdraw'
import AdminWithdrawTable from './AdminWithdrawTable'
const AdminWithdrawsPage = () => {
  const navigate = useNavigate()
  //   const primaryAction = (
  //     <Button
  //       key='add'
  //       type='primary'
  //       onClick={() => {
  //         navigate('/admin/dashboard/invitations/new')
  //       }}
  //     >
  //       アフィリエイトを招待する
  //     </Button>
  //   )

  return (
    <PageHeader title='振込申請'>
      <Card>
        <CardBody>
          <AdminWithdrawTableWrapper />
        </CardBody>
      </Card>
    </PageHeader>
  )
}

const AdminWithdrawTableWrapper = () => {
  const { state } = useSnapshot(tableStore)

  // use this to preload
  const { data: response, error } = useSWR(
    serialize(AdminWithdrawService.getAll, {
      page: 1,
      limit: state.pageSize,
    })
  )

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response) {
    return <Spinner />
  }

  const { total } = AdminWithdrawService.toPaginate(response)

  return (
    <>
      <AdminWithdrawTable total={total} />
      {/* preload next page */}
      {state.currentPage * state.pageSize < total && (
        <div style={{ display: 'none' }}>
          <AdminWithdrawTable
            total={total}
            overridePage={state.currentPage + 1}
          />
        </div>
      )}
    </>
  )
}

export default AdminWithdrawsPage
