import { IDataResponse } from '../request'
import { Affiliate } from './adminAffiliate'
import { Order } from './order'
import { Transaction } from './transaction'

export const commissionStatusText = {
  pending: {
    color: 'orange',
    text: '確認中',
  },
  issued: {
    color: 'green',
    text: '発行済み',
  },
  failed: {
    color: 'red',
    text: 'エラー',
  },
  refunded: {
    color: 'grey',
    text: '返金',
  },
  cancelled: {
    color: 'red',
    text: 'キャンセル',
  },
  confirmed: {
    color: 'green',
    text: '発行済み',
  },
}

export interface Commission {
  id: number
  order_id: number
  line_item_id: string
  product_full_name: string
  sku?: string
  price: number
  total_discount: number
  total_price: number
  quantity: number
  rate?: number
  fixed_amount?: number
  affiliate_id: number
  status:
    | 'pending'
    | 'issued'
    | 'failed'
    | 'refunded'
    | 'cancelled'
    | 'confirmed'
  amount: number
  currency: string
  should_issue_at?: Date
  issued_at?: Date
  created_at: Date
  updated_at: Date
  txn: Transaction
  order?: Order
  affiliate?: Affiliate
}
//swr
const getAll = `/admin/commissions`

const get = (id: string | number) => {
  return `/admin/commissions/${id}`
}

// const remove = (appClientId: string, id: string | number) => {
//   return client.delete(`/admin/affiliates/${id}`)
// }

const toRow = (data: IDataResponse<Commission>) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id,
      }
    })
  }

  return []
}

const toPaginate = (data: IDataResponse<Commission>) => {
  return {
    total: data?.meta?.total ?? 0,
  }
}

const AdminCommissionService = {
  getAll,
  get,
  toRow,
  toPaginate,
}

export default AdminCommissionService
