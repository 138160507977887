import React, { useState, useRef, useEffect } from 'react'
import {
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverAnchor,
  PopoverContent,
  PopoverBody,
  List,
  ListItem,
  Box,
  Text,
  useOutsideClick,
  Icon,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import Empty from './Empty'
import { UseFormRegister } from 'react-hook-form'

const SearchableSelect = ({ 
  options, 
  placeholder, 
  name, 
  rules = {}, 
  register,
  setValue,
  defaultValue,
  size,
  isDisabled,
  onSearch,
} : {
  options: any,
  placeholder?: string,
  name: string,
  rules?: any,
  register?: UseFormRegister<any>,
  setValue?: (name: any, option: any) => any,
  defaultValue?: string,
  size?: string,
  isDisabled?: boolean,
  onSearch?: (searchText: string) => any,
}) => {
  const ref = React.useRef() as any
  const inputRef = useRef() as any
  const popoverRef = useRef() as any

  const [searchText, setSearchText] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const filteredOptions = Array.from(
      new Set(options.map((option: { label: any }) => option.label))
    ).map(label => options.find((option: { label: unknown }) => option.label === label))
    .filter((option: { label: string }) =>
      option.label.toLowerCase().includes(searchText.toLowerCase())
    )

  useOutsideClick({
    ref: ref,
    handler: () => setIsOpen(false),
  })

  const handleOnInputChange = (e: any) => {
    if (onSearch) onSearch(e.target.value)
    setSearchText(e.target.value)
  }

  useEffect(() => {
    if (inputRef.current && popoverRef.current) {
      popoverRef.current.style.width = inputRef.current.offsetWidth + 'px';
    }
  }, [searchText, isOpen])

  useEffect(() => {
    if (setValue) setValue(name, searchText)
  }, [isOpen])

  return (
    <Popover
      isOpen={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      autoFocus={false}
    >
      <PopoverAnchor>
        <Box className='chakra-select__wrapper'>
          <InputGroup onClick={() => isDisabled ? null : setIsOpen(true)}>
            {register ? (
              <Input
                isDisabled={isDisabled}
                placeholder={placeholder}
                {...register(name, {
                  ...rules
                })}
                value={searchText}
                onChange={handleOnInputChange}
                defaultValue={defaultValue}
                size={size}
                ref={(node) => {
                  ref.current = node
                  inputRef.current = node
                }}
              />
            ) : (
              <Input
                isDisabled={isDisabled}
                placeholder={placeholder}
                value={searchText}
                onChange={handleOnInputChange}
                defaultValue={defaultValue}
                size={size}
                ref={(node) => {
                  ref.current = node
                  inputRef.current = node
                }}
              />
            )}
            <InputRightElement h='full' marginRight='4px'>
              <Icon as={ChevronDownIcon} color={isDisabled ? 'gray' : 'black'} />
            </InputRightElement>
          </InputGroup>
        </Box>
      </PopoverAnchor>
      <PopoverContent ref={popoverRef} minW='fit-content'>
        <PopoverBody p={0}>
          <List overflowY='auto' maxH='200px'>
            {filteredOptions.length > 0 && filteredOptions.map((option: { value: string, label: string }) => (
              <ListItem
                key={option.value}
                cursor='pointer'
                p={2}
                _hover={{ bg: 'gray.200' }}
                onClick={() => {
                  setSearchText(option.label)
                  if (setValue) setValue(name, option.value)
                  setIsOpen(false)
                }}
              >
                <Text>{option.label}</Text>
              </ListItem>
            ))}
            {filteredOptions.length === 0 && <Empty description='No data' />}
          </List>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default SearchableSelect