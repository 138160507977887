import { defineStyleConfig } from "@chakra-ui/react"

const Input = defineStyleConfig({
  baseStyle: {
    field: {
      borderRadius: '8px',
      _focus: {
        borderColor: 'brand.focusBorder !important',
        boxShadow: '0 0 0 4px var(--chakra-colors-brand-focusShadow) !important'
      },
    }, 
    addon: {
      borderRadius: '8px',
    }
  },
  defaultProps: {
    size: 'sm',
  }
})

export default Input
