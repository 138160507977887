const toJapaneseDate = (date: Date) => {
  console.log(date)
  const weekdays = ['月', '火', '水', '木', '金', '土', '日']
  return `${date.getFullYear()}年${
    date.getMonth() + 1
  }月${date.getDate()}日 （${
    weekdays[date.getDay()]
  }） ${date.getHours()}時${date.getMinutes()}分 `
}

export default toJapaneseDate
