import { useNavigate, useParams } from 'react-router-dom'
import { Button, Spinner } from '@chakra-ui/react'
import Empty from '../../../components/Empty'
import PageHeader from '../../../components/PageHeader'
import useSWR from 'swr'
import { useState, useRef } from 'react'
import InvitationDetail from './InvitationDetail'
import AffiliateInvitationService, {
  AffiliateInvitationCreateState,
} from '../../../network/services/affiliateInvitaiton'

const InvitationPage = () => {
  const navigate = useNavigate()
  const formRef = useRef() as any

  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)

  // use this to preload
  const {
    data: response,
    error,
    mutate,
  } = useSWR(id !== 'new' ? AffiliateInvitationService.get(id!) : null)

  if (error) {
    console.log(error)
    return <Empty description={`エラーが発生しました。${error?.message}`} />
  }

  if (!response && id !== 'new') {
    return (
      <PageHeader>
        <Spinner />
      </PageHeader>
    )
  }

  const refresh = () => {
    if (id != null) {
      if (id === 'new') {
      } else {
        mutate()
      }
    }
  }

  const saveAction = (
    <Button
      key={'招待する'}
      onClick={() => {
        if(formRef.current)
          formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
      }}
      isLoading={isLoading}
    >
      招待する
    </Button>
  )

  // const discardAction = (
  //   <Button
  //     key={'削除する'}
  //     type='default'
  //     // onClick={async () => {
  //     //   await CollaboratorService.remove(appClientId!, id!)
  //     //   navigate('/dashboard/collaborators')
  //     // }}
  //   >
  //     Discard
  //   </Button>
  // )

  return (
    <PageHeader
      title={'アフィリエイト招待'}
      onBack={() => navigate('/admin/dashboard/invitations')}
      extra={[
        saveAction,
        //discardAction
      ]}
    >
      <InvitationDetail
        formRef={formRef}
        initialValue={response}
        refresh={refresh}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </PageHeader>
  )
}

export default InvitationPage
