import React, { FC } from 'react'
import { useSnapshot } from 'valtio'
import { 
  Flex,
  Box,
  VStack,
  HStack,
  Text,
  Button,
  Spacer,
  Center,
  Icon,
  IconButton,
} from '@chakra-ui/react'
import { 
  ChevronDoubleRightIcon, 
  ChevronDoubleLeftIcon, 
  ArrowRightOnRectangleIcon 
} from '@heroicons/react/24/outline'
import { motion } from "framer-motion"
import { Link, Outlet, useLocation } from 'react-router-dom'

import globalStore from '../lib/store/global'
import authStore, { logout } from '../lib/store/auth'
import useSidebarMenu from '../hooks/useSidebarMenu'

const AuthLayout: FC = () => {
  const { collapsed } = useSnapshot(globalStore)
  const options = useSidebarMenu()
  const { state } = useSnapshot(authStore)
  const admin = state?.profile?.admin ?? null
  const { pathname } = useLocation()

  const toggle = () => {
    globalStore.collapsed = !collapsed
  }

  const getIsCurrentRoute = (route: string) => {
    if (route.endsWith('dashboard'))
      return pathname.endsWith('dashboard')
    return pathname.startsWith(route)
  }

  return (
    <HStack spacing='0' align='start'>
      <motion.div
        style={{
          height: '100vh',
          borderRight: '1px',
          borderRightColor: '#E9E9EC',
          padding:'16px 8px',
          alignContent: 'start',
          position: 'sticky',
          top: 0
        }}
        initial={false}
        animate={{
          width: !collapsed ? 240 : 75
        }}
      >
        <Flex direction='column' h='full'>
          <VStack>
            <Flex direction='row' w='100%' align='center'>
              <Link to={`/${admin ? 'admin' : 'affiliate'}/dashboard`}>
                {collapsed ? (
                  <img
                    src='/logo512.png'
                    alt=''
                    style={{
                      objectFit: 'contain',
                      marginLeft: '14px'
                    }}
                    width={20}
                  />
                ) : (
                  <img
                    src='/casefinite_logo.svg'
                    alt=''
                    style={{
                      objectFit: 'contain',
                      marginLeft: '16px'
                    }}
                    width={100}
                  />
                )}
              </Link>
              <Spacer />
              <IconButton
                isRound={true}
                variant='ghost'
                aria-label='Toggle Sidebar'
                size='xs'
                icon={collapsed ? <ChevronDoubleRightIcon /> : <ChevronDoubleLeftIcon />}
                onClick={() => toggle()}
              />
            </Flex>

            {options.map((option) => (
              <Button 
                key={option.route} 
                as='a' 
                href={`${option.route}`}
                leftIcon={<Icon as={option.icon} />}
                variant='ghost'
                fontWeight='normal'
                w='full'
                justifyContent='left'
                bg={getIsCurrentRoute(option.route) ? '#E9E9EC' : 'white'}
              >
                {collapsed ? null : <span>{option.name}</span>}
              </Button>
            ))}
          </VStack>
          <Spacer />
          <Button 
            leftIcon={<Icon as={ArrowRightOnRectangleIcon} />}
            variant='ghost'
            fontWeight='normal'
            w='full'
            justifyContent='left'
            onClick={logout}
          >
            {collapsed ? null : <span>ログアウト</span>}
          </Button>
        </Flex>
      </motion.div>

      <Flex 
        direction='column' 
        bg='#F7F8F8' 
        minHeight='100vh'
        w='100%'
      >
        <Box>
          <Outlet />
        </Box>

        <Spacer />

        <Center padding='24px 50px'>
          <Text>copyright @ {new Date().getFullYear()}</Text>
        </Center>
      </Flex>
    </HStack>
  )
}

export default AuthLayout