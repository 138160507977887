import React, { Dispatch, FC, SetStateAction } from 'react'
import { useForm } from 'react-hook-form'
import {
  Card,
  CardBody,
  FormControl,
  FormLabel,
  useToast,
  Input,
  FormErrorMessage,
  VStack,
} from '@chakra-ui/react'
import _ from 'lodash'
import AdminAffiliateService, {
  Affiliate,
  AffiliateUpdateState,
} from '../../../network/services/adminAffiliate'
import AffiliateInvitationService, {
  AffiliateInvitationCreateState,
} from '../../../network/services/affiliateInvitaiton'
import { useNavigate } from 'react-router-dom'
// import { useMediaQuery } from 'react-responsive'

const InvitationDetail: FC<{
  formRef: any
  initialValue?: Affiliate
  refresh: () => any
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
}> = ({ formRef, initialValue, refresh, isLoading, setIsLoading }) => {
  // const { id } = useParams()

  const navigate = useNavigate()

  const toast = useToast()
  let {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AffiliateInvitationCreateState>({
    shouldUseNativeValidation: false,
    defaultValues: {
      ...initialValue,
      email: initialValue?.user?.email,
    },
  })

  const submit = async (values: AffiliateInvitationCreateState) => {
    if (isLoading) return
    setIsLoading(true)

    // if (initialValue?.id) {
    //   try {
    //     const { data: result } = await AffiliateInvitationService.update(
    //       initialValue.id,
    //       values
    //     )
    //     if (result.success) {
    //       message.success('データが保存されした')
    //       refresh()
    //     }
    //   } catch (error: any) {
    //     message.error('エラーが発生しました。 ' + error.message)
    //   }
    // } else {
    try {
      const { data: result } = await AffiliateInvitationService.create(values)
      if (result.success) {
        toast({
          status: 'success',
          description: '招待メールが送信しました',
          variant: 'subtle',
          position: 'top',
        })
        // navigate(`/admin/dashboard/invitations/${result.data.id}`)
        navigate(`/admin/dashboard/affiliates`)
      }
    } catch (error: any) {
      toast({
        status: 'error',
        description: 'エラーが発生しました。' + error.message,
        variant: 'subtle',
        position: 'top',
      })
    }
    // }

    setIsLoading(false)
  }

  return (
    <>
      <Card>
        <CardBody>
          <VStack
            spacing='16px'
            as='form'
            onSubmit={handleSubmit(submit)}
            ref={formRef}
          >
            <FormControl
              isInvalid={Boolean(errors.email)}
              isRequired
              isReadOnly={initialValue != null}
            >
              <FormLabel>メールアドレス</FormLabel>
              <Input
                {...register('email', {
                  required: '必要なフィールドです',
                })}
              />

              <FormErrorMessage>
                {errors.email ? String(errors.email.message) : ''}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={Boolean(errors.name)}
              isRequired
              isReadOnly={initialValue != null}
            >
              <FormLabel>名前</FormLabel>
              <Input
                {...register('name', {
                  required: '必要なフィールドです',
                })}
              />

              <FormErrorMessage>
                {errors.name ? String(errors.name.message) : ''}
              </FormErrorMessage>
            </FormControl>

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            ></div>
          </VStack>
        </CardBody>
      </Card>
    </>
  )
}

export default InvitationDetail
