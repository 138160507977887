import { Card, CardBody, Spinner } from '@chakra-ui/react'
import PageHeader from '../../../components/PageHeader'
import Empty from '../../../components/Empty'
import useSWR from 'swr'
import { useSnapshot } from 'valtio'

import tableStore from '../../../lib/store/affiliate_campaign_table'
import { serialize } from '../../../network/request'
import { useNavigate } from 'react-router-dom'
import AffiliateCampaignsTable from './AffiliateCampaignTable'
import AffiliateCampaignService from '../../../network/services/affiliateCampaign'

const AffiliateCampaignsPage = () => {
  //   const navigate = useNavigate()

  return (
    <PageHeader title='キャンペーン管理'>
      <Card>
        <CardBody>
          <AffiliateCampaignsTableWrapper />
        </CardBody>
      </Card>
    </PageHeader>
  )
}

const AffiliateCampaignsTableWrapper = () => {
  const { state } = useSnapshot(tableStore)

  // use this to preload
  const { data: response, error } = useSWR(
    serialize(AffiliateCampaignService.getAll, {
      page: 1,
      limit: state.pageSize,
    })
  )

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response) {
    return <Spinner />
  }

  const { total } = AffiliateCampaignService.toPaginate(response)

  return (
    <>
      <AffiliateCampaignsTable />
      {/* preload next page */}
      {state.currentPage * state.pageSize < total && (
        <div style={{ display: 'none' }}>
          <AffiliateCampaignsTable
            overridePage={state.currentPage + 1}
          />
        </div>
      )}
    </>
  )
}

export default AffiliateCampaignsPage
