import { FC, useEffect, useState } from 'react'
import {
  Input,
  InputGroup,
  InputLeftAddon,
  Box,
  FormControl,
  Wrap,
  WrapItem,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
  Icon,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { Button, Spinner } from '@chakra-ui/react'
import { RangeDatepicker } from 'chakra-dayzed-datepicker'
import { useForm } from 'react-hook-form'
import _ from 'lodash'

// const { RangePicker } = DatePicker

export interface IDropDown {
  key: string
  width?: number
  default?: any
  label?: string
  value: { key: any; name: string; default?: boolean }[]
}

export interface ITableFilter {
  key: string
  name: string
  default_value?: any
  default?: boolean
}

function convertLocalToUTCDateToISOString(date: Date) {
  if (!date) {
    return date
  }
  date = new Date(date)
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
  return date.toISOString()
}

const TableFilter: FC<{
  dropdowns: IDropDown[]
  columns: ITableFilter[]
  setCurrentFilters?: (values: any) => any
  hasDate: boolean
}> = ({
  dropdowns = [],
  columns,
  setCurrentFilters = null,
  hasDate = false,
}) => {
  const [selectedDates, setSelectedDates] = useState<Date[]>([new Date(), new Date()])

  const toast = useToast()
  let {
    register,
    handleSubmit,
    resetField,
    setValue,
    watch,
    formState: { errors }
  } = useForm<any>({
    shouldUseNativeValidation: false,
    defaultValues: Object.fromEntries(
      dropdowns.map(dropdown => [dropdown.key, dropdown.default])
    )
  })

  const submit = (v: any) => {
    console.log(v)
    let filtering: Record<string, any> = {}
    if (v.stateKey != null) {
      filtering[v.stateKey] = v.search
    }

    if (v.stateKey == null) {
      toast({ 
        description: '検索スコープを選んでから検索をしてください',
        status: 'error',
        variant: 'subtle',
        position: 'top',
      })
      return
    }

    for (let dropdown of dropdowns) {
      // && v[dropdown.key] != -1
      if (v[dropdown.key] != null) {
        filtering[dropdown.key] = v[dropdown.key]
      }
    }

    if (v.date != null) {
      filtering['from_date'] = convertLocalToUTCDateToISOString(selectedDates[0])
      filtering['to_date'] = convertLocalToUTCDateToISOString(selectedDates[1])
    }

    console.log(filtering)
    if (!_.isEmpty(filtering) && setCurrentFilters != null) {
      setCurrentFilters(filtering)
      // tableStore.state.filters = filtering
      // tableStore.state.currentPage = 1
    }
  }

  //   const reset = (v) => {
  //     let filtering = {}
  //     for (let dropdown of dropdowns) {
  //       // && v[dropdown.key] != -1
  //       if (v[dropdown.key] != null) {
  //         filtering[dropdown.key] = v[dropdown.key]
  //       }
  //     }

  //     if (v.date != null) {
  //       filtering['from_date'] = v.date[0].toISOString()
  //       filtering['to_date'] = v.date[1].toISOString()
  //     }

  //     console.log(filtering)
  //     if (filtering !== {} && setCurrentFilters != null) {
  //       setCurrentFilters(filtering)
  //       // tableStore.state.filters = filtering
  //       // tableStore.state.currentPage = 1
  //     }
  //   }

  const [initial, setInitial] = useState<any | null>(null)
  useEffect(() => {
    let initial: Record<string, any> = {
      search: '',
    }

    for (let dropdown of dropdowns) {
      if (dropdown.default != null) {
        initial[dropdown.key] = dropdown.default
      }
    }

    columns.forEach((item) => {
      if (item.default === true) {
        initial.search = item.default_value
        setValue('stateKey', item.key)
      }
    })

    setInitial(initial)
    // submit(initial)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (initial == null) {
    return <Spinner />
  }

  const clearFilters = () => {
    submit({stateKey: 'search'})
    resetField('search')
  }

  return (
    <Box as='form' onSubmit={handleSubmit(submit)}>
      <Wrap spacing='24px' justify='end'>
        <WrapItem>
          <Button onClick={clearFilters}>
            フィルターを削除する
          </Button>
        </WrapItem>
        {dropdowns?.length > 0 &&
          dropdowns.map((dropdown, index) => {
            return (
              dropdown?.value?.length > 0 && (
                <WrapItem key={index}>
                  <FormControl style={{ margin: 0 }}>
                    <Menu closeOnSelect={true} autoSelect={false} variant='select'>
                      <MenuButton 
                        as={Button} 
                        rightIcon={<Icon as={ChevronDownIcon} />}
                        variant='select'
                      >
                        {_.find(dropdown.value, { key: watch(dropdown.key) })?.name ?? dropdown.label}
                      </MenuButton>
                      <MenuList>
                        <MenuOptionGroup 
                          type='radio' 
                          value={watch(dropdown.key)}
                          onChange={(e) => {
                            setValue(dropdown.key, e)
                            setValue('stateKey', dropdown.key)
                            handleSubmit(submit)()
                          }}
                        >
                          {dropdown.value.map((d) => {
                            return (
                              <MenuItemOption {...register(dropdown.key)} value={d.key} key={d.key}>
                                {d.name}
                              </MenuItemOption>
                            )
                          })}
                        </MenuOptionGroup>
                      </MenuList>
                    </Menu>
                  </FormControl>
                </WrapItem>
              )
            )
          })}

        {hasDate && (
          <WrapItem>
            <FormControl>
              <RangeDatepicker
                {...register('date')}
                selectedDates={selectedDates}
                onDateChange={setSelectedDates}
                configs={{
                  dayNames: 'SMTWTFS'.split(''),
                  monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                }}
                propsConfigs={{
                  dateNavBtnProps: {
                    variant: 'outline',
                    borderColor: 'gray.400',
                    borderWidth: '1px'
                  },
                  dayOfMonthBtnProps: {
                    defaultBtnProps: {
                      _hover: {
                        background: 'brand.focusBorder',
                        color: 'white',
                      }
                    },
                    isInRangeBtnProps: {
                      background: 'brand.purple',
                      color: 'white',
                    },
                    selectedBtnProps: {
                      background: 'brand.purple',
                      color: 'white',
                    },
                  },
                  calendarPanelProps: {
                    contentProps: {
                      borderWidth: 0,
                    },
                    headerProps: {
                      paddingBottom: '8px'
                    },
                    dividerProps: {
                      display: 'none'
                    }
                  },
                  weekdayLabelProps: {
                    fontSize: '0.875em',
                    color: 'gray.400'
                  }
                }}
              />
            </FormControl>
          </WrapItem>
        )}

        {columns?.length > 0 && (
          <WrapItem>
            <FormControl style={{ margin: 0 }}>
              <InputGroup>
                <InputLeftAddon p={0}>
                  <Menu closeOnSelect={true} autoSelect={false} variant='select'>
                    <MenuButton 
                      as={Button} 
                      rightIcon={<Icon as={ChevronDownIcon} />}
                      variant='select'
                      borderWidth={0}
                    >
                      {_.find(columns, { key: watch('stateKey') })?.name ?? '検索スコープ'}
                    </MenuButton>
                    <MenuList>
                      <MenuOptionGroup 
                        type='radio' 
                        value={watch('stateKey')}
                        onChange={value => {
                          setValue('stateKey', value)
                        }}
                      >
                        {columns.map((column) => {
                          return (
                            <MenuItemOption value={column.key} key={column.key}>
                              {column.name}
                            </MenuItemOption>
                          )
                        })}
                      </MenuOptionGroup>
                    </MenuList>
                  </Menu>
                </InputLeftAddon>
                <Input
                  {...register('search')}
                  // allowClear
                />
              </InputGroup>
            </FormControl>
          </WrapItem>
        )}
        <WrapItem>
          <Button type='submit'>
            検索する
          </Button>
        </WrapItem>
      </Wrap>
    </Box>
  )
}

export default TableFilter