import client from '../request'

export interface DiscountCode {
  id: number
  code: string
  created_at: Date
  updated_at: Date
}

export interface DiscountCodeCreateState {
  code_prefix: string
}

const createCode = (id: string | number, data: DiscountCodeCreateState) => {
  return client.post(`/affiliate/campaigns/${id}/discount-code`, data)
}

const AffiliateDiscountCodeService = {
  createCode,
}

export default AffiliateDiscountCodeService
