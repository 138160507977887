import { Card, CardBody, Spinner } from '@chakra-ui/react'
import Empty from '../../../components/Empty'
import PageHeader from '../../../components/PageHeader'
import useSWR from 'swr'
import { useSnapshot } from 'valtio'
import tableStore from '../../../lib/store/order_table'
import { serialize } from '../../../network/request'
import { useNavigate, useParams } from 'react-router-dom'
import OrdersTable from './OrderTable'
import OrderService from '../../../network/services/order'
const OrdersPage = () => {
  const navigate = useNavigate()
  //   const primaryAction = (
  //     <Button
  //       key='add'
  //       type='primary'
  //       onClick={() => {
  //         navigate('/admin/dashboard/invitations/new')
  //       }}
  //     >
  //       アフィリエイトを招待する
  //     </Button>
  //   )

  return (
    <PageHeader title='注文一覧'>
      <Card>
        <CardBody>
          <OrdersTableWrapper />
        </CardBody>
      </Card>
    </PageHeader>
  )
}

const OrdersTableWrapper = () => {
  const { state } = useSnapshot(tableStore)

  // use this to preload
  const { data: response, error } = useSWR(
    serialize(OrderService.getAll, {
      page: 1,
      limit: state.pageSize,
    })
  )

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response) {
    return <Spinner />
  }

  const { total } = OrderService.toPaginate(response)

  return (
    <>
      <OrdersTable total={total} />
      {/* preload next page */}
      {state.currentPage * state.pageSize < total && (
        <div style={{ display: 'none' }}>
          <OrdersTable total={total} overridePage={state.currentPage + 1} />
        </div>
      )}
    </>
  )
}

export default OrdersPage
