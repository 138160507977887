import { useNavigate, useParams } from 'react-router-dom'
import { Spinner } from '@chakra-ui/react'
import Empty from '../../../components/Empty'
import PageHeader from '../../../components/PageHeader'
import useSWR from 'swr'
import OrderDetail from './OrderDetail'
import OrderService, { Order } from '../../../network/services/order'

const OrderPage = () => {
  const navigate = useNavigate()

  const { id } = useParams()
  // use this to preload
  const {
    data: response,
    error,
    mutate,
  } = useSWR<Order>(id ? OrderService.get(id!) : null)

  if (error) {
    console.log(error)
    return <Empty description={`エラーが発生しました。${error?.message}`} />
  }

  if (!response && id !== 'new') {
    return (
      <PageHeader>
        <Spinner />
      </PageHeader>
    )
  }

  const refresh = () => {
    if (id != null) {
      if (id === 'new') {
      } else {
        mutate()
      }
    }
  }

  return (
    <PageHeader
      title={'注文詳細'}
      onBack={() => navigate('/admin/dashboard/orders')}
    >
      <OrderDetail initialValue={response} refresh={refresh} />
    </PageHeader>
  )
}

export default OrderPage
