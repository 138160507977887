import { extendTheme, defineStyleConfig } from "@chakra-ui/react"
import type { StyleFunctionProps } from "@chakra-ui/styled-system"
import Button from "./components/button"
import Table from "./components/table"
import Input from "./components/input"
import Select from "./components/select"
import NumberInput from "./components/numberinput"
import Card from "./components/card"
import Tabs from "./components/tabs"
import Stepper from "./components/stepper"
import Link from "./components/link"
import Menu from "./components/menu"

const colors = {
  brand: {
    primary: '#2D2E36',
    hover: '#57585e',
    purple: '#7F56D9',
    focusShadow: '#F4EBFF',
    focusBorder: '#D6BBFB',
  }
}

const fonts = {
  heading: 'Noto Sans JP',
  body: 'Noto Sans JP',
}

const theme = extendTheme({
  colors,
  fonts,
  components: {
    Button,
    Table,
    Input,
    Select,
    NumberInput,
    Card,
    Tabs,
    Stepper,
    Link,
    Menu,
  },
  textStyles: {
    p: {
      marginBottom: '15px',
    }
  },
  shadows: { outline: '0 0 0 4px var(--chakra-colors-brand-focusShadow)' },
  styles: {
    global: (props: StyleFunctionProps) => ({
      body: {
        fontSize: '14px'
      }
    })
  }
})

export default theme
