import client, { IDataResponse } from '../request'

export type Product = {
  id: number
  external_id: string
  name: string
  description: string
  page_url: string
  image_url: string
  published_at?: Date
  created_at: Date
  updated_at: Date
  variants: ProductVariant[]
}

export type ProductVariant = {
  id: number
  external_id: string
  name: string
  fullname: string
  product_id: number
  sku?: string
  image_url?: string
  price: number
  currency?: string
  barcode?: string
  created_at: Date
  updated_at: Date
}

//swr
const getAll = `/admin/products`

const get = (id: string | number) => {
  return `/admin/products/${id}`
}

const toRow = (data: IDataResponse<Product>) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id,
      }
    })
  }

  return []
}

const toPaginate = (data: IDataResponse<Product>) => {
  return {
    total: data?.meta?.total ?? 0,
  }
}

const ProductService = {
  getAll,
  get,
  toRow,
  toPaginate,
}

export default ProductService
