import { 
  Button, 
  Card, 
  CardBody, 
  Spinner,
} from '@chakra-ui/react'
import PageHeader from '../../../components/PageHeader'
import Empty from '../../../components/Empty'
import useSWR from 'swr'
import { useSnapshot } from 'valtio'
import tableStore from '../../../lib/store/affiliate_withdraw_table'
import { serialize } from '../../../network/request'
import { useNavigate, useParams } from 'react-router-dom'
import AffiliateWithdrawService from '../../../network/services/affiliateWithdraw'
import AffiliateWithdrawTable from './AffiliateWithdrawTable'

const AffiliateWithdrawsPage = () => {
  const navigate = useNavigate()
  const primaryAction = (
    <Button
      key='add'
      onClick={() => {
        navigate('new')
      }}
    >
      振込を申し込む
    </Button>
  )

  return (
    <PageHeader title='振込申請' extra={[primaryAction]}>
      <Card>
        <CardBody>
          <AdminWithdrawTableWrapper />
        </CardBody>
      </Card>
    </PageHeader>
  )
}

const AdminWithdrawTableWrapper = () => {
  const { state } = useSnapshot(tableStore)

  // use this to preload
  const { data: response, error } = useSWR(
    serialize(AffiliateWithdrawService.getAll, {
      page: 1,
      limit: state.pageSize,
    })
  )

  if (error) {
    console.log(error)
    // return <Empty description={error?.message ?? 'Please try again later'} />
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response) {
    return <Spinner />
  }

  const { total } = AffiliateWithdrawService.toPaginate(response)

  return (
    <>
      <AffiliateWithdrawTable total={total} />
      {/* preload next page */}
      {state.currentPage * state.pageSize < total && (
        <div style={{ display: 'none' }}>
          <AffiliateWithdrawTable
            total={total}
            overridePage={state.currentPage + 1}
          />
        </div>
      )}
    </>
  )
}

export default AffiliateWithdrawsPage
