import { defineStyleConfig } from "@chakra-ui/react"

const Stepper = defineStyleConfig({
  baseStyle: {
    indicator: {
      '&[data-status=complete]': {
        bg: 'brand.purple',
      },
      '&[data-status=active]': {
        borderColor: 'brand.purple',
        color: 'brand.purple',
      },
    }, 
    separator: {
      '&[data-status=complete]': {
        bg: 'brand.purple',
      },
    }
  },
})

export default Stepper
