import React, {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useContext,
} from 'react'
import { 
  Card,
  CardBody,
  CardHeader,
  VStack,
} from '@chakra-ui/react'
import Gaps from '../../../components/Gaps'
import _ from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import { IDataResponse, serialize } from '../../../network/request'
import OrderService, { Order } from '../../../network/services/order'
import useSWR from 'swr'
import ReadOnlyFormItem from '../../../components/ReadOnlyFormItem'
import { Withdraw } from '../../../network/services/adminWithdraw'

const AdminWithdrawDetail: FC<{
  initialValue?: Withdraw
  refresh: () => any
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
}> = ({ initialValue, refresh, isLoading, setIsLoading }) => {
  const { id } = useParams()

  const navigate = useNavigate()

  // const handleSubmit = async (values: AdminCampaignCreateState) => {
  //   if (isLoading) return
  //   setIsLoading(true)

  //   if (initialValue?.id) {
  //     try {
  //       const { data: result } = await AdminCampaignService.update(
  //         initialValue.id,
  //         values
  //       )
  //       if (result.success) {
  //         message.success('データが保存されした')
  //         refresh()
  //       }
  //     } catch (error: any) {
  //       message.error('エラーが発生しました。 ' + error.message)
  //     }
  //   } else {
  //     try {
  //       const { data: result } = await AdminCampaignService.create(values)
  //       if (result.success) {
  //         message.success('保存が完了しました')
  //         navigate(`/admin/dashboard/campaigns/${result.data.id}`)
  //       }
  //     } catch (error: any) {
  //       message.error('エラーが発生しました。' + error.message)
  //     }
  //   }

  //   setIsLoading(false)
  // }

  return (
    <>
      <Card>
        <CardBody>
          <VStack spacing='16px' align='stretch'>
            <ReadOnlyFormItem
              variant='unstyled'
              title='申込者'
              content={`${initialValue?.user?.affiliate?.last_name ?? ''} ${
                initialValue?.user?.affiliate?.first_name ?? ''
              }`}
            />

            <ReadOnlyFormItem
              variant='unstyled'
              title='申込者メールアドレス'
              content={initialValue?.user?.email}
            />

            <ReadOnlyFormItem
              variant='unstyled'
              title='金額'
              content={`￥${initialValue?.amount}`}
            />

            <ReadOnlyFormItem 
              variant='unstyled' 
              title='通貨ID' 
              content={initialValue?.currency} 
            />

            {initialValue?.status == 'approved' && (
              <Card>
                <CardHeader color='green'>申請が承認されました</CardHeader>
                <CardBody>
                  <ReadOnlyFormItem
                    variant='unstyled'
                    title='承認者'
                    content={initialValue.approved_by_admin?.user?.email}
                  />

                  <ReadOnlyFormItem
                    variant='unstyled'
                    title='AmazonギフトカードID'
                    content={initialValue.amazon_creation_request_id}
                  />
                </CardBody>
              </Card>
            )}

            {initialValue?.status == 'rejected' && (
              <Card>
                <CardHeader color='red'>申請が拒否されました</CardHeader>
                <CardBody>
                  <ReadOnlyFormItem
                    variant='unstyled'
                    title='拒否者'
                    content={initialValue.rejected_by_admin?.user?.email}
                  />

                  <ReadOnlyFormItem
                    variant='unstyled'
                    title='拒絶理由'
                    content={initialValue.reason}
                  />
                </CardBody>
              </Card>
            )}
          </VStack>
        </CardBody>
      </Card>
      <Gaps />
    </>
  )
}

export default AdminWithdrawDetail
