import { IDataResponse } from '../request'
import { Commission } from './adminCommission'
import { ProductVariant } from './product'

export interface NoteAttribute {
  name: string
  value: any
}

export interface LineItem {
  id: number
  order_id: number
  variant_id: number
  quantity: number
  price: number
  total_discount: number
  total_price: number
  currency: string
  external_id: string
  created_at: Date
  updated_at: Date
  variant: ProductVariant
}

export interface Order {
  id: number
  currency: string
  external_order_id: string
  email: string
  campaign_affiliate_id: string
  discount_code?: string
  cancelled_at: Date
  fulfillment_status: string
  financial_status: string
  note: string
  note_attributes: NoteAttribute[]
  payment_gateway_names?: string
  presentment_currency: string
  processed_at: string
  processing_method?: string
  is_test: boolean
  shipping_price?: number
  subtotal_price: number
  total_discounts: number
  total_line_items_price: number
  total_price: number
  total_tax?: number
  metafields?: any
  total_commission: number
  order_status_url?: string
  closed_at?: Date
  created_at: Date
  updated_at: Date
  name: string
  affiliate_id: number
  line_items: LineItem[]
  commissions?: Commission[]
}
//swr
const getAll = `/admin/orders`

const get = (id: string | number) => {
  return `/admin/orders/${id}`
}

// const remove = (appClientId: string, id: string | number) => {
//   return client.delete(`/admin/affiliates/${id}`)
// }

const toRow = (data: IDataResponse<Order>) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id,
      }
    })
  }

  return []
}

const toPaginate = (data: IDataResponse<Order>) => {
  return {
    total: data?.meta?.total ?? 0,
  }
}

const OrderService = {
  getAll,
  get,
  toRow,
  toPaginate,
}

export default OrderService
