import React, {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
} from 'react'
import {
  VStack,
  Card,
  CardBody,
  Text,
  Tabs, 
  TabList, 
  TabPanels, 
  Tab, 
  TabPanel,
} from '@chakra-ui/react'
import Gaps from '../../../components/Gaps'
import _ from 'lodash'
import AdminCampaignService, {
  AdminCampaign,
  AdminCampaignCreateState,
  CampaignAffiliate,
  CampaignProduct,
} from '../../../network/services/adminCampaign'
import { useNavigate, useParams } from 'react-router-dom'
import { IDataResponse, serialize } from '../../../network/request'
import OrderService, { LineItem, Order } from '../../../network/services/order'
import useSWR from 'swr'
import ReadOnlyFormItem from '../../../components/ReadOnlyFormItem'
import {
  Commission,
  commissionStatusText,
} from '../../../network/services/adminCommission'
import { ColumnDef } from '@tanstack/react-table'
import CustomTable from '../../../components/CustomTable'

const adminCampaignContext = createContext<AdminCampaign | null | undefined>(
  null
)

const OrderDetail: FC<{
  initialValue?: Order
  refresh: () => any
}> = ({ initialValue }) => {
  const tabItems = [
    {
      key: '1',
      label: '注文詳細',
      children: <LineItemTable />,
    },
    {
      key: '2',
      label: '紹介料詳細',
      children: <CommissionTable />,
    },
  ]

  return (
    <>
      <Card>
        <CardBody>
          <VStack spacing='16px' align='stretch'>
            <ReadOnlyFormItem
              variant='unstyled'
              title='注文名'
              content={initialValue?.name}
              enableCopy={false}
            />

            <ReadOnlyFormItem
              variant='unstyled'
              title='総額（税込）'
              content={`￥${initialValue?.total_price}`}
              enableCopy={false}
            />

            <ReadOnlyFormItem
              variant='unstyled'
              title='紹介料総額'
              content={`￥${initialValue?.total_commission}`}
              enableCopy={false}
            />
          </VStack>
        </CardBody>
      </Card>
      <Gaps />

      <Card>
        <CardBody>
          <Tabs defaultIndex={0} isLazy>
            <TabList>
              {tabItems.map((item) => (
                <Tab key={item.key}>{item.label}</Tab>
              ))}
            </TabList>
            <TabPanels>
              {tabItems.map((item) => (
                <TabPanel key={item.key}>
                  {item.children}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </CardBody>
      </Card>
    </>
  )
}

const LineItemTable: FC = () => {
  const { id } = useParams()
  // use this to preload
  const { data: response } = useSWR<Order>(id ? OrderService.get(id!) : null)

  const columns = useMemo<ColumnDef<LineItem, any>[]>(
    () => [
      {
        id: 'sku',
        accessorFn: (row: LineItem) => row.variant.sku,
        header: "SKU",
      },
      {
        id: 'fullname',
        accessorFn: (row: LineItem) => row.variant.fullname,
        header: "商品名",
        enableSorting: false,
      },
      {
        id: 'quantity',
        accessorKey: 'quantity',
        header: "個数",
        enableSorting: false,
      },
      {
        id: 'price',
        accessorKey: 'price',
        header: "単価",
        cell: (info) => `￥${info.getValue()}`,
        enableSorting: false,
      },
      {
        id: 'total_price',
        accessorFn: (row: LineItem) => row.price * row.quantity,
        header: "全額",
        cell: (info) => `￥${info.getValue()}`,
        enableSorting: false,
      },
      {
        id: 'total_discount',
        accessorKey: 'total_discount',
        header: "値引",
        cell: (info) => `￥${info.getValue()}`,
        enableSorting: false,
      },
    ],
    []
  )

  return (
    <CustomTable
      {...{
        data: response?.line_items ?? [],
        columns,
      }}
    />
  )
}

const CommissionTable: FC = () => {
  const { id } = useParams()
  // use this to preload
  const { data: response } = useSWR<Order>(id ? OrderService.get(id!) : null)

  const columns = useMemo<ColumnDef<Commission, any>[]>(
    () => [
      {
        id: 'affiliate_name',
        accessorFn: (row: Commission) => 
          `${row.affiliate?.last_name} ${row.affiliate?.first_name}`,
        header: "紹介者",
      },
      {
        id: 'status',
        accessorKey: 'status',
        header: 'ステータス',
        cell: (info) => (
          <Text style={{ 
            color: commissionStatusText[info.getValue() as keyof typeof commissionStatusText].color
          }}>
            {commissionStatusText[info.getValue() as keyof typeof commissionStatusText].text}
          </Text>
        ),
      },
      {
        id: 'item',
        accessorFn: (row: Commission) => 
          `${row.product_full_name} （${row.quantity}個）`,
        header: "商品",
        enableSorting: false,
      },
      {
        id: 'amount',
        accessorKey: 'amount',
        header: "紹介料",
        cell: (info) => (
          <Text style={{ color: info.getValue() > 0 ? 'green' : 'red' }}>
            {info.getValue() > 0 ? '+' : '-'}￥{Math.abs(info.getValue())}
          </Text>
        )
      },
    ],
    []
  )

  return (
    <CustomTable
      {...{
        data: response?.commissions ?? [],
        columns,
      }}
    />
  )
}

export default OrderDetail
