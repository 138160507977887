import { IDataResponse } from '../request'
import { Transaction } from './transaction'

const getAll = `/affiliate/transactions`

const get = (id: string | number) => {
  return `/affiliate/transactions/${id}`
}

const toRow = (data: IDataResponse<Transaction>) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id,
      }
    })
  }

  return []
}

const toPaginate = (data: IDataResponse<Transaction>) => {
  return {
    total: data?.meta?.total ?? 0,
  }
}

const AffiliateTransactionService = {
  getAll,
  get,
  toRow,
  toPaginate,
}

export default AffiliateTransactionService
