import { useEffect, useState } from 'react'
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  ColumnDef,
  flexRender,
  getSortedRowModel,
  SortingState,
  RowSelectionState,
} from '@tanstack/react-table'
import {
  HStack,
  Box, 
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Icon,
  NumberInput,
  NumberInputField,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
  Button,
} from '@chakra-ui/react'
import { 
  ChevronDoubleLeftIcon, 
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/outline'

const CustomTable = ({
  data, 
  columns,
  page = 1,
  size = 10,
  sorting,
  lastPage = Math.ceil(data.length/size),
  setPage,
  setSize,
  setSorting,
  setSelectedRowKeys,
  onClickRow,
}: {
  data: any[], 
  columns: ColumnDef<any>[],
  page?: number,
  size?: number,
  sorting?: SortingState,
  lastPage?: number,
  setPage?: (page: number) => any,
  setSize?: (size: number) => any,
  setSorting?: React.Dispatch<React.SetStateAction<SortingState>>,
  setSelectedRowKeys?: React.Dispatch<React.SetStateAction<any[]>>,
  onClickRow?: (record: any) => any,
}) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
  const [defaultSorting, defaultSetSorting] = useState<SortingState>([])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting ?? defaultSetSorting,
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    initialState: { pagination: { pageSize: size } },
    state: {
      sorting: sorting ?? defaultSorting,
      rowSelection,
    },
    debugTable: true,
    manualSorting: setSorting ? true : false,
  })
  
  setPage ?? (setPage = (p: number) => table.setPageIndex(p-1))
  setSize ?? (setSize = (s: number) => table.setPageSize(s))

  const getCanGoPreviousPage = () => {
    return table.getPageCount() > 1 ? !table.getCanPreviousPage() : page === 1
  }

  const getCanGoNextPage = () => {
    return table.getPageCount() > 1 ? !table.getCanNextPage() : page === lastPage
  }

  useEffect(() => {
    if (setSelectedRowKeys) {
      setSelectedRowKeys((prev: any[]) =>
        Object.keys(rowSelection).map((key) => {
          const selectedRow = table.getSelectedRowModel().rowsById[key]?.original || prev.find((row) => row.key === key)
          return selectedRow ? selectedRow.key : key
        })
      )
    }
  }, [rowSelection, setSelectedRowKeys, table]);

  return (
    <Box>
      <Table>
        <Thead>
          {table.getHeaderGroups().map(headerGroup => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <Th 
                    key={header.id} 
                    colSpan={header.colSpan}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {header.isPlaceholder ? null : (
                      <Box>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getIsSorted() && (
                          header.column.getIsSorted() === "desc" ? (
                            <Icon as={ChevronDownIcon} />
                          ) : (
                            <Icon as={ChevronUpIcon} />
                          )
                        )}
                      </Box>
                    )}
                  </Th>
                )
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map(row => {
            return (
              <Tr 
                key={row.id} 
                onClick={() => onClickRow ? onClickRow(row.original) : null}
              >
                {row.getVisibleCells().map(cell => {
                  return (
                    <Td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  )
                })}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
      
      <HStack mt={5} justifyContent='end'>
        <IconButton
          aria-label='First Page'
          icon={<Icon as={ChevronDoubleLeftIcon} />}
          onClick={() => { if (setPage) setPage(1) }}
          isDisabled={getCanGoPreviousPage()}
        />
        <IconButton
          aria-label='Previous Page'
          icon={<Icon as={ChevronLeftIcon} />}
          onClick={() => { if (setPage) setPage(page-1) }}
          isDisabled={getCanGoPreviousPage()}
        />
        <HStack>
          <Text>Page</Text>
          <NumberInput 
            key={page}
            defaultValue={page} 
            min={1} 
            max={lastPage} 
            w={16}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                var value = Number((e.target as HTMLInputElement).value)
                if (value >= 1 && value <= lastPage && setPage)
                  setPage(value)
              }
            }}
          >
            <NumberInputField px={0} textAlign='center'/>
          </NumberInput>
          {/* <Text as='b'> {page} </Text> */}
          <Text>of</Text>
          <Text as='b'> {lastPage} </Text>
        </HStack>
        <IconButton
          aria-label='Next Page'
          icon={<Icon as={ChevronRightIcon} />}
          onClick={() => { if (setPage) setPage(page+1) }}
          isDisabled={getCanGoNextPage()}
        />
        <IconButton
          aria-label='Last Page'
          icon={<Icon as={ChevronDoubleRightIcon} />}
          onClick={() => { if (setPage) setPage(lastPage) }}
          isDisabled={getCanGoNextPage()}
        />

        <Menu closeOnSelect={true} autoSelect={false} variant='select'>
          <MenuButton 
            as={Button} 
            rightIcon={<Icon as={ChevronDownIcon} />}
            variant='select'
          >
            Show {size}
          </MenuButton>
          <MenuList>
            <MenuOptionGroup 
              type='radio' 
              value={String(size)}
              onChange={value => {
                table.setPageSize(Number(value))
                if (setSize) setSize(Number(value))
                if (setPage) setPage(1)
              }}
            >
              {[10, 20, 50, 100, 200, 300].map(pageSize => (
                <MenuItemOption key={pageSize} value={String(pageSize)}>
                  Show {pageSize}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      </HStack>
    </Box>
  )
}

export default CustomTable