import { 
  VStack,
  Text,
  Icon,
} from '@chakra-ui/react'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { FC, ReactNode } from 'react'

const Empty: FC<{
  description?: string
}> = ({ description }) => {
  return (
    <VStack margin={5}>
      <Icon boxSize={75} color='chakra-placeholder-color' as={ExclamationCircleIcon} />
      <Text>{description}</Text>
    </VStack>
  )
}

export default Empty
