import client, { IDataResponse } from '../request'
import { Admin, User } from './auth'
import { Transaction } from './transaction'
import { Withdraw } from './adminWithdraw'

export interface WithdrawCreateState {
  amount: number
}

const getAll = `/affiliate/withdraws`

const get = (id: string | number) => {
  return `/affiliate/withdraws/${id}`
}
const getCode = (id: string | number) => {
  return `/affiliate/withdraws/${id}/code`
}

const create = (data: WithdrawCreateState) => {
  return client.post(`/affiliate/withdraws`, {
    ...data,
    //TODO: hard code for now
    currency: 'JPY',
  })
}

const toRow = (data: IDataResponse<Withdraw>) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id,
      }
    })
  }

  return []
}

const toPaginate = (data: IDataResponse<Withdraw>) => {
  return {
    total: data?.meta?.total ?? 0,
  }
}

const AffiliateWithdrawService = {
  getAll,
  get,
  create,
  getCode,
  toRow,
  toPaginate,
}

export default AffiliateWithdrawService
