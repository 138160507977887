import { defineStyleConfig } from "@chakra-ui/react"

const Table = defineStyleConfig({
  baseStyle: {
    thead: {
      bg: '#F4F5F5',
      borderRadius: '8px',
      '& th:first-of-type': {
        borderRadius: '8px 0 0 8px',
        border: 0
      },
      '& th:last-of-type': {
        borderRadius: '0 8px 8px 0',
        border: 0
      },
    },
    tr: {
      _hover: {
        bg: 'gray.100'
      }
    }
  },
})

export default Table