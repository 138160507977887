import { useNavigate, useParams } from 'react-router-dom'
import {
  Button,
  Spinner,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import Empty from '../../../components/Empty'
import SearchableSelect from '../../../components/SearchableSelect'
import useSWR from 'swr'
import PageHeader from '../../../components/PageHeader'
import { useSnapshot } from 'valtio'
import { Dispatch, SetStateAction, useMemo, useState, useRef } from 'react'
import AdminCampaignDetail from './AdminCampaignDetail'
import AdminCampaignService, {
  AdminCampaign,
  CampaignAffiliate,
} from '../../../network/services/adminCampaign'
import AdminAffiliateService from '../../../network/services/adminAffiliate'
import { IDataResponse, serialize } from '../../../network/request'
import ProductService from '../../../network/services/product'

const AdminCampaignPage = () => {
  const navigate = useNavigate()

  const formRef = useRef() as any

  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const {
    isOpen: isInviteOpen,
    onOpen: onInviteOpen,
    onClose: onInviteClose,
  } = useDisclosure()
  const {
    isOpen: isProductOpen,
    onOpen: onProductOpen,
    onClose: onProductClose,
  } = useDisclosure()

  // use this to preload
  const {
    data: response,
    error,
    mutate,
  } = useSWR(id !== 'new' ? AdminCampaignService.get(id!) : null)

  if (error) {
    console.log(error)
    return <Empty description={`エラーが発生しました。${error?.message}`} />
  }

  if (!response && id !== 'new') {
    return (
      <PageHeader>
        <Spinner />
      </PageHeader>
    )
  }

  const refresh = () => {
    if (id != null) {
      if (id === 'new') {
      } else {
        mutate()
      }
    }
  }

  const saveAction = (
    <Button
      key={'保存する'}
      onClick={() => {
        if (formRef.current)
          formRef.current.dispatchEvent(
            new Event('submit', { cancelable: true, bubbles: true })
          )
      }}
      isLoading={isLoading}
    >
      保存する
    </Button>
  )

  const inviteAction =
    id !== 'new' ? (
      <Button
        key={'アフィリエイトを追加する'}
        variant='outline'
        onClick={() => {
          onInviteOpen()
        }}
        isLoading={isLoading}
      >
        アフィリエイトを追加する
      </Button>
    ) : null

  const addProductAction =
    id !== 'new' ? (
      <Button
        key={'商品を追加する'}
        variant='outline'
        onClick={() => {
          onProductOpen()
        }}
        isLoading={isLoading}
      >
        商品を追加する
      </Button>
    ) : null

  // const discardAction = (
  //   <Button
  //     key={'削除する'}
  //     type='default'
  //     // onClick={async () => {
  //     //   await CollaboratorService.remove(appClientId!, id!)
  //     //   navigate('/dashboard/collaborators')
  //     // }}
  //   >
  //     Discard
  //   </Button>
  // )

  return (
    <PageHeader
      title={'キャンペーン管理'}
      onBack={() => navigate('/admin/dashboard/campaigns')}
      extra={[
        saveAction,
        inviteAction,
        addProductAction,
        //discardAction
      ]}
    >
      <InviteAffiliateModal isOpen={isInviteOpen} onClose={onInviteClose} />
      <InviteProductModal isOpen={isProductOpen} onClose={onProductClose} />
      <AdminCampaignDetail
        formRef={formRef}
        initialValue={response}
        refresh={refresh}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </PageHeader>
  )
}

const InviteAffiliateModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  const [search, setSearch] = useState<string>('')
  const [target, setTarget] = useState<number>()
  const [isLoading, setIsLoading] = useState(false)
  const { id } = useParams()

  const toast = useToast()
  const setValue = (name: string, option: any) => setTarget(parseInt(option))

  const { data: campaignAffiliateData, mutate: campaignMutate } = useSWR<
    IDataResponse<CampaignAffiliate>
  >(id ? AdminCampaignService.getAffiliates(id) : null)

  const campaignAffiliates = campaignAffiliateData
    ? AdminCampaignService.affiliateToRow(campaignAffiliateData)
    : []

  const { data: affiliateData, mutate: affiliateMutate } = useSWR(
    serialize(AdminAffiliateService.getAll, {
      search,
      status: 'active',
      not_in_ids: campaignAffiliates.map((e) => e.affiliate_id).join(','),
    })
  )

  const affiliates = AdminAffiliateService.toRow(affiliateData)

  const submit = async () => {
    setIsLoading(true)
    if (!id || !target) return
    try {
      const { data: result } = await AdminCampaignService.inviteAffiliate(id, {
        affiliate_id: target,
      })
      if (result.success) {
        toast({
          status: 'success',
          description: '招待メールが送信されました',
          variant: 'subtle',
          position: 'top',
        })
        await campaignMutate()
        await affiliateMutate()
        onClose()
      }
    } catch (error: any) {
      toast({
        status: 'error',
        description: 'エラーが発生しました。' + error.message,
        variant: 'subtle',
        position: 'top',
      })
    }

    setIsLoading(false)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>アフィリエイト追加</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SearchableSelect
            name='affiliates'
            placeholder='アフィリエイトを選んでください'
            options={affiliates.map((e) => ({
              value: e.id,
              label: `${e.last_name} ${e.first_name} (${e.user?.email})`,
            }))}
            setValue={setValue}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={isLoading}
            onClick={onClose}
            mr={3}
            variant='outline'
            loadingText='やめる'
          >
            やめる
          </Button>
          <Button isLoading={isLoading} onClick={submit} loadingText='追加する'>
            追加する
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const InviteProductModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  const [search, setSearch] = useState<string>('')
  const [target, setTarget] = useState<number>()
  const [isLoading, setIsLoading] = useState(false)
  const { id } = useParams()

  const toast = useToast()
  const setValue = (name: string, option: any) => setTarget(parseInt(option))

  const { data: campaign, mutate: campaignMutate } = useSWR<AdminCampaign>(
    id ? AdminCampaignService.get(id) : null
  )

  const { data: productData, mutate: productMutate } = useSWR(
    serialize(ProductService.getAll, {
      search,
      limit: 250,
      published: true,
      not_in_ids: campaign?.products?.map((e) => e.product.id).join(','),
    })
  )

  const products = useMemo(
    () => ProductService.toRow(productData),
    [productData]
  )

  const submit = async () => {
    setIsLoading(true)
    if (!id || !target) return
    try {
      const { data: result } = await AdminCampaignService.addProduct(id, {
        product_id: target,
      })
      if (result.success) {
        toast({
          status: 'success',
          description: '商品が追加されました',
          variant: 'subtle',
          position: 'top',
        })
        await campaignMutate()
        await productMutate()
        onClose()
      }
    } catch (error: any) {
      toast({
        status: 'error',
        description: 'エラーが発生しました。' + error.message,
        variant: 'subtle',
        position: 'top',
      })
    }

    setIsLoading(false)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>商品追加</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SearchableSelect
            name='products'
            placeholder='追加したい商品を選んでください'
            options={products.map((e) => ({
              value: e.id,
              label: `${e.name}`,
            }))}
            setValue={setValue}
            onSearch={setSearch}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={isLoading}
            onClick={onClose}
            mr={3}
            variant='outline'
            loadingText='やめる'
          >
            やめる
          </Button>
          <Button isLoading={isLoading} onClick={submit} loadingText='追加する'>
            追加する
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default AdminCampaignPage
