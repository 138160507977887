import { 
  IconButton, 
  Icon, 
  Text, 
  Tooltip, 
  useClipboard,
} from '@chakra-ui/react'
import { DocumentDuplicateIcon, CheckIcon } from '@heroicons/react/24/outline'
import { FC, PropsWithChildren } from 'react'

const CopyableText: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { onCopy, hasCopied } = useClipboard(children ? children.toString() : '');

  return (
    <>
      {children && 
        <Text>
          {children}
          <Tooltip 
            label={hasCopied ? 'Copied' : 'Copy'} 
            closeOnClick={false} 
            placement='top'
            hasArrow
          >
            <IconButton 
              aria-label='copy'
              variant='copyable'
              icon={<Icon as={hasCopied ? CheckIcon : DocumentDuplicateIcon} />}
              onClick={onCopy}
            />
          </Tooltip>
        </Text>
      }
    </>
  )
}

export default CopyableText
