import React, {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  Box,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Text,
  Button,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  FormErrorMessage,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  InputGroup,
  InputRightAddon,
  useToast,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'

import Gaps from '../../../components/Gaps'
import _ from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import { IDataResponse, serialize } from '../../../network/request'
import OrderService, { Order } from '../../../network/services/order'
import useSWR from 'swr'
import ReadOnlyFormItem from '../../../components/ReadOnlyFormItem'
import {
  Withdraw,
  withdrawStatusText,
} from '../../../network/services/adminWithdraw'
import AffiliateWithdrawService, {
  WithdrawCreateState,
} from '../../../network/services/affiliateWithdraw'
import { CommissionSummary } from '../../../network/services/adminAffiliate'
import AffiliateCommissionService from '../../../network/services/affiliateCommission'

const AffiliateWithdrawDetail: FC<{
  initialValue?: Withdraw
  formRef: any
  refresh: () => any
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
}> = ({ initialValue, refresh, isLoading, setIsLoading, formRef }) => {
  const { data: commissionSummary } = useSWR<CommissionSummary>(
    AffiliateCommissionService.getSummary
  )

  const toast = useToast()
  let {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<any>({
    shouldUseNativeValidation: false
  })

  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const submit = async (values: WithdrawCreateState) => {
    if (isLoading) return
    setIsLoading(true)
    try {
      const { data: result } = await AffiliateWithdrawService.create(values)
      if (result.success) {
        toast({
          status: 'success',
          description: '申し込みが成功しました！審査が完了するまでお待ちください',
          variant: 'subtle',
          position: 'top',
        })
        navigate(`/affiliate/dashboard/withdraws/${result.data.id}`)
      }
    } catch (error: any) {
      toast({
        status: 'error',
        description: 'エラーが発生しました。' + error.message,
        variant: 'subtle',
        position: 'top',
      })
    }
    setIsLoading(false)
  }

  return (
    <>
      {initialValue?.status == 'approved' && (
        <CodeModal isOpen={isOpen} onClose={onClose} />
      )}
      {!initialValue ? (
        <Box as='form' ref={formRef} onSubmit={handleSubmit(submit)}>
          <ReadOnlyFormItem
            title='振込可能金額'
            content={`￥${commissionSummary?.balance}`}
            enableCopy={false}
          />
          <Gaps />
          <Card>
            <CardBody>
              <FormControl
                isInvalid={Boolean(errors.amount)}
                isRequired
              >
                <FormLabel>要望金額</FormLabel>
                <InputGroup>
                  <NumberInput>
                    <NumberInputField 
                      borderRightRadius={0}
                      {...register("amount", {
                        required: '必要なフィールドです',
                        // min: { value: 500, message: '500円以上に設定してください' }
                      })} 
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <InputRightAddon children='円' />
                </InputGroup>
                <FormErrorMessage>
                  {errors.amount? String(errors.amount.message) : ""}
                </FormErrorMessage>
              </FormControl>
            </CardBody>
          </Card>
        </Box>
      ) : (
        <Card>
          <CardBody>
            <ReadOnlyFormItem
              variant='unstyled'
              title='申請状態'
              enableCopy={false}
              content={
                <Text
                  color={withdrawStatusText[initialValue.status].color}
                >
                  {withdrawStatusText[initialValue.status].text}
                </Text>
              }
            />

            <ReadOnlyFormItem
              variant='unstyled'
              title='申込者'
              enableCopy={false}
              content={`${initialValue?.user?.affiliate?.last_name ?? ''} ${
                initialValue?.user?.affiliate?.first_name ?? ''
              }`}
            />

            <ReadOnlyFormItem
              variant='unstyled'
              title='申込者メールアドレス'
              enableCopy={false}
              content={initialValue?.user?.email}
            />

            <ReadOnlyFormItem
              variant='unstyled'
              title='金額'
              enableCopy={false}
              content={`￥${initialValue?.amount}`}
            />

            <ReadOnlyFormItem
              variant='unstyled'
              title='通貨ID'
              enableCopy={false}
              content={initialValue?.currency}
            />

            {initialValue?.status == 'approved' && (
              <Card>
                <CardHeader>
                  <Heading size='sm' color='green'>申請が承認されました</Heading>
                </CardHeader>
                <CardBody>
                  <Button onClick={onOpen}>
                    Amazonギフトカードコードを表示する
                  </Button>
                </CardBody>
              </Card>
            )}

            {initialValue?.status == 'rejected' && (
              <Card>
                <CardHeader>
                  <Heading size='sm' color='red'>申請が拒否されました</Heading>
                </CardHeader>
                <CardBody>
                  <ReadOnlyFormItem
                    variant='unstyled'
                    title='拒絶理由'
                    enableCopy={false}
                    content={initialValue.reason}
                  />
                </CardBody>
              </Card>
            )}
          </CardBody>
        </Card>
      )}
      <Gaps />
    </>
  )
}

const CodeModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  const { id } = useParams()
  const { data, error } = useSWR<{
    code: string
  }>(id ? AffiliateWithdrawService.getCode(id) : null)

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader><Text fontSize='lg'>コードをコピーしてください</Text></ModalHeader>
        <ModalCloseButton />
        <ModalBody paddingBottom='16px'>
          {error && (
            <Text>エラーが発生しました　{error}</Text>
          )}

          {!error &&
            (data ? (
              <ReadOnlyFormItem
                title='Amazonギフトカードコード'
                content={data?.code}
              />
            ) : (
              <Spinner />
            ))}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default AffiliateWithdrawDetail
