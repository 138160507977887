import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { SWRConfig } from 'swr'
import { fetcher } from './network/request'
import theme from './theme'
import '@fontsource/noto-sans-jp'
import { ChakraProvider } from '@chakra-ui/react'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // <React.StrictMode>
  <ChakraProvider theme={theme}>
    <SWRConfig
      value={{ fetcher, revalidateOnFocus: false, errorRetryCount: 3 }}
    >
      <App />
    </SWRConfig>
  </ChakraProvider>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
