import { 
  IconButton, 
  Icon, 
  Text, 
  Tooltip, 
  useClipboard,
  Card,
  CardBody,
  Box,
} from '@chakra-ui/react'
import { DocumentDuplicateIcon, CheckIcon } from "@heroicons/react/24/outline"
import Gaps from './Gaps'
import { FC, ReactNode } from 'react'

const ReadOnlyFormItem: FC<{
  title: string
  content: ReactNode
  enableCopy?: boolean
  variant?: string
}> = ({ title, content, enableCopy = true, variant }) => {
  const { onCopy, hasCopied } = useClipboard(content ? content.toString() : '-');
  
  const ItemContent = () => {
    return (
      <>
        <div>{title}</div>
        <div
          style={{
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            minHeight: 30,
          }}
        >
          {typeof content === 'string' ||
          typeof content === 'number' ||
          typeof content === 'boolean' ? (
            <Text>
              {content ?? '-'}
              {content !== null && content !== undefined && enableCopy &&
                <Tooltip 
                  label={hasCopied ? 'Copied' : 'Copy'} 
                  closeOnClick={false} 
                  placement='top'
                  hasArrow
                >
                  <IconButton 
                    aria-label='copy'
                    variant='copyable'
                    icon={<Icon as={hasCopied ? CheckIcon : DocumentDuplicateIcon} />}
                    onClick={onCopy}
                  />
                </Tooltip>
              }
            </Text>
          ) : (
            content ?? '-'
          )}
        </div>
      </>
    )
  }

  return (
    <>
      {variant == 'unstyled' ? (
        <Box>
          <ItemContent />
          <Gaps />
        </Box>
      ): (
        <Card>
          <CardBody>
            <ItemContent />
          </CardBody>
          <Gaps />
        </Card>
      )}
    </>
  )
}

export default ReadOnlyFormItem
