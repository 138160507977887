import { useNavigate, useParams } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  Spinner,
  useToast,
  Heading,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import useSWR, { KeyedMutator } from 'swr'
import Empty from '../../../components/Empty'
import PageHeader from '../../../components/PageHeader'
import { UIEvent, useState } from 'react'
import AffiliateCampaignDetail from './AffiliateCampaignDetail'
import AffiliateCampaignService, {
  AffiliateCampaign,
} from '../../../network/services/affiliateCampaign'
import TNC from '../../../components/TNC'

const AffiliateCampaignPage = () => {
  const navigate = useNavigate()

  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()

  // use this to preload
  const {
    data: response,
    error,
    mutate,
  } = useSWR<AffiliateCampaign>(
    id !== 'new' ? AffiliateCampaignService.get(id!) : null
  )

  if (error) {
    console.log(error)
    return <Empty description={`エラーが発生しました。${error?.message}`} />
  }

  if (!response && id !== 'new') {
    return (
      <PageHeader>
        <Spinner />
      </PageHeader>
    )
  }

  const refresh = () => {
    if (id != null) {
      if (id === 'new') {
      } else {
        mutate()
      }
    }
  }

  const joinCampaignAction =
    response?.status == 'pending' ? (
      <Button
        key={'条約を同意して参加する'}
        onClick={() => {
          onOpen()
        }}
        isLoading={isLoading}
      >
        条約を同意して参加する
      </Button>
    ) : null

  return (
    <PageHeader
      title={'キャンペーン情報'}
      onBack={() => navigate('/affiliate/dashboard/campaigns')}
      extra={[joinCampaignAction]}
    >
      <AcceptJoinModal isOpen={isOpen} onClose={onClose} mutate={mutate} />

      <AffiliateCampaignDetail
        initialValue={response}
        refresh={refresh}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </PageHeader>
  )
}

const AcceptJoinModal = ({
  isOpen,
  onClose,
  mutate,
}: {
  isOpen: boolean
  onClose: () => void
  mutate: KeyedMutator<AffiliateCampaign>
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [scrolledToBtm, setScrolledToBtm] = useState(false)
  const { id } = useParams()
  const toast = useToast()

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const bottom =
      e.currentTarget?.scrollHeight - e.currentTarget?.clientHeight <=
      e.currentTarget?.scrollTop

    if (bottom) {
      setScrolledToBtm(true)
    }
  }

  const submit = async () => {
    setIsLoading(true)
    if (!id) return
    try {
      const { data: result } = await AffiliateCampaignService.accept(id)
      if (result.success) {
        toast({
          description: 'キャンペーンに参加しました',
          status: 'success',
          variant: 'subtle',
          position: 'top',
        })
        await mutate()
        onClose()
      }
    } catch (error: any) {
      toast({
        description: 'エラーが発生しました ' + error.message,
        status: 'error',
        variant: 'subtle',
        position: 'top',
      })
    }

    setIsLoading(false)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>キャンペーンに参加しますか？</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Card>
            <CardBody
              maxHeight='400'
              overflowY='scroll'
              onScroll={handleScroll}
            >
              <TNC />
            </CardBody>
          </Card>
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={isLoading}
            onClick={onClose}
            mr={3}
            variant='outline'
            loadingText='参加しない'
          >
            参加しない
          </Button>
          <Button
            isLoading={isLoading}
            onClick={submit}
            loadingText='規約に同意して参加する'
            isDisabled={!scrolledToBtm}
          >
            規約に同意して参加する
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default AffiliateCampaignPage
