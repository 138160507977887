import PageHeader from '../../components/PageHeader'
import {
  ColumnDef,
  SortingState,
} from '@tanstack/react-table'
import { 
  Card, 
  CardBody,
  HStack,
  VStack,
  Box, 
  Text,
} from '@chakra-ui/react'
import ReadOnlyFormItem from '../../components/ReadOnlyFormItem'
import AffiliateCommissionService from '../../network/services/affiliateCommission'
import useSWR from 'swr'
import { CommissionSummary } from '../../network/services/adminAffiliate'
import { useState, useMemo } from 'react'
import { IDataResponse, serialize } from '../../network/request'
import AffiliateTransactionService from '../../network/services/affiliateTransaction'
import { Transaction } from '../../network/services/transaction'
import toJapaneseDate from '../../functions/toJapaneseDate'
import Gaps from '../../components/Gaps'
import CustomTable from '../../components/CustomTable'

const AffiliateDashboardPage = () => {
  const { data: commissionSummary } = useSWR<CommissionSummary>(
    AffiliateCommissionService.getSummary
  )

  return (
    <PageHeader>
      <Card>
        <CardBody>
          <VStack align='stretch'>
            <HStack spacing={0}>
              <Box style={{ marginRight: 8 }}>
                <ReadOnlyFormItem
                  title='紹介料総額'
                  content={`￥${commissionSummary?.total_earned}`}
                  enableCopy={false}
                />
              </Box>
              <Box>
                <ReadOnlyFormItem
                  title='振込可能金額'
                  content={`￥${commissionSummary?.balance}`}
                  enableCopy={false}
                />
              </Box>
            </HStack>
            <Gaps />
            <AffiliateDashboardTransactionTable />
          </VStack>
        </CardBody>
      </Card>
    </PageHeader>
  )
}

const AffiliateDashboardTransactionTable = () => {
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(50)
  const [sorting, setSorting] = useState<SortingState>([]);

  const { data: response } = useSWR<IDataResponse<Transaction>>(
    serialize(AffiliateTransactionService.getAll, {
      page,
      limit: size,
      sort: sorting.length > 0 ? `${sorting[0].id}:${sorting[0].desc ? 'desc': 'asc'}` : 'created_at:desc',
    })
  )

  const rows = response ? AffiliateTransactionService.toRow(response) : []
  const lastPage = response?.meta?.last_page ? response?.meta?.last_page : 1
  const { total } = response
    ? AffiliateTransactionService.toPaginate(response)
    : { total: 0 }

  const columns = useMemo<ColumnDef<Transaction, any>[]>(
    () => [
      {
        id: 'created_at',
        accessorKey: 'created_at',
        header: "日付",
        cell: (info) => info.getValue() ? toJapaneseDate(new Date(info.getValue())) : '-',
      },
      {
        id: 'title',
        accessorKey: 'title',
        header: "タイトル",
      },
      {
        id: 'description',
        accessorKey: 'description',
        header: "説明",
      },
      {
        id: 'amount',
        accessorKey: 'amount',
        header: "金額",
        cell: (info) => (
          <Text style={{ color: info.getValue() > 0 ? 'green' : 'red' }}>
            {info.getValue() > 0 ? '+' : '-'}￥{Math.abs(info.getValue())}
          </Text>
        )
      },
    ],
    []
  )

  return (
    <CustomTable
      {...{
        data: rows,
        columns,
        lastPage,
        page,
        setPage,
        size,
        setSize,
        sorting,
        setSorting,
      }}
    />
  )
}

export default AffiliateDashboardPage
