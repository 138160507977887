import client, { IDataResponse } from '../request'
import { Admin, User } from './auth'
import { Transaction } from './transaction'

export const withdrawStatusText = {
  approved: {
    color: 'green',
    text: '承認された',
  },
  rejected: {
    color: 'red',
    text: '拒否された',
  },
  pending: {
    color: 'blue',
    text: '審査中',
  },
}

export interface Withdraw {
  id: number
  amount: number
  user?: User
  txn_number?: string
  withdraw_fee: number
  approved_by_admin_id?: number
  approved_by_admin?: Admin
  rejected_by_admin_id?: number
  rejected_by_admin?: Admin
  user_id: number
  currency: string
  status: 'pending' | 'approved' | 'rejected'
  reason?: string
  amazon_creation_request_id?: string
  amazon_currency_code?: string
  amazon_request_amount?: string
  rejected_at?: string
  approved_at?: Date
  created_at: Date
  updated_at: Date
  transaction?: Transaction
}

export interface WithdrawApprovalState {
  decision: 'accept' | 'reject'
  reason?: string
}

const getAll = `/admin/withdraws`

const get = (id: string | number) => {
  return `/admin/withdraws/${id}`
}

const approval = (id: string | number, data: WithdrawApprovalState) => {
  return client.post(`/admin/withdraws/${id}/approval`, data)
}

const toRow = (data: IDataResponse<Withdraw>) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id,
      }
    })
  }

  return []
}

const toPaginate = (data: IDataResponse<Withdraw>) => {
  return {
    total: data?.meta?.total ?? 0,
  }
}

const AdminWithdrawService = {
  getAll,
  get,
  approval,
  toRow,
  toPaginate,
}

export default AdminWithdrawService
