import { defineStyleConfig } from "@chakra-ui/react"

const Card = defineStyleConfig({
  baseStyle: {
    header: {
      borderBottom:'1px solid',
      borderColor:'gray.200',
    },
  },
})

export default Card