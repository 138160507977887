import client, { IDataResponse } from '../request'
import { Affiliate } from './adminAffiliate'
import { AdminCampaign } from './adminCampaign'
import { DiscountCode } from './affiliate_discount_code'
import { Product } from './product'

export const affiliateCampaignStatusText = {
  activated: {
    color: 'green',
    text: '参加中',
  },
  inactivated: {
    color: 'red',
    text: '無効',
  },
  pending: {
    color: 'blue',
    text: '未決済',
  },
}

export interface AffiliateCampaign {
  id: string
  affiliate_id: number
  campaign_id: number
  remark?: string
  status: 'pending' | 'activated' | 'inactivated'
  created_at: Date
  updated_at: Date
  campaign: AdminCampaign
  affiliate: Affiliate
  discount_code?: DiscountCode
  total_orders: number
  total_sales: string
  total_commission: string
  affiliate_urls?: AffiliateUrl[]
}

export interface AffiliateUrl {
  id: number
  campaign_affiliate_id: string
  campaign_product_id: number
  campaign_product: {
    product: Product
  }
  url: string
  created_at: Date
  updated_at: Date
}

//swr
const getAll = `/affiliate/campaigns`

const get = (id: string | number) => {
  return `/affiliate/campaigns/${id}`
}

const accept = (id: string | number) => {
  return client.post(`/affiliate/campaigns/${id}/accept`)
}

const toRow = (data: IDataResponse<AffiliateCampaign>) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id,
      }
    })
  }

  return []
}

const toPaginate = (data: IDataResponse<AffiliateCampaign>) => {
  return {
    total: data?.meta?.total ?? 0,
  }
}

const AffiliateCampaignService = {
  getAll,
  get,
  accept,
  toRow,
  toPaginate,
}

export default AffiliateCampaignService
