import { Heading, Text } from '@chakra-ui/react'
import React from 'react'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom'

export default function ErrorPage() {
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    return (
      <div>
        <Heading as='h1'>Oops!</Heading>
        <Heading as='h2' size='lg'>{error.status}</Heading>
        <Text>{error.statusText}</Text>
        {error.data?.message && <p>{error.data.message}</p>}
      </div>
    )
  } else {
    return <Heading as='h1'>Oops</Heading>
  }
}
