import React, { Dispatch, FC, SetStateAction, useState, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import {
  Box,
  Card,
  CardBody,
  Text,
  FormControl,
  FormLabel,
  useToast,
  HStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
  Icon,
  Button,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { ColumnDef, SortingState } from '@tanstack/react-table'
import CustomTable from '../../../components/CustomTable'
import Gaps from '../../../components/Gaps'
import _ from 'lodash'
import ReadOnlyFormItem from '../../../components/ReadOnlyFormItem'
import AdminAffiliateService, {
  Affiliate,
  AffiliateUpdateState,
  CommissionSummary,
} from '../../../network/services/adminAffiliate'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import AdminCommissionService, {
  Commission,
  commissionStatusText,
} from '../../../network/services/adminCommission'
import { serialize } from '../../../network/request'
import toJapaneseDate from '../../../functions/toJapaneseDate'
// import { useMediaQuery } from 'react-responsive'

const AdminAffiliateDetail: FC<{
  formRef: any
  initialValue?: Affiliate
  commissionSummary?: CommissionSummary
  refresh: () => any
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
}> = ({
  formRef,
  initialValue,
  refresh,
  isLoading,
  setIsLoading,
  commissionSummary,
}) => {
  // const { id } = useParams()

  // const navigate = useNavigate()
  const tabItems = [
    {
      key: '1',
      label: '紹介料詳細',
      children: <CampaignCommissionTable />,
    },
    //   {
    //     key: '2',
    //     label: '注文一覧',
    //     children: <CampaignOrderTable />,
    //   },
    //   {
    //     key: '3',
    //     label: 'アフィリエイト一覧',
    //     children: <CampaignAffiliateTable />,
    //   },
  ]

  const toast = useToast()
  let {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<any>({
    shouldUseNativeValidation: false,
    defaultValues: {
      ...initialValue,
      email: initialValue?.user?.email,
    },
  })

  const submit = async (values: AffiliateUpdateState) => {
    if (isLoading) return
    setIsLoading(true)

    if (initialValue?.id) {
      try {
        const { data: result } = await AdminAffiliateService.update(
          initialValue.id,
          values
        )
        if (result.success) {
          toast({
            status: 'success',
            description: 'データが保存されした',
            variant: 'subtle',
            position: 'top',
          })
          refresh()
        }
      } catch (error: any) {
        toast({
          status: 'error',
          description: 'エラーが発生しました。' + error.message,
          variant: 'subtle',
          position: 'top',
        })
      }
    }
    // else {
    //   try {
    //     const { data: result } = await AdminAffiliateService.invite(values)
    //     if (result.success) {
    //       message.success('Create successful')
    //       navigate(`/dashboard/collaborators/${result.data.id}`)
    //     }
    //   } catch (error: any) {
    //     message.error('Create collaborator error ' + error.message)
    //   }
    // }

    setIsLoading(false)
  }

  return (
    <>
      <Box as='form' ref={formRef} onSubmit={handleSubmit(submit)}>
        <Card>
          <CardBody>
            <FormControl isInvalid={Boolean(errors.amount)} marginBottom='16px'>
              <FormLabel>アカウント状態</FormLabel>
              <Menu closeOnSelect={true} autoSelect={false} variant='select'>
                <MenuButton
                  as={Button}
                  rightIcon={<Icon as={ChevronDownIcon} />}
                  variant='select'
                >
                  {watch('status') == 'active' ? '有効' : '無効'}
                </MenuButton>
                <MenuList>
                  <MenuOptionGroup
                    type='radio'
                    value={watch('status')}
                    onChange={(e) => {
                      setValue('status', e)
                    }}
                  >
                    <MenuItemOption
                      {...register('status')}
                      id='active'
                      value={'active'}
                    >
                      有効
                    </MenuItemOption>
                    <MenuItemOption
                      {...register('status')}
                      id='suspended'
                      value={'suspended'}
                    >
                      無効
                    </MenuItemOption>
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
            </FormControl>

            <HStack spacing='16px'>
              <ReadOnlyFormItem
                title='紹介料総額'
                content={`￥${initialValue?.total_commission}`}
                enableCopy={false}
              />

              <ReadOnlyFormItem
                title='連携した注文数'
                content={initialValue?.total_completed_orders}
                enableCopy={false}
              />

              <ReadOnlyFormItem
                title='売上総額'
                content={`￥${initialValue?.total_sales}`}
                enableCopy={false}
              />

              <ReadOnlyFormItem
                title='残高'
                content={`￥${commissionSummary?.balance ?? ''}`}
                enableCopy={false}
              />
            </HStack>

            <Gaps />

            <ReadOnlyFormItem
              variant='unstyled'
              title='メールアドレス'
              content={
                initialValue?.user?.email ?? initialValue?.referral_email
              }
            />

            <ReadOnlyFormItem
              variant='unstyled'
              title='姓'
              content={initialValue?.last_name}
            />

            <ReadOnlyFormItem
              variant='unstyled'
              title='名'
              content={initialValue?.first_name}
            />

            <ReadOnlyFormItem
              variant='unstyled'
              title='国'
              content={initialValue?.country}
            />

            <ReadOnlyFormItem
              variant='unstyled'
              title='都道府県'
              content={initialValue?.province}
            />

            <ReadOnlyFormItem
              variant='unstyled'
              title='市区町村'
              content={initialValue?.city}
            />

            <ReadOnlyFormItem
              variant='unstyled'
              title='郵便番号'
              content={initialValue?.zip}
            />

            <ReadOnlyFormItem
              variant='unstyled'
              title='住所1'
              content={initialValue?.address1}
            />

            <ReadOnlyFormItem
              variant='unstyled'
              title='住所2'
              content={initialValue?.address2}
            />

            <ReadOnlyFormItem
              variant='unstyled'
              title='電話番号'
              content={initialValue?.phone}
            />

            <ReadOnlyFormItem
              variant='unstyled'
              title='会社名'
              content={initialValue?.company}
            />

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            ></div>
          </CardBody>
        </Card>
        <Gaps />
      </Box>

      {initialValue && (
        <Card>
          <CardBody>
            <Tabs defaultIndex={0} isLazy>
              <TabList>
                {tabItems.map((item) => (
                  <Tab key={item.key}>{item.label}</Tab>
                ))}
              </TabList>
              <TabPanels>
                {tabItems.map((item) => (
                  <TabPanel key={item.key}>{item.children}</TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </CardBody>
        </Card>
      )}
    </>
  )
}

const CampaignCommissionTable = () => {
  const { id } = useParams()
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(50)
  const [sorting, setSorting] = useState<SortingState>([])
  const { data: result } = useSWR(
    serialize(AdminCommissionService.getAll, {
      limit: size,
      page,
      affiliate: id,
      sort:
        sorting.length > 0
          ? `${sorting[0].id}:${sorting[0].desc ? 'desc' : 'asc'}`
          : 'created_at:desc',
    })
  )
  const commissions = AdminCommissionService.toRow(result)
  const lastPage = result?.meta?.last_page ? result?.meta?.last_page : 1
  const { total } = AdminCommissionService.toPaginate(result)

  const columns = useMemo<ColumnDef<Commission, any>[]>(
    () => [
      {
        id: 'order_name',
        accessorKey: 'order_name',
        header: '注文',
        cell: (info) => info.row.original.order?.name,
        enableSorting: false,
      },
      {
        id: 'product_name',
        accessorKey: 'product_full_name',
        header: '商品',
        cell: (info) =>
          `${info.getValue()}　【${info.row.original.quantity}個】`,
        enableSorting: false,
      },
      {
        id: 'status',
        accessorKey: 'status',
        header: 'ステータス',
        cell: (info) => (
          <Text
            style={{
              color:
                commissionStatusText[
                  info.getValue() as keyof typeof commissionStatusText
                ].color,
            }}
          >
            {
              commissionStatusText[
                info.getValue() as keyof typeof commissionStatusText
              ].text
            }
          </Text>
        ),
        enableSorting: false,
      },
      {
        id: 'should_issue_at',
        accessorKey: 'should_issue_at',
        header: '発行予定日',
        cell: (info) =>
          info.getValue() ? toJapaneseDate(new Date(info.getValue())) : '-',
        enableSorting: false,
      },
      {
        id: 'issued_at',
        accessorKey: 'issued_at',
        header: '発行日',
        cell: (info) =>
          info.getValue() ? toJapaneseDate(new Date(info.getValue())) : '-',
        enableSorting: false,
      },
      {
        id: 'amount',
        accessorKey: 'amount',
        header: '金額',
        cell: (info) => (
          <Text style={{ color: info.getValue() > 0 ? 'green' : 'red' }}>
            {info.getValue() > 0 ? '+' : '-'}￥{Math.abs(info.getValue())}
          </Text>
        ),
      },
    ],
    []
  )

  return (
    <CustomTable
      {...{
        data: commissions ?? [],
        columns,
        lastPage,
        page,
        setPage,
        size,
        setSize,
        sorting,
        setSorting,
      }}
    />
  )
}

export default AdminAffiliateDetail
