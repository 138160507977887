import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { 
  Button, 
  Spinner,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  FormControl,
  FormErrorMessage,
  Input,
  FormLabel,
} from '@chakra-ui/react'
import Empty from '../../../components/Empty'
import PageHeader from '../../../components/PageHeader'
import useSWR, { KeyedMutator } from 'swr'
import { Dispatch, SetStateAction, useState } from 'react'
import AdminWithdrawService, {
  Withdraw,
} from '../../../network/services/adminWithdraw'
import AdminWithdrawDetail from './AdminWithDrawDetail'

const AdminWithdrawPage = () => {
  const navigate = useNavigate()

  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [approveModal, setApproveModal] = useState(false)
  const [rejectModal, setRejectModal] = useState(false)
  const { isOpen: isApproveModalOpen, onOpen: onApproveModalOpen, onClose: onApproveModalClose } = useDisclosure()
  const { isOpen: isRejectModalOpen, onOpen: onRejectModalOpen, onClose: onRejectModalClose } = useDisclosure()

  // use this to preload
  const {
    data: response,
    error,
    mutate,
  } = useSWR<Withdraw>(id !== 'new' ? AdminWithdrawService.get(id!) : null)

  if (error) {
    console.log(error)
    return <Empty description={`エラーが発生しました。${error?.message}`} />
  }

  if (!response && id !== 'new') {
    return (
      <PageHeader>
        <Spinner />
      </PageHeader>
    )
  }

  const refresh = () => {
    if (id != null) {
      if (id === 'new') {
      } else {
        mutate()
      }
    }
  }

  const approveAction = (
    <Button
      key={'許可する'}
      onClick={() => {
        onApproveModalOpen()
      }}
      isLoading={isLoading}
    >
      許可する
    </Button>
  )

  const rejectAction = (
    <Button
      key={'拒否する'}
      variant='outline'
      onClick={() => {
        onRejectModalOpen()
      }}
      isLoading={isLoading}
    >
      拒否する
    </Button>
  )
  // const discardAction = (
  //   <Button
  //     key={'削除する'}
  //     type='default'
  //     // onClick={async () => {
  //     //   await CollaboratorService.remove(appClientId!, id!)
  //     //   navigate('/dashboard/collaborators')
  //     // }}
  //   >
  //     Discard
  //   </Button>
  // )

  return (
    <PageHeader
      title={'振込申請'}
      onBack={() => navigate('/admin/dashboard/withdraws')}
      extra={
        response?.status == 'pending'
          ? [
              approveAction,
              rejectAction,
              //discardAction
            ]
          : []
      }
    >
      <ApproveModal
        isOpen={isApproveModalOpen}
        onClose={onApproveModalClose}
        mutate={mutate}
      />

      <RejectModal
        isOpen={isRejectModalOpen}
        onClose={onRejectModalClose}
        mutate={mutate}
      />

      <AdminWithdrawDetail
        initialValue={response}
        refresh={refresh}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </PageHeader>
  )
}

const RejectModal = ({
  isOpen,
  onClose,
  mutate,
}: {
  isOpen: boolean
  onClose: () => void
  mutate: KeyedMutator<Withdraw>
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { id } = useParams()
  const toast = useToast()
  let {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors }
  } = useForm<{reason: string}>({
    shouldUseNativeValidation: false
  })

  const submit = async (value: { reason: string }) => {
    setIsLoading(true)
    if (!id) return
    try {
      const { data: result } = await AdminWithdrawService.approval(id, {
        decision: 'reject',
        reason: value.reason,
      })
      if (result.success) {
        toast({
          status: 'success',
          description: '処理が完了しました',
          variant: 'subtle',
          position: 'top',
        })
        await mutate()
        onClose()
      }
    } catch (error: any) {
      toast({
        status: 'error',
        description: 'エラーが発生しました。' + error.message,
        variant: 'subtle',
        position: 'top',
      })
    }

    setIsLoading(false)
  }

  return (
    <Modal
      isOpen={isOpen} 
      onClose={onClose}
      isCentered={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody as='form' onSubmit={handleSubmit(submit)}>
          <FormControl
            isInvalid={Boolean(errors.reason)}
            isRequired
          >
            <FormLabel>理由</FormLabel>
            <Input 
              autoComplete='off' 
              {...register("reason", {
                required: '必要なフィールドです'
            })} />

            <FormErrorMessage>
              {errors.reason? String(errors.reason.message) : ""}
            </FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button isLoading={isLoading} onClick={onClose} mr={3} variant='outline' loadingText='いいえ'>
            いいえ
          </Button>
          <Button isLoading={isLoading} onClick={handleSubmit(submit)} loadingText='はい'>
            はい
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const ApproveModal = ({
  isOpen,
  onClose,
  mutate,
}: {
  isOpen: boolean
  onClose: () => void
  mutate: KeyedMutator<Withdraw>
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { id } = useParams()
  const toast = useToast()

  const submit = async () => {
    setIsLoading(true)
    if (!id) return
    try {
      const { data: result } = await AdminWithdrawService.approval(id, {
        decision: 'accept',
      })
      if (result.success) {
        toast({
          status: 'success',
          description: '処理が完了しました',
          variant: 'subtle',
          position: 'top',
        })
        await mutate()
        onClose()
      }
    } catch (error: any) {
      toast({
        status: 'error',
        description: 'エラーが発生しました。' + error.message,
        variant: 'subtle',
        position: 'top',
      })
    }

    setIsLoading(false)
  }

  return (
    <Modal
      isOpen={isOpen} 
      onClose={onClose}
      isCentered={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text color='red'>
            振込を許可しますか？
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button isLoading={isLoading} onClick={onClose} mr={3} variant='outline' loadingText='いいえ'>
            いいえ
          </Button>
          <Button isLoading={isLoading} onClick={submit} loadingText='はい'>
            はい
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default AdminWithdrawPage
