import { FC, useEffect, useState, useMemo } from 'react'
import { ColumnDef, SortingState } from '@tanstack/react-table'
import { Text, Grid, GridItem } from '@chakra-ui/react'
import CustomTable from '../../../components/CustomTable'
import Empty from '../../../components/Empty'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'

import tableStore from '../../../lib/store/affiliate_campaign_table'
import TableFilter, { ITableFilter } from '../../../components/TableFilter'
import { IDataResponse, serialize } from '../../../network/request'
import Gaps from '../../../components/Gaps'
import AffiliateCampaignService, {
  AffiliateCampaign,
  affiliateCampaignStatusText,
} from '../../../network/services/affiliateCampaign'
import { campaignStatusText } from '../../../network/services/adminCampaign'

const AffiliateCampaignsTable: FC<{ overridePage?: number }> = ({
  overridePage,
}) => {
  const navigate = useNavigate()
  const { state } = useSnapshot(tableStore)
  const [currentFilters, setCurrentFilters] = useState<any>(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([])
  const [visible, setVisible] = useState(false)

  const setPage = (page: number) => tableStore.state.currentPage = page
  const setSize = (size: number) => tableStore.state.pageSize = size

  const [sorting, setSorting] = useState<SortingState>([])
  if (sorting.length > 0) 
    tableStore.state.sort = `${sorting[0].id}:${sorting[0].desc ? 'desc': 'asc'}`

  const { data: response } = useSWR<IDataResponse<AffiliateCampaign>>(
    serialize(AffiliateCampaignService.getAll, {
      page: overridePage ?? state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters,
    })
  )
  const rows = response ? AffiliateCampaignService.toRow(response) : []
  const lastPage = response?.meta?.last_page ? response?.meta?.last_page : 1

  const rowSelection = {
    selectedRowKeys,
    onChange: (keySelected: any[]) => {
      setSelectedRowKeys(keySelected)
    },
  }

  const columns = useMemo<ColumnDef<AffiliateCampaign, any>[]>(
    () => [
      {
        id: 'id',
        accessorKey: 'id',
        header: '個人キャンペーンID',
      },
      {
        id: 'campaign_name',
        accessorKey: 'campaign',
        header: 'キャンペーン名',
        cell: (info) => info.getValue().name,
        enableSorting: false,
      },
      {
        id: 'status',
        accessorKey: 'status',
        header: '参加状態',
        cell: (info) => (
          <Text style={{ 
            color: affiliateCampaignStatusText[info.getValue() as keyof typeof affiliateCampaignStatusText].color
          }}>
            {affiliateCampaignStatusText[info.getValue() as keyof typeof affiliateCampaignStatusText].text}
          </Text>
        ),
      },
      {
        id: 'campaign_status',
        accessorKey: 'campaign',
        header: '開催状態',
        cell: (info) => (
          <Text style={{ 
            color: campaignStatusText[info.getValue().status as keyof typeof campaignStatusText].color
          }}>
            {campaignStatusText[info.getValue().status as keyof typeof campaignStatusText].text}
          </Text>
        ),
        enableSorting: false,
      },
      {
        id: 'total_sales',
        accessorKey: 'total_sales',
        header: '売上総額',
        cell: (info) => `￥${info.getValue()}`,
      },
      {
        id: 'total_commission',
        accessorKey: 'total_commission',
        header: '紹介料総額',
        cell: (info) => `￥${info.getValue()}`,
      },
      {
        id: 'total_orders',
        accessorKey: 'total_orders',
        header: '紹介した注文数',
      },
    ],
    []
  )

  // content={
  //   initialValue.variants.length > 0
  //     ? initialValue.variants.map((item) => item.name).join(',')
  //     : '-'
  // }

  const filterColumns: ITableFilter[] = [
    { key: 'campaign_name', name: 'キャンペーン名' },
  ]
  filterColumns.forEach((item) => {
    if (
      tableStore.state?.filters &&
      Object.keys(tableStore.state.filters).includes(item.key)
    ) {
      item.default_value = tableStore.state.filters[item.key]
      item.default = true
    }
  })

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state.filters = { ...currentFilters }
      tableStore.state.currentPage = 1
    }
  }, [currentFilters])

  const handleMenuClick = (e: any) => {
    setVisible(true)
    // setModalType(e.key)
  }

  const batchDeleteRows = async () => {
    // try {
    //   for (const id of selectedRowKeys) {
    //     const { data } = await ReviewService.remove(id)
    //     if (data?.success === true) {
    //       message.success(`Delete source success`)
    //     } else {
    //       message.error(`Delete source ${id} failed`)
    //     }
    //     tableStore.state.refresh = Math.random()
    //   }
    //   setVisible(false)
    // } catch (e) {
    //   message.error({ content: e.message, className: 'message-error' })
    // }
  }

  // const ActionModal: FC = () => {
  //   return (
  //     <Modal
  //       title={`Remove ${selectedRowKeys.length} reviews`}
  //       open={visible}
  //       onOk={() => {
  //         switch(modalType){
  //           case 'delete':
  //             batchDeleteRows()
  //             break;
  //           case 'publish':

  //         }
  //       }}
  //       onCancel={() => {
  //         setVisible(false)
  //       }}
  //       okText='Delete'
  //       cancelText='Cancel'
  //     >
  //       This can't be undone
  //     </Modal>
  //   )
  // }

  return (
    <>
      {/* <ActionModal /> */}

      <Grid templateColumns='repeat(3, 1fr)' gap={2}>
        <GridItem>
          {/* {selectedRowKeys?.length > 0 ? (
            <Dropdown
              menu={{
                items: [
                  {
                    label: 'Delete',
                    onClick: handleMenuClick,
                    key: 'delete',
                  },
                ],
              }}
              trigger={['click']}
            >
              <Button type='primary'>
                More Actions <CaretDownOutlined />
              </Button>
            </Dropdown>
          ) : (
            <></>
          )} */}
        </GridItem>
        <GridItem colSpan={2}>
          <TableFilter
            dropdowns={[]}
            columns={filterColumns}
            hasDate={false}
            setCurrentFilters={setCurrentFilters}
          />
          <Gaps level={3} />
        </GridItem>
      </Grid>

      {rows?.length > 0 ? (
        <CustomTable
          {...{
            data: rows,
            columns,
            lastPage,
            page: state.currentPage,
            setPage,
            size: state.pageSize,
            setSize,
            sorting,
            setSorting,
            onClickRow: (record) => {
              if (selectedRowKeys?.length > 0) {
                if (selectedRowKeys.includes(record.id)) {
                  const filterKeys = _.filter(
                    [...selectedRowKeys],
                    function (o) {
                      return o !== record.id
                    }
                  )
                  setSelectedRowKeys([...filterKeys])
                } else {
                  setSelectedRowKeys([...selectedRowKeys, record.id])
                }
              } else {
                navigate(`${record.campaign_id}`)
              }
            }, // click row
          }}
        />
      ) : (
        <Empty description={'データが見つかりませんでした'} />
      )}
    </>
  )
}

export default AffiliateCampaignsTable
