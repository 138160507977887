import { FC } from 'react'
import authStore from '../lib/store/auth'
import { Navigate, Outlet } from 'react-router-dom'
import { useSnapshot } from 'valtio'

const RequireAdmin: FC = () => {
  const { state } = useSnapshot(authStore)
  const admin = state?.profile?.admin ?? null

  return admin ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: '/affiliate/dashboard',
      }}
    />
  )
}

export default RequireAdmin
