import { useState, useEffect, FC } from 'react'
import { 
  Center,
  Button, 
  VStack, 
  Card, 
  CardBody, 
  Text, 
  Input, 
  InputGroup, 
  InputRightElement, 
  Icon,
  IconButton,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import { useSnapshot } from 'valtio'
import { Navigate, useLocation, Link, useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import authStore, { getToken, login } from '../lib/store/auth'
import AuthService, { LoginState } from '../network/services/auth'

const Login: FC = () => {
  let location = useLocation()
  const [searchParams] = useSearchParams()
  const { state } = useSnapshot(authStore)
  const [redirectToReferrer, setRedirectToReferrer] = useState(false)
  const [formError, setFormError] = useState(null)
  const [showPassword, setShowPassword] = useState(false)

  let {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors }
  } = useForm<LoginState>({
    shouldUseNativeValidation: false
  })

  useEffect(() => {
    const loggedin = getToken()
    if (loggedin) setRedirectToReferrer(true)
  })

  const handleLogin = async (data: LoginState) => {
    try {
      console.log(data)
      const { data: result } = await AuthService.login(data)

      const profile = result.user
      const token = result.jwt.token

      console.log(result)

      login({ profile, token: token })
      setRedirectToReferrer(true)
      //   globalStore.clearMenu()
      //   tableStore1.reset()
      //   tableStore2.reset()
    } catch (e: any) {
      console.log(e)
      setFormError(e.message)
    }
  }

  const { from } = location.state || {
    from: {
      pathname: `/${state.profile?.admin ? 'admin' : 'affiliate'}/dashboard`,
    },
  }

  if (redirectToReferrer) {
    const redirectUri = searchParams.get('redirect_uri')
    if (redirectUri && redirectUri != undefined) {
      window.location.href = redirectUri
    }
    return <Navigate to={from} />
  }

  return (
    <Center
      justifySelf='center'
      alignSelf='middle'
      style={{ height: '100vh', width: '100%' }}
    >
      <VStack>
        <div
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <img
            src='/casefinite_logo.svg'
            alt=''
            style={{
              objectFit: 'contain',
            }}
            width={200}
          />

          <Text style={{ margin: 0 }}>Partners</Text>
        </div>

        <Card style={{ minWidth: '400px' }}>
          <CardBody>
            <VStack 
              as='form' 
              onSubmit={handleSubmit(handleLogin)}
            >
              <FormControl
                isInvalid={Boolean(errors.email)}
              >
                <Input 
                  placeholder='Email' 
                  autoComplete='off' 
                  {...register("email", {
                    required: 'メールアドレスを入力してください'
                })} />

                <FormErrorMessage>
                  {errors.email? String(errors.email.message) : ""}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={Boolean(errors.password)}
                style={{ marginBottom: 4 }}
              >
                <InputGroup>
                  <Input
                    pr='4.5rem'
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Enter password'
                    {...register("password", {
                      required: 'パスワードを入力してください'
                    })}
                  />

                  <InputRightElement>
                    <IconButton 
                      aria-label={showPassword ? 'Hide' : 'Show'} 
                      icon={<Icon as={showPassword ? EyeIcon : EyeSlashIcon } />}
                      variant='ghost'
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </InputRightElement>
                </InputGroup>
                
                <FormErrorMessage>
                  {errors.password? String(errors.password.message) : ""}
                </FormErrorMessage>
              </FormControl>

              <p style={{ color: 'red', textTransform: 'capitalize' }}>
                {formError}
              </p>

              <Button 
                type='submit'
                onClick={() => {
                  clearErrors()
                }}
              >
                ログイン
              </Button>
            </VStack>
          </CardBody>
        </Card>
      </VStack>
    </Center>
  )
}

export default Login
