import { proxy, subscribe } from 'valtio'
import _ from 'lodash'

const initialTableAttr: {
  filters?: any
  sort?: string | null
  currentPage: number
  pageSize: number
  prevUrl?: string
  refresh?: number
} = {
  filters: null,
  sort: null,
  currentPage: 1,
  pageSize: 10,
  prevUrl: '',
  refresh: 0,
}

const storageName = 'affiliate_commission_table'
let storedTableAttr = { ...initialTableAttr }
let data = localStorage.getItem(storageName)
if (window.localStorage && data) {
  let result = JSON.parse(data)
  if (!_.isEmpty(result)) {
    console.log(result)
    storedTableAttr = result
  }
}

const tableAttr = proxy({
  state: storedTableAttr,
  reset() {
    tableAttr.state = { ...initialTableAttr }
    localStorage.setItem(storageName, JSON.stringify(initialTableAttr))
  },
})

subscribe(tableAttr, () => {
  console.log('state.arr has changed to', tableAttr.state)
  localStorage.setItem(storageName, JSON.stringify(tableAttr.state))
})

export default tableAttr
