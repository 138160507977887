import React, { useState, ReactNode } from 'react'
import { useSnapshot } from 'valtio'
import authStore from '../lib/store/auth'
import { 
  HomeIcon, 
  StarIcon, 
  BookOpenIcon, 
  CurrencyDollarIcon, 
  UserIcon,
  ShoppingBagIcon,
  DocumentMagnifyingGlassIcon,
} from '@heroicons/react/24/outline'

const useSidebarMenu = () => {
  const { state } = useSnapshot(authStore)
  const admin = state?.profile?.admin ?? null
  const [sidebarMenu] = useState<
    {
      name: string
      route: string
      icon: any
    }[]
  >(
    admin
      ? [
          {
            name: 'アフィリエイト管理',
            route: '/admin/dashboard/affiliates',
            icon: UserIcon,
          },
          {
            name: 'キャンペーン管理',
            route: '/admin/dashboard/campaigns',
            icon: StarIcon,
          },
          {
            name: '注文一覧',
            route: '/admin/dashboard/orders',
            icon: BookOpenIcon,
          },
          {
            name: '商品一覧',
            route: '/admin/dashboard/products',
            icon: ShoppingBagIcon,
          },
          {
            name: '振込申請',
            route: '/admin/dashboard/withdraws',
            icon: CurrencyDollarIcon,
          },
          // {
          //   name: '設定',
          //   route: '/admin/dashboard/settings',
          //   icon: <Cog6ToothIcon width='20px' height='20px' />,
          // },
        ]
      : [
          {
            name: 'ホームページ',
            route: '/affiliate/dashboard',
            icon: HomeIcon,
          },
          {
            name: '参加中キャンペーン一覧',
            route: '/affiliate/dashboard/campaigns',
            icon: StarIcon,
          },
          {
            name: '紹介料履歴',
            route: '/affiliate/dashboard/commissions',
            icon: BookOpenIcon,
          },
          {
            name: '報酬履歴',
            route: '/affiliate/dashboard/transactions',
            icon: DocumentMagnifyingGlassIcon,
          },
          {
            name: '振込申請',
            route: '/affiliate/dashboard/withdraws',
            icon: CurrencyDollarIcon,
          },
        ]
  )

  return sidebarMenu
}

export default useSidebarMenu
