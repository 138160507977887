import {
  useState,
  useEffect,
  FC,
  useMemo,
  Dispatch,
  SetStateAction,
  UIEvent,
  createContext,
  useContext,
} from 'react'
import { 
  Center,
  Button, 
  VStack, 
  Card, 
  CardBody, 
  Text, 
  Input, 
  InputGroup, 
  InputRightElement, 
  Icon,
  IconButton,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Step,
  StepIndicator,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  useToast,
  Select,
  Checkbox,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
} from '@chakra-ui/react'
import { 
  EyeIcon, 
  EyeSlashIcon, 
  KeyIcon, 
  UserIcon,
  IdentificationIcon,
  DocumentCheckIcon,
  CheckCircleIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/outline'
import SearchableSelect from '../components/SearchableSelect'
import { useForm } from 'react-hook-form'
import { useSearchParams, useNavigate } from 'react-router-dom'

import AuthService, { AffiliateSignupState } from '../network/services/auth'
import useSWR from 'swr'
import Gaps from '../components/Gaps'
import _ from 'lodash'
import { prefectures } from '../constants/prefectures'
import { cities } from '../constants/cities'
import TNC from '../components/TNC'

interface PasswordState {
  password: string
  password_confirm: string
}

interface PersonalInfoState {
  first_name: string
  last_name: string
  phone: string
  country: string
  province: string
  city: string
  zip: string
  address1: string
  address2?: string
  company?: string
}

interface SNSInfoState {
  twitter_account_url?: string
  youtube_account_url?: string
  facebook_account_url?: string
  instagram_account_url?: string
}

const SignupContext = createContext<{
  nextStepAction?: () => void
  goBackAction?: () => void
  submitAction?: () => void
  setData?: Dispatch<SetStateAction<object>>
}>({})

const steps = [
  {
    title: 'パスワード設定',
    icon: <Icon as={KeyIcon} />,
  },
  {
    title: '個人情報',
    icon: <Icon as={UserIcon} />,
  },
  {
    title: 'SNS情報',
    icon: <Icon as={IdentificationIcon} />,
  },
  {
    title: '利用規約',
    icon: <Icon as={DocumentCheckIcon} />,
  },
  {
    title: '登録完了',
    icon: <Icon as={CheckCircleIcon} />,
  },
]

const AffiliateSignUp: FC = () => {
  const [currentStep, setCurrentStep] = useState(0)
  // let location = useLocation()
  const [searchParams] = useSearchParams()
  const [data, setData] = useState<object>({})
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const token = useMemo(
    () => searchParams.get('token') ?? null,
    [searchParams.get('token')]
  )
  const { data: tokenValidity, error: tokenError } = useSWR(
    token ? AuthService.verifySignupToken(token) : null
  )

  const toast = useToast()

  const { goToNext, goToPrevious, setActiveStep, activeStep } = useSteps({
    index: 0,
    count: steps.length
  })

  const handleSubmit = async () => {
    if (loading) return
    setLoading(true)
    await new Promise(r => setTimeout(r, 2000));
    try {
      const { data: result } = await AuthService.affiliateSignUp({
        ...data,
        token: token ?? '',
      } as AffiliateSignupState)

      if (result.success) {
        // setCurrentStep(4)
        setActiveStep(4)
      }
    } catch (e: any) {
      toast({ 
        description: `エラーが発生しました ${_.toString(e?.message)}`,
        status: 'error',
        variant: 'subtle',
        position: 'top',
      })
      // setFormError(e.message)
    }
    setLoading(false)
  }

  // const { from } = location.state || {
  //   from: {
  //     pathname: `/`,
  //   },
  // }

  // if (redirectToReferrer) {
  //   const redirectUri = searchParams.get('redirect_uri')
  //   if (redirectUri && redirectUri != undefined) {
  //     window.location.href = redirectUri
  //   }
  //   return <Navigate to={from} />
  // }
  return (
    <Center
      justifySelf='center'
      alignSelf='middle'
      minH='100vh'
      w='100%'
      paddingY='2rem'
    >
      <VStack>
        <div
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <img
            src='/casefinite_logo.svg'
            alt=''
            style={{
              objectFit: 'contain',
            }}
            width={200}
          />

          <Text style={{ margin: 0 }}>Partners</Text>
        </div>
        {/* 
        {!token || tokenError ? (
          <Card
            style={{
              width: 800,
            }}
          >
            <div
              style={{
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <Typography.Text style={{ textAlign: 'center' }}>
                エラーが発生しました
              </Typography.Text>

              <Typography.Text style={{ color: 'red' }}>
                {tokenError.message}
              </Typography.Text>
            </div>
          </Card>
        ) : ( */}
        <>
          <Stepper index={activeStep} w='full'>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepIndicator>
                  <StepStatus
                    complete={step.icon}
                    incomplete={step.icon}
                    active={step.icon}
                  />
                </StepIndicator>
                <StepTitle>{step.title}</StepTitle>
                <StepSeparator />
              </Step>
            ))}
          </Stepper>
          <SignupContext.Provider
            value={{
              nextStepAction: goToNext,
              goBackAction: goToPrevious,
              submitAction: handleSubmit,
              setData,
            }}
          >
            <Card style={{ width: 800 }}>
              <CardBody>
                <PasswordStep display={activeStep == 0} />
                <PersonalInfoStep display={activeStep == 1} />
                <SNSStep display={activeStep == 2} />
                <TNCStep display={activeStep == 3} />
                <div
                  style={{
                    display: activeStep == 4 ? 'flex' : 'none',
                    width: '100%',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    isLoading={loading}
                    onClick={() => {
                      navigate('/')
                    }}
                  >
                    ログインページへ
                  </Button>
                </div>
              </CardBody>
            </Card>
          </SignupContext.Provider>
        </>
        {/* )} */}
      </VStack>
    </Center>
  )
}

const PasswordStep = ({ display }: { display: boolean }) => {
  const { nextStepAction, setData } = useContext(SignupContext)
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
  const [formError, setFormError] = useState<string | null>(null)

  let {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors }
  } = useForm<PasswordState>({
    shouldUseNativeValidation: false
  })
  
  const submit = (value: PasswordState) => {
    setFormError(null)
    if (value.password !== value.password_confirm) {
      setFormError('パスワードが一致していません、もう一度確認してください')
      return
    }
    setData &&
      setData((data) => ({
        ...data,
        ...value,
      }))
    nextStepAction && nextStepAction()
  }

  return (
    <div style={{ display: display ? 'block' : 'none' }}>
      <VStack 
        as='form' 
        onSubmit={handleSubmit(submit)}
      >
        <FormControl
          isInvalid={Boolean(errors.password)}
          isRequired
        >
          <FormLabel>パスワード</FormLabel>
          <InputGroup>
            <Input
              pr='4.5rem'
              type={showPassword ? 'text' : 'password'}
              {...register("password", {
                required: 'このフィールドは必要です',
                minLength: { value: 8, message: 'パスワードを8文字以上に設定してください' },
              })}
            />

            <InputRightElement>
              <IconButton 
                aria-label={showPassword ? 'Hide' : 'Show'} 
                icon={<Icon as={showPassword ? EyeIcon : EyeSlashIcon } />}
                variant='ghost'
                onClick={() => setShowPassword(!showPassword)}
              />
            </InputRightElement>
          </InputGroup>
          
          <FormErrorMessage>
            {errors.password? String(errors.password.message) : ""}
          </FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={Boolean(errors.password_confirm)}
          isRequired
        >
          <FormLabel>パスワード</FormLabel>
          <InputGroup>
            <Input
              pr='4.5rem'
              type={showPasswordConfirm ? 'text' : 'password'}
              {...register("password_confirm", {
                required: 'このフィールドは必要です',
              })}
            />

            <InputRightElement>
              <IconButton 
                aria-label={showPasswordConfirm ? 'Hide' : 'Show'} 
                icon={<Icon as={showPasswordConfirm ? EyeIcon : EyeSlashIcon } />}
                variant='ghost'
                onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
              />
            </InputRightElement>
          </InputGroup>
          
          <FormErrorMessage>
            {errors.password_confirm? String(errors.password_confirm.message) : ""}
          </FormErrorMessage>
        </FormControl>
      </VStack>

      <p style={{ color: 'red', textTransform: 'capitalize' }}>{formError}</p>
      <Gaps />
      <div
        style={{
          // display: currentStep < 4 ? 'flex' : 'none',
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
        }}
      >
        {/* <div>
                      {currentStep != 0 && currentStep != 4 && (
                        <Button
                          onClick={() => {
                            setCurrentStep((prev) => prev - 1)
                          }}
                        >
                          前へ
                        </Button>
                      )}
                    </div> */}
        <div>
          <Button
            onClick={() => {
              clearErrors()
              handleSubmit(submit)()
            }}
          >
            次へ
          </Button>
        </div>
      </div>
      {/* <p style={{ color: 'red', textTransform: 'capitalize' }}>{formError}</p> */}
    </div>
  )
}

const PersonalInfoStep = ({ display }: { display: boolean }) => {
  const { nextStepAction, setData, goBackAction } = useContext(SignupContext)

  let {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors }
  } = useForm<PersonalInfoState>({
    shouldUseNativeValidation: false,
    defaultValues: {
      country: 'Japan',
    }
  })

  const submit = (value: PersonalInfoState) => {
    convertEmptyStringToUndefined(value)
    setData &&
      setData((data) => ({
        ...data,
        ...value,
      }))
    nextStepAction && nextStepAction()
  }
  const province = watch('province')

  const provinceCode = useMemo(() => {
    return prefectures.find((e) => e.name == province)?.code ?? null
  }, [province])

  useEffect(() => {
    // Invalidate selected city value if province selection changed
    if (province) setValue('city', '')
  }, [province])

  return (
    <div style={{ display: display ? 'block' : 'none' }}>
      <VStack 
        as='form' 
        onSubmit={handleSubmit(submit)}
      >
        <FormControl
          isInvalid={Boolean(errors.last_name)}
          isRequired
        >
          <FormLabel>姓</FormLabel>
          <Input 
            autoComplete='off'
            {...register("last_name", {
              required: 'このフィールドは必要です'
            })}
          />

          <FormErrorMessage>
            {errors.last_name? String(errors.last_name.message) : ""}
          </FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={Boolean(errors.first_name)}
          isRequired
        >
          <FormLabel>名</FormLabel>
          <Input 
            autoComplete='off'
            {...register("first_name", {
              required: 'このフィールドは必要です'
            })} 
          />

          <FormErrorMessage>
            {errors.first_name? String(errors.first_name.message) : ""}
          </FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={Boolean(errors.phone)}
          isRequired
        >
          <FormLabel>電話番号</FormLabel>
          <Input 
            autoComplete='off'
            {...register("phone", {
              required: 'このフィールドは必要です'
            })} 
          />

          <FormErrorMessage>
            {errors.phone? String(errors.phone.message) : ""}
          </FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={Boolean(errors.country)}
          isRequired
        >
          <FormLabel>国</FormLabel>
          <Menu closeOnSelect={true} autoSelect={false} variant='select'>
            <MenuButton 
              as={Button} 
              rightIcon={<Icon as={ChevronDownIcon} />}
              variant='select'
              isDisabled
              w='full'
            >
              {watch('country') == 'Japan' ? '日本' : ''}
            </MenuButton>
            <MenuList>
              <MenuOptionGroup 
                type='radio' 
                value={watch('country')}
                onChange={(e) => {
                  setValue('country', String(e))
                }}
              >
                <MenuItemOption {...register('country', { required: 'このフィールドは必要です' })} value={'Japan'}>
                  日本
                </MenuItemOption>
              </MenuOptionGroup>
            </MenuList>
          </Menu>

          <FormErrorMessage>
            {errors.country? String(errors.country.message) : ""}
          </FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={Boolean(errors.province)}
          isRequired
        >
          <FormLabel>都道府県</FormLabel>
          <SearchableSelect
            name='province'
            options={prefectures.map((e) => ({
              value: e.name,
              label: e.name,
            }))}
            register={register}
            setValue={setValue}
            rules={{required: 'このフィールドは必要です'}}
          />
          <FormErrorMessage>
            {errors.province? String(errors.province.message) : ""}
          </FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={Boolean(errors.city)}
          isRequired
        >
          <FormLabel>市区町村</FormLabel>
          <SearchableSelect
            name='city'
            isDisabled={!province}
            options={cities
              .filter((e) => e.pref_code == provinceCode)
              .map((e) => ({
                value: e.name,
                label: e.name,
              }))
            }
            register={register}
            setValue={setValue}
            rules={{required: 'このフィールドは必要です'}}
          />
          <FormErrorMessage>
            {errors.city? String(errors.city.message) : ""}
          </FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={Boolean(errors.zip)}
          isRequired
        >
          <FormLabel>郵便番号</FormLabel>
          <Input 
            {...register("zip", {
              required: 'このフィールドは必要です'
            })} 
          />

          <FormErrorMessage>
            {errors.zip? String(errors.zip.message) : ""}
          </FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={Boolean(errors.address1)}
          isRequired
        >
          <FormLabel>住所1</FormLabel>
          <Input 
            {...register("address1", {
              required: 'このフィールドは必要です'
            })} 
          />

          <FormErrorMessage>
            {errors.address1? String(errors.address1.message) : ""}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.address2)}>
          <FormLabel>住所2</FormLabel>
          <Input 
            autoComplete='off'
            {...register("address2")} 
          />

          <FormErrorMessage>
            {errors.address2? String(errors.address2.message) : ""}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.company)}>
          <FormLabel>会社名</FormLabel>
          <Input 
            autoComplete='off'
            {...register("company")} 
          />

          <FormErrorMessage>
            {errors.company? String(errors.company.message) : ""}
          </FormErrorMessage>
        </FormControl>
      </VStack>

      <Gaps />
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Button
            onClick={() => {
              goBackAction && goBackAction()
            }}
          >
            前へ
          </Button>
        </div>
        <div>
          <Button
            onClick={() => {
              clearErrors()
              handleSubmit(submit)()
            }}
          >
            次へ
          </Button>
        </div>
      </div>
    </div>
  )
}

const SNSStep = ({ display }: { display: boolean }) => {
  const { nextStepAction, setData, goBackAction } = useContext(SignupContext)

  let {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors }
  } = useForm<SNSInfoState>({
    shouldUseNativeValidation: false
  })

  const submit = (value: SNSInfoState) => {
    convertEmptyStringToUndefined(value)
    setData &&
      setData((data) => ({
        ...data,
        ...value,
      }))
    nextStepAction && nextStepAction()
  }

  return (
    <div style={{ display: display ? 'block' : 'none' }}>
      <VStack 
        as='form' 
        onSubmit={handleSubmit(submit)}
      >
        <FormControl isInvalid={Boolean(errors.twitter_account_url)}>
          <FormLabel>TwitterアカウントURL</FormLabel>
          <Input {...register("twitter_account_url")} />
          <FormErrorMessage>
            {errors.twitter_account_url? String(errors.twitter_account_url.message) : ""}
          </FormErrorMessage>
        </FormControl>
        
        <FormControl isInvalid={Boolean(errors.youtube_account_url)}>
          <FormLabel>YoutubeチャンネルURL</FormLabel>
          <Input {...register("youtube_account_url")} />
          <FormErrorMessage>
            {errors.youtube_account_url? String(errors.youtube_account_url.message) : ""}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.facebook_account_url)}>
          <FormLabel>FacebookアカウントURL</FormLabel>
          <Input {...register("facebook_account_url")} />
          <FormErrorMessage>
            {errors.facebook_account_url? String(errors.facebook_account_url.message) : ""}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.instagram_account_url)}>
          <FormLabel>InstagramアカウントURL</FormLabel>
          <Input {...register("instagram_account_url")} />
          <FormErrorMessage>
            {errors.instagram_account_url? String(errors.instagram_account_url.message) : ""}
          </FormErrorMessage>
        </FormControl>
      </VStack>

      <Gaps />
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Button
            onClick={() => {
              goBackAction && goBackAction()
            }}
          >
            前へ
          </Button>
        </div>
        <div>
          <Button
            onClick={() => {
              clearErrors()
              handleSubmit(submit)()
            }}
          >
            次へ
          </Button>
        </div>
      </div>
    </div>
  )
}

const TNCStep = ({ display }: { display: boolean }) => {
  const [scrolledToBtm, setScrolledToBtm] = useState(false)
  const [checked, setChecked] = useState(false)
  const { goBackAction, submitAction } = useContext(SignupContext)
  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const bottom =
      e.currentTarget?.scrollHeight - e.currentTarget?.clientHeight <=
      e.currentTarget?.scrollTop

    if (bottom) {
      setScrolledToBtm(true)
    }
  }

  return (
    <div style={{ display: display ? 'block' : 'none' }}>
      <div
        onScroll={handleScroll}
        style={{
          maxHeight: 600,
          overflowY: 'scroll',
        }}
      >
        <TNC />
      </div>
      <Gaps />
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'end',
        }}
      >
        <Checkbox
          isDisabled={!scrolledToBtm}
          onChange={() => setChecked(!checked)}
          // isChecked={checked}
        >
          利用規約に同意する
        </Checkbox>
      </div>
      <Gaps />
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Button
            onClick={() => {
              goBackAction && goBackAction()
            }}
          >
            前へ
          </Button>
        </div>
        <div>
          <Button isDisabled={!checked} onClick={submitAction}>
            登録する
          </Button>
        </div>
      </div>
    </div>
  )
}

const convertEmptyStringToUndefined = (data: any) => {
  Object.keys(data).forEach(key => {
    if (data[key] === '') {
      data[key] = undefined
    }
  })
}

export default AffiliateSignUp
