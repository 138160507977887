import { User } from './auth'
import client, { IDataResponse } from '../request'

export const affiliateStatusText = {
  active: {
    color: 'green',
    text: '有効',
  },
  suspended: {
    color: 'red',
    text: '無効',
  },
  pending: {
    color: 'blue',
    text: '未決済',
  },
}

export type Affiliate = {
  id: string
  user_id: string
  type: 'professional' | 'referral'
  referral_email?: string
  first_name?: string
  last_name?: string
  created_at: Date
  updated_at: Date
  twitter_account_url?: string
  youtube_account_url?: string
  facebook_account_url?: string
  instagram_account_url?: string
  country?: string
  province?: string
  city?: string
  zip?: string
  address1?: string
  address2?: string
  phone?: string
  company?: string
  status: 'active' | 'suspended' | 'pending'
  user?: User
  total_completed_orders: number
  total_sales: number
  total_commission: number
}

export type AffiliateUpdateState = {
  // first_name?: string
  // last_name?: string
  // twitter_account_url?: string
  // youtube_account_url?: string
  // facebook_account_url?: string
  // instagram_account_url?: string
  // country?: string
  // province?: string
  // city?: string
  // zip?: string
  // address1?: string
  // address2?: string
  // phone?: string
  // company?: string
  status: 'active' | 'suspended' | 'pending'
}

export interface CommissionSummary {
  total_earned: number
  balance: number
}

//swr
const getAll = `/admin/affiliates`

const get = (id: string | number) => {
  return `/admin/affiliates/${id}`
}

const getOrders = (id: string | number) => {
  return `/admin/affiliates/${id}/orders`
}

const getCommissions = (id: string | number) => {
  return `/admin/affiliates/${id}/commissions`
}

const getCommissionSummary = (id: string | number) => {
  return `/admin/affiliates/${id}/commission-summary`
}

const getWithdraws = (id: string | number) => {
  return `/admin/affiliates/${id}/withdraws`
}

const getTransactions = (id: string | number) => {
  return `/admin/affiliates/${id}/transactions`
}

const update = (id: string | number, data: AffiliateUpdateState) => {
  return client.put(`/admin/affiliates/${id}`, data)
}

const remove = (appClientId: string, id: string | number) => {
  return client.delete(`/admin/affiliates/${id}`)
}

const toRow = (data: IDataResponse<Affiliate>) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id,
      }
    })
  }

  return []
}

const toPaginate = (data: IDataResponse<Affiliate>) => {
  return {
    total: data?.meta?.total ?? 0,
  }
}

const AdminAffiliateService = {
  update,
  getAll,
  get,
  getOrders,
  getCommissionSummary,
  getCommissions,
  getTransactions,
  getWithdraws,
  toRow,
  toPaginate,
  remove,
}

export default AdminAffiliateService
