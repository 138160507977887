import { defineStyleConfig } from "@chakra-ui/react"

const Tabs = defineStyleConfig({
  baseStyle: {
    tab: {
      _selected: {
        color: 'brand.primary',
        borderBottomWidth: '2px',
      }
    },
    tablist: {
      color: 'gray',
    }
  },
})

export default Tabs