import { Button, Card, CardBody, Spinner } from '@chakra-ui/react'
import Empty from '../../../components/Empty'
import PageHeader from '../../../components/PageHeader'
import useSWR from 'swr'
import { useSnapshot } from 'valtio'

import tableStore from '../../../lib/store/admin_campaign_table'
import { serialize } from '../../../network/request'
import AdminCampaignsTable from './AdminCampaignTable'
import { useNavigate } from 'react-router-dom'
import AdminCampaignService from '../../../network/services/adminCampaign'
const AdminCampaignsPage = () => {
  const navigate = useNavigate()
  const primaryAction = (
    <Button
      key='add'
      onClick={() => {
        navigate('new')
      }}
    >
      キャンペーを作成する
    </Button>
  )

  return (
    <PageHeader title='キャンペーン管理' extra={[primaryAction]}>
      <Card>
        <CardBody>
          <AdminCampaignsTableWrapper />
        </CardBody>
      </Card>
    </PageHeader>
  )
}

const AdminCampaignsTableWrapper = () => {
  const { state } = useSnapshot(tableStore)

  // use this to preload
  const { data: response, error } = useSWR(
    serialize(AdminCampaignService.getAll, {
      page: 1,
      limit: state.pageSize,
    })
  )

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response) {
    return <Spinner />
  }

  const { total } = AdminCampaignService.toPaginate(response)

  return (
    <>
      <AdminCampaignsTable total={total} />
      {/* preload next page */}
      {state.currentPage * state.pageSize < total && (
        <div style={{ display: 'none' }}>
          <AdminCampaignsTable
            total={total}
            overridePage={state.currentPage + 1}
          />
        </div>
      )}
    </>
  )
}

export default AdminCampaignsPage
