export const cities = [
  {
    pref_code: '01',
    city_code: '100',
    name: '札幌市',
    kana_name: { half_upper: 'ｻﾂﾎﾟﾛｼ', full_lower: 'サッポロシ' },
    en: null,
    start_date: '1972-04-01',
    end_date: null,
    remark: { start: 'S47/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '101',
    name: '札幌市　中央区',
    kana_name: {
      half_upper: 'ｻﾂﾎﾟﾛｼ ﾁﾕｳｵｳｸ',
      full_lower: 'サッポロシ　チュウオウク',
    },
    en: null,
    start_date: '1972-04-01',
    end_date: null,
    remark: { start: 'S47/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '102',
    name: '札幌市　北区',
    kana_name: { half_upper: 'ｻﾂﾎﾟﾛｼ ｷﾀｸ', full_lower: 'サッポロシ　キタク' },
    en: null,
    start_date: '1972-04-01',
    end_date: null,
    remark: { start: 'S47/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '103',
    name: '札幌市　東区',
    kana_name: {
      half_upper: 'ｻﾂﾎﾟﾛｼ ﾋｶﾞｼｸ',
      full_lower: 'サッポロシ　ヒガシク',
    },
    en: null,
    start_date: '1972-04-01',
    end_date: null,
    remark: { start: 'S47/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '104',
    name: '札幌市　白石区',
    kana_name: {
      half_upper: 'ｻﾂﾎﾟﾛｼ ｼﾛｲｼｸ',
      full_lower: 'サッポロシ　シロイシク',
    },
    en: null,
    start_date: '1972-04-01',
    end_date: null,
    remark: { start: 'S47/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '105',
    name: '札幌市　豊平区',
    kana_name: {
      half_upper: 'ｻﾂﾎﾟﾛｼ ﾄﾖﾋﾗｸ',
      full_lower: 'サッポロシ　トヨヒラク',
    },
    en: null,
    start_date: '1972-04-01',
    end_date: null,
    remark: { start: 'S47/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '106',
    name: '札幌市　南区',
    kana_name: {
      half_upper: 'ｻﾂﾎﾟﾛｼ ﾐﾅﾐｸ',
      full_lower: 'サッポロシ　ミナミク',
    },
    en: null,
    start_date: '1972-04-01',
    end_date: null,
    remark: { start: 'S47/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '107',
    name: '札幌市　西区',
    kana_name: { half_upper: 'ｻﾂﾎﾟﾛｼ ﾆｼｸ', full_lower: 'サッポロシ　ニシク' },
    en: null,
    start_date: '1972-04-01',
    end_date: null,
    remark: { start: 'S47/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '108',
    name: '札幌市　厚別区',
    kana_name: {
      half_upper: 'ｻﾂﾎﾟﾛｼ ｱﾂﾍﾞﾂｸ',
      full_lower: 'サッポロシ　アツベツク',
    },
    en: null,
    start_date: '1989-11-06',
    end_date: null,
    remark: {
      start: 'H01/11/06白石区(01104)から分離',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '109',
    name: '札幌市　手稲区',
    kana_name: {
      half_upper: 'ｻﾂﾎﾟﾛｼ ﾃｲﾈｸ',
      full_lower: 'サッポロシ　テイネク',
    },
    en: null,
    start_date: '1989-11-06',
    end_date: null,
    remark: { start: 'H01/11/06西区(01107)から分離', end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '110',
    name: '札幌市　清田区',
    kana_name: {
      half_upper: 'ｻﾂﾎﾟﾛｼ ｷﾖﾀｸ',
      full_lower: 'サッポロシ　キヨタク',
    },
    en: null,
    start_date: '1997-11-04',
    end_date: null,
    remark: {
      start: 'H09/11/04豊平区(01105)から分離',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '201',
    name: '札幌市',
    kana_name: { half_upper: 'ｻﾂﾎﾟﾛｼ', full_lower: 'サッポロシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-04-01',
    remark: { start: null, end: '01100札幌市', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '202',
    name: '函館市',
    kana_name: { half_upper: 'ﾊｺﾀﾞﾃｼ', full_lower: 'ハコダテシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '203',
    name: '小樽市',
    kana_name: { half_upper: 'ｵﾀﾙｼ', full_lower: 'オタルシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '204',
    name: '旭川市',
    kana_name: { half_upper: 'ｱｻﾋｶﾜｼ', full_lower: 'アサヒカワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '205',
    name: '室蘭市',
    kana_name: { half_upper: 'ﾑﾛﾗﾝｼ', full_lower: 'ムロランシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '206',
    name: '釧路市',
    kana_name: { half_upper: 'ｸｼﾛｼ', full_lower: 'クシロシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '207',
    name: '帯広市',
    kana_name: { half_upper: 'ｵﾋﾞﾋﾛｼ', full_lower: 'オビヒロシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '208',
    name: '北見市',
    kana_name: { half_upper: 'ｷﾀﾐｼ', full_lower: 'キタミシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '209',
    name: '夕張市',
    kana_name: { half_upper: 'ﾕｳﾊﾞﾘｼ', full_lower: 'ユウバリシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '210',
    name: '岩見沢市',
    kana_name: { half_upper: 'ｲﾜﾐｻﾞﾜｼ', full_lower: 'イワミザワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '211',
    name: '網走市',
    kana_name: { half_upper: 'ｱﾊﾞｼﾘｼ', full_lower: 'アバシリシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '212',
    name: '留萌市',
    kana_name: { half_upper: 'ﾙﾓｲｼ', full_lower: 'ルモイシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '213',
    name: '苫小牧市',
    kana_name: { half_upper: 'ﾄﾏｺﾏｲｼ', full_lower: 'トマコマイシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '214',
    name: '稚内市',
    kana_name: { half_upper: 'ﾜﾂｶﾅｲｼ', full_lower: 'ワッカナイシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '215',
    name: '美唄市',
    kana_name: { half_upper: 'ﾋﾞﾊﾞｲｼ', full_lower: 'ビバイシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '216',
    name: '芦別市',
    kana_name: { half_upper: 'ｱｼﾍﾞﾂｼ', full_lower: 'アシベツシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '217',
    name: '江別市',
    kana_name: { half_upper: 'ｴﾍﾞﾂｼ', full_lower: 'エベツシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '218',
    name: '赤平市',
    kana_name: { half_upper: 'ｱｶﾋﾞﾗｼ', full_lower: 'アカビラシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '219',
    name: '紋別市',
    kana_name: { half_upper: 'ﾓﾝﾍﾞﾂｼ', full_lower: 'モンベツシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '220',
    name: '士別市',
    kana_name: { half_upper: 'ｼﾍﾞﾂｼ', full_lower: 'シベツシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '221',
    name: '名寄市',
    kana_name: { half_upper: 'ﾅﾖﾛｼ', full_lower: 'ナヨロシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '222',
    name: '三笠市',
    kana_name: { half_upper: 'ﾐｶｻｼ', full_lower: 'ミカサシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '223',
    name: '根室市',
    kana_name: { half_upper: 'ﾈﾑﾛｼ', full_lower: 'ネムロシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '224',
    name: '千歳市',
    kana_name: { half_upper: 'ﾁﾄｾｼ', full_lower: 'チトセシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '225',
    name: '滝川市',
    kana_name: { half_upper: 'ﾀｷｶﾜｼ', full_lower: 'タキカワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '226',
    name: '砂川市',
    kana_name: { half_upper: 'ｽﾅｶﾞﾜｼ', full_lower: 'スナガワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '227',
    name: '歌志内市',
    kana_name: { half_upper: 'ｳﾀｼﾅｲｼ', full_lower: 'ウタシナイシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '228',
    name: '深川市',
    kana_name: { half_upper: 'ﾌｶｶﾞﾜｼ', full_lower: 'フカガワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '229',
    name: '富良野市',
    kana_name: { half_upper: 'ﾌﾗﾉｼ', full_lower: 'フラノシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '230',
    name: '登別市',
    kana_name: { half_upper: 'ﾉﾎﾞﾘﾍﾞﾂｼ', full_lower: 'ノボリベツシ' },
    en: null,
    start_date: '1970-08-01',
    end_date: null,
    remark: { start: 'S45/08/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '231',
    name: '恵庭市',
    kana_name: { half_upper: 'ｴﾆﾜｼ', full_lower: 'エニワシ' },
    en: null,
    start_date: '1970-11-01',
    end_date: null,
    remark: { start: 'S45/11/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '232',
    name: '亀田市',
    kana_name: { half_upper: 'ｶﾒﾀﾞｼ', full_lower: 'カメダシ' },
    en: null,
    start_date: '1971-11-01',
    end_date: '1973-12-01',
    remark: { start: 'S46/11/01市制', end: '01202函館市', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '233',
    name: '伊達市',
    kana_name: { half_upper: 'ﾀﾞﾃｼ', full_lower: 'ダテシ' },
    en: null,
    start_date: '1972-04-01',
    end_date: null,
    remark: { start: 'S47/04/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '234',
    name: '北広島市',
    kana_name: { half_upper: 'ｷﾀﾋﾛｼﾏｼ', full_lower: 'キタヒロシマシ' },
    en: null,
    start_date: '1996-09-01',
    end_date: null,
    remark: { start: 'H08/09/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '235',
    name: '石狩市',
    kana_name: { half_upper: 'ｲｼｶﾘｼ', full_lower: 'イシカリシ' },
    en: null,
    start_date: '1996-09-01',
    end_date: null,
    remark: { start: 'H08/09/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '236',
    name: '北斗市',
    kana_name: { half_upper: 'ﾎｸﾄｼ', full_lower: 'ホクトシ' },
    en: null,
    start_date: '2006-02-01',
    end_date: null,
    remark: { start: 'H18/02/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '300',
    name: '石狩支庁',
    kana_name: { half_upper: 'ｲｼｶﾘｼﾁﾖｳ', full_lower: 'イシカリシチョウ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-04-01',
    remark: { start: null, end: '01300石狩振興局', other: null },
    note: 'キー重複',
  },
  {
    pref_code: '01',
    city_code: '300',
    name: '石狩振興局',
    kana_name: {
      half_upper: 'ｲｼｶﾘｼﾝｺｳｷﾖｸ',
      full_lower: 'イシカリシンコウキョク',
    },
    en: null,
    start_date: '2010-04-01',
    end_date: null,
    remark: {
      start: 'H22/04/01支庁名変更：旧「石狩支庁」',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '301',
    name: '札幌郡　広島町',
    kana_name: {
      half_upper: 'ｻﾂﾎﾟﾛｸﾞﾝ ﾋﾛｼﾏﾁﾖｳ',
      full_lower: 'サッポログン　ヒロシマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1996-09-01',
    remark: { start: null, end: '01234北広島市', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '302',
    name: '石狩郡　石狩町',
    kana_name: {
      half_upper: 'ｲｼｶﾘｸﾞﾝ ｲｼｶﾘﾁﾖｳ',
      full_lower: 'イシカリグン　イシカリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1996-09-01',
    remark: { start: null, end: '01235石狩市', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '303',
    name: '石狩郡　当別町',
    kana_name: {
      half_upper: 'ｲｼｶﾘｸﾞﾝ ﾄｳﾍﾞﾂﾁﾖｳ',
      full_lower: 'イシカリグン　トウベツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '304',
    name: '石狩郡　新篠津村',
    kana_name: {
      half_upper: 'ｲｼｶﾘｸﾞﾝ ｼﾝｼﾉﾂﾑﾗ',
      full_lower: 'イシカリグン　シンシノツムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '305',
    name: '厚田郡　厚田村',
    kana_name: {
      half_upper: 'ｱﾂﾀｸﾞﾝ ｱﾂﾀﾑﾗ',
      full_lower: 'アツタグン　アツタムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '01235石狩市', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '306',
    name: '浜益郡　浜益村',
    kana_name: {
      half_upper: 'ﾊﾏﾏｽｸﾞﾝ ﾊﾏﾏｽﾑﾗ',
      full_lower: 'ハママスグン　ハママスムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '01235石狩市', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '307',
    name: '千歳郡　恵庭町',
    kana_name: {
      half_upper: 'ﾁﾄｾｸﾞﾝ ｴﾆﾜﾁﾖｳ',
      full_lower: 'チトセグン　エニワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-11-01',
    remark: { start: null, end: '01231恵庭市', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '330',
    name: '渡島支庁',
    kana_name: { half_upper: 'ｵｼﾏｼﾁﾖｳ', full_lower: 'オシマシチョウ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-04-01',
    remark: { start: null, end: '01330渡島総合振興局', other: null },
    note: 'キー重複',
  },
  {
    pref_code: '01',
    city_code: '330',
    name: '渡島総合振興局',
    kana_name: {
      half_upper: 'ｵｼﾏｿｳｺﾞｳｼﾝｺｳｷﾖｸ',
      full_lower: 'オシマソウゴウシンコウキョク',
    },
    en: null,
    start_date: '2010-04-01',
    end_date: null,
    remark: {
      start: 'H22/04/01支庁名変更：旧「渡島支庁」',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '331',
    name: '松前郡　松前町',
    kana_name: {
      half_upper: 'ﾏﾂﾏｴｸﾞﾝ ﾏﾂﾏｴﾁﾖｳ',
      full_lower: 'マツマエグン　マツマエチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '332',
    name: '松前郡　福島町',
    kana_name: {
      half_upper: 'ﾏﾂﾏｴｸﾞﾝ ﾌｸｼﾏﾁﾖｳ',
      full_lower: 'マツマエグン　フクシマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '333',
    name: '上磯郡　知内町',
    kana_name: {
      half_upper: 'ｶﾐｲｿｸﾞﾝ ｼﾘｳﾁﾁﾖｳ',
      full_lower: 'カミイソグン　シリウチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '334',
    name: '上磯郡　木古内町',
    kana_name: {
      half_upper: 'ｶﾐｲｿｸﾞﾝ ｷｺﾅｲﾁﾖｳ',
      full_lower: 'カミイソグン　キコナイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '335',
    name: '上磯郡　上磯町',
    kana_name: {
      half_upper: 'ｶﾐｲｿｸﾞﾝ ｶﾐｲｿﾁﾖｳ',
      full_lower: 'カミイソグン　カミイソチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-01',
    remark: { start: null, end: '01236北斗市', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '336',
    name: '亀田郡　大野町',
    kana_name: {
      half_upper: 'ｶﾒﾀﾞｸﾞﾝ ｵｵﾉﾁﾖｳ',
      full_lower: 'カメダグン　オオノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-01',
    remark: { start: null, end: '01236北斗市', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '337',
    name: '亀田郡　七飯町',
    kana_name: {
      half_upper: 'ｶﾒﾀﾞｸﾞﾝ ﾅﾅｴﾁﾖｳ',
      full_lower: 'カメダグン　ナナエチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '338',
    name: '亀田郡　亀田町',
    kana_name: {
      half_upper: 'ｶﾒﾀﾞｸﾞﾝ ｶﾒﾀﾞﾁﾖｳ',
      full_lower: 'カメダグン　カメダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-11-01',
    remark: { start: null, end: '01232亀田市', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '339',
    name: '亀田郡　戸井町',
    kana_name: {
      half_upper: 'ｶﾒﾀﾞｸﾞﾝ ﾄｲﾁﾖｳ',
      full_lower: 'カメダグン　トイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-12-01',
    remark: { start: null, end: '01202函館市', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '340',
    name: '亀田郡　恵山町',
    kana_name: {
      half_upper: 'ｶﾒﾀﾞｸﾞﾝ ｴｻﾝﾁﾖｳ',
      full_lower: 'カメダグン　エサンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-12-01',
    remark: { start: null, end: '01202函館市', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '341',
    name: '亀田郡　椴法華村',
    kana_name: {
      half_upper: 'ｶﾒﾀﾞｸﾞﾝ ﾄﾄﾞﾎﾂｹﾑﾗ',
      full_lower: 'カメダグン　トドホッケムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-12-01',
    remark: { start: null, end: '01202函館市', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '342',
    name: '茅部郡　南茅部町',
    kana_name: {
      half_upper: 'ｶﾔﾍﾞｸﾞﾝ ﾐﾅﾐｶﾔﾍﾞﾁﾖｳ',
      full_lower: 'カヤベグン　ミナミカヤベチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-12-01',
    remark: { start: null, end: '01202函館市', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '343',
    name: '茅部郡　鹿部町',
    kana_name: {
      half_upper: 'ｶﾔﾍﾞｸﾞﾝ ｼｶﾍﾞﾁﾖｳ',
      full_lower: 'カヤベグン　シカベチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '344',
    name: '茅部郡　砂原町',
    kana_name: {
      half_upper: 'ｶﾔﾍﾞｸﾞﾝ ｻﾜﾗﾁﾖｳ',
      full_lower: 'カヤベグン　サワラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '01345茅部郡　森町', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '345',
    name: '茅部郡　森町',
    kana_name: {
      half_upper: 'ｶﾔﾍﾞｸﾞﾝ ﾓﾘﾏﾁ',
      full_lower: 'カヤベグン　モリマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '346',
    name: '二海郡　八雲町',
    kana_name: {
      half_upper: 'ﾌﾀﾐｸﾞﾝ ﾔｸﾓﾁﾖｳ',
      full_lower: 'フタミグン　ヤクモチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '347',
    name: '山越郡　長万部町',
    kana_name: {
      half_upper: 'ﾔﾏｺｼｸﾞﾝ ｵｼﾔﾏﾝﾍﾞﾁﾖｳ',
      full_lower: 'ヤマコシグン　オシャマンベチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '360',
    name: '檜山支庁',
    kana_name: { half_upper: 'ﾋﾔﾏｼﾁﾖｳ', full_lower: 'ヒヤマシチョウ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-04-01',
    remark: { start: null, end: '01360檜山振興局', other: null },
    note: 'キー重複',
  },
  {
    pref_code: '01',
    city_code: '360',
    name: '檜山振興局',
    kana_name: {
      half_upper: 'ﾋﾔﾏｼﾝｺｳｷﾖｸ',
      full_lower: 'ヒヤマシンコウキョク',
    },
    en: null,
    start_date: '2010-04-01',
    end_date: null,
    remark: {
      start: 'H22/04/01支庁名変更：旧「檜山支庁」',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '361',
    name: '檜山郡　江差町',
    kana_name: {
      half_upper: 'ﾋﾔﾏｸﾞﾝ ｴｻｼﾁﾖｳ',
      full_lower: 'ヒヤマグン　エサシチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '362',
    name: '檜山郡　上ノ国町',
    kana_name: {
      half_upper: 'ﾋﾔﾏｸﾞﾝ ｶﾐﾉｸﾆﾁﾖｳ',
      full_lower: 'ヒヤマグン　カミノクニチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '363',
    name: '檜山郡　厚沢部町',
    kana_name: {
      half_upper: 'ﾋﾔﾏｸﾞﾝ ｱﾂｻﾌﾞﾁﾖｳ',
      full_lower: 'ヒヤマグン　アッサブチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '364',
    name: '爾志郡　乙部町',
    kana_name: {
      half_upper: 'ﾆｼｸﾞﾝ ｵﾄﾍﾞﾁﾖｳ',
      full_lower: 'ニシグン　オトベチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '365',
    name: '爾志郡　熊石町',
    kana_name: {
      half_upper: 'ﾆｼｸﾞﾝ ｸﾏｲｼﾁﾖｳ',
      full_lower: 'ニシグン　クマイシチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '01346二海郡　八雲町', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '366',
    name: '久遠郡　大成町',
    kana_name: {
      half_upper: 'ｸﾄﾞｳｸﾞﾝ ﾀｲｾｲﾁﾖｳ',
      full_lower: 'クドウグン　タイセイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-01',
    remark: { start: null, end: '01371久遠郡　せたな町', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '367',
    name: '奥尻郡　奥尻町',
    kana_name: {
      half_upper: 'ｵｸｼﾘｸﾞﾝ ｵｸｼﾘﾁﾖｳ',
      full_lower: 'オクシリグン　オクシリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '368',
    name: '瀬棚郡　瀬棚町',
    kana_name: {
      half_upper: 'ｾﾀﾅｸﾞﾝ ｾﾀﾅﾁﾖｳ',
      full_lower: 'セタナグン　セタナチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-01',
    remark: { start: null, end: '01371久遠郡　せたな町', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '369',
    name: '瀬棚郡　北檜山町',
    kana_name: {
      half_upper: 'ｾﾀﾅｸﾞﾝ ｷﾀﾋﾔﾏﾁﾖｳ',
      full_lower: 'セタナグン　キタヒヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-01',
    remark: { start: null, end: '01371久遠郡　せたな町', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '370',
    name: '瀬棚郡　今金町',
    kana_name: {
      half_upper: 'ｾﾀﾅｸﾞﾝ ｲﾏｶﾈﾁﾖｳ',
      full_lower: 'セタナグン　イマカネチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '371',
    name: '久遠郡　せたな町',
    kana_name: {
      half_upper: 'ｸﾄﾞｳｸﾞﾝ ｾﾀﾅﾁﾖｳ',
      full_lower: 'クドウグン　セタナチョウ',
    },
    en: null,
    start_date: '2005-09-01',
    end_date: null,
    remark: { start: 'H17/09/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '390',
    name: '後志支庁',
    kana_name: { half_upper: 'ｼﾘﾍﾞｼｼﾁﾖｳ', full_lower: 'シリベシシチョウ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-04-01',
    remark: { start: null, end: '01390後志総合振興局', other: null },
    note: 'キー重複',
  },
  {
    pref_code: '01',
    city_code: '390',
    name: '後志総合振興局',
    kana_name: {
      half_upper: 'ｼﾘﾍﾞｼｿｳｺﾞｳｼﾝｺｳｷﾖｸ',
      full_lower: 'シリベシソウゴウシンコウキョク',
    },
    en: null,
    start_date: '2010-04-01',
    end_date: null,
    remark: {
      start: 'H22/04/01支庁名変更：旧「後志支庁」',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '391',
    name: '島牧郡　島牧村',
    kana_name: {
      half_upper: 'ｼﾏﾏｷｸﾞﾝ ｼﾏﾏｷﾑﾗ',
      full_lower: 'シママキグン　シママキムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '392',
    name: '寿都郡　寿都町',
    kana_name: {
      half_upper: 'ｽﾂﾂｸﾞﾝ ｽﾂﾂﾁﾖｳ',
      full_lower: 'スッツグン　スッツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '393',
    name: '寿都郡　黒松内町',
    kana_name: {
      half_upper: 'ｽﾂﾂｸﾞﾝ ｸﾛﾏﾂﾅｲﾁﾖｳ',
      full_lower: 'スッツグン　クロマツナイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '394',
    name: '磯谷郡　蘭越町',
    kana_name: {
      half_upper: 'ｲｿﾔｸﾞﾝ ﾗﾝｺｼﾁﾖｳ',
      full_lower: 'イソヤグン　ランコシチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '395',
    name: '虻田郡　ニセコ町',
    kana_name: {
      half_upper: 'ｱﾌﾞﾀｸﾞﾝ ﾆｾｺﾁﾖｳ',
      full_lower: 'アブタグン　ニセコチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '396',
    name: '虻田郡　真狩村',
    kana_name: {
      half_upper: 'ｱﾌﾞﾀｸﾞﾝ ﾏﾂｶﾘﾑﾗ',
      full_lower: 'アブタグン　マッカリムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '397',
    name: '虻田郡　留寿都村',
    kana_name: {
      half_upper: 'ｱﾌﾞﾀｸﾞﾝ ﾙｽﾂﾑﾗ',
      full_lower: 'アブタグン　ルスツムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '398',
    name: '虻田郡　喜茂別町',
    kana_name: {
      half_upper: 'ｱﾌﾞﾀｸﾞﾝ ｷﾓﾍﾞﾂﾁﾖｳ',
      full_lower: 'アブタグン　キモベツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '399',
    name: '虻田郡　京極町',
    kana_name: {
      half_upper: 'ｱﾌﾞﾀｸﾞﾝ ｷﾖｳｺﾞｸﾁﾖｳ',
      full_lower: 'アブタグン　キョウゴクチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '400',
    name: '虻田郡　倶知安町',
    kana_name: {
      half_upper: 'ｱﾌﾞﾀｸﾞﾝ ｸﾂﾁﾔﾝﾁﾖｳ',
      full_lower: 'アブタグン　クッチャンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '401',
    name: '岩内郡　共和町',
    kana_name: {
      half_upper: 'ｲﾜﾅｲｸﾞﾝ ｷﾖｳﾜﾁﾖｳ',
      full_lower: 'イワナイグン　キョウワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '402',
    name: '岩内郡　岩内町',
    kana_name: {
      half_upper: 'ｲﾜﾅｲｸﾞﾝ ｲﾜﾅｲﾁﾖｳ',
      full_lower: 'イワナイグン　イワナイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '403',
    name: '古宇郡　泊村',
    kana_name: {
      half_upper: 'ﾌﾙｳｸﾞﾝ ﾄﾏﾘﾑﾗ',
      full_lower: 'フルウグン　トマリムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '404',
    name: '古宇郡　神恵内村',
    kana_name: {
      half_upper: 'ﾌﾙｳｸﾞﾝ ｶﾓｴﾅｲﾑﾗ',
      full_lower: 'フルウグン　カモエナイムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '405',
    name: '積丹郡　積丹町',
    kana_name: {
      half_upper: 'ｼﾔｺﾀﾝｸﾞﾝ ｼﾔｺﾀﾝﾁﾖｳ',
      full_lower: 'シャコタングン　シャコタンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '406',
    name: '古平郡　古平町',
    kana_name: {
      half_upper: 'ﾌﾙﾋﾞﾗｸﾞﾝ ﾌﾙﾋﾞﾗﾁﾖｳ',
      full_lower: 'フルビラグン　フルビラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '407',
    name: '余市郡　仁木町',
    kana_name: {
      half_upper: 'ﾖｲﾁｸﾞﾝ ﾆｷﾁﾖｳ',
      full_lower: 'ヨイチグン　ニキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '408',
    name: '余市郡　余市町',
    kana_name: {
      half_upper: 'ﾖｲﾁｸﾞﾝ ﾖｲﾁﾁﾖｳ',
      full_lower: 'ヨイチグン　ヨイチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '409',
    name: '余市郡　赤井川村',
    kana_name: {
      half_upper: 'ﾖｲﾁｸﾞﾝ ｱｶｲｶﾞﾜﾑﾗ',
      full_lower: 'ヨイチグン　アカイガワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '420',
    name: '空知支庁',
    kana_name: { half_upper: 'ｿﾗﾁｼﾁﾖｳ', full_lower: 'ソラチシチョウ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-04-01',
    remark: { start: null, end: '01420空知総合振興局', other: null },
    note: 'キー重複',
  },
  {
    pref_code: '01',
    city_code: '420',
    name: '空知総合振興局',
    kana_name: {
      half_upper: 'ｿﾗﾁｿｳｺﾞｳｼﾝｺｳｷﾖｸ',
      full_lower: 'ソラチソウゴウシンコウキョク',
    },
    en: null,
    start_date: '2010-04-01',
    end_date: null,
    remark: {
      start: 'H22/04/01支庁名変更：旧「空知支庁」',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '421',
    name: '空知郡　北村',
    kana_name: {
      half_upper: 'ｿﾗﾁｸﾞﾝ ｷﾀﾑﾗ',
      full_lower: 'ソラチグン　キタムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '01210岩見沢市', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '422',
    name: '空知郡　栗沢町',
    kana_name: {
      half_upper: 'ｿﾗﾁｸﾞﾝ ｸﾘｻﾜﾁﾖｳ',
      full_lower: 'ソラチグン　クリサワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '01210岩見沢市', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '423',
    name: '空知郡　南幌町',
    kana_name: {
      half_upper: 'ｿﾗﾁｸﾞﾝ ﾅﾝﾎﾟﾛﾁﾖｳ',
      full_lower: 'ソラチグン　ナンポロチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '424',
    name: '空知郡　奈井江町',
    kana_name: {
      half_upper: 'ｿﾗﾁｸﾞﾝ ﾅｲｴﾁﾖｳ',
      full_lower: 'ソラチグン　ナイエチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '425',
    name: '空知郡　上砂川町',
    kana_name: {
      half_upper: 'ｿﾗﾁｸﾞﾝ ｶﾐｽﾅｶﾞﾜﾁﾖｳ',
      full_lower: 'ソラチグン　カミスナガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '426',
    name: '空知郡　江部乙町',
    kana_name: {
      half_upper: 'ｿﾗﾁｸﾞﾝ ｴﾍﾞｵﾂﾁﾖｳ',
      full_lower: 'ソラチグン　エベオツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-04-01',
    remark: { start: null, end: '01225滝川市', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '427',
    name: '夕張郡　由仁町',
    kana_name: {
      half_upper: 'ﾕｳﾊﾞﾘｸﾞﾝ ﾕﾆﾁﾖｳ',
      full_lower: 'ユウバリグン　ユニチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '428',
    name: '夕張郡　長沼町',
    kana_name: {
      half_upper: 'ﾕｳﾊﾞﾘｸﾞﾝ ﾅｶﾞﾇﾏﾁﾖｳ',
      full_lower: 'ユウバリグン　ナガヌマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '429',
    name: '夕張郡　栗山町',
    kana_name: {
      half_upper: 'ﾕｳﾊﾞﾘｸﾞﾝ ｸﾘﾔﾏﾁﾖｳ',
      full_lower: 'ユウバリグン　クリヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '430',
    name: '樺戸郡　月形町',
    kana_name: {
      half_upper: 'ｶﾊﾞﾄｸﾞﾝ ﾂｷｶﾞﾀﾁﾖｳ',
      full_lower: 'カバトグン　ツキガタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '431',
    name: '樺戸郡　浦臼町',
    kana_name: {
      half_upper: 'ｶﾊﾞﾄｸﾞﾝ ｳﾗｳｽﾁﾖｳ',
      full_lower: 'カバトグン　ウラウスチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '432',
    name: '樺戸郡　新十津川町',
    kana_name: {
      half_upper: 'ｶﾊﾞﾄｸﾞﾝ ｼﾝﾄﾂｶﾜﾁﾖｳ',
      full_lower: 'カバトグン　シントツカワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '433',
    name: '雨竜郡　妹背牛町',
    kana_name: {
      half_upper: 'ｳﾘﾕｳｸﾞﾝ ﾓｾｳｼﾁﾖｳ',
      full_lower: 'ウリュウグン　モセウシチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '434',
    name: '雨竜郡　秩父別町',
    kana_name: {
      half_upper: 'ｳﾘﾕｳｸﾞﾝ ﾁﾂﾌﾟﾍﾞﾂﾁﾖｳ',
      full_lower: 'ウリュウグン　チップベツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '435',
    name: '雨竜郡　多度志町',
    kana_name: {
      half_upper: 'ｳﾘﾕｳｸﾞﾝ ﾀﾄﾞｼﾁﾖｳ',
      full_lower: 'ウリュウグン　タドシチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-04-01',
    remark: { start: null, end: '01228深川市', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '436',
    name: '雨竜郡　雨竜町',
    kana_name: {
      half_upper: 'ｳﾘﾕｳｸﾞﾝ ｳﾘﾕｳﾁﾖｳ',
      full_lower: 'ウリュウグン　ウリュウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '437',
    name: '雨竜郡　北竜町',
    kana_name: {
      half_upper: 'ｳﾘﾕｳｸﾞﾝ ﾎｸﾘﾕｳﾁﾖｳ',
      full_lower: 'ウリュウグン　ホクリュウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '438',
    name: '雨竜郡　沼田町',
    kana_name: {
      half_upper: 'ｳﾘﾕｳｸﾞﾝ ﾇﾏﾀﾁﾖｳ',
      full_lower: 'ウリュウグン　ヌマタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '439',
    name: '雨竜郡　幌加内町',
    kana_name: {
      half_upper: 'ｳﾘﾕｳｸﾞﾝ ﾎﾛｶﾅｲﾁﾖｳ',
      full_lower: 'ウリュウグン　ホロカナイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-04-01',
    remark: { start: null, end: '01472雨竜郡　幌加内町', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '450',
    name: '上川支庁',
    kana_name: { half_upper: 'ｶﾐｶﾜｼﾁﾖｳ', full_lower: 'カミカワシチョウ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-04-01',
    remark: { start: null, end: '01450上川総合振興局', other: null },
    note: 'キー重複',
  },
  {
    pref_code: '01',
    city_code: '450',
    name: '上川総合振興局',
    kana_name: {
      half_upper: 'ｶﾐｶﾜｿｳｺﾞｳｼﾝｺｳｷﾖｸ',
      full_lower: 'カミカワソウゴウシンコウキョク',
    },
    en: null,
    start_date: '2010-04-01',
    end_date: null,
    remark: {
      start: 'H22/04/01支庁名変更：旧「上川支庁」',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '451',
    name: '上川郡　東鷹栖町',
    kana_name: {
      half_upper: 'ｶﾐｶﾜｸﾞﾝ ﾋｶﾞｼﾀｶｽﾁﾖｳ',
      full_lower: 'カミカワグン　ヒガシタカスチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-03-02',
    remark: { start: null, end: '01204旭川市', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '452',
    name: '上川郡　鷹栖町',
    kana_name: {
      half_upper: 'ｶﾐｶﾜｸﾞﾝ ﾀｶｽﾁﾖｳ',
      full_lower: 'カミカワグン　タカスチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '453',
    name: '上川郡　東神楽町',
    kana_name: {
      half_upper: 'ｶﾐｶﾜｸﾞﾝ ﾋｶﾞｼｶｸﾞﾗﾁﾖｳ',
      full_lower: 'カミカワグン　ヒガシカグラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '454',
    name: '上川郡　当麻町',
    kana_name: {
      half_upper: 'ｶﾐｶﾜｸﾞﾝ ﾄｳﾏﾁﾖｳ',
      full_lower: 'カミカワグン　トウマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '455',
    name: '上川郡　比布町',
    kana_name: {
      half_upper: 'ｶﾐｶﾜｸﾞﾝ ﾋﾟﾂﾌﾟﾁﾖｳ',
      full_lower: 'カミカワグン　ピップチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '456',
    name: '上川郡　愛別町',
    kana_name: {
      half_upper: 'ｶﾐｶﾜｸﾞﾝ ｱｲﾍﾞﾂﾁﾖｳ',
      full_lower: 'カミカワグン　アイベツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '457',
    name: '上川郡　上川町',
    kana_name: {
      half_upper: 'ｶﾐｶﾜｸﾞﾝ ｶﾐｶﾜﾁﾖｳ',
      full_lower: 'カミカワグン　カミカワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '458',
    name: '上川郡　東川町',
    kana_name: {
      half_upper: 'ｶﾐｶﾜｸﾞﾝ ﾋｶﾞｼｶﾜﾁﾖｳ',
      full_lower: 'カミカワグン　ヒガシカワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '459',
    name: '上川郡　美瑛町',
    kana_name: {
      half_upper: 'ｶﾐｶﾜｸﾞﾝ ﾋﾞｴｲﾁﾖｳ',
      full_lower: 'カミカワグン　ビエイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '460',
    name: '空知郡　上富良野町',
    kana_name: {
      half_upper: 'ｿﾗﾁｸﾞﾝ ｶﾐﾌﾗﾉﾁﾖｳ',
      full_lower: 'ソラチグン　カミフラノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '461',
    name: '空知郡　中富良野町',
    kana_name: {
      half_upper: 'ｿﾗﾁｸﾞﾝ ﾅｶﾌﾗﾉﾁﾖｳ',
      full_lower: 'ソラチグン　ナカフラノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '462',
    name: '空知郡　南富良野町',
    kana_name: {
      half_upper: 'ｿﾗﾁｸﾞﾝ ﾐﾅﾐﾌﾗﾉﾁﾖｳ',
      full_lower: 'ソラチグン　ミナミフラノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '463',
    name: '勇払郡　占冠村',
    kana_name: {
      half_upper: 'ﾕｳﾌﾂｸﾞﾝ ｼﾑｶﾂﾌﾟﾑﾗ',
      full_lower: 'ユウフツグン　シムカップムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '464',
    name: '上川郡　和寒町',
    kana_name: {
      half_upper: 'ｶﾐｶﾜｸﾞﾝ ﾜﾂｻﾑﾁﾖｳ',
      full_lower: 'カミカワグン　ワッサムチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '465',
    name: '上川郡　剣淵町',
    kana_name: {
      half_upper: 'ｶﾐｶﾜｸﾞﾝ ｹﾝﾌﾞﾁﾁﾖｳ',
      full_lower: 'カミカワグン　ケンブチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '466',
    name: '上川郡　朝日町',
    kana_name: {
      half_upper: 'ｶﾐｶﾜｸﾞﾝ ｱｻﾋﾁﾖｳ',
      full_lower: 'カミカワグン　アサヒチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-01',
    remark: { start: null, end: '01220士別市', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '467',
    name: '上川郡　風連町',
    kana_name: {
      half_upper: 'ｶﾐｶﾜｸﾞﾝ ﾌｳﾚﾝﾁﾖｳ',
      full_lower: 'カミカワグン　フウレンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '01221名寄市', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '468',
    name: '上川郡　下川町',
    kana_name: {
      half_upper: 'ｶﾐｶﾜｸﾞﾝ ｼﾓｶﾜﾁﾖｳ',
      full_lower: 'カミカワグン　シモカワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '469',
    name: '中川郡　美深町',
    kana_name: {
      half_upper: 'ﾅｶｶﾞﾜｸﾞﾝ ﾋﾞﾌｶﾁﾖｳ',
      full_lower: 'ナカガワグン　ビフカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '470',
    name: '中川郡　音威子府村',
    kana_name: {
      half_upper: 'ﾅｶｶﾞﾜｸﾞﾝ ｵﾄｲﾈﾂﾌﾟﾑﾗ',
      full_lower: 'ナカガワグン　オトイネップムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '471',
    name: '中川郡　中川町',
    kana_name: {
      half_upper: 'ﾅｶｶﾞﾜｸﾞﾝ ﾅｶｶﾞﾜﾁﾖｳ',
      full_lower: 'ナカガワグン　ナカガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '472',
    name: '雨竜郡　幌加内町',
    kana_name: {
      half_upper: 'ｳﾘﾕｳｸﾞﾝ ﾎﾛｶﾅｲﾁﾖｳ',
      full_lower: 'ウリュウグン　ホロカナイチョウ',
    },
    en: null,
    start_date: '2010-04-01',
    end_date: null,
    remark: { start: 'H22/04/01支庁変更(空知→上川)', end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '480',
    name: '留萌支庁',
    kana_name: { half_upper: 'ﾙﾓｲｼﾁﾖｳ', full_lower: 'ルモイシチョウ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-04-01',
    remark: { start: null, end: '01480留萌振興局', other: null },
    note: 'キー重複',
  },
  {
    pref_code: '01',
    city_code: '480',
    name: '留萌振興局',
    kana_name: {
      half_upper: 'ﾙﾓｲｼﾝｺｳｷﾖｸ',
      full_lower: 'ルモイシンコウキョク',
    },
    en: null,
    start_date: '2010-04-01',
    end_date: null,
    remark: {
      start: 'H22/04/01支庁名変更：旧「留萌支庁」',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '481',
    name: '増毛郡　増毛町',
    kana_name: {
      half_upper: 'ﾏｼｹｸﾞﾝ ﾏｼｹﾁﾖｳ',
      full_lower: 'マシケグン　マシケチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '482',
    name: '留萌郡　小平町',
    kana_name: {
      half_upper: 'ﾙﾓｲｸﾞﾝ ｵﾋﾞﾗﾁﾖｳ',
      full_lower: 'ルモイグン　オビラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '483',
    name: '苫前郡　苫前町',
    kana_name: {
      half_upper: 'ﾄﾏﾏｴｸﾞﾝ ﾄﾏﾏｴﾁﾖｳ',
      full_lower: 'トママエグン　トママエチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '484',
    name: '苫前郡　羽幌町',
    kana_name: {
      half_upper: 'ﾄﾏﾏｴｸﾞﾝ ﾊﾎﾞﾛﾁﾖｳ',
      full_lower: 'トママエグン　ハボロチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '485',
    name: '苫前郡　初山別村',
    kana_name: {
      half_upper: 'ﾄﾏﾏｴｸﾞﾝ ｼﾖｻﾝﾍﾞﾂﾑﾗ',
      full_lower: 'トママエグン　ショサンベツムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '486',
    name: '天塩郡　遠別町',
    kana_name: {
      half_upper: 'ﾃｼｵｸﾞﾝ ｴﾝﾍﾞﾂﾁﾖｳ',
      full_lower: 'テシオグン　エンベツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '487',
    name: '天塩郡　天塩町',
    kana_name: {
      half_upper: 'ﾃｼｵｸﾞﾝ ﾃｼｵﾁﾖｳ',
      full_lower: 'テシオグン　テシオチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '488',
    name: '天塩郡　幌延町',
    kana_name: {
      half_upper: 'ﾃｼｵｸﾞﾝ ﾎﾛﾉﾍﾞﾁﾖｳ',
      full_lower: 'テシオグン　ホロノベチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-04-01',
    remark: { start: null, end: '01520天塩郡　幌延町', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '510',
    name: '宗谷支庁',
    kana_name: { half_upper: 'ｿｳﾔｼﾁﾖｳ', full_lower: 'ソウヤシチョウ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-04-01',
    remark: { start: null, end: '01510宗谷総合振興局', other: null },
    note: 'キー重複',
  },
  {
    pref_code: '01',
    city_code: '510',
    name: '宗谷総合振興局',
    kana_name: {
      half_upper: 'ｿｳﾔｿｳｺﾞｳｼﾝｺｳｷﾖｸ',
      full_lower: 'ソウヤソウゴウシンコウキョク',
    },
    en: null,
    start_date: '2010-04-01',
    end_date: null,
    remark: {
      start: 'H22/04/01支庁名変更：旧「宗谷支庁」',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '511',
    name: '宗谷郡　猿払村',
    kana_name: {
      half_upper: 'ｿｳﾔｸﾞﾝ ｻﾙﾌﾂﾑﾗ',
      full_lower: 'ソウヤグン　サルフツムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '512',
    name: '枝幸郡　浜頓別町',
    kana_name: {
      half_upper: 'ｴｻｼｸﾞﾝ ﾊﾏﾄﾝﾍﾞﾂﾁﾖｳ',
      full_lower: 'エサシグン　ハマトンベツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '513',
    name: '枝幸郡　中頓別町',
    kana_name: {
      half_upper: 'ｴｻｼｸﾞﾝ ﾅｶﾄﾝﾍﾞﾂﾁﾖｳ',
      full_lower: 'エサシグン　ナカトンベツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '514',
    name: '枝幸郡　枝幸町',
    kana_name: {
      half_upper: 'ｴｻｼｸﾞﾝ ｴｻｼﾁﾖｳ',
      full_lower: 'エサシグン　エサシチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '515',
    name: '枝幸郡　歌登町',
    kana_name: {
      half_upper: 'ｴｻｼｸﾞﾝ ｳﾀﾉﾎﾞﾘﾁﾖｳ',
      full_lower: 'エサシグン　ウタノボリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '01514枝幸郡　枝幸町', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '516',
    name: '天塩郡　豊富町',
    kana_name: {
      half_upper: 'ﾃｼｵｸﾞﾝ ﾄﾖﾄﾐﾁﾖｳ',
      full_lower: 'テシオグン　トヨトミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '517',
    name: '礼文郡　礼文町',
    kana_name: {
      half_upper: 'ﾚﾌﾞﾝｸﾞﾝ ﾚﾌﾞﾝﾁﾖｳ',
      full_lower: 'レブングン　レブンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '518',
    name: '利尻郡　利尻町',
    kana_name: {
      half_upper: 'ﾘｼﾘｸﾞﾝ ﾘｼﾘﾁﾖｳ',
      full_lower: 'リシリグン　リシリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '519',
    name: '利尻郡　利尻富士町',
    kana_name: {
      half_upper: 'ﾘｼﾘｸﾞﾝ ﾘｼﾘﾌｼﾞﾁﾖｳ',
      full_lower: 'リシリグン　リシリフジチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '520',
    name: '天塩郡　幌延町',
    kana_name: {
      half_upper: 'ﾃｼｵｸﾞﾝ ﾎﾛﾉﾍﾞﾁﾖｳ',
      full_lower: 'テシオグン　ホロノベチョウ',
    },
    en: null,
    start_date: '2010-04-01',
    end_date: null,
    remark: { start: 'H22/04/01支庁変更(留萌→宗谷)', end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '540',
    name: '網走支庁',
    kana_name: { half_upper: 'ｱﾊﾞｼﾘｼﾁﾖｳ', full_lower: 'アバシリシチョウ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-04-01',
    remark: { start: null, end: '01540オホーツク総合振興局', other: null },
    note: 'キー重複',
  },
  {
    pref_code: '01',
    city_code: '540',
    name: 'オホーツク総合振興局',
    kana_name: {
      half_upper: 'ｵﾎｰﾂｸｿｳｺﾞｳｼﾝｺｳｷﾖｸ',
      full_lower: 'オホーツクソウゴウシンコウキョク',
    },
    en: null,
    start_date: '2010-04-01',
    end_date: null,
    remark: {
      start: 'H22/04/01支庁名変更：旧「網走支庁」',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '541',
    name: '網走郡　東藻琴村',
    kana_name: {
      half_upper: 'ｱﾊﾞｼﾘｸﾞﾝ ﾋｶﾞｼﾓｺﾄﾑﾗ',
      full_lower: 'アバシリグン　ヒガシモコトムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '01564網走郡　大空町', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '542',
    name: '網走郡　女満別町',
    kana_name: {
      half_upper: 'ｱﾊﾞｼﾘｸﾞﾝ ﾒﾏﾝﾍﾞﾂﾁﾖｳ',
      full_lower: 'アバシリグン　メマンベツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '01564網走郡　大空町', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '543',
    name: '網走郡　美幌町',
    kana_name: {
      half_upper: 'ｱﾊﾞｼﾘｸﾞﾝ ﾋﾞﾎﾛﾁﾖｳ',
      full_lower: 'アバシリグン　ビホロチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '544',
    name: '網走郡　津別町',
    kana_name: {
      half_upper: 'ｱﾊﾞｼﾘｸﾞﾝ ﾂﾍﾞﾂﾁﾖｳ',
      full_lower: 'アバシリグン　ツベツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '545',
    name: '斜里郡　斜里町',
    kana_name: {
      half_upper: 'ｼﾔﾘｸﾞﾝ ｼﾔﾘﾁﾖｳ',
      full_lower: 'シャリグン　シャリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '546',
    name: '斜里郡　清里町',
    kana_name: {
      half_upper: 'ｼﾔﾘｸﾞﾝ ｷﾖｻﾄﾁﾖｳ',
      full_lower: 'シャリグン　キヨサトチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '547',
    name: '斜里郡　小清水町',
    kana_name: {
      half_upper: 'ｼﾔﾘｸﾞﾝ ｺｼﾐｽﾞﾁﾖｳ',
      full_lower: 'シャリグン　コシミズチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '548',
    name: '常呂郡　端野町',
    kana_name: {
      half_upper: 'ﾄｺﾛｸﾞﾝ ﾀﾝﾉﾁﾖｳ',
      full_lower: 'トコログン　タンノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-05',
    remark: { start: null, end: '01208北見市', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '549',
    name: '常呂郡　訓子府町',
    kana_name: {
      half_upper: 'ﾄｺﾛｸﾞﾝ ｸﾝﾈﾂﾌﾟﾁﾖｳ',
      full_lower: 'トコログン　クンネップチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '550',
    name: '常呂郡　置戸町',
    kana_name: {
      half_upper: 'ﾄｺﾛｸﾞﾝ ｵｹﾄﾁﾖｳ',
      full_lower: 'トコログン　オケトチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '551',
    name: '常呂郡　留辺蘂町',
    kana_name: {
      half_upper: 'ﾄｺﾛｸﾞﾝ ﾙﾍﾞｼﾍﾞﾁﾖｳ',
      full_lower: 'トコログン　ルベシベチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-05',
    remark: { start: null, end: '01208北見市', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '552',
    name: '常呂郡　佐呂間町',
    kana_name: {
      half_upper: 'ﾄｺﾛｸﾞﾝ ｻﾛﾏﾁﾖｳ',
      full_lower: 'トコログン　サロマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '553',
    name: '常呂郡　常呂町',
    kana_name: {
      half_upper: 'ﾄｺﾛｸﾞﾝ ﾄｺﾛﾁﾖｳ',
      full_lower: 'トコログン　トコロチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-05',
    remark: { start: null, end: '01208北見市', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '554',
    name: '紋別郡　生田原町',
    kana_name: {
      half_upper: 'ﾓﾝﾍﾞﾂｸﾞﾝ ｲｸﾀﾊﾗﾁﾖｳ',
      full_lower: 'モンベツグン　イクタハラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '01555紋別郡　遠軽町', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '555',
    name: '紋別郡　遠軽町',
    kana_name: {
      half_upper: 'ﾓﾝﾍﾞﾂｸﾞﾝ ｴﾝｶﾞﾙﾁﾖｳ',
      full_lower: 'モンベツグン　エンガルチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '556',
    name: '紋別郡　丸瀬布町',
    kana_name: {
      half_upper: 'ﾓﾝﾍﾞﾂｸﾞﾝ ﾏﾙｾﾂﾌﾟﾁﾖｳ',
      full_lower: 'モンベツグン　マルセップチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '01555紋別郡　遠軽町', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '557',
    name: '紋別郡　白滝村',
    kana_name: {
      half_upper: 'ﾓﾝﾍﾞﾂｸﾞﾝ ｼﾗﾀｷﾑﾗ',
      full_lower: 'モンベツグン　シラタキムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '01555紋別郡　遠軽町', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '558',
    name: '紋別郡　上湧別町',
    kana_name: {
      half_upper: 'ﾓﾝﾍﾞﾂｸﾞﾝ ｶﾐﾕｳﾍﾞﾂﾁﾖｳ',
      full_lower: 'モンベツグン　カミユウベツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2009-10-05',
    remark: { start: null, end: '01559紋別郡　湧別町', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '559',
    name: '紋別郡　湧別町',
    kana_name: {
      half_upper: 'ﾓﾝﾍﾞﾂｸﾞﾝ ﾕｳﾍﾞﾂﾁﾖｳ',
      full_lower: 'モンベツグン　ユウベツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '560',
    name: '紋別郡　滝上町',
    kana_name: {
      half_upper: 'ﾓﾝﾍﾞﾂｸﾞﾝ ﾀｷﾉｳｴﾁﾖｳ',
      full_lower: 'モンベツグン　タキノウエチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '561',
    name: '紋別郡　興部町',
    kana_name: {
      half_upper: 'ﾓﾝﾍﾞﾂｸﾞﾝ ｵｺﾂﾍﾟﾁﾖｳ',
      full_lower: 'モンベツグン　オコッペチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '562',
    name: '紋別郡　西興部村',
    kana_name: {
      half_upper: 'ﾓﾝﾍﾞﾂｸﾞﾝ ﾆｼｵｺﾂﾍﾟﾑﾗ',
      full_lower: 'モンベツグン　ニシオコッペムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '563',
    name: '紋別郡　雄武町',
    kana_name: {
      half_upper: 'ﾓﾝﾍﾞﾂｸﾞﾝ ｵｳﾑﾁﾖｳ',
      full_lower: 'モンベツグン　オウムチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '564',
    name: '網走郡　大空町',
    kana_name: {
      half_upper: 'ｱﾊﾞｼﾘｸﾞﾝ ｵｵｿﾞﾗﾁﾖｳ',
      full_lower: 'アバシリグン　オオゾラチョウ',
    },
    en: null,
    start_date: '2006-03-31',
    end_date: null,
    remark: { start: 'H18/03/31新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '570',
    name: '胆振支庁',
    kana_name: { half_upper: 'ｲﾌﾞﾘｼﾁﾖｳ', full_lower: 'イブリシチョウ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-04-01',
    remark: { start: null, end: '01570胆振総合振興局', other: null },
    note: 'キー重複',
  },
  {
    pref_code: '01',
    city_code: '570',
    name: '胆振総合振興局',
    kana_name: {
      half_upper: 'ｲﾌﾞﾘｿｳｺﾞｳｼﾝｺｳｷﾖｸ',
      full_lower: 'イブリソウゴウシンコウキョク',
    },
    en: null,
    start_date: '2010-04-01',
    end_date: null,
    remark: {
      start: 'H22/04/01支庁名変更：旧「胆振支庁」',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '571',
    name: '虻田郡　豊浦町',
    kana_name: {
      half_upper: 'ｱﾌﾞﾀｸﾞﾝ ﾄﾖｳﾗﾁﾖｳ',
      full_lower: 'アブタグン　トヨウラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '572',
    name: '虻田郡　虻田町',
    kana_name: {
      half_upper: 'ｱﾌﾞﾀｸﾞﾝ ｱﾌﾞﾀﾁﾖｳ',
      full_lower: 'アブタグン　アブタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '01584虻田郡　洞爺湖町', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '573',
    name: '虻田郡　洞爺村',
    kana_name: {
      half_upper: 'ｱﾌﾞﾀｸﾞﾝ ﾄｳﾔﾑﾗ',
      full_lower: 'アブタグン　トウヤムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '01584虻田郡　洞爺湖町', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '574',
    name: '有珠郡　大滝村',
    kana_name: {
      half_upper: 'ｳｽｸﾞﾝ ｵｵﾀｷﾑﾗ',
      full_lower: 'ウスグン　オオタキムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '01233伊達市', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '575',
    name: '有珠郡　壮瞥町',
    kana_name: {
      half_upper: 'ｳｽｸﾞﾝ ｿｳﾍﾞﾂﾁﾖｳ',
      full_lower: 'ウスグン　ソウベツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '576',
    name: '有珠郡　伊達町',
    kana_name: {
      half_upper: 'ｳｽｸﾞﾝ ﾀﾞﾃﾁﾖｳ',
      full_lower: 'ウスグン　ダテチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-04-01',
    remark: { start: null, end: '01233伊達市', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '577',
    name: '幌別郡　登別町',
    kana_name: {
      half_upper: 'ﾎﾛﾍﾞﾂｸﾞﾝ ﾉﾎﾞﾘﾍﾞﾂﾁﾖｳ',
      full_lower: 'ホロベツグン　ノボリベツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-08-01',
    remark: { start: null, end: '01230登別市', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '578',
    name: '白老郡　白老町',
    kana_name: {
      half_upper: 'ｼﾗｵｲｸﾞﾝ ｼﾗｵｲﾁﾖｳ',
      full_lower: 'シラオイグン　シラオイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '579',
    name: '勇払郡　早来町',
    kana_name: {
      half_upper: 'ﾕｳﾌﾂｸﾞﾝ ﾊﾔｷﾀﾁﾖｳ',
      full_lower: 'ユウフツグン　ハヤキタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '01585勇払郡　安平町', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '580',
    name: '勇払郡　追分町',
    kana_name: {
      half_upper: 'ﾕｳﾌﾂｸﾞﾝ ｵｲﾜｹﾁﾖｳ',
      full_lower: 'ユウフツグン　オイワケチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '01585勇払郡　安平町', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '581',
    name: '勇払郡　厚真町',
    kana_name: {
      half_upper: 'ﾕｳﾌﾂｸﾞﾝ ｱﾂﾏﾁﾖｳ',
      full_lower: 'ユウフツグン　アツマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '582',
    name: '勇払郡　鵡川町',
    kana_name: {
      half_upper: 'ﾕｳﾌﾂｸﾞﾝ ﾑｶﾜﾁﾖｳ',
      full_lower: 'ユウフツグン　ムカワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '01586勇払郡　むかわ町', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '583',
    name: '勇払郡　穂別町',
    kana_name: {
      half_upper: 'ﾕｳﾌﾂｸﾞﾝ ﾎﾍﾞﾂﾁﾖｳ',
      full_lower: 'ユウフツグン　ホベツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '01586勇払郡　むかわ町', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '584',
    name: '虻田郡　洞爺湖町',
    kana_name: {
      half_upper: 'ｱﾌﾞﾀｸﾞﾝ ﾄｳﾔｺﾁﾖｳ',
      full_lower: 'アブタグン　トウヤコチョウ',
    },
    en: null,
    start_date: '2006-03-27',
    end_date: null,
    remark: { start: 'H18/03/27新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '585',
    name: '勇払郡　安平町',
    kana_name: {
      half_upper: 'ﾕｳﾌﾂｸﾞﾝ ｱﾋﾞﾗﾁﾖｳ',
      full_lower: 'ユウフツグン　アビラチョウ',
    },
    en: null,
    start_date: '2006-03-27',
    end_date: null,
    remark: { start: 'H18/03/27新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '586',
    name: '勇払郡　むかわ町',
    kana_name: {
      half_upper: 'ﾕｳﾌﾂｸﾞﾝ ﾑｶﾜﾁﾖｳ',
      full_lower: 'ユウフツグン　ムカワチョウ',
    },
    en: null,
    start_date: '2006-03-27',
    end_date: null,
    remark: { start: 'H18/03/27新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '600',
    name: '日高支庁',
    kana_name: { half_upper: 'ﾋﾀﾞｶｼﾁﾖｳ', full_lower: 'ヒダカシチョウ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-04-01',
    remark: { start: null, end: '01600日高振興局', other: null },
    note: 'キー重複',
  },
  {
    pref_code: '01',
    city_code: '600',
    name: '日高振興局',
    kana_name: {
      half_upper: 'ﾋﾀﾞｶｼﾝｺｳｷﾖｸ',
      full_lower: 'ヒダカシンコウキョク',
    },
    en: null,
    start_date: '2010-04-01',
    end_date: null,
    remark: {
      start: 'H22/04/01支庁名変更：旧「日高支庁」',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '601',
    name: '沙流郡　日高町',
    kana_name: {
      half_upper: 'ｻﾙｸﾞﾝ ﾋﾀﾞｶﾁﾖｳ',
      full_lower: 'サルグン　ヒダカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '602',
    name: '沙流郡　平取町',
    kana_name: {
      half_upper: 'ｻﾙｸﾞﾝ ﾋﾞﾗﾄﾘﾁﾖｳ',
      full_lower: 'サルグン　ビラトリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '603',
    name: '沙流郡　門別町',
    kana_name: {
      half_upper: 'ｻﾙｸﾞﾝ ﾓﾝﾍﾞﾂﾁﾖｳ',
      full_lower: 'サルグン　モンベツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '01601沙流郡　日高町', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '604',
    name: '新冠郡　新冠町',
    kana_name: {
      half_upper: 'ﾆｲｶﾂﾌﾟｸﾞﾝ ﾆｲｶﾂﾌﾟﾁﾖｳ',
      full_lower: 'ニイカップグン　ニイカップチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '605',
    name: '静内郡　静内町',
    kana_name: {
      half_upper: 'ｼｽﾞﾅｲｸﾞﾝ ｼｽﾞﾅｲﾁﾖｳ',
      full_lower: 'シズナイグン　シズナイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '01610日高郡　新ひだか町', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '606',
    name: '三石郡　三石町',
    kana_name: {
      half_upper: 'ﾐﾂｲｼｸﾞﾝ ﾐﾂｲｼﾁﾖｳ',
      full_lower: 'ミツイシグン　ミツイシチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '01610日高郡　新ひだか町', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '607',
    name: '浦河郡　浦河町',
    kana_name: {
      half_upper: 'ｳﾗｶﾜｸﾞﾝ ｳﾗｶﾜﾁﾖｳ',
      full_lower: 'ウラカワグン　ウラカワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '608',
    name: '様似郡　様似町',
    kana_name: {
      half_upper: 'ｻﾏﾆｸﾞﾝ ｻﾏﾆﾁﾖｳ',
      full_lower: 'サマニグン　サマニチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '609',
    name: '幌泉郡　えりも町',
    kana_name: {
      half_upper: 'ﾎﾛｲｽﾞﾐｸﾞﾝ ｴﾘﾓﾁﾖｳ',
      full_lower: 'ホロイズミグン　エリモチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '610',
    name: '日高郡　新ひだか町',
    kana_name: {
      half_upper: 'ﾋﾀﾞｶｸﾞﾝ ｼﾝﾋﾀﾞｶﾁﾖｳ',
      full_lower: 'ヒダカグン　シンヒダカチョウ',
    },
    en: null,
    start_date: '2006-03-31',
    end_date: null,
    remark: { start: 'H18/03/31新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '630',
    name: '十勝支庁',
    kana_name: { half_upper: 'ﾄｶﾁｼﾁﾖｳ', full_lower: 'トカチシチョウ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-04-01',
    remark: { start: null, end: '01630十勝総合振興局', other: null },
    note: 'キー重複',
  },
  {
    pref_code: '01',
    city_code: '630',
    name: '十勝総合振興局',
    kana_name: {
      half_upper: 'ﾄｶﾁｿｳｺﾞｳｼﾝｺｳｷﾖｸ',
      full_lower: 'トカチソウゴウシンコウキョク',
    },
    en: null,
    start_date: '2010-04-01',
    end_date: null,
    remark: {
      start: 'H22/04/01支庁名変更：旧「十勝支庁」',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '631',
    name: '河東郡　音更町',
    kana_name: {
      half_upper: 'ｶﾄｳｸﾞﾝ ｵﾄﾌｹﾁﾖｳ',
      full_lower: 'カトウグン　オトフケチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '632',
    name: '河東郡　士幌町',
    kana_name: {
      half_upper: 'ｶﾄｳｸﾞﾝ ｼﾎﾛﾁﾖｳ',
      full_lower: 'カトウグン　シホロチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '633',
    name: '河東郡　上士幌町',
    kana_name: {
      half_upper: 'ｶﾄｳｸﾞﾝ ｶﾐｼﾎﾛﾁﾖｳ',
      full_lower: 'カトウグン　カミシホロチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '634',
    name: '河東郡　鹿追町',
    kana_name: {
      half_upper: 'ｶﾄｳｸﾞﾝ ｼｶｵｲﾁﾖｳ',
      full_lower: 'カトウグン　シカオイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '635',
    name: '上川郡　新得町',
    kana_name: {
      half_upper: 'ｶﾐｶﾜｸﾞﾝ ｼﾝﾄｸﾁﾖｳ',
      full_lower: 'カミカワグン　シントクチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '636',
    name: '上川郡　清水町',
    kana_name: {
      half_upper: 'ｶﾐｶﾜｸﾞﾝ ｼﾐｽﾞﾁﾖｳ',
      full_lower: 'カミカワグン　シミズチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '637',
    name: '河西郡　芽室町',
    kana_name: {
      half_upper: 'ｶｻｲｸﾞﾝ ﾒﾑﾛﾁﾖｳ',
      full_lower: 'カサイグン　メムロチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '638',
    name: '河西郡　中札内村',
    kana_name: {
      half_upper: 'ｶｻｲｸﾞﾝ ﾅｶｻﾂﾅｲﾑﾗ',
      full_lower: 'カサイグン　ナカサツナイムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '639',
    name: '河西郡　更別村',
    kana_name: {
      half_upper: 'ｶｻｲｸﾞﾝ ｻﾗﾍﾞﾂﾑﾗ',
      full_lower: 'カサイグン　サラベツムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '640',
    name: '広尾郡　忠類村',
    kana_name: {
      half_upper: 'ﾋﾛｵｸﾞﾝ ﾁﾕｳﾙｲﾑﾗ',
      full_lower: 'ヒロオグン　チュウルイムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-06',
    remark: { start: null, end: '01643中川郡　幕別町', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '641',
    name: '広尾郡　大樹町',
    kana_name: {
      half_upper: 'ﾋﾛｵｸﾞﾝ ﾀｲｷﾁﾖｳ',
      full_lower: 'ヒロオグン　タイキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '642',
    name: '広尾郡　広尾町',
    kana_name: {
      half_upper: 'ﾋﾛｵｸﾞﾝ ﾋﾛｵﾁﾖｳ',
      full_lower: 'ヒロオグン　ヒロオチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '643',
    name: '中川郡　幕別町',
    kana_name: {
      half_upper: 'ﾅｶｶﾞﾜｸﾞﾝ ﾏｸﾍﾞﾂﾁﾖｳ',
      full_lower: 'ナカガワグン　マクベツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '644',
    name: '中川郡　池田町',
    kana_name: {
      half_upper: 'ﾅｶｶﾞﾜｸﾞﾝ ｲｹﾀﾞﾁﾖｳ',
      full_lower: 'ナカガワグン　イケダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '645',
    name: '中川郡　豊頃町',
    kana_name: {
      half_upper: 'ﾅｶｶﾞﾜｸﾞﾝ ﾄﾖｺﾛﾁﾖｳ',
      full_lower: 'ナカガワグン　トヨコロチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '646',
    name: '中川郡　本別町',
    kana_name: {
      half_upper: 'ﾅｶｶﾞﾜｸﾞﾝ ﾎﾝﾍﾞﾂﾁﾖｳ',
      full_lower: 'ナカガワグン　ホンベツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '647',
    name: '足寄郡　足寄町',
    kana_name: {
      half_upper: 'ｱｼﾖﾛｸﾞﾝ ｱｼﾖﾛﾁﾖｳ',
      full_lower: 'アショログン　アショロチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '648',
    name: '足寄郡　陸別町',
    kana_name: {
      half_upper: 'ｱｼﾖﾛｸﾞﾝ ﾘｸﾍﾞﾂﾁﾖｳ',
      full_lower: 'アショログン　リクベツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '649',
    name: '十勝郡　浦幌町',
    kana_name: {
      half_upper: 'ﾄｶﾁｸﾞﾝ ｳﾗﾎﾛﾁﾖｳ',
      full_lower: 'トカチグン　ウラホロチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '660',
    name: '釧路支庁',
    kana_name: { half_upper: 'ｸｼﾛｼﾁﾖｳ', full_lower: 'クシロシチョウ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-04-01',
    remark: { start: null, end: '01660釧路総合振興局', other: null },
    note: 'キー重複',
  },
  {
    pref_code: '01',
    city_code: '660',
    name: '釧路総合振興局',
    kana_name: {
      half_upper: 'ｸｼﾛｿｳｺﾞｳｼﾝｺｳｷﾖｸ',
      full_lower: 'クシロソウゴウシンコウキョク',
    },
    en: null,
    start_date: '2010-04-01',
    end_date: null,
    remark: {
      start: 'H22/04/01支庁名変更：旧「釧路支庁」',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '661',
    name: '釧路郡　釧路町',
    kana_name: {
      half_upper: 'ｸｼﾛｸﾞﾝ ｸｼﾛﾁﾖｳ',
      full_lower: 'クシログン　クシロチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '662',
    name: '厚岸郡　厚岸町',
    kana_name: {
      half_upper: 'ｱﾂｹｼｸﾞﾝ ｱﾂｹｼﾁﾖｳ',
      full_lower: 'アッケシグン　アッケシチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '663',
    name: '厚岸郡　浜中町',
    kana_name: {
      half_upper: 'ｱﾂｹｼｸﾞﾝ ﾊﾏﾅｶﾁﾖｳ',
      full_lower: 'アッケシグン　ハマナカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '664',
    name: '川上郡　標茶町',
    kana_name: {
      half_upper: 'ｶﾜｶﾐｸﾞﾝ ｼﾍﾞﾁﾔﾁﾖｳ',
      full_lower: 'カワカミグン　シベチャチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '665',
    name: '川上郡　弟子屈町',
    kana_name: {
      half_upper: 'ｶﾜｶﾐｸﾞﾝ ﾃｼｶｶﾞﾁﾖｳ',
      full_lower: 'カワカミグン　テシカガチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '666',
    name: '阿寒郡　阿寒町',
    kana_name: {
      half_upper: 'ｱｶﾝｸﾞﾝ ｱｶﾝﾁﾖｳ',
      full_lower: 'アカングン　アカンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-11',
    remark: { start: null, end: '01206釧路市', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '667',
    name: '阿寒郡　鶴居村',
    kana_name: {
      half_upper: 'ｱｶﾝｸﾞﾝ ﾂﾙｲﾑﾗ',
      full_lower: 'アカングン　ツルイムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '668',
    name: '白糠郡　白糠町',
    kana_name: {
      half_upper: 'ｼﾗﾇｶｸﾞﾝ ｼﾗﾇｶﾁﾖｳ',
      full_lower: 'シラヌカグン　シラヌカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '669',
    name: '白糠郡　音別町',
    kana_name: {
      half_upper: 'ｼﾗﾇｶｸﾞﾝ ｵﾝﾍﾞﾂﾁﾖｳ',
      full_lower: 'シラヌカグン　オンベツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-11',
    remark: { start: null, end: '01206釧路市', other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '690',
    name: '根室支庁',
    kana_name: { half_upper: 'ﾈﾑﾛｼﾁﾖｳ', full_lower: 'ネムロシチョウ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-04-01',
    remark: { start: null, end: '01690根室振興局', other: null },
    note: 'キー重複',
  },
  {
    pref_code: '01',
    city_code: '690',
    name: '根室振興局',
    kana_name: {
      half_upper: 'ﾈﾑﾛｼﾝｺｳｷﾖｸ',
      full_lower: 'ネムロシンコウキョク',
    },
    en: null,
    start_date: '2010-04-01',
    end_date: null,
    remark: {
      start: 'H22/04/01支庁名変更：旧「根室支庁」',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '691',
    name: '野付郡　別海町',
    kana_name: {
      half_upper: 'ﾉﾂｹｸﾞﾝ ﾍﾞﾂｶｲﾁﾖｳ',
      full_lower: 'ノツケグン　ベツカイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '692',
    name: '標津郡　中標津町',
    kana_name: {
      half_upper: 'ｼﾍﾞﾂｸﾞﾝ ﾅｶｼﾍﾞﾂﾁﾖｳ',
      full_lower: 'シベツグン　ナカシベツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '693',
    name: '標津郡　標津町',
    kana_name: {
      half_upper: 'ｼﾍﾞﾂｸﾞﾝ ｼﾍﾞﾂﾁﾖｳ',
      full_lower: 'シベツグン　シベツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '694',
    name: '目梨郡　羅臼町',
    kana_name: {
      half_upper: 'ﾒﾅｼｸﾞﾝ ﾗｳｽﾁﾖｳ',
      full_lower: 'メナシグン　ラウスチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '695',
    name: '色丹郡　色丹村',
    kana_name: {
      half_upper: 'ｼｺﾀﾝｸﾞﾝ ｼｺﾀﾝﾑﾗ',
      full_lower: 'シコタングン　シコタンムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '696',
    name: '国後郡　泊村',
    kana_name: {
      half_upper: 'ｸﾅｼﾘｸﾞﾝ ﾄﾏﾘﾑﾗ',
      full_lower: 'クナシリグン　トマリムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '697',
    name: '国後郡　留夜別村',
    kana_name: {
      half_upper: 'ｸﾅｼﾘｸﾞﾝ ﾙﾖﾍﾞﾂﾑﾗ',
      full_lower: 'クナシリグン　ルヨベツムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '698',
    name: '択捉郡　留別村',
    kana_name: {
      half_upper: 'ｴﾄﾛﾌｸﾞﾝ ﾙﾍﾞﾂﾑﾗ',
      full_lower: 'エトロフグン　ルベツムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '699',
    name: '紗那郡　紗那村',
    kana_name: {
      half_upper: 'ｼﾔﾅｸﾞﾝ ｼﾔﾅﾑﾗ',
      full_lower: 'シャナグン　シャナムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '01',
    city_code: '700',
    name: '蘂取郡　蘂取村',
    kana_name: {
      half_upper: 'ｼﾍﾞﾄﾛｸﾞﾝ ｼﾍﾞﾄﾛﾑﾗ',
      full_lower: 'シベトログン　シベトロムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '201',
    name: '青森市',
    kana_name: { half_upper: 'ｱｵﾓﾘｼ', full_lower: 'アオモリシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '202',
    name: '弘前市',
    kana_name: { half_upper: 'ﾋﾛｻｷｼ', full_lower: 'ヒロサキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '203',
    name: '八戸市',
    kana_name: { half_upper: 'ﾊﾁﾉﾍｼ', full_lower: 'ハチノヘシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '204',
    name: '黒石市',
    kana_name: { half_upper: 'ｸﾛｲｼｼ', full_lower: 'クロイシシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '205',
    name: '五所川原市',
    kana_name: { half_upper: 'ｺﾞｼﾖｶﾞﾜﾗｼ', full_lower: 'ゴショガワラシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '206',
    name: '十和田市',
    kana_name: { half_upper: 'ﾄﾜﾀﾞｼ', full_lower: 'トワダシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '207',
    name: '三沢市',
    kana_name: { half_upper: 'ﾐｻﾜｼ', full_lower: 'ミサワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '208',
    name: 'むつ市',
    kana_name: { half_upper: 'ﾑﾂｼ', full_lower: 'ムツシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '209',
    name: 'つがる市',
    kana_name: { half_upper: 'ﾂｶﾞﾙｼ', full_lower: 'ツガルシ' },
    en: null,
    start_date: '2005-02-11',
    end_date: null,
    remark: { start: 'H17/02/11新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '210',
    name: '平川市',
    kana_name: { half_upper: 'ﾋﾗｶﾜｼ', full_lower: 'ヒラカワシ' },
    en: null,
    start_date: '2006-01-01',
    end_date: null,
    remark: { start: 'H18/01/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '300',
    name: '東津軽郡',
    kana_name: { half_upper: 'ﾋｶﾞｼﾂｶﾞﾙｸﾞﾝ', full_lower: 'ヒガシツガルグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '301',
    name: '東津軽郡　平内町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾂｶﾞﾙｸﾞﾝ ﾋﾗﾅｲﾏﾁ',
      full_lower: 'ヒガシツガルグン　ヒラナイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '302',
    name: '東津軽郡　蟹田町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾂｶﾞﾙｸﾞﾝ ｶﾆﾀﾏﾁ',
      full_lower: 'ヒガシツガルグン　カニタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '02307東津軽郡　外ヶ浜町', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '303',
    name: '東津軽郡　今別町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾂｶﾞﾙｸﾞﾝ ｲﾏﾍﾞﾂﾏﾁ',
      full_lower: 'ヒガシツガルグン　イマベツマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '304',
    name: '東津軽郡　蓬田村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾂｶﾞﾙｸﾞﾝ ﾖﾓｷﾞﾀﾑﾗ',
      full_lower: 'ヒガシツガルグン　ヨモギタムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '305',
    name: '東津軽郡　平舘村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾂｶﾞﾙｸﾞﾝ ﾀｲﾗﾀﾞﾃﾑﾗ',
      full_lower: 'ヒガシツガルグン　タイラダテムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '02307東津軽郡　外ヶ浜町', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '306',
    name: '東津軽郡　三厩村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾂｶﾞﾙｸﾞﾝ ﾐﾝﾏﾔﾑﾗ',
      full_lower: 'ヒガシツガルグン　ミンマヤムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '02307東津軽郡　外ヶ浜町', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '307',
    name: '東津軽郡　外ヶ浜町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾂｶﾞﾙｸﾞﾝ ｿﾄｶﾞﾊﾏﾏﾁ',
      full_lower: 'ヒガシツガルグン　ソトガハママチ',
    },
    en: null,
    start_date: '2005-03-28',
    end_date: null,
    remark: { start: 'H17/03/28新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '320',
    name: '西津軽郡',
    kana_name: { half_upper: 'ﾆｼﾂｶﾞﾙｸﾞﾝ', full_lower: 'ニシツガルグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '321',
    name: '西津軽郡　鰺ヶ沢町',
    kana_name: {
      half_upper: 'ﾆｼﾂｶﾞﾙｸﾞﾝ ｱｼﾞｶﾞｻﾜﾏﾁ',
      full_lower: 'ニシツガルグン　アジガサワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '322',
    name: '西津軽郡　木造町',
    kana_name: {
      half_upper: 'ﾆｼﾂｶﾞﾙｸﾞﾝ ｷﾂﾞｸﾘﾏﾁ',
      full_lower: 'ニシツガルグン　キヅクリマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-11',
    remark: { start: null, end: '02209つがる市', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '323',
    name: '西津軽郡　深浦町',
    kana_name: {
      half_upper: 'ﾆｼﾂｶﾞﾙｸﾞﾝ ﾌｶｳﾗﾏﾁ',
      full_lower: 'ニシツガルグン　フカウラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '324',
    name: '西津軽郡　森田村',
    kana_name: {
      half_upper: 'ﾆｼﾂｶﾞﾙｸﾞﾝ ﾓﾘﾀﾑﾗ',
      full_lower: 'ニシツガルグン　モリタムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-11',
    remark: { start: null, end: '02209つがる市', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '325',
    name: '西津軽郡　岩崎村',
    kana_name: {
      half_upper: 'ﾆｼﾂｶﾞﾙｸﾞﾝ ｲﾜｻｷﾑﾗ',
      full_lower: 'ニシツガルグン　イワサキムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '02323西津軽郡　深浦町', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '326',
    name: '西津軽郡　柏村',
    kana_name: {
      half_upper: 'ﾆｼﾂｶﾞﾙｸﾞﾝ ｶｼﾜﾑﾗ',
      full_lower: 'ニシツガルグン　カシワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-11',
    remark: { start: null, end: '02209つがる市', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '327',
    name: '西津軽郡　稲垣村',
    kana_name: {
      half_upper: 'ﾆｼﾂｶﾞﾙｸﾞﾝ ｲﾅｶﾞｷﾑﾗ',
      full_lower: 'ニシツガルグン　イナガキムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-11',
    remark: { start: null, end: '02209つがる市', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '328',
    name: '西津軽郡　車力村',
    kana_name: {
      half_upper: 'ﾆｼﾂｶﾞﾙｸﾞﾝ ｼﾔﾘｷﾑﾗ',
      full_lower: 'ニシツガルグン　シャリキムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-11',
    remark: { start: null, end: '02209つがる市', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '340',
    name: '中津軽郡',
    kana_name: { half_upper: 'ﾅｶﾂｶﾞﾙｸﾞﾝ', full_lower: 'ナカツガルグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '341',
    name: '中津軽郡　岩木町',
    kana_name: {
      half_upper: 'ﾅｶﾂｶﾞﾙｸﾞﾝ ｲﾜｷﾏﾁ',
      full_lower: 'ナカツガルグン　イワキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-27',
    remark: { start: null, end: '02202弘前市', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '342',
    name: '中津軽郡　相馬村',
    kana_name: {
      half_upper: 'ﾅｶﾂｶﾞﾙｸﾞﾝ ｿｳﾏﾑﾗ',
      full_lower: 'ナカツガルグン　ソウマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-27',
    remark: { start: null, end: '02202弘前市', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '343',
    name: '中津軽郡　西目屋村',
    kana_name: {
      half_upper: 'ﾅｶﾂｶﾞﾙｸﾞﾝ ﾆｼﾒﾔﾑﾗ',
      full_lower: 'ナカツガルグン　ニシメヤムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '360',
    name: '南津軽郡',
    kana_name: { half_upper: 'ﾐﾅﾐﾂｶﾞﾙｸﾞﾝ', full_lower: 'ミナミツガルグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '361',
    name: '南津軽郡　藤崎町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾂｶﾞﾙｸﾞﾝ ﾌｼﾞｻｷﾏﾁ',
      full_lower: 'ミナミツガルグン　フジサキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '362',
    name: '南津軽郡　大鰐町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾂｶﾞﾙｸﾞﾝ ｵｵﾜﾆﾏﾁ',
      full_lower: 'ミナミツガルグン　オオワニマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '363',
    name: '南津軽郡　尾上町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾂｶﾞﾙｸﾞﾝ ｵﾉｴﾏﾁ',
      full_lower: 'ミナミツガルグン　オノエマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '02210平川市', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '364',
    name: '南津軽郡　浪岡町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾂｶﾞﾙｸﾞﾝ ﾅﾐｵｶﾏﾁ',
      full_lower: 'ミナミツガルグン　ナミオカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '02201青森市', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '365',
    name: '南津軽郡　平賀町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾂｶﾞﾙｸﾞﾝ ﾋﾗｶﾏﾁ',
      full_lower: 'ミナミツガルグン　ヒラカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '02210平川市', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '366',
    name: '南津軽郡　常盤村',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾂｶﾞﾙｸﾞﾝ ﾄｷﾜﾑﾗ',
      full_lower: 'ミナミツガルグン　トキワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '02361南津軽郡　藤崎町', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '367',
    name: '南津軽郡　田舎館村',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾂｶﾞﾙｸﾞﾝ ｲﾅｶﾀﾞﾃﾑﾗ',
      full_lower: 'ミナミツガルグン　イナカダテムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '368',
    name: '南津軽郡　碇ヶ関村',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾂｶﾞﾙｸﾞﾝ ｲｶﾘｶﾞｾｷﾑﾗ',
      full_lower: 'ミナミツガルグン　イカリガセキムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '02210平川市', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '380',
    name: '北津軽郡',
    kana_name: { half_upper: 'ｷﾀﾂｶﾞﾙｸﾞﾝ', full_lower: 'キタツガルグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '381',
    name: '北津軽郡　板柳町',
    kana_name: {
      half_upper: 'ｷﾀﾂｶﾞﾙｸﾞﾝ ｲﾀﾔﾅｷﾞﾏﾁ',
      full_lower: 'キタツガルグン　イタヤナギマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '382',
    name: '北津軽郡　金木町',
    kana_name: {
      half_upper: 'ｷﾀﾂｶﾞﾙｸﾞﾝ ｶﾅｷﾞﾏﾁ',
      full_lower: 'キタツガルグン　カナギマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '02205五所川原市', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '383',
    name: '北津軽郡　中里町',
    kana_name: {
      half_upper: 'ｷﾀﾂｶﾞﾙｸﾞﾝ ﾅｶｻﾄﾏﾁ',
      full_lower: 'キタツガルグン　ナカサトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '02387北津軽郡　中泊町', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '384',
    name: '北津軽郡　鶴田町',
    kana_name: {
      half_upper: 'ｷﾀﾂｶﾞﾙｸﾞﾝ ﾂﾙﾀﾏﾁ',
      full_lower: 'キタツガルグン　ツルタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '385',
    name: '北津軽郡　市浦村',
    kana_name: {
      half_upper: 'ｷﾀﾂｶﾞﾙｸﾞﾝ ｼｳﾗﾑﾗ',
      full_lower: 'キタツガルグン　シウラムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '02205五所川原市', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '386',
    name: '北津軽郡　小泊村',
    kana_name: {
      half_upper: 'ｷﾀﾂｶﾞﾙｸﾞﾝ ｺﾄﾞﾏﾘﾑﾗ',
      full_lower: 'キタツガルグン　コドマリムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '02387北津軽郡　中泊町', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '387',
    name: '北津軽郡　中泊町',
    kana_name: {
      half_upper: 'ｷﾀﾂｶﾞﾙｸﾞﾝ ﾅｶﾄﾞﾏﾘﾏﾁ',
      full_lower: 'キタツガルグン　ナカドマリマチ',
    },
    en: null,
    start_date: '2005-03-28',
    end_date: null,
    remark: { start: 'H17/03/28新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '400',
    name: '上北郡',
    kana_name: { half_upper: 'ｶﾐｷﾀｸﾞﾝ', full_lower: 'カミキタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '401',
    name: '上北郡　野辺地町',
    kana_name: {
      half_upper: 'ｶﾐｷﾀｸﾞﾝ ﾉﾍｼﾞﾏﾁ',
      full_lower: 'カミキタグン　ノヘジマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '402',
    name: '上北郡　七戸町',
    kana_name: {
      half_upper: 'ｶﾐｷﾀｸﾞﾝ ｼﾁﾉﾍﾏﾁ',
      full_lower: 'カミキタグン　シチノヘマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '403',
    name: '上北郡　百石町',
    kana_name: {
      half_upper: 'ｶﾐｷﾀｸﾞﾝ ﾓﾓｲｼﾏﾁ',
      full_lower: 'カミキタグン　モモイシマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '02412上北郡　おいらせ町', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '404',
    name: '上北郡　十和田湖町',
    kana_name: {
      half_upper: 'ｶﾐｷﾀｸﾞﾝ ﾄﾜﾀﾞｺﾏﾁ',
      full_lower: 'カミキタグン　トワダコマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '02206十和田市', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '405',
    name: '上北郡　六戸町',
    kana_name: {
      half_upper: 'ｶﾐｷﾀｸﾞﾝ ﾛｸﾉﾍﾏﾁ',
      full_lower: 'カミキタグン　ロクノヘマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '406',
    name: '上北郡　横浜町',
    kana_name: {
      half_upper: 'ｶﾐｷﾀｸﾞﾝ ﾖｺﾊﾏﾏﾁ',
      full_lower: 'カミキタグン　ヨコハママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '407',
    name: '上北郡　上北町',
    kana_name: {
      half_upper: 'ｶﾐｷﾀｸﾞﾝ ｶﾐｷﾀﾏﾁ',
      full_lower: 'カミキタグン　カミキタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '02408上北郡　東北町', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '408',
    name: '上北郡　東北町',
    kana_name: {
      half_upper: 'ｶﾐｷﾀｸﾞﾝ ﾄｳﾎｸﾏﾁ',
      full_lower: 'カミキタグン　トウホクマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '409',
    name: '上北郡　天間林村',
    kana_name: {
      half_upper: 'ｶﾐｷﾀｸﾞﾝ ﾃﾝﾏﾊﾞﾔｼﾑﾗ',
      full_lower: 'カミキタグン　テンマバヤシムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '02402上北郡　七戸町', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '410',
    name: '上北郡　下田町',
    kana_name: {
      half_upper: 'ｶﾐｷﾀｸﾞﾝ ｼﾓﾀﾞﾏﾁ',
      full_lower: 'カミキタグン　シモダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '02412上北郡　おいらせ町', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '411',
    name: '上北郡　六ヶ所村',
    kana_name: {
      half_upper: 'ｶﾐｷﾀｸﾞﾝ ﾛﾂｶｼﾖﾑﾗ',
      full_lower: 'カミキタグン　ロッカショムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '412',
    name: '上北郡　おいらせ町',
    kana_name: {
      half_upper: 'ｶﾐｷﾀｸﾞﾝ ｵｲﾗｾﾁﾖｳ',
      full_lower: 'カミキタグン　オイラセチョウ',
    },
    en: null,
    start_date: '2006-03-01',
    end_date: null,
    remark: { start: 'H18/03/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '420',
    name: '下北郡',
    kana_name: { half_upper: 'ｼﾓｷﾀｸﾞﾝ', full_lower: 'シモキタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '421',
    name: '下北郡　川内町',
    kana_name: {
      half_upper: 'ｼﾓｷﾀｸﾞﾝ ｶﾜｳﾁﾏﾁ',
      full_lower: 'シモキタグン　カワウチマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-14',
    remark: { start: null, end: '02208むつ市', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '422',
    name: '下北郡　大畑町',
    kana_name: {
      half_upper: 'ｼﾓｷﾀｸﾞﾝ ｵｵﾊﾀﾏﾁ',
      full_lower: 'シモキタグン　オオハタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-14',
    remark: { start: null, end: '02208むつ市', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '423',
    name: '下北郡　大間町',
    kana_name: {
      half_upper: 'ｼﾓｷﾀｸﾞﾝ ｵｵﾏﾏﾁ',
      full_lower: 'シモキタグン　オオママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '424',
    name: '下北郡　東通村',
    kana_name: {
      half_upper: 'ｼﾓｷﾀｸﾞﾝ ﾋｶﾞｼﾄﾞｵﾘﾑﾗ',
      full_lower: 'シモキタグン　ヒガシドオリムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '425',
    name: '下北郡　風間浦村',
    kana_name: {
      half_upper: 'ｼﾓｷﾀｸﾞﾝ ｶｻﾞﾏｳﾗﾑﾗ',
      full_lower: 'シモキタグン　カザマウラムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '426',
    name: '下北郡　佐井村',
    kana_name: {
      half_upper: 'ｼﾓｷﾀｸﾞﾝ ｻｲﾑﾗ',
      full_lower: 'シモキタグン　サイムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '427',
    name: '下北郡　脇野沢村',
    kana_name: {
      half_upper: 'ｼﾓｷﾀｸﾞﾝ ﾜｷﾉｻﾜﾑﾗ',
      full_lower: 'シモキタグン　ワキノサワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-14',
    remark: { start: null, end: '02208むつ市', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '440',
    name: '三戸郡',
    kana_name: { half_upper: 'ｻﾝﾉﾍｸﾞﾝ', full_lower: 'サンノヘグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '441',
    name: '三戸郡　三戸町',
    kana_name: {
      half_upper: 'ｻﾝﾉﾍｸﾞﾝ ｻﾝﾉﾍﾏﾁ',
      full_lower: 'サンノヘグン　サンノヘマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '442',
    name: '三戸郡　五戸町',
    kana_name: {
      half_upper: 'ｻﾝﾉﾍｸﾞﾝ ｺﾞﾉﾍﾏﾁ',
      full_lower: 'サンノヘグン　ゴノヘマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '443',
    name: '三戸郡　田子町',
    kana_name: {
      half_upper: 'ｻﾝﾉﾍｸﾞﾝ ﾀﾂｺﾏﾁ',
      full_lower: 'サンノヘグン　タッコマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '444',
    name: '三戸郡　名川町',
    kana_name: {
      half_upper: 'ｻﾝﾉﾍｸﾞﾝ ﾅｶﾞﾜﾏﾁ',
      full_lower: 'サンノヘグン　ナガワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '02445三戸郡　南部町', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '445',
    name: '三戸郡　南部町',
    kana_name: {
      half_upper: 'ｻﾝﾉﾍｸﾞﾝ ﾅﾝﾌﾞﾁﾖｳ',
      full_lower: 'サンノヘグン　ナンブチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '446',
    name: '三戸郡　階上町',
    kana_name: {
      half_upper: 'ｻﾝﾉﾍｸﾞﾝ ﾊｼｶﾐﾁﾖｳ',
      full_lower: 'サンノヘグン　ハシカミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '447',
    name: '三戸郡　福地村',
    kana_name: {
      half_upper: 'ｻﾝﾉﾍｸﾞﾝ ﾌｸﾁﾑﾗ',
      full_lower: 'サンノヘグン　フクチムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '02445三戸郡　南部町', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '448',
    name: '三戸郡　南郷村',
    kana_name: {
      half_upper: 'ｻﾝﾉﾍｸﾞﾝ ﾅﾝｺﾞｳﾑﾗ',
      full_lower: 'サンノヘグン　ナンゴウムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '02203八戸市', other: null },
    note: 'JISX0402:2020では現存する自治体のように見えるが「平成17年1月18日付総務省告示第53～68号」によるコード表の変更に含まれているので、削除モレ',
  },
  {
    pref_code: '02',
    city_code: '449',
    name: '三戸郡　倉石村',
    kana_name: {
      half_upper: 'ｻﾝﾉﾍｸﾞﾝ ｸﾗｲｼﾑﾗ',
      full_lower: 'サンノヘグン　クライシムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-07-01',
    remark: { start: null, end: '02442三戸郡　五戸町', other: null },
    note: null,
  },
  {
    pref_code: '02',
    city_code: '450',
    name: '三戸郡　新郷村',
    kana_name: {
      half_upper: 'ｻﾝﾉﾍｸﾞﾝ ｼﾝｺﾞｳﾑﾗ',
      full_lower: 'サンノヘグン　シンゴウムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '201',
    name: '盛岡市',
    kana_name: { half_upper: 'ﾓﾘｵｶｼ', full_lower: 'モリオカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '202',
    name: '宮古市',
    kana_name: { half_upper: 'ﾐﾔｺｼ', full_lower: 'ミヤコシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '203',
    name: '大船渡市',
    kana_name: { half_upper: 'ｵｵﾌﾅﾄｼ', full_lower: 'オオフナトシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '204',
    name: '水沢市',
    kana_name: { half_upper: 'ﾐｽﾞｻﾜｼ', full_lower: 'ミズサワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-20',
    remark: { start: null, end: '03215奥州市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '205',
    name: '花巻市',
    kana_name: { half_upper: 'ﾊﾅﾏｷｼ', full_lower: 'ハナマキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '206',
    name: '北上市',
    kana_name: { half_upper: 'ｷﾀｶﾐｼ', full_lower: 'キタカミシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '207',
    name: '久慈市',
    kana_name: { half_upper: 'ｸｼﾞｼ', full_lower: 'クジシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '208',
    name: '遠野市',
    kana_name: { half_upper: 'ﾄｵﾉｼ', full_lower: 'トオノシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '209',
    name: '一関市',
    kana_name: { half_upper: 'ｲﾁﾉｾｷｼ', full_lower: 'イチノセキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '210',
    name: '陸前高田市',
    kana_name: { half_upper: 'ﾘｸｾﾞﾝﾀｶﾀｼ', full_lower: 'リクゼンタカタシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '211',
    name: '釜石市',
    kana_name: { half_upper: 'ｶﾏｲｼｼ', full_lower: 'カマイシシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '212',
    name: '江刺市',
    kana_name: { half_upper: 'ｴｻｼｼ', full_lower: 'エサシシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-20',
    remark: { start: null, end: '03215奥州市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '213',
    name: '二戸市',
    kana_name: { half_upper: 'ﾆﾉﾍｼ', full_lower: 'ニノヘシ' },
    en: null,
    start_date: '1972-04-01',
    end_date: null,
    remark: { start: 'S47/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '214',
    name: '八幡平市',
    kana_name: { half_upper: 'ﾊﾁﾏﾝﾀｲｼ', full_lower: 'ハチマンタイシ' },
    en: null,
    start_date: '2005-09-01',
    end_date: null,
    remark: { start: 'H17/09/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '215',
    name: '奥州市',
    kana_name: { half_upper: 'ｵｳｼﾕｳｼ', full_lower: 'オウシュウシ' },
    en: null,
    start_date: '2006-02-20',
    end_date: null,
    remark: { start: 'H18/02/20新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '216',
    name: '滝沢市',
    kana_name: { half_upper: 'ﾀｷｻﾞﾜｼ', full_lower: 'タキザワシ' },
    en: null,
    start_date: '2014-01-01',
    end_date: null,
    remark: { start: 'H26/01/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '300',
    name: '岩手郡',
    kana_name: { half_upper: 'ｲﾜﾃｸﾞﾝ', full_lower: 'イワテグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '301',
    name: '岩手郡　雫石町',
    kana_name: {
      half_upper: 'ｲﾜﾃｸﾞﾝ ｼｽﾞｸｲｼﾁﾖｳ',
      full_lower: 'イワテグン　シズクイシチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '302',
    name: '岩手郡　葛巻町',
    kana_name: {
      half_upper: 'ｲﾜﾃｸﾞﾝ ｸｽﾞﾏｷﾏﾁ',
      full_lower: 'イワテグン　クズマキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '303',
    name: '岩手郡　岩手町',
    kana_name: {
      half_upper: 'ｲﾜﾃｸﾞﾝ ｲﾜﾃﾏﾁ',
      full_lower: 'イワテグン　イワテマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '304',
    name: '岩手郡　西根町',
    kana_name: {
      half_upper: 'ｲﾜﾃｸﾞﾝ ﾆｼﾈﾁﾖｳ',
      full_lower: 'イワテグン　ニシネチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-01',
    remark: { start: null, end: '03214八幡平市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '305',
    name: '岩手郡　滝沢村',
    kana_name: {
      half_upper: 'ｲﾜﾃｸﾞﾝ ﾀｷｻﾞﾜﾑﾗ',
      full_lower: 'イワテグン　タキザワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2014-01-01',
    remark: { start: null, end: '03216滝沢市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '306',
    name: '岩手郡　松尾村',
    kana_name: {
      half_upper: 'ｲﾜﾃｸﾞﾝ ﾏﾂｵﾑﾗ',
      full_lower: 'イワテグン　マツオムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-01',
    remark: { start: null, end: '03214八幡平市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '307',
    name: '岩手郡　玉山村',
    kana_name: {
      half_upper: 'ｲﾜﾃｸﾞﾝ ﾀﾏﾔﾏﾑﾗ',
      full_lower: 'イワテグン　タマヤマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-10',
    remark: { start: null, end: '03201盛岡市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '308',
    name: '岩手郡　安代町',
    kana_name: {
      half_upper: 'ｲﾜﾃｸﾞﾝ ｱｼﾛﾁﾖｳ',
      full_lower: 'イワテグン　アシロチョウ',
    },
    en: null,
    start_date: '2002-04-01',
    end_date: '2005-09-01',
    remark: { start: 'H14/04/01郡変更', end: '03214八幡平市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '320',
    name: '紫波郡',
    kana_name: { half_upper: 'ｼﾜｸﾞﾝ', full_lower: 'シワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '321',
    name: '紫波郡　紫波町',
    kana_name: {
      half_upper: 'ｼﾜｸﾞﾝ ｼﾜﾁﾖｳ',
      full_lower: 'シワグン　シワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '322',
    name: '紫波郡　矢巾町',
    kana_name: {
      half_upper: 'ｼﾜｸﾞﾝ ﾔﾊﾊﾞﾁﾖｳ',
      full_lower: 'シワグン　ヤハバチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '323',
    name: '紫波郡　都南村',
    kana_name: {
      half_upper: 'ｼﾜｸﾞﾝ ﾄﾅﾝﾑﾗ',
      full_lower: 'シワグン　トナンムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1992-04-01',
    remark: { start: null, end: '03201盛岡市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '340',
    name: '稗貫郡',
    kana_name: { half_upper: 'ﾋｴﾇｷｸﾞﾝ', full_lower: 'ヒエヌキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '341',
    name: '稗貫郡　大迫町',
    kana_name: {
      half_upper: 'ﾋｴﾇｷｸﾞﾝ ｵｵﾊｻﾏﾏﾁ',
      full_lower: 'ヒエヌキグン　オオハサママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '03205花巻市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '342',
    name: '稗貫郡　石鳥谷町',
    kana_name: {
      half_upper: 'ﾋｴﾇｷｸﾞﾝ ｲｼﾄﾞﾘﾔﾁﾖｳ',
      full_lower: 'ヒエヌキグン　イシドリヤチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '03205花巻市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '360',
    name: '和賀郡',
    kana_name: { half_upper: 'ﾜｶﾞｸﾞﾝ', full_lower: 'ワガグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '361',
    name: '和賀郡　東和町',
    kana_name: {
      half_upper: 'ﾜｶﾞｸﾞﾝ ﾄｳﾜﾁﾖｳ',
      full_lower: 'ワガグン　トウワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '03205花巻市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '362',
    name: '和賀郡　和賀町',
    kana_name: {
      half_upper: 'ﾜｶﾞｸﾞﾝ ﾜｶﾞﾁﾖｳ',
      full_lower: 'ワガグン　ワガチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1991-04-01',
    remark: { start: null, end: '03206北上市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '363',
    name: '和賀郡　湯田町',
    kana_name: {
      half_upper: 'ﾜｶﾞｸﾞﾝ ﾕﾀﾞﾏﾁ',
      full_lower: 'ワガグン　ユダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-01',
    remark: { start: null, end: '03366和賀郡　西和賀町', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '364',
    name: '和賀郡　江釣子村',
    kana_name: {
      half_upper: 'ﾜｶﾞｸﾞﾝ ｴﾂﾞﾘｺﾑﾗ',
      full_lower: 'ワガグン　エヅリコムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1991-04-01',
    remark: { start: null, end: '03206北上市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '365',
    name: '和賀郡　沢内村',
    kana_name: {
      half_upper: 'ﾜｶﾞｸﾞﾝ ｻﾜｳﾁﾑﾗ',
      full_lower: 'ワガグン　サワウチムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-01',
    remark: { start: null, end: '03366和賀郡　西和賀町', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '366',
    name: '和賀郡　西和賀町',
    kana_name: {
      half_upper: 'ﾜｶﾞｸﾞﾝ ﾆｼﾜｶﾞﾏﾁ',
      full_lower: 'ワガグン　ニシワガマチ',
    },
    en: null,
    start_date: '2005-11-01',
    end_date: null,
    remark: { start: 'H17/11/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '380',
    name: '胆沢郡',
    kana_name: { half_upper: 'ｲｻﾜｸﾞﾝ', full_lower: 'イサワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '381',
    name: '胆沢郡　金ケ崎町',
    kana_name: {
      half_upper: 'ｲｻﾜｸﾞﾝ ｶﾈｶﾞｻｷﾁﾖｳ',
      full_lower: 'イサワグン　カネガサキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '382',
    name: '胆沢郡　前沢町',
    kana_name: {
      half_upper: 'ｲｻﾜｸﾞﾝ ﾏｴｻﾜﾁﾖｳ',
      full_lower: 'イサワグン　マエサワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-20',
    remark: { start: null, end: '03215奥州市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '383',
    name: '胆沢郡　胆沢町',
    kana_name: {
      half_upper: 'ｲｻﾜｸﾞﾝ ｲｻﾜﾁﾖｳ',
      full_lower: 'イサワグン　イサワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-20',
    remark: { start: null, end: '03215奥州市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '384',
    name: '胆沢郡　衣川村',
    kana_name: {
      half_upper: 'ｲｻﾜｸﾞﾝ ｺﾛﾓｶﾞﾜﾑﾗ',
      full_lower: 'イサワグン　コロモガワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-20',
    remark: { start: null, end: '03215奥州市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '400',
    name: '西磐井郡',
    kana_name: { half_upper: 'ﾆｼｲﾜｲｸﾞﾝ', full_lower: 'ニシイワイグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '401',
    name: '西磐井郡　花泉町',
    kana_name: {
      half_upper: 'ﾆｼｲﾜｲｸﾞﾝ ﾊﾅｲｽﾞﾐﾏﾁ',
      full_lower: 'ニシイワイグン　ハナイズミマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-20',
    remark: { start: null, end: '03209一関市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '402',
    name: '西磐井郡　平泉町',
    kana_name: {
      half_upper: 'ﾆｼｲﾜｲｸﾞﾝ ﾋﾗｲｽﾞﾐﾁﾖｳ',
      full_lower: 'ニシイワイグン　ヒライズミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '420',
    name: '東磐井郡',
    kana_name: { half_upper: 'ﾋｶﾞｼｲﾜｲｸﾞﾝ', full_lower: 'ヒガシイワイグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2011-09-26',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '421',
    name: '東磐井郡　大東町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｲﾜｲｸﾞﾝ ﾀﾞｲﾄｳﾁﾖｳ',
      full_lower: 'ヒガシイワイグン　ダイトウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-20',
    remark: { start: null, end: '03209一関市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '422',
    name: '東磐井郡　藤沢町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｲﾜｲｸﾞﾝ ﾌｼﾞｻﾜﾁﾖｳ',
      full_lower: 'ヒガシイワイグン　フジサワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2011-09-26',
    remark: { start: null, end: '03209一関市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '423',
    name: '東磐井郡　千厩町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｲﾜｲｸﾞﾝ ｾﾝﾏﾔﾁﾖｳ',
      full_lower: 'ヒガシイワイグン　センマヤチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-20',
    remark: { start: null, end: '03209一関市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '424',
    name: '東磐井郡　東山町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｲﾜｲｸﾞﾝ ﾋｶﾞｼﾔﾏﾁﾖｳ',
      full_lower: 'ヒガシイワイグン　ヒガシヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-20',
    remark: { start: null, end: '03209一関市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '425',
    name: '東磐井郡　室根村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｲﾜｲｸﾞﾝ ﾑﾛﾈﾑﾗ',
      full_lower: 'ヒガシイワイグン　ムロネムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-20',
    remark: { start: null, end: '03209一関市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '426',
    name: '東磐井郡　川崎村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｲﾜｲｸﾞﾝ ｶﾜｻｷﾑﾗ',
      full_lower: 'ヒガシイワイグン　カワサキムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-20',
    remark: { start: null, end: '03209一関市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '440',
    name: '気仙郡',
    kana_name: { half_upper: 'ｹｾﾝｸﾞﾝ', full_lower: 'ケセングン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '441',
    name: '気仙郡　住田町',
    kana_name: {
      half_upper: 'ｹｾﾝｸﾞﾝ ｽﾐﾀﾁﾖｳ',
      full_lower: 'ケセングン　スミタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '442',
    name: '気仙郡　三陸町',
    kana_name: {
      half_upper: 'ｹｾﾝｸﾞﾝ ｻﾝﾘｸﾁﾖｳ',
      full_lower: 'ケセングン　サンリクチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2001-11-15',
    remark: { start: null, end: '03203大船渡市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '460',
    name: '上閉伊郡',
    kana_name: { half_upper: 'ｶﾐﾍｲｸﾞﾝ', full_lower: 'カミヘイグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '461',
    name: '上閉伊郡　大槌町',
    kana_name: {
      half_upper: 'ｶﾐﾍｲｸﾞﾝ ｵｵﾂﾁﾁﾖｳ',
      full_lower: 'カミヘイグン　オオツチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '462',
    name: '上閉伊郡　宮守村',
    kana_name: {
      half_upper: 'ｶﾐﾍｲｸﾞﾝ ﾐﾔﾓﾘﾑﾗ',
      full_lower: 'カミヘイグン　ミヤモリムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '03208遠野市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '480',
    name: '下閉伊郡',
    kana_name: { half_upper: 'ｼﾓﾍｲｸﾞﾝ', full_lower: 'シモヘイグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '481',
    name: '下閉伊郡　田老町',
    kana_name: {
      half_upper: 'ｼﾓﾍｲｸﾞﾝ ﾀﾛｳﾁﾖｳ',
      full_lower: 'シモヘイグン　タロウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-06-06',
    remark: { start: null, end: '03202宮古市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '482',
    name: '下閉伊郡　山田町',
    kana_name: {
      half_upper: 'ｼﾓﾍｲｸﾞﾝ ﾔﾏﾀﾞﾏﾁ',
      full_lower: 'シモヘイグン　ヤマダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '483',
    name: '下閉伊郡　岩泉町',
    kana_name: {
      half_upper: 'ｼﾓﾍｲｸﾞﾝ ｲﾜｲｽﾞﾐﾁﾖｳ',
      full_lower: 'シモヘイグン　イワイズミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '484',
    name: '下閉伊郡　田野畑村',
    kana_name: {
      half_upper: 'ｼﾓﾍｲｸﾞﾝ ﾀﾉﾊﾀﾑﾗ',
      full_lower: 'シモヘイグン　タノハタムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '485',
    name: '下閉伊郡　普代村',
    kana_name: {
      half_upper: 'ｼﾓﾍｲｸﾞﾝ ﾌﾀﾞｲﾑﾗ',
      full_lower: 'シモヘイグン　フダイムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '486',
    name: '下閉伊郡　新里村',
    kana_name: {
      half_upper: 'ｼﾓﾍｲｸﾞﾝ ﾆｲｻﾄﾑﾗ',
      full_lower: 'シモヘイグン　ニイサトムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-06-06',
    remark: { start: null, end: '03202宮古市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '487',
    name: '下閉伊郡　川井村',
    kana_name: {
      half_upper: 'ｼﾓﾍｲｸﾞﾝ ｶﾜｲﾑﾗ',
      full_lower: 'シモヘイグン　カワイムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-01-01',
    remark: { start: null, end: '03202宮古市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '500',
    name: '九戸郡',
    kana_name: { half_upper: 'ｸﾉﾍｸﾞﾝ', full_lower: 'クノヘグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '501',
    name: '九戸郡　軽米町',
    kana_name: {
      half_upper: 'ｸﾉﾍｸﾞﾝ ｶﾙﾏｲﾏﾁ',
      full_lower: 'クノヘグン　カルマイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '502',
    name: '九戸郡　種市町',
    kana_name: {
      half_upper: 'ｸﾉﾍｸﾞﾝ ﾀﾈｲﾁﾏﾁ',
      full_lower: 'クノヘグン　タネイチマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '03507九戸郡　洋野町', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '503',
    name: '九戸郡　野田村',
    kana_name: {
      half_upper: 'ｸﾉﾍｸﾞﾝ ﾉﾀﾞﾑﾗ',
      full_lower: 'クノヘグン　ノダムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '504',
    name: '九戸郡　山形村',
    kana_name: {
      half_upper: 'ｸﾉﾍｸﾞﾝ ﾔﾏｶﾞﾀﾑﾗ',
      full_lower: 'クノヘグン　ヤマガタムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-06',
    remark: { start: null, end: '03207久慈市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '505',
    name: '九戸郡　大野村',
    kana_name: {
      half_upper: 'ｸﾉﾍｸﾞﾝ ｵｵﾉﾑﾗ',
      full_lower: 'クノヘグン　オオノムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '03507九戸郡　洋野町', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '506',
    name: '九戸郡　九戸村',
    kana_name: {
      half_upper: 'ｸﾉﾍｸﾞﾝ ｸﾉﾍﾑﾗ',
      full_lower: 'クノヘグン　クノヘムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '507',
    name: '九戸郡　洋野町',
    kana_name: {
      half_upper: 'ｸﾉﾍｸﾞﾝ ﾋﾛﾉﾁﾖｳ',
      full_lower: 'クノヘグン　ヒロノチョウ',
    },
    en: null,
    start_date: '2006-01-01',
    end_date: null,
    remark: { start: 'H18/01/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '520',
    name: '二戸郡',
    kana_name: { half_upper: 'ﾆﾉﾍｸﾞﾝ', full_lower: 'ニノヘグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '521',
    name: '二戸郡　浄法寺町',
    kana_name: {
      half_upper: 'ﾆﾉﾍｸﾞﾝ ｼﾞﾖｳﾎﾞｳｼﾞﾏﾁ',
      full_lower: 'ニノヘグン　ジョウボウジマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '03213二戸市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '522',
    name: '二戸郡　福岡町',
    kana_name: {
      half_upper: 'ﾆﾉﾍｸﾞﾝ ﾌｸｵｶﾏﾁ',
      full_lower: 'ニノヘグン　フクオカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-04-01',
    remark: { start: null, end: '03213二戸市', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '523',
    name: '二戸郡　安代町',
    kana_name: {
      half_upper: 'ﾆﾉﾍｸﾞﾝ ｱｼﾛﾁﾖｳ',
      full_lower: 'ニノヘグン　アシロチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2002-04-01',
    remark: { start: null, end: '03308岩手郡　安代町', other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '524',
    name: '二戸郡　一戸町',
    kana_name: {
      half_upper: 'ﾆﾉﾍｸﾞﾝ ｲﾁﾉﾍﾏﾁ',
      full_lower: 'ニノヘグン　イチノヘマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '03',
    city_code: '525',
    name: '二戸郡　金田一村',
    kana_name: {
      half_upper: 'ﾆﾉﾍｸﾞﾝ ｷﾝﾀﾞｲﾁﾑﾗ',
      full_lower: 'ニノヘグン　キンダイチムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-04-01',
    remark: { start: null, end: '03213二戸市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '100',
    name: '仙台市',
    kana_name: { half_upper: 'ｾﾝﾀﾞｲｼ', full_lower: 'センダイシ' },
    en: null,
    start_date: '1989-04-01',
    end_date: null,
    remark: { start: 'H01/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '101',
    name: '仙台市　青葉区',
    kana_name: {
      half_upper: 'ｾﾝﾀﾞｲｼ ｱｵﾊﾞｸ',
      full_lower: 'センダイシ　アオバク',
    },
    en: null,
    start_date: '1989-04-01',
    end_date: null,
    remark: { start: 'H01/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '102',
    name: '仙台市　宮城野区',
    kana_name: {
      half_upper: 'ｾﾝﾀﾞｲｼ ﾐﾔｷﾞﾉｸ',
      full_lower: 'センダイシ　ミヤギノク',
    },
    en: null,
    start_date: '1989-04-01',
    end_date: null,
    remark: { start: 'H01/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '103',
    name: '仙台市　若林区',
    kana_name: {
      half_upper: 'ｾﾝﾀﾞｲｼ ﾜｶﾊﾞﾔｼｸ',
      full_lower: 'センダイシ　ワカバヤシク',
    },
    en: null,
    start_date: '1989-04-01',
    end_date: null,
    remark: { start: 'H01/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '104',
    name: '仙台市　太白区',
    kana_name: {
      half_upper: 'ｾﾝﾀﾞｲｼ ﾀｲﾊｸｸ',
      full_lower: 'センダイシ　タイハクク',
    },
    en: null,
    start_date: '1989-04-01',
    end_date: null,
    remark: { start: 'H01/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '105',
    name: '仙台市　泉区',
    kana_name: {
      half_upper: 'ｾﾝﾀﾞｲｼ ｲｽﾞﾐｸ',
      full_lower: 'センダイシ　イズミク',
    },
    en: null,
    start_date: '1989-04-01',
    end_date: null,
    remark: { start: 'H01/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '201',
    name: '仙台市',
    kana_name: { half_upper: 'ｾﾝﾀﾞｲｼ', full_lower: 'センダイシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '1989-04-01',
    remark: { start: null, end: '04100仙台市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '202',
    name: '石巻市',
    kana_name: { half_upper: 'ｲｼﾉﾏｷｼ', full_lower: 'イシノマキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '203',
    name: '塩竈市',
    kana_name: { half_upper: 'ｼｵｶﾞﾏｼ', full_lower: 'シオガマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '204',
    name: '古川市',
    kana_name: { half_upper: 'ﾌﾙｶﾜｼ', full_lower: 'フルカワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '04215大崎市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '205',
    name: '気仙沼市',
    kana_name: { half_upper: 'ｹｾﾝﾇﾏｼ', full_lower: 'ケセンヌマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '206',
    name: '白石市',
    kana_name: { half_upper: 'ｼﾛｲｼｼ', full_lower: 'シロイシシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '207',
    name: '名取市',
    kana_name: { half_upper: 'ﾅﾄﾘｼ', full_lower: 'ナトリシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '208',
    name: '角田市',
    kana_name: { half_upper: 'ｶｸﾀﾞｼ', full_lower: 'カクダシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '209',
    name: '多賀城市',
    kana_name: { half_upper: 'ﾀｶﾞｼﾞﾖｳｼ', full_lower: 'タガジョウシ' },
    en: null,
    start_date: '1971-11-01',
    end_date: null,
    remark: { start: 'S46/11/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '210',
    name: '泉市',
    kana_name: { half_upper: 'ｲｽﾞﾐｼ', full_lower: 'イズミシ' },
    en: null,
    start_date: '1971-11-01',
    end_date: '1988-03-01',
    remark: { start: 'S46/11/01市制', end: '04201仙台市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '211',
    name: '岩沼市',
    kana_name: { half_upper: 'ｲﾜﾇﾏｼ', full_lower: 'イワヌマシ' },
    en: null,
    start_date: '1971-11-01',
    end_date: null,
    remark: { start: 'S46/11/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '212',
    name: '登米市',
    kana_name: { half_upper: 'ﾄﾒｼ', full_lower: 'トメシ' },
    en: null,
    start_date: '2005-04-01',
    end_date: null,
    remark: { start: 'H17/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '213',
    name: '栗原市',
    kana_name: { half_upper: 'ｸﾘﾊﾗｼ', full_lower: 'クリハラシ' },
    en: null,
    start_date: '2005-04-01',
    end_date: null,
    remark: { start: 'H17/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '214',
    name: '東松島市',
    kana_name: { half_upper: 'ﾋｶﾞｼﾏﾂｼﾏｼ', full_lower: 'ヒガシマツシマシ' },
    en: null,
    start_date: '2005-04-01',
    end_date: null,
    remark: { start: 'H17/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '215',
    name: '大崎市',
    kana_name: { half_upper: 'ｵｵｻｷｼ', full_lower: 'オオサキシ' },
    en: null,
    start_date: '2006-03-31',
    end_date: null,
    remark: { start: 'H18/03/31新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '216',
    name: '富谷市',
    kana_name: { half_upper: 'ﾄﾐﾔｼ', full_lower: 'トミヤシ' },
    en: null,
    start_date: '2016-10-10',
    end_date: null,
    remark: { start: 'H28/10/10市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '300',
    name: '刈田郡',
    kana_name: { half_upper: 'ｶﾂﾀｸﾞﾝ', full_lower: 'カッタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '301',
    name: '刈田郡　蔵王町',
    kana_name: {
      half_upper: 'ｶﾂﾀｸﾞﾝ ｻﾞｵｳﾏﾁ',
      full_lower: 'カッタグン　ザオウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '302',
    name: '刈田郡　七ヶ宿町',
    kana_name: {
      half_upper: 'ｶﾂﾀｸﾞﾝ ｼﾁｶｼﾕｸﾏﾁ',
      full_lower: 'カッタグン　シチカシュクマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '320',
    name: '柴田郡',
    kana_name: { half_upper: 'ｼﾊﾞﾀｸﾞﾝ', full_lower: 'シバタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '321',
    name: '柴田郡　大河原町',
    kana_name: {
      half_upper: 'ｼﾊﾞﾀｸﾞﾝ ｵｵｶﾞﾜﾗﾏﾁ',
      full_lower: 'シバタグン　オオガワラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '322',
    name: '柴田郡　村田町',
    kana_name: {
      half_upper: 'ｼﾊﾞﾀｸﾞﾝ ﾑﾗﾀﾏﾁ',
      full_lower: 'シバタグン　ムラタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '323',
    name: '柴田郡　柴田町',
    kana_name: {
      half_upper: 'ｼﾊﾞﾀｸﾞﾝ ｼﾊﾞﾀﾏﾁ',
      full_lower: 'シバタグン　シバタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '324',
    name: '柴田郡　川崎町',
    kana_name: {
      half_upper: 'ｼﾊﾞﾀｸﾞﾝ ｶﾜｻｷﾏﾁ',
      full_lower: 'シバタグン　カワサキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '340',
    name: '伊具郡',
    kana_name: { half_upper: 'ｲｸﾞｸﾞﾝ', full_lower: 'イググン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '341',
    name: '伊具郡　丸森町',
    kana_name: {
      half_upper: 'ｲｸﾞｸﾞﾝ ﾏﾙﾓﾘﾏﾁ',
      full_lower: 'イググン　マルモリマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '360',
    name: '亘理郡',
    kana_name: { half_upper: 'ﾜﾀﾘｸﾞﾝ', full_lower: 'ワタリグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '361',
    name: '亘理郡　亘理町',
    kana_name: {
      half_upper: 'ﾜﾀﾘｸﾞﾝ ﾜﾀﾘﾁﾖｳ',
      full_lower: 'ワタリグン　ワタリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '362',
    name: '亘理郡　山元町',
    kana_name: {
      half_upper: 'ﾜﾀﾘｸﾞﾝ ﾔﾏﾓﾄﾁﾖｳ',
      full_lower: 'ワタリグン　ヤマモトチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '380',
    name: '名取郡',
    kana_name: { half_upper: 'ﾅﾄﾘｸﾞﾝ', full_lower: 'ナトリグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '1988-03-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '381',
    name: '名取郡　岩沼町',
    kana_name: {
      half_upper: 'ﾅﾄﾘｸﾞﾝ ｲﾜﾇﾏﾏﾁ',
      full_lower: 'ナトリグン　イワヌママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-11-01',
    remark: { start: null, end: '04211岩沼市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '382',
    name: '名取郡　秋保町',
    kana_name: {
      half_upper: 'ﾅﾄﾘｸﾞﾝ ｱｷｳﾏﾁ',
      full_lower: 'ナトリグン　アキウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1988-03-01',
    remark: { start: null, end: '04201仙台市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '400',
    name: '宮城郡',
    kana_name: { half_upper: 'ﾐﾔｷﾞｸﾞﾝ', full_lower: 'ミヤギグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '401',
    name: '宮城郡　松島町',
    kana_name: {
      half_upper: 'ﾐﾔｷﾞｸﾞﾝ ﾏﾂｼﾏﾏﾁ',
      full_lower: 'ミヤギグン　マツシママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '402',
    name: '宮城郡　多賀城町',
    kana_name: {
      half_upper: 'ﾐﾔｷﾞｸﾞﾝ ﾀｶﾞｼﾞﾖｳﾏﾁ',
      full_lower: 'ミヤギグン　タガジョウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-11-01',
    remark: { start: null, end: '04209多賀城市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '403',
    name: '宮城郡　泉町',
    kana_name: {
      half_upper: 'ﾐﾔｷﾞｸﾞﾝ ｲｽﾞﾐﾏﾁ',
      full_lower: 'ミヤギグン　イズミマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-11-01',
    remark: { start: null, end: '04210泉市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '404',
    name: '宮城郡　七ヶ浜町',
    kana_name: {
      half_upper: 'ﾐﾔｷﾞｸﾞﾝ ｼﾁｶﾞﾊﾏﾏﾁ',
      full_lower: 'ミヤギグン　シチガハママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '405',
    name: '宮城郡　宮城町',
    kana_name: {
      half_upper: 'ﾐﾔｷﾞｸﾞﾝ ﾐﾔｷﾞﾏﾁ',
      full_lower: 'ミヤギグン　ミヤギマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1987-11-01',
    remark: { start: null, end: '04201仙台市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '406',
    name: '宮城郡　利府町',
    kana_name: {
      half_upper: 'ﾐﾔｷﾞｸﾞﾝ ﾘﾌﾁﾖｳ',
      full_lower: 'ミヤギグン　リフチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '420',
    name: '黒川郡',
    kana_name: { half_upper: 'ｸﾛｶﾜｸﾞﾝ', full_lower: 'クロカワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '421',
    name: '黒川郡　大和町',
    kana_name: {
      half_upper: 'ｸﾛｶﾜｸﾞﾝ ﾀｲﾜﾁﾖｳ',
      full_lower: 'クロカワグン　タイワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '422',
    name: '黒川郡　大郷町',
    kana_name: {
      half_upper: 'ｸﾛｶﾜｸﾞﾝ ｵｵｻﾄﾁﾖｳ',
      full_lower: 'クロカワグン　オオサトチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '423',
    name: '黒川郡　富谷町',
    kana_name: {
      half_upper: 'ｸﾛｶﾜｸﾞﾝ ﾄﾐﾔﾏﾁ',
      full_lower: 'クロカワグン　トミヤマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2016-10-10',
    remark: { start: null, end: '04216富谷市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '424',
    name: '黒川郡　大衡村',
    kana_name: {
      half_upper: 'ｸﾛｶﾜｸﾞﾝ ｵｵﾋﾗﾑﾗ',
      full_lower: 'クロカワグン　オオヒラムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '440',
    name: '加美郡',
    kana_name: { half_upper: 'ｶﾐｸﾞﾝ', full_lower: 'カミグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '441',
    name: '加美郡　中新田町',
    kana_name: {
      half_upper: 'ｶﾐｸﾞﾝ ﾅｶﾆｲﾀﾞﾏﾁ',
      full_lower: 'カミグン　ナカニイダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: '04445加美郡　加美町', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '442',
    name: '加美郡　小野田町',
    kana_name: {
      half_upper: 'ｶﾐｸﾞﾝ ｵﾉﾀﾞﾏﾁ',
      full_lower: 'カミグン　オノダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: '04445加美郡　加美町', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '443',
    name: '加美郡　宮崎町',
    kana_name: {
      half_upper: 'ｶﾐｸﾞﾝ ﾐﾔｻﾞｷﾁﾖｳ',
      full_lower: 'カミグン　ミヤザキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: '04445加美郡　加美町', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '444',
    name: '加美郡　色麻町',
    kana_name: {
      half_upper: 'ｶﾐｸﾞﾝ ｼｶﾏﾁﾖｳ',
      full_lower: 'カミグン　シカマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '445',
    name: '加美郡　加美町',
    kana_name: { half_upper: 'ｶﾐｸﾞﾝ ｶﾐﾏﾁ', full_lower: 'カミグン　カミマチ' },
    en: null,
    start_date: '2003-04-01',
    end_date: null,
    remark: { start: 'H15/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '460',
    name: '志田郡',
    kana_name: { half_upper: 'ｼﾀﾞｸﾞﾝ', full_lower: 'シダグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '461',
    name: '志田郡　松山町',
    kana_name: {
      half_upper: 'ｼﾀﾞｸﾞﾝ ﾏﾂﾔﾏﾏﾁ',
      full_lower: 'シダグン　マツヤママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '04215大崎市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '462',
    name: '志田郡　三本木町',
    kana_name: {
      half_upper: 'ｼﾀﾞｸﾞﾝ ｻﾝﾎﾞﾝｷﾞﾁﾖｳ',
      full_lower: 'シダグン　サンボンギチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '04215大崎市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '463',
    name: '志田郡　鹿島台町',
    kana_name: {
      half_upper: 'ｼﾀﾞｸﾞﾝ ｶｼﾏﾀﾞｲﾏﾁ',
      full_lower: 'シダグン　カシマダイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '04215大崎市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '480',
    name: '玉造郡',
    kana_name: { half_upper: 'ﾀﾏﾂｸﾘｸﾞﾝ', full_lower: 'タマツクリグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '481',
    name: '玉造郡　岩出山町',
    kana_name: {
      half_upper: 'ﾀﾏﾂｸﾘｸﾞﾝ ｲﾜﾃﾞﾔﾏﾏﾁ',
      full_lower: 'タマツクリグン　イワデヤママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '04215大崎市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '482',
    name: '玉造郡　鳴子町',
    kana_name: {
      half_upper: 'ﾀﾏﾂｸﾘｸﾞﾝ ﾅﾙｺﾁﾖｳ',
      full_lower: 'タマツクリグン　ナルコチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '04215大崎市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '500',
    name: '遠田郡',
    kana_name: { half_upper: 'ﾄｵﾀﾞｸﾞﾝ', full_lower: 'トオダグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '501',
    name: '遠田郡　涌谷町',
    kana_name: {
      half_upper: 'ﾄｵﾀﾞｸﾞﾝ ﾜｸﾔﾁﾖｳ',
      full_lower: 'トオダグン　ワクヤチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '502',
    name: '遠田郡　田尻町',
    kana_name: {
      half_upper: 'ﾄｵﾀﾞｸﾞﾝ ﾀｼﾞﾘﾁﾖｳ',
      full_lower: 'トオダグン　タジリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '04215大崎市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '503',
    name: '遠田郡　小牛田町',
    kana_name: {
      half_upper: 'ﾄｵﾀﾞｸﾞﾝ ｺｺﾞﾀﾁﾖｳ',
      full_lower: 'トオダグン　コゴタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '04505遠田郡　美里町', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '504',
    name: '遠田郡　南郷町',
    kana_name: {
      half_upper: 'ﾄｵﾀﾞｸﾞﾝ ﾅﾝｺﾞｳﾁﾖｳ',
      full_lower: 'トオダグン　ナンゴウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '04505遠田郡　美里町', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '505',
    name: '遠田郡　美里町',
    kana_name: {
      half_upper: 'ﾄｵﾀﾞｸﾞﾝ ﾐｻﾄﾏﾁ',
      full_lower: 'トオダグン　ミサトマチ',
    },
    en: null,
    start_date: '2006-01-01',
    end_date: null,
    remark: { start: 'H18/01/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '520',
    name: '栗原郡',
    kana_name: { half_upper: 'ｸﾘﾊﾗｸﾞﾝ', full_lower: 'クリハラグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '521',
    name: '栗原郡　築館町',
    kana_name: {
      half_upper: 'ｸﾘﾊﾗｸﾞﾝ ﾂｷﾀﾞﾃﾁﾖｳ',
      full_lower: 'クリハラグン　ツキダテチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '04213栗原市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '522',
    name: '栗原郡　若柳町',
    kana_name: {
      half_upper: 'ｸﾘﾊﾗｸﾞﾝ ﾜｶﾔﾅｷﾞﾁﾖｳ',
      full_lower: 'クリハラグン　ワカヤナギチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '04213栗原市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '523',
    name: '栗原郡　栗駒町',
    kana_name: {
      half_upper: 'ｸﾘﾊﾗｸﾞﾝ ｸﾘｺﾏﾏﾁ',
      full_lower: 'クリハラグン　クリコママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '04213栗原市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '524',
    name: '栗原郡　高清水町',
    kana_name: {
      half_upper: 'ｸﾘﾊﾗｸﾞﾝ ﾀｶｼﾐｽﾞﾏﾁ',
      full_lower: 'クリハラグン　タカシミズマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '04213栗原市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '525',
    name: '栗原郡　一迫町',
    kana_name: {
      half_upper: 'ｸﾘﾊﾗｸﾞﾝ ｲﾁﾊｻﾏﾁﾖｳ',
      full_lower: 'クリハラグン　イチハサマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '04213栗原市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '526',
    name: '栗原郡　瀬峰町',
    kana_name: {
      half_upper: 'ｸﾘﾊﾗｸﾞﾝ ｾﾐﾈﾁﾖｳ',
      full_lower: 'クリハラグン　セミネチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '04213栗原市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '527',
    name: '栗原郡　鶯沢町',
    kana_name: {
      half_upper: 'ｸﾘﾊﾗｸﾞﾝ ｳｸﾞｲｽｻﾞﾜﾁﾖｳ',
      full_lower: 'クリハラグン　ウグイスザワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '04213栗原市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '528',
    name: '栗原郡　金成町',
    kana_name: {
      half_upper: 'ｸﾘﾊﾗｸﾞﾝ ｶﾝﾅﾘﾁﾖｳ',
      full_lower: 'クリハラグン　カンナリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '04213栗原市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '529',
    name: '栗原郡　志波姫町',
    kana_name: {
      half_upper: 'ｸﾘﾊﾗｸﾞﾝ ｼﾜﾋﾒﾁﾖｳ',
      full_lower: 'クリハラグン　シワヒメチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '04213栗原市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '530',
    name: '栗原郡　花山村',
    kana_name: {
      half_upper: 'ｸﾘﾊﾗｸﾞﾝ ﾊﾅﾔﾏﾑﾗ',
      full_lower: 'クリハラグン　ハナヤマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '04213栗原市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '540',
    name: '登米郡',
    kana_name: { half_upper: 'ﾄﾒｸﾞﾝ', full_lower: 'トメグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '541',
    name: '登米郡　迫町',
    kana_name: {
      half_upper: 'ﾄﾒｸﾞﾝ ﾊｻﾏﾁﾖｳ',
      full_lower: 'トメグン　ハサマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '04212登米市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '542',
    name: '登米郡　登米町',
    kana_name: {
      half_upper: 'ﾄﾒｸﾞﾝ ﾄﾖﾏﾏﾁ',
      full_lower: 'トメグン　トヨママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '04212登米市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '543',
    name: '登米郡　東和町',
    kana_name: {
      half_upper: 'ﾄﾒｸﾞﾝ ﾄｳﾜﾁﾖｳ',
      full_lower: 'トメグン　トウワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '04212登米市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '544',
    name: '登米郡　中田町',
    kana_name: {
      half_upper: 'ﾄﾒｸﾞﾝ ﾅｶﾀﾞﾁﾖｳ',
      full_lower: 'トメグン　ナカダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '04212登米市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '545',
    name: '登米郡　豊里町',
    kana_name: {
      half_upper: 'ﾄﾒｸﾞﾝ ﾄﾖｻﾄﾁﾖｳ',
      full_lower: 'トメグン　トヨサトチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '04212登米市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '546',
    name: '登米郡　米山町',
    kana_name: {
      half_upper: 'ﾄﾒｸﾞﾝ ﾖﾈﾔﾏﾁﾖｳ',
      full_lower: 'トメグン　ヨネヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '04212登米市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '547',
    name: '登米郡　石越町',
    kana_name: {
      half_upper: 'ﾄﾒｸﾞﾝ ｲｼｺｼﾏﾁ',
      full_lower: 'トメグン　イシコシマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '04212登米市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '548',
    name: '登米郡　南方町',
    kana_name: {
      half_upper: 'ﾄﾒｸﾞﾝ ﾐﾅﾐｶﾀﾏﾁ',
      full_lower: 'トメグン　ミナミカタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '04212登米市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '560',
    name: '桃生郡',
    kana_name: { half_upper: 'ﾓﾉｳｸﾞﾝ', full_lower: 'モノウグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '561',
    name: '桃生郡　河北町',
    kana_name: {
      half_upper: 'ﾓﾉｳｸﾞﾝ ｶﾎｸﾁﾖｳ',
      full_lower: 'モノウグン　カホクチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '04202石巻市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '562',
    name: '桃生郡　矢本町',
    kana_name: {
      half_upper: 'ﾓﾉｳｸﾞﾝ ﾔﾓﾄﾁﾖｳ',
      full_lower: 'モノウグン　ヤモトチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '04214東松島市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '563',
    name: '桃生郡　雄勝町',
    kana_name: {
      half_upper: 'ﾓﾉｳｸﾞﾝ ｵｶﾞﾂﾁﾖｳ',
      full_lower: 'モノウグン　オガツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '04202石巻市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '564',
    name: '桃生郡　河南町',
    kana_name: {
      half_upper: 'ﾓﾉｳｸﾞﾝ ｶﾅﾝﾁﾖｳ',
      full_lower: 'モノウグン　カナンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '04202石巻市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '565',
    name: '桃生郡　桃生町',
    kana_name: {
      half_upper: 'ﾓﾉｳｸﾞﾝ ﾓﾉｳﾁﾖｳ',
      full_lower: 'モノウグン　モノウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '04202石巻市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '566',
    name: '桃生郡　鳴瀬町',
    kana_name: {
      half_upper: 'ﾓﾉｳｸﾞﾝ ﾅﾙｾﾁﾖｳ',
      full_lower: 'モノウグン　ナルセチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '04214東松島市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '567',
    name: '桃生郡　北上町',
    kana_name: {
      half_upper: 'ﾓﾉｳｸﾞﾝ ｷﾀｶﾐﾏﾁ',
      full_lower: 'モノウグン　キタカミマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '04202石巻市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '580',
    name: '牡鹿郡',
    kana_name: { half_upper: 'ｵｼｶｸﾞﾝ', full_lower: 'オシカグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '581',
    name: '牡鹿郡　女川町',
    kana_name: {
      half_upper: 'ｵｼｶｸﾞﾝ ｵﾅｶﾞﾜﾁﾖｳ',
      full_lower: 'オシカグン　オナガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '582',
    name: '牡鹿郡　牡鹿町',
    kana_name: {
      half_upper: 'ｵｼｶｸﾞﾝ ｵｼｶﾁﾖｳ',
      full_lower: 'オシカグン　オシカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '04202石巻市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '600',
    name: '本吉郡',
    kana_name: { half_upper: 'ﾓﾄﾖｼｸﾞﾝ', full_lower: 'モトヨシグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '601',
    name: '本吉郡　志津川町',
    kana_name: {
      half_upper: 'ﾓﾄﾖｼｸﾞﾝ ｼﾂﾞｶﾞﾜﾁﾖｳ',
      full_lower: 'モトヨシグン　シヅガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '04606本吉郡　南三陸町', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '602',
    name: '本吉郡　津山町',
    kana_name: {
      half_upper: 'ﾓﾄﾖｼｸﾞﾝ ﾂﾔﾏﾁﾖｳ',
      full_lower: 'モトヨシグン　ツヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '04212登米市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '603',
    name: '本吉郡　本吉町',
    kana_name: {
      half_upper: 'ﾓﾄﾖｼｸﾞﾝ ﾓﾄﾖｼﾁﾖｳ',
      full_lower: 'モトヨシグン　モトヨシチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2009-09-01',
    remark: { start: null, end: '04205気仙沼市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '604',
    name: '本吉郡　唐桑町',
    kana_name: {
      half_upper: 'ﾓﾄﾖｼｸﾞﾝ ｶﾗｸﾜﾁﾖｳ',
      full_lower: 'モトヨシグン　カラクワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '04205気仙沼市', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '605',
    name: '本吉郡　歌津町',
    kana_name: {
      half_upper: 'ﾓﾄﾖｼｸﾞﾝ ｳﾀﾂﾁﾖｳ',
      full_lower: 'モトヨシグン　ウタツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '04606本吉郡　南三陸町', other: null },
    note: null,
  },
  {
    pref_code: '04',
    city_code: '606',
    name: '本吉郡　南三陸町',
    kana_name: {
      half_upper: 'ﾓﾄﾖｼｸﾞﾝ ﾐﾅﾐｻﾝﾘｸﾁﾖｳ',
      full_lower: 'モトヨシグン　ミナミサンリクチョウ',
    },
    en: null,
    start_date: '2005-10-01',
    end_date: null,
    remark: { start: 'H17/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '201',
    name: '秋田市',
    kana_name: { half_upper: 'ｱｷﾀｼ', full_lower: 'アキタシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '202',
    name: '能代市',
    kana_name: { half_upper: 'ﾉｼﾛｼ', full_lower: 'ノシロシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '203',
    name: '横手市',
    kana_name: { half_upper: 'ﾖｺﾃｼ', full_lower: 'ヨコテシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '204',
    name: '大館市',
    kana_name: { half_upper: 'ｵｵﾀﾞﾃｼ', full_lower: 'オオダテシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '205',
    name: '本荘市',
    kana_name: { half_upper: 'ﾎﾝｼﾞﾖｳｼ', full_lower: 'ホンジョウシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '05210由利本荘市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '206',
    name: '男鹿市',
    kana_name: { half_upper: 'ｵｶﾞｼ', full_lower: 'オガシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '207',
    name: '湯沢市',
    kana_name: { half_upper: 'ﾕｻﾞﾜｼ', full_lower: 'ユザワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '208',
    name: '大曲市',
    kana_name: { half_upper: 'ｵｵﾏｶﾞﾘｼ', full_lower: 'オオマガリシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '05212大仙市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '209',
    name: '鹿角市',
    kana_name: { half_upper: 'ｶﾂﾞﾉｼ', full_lower: 'カヅノシ' },
    en: null,
    start_date: '1972-04-01',
    end_date: null,
    remark: { start: 'S47/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '210',
    name: '由利本荘市',
    kana_name: { half_upper: 'ﾕﾘﾎﾝｼﾞﾖｳｼ', full_lower: 'ユリホンジョウシ' },
    en: null,
    start_date: '2005-03-22',
    end_date: null,
    remark: { start: 'H17/03/22新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '211',
    name: '潟上市',
    kana_name: { half_upper: 'ｶﾀｶﾞﾐｼ', full_lower: 'カタガミシ' },
    en: null,
    start_date: '2005-03-22',
    end_date: null,
    remark: { start: 'H17/03/22新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '212',
    name: '大仙市',
    kana_name: { half_upper: 'ﾀﾞｲｾﾝｼ', full_lower: 'ダイセンシ' },
    en: null,
    start_date: '2005-03-22',
    end_date: null,
    remark: { start: 'H17/03/22新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '213',
    name: '北秋田市',
    kana_name: { half_upper: 'ｷﾀｱｷﾀｼ', full_lower: 'キタアキタシ' },
    en: null,
    start_date: '2005-03-22',
    end_date: null,
    remark: { start: 'H17/03/22新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '214',
    name: 'にかほ市',
    kana_name: { half_upper: 'ﾆｶﾎｼ', full_lower: 'ニカホシ' },
    en: null,
    start_date: '2005-10-01',
    end_date: null,
    remark: { start: 'H17/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '215',
    name: '仙北市',
    kana_name: { half_upper: 'ｾﾝﾎﾞｸｼ', full_lower: 'センボクシ' },
    en: null,
    start_date: '2005-09-20',
    end_date: null,
    remark: { start: 'H17/09/20新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '300',
    name: '鹿角郡',
    kana_name: { half_upper: 'ｶﾂﾞﾉｸﾞﾝ', full_lower: 'カヅノグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '301',
    name: '鹿角郡　花輪町',
    kana_name: {
      half_upper: 'ｶﾂﾞﾉｸﾞﾝ ﾊﾅﾜﾏﾁ',
      full_lower: 'カヅノグン　ハナワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-04-01',
    remark: { start: null, end: '05209鹿角市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '302',
    name: '鹿角郡　十和田町',
    kana_name: {
      half_upper: 'ｶﾂﾞﾉｸﾞﾝ ﾄﾜﾀﾞﾏﾁ',
      full_lower: 'カヅノグン　トワダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-04-01',
    remark: { start: null, end: '05209鹿角市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '303',
    name: '鹿角郡　小坂町',
    kana_name: {
      half_upper: 'ｶﾂﾞﾉｸﾞﾝ ｺｻｶﾏﾁ',
      full_lower: 'カヅノグン　コサカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '304',
    name: '鹿角郡　尾去沢町',
    kana_name: {
      half_upper: 'ｶﾂﾞﾉｸﾞﾝ ｵｻﾘｻﾞﾜﾏﾁ',
      full_lower: 'カヅノグン　オサリザワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-04-01',
    remark: { start: null, end: '05209鹿角市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '305',
    name: '鹿角郡　八幡平村',
    kana_name: {
      half_upper: 'ｶﾂﾞﾉｸﾞﾝ ﾊﾁﾏﾝﾀｲﾑﾗ',
      full_lower: 'カヅノグン　ハチマンタイムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-04-01',
    remark: { start: null, end: '05209鹿角市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '320',
    name: '北秋田郡',
    kana_name: { half_upper: 'ｷﾀｱｷﾀｸﾞﾝ', full_lower: 'キタアキタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '321',
    name: '北秋田郡　鷹巣町',
    kana_name: {
      half_upper: 'ｷﾀｱｷﾀｸﾞﾝ ﾀｶﾉｽﾏﾁ',
      full_lower: 'キタアキタグン　タカノスマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '05213北秋田市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '322',
    name: '北秋田郡　比内町',
    kana_name: {
      half_upper: 'ｷﾀｱｷﾀｸﾞﾝ ﾋﾅｲﾏﾁ',
      full_lower: 'キタアキタグン　ヒナイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-06-20',
    remark: { start: null, end: '05204大館市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '323',
    name: '北秋田郡　森吉町',
    kana_name: {
      half_upper: 'ｷﾀｱｷﾀｸﾞﾝ ﾓﾘﾖｼﾏﾁ',
      full_lower: 'キタアキタグン　モリヨシマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '05213北秋田市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '324',
    name: '北秋田郡　阿仁町',
    kana_name: {
      half_upper: 'ｷﾀｱｷﾀｸﾞﾝ ｱﾆﾏﾁ',
      full_lower: 'キタアキタグン　アニマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '05213北秋田市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '325',
    name: '北秋田郡　田代町',
    kana_name: {
      half_upper: 'ｷﾀｱｷﾀｸﾞﾝ ﾀｼﾛﾏﾁ',
      full_lower: 'キタアキタグン　タシロマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-06-20',
    remark: { start: null, end: '05204大館市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '326',
    name: '北秋田郡　合川町',
    kana_name: {
      half_upper: 'ｷﾀｱｷﾀｸﾞﾝ ｱｲｶﾜﾏﾁ',
      full_lower: 'キタアキタグン　アイカワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '05213北秋田市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '327',
    name: '北秋田郡　上小阿仁村',
    kana_name: {
      half_upper: 'ｷﾀｱｷﾀｸﾞﾝ ｶﾐｺｱﾆﾑﾗ',
      full_lower: 'キタアキタグン　カミコアニムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '340',
    name: '山本郡',
    kana_name: { half_upper: 'ﾔﾏﾓﾄｸﾞﾝ', full_lower: 'ヤマモトグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '341',
    name: '山本郡　琴丘町',
    kana_name: {
      half_upper: 'ﾔﾏﾓﾄｸﾞﾝ ｺﾄｵｶﾏﾁ',
      full_lower: 'ヤマモトグン　コトオカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '05348山本郡　三種町', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '342',
    name: '山本郡　二ツ井町',
    kana_name: {
      half_upper: 'ﾔﾏﾓﾄｸﾞﾝ ﾌﾀﾂｲﾏﾁ',
      full_lower: 'ヤマモトグン　フタツイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-21',
    remark: { start: null, end: '05202能代市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '343',
    name: '山本郡　八森町',
    kana_name: {
      half_upper: 'ﾔﾏﾓﾄｸﾞﾝ ﾊﾁﾓﾘﾏﾁ',
      full_lower: 'ヤマモトグン　ハチモリマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '05349山本郡　八峰町', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '344',
    name: '山本郡　山本町',
    kana_name: {
      half_upper: 'ﾔﾏﾓﾄｸﾞﾝ ﾔﾏﾓﾄﾏﾁ',
      full_lower: 'ヤマモトグン　ヤマモトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '05348山本郡　三種町', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '345',
    name: '山本郡　八竜町',
    kana_name: {
      half_upper: 'ﾔﾏﾓﾄｸﾞﾝ ﾊﾁﾘﾕｳﾏﾁ',
      full_lower: 'ヤマモトグン　ハチリュウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '05348山本郡　三種町', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '346',
    name: '山本郡　藤里町',
    kana_name: {
      half_upper: 'ﾔﾏﾓﾄｸﾞﾝ ﾌｼﾞｻﾄﾏﾁ',
      full_lower: 'ヤマモトグン　フジサトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '347',
    name: '山本郡　峰浜村',
    kana_name: {
      half_upper: 'ﾔﾏﾓﾄｸﾞﾝ ﾐﾈﾊﾏﾑﾗ',
      full_lower: 'ヤマモトグン　ミネハマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '05349山本郡　八峰町', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '348',
    name: '山本郡　三種町',
    kana_name: {
      half_upper: 'ﾔﾏﾓﾄｸﾞﾝ ﾐﾀﾈﾁﾖｳ',
      full_lower: 'ヤマモトグン　ミタネチョウ',
    },
    en: null,
    start_date: '2006-03-20',
    end_date: null,
    remark: { start: 'H18/03/20新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '349',
    name: '山本郡　八峰町',
    kana_name: {
      half_upper: 'ﾔﾏﾓﾄｸﾞﾝ ﾊﾂﾎﾟｳﾁﾖｳ',
      full_lower: 'ヤマモトグン　ハッポウチョウ',
    },
    en: null,
    start_date: '2006-03-27',
    end_date: null,
    remark: { start: 'H18/03/27新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '360',
    name: '南秋田郡',
    kana_name: { half_upper: 'ﾐﾅﾐｱｷﾀｸﾞﾝ', full_lower: 'ミナミアキタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '361',
    name: '南秋田郡　五城目町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱｷﾀｸﾞﾝ ｺﾞｼﾞﾖｳﾒﾏﾁ',
      full_lower: 'ミナミアキタグン　ゴジョウメマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '362',
    name: '南秋田郡　昭和町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱｷﾀｸﾞﾝ ｼﾖｳﾜﾏﾁ',
      full_lower: 'ミナミアキタグン　ショウワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '05211潟上市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '363',
    name: '南秋田郡　八郎潟町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱｷﾀｸﾞﾝ ﾊﾁﾛｳｶﾞﾀﾏﾁ',
      full_lower: 'ミナミアキタグン　ハチロウガタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '364',
    name: '南秋田郡　飯田川町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱｷﾀｸﾞﾝ ｲｲﾀｶﾞﾜﾏﾁ',
      full_lower: 'ミナミアキタグン　イイタガワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '05211潟上市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '365',
    name: '南秋田郡　天王町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱｷﾀｸﾞﾝ ﾃﾝﾉｳﾏﾁ',
      full_lower: 'ミナミアキタグン　テンノウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '05211潟上市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '366',
    name: '南秋田郡　井川町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱｷﾀｸﾞﾝ ｲｶﾜﾏﾁ',
      full_lower: 'ミナミアキタグン　イカワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '367',
    name: '南秋田郡　若美町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱｷﾀｸﾞﾝ ﾜｶﾐﾏﾁ',
      full_lower: 'ミナミアキタグン　ワカミマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '05206男鹿市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '368',
    name: '南秋田郡　大潟村',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱｷﾀｸﾞﾝ ｵｵｶﾞﾀﾑﾗ',
      full_lower: 'ミナミアキタグン　オオガタムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '380',
    name: '河辺郡',
    kana_name: { half_upper: 'ｶﾜﾍﾞｸﾞﾝ', full_lower: 'カワベグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-11',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '381',
    name: '河辺郡　河辺町',
    kana_name: {
      half_upper: 'ｶﾜﾍﾞｸﾞﾝ ｶﾜﾍﾞﾏﾁ',
      full_lower: 'カワベグン　カワベマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-11',
    remark: { start: null, end: '05201秋田市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '382',
    name: '河辺郡　雄和町',
    kana_name: {
      half_upper: 'ｶﾜﾍﾞｸﾞﾝ ﾕｳﾜﾏﾁ',
      full_lower: 'カワベグン　ユウワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-11',
    remark: { start: null, end: '05201秋田市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '400',
    name: '由利郡',
    kana_name: { half_upper: 'ﾕﾘｸﾞﾝ', full_lower: 'ユリグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '401',
    name: '由利郡　仁賀保町',
    kana_name: {
      half_upper: 'ﾕﾘｸﾞﾝ ﾆｶﾎﾏﾁ',
      full_lower: 'ユリグン　ニカホマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '05214にかほ市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '402',
    name: '由利郡　金浦町',
    kana_name: {
      half_upper: 'ﾕﾘｸﾞﾝ ｺﾉｳﾗﾏﾁ',
      full_lower: 'ユリグン　コノウラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '05214にかほ市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '403',
    name: '由利郡　象潟町',
    kana_name: {
      half_upper: 'ﾕﾘｸﾞﾝ ｷｻｶﾀﾏﾁ',
      full_lower: 'ユリグン　キサカタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '05214にかほ市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '404',
    name: '由利郡　矢島町',
    kana_name: {
      half_upper: 'ﾕﾘｸﾞﾝ ﾔｼﾏﾏﾁ',
      full_lower: 'ユリグン　ヤシママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '05210由利本荘市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '405',
    name: '由利郡　岩城町',
    kana_name: {
      half_upper: 'ﾕﾘｸﾞﾝ ｲﾜｷﾏﾁ',
      full_lower: 'ユリグン　イワキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '05210由利本荘市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '406',
    name: '由利郡　由利町',
    kana_name: { half_upper: 'ﾕﾘｸﾞﾝ ﾕﾘﾏﾁ', full_lower: 'ユリグン　ユリマチ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '05210由利本荘市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '407',
    name: '由利郡　西目町',
    kana_name: {
      half_upper: 'ﾕﾘｸﾞﾝ ﾆｼﾒﾏﾁ',
      full_lower: 'ユリグン　ニシメマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '05210由利本荘市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '408',
    name: '由利郡　鳥海町',
    kana_name: {
      half_upper: 'ﾕﾘｸﾞﾝ ﾁﾖｳｶｲﾏﾁ',
      full_lower: 'ユリグン　チョウカイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '05210由利本荘市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '409',
    name: '由利郡　東由利町',
    kana_name: {
      half_upper: 'ﾕﾘｸﾞﾝ ﾋｶﾞｼﾕﾘﾏﾁ',
      full_lower: 'ユリグン　ヒガシユリマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '05210由利本荘市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '410',
    name: '由利郡　大内町',
    kana_name: {
      half_upper: 'ﾕﾘｸﾞﾝ ｵｵｳﾁﾏﾁ',
      full_lower: 'ユリグン　オオウチマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '05210由利本荘市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '420',
    name: '仙北郡',
    kana_name: { half_upper: 'ｾﾝﾎﾞｸｸﾞﾝ', full_lower: 'センボクグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '421',
    name: '仙北郡　神岡町',
    kana_name: {
      half_upper: 'ｾﾝﾎﾞｸｸﾞﾝ ｶﾐｵｶﾏﾁ',
      full_lower: 'センボクグン　カミオカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '05212大仙市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '422',
    name: '仙北郡　西仙北町',
    kana_name: {
      half_upper: 'ｾﾝﾎﾞｸｸﾞﾝ ﾆｼｾﾝﾎﾞｸﾏﾁ',
      full_lower: 'センボクグン　ニシセンボクマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '05212大仙市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '423',
    name: '仙北郡　角館町',
    kana_name: {
      half_upper: 'ｾﾝﾎﾞｸｸﾞﾝ ｶｸﾉﾀﾞﾃﾏﾁ',
      full_lower: 'センボクグン　カクノダテマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-20',
    remark: { start: null, end: '05215仙北市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '424',
    name: '仙北郡　六郷町',
    kana_name: {
      half_upper: 'ｾﾝﾎﾞｸｸﾞﾝ ﾛｸｺﾞｳﾏﾁ',
      full_lower: 'センボクグン　ロクゴウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '05434仙北郡　美郷町', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '425',
    name: '仙北郡　中仙町',
    kana_name: {
      half_upper: 'ｾﾝﾎﾞｸｸﾞﾝ ﾅｶｾﾝﾏﾁ',
      full_lower: 'センボクグン　ナカセンマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '05212大仙市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '426',
    name: '仙北郡　田沢湖町',
    kana_name: {
      half_upper: 'ｾﾝﾎﾞｸｸﾞﾝ ﾀｻﾞﾜｺﾏﾁ',
      full_lower: 'センボクグン　タザワコマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-20',
    remark: { start: null, end: '05215仙北市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '427',
    name: '仙北郡　協和町',
    kana_name: {
      half_upper: 'ｾﾝﾎﾞｸｸﾞﾝ ｷﾖｳﾜﾏﾁ',
      full_lower: 'センボクグン　キョウワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '05212大仙市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '428',
    name: '仙北郡　南外村',
    kana_name: {
      half_upper: 'ｾﾝﾎﾞｸｸﾞﾝ ﾅﾝｶﾞｲﾑﾗ',
      full_lower: 'センボクグン　ナンガイムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '05212大仙市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '429',
    name: '仙北郡　仙北町',
    kana_name: {
      half_upper: 'ｾﾝﾎﾞｸｸﾞﾝ ｾﾝﾎﾞｸﾏﾁ',
      full_lower: 'センボクグン　センボクマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '05212大仙市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '430',
    name: '仙北郡　西木村',
    kana_name: {
      half_upper: 'ｾﾝﾎﾞｸｸﾞﾝ ﾆｼｷﾑﾗ',
      full_lower: 'センボクグン　ニシキムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-20',
    remark: { start: null, end: '05215仙北市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '431',
    name: '仙北郡　太田町',
    kana_name: {
      half_upper: 'ｾﾝﾎﾞｸｸﾞﾝ ｵｵﾀﾏﾁ',
      full_lower: 'センボクグン　オオタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '05212大仙市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '432',
    name: '仙北郡　千畑町',
    kana_name: {
      half_upper: 'ｾﾝﾎﾞｸｸﾞﾝ ｾﾝﾊﾀﾏﾁ',
      full_lower: 'センボクグン　センハタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '05434仙北郡　美郷町', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '433',
    name: '仙北郡　仙南村',
    kana_name: {
      half_upper: 'ｾﾝﾎﾞｸｸﾞﾝ ｾﾝﾅﾝﾑﾗ',
      full_lower: 'センボクグン　センナンムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '05434仙北郡　美郷町', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '434',
    name: '仙北郡　美郷町',
    kana_name: {
      half_upper: 'ｾﾝﾎﾞｸｸﾞﾝ ﾐｻﾄﾁﾖｳ',
      full_lower: 'センボクグン　ミサトチョウ',
    },
    en: null,
    start_date: '2004-11-01',
    end_date: null,
    remark: { start: 'H16/11/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '440',
    name: '平鹿郡',
    kana_name: { half_upper: 'ﾋﾗｶｸﾞﾝ', full_lower: 'ヒラカグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '441',
    name: '平鹿郡　増田町',
    kana_name: {
      half_upper: 'ﾋﾗｶｸﾞﾝ ﾏｽﾀﾞﾏﾁ',
      full_lower: 'ヒラカグン　マスダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '05203横手市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '442',
    name: '平鹿郡　平鹿町',
    kana_name: {
      half_upper: 'ﾋﾗｶｸﾞﾝ ﾋﾗｶﾏﾁ',
      full_lower: 'ヒラカグン　ヒラカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '05203横手市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '443',
    name: '平鹿郡　雄物川町',
    kana_name: {
      half_upper: 'ﾋﾗｶｸﾞﾝ ｵﾓﾉｶﾞﾜﾏﾁ',
      full_lower: 'ヒラカグン　オモノガワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '05203横手市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '444',
    name: '平鹿郡　大森町',
    kana_name: {
      half_upper: 'ﾋﾗｶｸﾞﾝ ｵｵﾓﾘﾏﾁ',
      full_lower: 'ヒラカグン　オオモリマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '05203横手市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '445',
    name: '平鹿郡　十文字町',
    kana_name: {
      half_upper: 'ﾋﾗｶｸﾞﾝ ｼﾞﾕｳﾓﾝｼﾞﾏﾁ',
      full_lower: 'ヒラカグン　ジュウモンジマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '05203横手市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '446',
    name: '平鹿郡　山内村',
    kana_name: {
      half_upper: 'ﾋﾗｶｸﾞﾝ ｻﾝﾅｲﾑﾗ',
      full_lower: 'ヒラカグン　サンナイムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '05203横手市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '447',
    name: '平鹿郡　大雄村',
    kana_name: {
      half_upper: 'ﾋﾗｶｸﾞﾝ ﾀｲﾕｳﾑﾗ',
      full_lower: 'ヒラカグン　タイユウムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '05203横手市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '460',
    name: '雄勝郡',
    kana_name: { half_upper: 'ｵｶﾞﾁｸﾞﾝ', full_lower: 'オガチグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '461',
    name: '雄勝郡　稲川町',
    kana_name: {
      half_upper: 'ｵｶﾞﾁｸﾞﾝ ｲﾅｶﾜﾏﾁ',
      full_lower: 'オガチグン　イナカワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '05207湯沢市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '462',
    name: '雄勝郡　雄勝町',
    kana_name: {
      half_upper: 'ｵｶﾞﾁｸﾞﾝ ｵｶﾞﾁﾏﾁ',
      full_lower: 'オガチグン　オガチマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '05207湯沢市', other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '463',
    name: '雄勝郡　羽後町',
    kana_name: {
      half_upper: 'ｵｶﾞﾁｸﾞﾝ ｳｺﾞﾏﾁ',
      full_lower: 'オガチグン　ウゴマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '464',
    name: '雄勝郡　東成瀬村',
    kana_name: {
      half_upper: 'ｵｶﾞﾁｸﾞﾝ ﾋｶﾞｼﾅﾙｾﾑﾗ',
      full_lower: 'オガチグン　ヒガシナルセムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '05',
    city_code: '465',
    name: '雄勝郡　皆瀬村',
    kana_name: {
      half_upper: 'ｵｶﾞﾁｸﾞﾝ ﾐﾅｾﾑﾗ',
      full_lower: 'オガチグン　ミナセムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '05207湯沢市', other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '201',
    name: '山形市',
    kana_name: { half_upper: 'ﾔﾏｶﾞﾀｼ', full_lower: 'ヤマガタシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '202',
    name: '米沢市',
    kana_name: { half_upper: 'ﾖﾈｻﾞﾜｼ', full_lower: 'ヨネザワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '203',
    name: '鶴岡市',
    kana_name: { half_upper: 'ﾂﾙｵｶｼ', full_lower: 'ツルオカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '204',
    name: '酒田市',
    kana_name: { half_upper: 'ｻｶﾀｼ', full_lower: 'サカタシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '205',
    name: '新庄市',
    kana_name: { half_upper: 'ｼﾝｼﾞﾖｳｼ', full_lower: 'シンジョウシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '206',
    name: '寒河江市',
    kana_name: { half_upper: 'ｻｶﾞｴｼ', full_lower: 'サガエシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '207',
    name: '上山市',
    kana_name: { half_upper: 'ｶﾐﾉﾔﾏｼ', full_lower: 'カミノヤマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '208',
    name: '村山市',
    kana_name: { half_upper: 'ﾑﾗﾔﾏｼ', full_lower: 'ムラヤマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '209',
    name: '長井市',
    kana_name: { half_upper: 'ﾅｶﾞｲｼ', full_lower: 'ナガイシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '210',
    name: '天童市',
    kana_name: { half_upper: 'ﾃﾝﾄﾞｳｼ', full_lower: 'テンドウシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '211',
    name: '東根市',
    kana_name: { half_upper: 'ﾋｶﾞｼﾈｼ', full_lower: 'ヒガシネシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '212',
    name: '尾花沢市',
    kana_name: { half_upper: 'ｵﾊﾞﾅｻﾞﾜｼ', full_lower: 'オバナザワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '213',
    name: '南陽市',
    kana_name: { half_upper: 'ﾅﾝﾖｳｼ', full_lower: 'ナンヨウシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '300',
    name: '東村山郡',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾑﾗﾔﾏｸﾞﾝ',
      full_lower: 'ヒガシムラヤマグン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '301',
    name: '東村山郡　山辺町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾑﾗﾔﾏｸﾞﾝ ﾔﾏﾉﾍﾞﾏﾁ',
      full_lower: 'ヒガシムラヤマグン　ヤマノベマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '302',
    name: '東村山郡　中山町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾑﾗﾔﾏｸﾞﾝ ﾅｶﾔﾏﾏﾁ',
      full_lower: 'ヒガシムラヤマグン　ナカヤママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '320',
    name: '西村山郡',
    kana_name: { half_upper: 'ﾆｼﾑﾗﾔﾏｸﾞﾝ', full_lower: 'ニシムラヤマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '321',
    name: '西村山郡　河北町',
    kana_name: {
      half_upper: 'ﾆｼﾑﾗﾔﾏｸﾞﾝ ｶﾎｸﾁﾖｳ',
      full_lower: 'ニシムラヤマグン　カホクチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '322',
    name: '西村山郡　西川町',
    kana_name: {
      half_upper: 'ﾆｼﾑﾗﾔﾏｸﾞﾝ ﾆｼｶﾜﾏﾁ',
      full_lower: 'ニシムラヤマグン　ニシカワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '323',
    name: '西村山郡　朝日町',
    kana_name: {
      half_upper: 'ﾆｼﾑﾗﾔﾏｸﾞﾝ ｱｻﾋﾏﾁ',
      full_lower: 'ニシムラヤマグン　アサヒマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '324',
    name: '西村山郡　大江町',
    kana_name: {
      half_upper: 'ﾆｼﾑﾗﾔﾏｸﾞﾝ ｵｵｴﾏﾁ',
      full_lower: 'ニシムラヤマグン　オオエマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '340',
    name: '北村山郡',
    kana_name: { half_upper: 'ｷﾀﾑﾗﾔﾏｸﾞﾝ', full_lower: 'キタムラヤマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '341',
    name: '北村山郡　大石田町',
    kana_name: {
      half_upper: 'ｷﾀﾑﾗﾔﾏｸﾞﾝ ｵｵｲｼﾀﾞﾏﾁ',
      full_lower: 'キタムラヤマグン　オオイシダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '360',
    name: '最上郡',
    kana_name: { half_upper: 'ﾓｶﾞﾐｸﾞﾝ', full_lower: 'モガミグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '361',
    name: '最上郡　金山町',
    kana_name: {
      half_upper: 'ﾓｶﾞﾐｸﾞﾝ ｶﾈﾔﾏﾏﾁ',
      full_lower: 'モガミグン　カネヤママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '362',
    name: '最上郡　最上町',
    kana_name: {
      half_upper: 'ﾓｶﾞﾐｸﾞﾝ ﾓｶﾞﾐﾏﾁ',
      full_lower: 'モガミグン　モガミマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '363',
    name: '最上郡　舟形町',
    kana_name: {
      half_upper: 'ﾓｶﾞﾐｸﾞﾝ ﾌﾅｶﾞﾀﾏﾁ',
      full_lower: 'モガミグン　フナガタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '364',
    name: '最上郡　真室川町',
    kana_name: {
      half_upper: 'ﾓｶﾞﾐｸﾞﾝ ﾏﾑﾛｶﾞﾜﾏﾁ',
      full_lower: 'モガミグン　マムロガワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '365',
    name: '最上郡　大蔵村',
    kana_name: {
      half_upper: 'ﾓｶﾞﾐｸﾞﾝ ｵｵｸﾗﾑﾗ',
      full_lower: 'モガミグン　オオクラムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '366',
    name: '最上郡　鮭川村',
    kana_name: {
      half_upper: 'ﾓｶﾞﾐｸﾞﾝ ｻｹｶﾞﾜﾑﾗ',
      full_lower: 'モガミグン　サケガワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '367',
    name: '最上郡　戸沢村',
    kana_name: {
      half_upper: 'ﾓｶﾞﾐｸﾞﾝ ﾄｻﾞﾜﾑﾗ',
      full_lower: 'モガミグン　トザワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '380',
    name: '東置賜郡',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｵｷﾀﾏｸﾞﾝ',
      full_lower: 'ヒガシオキタマグン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '381',
    name: '東置賜郡　高畠町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｵｷﾀﾏｸﾞﾝ ﾀｶﾊﾀﾏﾁ',
      full_lower: 'ヒガシオキタマグン　タカハタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '382',
    name: '東置賜郡　川西町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｵｷﾀﾏｸﾞﾝ ｶﾜﾆｼﾏﾁ',
      full_lower: 'ヒガシオキタマグン　カワニシマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '400',
    name: '西置賜郡',
    kana_name: { half_upper: 'ﾆｼｵｷﾀﾏｸﾞﾝ', full_lower: 'ニシオキタマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '401',
    name: '西置賜郡　小国町',
    kana_name: {
      half_upper: 'ﾆｼｵｷﾀﾏｸﾞﾝ ｵｸﾞﾆﾏﾁ',
      full_lower: 'ニシオキタマグン　オグニマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '402',
    name: '西置賜郡　白鷹町',
    kana_name: {
      half_upper: 'ﾆｼｵｷﾀﾏｸﾞﾝ ｼﾗﾀｶﾏﾁ',
      full_lower: 'ニシオキタマグン　シラタカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '403',
    name: '西置賜郡　飯豊町',
    kana_name: {
      half_upper: 'ﾆｼｵｷﾀﾏｸﾞﾝ ｲｲﾃﾞﾏﾁ',
      full_lower: 'ニシオキタマグン　イイデマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '420',
    name: '東田川郡',
    kana_name: { half_upper: 'ﾋｶﾞｼﾀｶﾞﾜｸﾞﾝ', full_lower: 'ヒガシタガワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '421',
    name: '東田川郡　立川町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾀｶﾞﾜｸﾞﾝ ﾀﾁｶﾜﾏﾁ',
      full_lower: 'ヒガシタガワグン　タチカワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-07-01',
    remark: { start: null, end: '06428東田川郡　庄内町', other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '422',
    name: '東田川郡　余目町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾀｶﾞﾜｸﾞﾝ ｱﾏﾙﾒﾏﾁ',
      full_lower: 'ヒガシタガワグン　アマルメマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-07-01',
    remark: { start: null, end: '06428東田川郡　庄内町', other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '423',
    name: '東田川郡　藤島町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾀｶﾞﾜｸﾞﾝ ﾌｼﾞｼﾏﾏﾁ',
      full_lower: 'ヒガシタガワグン　フジシママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '06203鶴岡市', other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '424',
    name: '東田川郡　羽黒町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾀｶﾞﾜｸﾞﾝ ﾊｸﾞﾛﾏﾁ',
      full_lower: 'ヒガシタガワグン　ハグロマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '06203鶴岡市', other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '425',
    name: '東田川郡　櫛引町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾀｶﾞﾜｸﾞﾝ ｸｼﾋﾞｷﾏﾁ',
      full_lower: 'ヒガシタガワグン　クシビキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '06203鶴岡市', other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '426',
    name: '東田川郡　三川町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾀｶﾞﾜｸﾞﾝ ﾐｶﾜﾏﾁ',
      full_lower: 'ヒガシタガワグン　ミカワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '427',
    name: '東田川郡　朝日村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾀｶﾞﾜｸﾞﾝ ｱｻﾋﾑﾗ',
      full_lower: 'ヒガシタガワグン　アサヒムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '06203鶴岡市', other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '428',
    name: '東田川郡　庄内町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾀｶﾞﾜｸﾞﾝ ｼﾖｳﾅｲﾏﾁ',
      full_lower: 'ヒガシタガワグン　ショウナイマチ',
    },
    en: null,
    start_date: '2005-07-01',
    end_date: null,
    remark: { start: 'H17/07/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '440',
    name: '西田川郡',
    kana_name: { half_upper: 'ﾆｼﾀｶﾞﾜｸﾞﾝ', full_lower: 'ニシタガワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '441',
    name: '西田川郡　温海町',
    kana_name: {
      half_upper: 'ﾆｼﾀｶﾞﾜｸﾞﾝ ｱﾂﾐﾏﾁ',
      full_lower: 'ニシタガワグン　アツミマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '06203鶴岡市', other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '460',
    name: '飽海郡',
    kana_name: { half_upper: 'ｱｸﾐｸﾞﾝ', full_lower: 'アクミグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '461',
    name: '飽海郡　遊佐町',
    kana_name: {
      half_upper: 'ｱｸﾐｸﾞﾝ ﾕｻﾞﾏﾁ',
      full_lower: 'アクミグン　ユザマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '462',
    name: '飽海郡　八幡町',
    kana_name: {
      half_upper: 'ｱｸﾐｸﾞﾝ ﾔﾜﾀﾏﾁ',
      full_lower: 'アクミグン　ヤワタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-01',
    remark: { start: null, end: '06204酒田市', other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '463',
    name: '飽海郡　松山町',
    kana_name: {
      half_upper: 'ｱｸﾐｸﾞﾝ ﾏﾂﾔﾏﾏﾁ',
      full_lower: 'アクミグン　マツヤママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-01',
    remark: { start: null, end: '06204酒田市', other: null },
    note: null,
  },
  {
    pref_code: '06',
    city_code: '464',
    name: '飽海郡　平田町',
    kana_name: {
      half_upper: 'ｱｸﾐｸﾞﾝ ﾋﾗﾀﾏﾁ',
      full_lower: 'アクミグン　ヒラタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-01',
    remark: { start: null, end: '06204酒田市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '201',
    name: '福島市',
    kana_name: { half_upper: 'ﾌｸｼﾏｼ', full_lower: 'フクシマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '202',
    name: '会津若松市',
    kana_name: { half_upper: 'ｱｲﾂﾞﾜｶﾏﾂｼ', full_lower: 'アイヅワカマツシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '203',
    name: '郡山市',
    kana_name: { half_upper: 'ｺｵﾘﾔﾏｼ', full_lower: 'コオリヤマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '204',
    name: 'いわき市',
    kana_name: { half_upper: 'ｲﾜｷｼ', full_lower: 'イワキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '205',
    name: '白河市',
    kana_name: { half_upper: 'ｼﾗｶﾜｼ', full_lower: 'シラカワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '206',
    name: '原町市',
    kana_name: { half_upper: 'ﾊﾗﾏﾁｼ', full_lower: 'ハラマチシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '07212南相馬市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '207',
    name: '須賀川市',
    kana_name: { half_upper: 'ｽｶｶﾞﾜｼ', full_lower: 'スカガワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '208',
    name: '喜多方市',
    kana_name: { half_upper: 'ｷﾀｶﾀｼ', full_lower: 'キタカタシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '209',
    name: '相馬市',
    kana_name: { half_upper: 'ｿｳﾏｼ', full_lower: 'ソウマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '210',
    name: '二本松市',
    kana_name: { half_upper: 'ﾆﾎﾝﾏﾂｼ', full_lower: 'ニホンマツシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '211',
    name: '田村市',
    kana_name: { half_upper: 'ﾀﾑﾗｼ', full_lower: 'タムラシ' },
    en: null,
    start_date: '2005-03-01',
    end_date: null,
    remark: { start: 'H17/03/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '212',
    name: '南相馬市',
    kana_name: { half_upper: 'ﾐﾅﾐｿｳﾏｼ', full_lower: 'ミナミソウマシ' },
    en: null,
    start_date: '2006-01-01',
    end_date: null,
    remark: { start: 'H18/01/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '213',
    name: '伊達市',
    kana_name: { half_upper: 'ﾀﾞﾃｼ', full_lower: 'ダテシ' },
    en: null,
    start_date: '2006-01-01',
    end_date: null,
    remark: { start: 'H18/01/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '214',
    name: '本宮市',
    kana_name: { half_upper: 'ﾓﾄﾐﾔｼ', full_lower: 'モトミヤシ' },
    en: null,
    start_date: '2007-01-01',
    end_date: null,
    remark: { start: 'H19/01/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '300',
    name: '伊達郡',
    kana_name: { half_upper: 'ﾀﾞﾃｸﾞﾝ', full_lower: 'ダテグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '301',
    name: '伊達郡　桑折町',
    kana_name: {
      half_upper: 'ﾀﾞﾃｸﾞﾝ ｺｵﾘﾏﾁ',
      full_lower: 'ダテグン　コオリマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '302',
    name: '伊達郡　伊達町',
    kana_name: {
      half_upper: 'ﾀﾞﾃｸﾞﾝ ﾀﾞﾃﾏﾁ',
      full_lower: 'ダテグン　ダテマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '07213伊達市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '303',
    name: '伊達郡　国見町',
    kana_name: {
      half_upper: 'ﾀﾞﾃｸﾞﾝ ｸﾆﾐﾏﾁ',
      full_lower: 'ダテグン　クニミマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '304',
    name: '伊達郡　梁川町',
    kana_name: {
      half_upper: 'ﾀﾞﾃｸﾞﾝ ﾔﾅｶﾞﾜﾏﾁ',
      full_lower: 'ダテグン　ヤナガワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '07213伊達市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '305',
    name: '伊達郡　保原町',
    kana_name: {
      half_upper: 'ﾀﾞﾃｸﾞﾝ ﾎﾊﾞﾗﾏﾁ',
      full_lower: 'ダテグン　ホバラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '07213伊達市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '306',
    name: '伊達郡　霊山町',
    kana_name: {
      half_upper: 'ﾀﾞﾃｸﾞﾝ ﾘﾖｳｾﾞﾝﾏﾁ',
      full_lower: 'ダテグン　リョウゼンマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '07213伊達市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '307',
    name: '伊達郡　月舘町',
    kana_name: {
      half_upper: 'ﾀﾞﾃｸﾞﾝ ﾂｷﾀﾞﾃﾏﾁ',
      full_lower: 'ダテグン　ツキダテマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '07213伊達市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '308',
    name: '伊達郡　川俣町',
    kana_name: {
      half_upper: 'ﾀﾞﾃｸﾞﾝ ｶﾜﾏﾀﾏﾁ',
      full_lower: 'ダテグン　カワマタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '309',
    name: '伊達郡　飯野町',
    kana_name: {
      half_upper: 'ﾀﾞﾃｸﾞﾝ ｲｲﾉﾏﾁ',
      full_lower: 'ダテグン　イイノマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2008-07-01',
    remark: { start: null, end: '07201福島市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '320',
    name: '安達郡',
    kana_name: { half_upper: 'ｱﾀﾞﾁｸﾞﾝ', full_lower: 'アダチグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '321',
    name: '安達郡　安達町',
    kana_name: {
      half_upper: 'ｱﾀﾞﾁｸﾞﾝ ｱﾀﾞﾁﾏﾁ',
      full_lower: 'アダチグン　アダチマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-12-01',
    remark: { start: null, end: '07210二本松市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '322',
    name: '安達郡　大玉村',
    kana_name: {
      half_upper: 'ｱﾀﾞﾁｸﾞﾝ ｵｵﾀﾏﾑﾗ',
      full_lower: 'アダチグン　オオタマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '323',
    name: '安達郡　本宮町',
    kana_name: {
      half_upper: 'ｱﾀﾞﾁｸﾞﾝ ﾓﾄﾐﾔﾏﾁ',
      full_lower: 'アダチグン　モトミヤマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-01-01',
    remark: { start: null, end: '07214本宮市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '324',
    name: '安達郡　白沢村',
    kana_name: {
      half_upper: 'ｱﾀﾞﾁｸﾞﾝ ｼﾗｻﾜﾑﾗ',
      full_lower: 'アダチグン　シラサワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-01-01',
    remark: { start: null, end: '07214本宮市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '325',
    name: '安達郡　岩代町',
    kana_name: {
      half_upper: 'ｱﾀﾞﾁｸﾞﾝ ｲﾜｼﾛﾏﾁ',
      full_lower: 'アダチグン　イワシロマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-12-01',
    remark: { start: null, end: '07210二本松市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '326',
    name: '安達郡　東和町',
    kana_name: {
      half_upper: 'ｱﾀﾞﾁｸﾞﾝ ﾄｳﾜﾏﾁ',
      full_lower: 'アダチグン　トウワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-12-01',
    remark: { start: null, end: '07210二本松市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '340',
    name: '岩瀬郡',
    kana_name: { half_upper: 'ｲﾜｾｸﾞﾝ', full_lower: 'イワセグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '341',
    name: '岩瀬郡　長沼町',
    kana_name: {
      half_upper: 'ｲﾜｾｸﾞﾝ ﾅｶﾞﾇﾏﾏﾁ',
      full_lower: 'イワセグン　ナガヌママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '07207須賀川市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '342',
    name: '岩瀬郡　鏡石町',
    kana_name: {
      half_upper: 'ｲﾜｾｸﾞﾝ ｶｶﾞﾐｲｼﾏﾁ',
      full_lower: 'イワセグン　カガミイシマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '343',
    name: '岩瀬郡　岩瀬村',
    kana_name: {
      half_upper: 'ｲﾜｾｸﾞﾝ ｲﾜｾﾑﾗ',
      full_lower: 'イワセグン　イワセムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '07207須賀川市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '344',
    name: '岩瀬郡　天栄村',
    kana_name: {
      half_upper: 'ｲﾜｾｸﾞﾝ ﾃﾝｴｲﾑﾗ',
      full_lower: 'イワセグン　テンエイムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '360',
    name: '南会津郡',
    kana_name: { half_upper: 'ﾐﾅﾐｱｲﾂﾞｸﾞﾝ', full_lower: 'ミナミアイヅグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '361',
    name: '南会津郡　田島町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱｲﾂﾞｸﾞﾝ ﾀｼﾞﾏﾏﾁ',
      full_lower: 'ミナミアイヅグン　タジママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '07368南会津郡　南会津町', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '362',
    name: '南会津郡　下郷町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱｲﾂﾞｸﾞﾝ ｼﾓｺﾞｳﾏﾁ',
      full_lower: 'ミナミアイヅグン　シモゴウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '363',
    name: '南会津郡　舘岩村',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱｲﾂﾞｸﾞﾝ ﾀﾃｲﾜﾑﾗ',
      full_lower: 'ミナミアイヅグン　タテイワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '07368南会津郡　南会津町', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '364',
    name: '南会津郡　檜枝岐村',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱｲﾂﾞｸﾞﾝ ﾋﾉｴﾏﾀﾑﾗ',
      full_lower: 'ミナミアイヅグン　ヒノエマタムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '365',
    name: '南会津郡　伊南村',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱｲﾂﾞｸﾞﾝ ｲﾅﾑﾗ',
      full_lower: 'ミナミアイヅグン　イナムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '07368南会津郡　南会津町', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '366',
    name: '南会津郡　南郷村',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱｲﾂﾞｸﾞﾝ ﾅﾝｺﾞｳﾑﾗ',
      full_lower: 'ミナミアイヅグン　ナンゴウムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '07368南会津郡　南会津町', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '367',
    name: '南会津郡　只見町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱｲﾂﾞｸﾞﾝ ﾀﾀﾞﾐﾏﾁ',
      full_lower: 'ミナミアイヅグン　タダミマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '368',
    name: '南会津郡　南会津町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱｲﾂﾞｸﾞﾝ ﾐﾅﾐｱｲﾂﾞﾏﾁ',
      full_lower: 'ミナミアイヅグン　ミナミアイヅマチ',
    },
    en: null,
    start_date: '2006-03-20',
    end_date: null,
    remark: { start: 'H18/03/20新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '380',
    name: '北会津郡',
    kana_name: { half_upper: 'ｷﾀｱｲﾂﾞｸﾞﾝ', full_lower: 'キタアイヅグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '381',
    name: '北会津郡　北会津村',
    kana_name: {
      half_upper: 'ｷﾀｱｲﾂﾞｸﾞﾝ ｷﾀｱｲﾂﾞﾑﾗ',
      full_lower: 'キタアイヅグン　キタアイヅムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '07202会津若松市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '400',
    name: '耶麻郡',
    kana_name: { half_upper: 'ﾔﾏｸﾞﾝ', full_lower: 'ヤマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '401',
    name: '耶麻郡　熱塩加納村',
    kana_name: {
      half_upper: 'ﾔﾏｸﾞﾝ ｱﾂｼｵｶﾉｳﾑﾗ',
      full_lower: 'ヤマグン　アツシオカノウムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-04',
    remark: { start: null, end: '07208喜多方市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '402',
    name: '耶麻郡　北塩原村',
    kana_name: {
      half_upper: 'ﾔﾏｸﾞﾝ ｷﾀｼｵﾊﾞﾗﾑﾗ',
      full_lower: 'ヤマグン　キタシオバラムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '403',
    name: '耶麻郡　塩川町',
    kana_name: {
      half_upper: 'ﾔﾏｸﾞﾝ ｼｵｶﾜﾏﾁ',
      full_lower: 'ヤマグン　シオカワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-04',
    remark: { start: null, end: '07208喜多方市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '404',
    name: '耶麻郡　山都町',
    kana_name: {
      half_upper: 'ﾔﾏｸﾞﾝ ﾔﾏﾄﾏﾁ',
      full_lower: 'ヤマグン　ヤマトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-04',
    remark: { start: null, end: '07208喜多方市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '405',
    name: '耶麻郡　西会津町',
    kana_name: {
      half_upper: 'ﾔﾏｸﾞﾝ ﾆｼｱｲﾂﾞﾏﾁ',
      full_lower: 'ヤマグン　ニシアイヅマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '406',
    name: '耶麻郡　高郷村',
    kana_name: {
      half_upper: 'ﾔﾏｸﾞﾝ ﾀｶｻﾄﾑﾗ',
      full_lower: 'ヤマグン　タカサトムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-04',
    remark: { start: null, end: '07208喜多方市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '407',
    name: '耶麻郡　磐梯町',
    kana_name: {
      half_upper: 'ﾔﾏｸﾞﾝ ﾊﾞﾝﾀﾞｲﾏﾁ',
      full_lower: 'ヤマグン　バンダイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '408',
    name: '耶麻郡　猪苗代町',
    kana_name: {
      half_upper: 'ﾔﾏｸﾞﾝ ｲﾅﾜｼﾛﾏﾁ',
      full_lower: 'ヤマグン　イナワシロマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '420',
    name: '河沼郡',
    kana_name: { half_upper: 'ｶﾜﾇﾏｸﾞﾝ', full_lower: 'カワヌマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '421',
    name: '河沼郡　会津坂下町',
    kana_name: {
      half_upper: 'ｶﾜﾇﾏｸﾞﾝ ｱｲﾂﾞﾊﾞﾝｹﾞﾏﾁ',
      full_lower: 'カワヌマグン　アイヅバンゲマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '422',
    name: '河沼郡　湯川村',
    kana_name: {
      half_upper: 'ｶﾜﾇﾏｸﾞﾝ ﾕｶﾞﾜﾑﾗ',
      full_lower: 'カワヌマグン　ユガワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '423',
    name: '河沼郡　柳津町',
    kana_name: {
      half_upper: 'ｶﾜﾇﾏｸﾞﾝ ﾔﾅｲﾂﾞﾏﾁ',
      full_lower: 'カワヌマグン　ヤナイヅマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '424',
    name: '河沼郡　河東町',
    kana_name: {
      half_upper: 'ｶﾜﾇﾏｸﾞﾝ ｶﾜﾋｶﾞｼﾏﾁ',
      full_lower: 'カワヌマグン　カワヒガシマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-01',
    remark: { start: null, end: '07202会津若松市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '440',
    name: '大沼郡',
    kana_name: { half_upper: 'ｵｵﾇﾏｸﾞﾝ', full_lower: 'オオヌマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '441',
    name: '大沼郡　会津高田町',
    kana_name: {
      half_upper: 'ｵｵﾇﾏｸﾞﾝ ｱｲﾂﾞﾀｶﾀﾞﾏﾁ',
      full_lower: 'オオヌマグン　アイヅタカダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '07447大沼郡　会津美里町', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '442',
    name: '大沼郡　会津本郷町',
    kana_name: {
      half_upper: 'ｵｵﾇﾏｸﾞﾝ ｱｲﾂﾞﾎﾝｺﾞｳﾏﾁ',
      full_lower: 'オオヌマグン　アイヅホンゴウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '07447大沼郡　会津美里町', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '443',
    name: '大沼郡　新鶴村',
    kana_name: {
      half_upper: 'ｵｵﾇﾏｸﾞﾝ ﾆｲﾂﾙﾑﾗ',
      full_lower: 'オオヌマグン　ニイツルムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '07447大沼郡　会津美里町', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '444',
    name: '大沼郡　三島町',
    kana_name: {
      half_upper: 'ｵｵﾇﾏｸﾞﾝ ﾐｼﾏﾏﾁ',
      full_lower: 'オオヌマグン　ミシママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '445',
    name: '大沼郡　金山町',
    kana_name: {
      half_upper: 'ｵｵﾇﾏｸﾞﾝ ｶﾈﾔﾏﾏﾁ',
      full_lower: 'オオヌマグン　カネヤママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '446',
    name: '大沼郡　昭和村',
    kana_name: {
      half_upper: 'ｵｵﾇﾏｸﾞﾝ ｼﾖｳﾜﾑﾗ',
      full_lower: 'オオヌマグン　ショウワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '447',
    name: '大沼郡　会津美里町',
    kana_name: {
      half_upper: 'ｵｵﾇﾏｸﾞﾝ ｱｲﾂﾞﾐｻﾄﾏﾁ',
      full_lower: 'オオヌマグン　アイヅミサトマチ',
    },
    en: null,
    start_date: '2005-10-01',
    end_date: null,
    remark: { start: 'H17/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '460',
    name: '西白河郡',
    kana_name: { half_upper: 'ﾆｼｼﾗｶﾜｸﾞﾝ', full_lower: 'ニシシラカワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '461',
    name: '西白河郡　西郷村',
    kana_name: {
      half_upper: 'ﾆｼｼﾗｶﾜｸﾞﾝ ﾆｼｺﾞｳﾑﾗ',
      full_lower: 'ニシシラカワグン　ニシゴウムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '462',
    name: '西白河郡　表郷村',
    kana_name: {
      half_upper: 'ﾆｼｼﾗｶﾜｸﾞﾝ ｵﾓﾃｺﾞｳﾑﾗ',
      full_lower: 'ニシシラカワグン　オモテゴウムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-07',
    remark: { start: null, end: '07205白河市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '463',
    name: '西白河郡　東村',
    kana_name: {
      half_upper: 'ﾆｼｼﾗｶﾜｸﾞﾝ ﾋｶﾞｼﾑﾗ',
      full_lower: 'ニシシラカワグン　ヒガシムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-07',
    remark: { start: null, end: '07205白河市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '464',
    name: '西白河郡　泉崎村',
    kana_name: {
      half_upper: 'ﾆｼｼﾗｶﾜｸﾞﾝ ｲｽﾞﾐｻﾞｷﾑﾗ',
      full_lower: 'ニシシラカワグン　イズミザキムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '465',
    name: '西白河郡　中島村',
    kana_name: {
      half_upper: 'ﾆｼｼﾗｶﾜｸﾞﾝ ﾅｶｼﾞﾏﾑﾗ',
      full_lower: 'ニシシラカワグン　ナカジマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '466',
    name: '西白河郡　矢吹町',
    kana_name: {
      half_upper: 'ﾆｼｼﾗｶﾜｸﾞﾝ ﾔﾌﾞｷﾏﾁ',
      full_lower: 'ニシシラカワグン　ヤブキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '467',
    name: '西白河郡　大信村',
    kana_name: {
      half_upper: 'ﾆｼｼﾗｶﾜｸﾞﾝ ﾀｲｼﾝﾑﾗ',
      full_lower: 'ニシシラカワグン　タイシンムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-07',
    remark: { start: null, end: '07205白河市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '480',
    name: '東白川郡',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｼﾗｶﾜｸﾞﾝ',
      full_lower: 'ヒガシシラカワグン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '481',
    name: '東白川郡　棚倉町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｼﾗｶﾜｸﾞﾝ ﾀﾅｸﾞﾗﾏﾁ',
      full_lower: 'ヒガシシラカワグン　タナグラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '482',
    name: '東白川郡　矢祭町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｼﾗｶﾜｸﾞﾝ ﾔﾏﾂﾘﾏﾁ',
      full_lower: 'ヒガシシラカワグン　ヤマツリマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '483',
    name: '東白川郡　塙町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｼﾗｶﾜｸﾞﾝ ﾊﾅﾜﾏﾁ',
      full_lower: 'ヒガシシラカワグン　ハナワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '484',
    name: '東白川郡　鮫川村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｼﾗｶﾜｸﾞﾝ ｻﾒｶﾞﾜﾑﾗ',
      full_lower: 'ヒガシシラカワグン　サメガワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '485',
    name: '東白川郡　古殿町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｼﾗｶﾜｸﾞﾝ ﾌﾙﾄﾞﾉﾏﾁ',
      full_lower: 'ヒガシシラカワグン　フルドノマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1994-04-01',
    remark: { start: null, end: '07505石川郡　古殿町', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '500',
    name: '石川郡',
    kana_name: { half_upper: 'ｲｼｶﾜｸﾞﾝ', full_lower: 'イシカワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '501',
    name: '石川郡　石川町',
    kana_name: {
      half_upper: 'ｲｼｶﾜｸﾞﾝ ｲｼｶﾜﾏﾁ',
      full_lower: 'イシカワグン　イシカワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '502',
    name: '石川郡　玉川村',
    kana_name: {
      half_upper: 'ｲｼｶﾜｸﾞﾝ ﾀﾏｶﾜﾑﾗ',
      full_lower: 'イシカワグン　タマカワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '503',
    name: '石川郡　平田村',
    kana_name: {
      half_upper: 'ｲｼｶﾜｸﾞﾝ ﾋﾗﾀﾑﾗ',
      full_lower: 'イシカワグン　ヒラタムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '504',
    name: '石川郡　浅川町',
    kana_name: {
      half_upper: 'ｲｼｶﾜｸﾞﾝ ｱｻｶﾜﾏﾁ',
      full_lower: 'イシカワグン　アサカワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '505',
    name: '石川郡　古殿町',
    kana_name: {
      half_upper: 'ｲｼｶﾜｸﾞﾝ ﾌﾙﾄﾞﾉﾏﾁ',
      full_lower: 'イシカワグン　フルドノマチ',
    },
    en: null,
    start_date: '1994-04-01',
    end_date: null,
    remark: { start: 'H06/04/01郡変更', end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '520',
    name: '田村郡',
    kana_name: { half_upper: 'ﾀﾑﾗｸﾞﾝ', full_lower: 'タムラグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '521',
    name: '田村郡　三春町',
    kana_name: {
      half_upper: 'ﾀﾑﾗｸﾞﾝ ﾐﾊﾙﾏﾁ',
      full_lower: 'タムラグン　ミハルマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '522',
    name: '田村郡　小野町',
    kana_name: {
      half_upper: 'ﾀﾑﾗｸﾞﾝ ｵﾉﾏﾁ',
      full_lower: 'タムラグン　オノマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '523',
    name: '田村郡　滝根町',
    kana_name: {
      half_upper: 'ﾀﾑﾗｸﾞﾝ ﾀｷﾈﾏﾁ',
      full_lower: 'タムラグン　タキネマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '07211田村市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '524',
    name: '田村郡　大越町',
    kana_name: {
      half_upper: 'ﾀﾑﾗｸﾞﾝ ｵｵｺﾞｴﾏﾁ',
      full_lower: 'タムラグン　オオゴエマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '07211田村市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '525',
    name: '田村郡　都路村',
    kana_name: {
      half_upper: 'ﾀﾑﾗｸﾞﾝ ﾐﾔｺｼﾞﾑﾗ',
      full_lower: 'タムラグン　ミヤコジムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '07211田村市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '526',
    name: '田村郡　常葉町',
    kana_name: {
      half_upper: 'ﾀﾑﾗｸﾞﾝ ﾄｷﾜﾏﾁ',
      full_lower: 'タムラグン　トキワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '07211田村市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '527',
    name: '田村郡　船引町',
    kana_name: {
      half_upper: 'ﾀﾑﾗｸﾞﾝ ﾌﾈﾋｷﾏﾁ',
      full_lower: 'タムラグン　フネヒキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '07211田村市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '540',
    name: '双葉郡',
    kana_name: { half_upper: 'ﾌﾀﾊﾞｸﾞﾝ', full_lower: 'フタバグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '541',
    name: '双葉郡　広野町',
    kana_name: {
      half_upper: 'ﾌﾀﾊﾞｸﾞﾝ ﾋﾛﾉﾏﾁ',
      full_lower: 'フタバグン　ヒロノマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '542',
    name: '双葉郡　楢葉町',
    kana_name: {
      half_upper: 'ﾌﾀﾊﾞｸﾞﾝ ﾅﾗﾊﾏﾁ',
      full_lower: 'フタバグン　ナラハマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '543',
    name: '双葉郡　富岡町',
    kana_name: {
      half_upper: 'ﾌﾀﾊﾞｸﾞﾝ ﾄﾐｵｶﾏﾁ',
      full_lower: 'フタバグン　トミオカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '544',
    name: '双葉郡　川内村',
    kana_name: {
      half_upper: 'ﾌﾀﾊﾞｸﾞﾝ ｶﾜｳﾁﾑﾗ',
      full_lower: 'フタバグン　カワウチムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '545',
    name: '双葉郡　大熊町',
    kana_name: {
      half_upper: 'ﾌﾀﾊﾞｸﾞﾝ ｵｵｸﾏﾏﾁ',
      full_lower: 'フタバグン　オオクママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '546',
    name: '双葉郡　双葉町',
    kana_name: {
      half_upper: 'ﾌﾀﾊﾞｸﾞﾝ ﾌﾀﾊﾞﾏﾁ',
      full_lower: 'フタバグン　フタバマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '547',
    name: '双葉郡　浪江町',
    kana_name: {
      half_upper: 'ﾌﾀﾊﾞｸﾞﾝ ﾅﾐｴﾏﾁ',
      full_lower: 'フタバグン　ナミエマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '548',
    name: '双葉郡　葛尾村',
    kana_name: {
      half_upper: 'ﾌﾀﾊﾞｸﾞﾝ ｶﾂﾗｵﾑﾗ',
      full_lower: 'フタバグン　カツラオムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '560',
    name: '相馬郡',
    kana_name: { half_upper: 'ｿｳﾏｸﾞﾝ', full_lower: 'ソウマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '561',
    name: '相馬郡　新地町',
    kana_name: {
      half_upper: 'ｿｳﾏｸﾞﾝ ｼﾝﾁﾏﾁ',
      full_lower: 'ソウマグン　シンチマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '562',
    name: '相馬郡　鹿島町',
    kana_name: {
      half_upper: 'ｿｳﾏｸﾞﾝ ｶｼﾏﾏﾁ',
      full_lower: 'ソウマグン　カシママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '07212南相馬市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '563',
    name: '相馬郡　小高町',
    kana_name: {
      half_upper: 'ｿｳﾏｸﾞﾝ ｵﾀﾞｶﾏﾁ',
      full_lower: 'ソウマグン　オダカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '07212南相馬市', other: null },
    note: null,
  },
  {
    pref_code: '07',
    city_code: '564',
    name: '相馬郡　飯舘村',
    kana_name: {
      half_upper: 'ｿｳﾏｸﾞﾝ ｲｲﾀﾃﾑﾗ',
      full_lower: 'ソウマグン　イイタテムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '201',
    name: '水戸市',
    kana_name: { half_upper: 'ﾐﾄｼ', full_lower: 'ミトシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '202',
    name: '日立市',
    kana_name: { half_upper: 'ﾋﾀﾁｼ', full_lower: 'ヒタチシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '203',
    name: '土浦市',
    kana_name: { half_upper: 'ﾂﾁｳﾗｼ', full_lower: 'ツチウラシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '204',
    name: '古河市',
    kana_name: { half_upper: 'ｺｶﾞｼ', full_lower: 'コガシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '205',
    name: '石岡市',
    kana_name: { half_upper: 'ｲｼｵｶｼ', full_lower: 'イシオカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '206',
    name: '下館市',
    kana_name: { half_upper: 'ｼﾓﾀﾞﾃｼ', full_lower: 'シモダテシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '08227筑西市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '207',
    name: '結城市',
    kana_name: { half_upper: 'ﾕｳｷｼ', full_lower: 'ユウキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '208',
    name: '龍ケ崎市',
    kana_name: { half_upper: 'ﾘﾕｳｶﾞｻｷｼ', full_lower: 'リュウガサキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '209',
    name: '那珂湊市',
    kana_name: { half_upper: 'ﾅｶﾐﾅﾄｼ', full_lower: 'ナカミナトシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '1994-11-01',
    remark: { start: null, end: '08221ひたちなか市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '210',
    name: '下妻市',
    kana_name: { half_upper: 'ｼﾓﾂﾏｼ', full_lower: 'シモツマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '211',
    name: '水海道市',
    kana_name: { half_upper: 'ﾐﾂｶｲﾄﾞｳｼ', full_lower: 'ミツカイドウシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-10-01',
    remark: { start: null, end: '08211常総市', other: null },
    note: 'キー重複',
  },
  {
    pref_code: '08',
    city_code: '211',
    name: '常総市',
    kana_name: { half_upper: 'ｼﾞﾖｳｿｳｼ', full_lower: 'ジョウソウシ' },
    en: null,
    start_date: '2006-10-01',
    end_date: null,
    remark: {
      start: 'H18/10/01名称変更：旧「水海道市」',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '212',
    name: '常陸太田市',
    kana_name: { half_upper: 'ﾋﾀﾁｵｵﾀｼ', full_lower: 'ヒタチオオタシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '213',
    name: '勝田市',
    kana_name: { half_upper: 'ｶﾂﾀｼ', full_lower: 'カツタシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '1994-11-01',
    remark: { start: null, end: '08221ひたちなか市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '214',
    name: '高萩市',
    kana_name: { half_upper: 'ﾀｶﾊｷﾞｼ', full_lower: 'タカハギシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '215',
    name: '北茨城市',
    kana_name: { half_upper: 'ｷﾀｲﾊﾞﾗｷｼ', full_lower: 'キタイバラキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '216',
    name: '笠間市',
    kana_name: { half_upper: 'ｶｻﾏｼ', full_lower: 'カサマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '217',
    name: '取手市',
    kana_name: { half_upper: 'ﾄﾘﾃﾞｼ', full_lower: 'トリデシ' },
    en: null,
    start_date: '1970-10-01',
    end_date: null,
    remark: { start: 'S45/10/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '218',
    name: '岩井市',
    kana_name: { half_upper: 'ｲﾜｲｼ', full_lower: 'イワイシ' },
    en: null,
    start_date: '1972-04-01',
    end_date: '2005-03-22',
    remark: { start: 'S47/04/01市制', end: '08228坂東市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '219',
    name: '牛久市',
    kana_name: { half_upper: 'ｳｼｸｼ', full_lower: 'ウシクシ' },
    en: null,
    start_date: '1986-06-01',
    end_date: null,
    remark: { start: 'S61/06/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '220',
    name: 'つくば市',
    kana_name: { half_upper: 'ﾂｸﾊﾞｼ', full_lower: 'ツクバシ' },
    en: null,
    start_date: '1987-11-30',
    end_date: null,
    remark: { start: 'S62/11/30新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '221',
    name: 'ひたちなか市',
    kana_name: { half_upper: 'ﾋﾀﾁﾅｶｼ', full_lower: 'ヒタチナカシ' },
    en: null,
    start_date: '1994-11-01',
    end_date: null,
    remark: { start: 'H06/11/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '222',
    name: '鹿嶋市',
    kana_name: { half_upper: 'ｶｼﾏｼ', full_lower: 'カシマシ' },
    en: null,
    start_date: '1995-09-01',
    end_date: null,
    remark: { start: 'H07/09/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '223',
    name: '潮来市',
    kana_name: { half_upper: 'ｲﾀｺｼ', full_lower: 'イタコシ' },
    en: null,
    start_date: '2001-04-01',
    end_date: null,
    remark: { start: 'H13/04/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '224',
    name: '守谷市',
    kana_name: { half_upper: 'ﾓﾘﾔｼ', full_lower: 'モリヤシ' },
    en: null,
    start_date: '2002-02-02',
    end_date: null,
    remark: { start: 'H14/02/02市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '225',
    name: '常陸大宮市',
    kana_name: { half_upper: 'ﾋﾀﾁｵｵﾐﾔｼ', full_lower: 'ヒタチオオミヤシ' },
    en: null,
    start_date: '2004-10-16',
    end_date: null,
    remark: { start: 'H16/10/16市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '226',
    name: '那珂市',
    kana_name: { half_upper: 'ﾅｶｼ', full_lower: 'ナカシ' },
    en: null,
    start_date: '2005-01-21',
    end_date: null,
    remark: { start: 'H17/01/21市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '227',
    name: '筑西市',
    kana_name: { half_upper: 'ﾁｸｾｲｼ', full_lower: 'チクセイシ' },
    en: null,
    start_date: '2005-03-28',
    end_date: null,
    remark: { start: 'H17/03/28新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '228',
    name: '坂東市',
    kana_name: { half_upper: 'ﾊﾞﾝﾄﾞｳｼ', full_lower: 'バンドウシ' },
    en: null,
    start_date: '2005-03-22',
    end_date: null,
    remark: { start: 'H17/03/22新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '229',
    name: '稲敷市',
    kana_name: { half_upper: 'ｲﾅｼｷｼ', full_lower: 'イナシキシ' },
    en: null,
    start_date: '2005-03-22',
    end_date: null,
    remark: { start: 'H17/03/22新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '230',
    name: 'かすみがうら市',
    kana_name: { half_upper: 'ｶｽﾐｶﾞｳﾗｼ', full_lower: 'カスミガウラシ' },
    en: null,
    start_date: '2005-03-28',
    end_date: null,
    remark: { start: 'H17/03/28新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '231',
    name: '桜川市',
    kana_name: { half_upper: 'ｻｸﾗｶﾞﾜｼ', full_lower: 'サクラガワシ' },
    en: null,
    start_date: '2005-10-01',
    end_date: null,
    remark: { start: 'H17/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '232',
    name: '神栖市',
    kana_name: { half_upper: 'ｶﾐｽｼ', full_lower: 'カミスシ' },
    en: null,
    start_date: '2005-08-01',
    end_date: null,
    remark: { start: 'H17/08/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '233',
    name: '行方市',
    kana_name: { half_upper: 'ﾅﾒｶﾞﾀｼ', full_lower: 'ナメガタシ' },
    en: null,
    start_date: '2005-09-02',
    end_date: null,
    remark: { start: 'H17/09/02新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '234',
    name: '鉾田市',
    kana_name: { half_upper: 'ﾎｺﾀｼ', full_lower: 'ホコタシ' },
    en: null,
    start_date: '2005-10-11',
    end_date: null,
    remark: { start: 'H17/10/11新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '235',
    name: 'つくばみらい市',
    kana_name: { half_upper: 'ﾂｸﾊﾞﾐﾗｲｼ', full_lower: 'ツクバミライシ' },
    en: null,
    start_date: '2006-03-27',
    end_date: null,
    remark: { start: 'H18/03/27新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '236',
    name: '小美玉市',
    kana_name: { half_upper: 'ｵﾐﾀﾏｼ', full_lower: 'オミタマシ' },
    en: null,
    start_date: '2006-03-27',
    end_date: null,
    remark: { start: 'H18/03/27新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '300',
    name: '東茨城郡',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｲﾊﾞﾗｷｸﾞﾝ',
      full_lower: 'ヒガシイバラキグン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '301',
    name: '東茨城郡　常澄村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｲﾊﾞﾗｷｸﾞﾝ ﾂﾈｽﾞﾐﾑﾗ',
      full_lower: 'ヒガシイバラキグン　ツネズミムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1992-03-03',
    remark: { start: null, end: '08201水戸市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '302',
    name: '東茨城郡　茨城町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｲﾊﾞﾗｷｸﾞﾝ ｲﾊﾞﾗｷﾏﾁ',
      full_lower: 'ヒガシイバラキグン　イバラキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '303',
    name: '東茨城郡　小川町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｲﾊﾞﾗｷｸﾞﾝ ｵｶﾞﾜﾏﾁ',
      full_lower: 'ヒガシイバラキグン　オガワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '08236小美玉市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '304',
    name: '東茨城郡　美野里町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｲﾊﾞﾗｷｸﾞﾝ ﾐﾉﾘﾏﾁ',
      full_lower: 'ヒガシイバラキグン　ミノリマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '08236小美玉市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '305',
    name: '東茨城郡　内原町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｲﾊﾞﾗｷｸﾞﾝ ｳﾁﾊﾗﾏﾁ',
      full_lower: 'ヒガシイバラキグン　ウチハラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '08201水戸市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '306',
    name: '東茨城郡　常北町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｲﾊﾞﾗｷｸﾞﾝ ｼﾞﾖｳﾎｸﾏﾁ',
      full_lower: 'ヒガシイバラキグン　ジョウホクマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '08310東茨城郡　城里町', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '307',
    name: '東茨城郡　桂村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｲﾊﾞﾗｷｸﾞﾝ ｶﾂﾗﾑﾗ',
      full_lower: 'ヒガシイバラキグン　カツラムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '08310東茨城郡　城里町', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '308',
    name: '東茨城郡　御前山村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｲﾊﾞﾗｷｸﾞﾝ ｺﾞｾﾞﾝﾔﾏﾑﾗ',
      full_lower: 'ヒガシイバラキグン　ゴゼンヤマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-16',
    remark: { start: null, end: '08344那珂郡　大宮町', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '309',
    name: '東茨城郡　大洗町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｲﾊﾞﾗｷｸﾞﾝ ｵｵｱﾗｲﾏﾁ',
      full_lower: 'ヒガシイバラキグン　オオアライマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '310',
    name: '東茨城郡　城里町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｲﾊﾞﾗｷｸﾞﾝ ｼﾛｻﾄﾏﾁ',
      full_lower: 'ヒガシイバラキグン　シロサトマチ',
    },
    en: null,
    start_date: '2005-02-01',
    end_date: null,
    remark: { start: 'H17/02/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '320',
    name: '西茨城郡',
    kana_name: { half_upper: 'ﾆｼｲﾊﾞﾗｷｸﾞﾝ', full_lower: 'ニシイバラキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-19',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '321',
    name: '西茨城郡　友部町',
    kana_name: {
      half_upper: 'ﾆｼｲﾊﾞﾗｷｸﾞﾝ ﾄﾓﾍﾞﾏﾁ',
      full_lower: 'ニシイバラキグン　トモベマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-19',
    remark: { start: null, end: '08216笠間市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '322',
    name: '西茨城郡　岩間町',
    kana_name: {
      half_upper: 'ﾆｼｲﾊﾞﾗｷｸﾞﾝ ｲﾜﾏﾏﾁ',
      full_lower: 'ニシイバラキグン　イワママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-19',
    remark: { start: null, end: '08216笠間市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '323',
    name: '西茨城郡　七会村',
    kana_name: {
      half_upper: 'ﾆｼｲﾊﾞﾗｷｸﾞﾝ ﾅﾅｶｲﾑﾗ',
      full_lower: 'ニシイバラキグン　ナナカイムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '08310東茨城郡　城里町', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '324',
    name: '西茨城郡　岩瀬町',
    kana_name: {
      half_upper: 'ﾆｼｲﾊﾞﾗｷｸﾞﾝ ｲﾜｾﾏﾁ',
      full_lower: 'ニシイバラキグン　イワセマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '08231桜川市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '340',
    name: '那珂郡',
    kana_name: { half_upper: 'ﾅｶｸﾞﾝ', full_lower: 'ナカグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '341',
    name: '那珂郡　東海村',
    kana_name: {
      half_upper: 'ﾅｶｸﾞﾝ ﾄｳｶｲﾑﾗ',
      full_lower: 'ナカグン　トウカイムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '342',
    name: '那珂郡　那珂町',
    kana_name: { half_upper: 'ﾅｶｸﾞﾝ ﾅｶﾏﾁ', full_lower: 'ナカグン　ナカマチ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-21',
    remark: { start: null, end: '08226那珂市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '343',
    name: '那珂郡　瓜連町',
    kana_name: {
      half_upper: 'ﾅｶｸﾞﾝ ｳﾘﾂﾞﾗﾏﾁ',
      full_lower: 'ナカグン　ウリヅラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-21',
    remark: { start: null, end: '08342那珂郡　那珂町', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '344',
    name: '那珂郡　大宮町',
    kana_name: {
      half_upper: 'ﾅｶｸﾞﾝ ｵｵﾐﾔﾏﾁ',
      full_lower: 'ナカグン　オオミヤマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-16',
    remark: { start: null, end: '08225常陸大宮市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '345',
    name: '那珂郡　山方町',
    kana_name: {
      half_upper: 'ﾅｶｸﾞﾝ ﾔﾏｶﾞﾀﾏﾁ',
      full_lower: 'ナカグン　ヤマガタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-16',
    remark: { start: null, end: '08344那珂郡　大宮町', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '346',
    name: '那珂郡　美和村',
    kana_name: { half_upper: 'ﾅｶｸﾞﾝ ﾐﾜﾑﾗ', full_lower: 'ナカグン　ミワムラ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-16',
    remark: { start: null, end: '08344那珂郡　大宮町', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '347',
    name: '那珂郡　緒川村',
    kana_name: {
      half_upper: 'ﾅｶｸﾞﾝ ｵｶﾞﾜﾑﾗ',
      full_lower: 'ナカグン　オガワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-16',
    remark: { start: null, end: '08344那珂郡　大宮町', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '360',
    name: '久慈郡',
    kana_name: { half_upper: 'ｸｼﾞｸﾞﾝ', full_lower: 'クジグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '361',
    name: '久慈郡　金砂郷町',
    kana_name: {
      half_upper: 'ｸｼﾞｸﾞﾝ ｶﾅｻｺﾞｳﾏﾁ',
      full_lower: 'クジグン　カナサゴウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-12-01',
    remark: { start: null, end: '08212常陸太田市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '362',
    name: '久慈郡　水府村',
    kana_name: {
      half_upper: 'ｸｼﾞｸﾞﾝ ｽｲﾌﾑﾗ',
      full_lower: 'クジグン　スイフムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-12-01',
    remark: { start: null, end: '08212常陸太田市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '363',
    name: '久慈郡　里美村',
    kana_name: {
      half_upper: 'ｸｼﾞｸﾞﾝ ｻﾄﾐﾑﾗ',
      full_lower: 'クジグン　サトミムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-12-01',
    remark: { start: null, end: '08212常陸太田市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '364',
    name: '久慈郡　大子町',
    kana_name: {
      half_upper: 'ｸｼﾞｸﾞﾝ ﾀﾞｲｺﾞﾏﾁ',
      full_lower: 'クジグン　ダイゴマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '380',
    name: '多賀郡',
    kana_name: { half_upper: 'ﾀｶﾞｸﾞﾝ', full_lower: 'タガグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '381',
    name: '多賀郡　十王町',
    kana_name: {
      half_upper: 'ﾀｶﾞｸﾞﾝ ｼﾞﾕｳｵｳﾏﾁ',
      full_lower: 'タガグン　ジュウオウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '08202日立市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '400',
    name: '鹿島郡',
    kana_name: { half_upper: 'ｶｼﾏｸﾞﾝ', full_lower: 'カシマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-11',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '401',
    name: '鹿島郡　旭村',
    kana_name: {
      half_upper: 'ｶｼﾏｸﾞﾝ ｱｻﾋﾑﾗ',
      full_lower: 'カシマグン　アサヒムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-11',
    remark: { start: null, end: '08234鉾田市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '402',
    name: '鹿島郡　鉾田町',
    kana_name: {
      half_upper: 'ｶｼﾏｸﾞﾝ ﾎｺﾀﾏﾁ',
      full_lower: 'カシマグン　ホコタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-11',
    remark: { start: null, end: '08234鉾田市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '403',
    name: '鹿島郡　大洋村',
    kana_name: {
      half_upper: 'ｶｼﾏｸﾞﾝ ﾀｲﾖｳﾑﾗ',
      full_lower: 'カシマグン　タイヨウムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-11',
    remark: { start: null, end: '08234鉾田市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '404',
    name: '鹿島郡　大野村',
    kana_name: {
      half_upper: 'ｶｼﾏｸﾞﾝ ｵｵﾉﾑﾗ',
      full_lower: 'カシマグン　オオノムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1995-09-01',
    remark: { start: null, end: '08405鹿島郡　鹿島町', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '405',
    name: '鹿島郡　鹿島町',
    kana_name: {
      half_upper: 'ｶｼﾏｸﾞﾝ ｶｼﾏﾏﾁ',
      full_lower: 'カシマグン　カシママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1995-09-01',
    remark: { start: null, end: '08222鹿嶋市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '406',
    name: '鹿島郡　神栖町',
    kana_name: {
      half_upper: 'ｶｼﾏｸﾞﾝ ｶﾐｽﾏﾁ',
      full_lower: 'カシマグン　カミスマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-08-01',
    remark: { start: null, end: '08232神栖市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '407',
    name: '鹿島郡　波崎町',
    kana_name: {
      half_upper: 'ｶｼﾏｸﾞﾝ ﾊｻｷﾏﾁ',
      full_lower: 'カシマグン　ハサキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-08-01',
    remark: { start: null, end: '08406鹿島郡　神栖町', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '420',
    name: '行方郡',
    kana_name: { half_upper: 'ﾅﾒｶﾞﾀｸﾞﾝ', full_lower: 'ナメガタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-02',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '421',
    name: '行方郡　麻生町',
    kana_name: {
      half_upper: 'ﾅﾒｶﾞﾀｸﾞﾝ ｱｿｳﾏﾁ',
      full_lower: 'ナメガタグン　アソウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-02',
    remark: { start: null, end: '08233行方市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '422',
    name: '行方郡　牛堀町',
    kana_name: {
      half_upper: 'ﾅﾒｶﾞﾀｸﾞﾝ ｳｼﾎﾞﾘﾏﾁ',
      full_lower: 'ナメガタグン　ウシボリマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2001-04-01',
    remark: { start: null, end: '08423行方郡　潮来町', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '423',
    name: '行方郡　潮来町',
    kana_name: {
      half_upper: 'ﾅﾒｶﾞﾀｸﾞﾝ ｲﾀｺﾏﾁ',
      full_lower: 'ナメガタグン　イタコマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2001-04-01',
    remark: { start: null, end: '08223潮来市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '424',
    name: '行方郡　北浦町',
    kana_name: {
      half_upper: 'ﾅﾒｶﾞﾀｸﾞﾝ ｷﾀｳﾗﾏﾁ',
      full_lower: 'ナメガタグン　キタウラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-02',
    remark: { start: null, end: '08233行方市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '425',
    name: '行方郡　玉造町',
    kana_name: {
      half_upper: 'ﾅﾒｶﾞﾀｸﾞﾝ ﾀﾏﾂｸﾘﾏﾁ',
      full_lower: 'ナメガタグン　タマツクリマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-02',
    remark: { start: null, end: '08233行方市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '440',
    name: '稲敷郡',
    kana_name: { half_upper: 'ｲﾅｼｷｸﾞﾝ', full_lower: 'イナシキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '441',
    name: '稲敷郡　江戸崎町',
    kana_name: {
      half_upper: 'ｲﾅｼｷｸﾞﾝ ｴﾄﾞｻｷﾏﾁ',
      full_lower: 'イナシキグン　エドサキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '08229稲敷市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '442',
    name: '稲敷郡　美浦村',
    kana_name: {
      half_upper: 'ｲﾅｼｷｸﾞﾝ ﾐﾎﾑﾗ',
      full_lower: 'イナシキグン　ミホムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '443',
    name: '稲敷郡　阿見町',
    kana_name: {
      half_upper: 'ｲﾅｼｷｸﾞﾝ ｱﾐﾏﾁ',
      full_lower: 'イナシキグン　アミマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '444',
    name: '稲敷郡　牛久町',
    kana_name: {
      half_upper: 'ｲﾅｼｷｸﾞﾝ ｳｼｸﾏﾁ',
      full_lower: 'イナシキグン　ウシクマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1986-06-01',
    remark: { start: null, end: '08219牛久市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '445',
    name: '稲敷郡　茎崎町',
    kana_name: {
      half_upper: 'ｲﾅｼｷｸﾞﾝ ｸｷｻﾞｷﾏﾁ',
      full_lower: 'イナシキグン　クキザキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2002-11-01',
    remark: { start: null, end: '08220つくば市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '446',
    name: '稲敷郡　新利根町',
    kana_name: {
      half_upper: 'ｲﾅｼｷｸﾞﾝ ｼﾝﾄﾈﾏﾁ',
      full_lower: 'イナシキグン　シントネマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '08229稲敷市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '447',
    name: '稲敷郡　河内町',
    kana_name: {
      half_upper: 'ｲﾅｼｷｸﾞﾝ ｶﾜﾁﾏﾁ',
      full_lower: 'イナシキグン　カワチマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '448',
    name: '稲敷郡　桜川村',
    kana_name: {
      half_upper: 'ｲﾅｼｷｸﾞﾝ ｻｸﾗｶﾞﾜﾑﾗ',
      full_lower: 'イナシキグン　サクラガワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '08229稲敷市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '449',
    name: '稲敷郡　東町',
    kana_name: {
      half_upper: 'ｲﾅｼｷｸﾞﾝ ｱｽﾞﾏﾏﾁ',
      full_lower: 'イナシキグン　アズママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '08229稲敷市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '460',
    name: '新治郡',
    kana_name: { half_upper: 'ﾆｲﾊﾘｸﾞﾝ', full_lower: 'ニイハリグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '461',
    name: '新治郡　霞ヶ浦町',
    kana_name: {
      half_upper: 'ﾆｲﾊﾘｸﾞﾝ ｶｽﾐｶﾞｳﾗﾏﾁ',
      full_lower: 'ニイハリグン　カスミガウラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '08230かすみがうら市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '462',
    name: '新治郡　玉里村',
    kana_name: {
      half_upper: 'ﾆｲﾊﾘｸﾞﾝ ﾀﾏﾘﾑﾗ',
      full_lower: 'ニイハリグン　タマリムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '08236小美玉市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '463',
    name: '新治郡　八郷町',
    kana_name: {
      half_upper: 'ﾆｲﾊﾘｸﾞﾝ ﾔｻﾄﾏﾁ',
      full_lower: 'ニイハリグン　ヤサトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '08205石岡市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '464',
    name: '新治郡　千代田町',
    kana_name: {
      half_upper: 'ﾆｲﾊﾘｸﾞﾝ ﾁﾖﾀﾞﾏﾁ',
      full_lower: 'ニイハリグン　チヨダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '08230かすみがうら市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '465',
    name: '新治郡　新治村',
    kana_name: {
      half_upper: 'ﾆｲﾊﾘｸﾞﾝ ﾆｲﾊﾘﾑﾗ',
      full_lower: 'ニイハリグン　ニイハリムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-20',
    remark: { start: null, end: '08203土浦市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '466',
    name: '新治郡　桜村',
    kana_name: {
      half_upper: 'ﾆｲﾊﾘｸﾞﾝ ｻｸﾗﾑﾗ',
      full_lower: 'ニイハリグン　サクラムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1987-11-30',
    remark: { start: null, end: '08220つくば市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '480',
    name: '筑波郡',
    kana_name: { half_upper: 'ﾂｸﾊﾞｸﾞﾝ', full_lower: 'ツクバグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '481',
    name: '筑波郡　谷田部町',
    kana_name: {
      half_upper: 'ﾂｸﾊﾞｸﾞﾝ ﾔﾀﾍﾞﾏﾁ',
      full_lower: 'ツクバグン　ヤタベマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1987-11-30',
    remark: { start: null, end: '08220つくば市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '482',
    name: '筑波郡　伊奈町',
    kana_name: {
      half_upper: 'ﾂｸﾊﾞｸﾞﾝ ｲﾅﾏﾁ',
      full_lower: 'ツクバグン　イナマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '08235つくばみらい市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '483',
    name: '筑波郡　谷和原村',
    kana_name: {
      half_upper: 'ﾂｸﾊﾞｸﾞﾝ ﾔﾜﾗﾑﾗ',
      full_lower: 'ツクバグン　ヤワラムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '08235つくばみらい市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '484',
    name: '筑波郡　豊里町',
    kana_name: {
      half_upper: 'ﾂｸﾊﾞｸﾞﾝ ﾄﾖｻﾄﾏﾁ',
      full_lower: 'ツクバグン　トヨサトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1987-11-30',
    remark: { start: null, end: '08220つくば市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '485',
    name: '筑波郡　筑波町',
    kana_name: {
      half_upper: 'ﾂｸﾊﾞｸﾞﾝ ﾂｸﾊﾞﾏﾁ',
      full_lower: 'ツクバグン　ツクバマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1988-01-31',
    remark: { start: null, end: '08220つくば市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '486',
    name: '筑波郡　大穂町',
    kana_name: {
      half_upper: 'ﾂｸﾊﾞｸﾞﾝ ｵｵﾎﾏﾁ',
      full_lower: 'ツクバグン　オオホマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1987-11-30',
    remark: { start: null, end: '08220つくば市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '500',
    name: '真壁郡',
    kana_name: { half_upper: 'ﾏｶﾍﾞｸﾞﾝ', full_lower: 'マカベグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '501',
    name: '真壁郡　関城町',
    kana_name: {
      half_upper: 'ﾏｶﾍﾞｸﾞﾝ ｾｷｼﾞﾖｳﾏﾁ',
      full_lower: 'マカベグン　セキジョウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '08227筑西市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '502',
    name: '真壁郡　明野町',
    kana_name: {
      half_upper: 'ﾏｶﾍﾞｸﾞﾝ ｱｹﾉﾏﾁ',
      full_lower: 'マカベグン　アケノマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '08227筑西市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '503',
    name: '真壁郡　真壁町',
    kana_name: {
      half_upper: 'ﾏｶﾍﾞｸﾞﾝ ﾏｶﾍﾞﾏﾁ',
      full_lower: 'マカベグン　マカベマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '08231桜川市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '504',
    name: '真壁郡　大和村',
    kana_name: {
      half_upper: 'ﾏｶﾍﾞｸﾞﾝ ﾔﾏﾄﾑﾗ',
      full_lower: 'マカベグン　ヤマトムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '08231桜川市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '505',
    name: '真壁郡　協和町',
    kana_name: {
      half_upper: 'ﾏｶﾍﾞｸﾞﾝ ｷﾖｳﾜﾏﾁ',
      full_lower: 'マカベグン　キョウワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '08227筑西市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '520',
    name: '結城郡',
    kana_name: { half_upper: 'ﾕｳｷｸﾞﾝ', full_lower: 'ユウキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '521',
    name: '結城郡　八千代町',
    kana_name: {
      half_upper: 'ﾕｳｷｸﾞﾝ ﾔﾁﾖﾏﾁ',
      full_lower: 'ユウキグン　ヤチヨマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '522',
    name: '結城郡　千代川村',
    kana_name: {
      half_upper: 'ﾕｳｷｸﾞﾝ ﾁﾖｶﾜﾑﾗ',
      full_lower: 'ユウキグン　チヨカワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '08210下妻市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '523',
    name: '結城郡　石下町',
    kana_name: {
      half_upper: 'ﾕｳｷｸﾞﾝ ｲｼｹﾞﾏﾁ',
      full_lower: 'ユウキグン　イシゲマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '08211水海道市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '540',
    name: '猿島郡',
    kana_name: { half_upper: 'ｻｼﾏｸﾞﾝ', full_lower: 'サシマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '541',
    name: '猿島郡　総和町',
    kana_name: {
      half_upper: 'ｻｼﾏｸﾞﾝ ｿｳﾜﾏﾁ',
      full_lower: 'サシマグン　ソウワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-12',
    remark: { start: null, end: '08204古河市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '542',
    name: '猿島郡　五霞町',
    kana_name: {
      half_upper: 'ｻｼﾏｸﾞﾝ ｺﾞｶﾏﾁ',
      full_lower: 'サシマグン　ゴカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '543',
    name: '猿島郡　三和町',
    kana_name: {
      half_upper: 'ｻｼﾏｸﾞﾝ ｻﾝﾜﾏﾁ',
      full_lower: 'サシマグン　サンワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-12',
    remark: { start: null, end: '08204古河市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '544',
    name: '猿島郡　猿島町',
    kana_name: {
      half_upper: 'ｻｼﾏｸﾞﾝ ｻｼﾏﾏﾁ',
      full_lower: 'サシマグン　サシママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '08228坂東市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '545',
    name: '猿島郡　岩井町',
    kana_name: {
      half_upper: 'ｻｼﾏｸﾞﾝ ｲﾜｲﾏﾁ',
      full_lower: 'サシマグン　イワイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-04-01',
    remark: { start: null, end: '08218岩井市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '546',
    name: '猿島郡　境町',
    kana_name: {
      half_upper: 'ｻｼﾏｸﾞﾝ ｻｶｲﾏﾁ',
      full_lower: 'サシマグン　サカイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '560',
    name: '北相馬郡',
    kana_name: { half_upper: 'ｷﾀｿｳﾏｸﾞﾝ', full_lower: 'キタソウマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '561',
    name: '北相馬郡　守谷町',
    kana_name: {
      half_upper: 'ｷﾀｿｳﾏｸﾞﾝ ﾓﾘﾔﾏﾁ',
      full_lower: 'キタソウマグン　モリヤマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2002-02-02',
    remark: { start: null, end: '08224守谷市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '562',
    name: '北相馬郡　取手町',
    kana_name: {
      half_upper: 'ｷﾀｿｳﾏｸﾞﾝ ﾄﾘﾃﾞﾏﾁ',
      full_lower: 'キタソウマグン　トリデマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-10-01',
    remark: { start: null, end: '08217取手市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '563',
    name: '北相馬郡　藤代町',
    kana_name: {
      half_upper: 'ｷﾀｿｳﾏｸﾞﾝ ﾌｼﾞｼﾛﾏﾁ',
      full_lower: 'キタソウマグン　フジシロマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '08217取手市', other: null },
    note: null,
  },
  {
    pref_code: '08',
    city_code: '564',
    name: '北相馬郡　利根町',
    kana_name: {
      half_upper: 'ｷﾀｿｳﾏｸﾞﾝ ﾄﾈﾏﾁ',
      full_lower: 'キタソウマグン　トネマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '201',
    name: '宇都宮市',
    kana_name: { half_upper: 'ｳﾂﾉﾐﾔｼ', full_lower: 'ウツノミヤシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '202',
    name: '足利市',
    kana_name: { half_upper: 'ｱｼｶｶﾞｼ', full_lower: 'アシカガシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '203',
    name: '栃木市',
    kana_name: { half_upper: 'ﾄﾁｷﾞｼ', full_lower: 'トチギシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '204',
    name: '佐野市',
    kana_name: { half_upper: 'ｻﾉｼ', full_lower: 'サノシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '205',
    name: '鹿沼市',
    kana_name: { half_upper: 'ｶﾇﾏｼ', full_lower: 'カヌマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '206',
    name: '日光市',
    kana_name: { half_upper: 'ﾆﾂｺｳｼ', full_lower: 'ニッコウシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '207',
    name: '今市市',
    kana_name: { half_upper: 'ｲﾏｲﾁｼ', full_lower: 'イマイチシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '09206日光市', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '208',
    name: '小山市',
    kana_name: { half_upper: 'ｵﾔﾏｼ', full_lower: 'オヤマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '209',
    name: '真岡市',
    kana_name: { half_upper: 'ﾓｵｶｼ', full_lower: 'モオカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '210',
    name: '大田原市',
    kana_name: { half_upper: 'ｵｵﾀﾜﾗｼ', full_lower: 'オオタワラシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '211',
    name: '矢板市',
    kana_name: { half_upper: 'ﾔｲﾀｼ', full_lower: 'ヤイタシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '212',
    name: '黒磯市',
    kana_name: { half_upper: 'ｸﾛｲｿｼ', full_lower: 'クロイソシ' },
    en: null,
    start_date: '1970-11-01',
    end_date: '2005-01-01',
    remark: { start: 'S45/11/01市制', end: '09213那須塩原市', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '213',
    name: '那須塩原市',
    kana_name: { half_upper: 'ﾅｽｼｵﾊﾞﾗｼ', full_lower: 'ナスシオバラシ' },
    en: null,
    start_date: '2005-01-01',
    end_date: null,
    remark: { start: 'H17/01/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '214',
    name: 'さくら市',
    kana_name: { half_upper: 'ｻｸﾗｼ', full_lower: 'サクラシ' },
    en: null,
    start_date: '2005-03-28',
    end_date: null,
    remark: { start: 'H17/03/28新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '215',
    name: '那須烏山市',
    kana_name: { half_upper: 'ﾅｽｶﾗｽﾔﾏｼ', full_lower: 'ナスカラスヤマシ' },
    en: null,
    start_date: '2005-10-01',
    end_date: null,
    remark: { start: 'H17/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '216',
    name: '下野市',
    kana_name: { half_upper: 'ｼﾓﾂｹｼ', full_lower: 'シモツケシ' },
    en: null,
    start_date: '2006-01-10',
    end_date: null,
    remark: { start: 'H18/01/10新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '300',
    name: '河内郡',
    kana_name: { half_upper: 'ｶﾜﾁｸﾞﾝ', full_lower: 'カワチグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '301',
    name: '河内郡　上三川町',
    kana_name: {
      half_upper: 'ｶﾜﾁｸﾞﾝ ｶﾐﾉｶﾜﾏﾁ',
      full_lower: 'カワチグン　カミノカワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '302',
    name: '河内郡　南河内町',
    kana_name: {
      half_upper: 'ｶﾜﾁｸﾞﾝ ﾐﾅﾐｶﾜﾁﾏﾁ',
      full_lower: 'カワチグン　ミナミカワチマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-10',
    remark: { start: null, end: '09216下野市', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '303',
    name: '河内郡　上河内町',
    kana_name: {
      half_upper: 'ｶﾜﾁｸﾞﾝ ｶﾐｶﾜﾁﾏﾁ',
      full_lower: 'カワチグン　カミカワチマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-03-31',
    remark: { start: null, end: '09201宇都宮市', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '304',
    name: '河内郡　河内町',
    kana_name: {
      half_upper: 'ｶﾜﾁｸﾞﾝ ｶﾜﾁﾏﾁ',
      full_lower: 'カワチグン　カワチマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-03-31',
    remark: { start: null, end: '09201宇都宮市', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '320',
    name: '上都賀郡',
    kana_name: { half_upper: 'ｶﾐﾂｶﾞｸﾞﾝ', full_lower: 'カミツガグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2011-10-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '321',
    name: '上都賀郡　西方町',
    kana_name: {
      half_upper: 'ｶﾐﾂｶﾞｸﾞﾝ ﾆｼｶﾀﾏﾁ',
      full_lower: 'カミツガグン　ニシカタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2011-10-01',
    remark: { start: null, end: '09203栃木市', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '322',
    name: '上都賀郡　粟野町',
    kana_name: {
      half_upper: 'ｶﾐﾂｶﾞｸﾞﾝ ｱﾜﾉﾏﾁ',
      full_lower: 'カミツガグン　アワノマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '09205鹿沼市', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '323',
    name: '上都賀郡　足尾町',
    kana_name: {
      half_upper: 'ｶﾐﾂｶﾞｸﾞﾝ ｱｼｵﾏﾁ',
      full_lower: 'カミツガグン　アシオマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '09206日光市', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '340',
    name: '芳賀郡',
    kana_name: { half_upper: 'ﾊｶﾞｸﾞﾝ', full_lower: 'ハガグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '341',
    name: '芳賀郡　二宮町',
    kana_name: {
      half_upper: 'ﾊｶﾞｸﾞﾝ ﾆﾉﾐﾔﾏﾁ',
      full_lower: 'ハガグン　ニノミヤマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2009-03-23',
    remark: { start: null, end: '09209真岡市', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '342',
    name: '芳賀郡　益子町',
    kana_name: {
      half_upper: 'ﾊｶﾞｸﾞﾝ ﾏｼｺﾏﾁ',
      full_lower: 'ハガグン　マシコマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '343',
    name: '芳賀郡　茂木町',
    kana_name: {
      half_upper: 'ﾊｶﾞｸﾞﾝ ﾓﾃｷﾞﾏﾁ',
      full_lower: 'ハガグン　モテギマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '344',
    name: '芳賀郡　市貝町',
    kana_name: {
      half_upper: 'ﾊｶﾞｸﾞﾝ ｲﾁｶｲﾏﾁ',
      full_lower: 'ハガグン　イチカイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '345',
    name: '芳賀郡　芳賀町',
    kana_name: {
      half_upper: 'ﾊｶﾞｸﾞﾝ ﾊｶﾞﾏﾁ',
      full_lower: 'ハガグン　ハガマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '360',
    name: '下都賀郡',
    kana_name: { half_upper: 'ｼﾓﾂｶﾞｸﾞﾝ', full_lower: 'シモツガグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '361',
    name: '下都賀郡　壬生町',
    kana_name: {
      half_upper: 'ｼﾓﾂｶﾞｸﾞﾝ ﾐﾌﾞﾏﾁ',
      full_lower: 'シモツガグン　ミブマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '362',
    name: '下都賀郡　石橋町',
    kana_name: {
      half_upper: 'ｼﾓﾂｶﾞｸﾞﾝ ｲｼﾊﾞｼﾏﾁ',
      full_lower: 'シモツガグン　イシバシマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-10',
    remark: { start: null, end: '09216下野市', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '363',
    name: '下都賀郡　国分寺町',
    kana_name: {
      half_upper: 'ｼﾓﾂｶﾞｸﾞﾝ ｺｸﾌﾞﾝｼﾞﾏﾁ',
      full_lower: 'シモツガグン　コクブンジマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-10',
    remark: { start: null, end: '09216下野市', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '364',
    name: '下都賀郡　野木町',
    kana_name: {
      half_upper: 'ｼﾓﾂｶﾞｸﾞﾝ ﾉｷﾞﾏﾁ',
      full_lower: 'シモツガグン　ノギマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '365',
    name: '下都賀郡　大平町',
    kana_name: {
      half_upper: 'ｼﾓﾂｶﾞｸﾞﾝ ｵｵﾋﾗﾏﾁ',
      full_lower: 'シモツガグン　オオヒラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-29',
    remark: { start: null, end: '09203栃木市', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '366',
    name: '下都賀郡　藤岡町',
    kana_name: {
      half_upper: 'ｼﾓﾂｶﾞｸﾞﾝ ﾌｼﾞｵｶﾏﾁ',
      full_lower: 'シモツガグン　フジオカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-29',
    remark: { start: null, end: '09203栃木市', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '367',
    name: '下都賀郡　岩舟町',
    kana_name: {
      half_upper: 'ｼﾓﾂｶﾞｸﾞﾝ ｲﾜﾌﾈﾏﾁ',
      full_lower: 'シモツガグン　イワフネマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2014-04-05',
    remark: { start: null, end: '09203栃木市', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '368',
    name: '下都賀郡　都賀町',
    kana_name: {
      half_upper: 'ｼﾓﾂｶﾞｸﾞﾝ ﾂｶﾞﾏﾁ',
      full_lower: 'シモツガグン　ツガマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-29',
    remark: { start: null, end: '09203栃木市', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '380',
    name: '塩谷郡',
    kana_name: { half_upper: 'ｼｵﾔｸﾞﾝ', full_lower: 'シオヤグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '381',
    name: '塩谷郡　塩原町',
    kana_name: {
      half_upper: 'ｼｵﾔｸﾞﾝ ｼｵﾊﾞﾗﾏﾁ',
      full_lower: 'シオヤグン　シオバラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1982-04-01',
    remark: { start: null, end: '09410那須郡　塩原町', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '382',
    name: '塩谷郡　栗山村',
    kana_name: {
      half_upper: 'ｼｵﾔｸﾞﾝ ｸﾘﾔﾏﾑﾗ',
      full_lower: 'シオヤグン　クリヤマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '09206日光市', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '383',
    name: '塩谷郡　藤原町',
    kana_name: {
      half_upper: 'ｼｵﾔｸﾞﾝ ﾌｼﾞﾊﾗﾏﾁ',
      full_lower: 'シオヤグン　フジハラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '09206日光市', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '384',
    name: '塩谷郡　塩谷町',
    kana_name: {
      half_upper: 'ｼｵﾔｸﾞﾝ ｼｵﾔﾏﾁ',
      full_lower: 'シオヤグン　シオヤマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '385',
    name: '塩谷郡　氏家町',
    kana_name: {
      half_upper: 'ｼｵﾔｸﾞﾝ ｳｼﾞｲｴﾏﾁ',
      full_lower: 'シオヤグン　ウジイエマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '09214さくら市', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '386',
    name: '塩谷郡　高根沢町',
    kana_name: {
      half_upper: 'ｼｵﾔｸﾞﾝ ﾀｶﾈｻﾞﾜﾏﾁ',
      full_lower: 'シオヤグン　タカネザワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '387',
    name: '塩谷郡　喜連川町',
    kana_name: {
      half_upper: 'ｼｵﾔｸﾞﾝ ｷﾂﾚｶﾞﾜﾏﾁ',
      full_lower: 'シオヤグン　キツレガワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '09214さくら市', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '400',
    name: '那須郡',
    kana_name: { half_upper: 'ﾅｽｸﾞﾝ', full_lower: 'ナスグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '401',
    name: '那須郡　南那須町',
    kana_name: {
      half_upper: 'ﾅｽｸﾞﾝ ﾐﾅﾐﾅｽﾏﾁ',
      full_lower: 'ナスグン　ミナミナスマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '09215那須烏山市', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '402',
    name: '那須郡　烏山町',
    kana_name: {
      half_upper: 'ﾅｽｸﾞﾝ ｶﾗｽﾔﾏﾏﾁ',
      full_lower: 'ナスグン　カラスヤママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '09215那須烏山市', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '403',
    name: '那須郡　馬頭町',
    kana_name: {
      half_upper: 'ﾅｽｸﾞﾝ ﾊﾞﾄｳﾏﾁ',
      full_lower: 'ナスグン　バトウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '09411那須郡　那珂川町', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '404',
    name: '那須郡　小川町',
    kana_name: {
      half_upper: 'ﾅｽｸﾞﾝ ｵｶﾞﾜﾏﾁ',
      full_lower: 'ナスグン　オガワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '09411那須郡　那珂川町', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '405',
    name: '那須郡　湯津上村',
    kana_name: {
      half_upper: 'ﾅｽｸﾞﾝ ﾕﾂﾞｶﾐﾑﾗ',
      full_lower: 'ナスグン　ユヅカミムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '09210大田原市', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '406',
    name: '那須郡　黒羽町',
    kana_name: {
      half_upper: 'ﾅｽｸﾞﾝ ｸﾛﾊﾞﾈﾏﾁ',
      full_lower: 'ナスグン　クロバネマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '09210大田原市', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '407',
    name: '那須郡　那須町',
    kana_name: { half_upper: 'ﾅｽｸﾞﾝ ﾅｽﾏﾁ', full_lower: 'ナスグン　ナスマチ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '408',
    name: '那須郡　黒磯町',
    kana_name: {
      half_upper: 'ﾅｽｸﾞﾝ ｸﾛｲｿﾏﾁ',
      full_lower: 'ナスグン　クロイソマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-11-01',
    remark: { start: null, end: '09212黒磯市', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '409',
    name: '那須郡　西那須野町',
    kana_name: {
      half_upper: 'ﾅｽｸﾞﾝ ﾆｼﾅｽﾉﾏﾁ',
      full_lower: 'ナスグン　ニシナスノマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '09213那須塩原市', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '410',
    name: '那須郡　塩原町',
    kana_name: {
      half_upper: 'ﾅｽｸﾞﾝ ｼｵﾊﾞﾗﾏﾁ',
      full_lower: 'ナスグン　シオバラマチ',
    },
    en: null,
    start_date: '1982-04-01',
    end_date: '2005-01-01',
    remark: { start: 'S57/04/01郡変更', end: '09213那須塩原市', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '411',
    name: '那須郡　那珂川町',
    kana_name: {
      half_upper: 'ﾅｽｸﾞﾝ ﾅｶｶﾞﾜﾏﾁ',
      full_lower: 'ナスグン　ナカガワマチ',
    },
    en: null,
    start_date: '2005-10-01',
    end_date: null,
    remark: { start: 'H17/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '420',
    name: '安蘇郡',
    kana_name: { half_upper: 'ｱｿｸﾞﾝ', full_lower: 'アソグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-28',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '421',
    name: '安蘇郡　田沼町',
    kana_name: {
      half_upper: 'ｱｿｸﾞﾝ ﾀﾇﾏﾏﾁ',
      full_lower: 'アソグン　タヌママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-28',
    remark: { start: null, end: '09204佐野市', other: null },
    note: null,
  },
  {
    pref_code: '09',
    city_code: '422',
    name: '安蘇郡　葛生町',
    kana_name: {
      half_upper: 'ｱｿｸﾞﾝ ｸｽﾞｳﾏﾁ',
      full_lower: 'アソグン　クズウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-28',
    remark: { start: null, end: '09204佐野市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '201',
    name: '前橋市',
    kana_name: { half_upper: 'ﾏｴﾊﾞｼｼ', full_lower: 'マエバシシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '202',
    name: '高崎市',
    kana_name: { half_upper: 'ﾀｶｻｷｼ', full_lower: 'タカサキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '203',
    name: '桐生市',
    kana_name: { half_upper: 'ｷﾘﾕｳｼ', full_lower: 'キリュウシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '204',
    name: '伊勢崎市',
    kana_name: { half_upper: 'ｲｾｻｷｼ', full_lower: 'イセサキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '205',
    name: '太田市',
    kana_name: { half_upper: 'ｵｵﾀｼ', full_lower: 'オオタシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '206',
    name: '沼田市',
    kana_name: { half_upper: 'ﾇﾏﾀｼ', full_lower: 'ヌマタシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '207',
    name: '館林市',
    kana_name: { half_upper: 'ﾀﾃﾊﾞﾔｼｼ', full_lower: 'タテバヤシシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '208',
    name: '渋川市',
    kana_name: { half_upper: 'ｼﾌﾞｶﾜｼ', full_lower: 'シブカワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '209',
    name: '藤岡市',
    kana_name: { half_upper: 'ﾌｼﾞｵｶｼ', full_lower: 'フジオカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '210',
    name: '富岡市',
    kana_name: { half_upper: 'ﾄﾐｵｶｼ', full_lower: 'トミオカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '211',
    name: '安中市',
    kana_name: { half_upper: 'ｱﾝﾅｶｼ', full_lower: 'アンナカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '212',
    name: 'みどり市',
    kana_name: { half_upper: 'ﾐﾄﾞﾘｼ', full_lower: 'ミドリシ' },
    en: null,
    start_date: '2006-03-27',
    end_date: null,
    remark: { start: 'H18/03/27新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '300',
    name: '勢多郡',
    kana_name: { half_upper: 'ｾﾀｸﾞﾝ', full_lower: 'セタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2009-05-05',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '301',
    name: '勢多郡　北橘村',
    kana_name: {
      half_upper: 'ｾﾀｸﾞﾝ ｷﾀﾀﾁﾊﾞﾅﾑﾗ',
      full_lower: 'セタグン　キタタチバナムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-20',
    remark: { start: null, end: '10208渋川市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '302',
    name: '勢多郡　赤城村',
    kana_name: {
      half_upper: 'ｾﾀｸﾞﾝ ｱｶｷﾞﾑﾗ',
      full_lower: 'セタグン　アカギムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-20',
    remark: { start: null, end: '10208渋川市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '303',
    name: '勢多郡　富士見村',
    kana_name: {
      half_upper: 'ｾﾀｸﾞﾝ ﾌｼﾞﾐﾑﾗ',
      full_lower: 'セタグン　フジミムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2009-05-05',
    remark: { start: null, end: '10201前橋市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '304',
    name: '勢多郡　大胡町',
    kana_name: {
      half_upper: 'ｾﾀｸﾞﾝ ｵｵｺﾞﾏﾁ',
      full_lower: 'セタグン　オオゴマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-12-05',
    remark: { start: null, end: '10201前橋市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '305',
    name: '勢多郡　宮城村',
    kana_name: {
      half_upper: 'ｾﾀｸﾞﾝ ﾐﾔｷﾞﾑﾗ',
      full_lower: 'セタグン　ミヤギムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-12-05',
    remark: { start: null, end: '10201前橋市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '306',
    name: '勢多郡　粕川村',
    kana_name: {
      half_upper: 'ｾﾀｸﾞﾝ ｶｽｶﾜﾑﾗ',
      full_lower: 'セタグン　カスカワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-12-05',
    remark: { start: null, end: '10201前橋市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '307',
    name: '勢多郡　新里村',
    kana_name: {
      half_upper: 'ｾﾀｸﾞﾝ ﾆｲｻﾄﾑﾗ',
      full_lower: 'セタグン　ニイサトムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-06-13',
    remark: { start: null, end: '10203桐生市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '308',
    name: '勢多郡　黒保根村',
    kana_name: {
      half_upper: 'ｾﾀｸﾞﾝ ｸﾛﾎﾈﾑﾗ',
      full_lower: 'セタグン　クロホネムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-06-13',
    remark: { start: null, end: '10203桐生市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '309',
    name: '勢多郡　東村',
    kana_name: {
      half_upper: 'ｾﾀｸﾞﾝ ｱｽﾞﾏﾑﾗ',
      full_lower: 'セタグン　アズマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '10212みどり市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '320',
    name: '群馬郡',
    kana_name: { half_upper: 'ｸﾞﾝﾏｸﾞﾝ', full_lower: 'グンマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-10-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '321',
    name: '群馬郡　榛名町',
    kana_name: {
      half_upper: 'ｸﾞﾝﾏｸﾞﾝ ﾊﾙﾅﾏﾁ',
      full_lower: 'グンマグン　ハルナマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-10-01',
    remark: { start: null, end: '10202高崎市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '322',
    name: '群馬郡　倉渕村',
    kana_name: {
      half_upper: 'ｸﾞﾝﾏｸﾞﾝ ｸﾗﾌﾞﾁﾑﾗ',
      full_lower: 'グンマグン　クラブチムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-23',
    remark: { start: null, end: '10202高崎市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '323',
    name: '群馬郡　箕郷町',
    kana_name: {
      half_upper: 'ｸﾞﾝﾏｸﾞﾝ ﾐｻﾄﾏﾁ',
      full_lower: 'グンマグン　ミサトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-23',
    remark: { start: null, end: '10202高崎市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '324',
    name: '群馬郡　群馬町',
    kana_name: {
      half_upper: 'ｸﾞﾝﾏｸﾞﾝ ｸﾞﾝﾏﾏﾁ',
      full_lower: 'グンマグン　グンママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-23',
    remark: { start: null, end: '10202高崎市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '340',
    name: '北群馬郡',
    kana_name: { half_upper: 'ｷﾀｸﾞﾝﾏｸﾞﾝ', full_lower: 'キタグンマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '341',
    name: '北群馬郡　子持村',
    kana_name: {
      half_upper: 'ｷﾀｸﾞﾝﾏｸﾞﾝ ｺﾓﾁﾑﾗ',
      full_lower: 'キタグンマグン　コモチムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-20',
    remark: { start: null, end: '10208渋川市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '342',
    name: '北群馬郡　小野上村',
    kana_name: {
      half_upper: 'ｷﾀｸﾞﾝﾏｸﾞﾝ ｵﾉｶﾞﾐﾑﾗ',
      full_lower: 'キタグンマグン　オノガミムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-20',
    remark: { start: null, end: '10208渋川市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '343',
    name: '北群馬郡　伊香保町',
    kana_name: {
      half_upper: 'ｷﾀｸﾞﾝﾏｸﾞﾝ ｲｶﾎﾏﾁ',
      full_lower: 'キタグンマグン　イカホマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-20',
    remark: { start: null, end: '10208渋川市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '344',
    name: '北群馬郡　榛東村',
    kana_name: {
      half_upper: 'ｷﾀｸﾞﾝﾏｸﾞﾝ ｼﾝﾄｳﾑﾗ',
      full_lower: 'キタグンマグン　シントウムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '345',
    name: '北群馬郡　吉岡町',
    kana_name: {
      half_upper: 'ｷﾀｸﾞﾝﾏｸﾞﾝ ﾖｼｵｶﾏﾁ',
      full_lower: 'キタグンマグン　ヨシオカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '360',
    name: '多野郡',
    kana_name: { half_upper: 'ﾀﾉｸﾞﾝ', full_lower: 'タノグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '361',
    name: '多野郡　新町',
    kana_name: { half_upper: 'ﾀﾉｸﾞﾝ ｼﾝﾏﾁ', full_lower: 'タノグン　シンマチ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-23',
    remark: { start: null, end: '10202高崎市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '362',
    name: '多野郡　鬼石町',
    kana_name: {
      half_upper: 'ﾀﾉｸﾞﾝ ｵﾆｼﾏﾁ',
      full_lower: 'タノグン　オニシマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '10209藤岡市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '363',
    name: '多野郡　吉井町',
    kana_name: {
      half_upper: 'ﾀﾉｸﾞﾝ ﾖｼｲﾏﾁ',
      full_lower: 'タノグン　ヨシイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2009-06-01',
    remark: { start: null, end: '10202高崎市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '364',
    name: '多野郡　万場町',
    kana_name: {
      half_upper: 'ﾀﾉｸﾞﾝ ﾏﾝﾊﾞﾏﾁ',
      full_lower: 'タノグン　マンバマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: '10367多野郡　神流町', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '365',
    name: '多野郡　中里村',
    kana_name: {
      half_upper: 'ﾀﾉｸﾞﾝ ﾅｶｻﾄﾑﾗ',
      full_lower: 'タノグン　ナカサトムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: '10367多野郡　神流町', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '366',
    name: '多野郡　上野村',
    kana_name: {
      half_upper: 'ﾀﾉｸﾞﾝ ｳｴﾉﾑﾗ',
      full_lower: 'タノグン　ウエノムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '367',
    name: '多野郡　神流町',
    kana_name: {
      half_upper: 'ﾀﾉｸﾞﾝ ｶﾝﾅﾏﾁ',
      full_lower: 'タノグン　カンナマチ',
    },
    en: null,
    start_date: '2003-04-01',
    end_date: null,
    remark: { start: 'H15/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '380',
    name: '甘楽郡',
    kana_name: { half_upper: 'ｶﾝﾗｸﾞﾝ', full_lower: 'カンラグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '381',
    name: '甘楽郡　妙義町',
    kana_name: {
      half_upper: 'ｶﾝﾗｸﾞﾝ ﾐﾖｳｷﾞﾏﾁ',
      full_lower: 'カンラグン　ミョウギマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '10210富岡市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '382',
    name: '甘楽郡　下仁田町',
    kana_name: {
      half_upper: 'ｶﾝﾗｸﾞﾝ ｼﾓﾆﾀﾏﾁ',
      full_lower: 'カンラグン　シモニタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '383',
    name: '甘楽郡　南牧村',
    kana_name: {
      half_upper: 'ｶﾝﾗｸﾞﾝ ﾅﾝﾓｸﾑﾗ',
      full_lower: 'カンラグン　ナンモクムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '384',
    name: '甘楽郡　甘楽町',
    kana_name: {
      half_upper: 'ｶﾝﾗｸﾞﾝ ｶﾝﾗﾏﾁ',
      full_lower: 'カンラグン　カンラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '400',
    name: '碓氷郡',
    kana_name: { half_upper: 'ｳｽｲｸﾞﾝ', full_lower: 'ウスイグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-18',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '401',
    name: '碓氷郡　松井田町',
    kana_name: {
      half_upper: 'ｳｽｲｸﾞﾝ ﾏﾂｲﾀﾞﾏﾁ',
      full_lower: 'ウスイグン　マツイダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-18',
    remark: { start: null, end: '10211安中市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '420',
    name: '吾妻郡',
    kana_name: { half_upper: 'ｱｶﾞﾂﾏｸﾞﾝ', full_lower: 'アガツマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '421',
    name: '吾妻郡　中之条町',
    kana_name: {
      half_upper: 'ｱｶﾞﾂﾏｸﾞﾝ ﾅｶﾉｼﾞﾖｳﾏﾁ',
      full_lower: 'アガツマグン　ナカノジョウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '422',
    name: '吾妻郡　東村',
    kana_name: {
      half_upper: 'ｱｶﾞﾂﾏｸﾞﾝ ｱﾂﾞﾏﾑﾗ',
      full_lower: 'アガツマグン　アヅマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '10429吾妻郡　東吾妻町', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '423',
    name: '吾妻郡　吾妻町',
    kana_name: {
      half_upper: 'ｱｶﾞﾂﾏｸﾞﾝ ｱｶﾞﾂﾏﾏﾁ',
      full_lower: 'アガツマグン　アガツママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '10429吾妻郡　東吾妻町', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '424',
    name: '吾妻郡　長野原町',
    kana_name: {
      half_upper: 'ｱｶﾞﾂﾏｸﾞﾝ ﾅｶﾞﾉﾊﾗﾏﾁ',
      full_lower: 'アガツマグン　ナガノハラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '425',
    name: '吾妻郡　嬬恋村',
    kana_name: {
      half_upper: 'ｱｶﾞﾂﾏｸﾞﾝ ﾂﾏｺﾞｲﾑﾗ',
      full_lower: 'アガツマグン　ツマゴイムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '426',
    name: '吾妻郡　草津町',
    kana_name: {
      half_upper: 'ｱｶﾞﾂﾏｸﾞﾝ ｸｻﾂﾏﾁ',
      full_lower: 'アガツマグン　クサツマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '427',
    name: '吾妻郡　六合村',
    kana_name: {
      half_upper: 'ｱｶﾞﾂﾏｸﾞﾝ ｸﾆﾑﾗ',
      full_lower: 'アガツマグン　クニムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-28',
    remark: { start: null, end: '10421吾妻郡　中之条町', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '428',
    name: '吾妻郡　高山村',
    kana_name: {
      half_upper: 'ｱｶﾞﾂﾏｸﾞﾝ ﾀｶﾔﾏﾑﾗ',
      full_lower: 'アガツマグン　タカヤマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '429',
    name: '吾妻郡　東吾妻町',
    kana_name: {
      half_upper: 'ｱｶﾞﾂﾏｸﾞﾝ ﾋｶﾞｼｱｶﾞﾂﾏﾏﾁ',
      full_lower: 'アガツマグン　ヒガシアガツママチ',
    },
    en: null,
    start_date: '2006-03-27',
    end_date: null,
    remark: { start: 'H18/03/27新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '440',
    name: '利根郡',
    kana_name: { half_upper: 'ﾄﾈｸﾞﾝ', full_lower: 'トネグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '441',
    name: '利根郡　白沢村',
    kana_name: {
      half_upper: 'ﾄﾈｸﾞﾝ ｼﾗｻﾜﾑﾗ',
      full_lower: 'トネグン　シラサワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-13',
    remark: { start: null, end: '10206沼田市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '442',
    name: '利根郡　利根村',
    kana_name: { half_upper: 'ﾄﾈｸﾞﾝ ﾄﾈﾑﾗ', full_lower: 'トネグン　トネムラ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-13',
    remark: { start: null, end: '10206沼田市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '443',
    name: '利根郡　片品村',
    kana_name: {
      half_upper: 'ﾄﾈｸﾞﾝ ｶﾀｼﾅﾑﾗ',
      full_lower: 'トネグン　カタシナムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '444',
    name: '利根郡　川場村',
    kana_name: {
      half_upper: 'ﾄﾈｸﾞﾝ ｶﾜﾊﾞﾑﾗ',
      full_lower: 'トネグン　カワバムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '445',
    name: '利根郡　月夜野町',
    kana_name: {
      half_upper: 'ﾄﾈｸﾞﾝ ﾂｷﾖﾉﾏﾁ',
      full_lower: 'トネグン　ツキヨノマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '10449利根郡　みなかみ町', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '446',
    name: '利根郡　水上町',
    kana_name: {
      half_upper: 'ﾄﾈｸﾞﾝ ﾐﾅｶﾐﾏﾁ',
      full_lower: 'トネグン　ミナカミマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '10449利根郡　みなかみ町', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '447',
    name: '利根郡　新治村',
    kana_name: {
      half_upper: 'ﾄﾈｸﾞﾝ ﾆｲﾊﾙﾑﾗ',
      full_lower: 'トネグン　ニイハルムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '10449利根郡　みなかみ町', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '448',
    name: '利根郡　昭和村',
    kana_name: {
      half_upper: 'ﾄﾈｸﾞﾝ ｼﾖｳﾜﾑﾗ',
      full_lower: 'トネグン　ショウワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '449',
    name: '利根郡　みなかみ町',
    kana_name: {
      half_upper: 'ﾄﾈｸﾞﾝ ﾐﾅｶﾐﾏﾁ',
      full_lower: 'トネグン　ミナカミマチ',
    },
    en: null,
    start_date: '2005-10-01',
    end_date: null,
    remark: { start: 'H17/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '460',
    name: '佐波郡',
    kana_name: { half_upper: 'ｻﾜｸﾞﾝ', full_lower: 'サワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '461',
    name: '佐波郡　赤堀町',
    kana_name: {
      half_upper: 'ｻﾜｸﾞﾝ ｱｶﾎﾞﾘﾏﾁ',
      full_lower: 'サワグン　アカボリマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '10204伊勢崎市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '462',
    name: '佐波郡　東村',
    kana_name: {
      half_upper: 'ｻﾜｸﾞﾝ ｱｽﾞﾏﾑﾗ',
      full_lower: 'サワグン　アズマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '10204伊勢崎市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '463',
    name: '佐波郡　境町',
    kana_name: {
      half_upper: 'ｻﾜｸﾞﾝ ｻｶｲﾏﾁ',
      full_lower: 'サワグン　サカイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '10204伊勢崎市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '464',
    name: '佐波郡　玉村町',
    kana_name: {
      half_upper: 'ｻﾜｸﾞﾝ ﾀﾏﾑﾗﾏﾁ',
      full_lower: 'サワグン　タマムラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '480',
    name: '新田郡',
    kana_name: { half_upper: 'ﾆﾂﾀｸﾞﾝ', full_lower: 'ニッタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '481',
    name: '新田郡　尾島町',
    kana_name: {
      half_upper: 'ﾆﾂﾀｸﾞﾝ ｵｼﾞﾏﾏﾁ',
      full_lower: 'ニッタグン　オジママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '10205太田市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '482',
    name: '新田郡　新田町',
    kana_name: {
      half_upper: 'ﾆﾂﾀｸﾞﾝ ﾆﾂﾀﾏﾁ',
      full_lower: 'ニッタグン　ニッタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '10205太田市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '483',
    name: '新田郡　藪塚本町',
    kana_name: {
      half_upper: 'ﾆﾂﾀｸﾞﾝ ﾔﾌﾞﾂﾞｶﾎﾝﾏﾁ',
      full_lower: 'ニッタグン　ヤブヅカホンマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '10205太田市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '484',
    name: '新田郡　笠懸町',
    kana_name: {
      half_upper: 'ﾆﾂﾀｸﾞﾝ ｶｻｶｹﾏﾁ',
      full_lower: 'ニッタグン　カサカケマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '10212みどり市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '500',
    name: '山田郡',
    kana_name: { half_upper: 'ﾔﾏﾀﾞｸﾞﾝ', full_lower: 'ヤマダグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '501',
    name: '山田郡　大間々町',
    kana_name: {
      half_upper: 'ﾔﾏﾀﾞｸﾞﾝ ｵｵﾏﾏﾏﾁ',
      full_lower: 'ヤマダグン　オオマママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '10212みどり市', other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '520',
    name: '邑楽郡',
    kana_name: { half_upper: 'ｵｳﾗｸﾞﾝ', full_lower: 'オウラグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '521',
    name: '邑楽郡　板倉町',
    kana_name: {
      half_upper: 'ｵｳﾗｸﾞﾝ ｲﾀｸﾗﾏﾁ',
      full_lower: 'オウラグン　イタクラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '522',
    name: '邑楽郡　明和町',
    kana_name: {
      half_upper: 'ｵｳﾗｸﾞﾝ ﾒｲﾜﾏﾁ',
      full_lower: 'オウラグン　メイワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '523',
    name: '邑楽郡　千代田町',
    kana_name: {
      half_upper: 'ｵｳﾗｸﾞﾝ ﾁﾖﾀﾞﾏﾁ',
      full_lower: 'オウラグン　チヨダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '524',
    name: '邑楽郡　大泉町',
    kana_name: {
      half_upper: 'ｵｳﾗｸﾞﾝ ｵｵｲｽﾞﾐﾏﾁ',
      full_lower: 'オウラグン　オオイズミマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '10',
    city_code: '525',
    name: '邑楽郡　邑楽町',
    kana_name: {
      half_upper: 'ｵｳﾗｸﾞﾝ ｵｳﾗﾏﾁ',
      full_lower: 'オウラグン　オウラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '100',
    name: 'さいたま市',
    kana_name: { half_upper: 'ｻｲﾀﾏｼ', full_lower: 'サイタマシ' },
    en: null,
    start_date: '2003-04-01',
    end_date: null,
    remark: { start: 'H15/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '101',
    name: 'さいたま市　西区',
    kana_name: { half_upper: 'ｻｲﾀﾏｼ ﾆｼｸ', full_lower: 'サイタマシ　ニシク' },
    en: null,
    start_date: '2003-04-01',
    end_date: null,
    remark: { start: 'H15/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '102',
    name: 'さいたま市　北区',
    kana_name: { half_upper: 'ｻｲﾀﾏｼ ｷﾀｸ', full_lower: 'サイタマシ　キタク' },
    en: null,
    start_date: '2003-04-01',
    end_date: null,
    remark: { start: 'H15/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '103',
    name: 'さいたま市　大宮区',
    kana_name: {
      half_upper: 'ｻｲﾀﾏｼ ｵｵﾐﾔｸ',
      full_lower: 'サイタマシ　オオミヤク',
    },
    en: null,
    start_date: '2003-04-01',
    end_date: null,
    remark: { start: 'H15/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '104',
    name: 'さいたま市　見沼区',
    kana_name: {
      half_upper: 'ｻｲﾀﾏｼ ﾐﾇﾏｸ',
      full_lower: 'サイタマシ　ミヌマク',
    },
    en: null,
    start_date: '2003-04-01',
    end_date: null,
    remark: { start: 'H15/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '105',
    name: 'さいたま市　中央区',
    kana_name: {
      half_upper: 'ｻｲﾀﾏｼ ﾁﾕｳｵｳｸ',
      full_lower: 'サイタマシ　チュウオウク',
    },
    en: null,
    start_date: '2003-04-01',
    end_date: null,
    remark: { start: 'H15/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '106',
    name: 'さいたま市　桜区',
    kana_name: {
      half_upper: 'ｻｲﾀﾏｼ ｻｸﾗｸ',
      full_lower: 'サイタマシ　サクラク',
    },
    en: null,
    start_date: '2003-04-01',
    end_date: null,
    remark: { start: 'H15/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '107',
    name: 'さいたま市　浦和区',
    kana_name: {
      half_upper: 'ｻｲﾀﾏｼ ｳﾗﾜｸ',
      full_lower: 'サイタマシ　ウラワク',
    },
    en: null,
    start_date: '2003-04-01',
    end_date: null,
    remark: { start: 'H15/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '108',
    name: 'さいたま市　南区',
    kana_name: {
      half_upper: 'ｻｲﾀﾏｼ ﾐﾅﾐｸ',
      full_lower: 'サイタマシ　ミナミク',
    },
    en: null,
    start_date: '2003-04-01',
    end_date: null,
    remark: { start: 'H15/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '109',
    name: 'さいたま市　緑区',
    kana_name: {
      half_upper: 'ｻｲﾀﾏｼ ﾐﾄﾞﾘｸ',
      full_lower: 'サイタマシ　ミドリク',
    },
    en: null,
    start_date: '2003-04-01',
    end_date: null,
    remark: { start: 'H15/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '110',
    name: 'さいたま市　岩槻区',
    kana_name: {
      half_upper: 'ｻｲﾀﾏｼ ｲﾜﾂｷｸ',
      full_lower: 'サイタマシ　イワツキク',
    },
    en: null,
    start_date: '2005-04-01',
    end_date: null,
    remark: { start: 'H17/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '201',
    name: '川越市',
    kana_name: { half_upper: 'ｶﾜｺﾞｴｼ', full_lower: 'カワゴエシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '202',
    name: '熊谷市',
    kana_name: { half_upper: 'ｸﾏｶﾞﾔｼ', full_lower: 'クマガヤシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '203',
    name: '川口市',
    kana_name: { half_upper: 'ｶﾜｸﾞﾁｼ', full_lower: 'カワグチシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '204',
    name: '浦和市',
    kana_name: { half_upper: 'ｳﾗﾜｼ', full_lower: 'ウラワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2001-05-01',
    remark: { start: null, end: '11244さいたま市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '205',
    name: '大宮市',
    kana_name: { half_upper: 'ｵｵﾐﾔｼ', full_lower: 'オオミヤシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2001-05-01',
    remark: { start: null, end: '11244さいたま市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '206',
    name: '行田市',
    kana_name: { half_upper: 'ｷﾞﾖｳﾀﾞｼ', full_lower: 'ギョウダシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '207',
    name: '秩父市',
    kana_name: { half_upper: 'ﾁﾁﾌﾞｼ', full_lower: 'チチブシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '208',
    name: '所沢市',
    kana_name: { half_upper: 'ﾄｺﾛｻﾞﾜｼ', full_lower: 'トコロザワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '209',
    name: '飯能市',
    kana_name: { half_upper: 'ﾊﾝﾉｳｼ', full_lower: 'ハンノウシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '210',
    name: '加須市',
    kana_name: { half_upper: 'ｶｿﾞｼ', full_lower: 'カゾシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '211',
    name: '本庄市',
    kana_name: { half_upper: 'ﾎﾝｼﾞﾖｳｼ', full_lower: 'ホンジョウシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '212',
    name: '東松山市',
    kana_name: { half_upper: 'ﾋｶﾞｼﾏﾂﾔﾏｼ', full_lower: 'ヒガシマツヤマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '213',
    name: '岩槻市',
    kana_name: { half_upper: 'ｲﾜﾂｷｼ', full_lower: 'イワツキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '11100さいたま市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '214',
    name: '春日部市',
    kana_name: { half_upper: 'ｶｽｶﾍﾞｼ', full_lower: 'カスカベシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '215',
    name: '狭山市',
    kana_name: { half_upper: 'ｻﾔﾏｼ', full_lower: 'サヤマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '216',
    name: '羽生市',
    kana_name: { half_upper: 'ﾊﾆﾕｳｼ', full_lower: 'ハニュウシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '217',
    name: '鴻巣市',
    kana_name: { half_upper: 'ｺｳﾉｽｼ', full_lower: 'コウノスシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '218',
    name: '深谷市',
    kana_name: { half_upper: 'ﾌｶﾔｼ', full_lower: 'フカヤシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '219',
    name: '上尾市',
    kana_name: { half_upper: 'ｱｹﾞｵｼ', full_lower: 'アゲオシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '220',
    name: '与野市',
    kana_name: { half_upper: 'ﾖﾉｼ', full_lower: 'ヨノシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2001-05-01',
    remark: { start: null, end: '11244さいたま市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '221',
    name: '草加市',
    kana_name: { half_upper: 'ｿｳｶｼ', full_lower: 'ソウカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '222',
    name: '越谷市',
    kana_name: { half_upper: 'ｺｼｶﾞﾔｼ', full_lower: 'コシガヤシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '223',
    name: '蕨市',
    kana_name: { half_upper: 'ﾜﾗﾋﾞｼ', full_lower: 'ワラビシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '224',
    name: '戸田市',
    kana_name: { half_upper: 'ﾄﾀﾞｼ', full_lower: 'トダシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '225',
    name: '入間市',
    kana_name: { half_upper: 'ｲﾙﾏｼ', full_lower: 'イルマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '226',
    name: '鳩ケ谷市',
    kana_name: { half_upper: 'ﾊﾄｶﾞﾔｼ', full_lower: 'ハトガヤシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2011-10-11',
    remark: { start: null, end: '11203川口市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '227',
    name: '朝霞市',
    kana_name: { half_upper: 'ｱｻｶｼ', full_lower: 'アサカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '228',
    name: '志木市',
    kana_name: { half_upper: 'ｼｷｼ', full_lower: 'シキシ' },
    en: null,
    start_date: '1970-10-26',
    end_date: null,
    remark: { start: 'S45/10/26市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '229',
    name: '和光市',
    kana_name: { half_upper: 'ﾜｺｳｼ', full_lower: 'ワコウシ' },
    en: null,
    start_date: '1970-10-31',
    end_date: null,
    remark: { start: 'S45/10/31市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '230',
    name: '新座市',
    kana_name: { half_upper: 'ﾆｲｻﾞｼ', full_lower: 'ニイザシ' },
    en: null,
    start_date: '1970-11-01',
    end_date: null,
    remark: { start: 'S45/11/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '231',
    name: '桶川市',
    kana_name: { half_upper: 'ｵｹｶﾞﾜｼ', full_lower: 'オケガワシ' },
    en: null,
    start_date: '1970-11-03',
    end_date: null,
    remark: { start: 'S45/11/03市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '232',
    name: '久喜市',
    kana_name: { half_upper: 'ｸｷｼ', full_lower: 'クキシ' },
    en: null,
    start_date: '1971-10-01',
    end_date: null,
    remark: { start: 'S46/11/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '233',
    name: '北本市',
    kana_name: { half_upper: 'ｷﾀﾓﾄｼ', full_lower: 'キタモトシ' },
    en: null,
    start_date: '1971-11-03',
    end_date: null,
    remark: { start: 'S46/11/03市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '234',
    name: '八潮市',
    kana_name: { half_upper: 'ﾔｼｵｼ', full_lower: 'ヤシオシ' },
    en: null,
    start_date: '1972-01-15',
    end_date: null,
    remark: { start: 'S47/01/15市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '235',
    name: '富士見市',
    kana_name: { half_upper: 'ﾌｼﾞﾐｼ', full_lower: 'フジミシ' },
    en: null,
    start_date: '1972-04-10',
    end_date: null,
    remark: { start: 'S47/04/10市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '236',
    name: '上福岡市',
    kana_name: { half_upper: 'ｶﾐﾌｸｵｶｼ', full_lower: 'カミフクオカシ' },
    en: null,
    start_date: '1972-04-09',
    end_date: '2005-10-01',
    remark: { start: 'S47/04/09市制', end: '11245ふじみ野市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '237',
    name: '三郷市',
    kana_name: { half_upper: 'ﾐｻﾄｼ', full_lower: 'ミサトシ' },
    en: null,
    start_date: '1972-05-03',
    end_date: null,
    remark: { start: 'S47/05/03市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '238',
    name: '蓮田市',
    kana_name: { half_upper: 'ﾊｽﾀﾞｼ', full_lower: 'ハスダシ' },
    en: null,
    start_date: '1972-10-01',
    end_date: null,
    remark: { start: 'S47/10/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '239',
    name: '坂戸市',
    kana_name: { half_upper: 'ｻｶﾄﾞｼ', full_lower: 'サカドシ' },
    en: null,
    start_date: '1976-09-01',
    end_date: null,
    remark: { start: 'S51/09/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '240',
    name: '幸手市',
    kana_name: { half_upper: 'ｻﾂﾃｼ', full_lower: 'サッテシ' },
    en: null,
    start_date: '1986-10-01',
    end_date: null,
    remark: { start: 'S61/10/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '241',
    name: '鶴ヶ島市',
    kana_name: { half_upper: 'ﾂﾙｶﾞｼﾏｼ', full_lower: 'ツルガシマシ' },
    en: null,
    start_date: '1991-09-01',
    end_date: null,
    remark: { start: 'H03/09/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '242',
    name: '日高市',
    kana_name: { half_upper: 'ﾋﾀﾞｶｼ', full_lower: 'ヒダカシ' },
    en: null,
    start_date: '1991-10-01',
    end_date: null,
    remark: { start: 'H03/10/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '243',
    name: '吉川市',
    kana_name: { half_upper: 'ﾖｼｶﾜｼ', full_lower: 'ヨシカワシ' },
    en: null,
    start_date: '1996-04-01',
    end_date: null,
    remark: { start: 'H08/04/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '244',
    name: 'さいたま市',
    kana_name: { half_upper: 'ｻｲﾀﾏｼ', full_lower: 'サイタマシ' },
    en: null,
    start_date: '2001-05-01',
    end_date: '2003-04-01',
    remark: { start: 'H13/05/01新設', end: '11100さいたま市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '245',
    name: 'ふじみ野市',
    kana_name: { half_upper: 'ﾌｼﾞﾐﾉｼ', full_lower: 'フジミノシ' },
    en: null,
    start_date: '2005-10-01',
    end_date: null,
    remark: { start: 'H17/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '246',
    name: '白岡市',
    kana_name: { half_upper: 'ｼﾗｵｶｼ', full_lower: 'シラオカシ' },
    en: null,
    start_date: '2012-10-01',
    end_date: null,
    remark: { start: 'H24/10/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '300',
    name: '北足立郡',
    kana_name: { half_upper: 'ｷﾀｱﾀﾞﾁｸﾞﾝ', full_lower: 'キタアダチグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '301',
    name: '北足立郡　伊奈町',
    kana_name: {
      half_upper: 'ｷﾀｱﾀﾞﾁｸﾞﾝ ｲﾅﾏﾁ',
      full_lower: 'キタアダチグン　イナマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '302',
    name: '北足立郡　桶川町',
    kana_name: {
      half_upper: 'ｷﾀｱﾀﾞﾁｸﾞﾝ ｵｹｶﾞﾜﾏﾁ',
      full_lower: 'キタアダチグン　オケガワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-11-03',
    remark: { start: null, end: '11231桶川市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '303',
    name: '北足立郡　北本町',
    kana_name: {
      half_upper: 'ｷﾀｱﾀﾞﾁｸﾞﾝ ｷﾀﾓﾄﾏﾁ',
      full_lower: 'キタアダチグン　キタモトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-11-03',
    remark: { start: null, end: '11233北本市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '304',
    name: '北足立郡　吹上町',
    kana_name: {
      half_upper: 'ｷﾀｱﾀﾞﾁｸﾞﾝ ﾌｷｱｹﾞﾏﾁ',
      full_lower: 'キタアダチグン　フキアゲマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '11217鴻巣市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '305',
    name: '北足立郡　足立町',
    kana_name: {
      half_upper: 'ｷﾀｱﾀﾞﾁｸﾞﾝ ｱﾀﾞﾁﾏﾁ',
      full_lower: 'キタアダチグン　アダチマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-10-26',
    remark: { start: null, end: '11228志木市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '306',
    name: '北足立郡　新座町',
    kana_name: {
      half_upper: 'ｷﾀｱﾀﾞﾁｸﾞﾝ ﾆｲｻﾞﾏﾁ',
      full_lower: 'キタアダチグン　ニイザマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-11-01',
    remark: { start: null, end: '11230新座市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '307',
    name: '北足立郡　大和町',
    kana_name: {
      half_upper: 'ｷﾀｱﾀﾞﾁｸﾞﾝ ﾔﾏﾄﾏﾁ',
      full_lower: 'キタアダチグン　ヤマトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-10-31',
    remark: { start: null, end: '11229和光市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '320',
    name: '入間郡',
    kana_name: { half_upper: 'ｲﾙﾏｸﾞﾝ', full_lower: 'イルマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '321',
    name: '入間郡　福岡町',
    kana_name: {
      half_upper: 'ｲﾙﾏｸﾞﾝ ﾌｸｵｶﾏﾁ',
      full_lower: 'イルマグン　フクオカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-04-09',
    remark: { start: null, end: '11236上福岡市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '322',
    name: '入間郡　大井町',
    kana_name: {
      half_upper: 'ｲﾙﾏｸﾞﾝ ｵｵｲﾏﾁ',
      full_lower: 'イルマグン　オオイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '11245ふじみ野市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '323',
    name: '入間郡　富士見町',
    kana_name: {
      half_upper: 'ｲﾙﾏｸﾞﾝ ﾌｼﾞﾐﾏﾁ',
      full_lower: 'イルマグン　フジミマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-04-10',
    remark: { start: null, end: '11235富士見市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '324',
    name: '入間郡　三芳町',
    kana_name: {
      half_upper: 'ｲﾙﾏｸﾞﾝ ﾐﾖｼﾏﾁ',
      full_lower: 'イルマグン　ミヨシマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '325',
    name: '入間郡　坂戸町',
    kana_name: {
      half_upper: 'ｲﾙﾏｸﾞﾝ ｻｶﾄﾞﾏﾁ',
      full_lower: 'イルマグン　サカドマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1976-09-01',
    remark: { start: null, end: '11239坂戸市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '326',
    name: '入間郡　毛呂山町',
    kana_name: {
      half_upper: 'ｲﾙﾏｸﾞﾝ ﾓﾛﾔﾏﾏﾁ',
      full_lower: 'イルマグン　モロヤママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '327',
    name: '入間郡　越生町',
    kana_name: {
      half_upper: 'ｲﾙﾏｸﾞﾝ ｵｺﾞｾﾏﾁ',
      full_lower: 'イルマグン　オゴセマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '328',
    name: '入間郡　鶴ケ島町',
    kana_name: {
      half_upper: 'ｲﾙﾏｸﾞﾝ ﾂﾙｶﾞｼﾏﾏﾁ',
      full_lower: 'イルマグン　ツルガシママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1991-09-01',
    remark: { start: null, end: '11241鶴ヶ島市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '329',
    name: '入間郡　日高町',
    kana_name: {
      half_upper: 'ｲﾙﾏｸﾞﾝ ﾋﾀﾞｶﾏﾁ',
      full_lower: 'イルマグン　ヒダカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1991-10-01',
    remark: { start: null, end: '11242日高市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '330',
    name: '入間郡　名栗村',
    kana_name: {
      half_upper: 'ｲﾙﾏｸﾞﾝ ﾅｸﾞﾘﾑﾗ',
      full_lower: 'イルマグン　ナグリムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '11209飯能市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '340',
    name: '比企郡',
    kana_name: { half_upper: 'ﾋｷｸﾞﾝ', full_lower: 'ヒキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '341',
    name: '比企郡　滑川町',
    kana_name: {
      half_upper: 'ﾋｷｸﾞﾝ ﾅﾒｶﾞﾜﾏﾁ',
      full_lower: 'ヒキグン　ナメガワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '342',
    name: '比企郡　嵐山町',
    kana_name: {
      half_upper: 'ﾋｷｸﾞﾝ ﾗﾝｻﾞﾝﾏﾁ',
      full_lower: 'ヒキグン　ランザンマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '343',
    name: '比企郡　小川町',
    kana_name: {
      half_upper: 'ﾋｷｸﾞﾝ ｵｶﾞﾜﾏﾁ',
      full_lower: 'ヒキグン　オガワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '344',
    name: '比企郡　都幾川村',
    kana_name: {
      half_upper: 'ﾋｷｸﾞﾝ ﾄｷｶﾞﾜﾑﾗ',
      full_lower: 'ヒキグン　トキガワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-01',
    remark: { start: null, end: '11349比企郡　ときがわ町', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '345',
    name: '比企郡　玉川村',
    kana_name: {
      half_upper: 'ﾋｷｸﾞﾝ ﾀﾏｶﾞﾜﾑﾗ',
      full_lower: 'ヒキグン　タマガワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-01',
    remark: { start: null, end: '11349比企郡　ときがわ町', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '346',
    name: '比企郡　川島町',
    kana_name: {
      half_upper: 'ﾋｷｸﾞﾝ ｶﾜｼﾞﾏﾏﾁ',
      full_lower: 'ヒキグン　カワジママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '347',
    name: '比企郡　吉見町',
    kana_name: {
      half_upper: 'ﾋｷｸﾞﾝ ﾖｼﾐﾏﾁ',
      full_lower: 'ヒキグン　ヨシミマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '348',
    name: '比企郡　鳩山町',
    kana_name: {
      half_upper: 'ﾋｷｸﾞﾝ ﾊﾄﾔﾏﾏﾁ',
      full_lower: 'ヒキグン　ハトヤママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '349',
    name: '比企郡　ときがわ町',
    kana_name: {
      half_upper: 'ﾋｷｸﾞﾝ ﾄｷｶﾞﾜﾏﾁ',
      full_lower: 'ヒキグン　トキガワマチ',
    },
    en: null,
    start_date: '2006-02-01',
    end_date: null,
    remark: { start: 'H18/02/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '360',
    name: '秩父郡',
    kana_name: { half_upper: 'ﾁﾁﾌﾞｸﾞﾝ', full_lower: 'チチブグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '361',
    name: '秩父郡　横瀬町',
    kana_name: {
      half_upper: 'ﾁﾁﾌﾞｸﾞﾝ ﾖｺｾﾞﾏﾁ',
      full_lower: 'チチブグン　ヨコゼマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '362',
    name: '秩父郡　皆野町',
    kana_name: {
      half_upper: 'ﾁﾁﾌﾞｸﾞﾝ ﾐﾅﾉﾏﾁ',
      full_lower: 'チチブグン　ミナノマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '363',
    name: '秩父郡　長瀞町',
    kana_name: {
      half_upper: 'ﾁﾁﾌﾞｸﾞﾝ ﾅｶﾞﾄﾛﾏﾁ',
      full_lower: 'チチブグン　ナガトロマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '364',
    name: '秩父郡　吉田町',
    kana_name: {
      half_upper: 'ﾁﾁﾌﾞｸﾞﾝ ﾖｼﾀﾞﾏﾁ',
      full_lower: 'チチブグン　ヨシダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '11207秩父市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '365',
    name: '秩父郡　小鹿野町',
    kana_name: {
      half_upper: 'ﾁﾁﾌﾞｸﾞﾝ ｵｶﾞﾉﾏﾁ',
      full_lower: 'チチブグン　オガノマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '366',
    name: '秩父郡　両神村',
    kana_name: {
      half_upper: 'ﾁﾁﾌﾞｸﾞﾝ ﾘﾖｳｶﾐﾑﾗ',
      full_lower: 'チチブグン　リョウカミムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '11365秩父郡　小鹿野町', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '367',
    name: '秩父郡　大滝村',
    kana_name: {
      half_upper: 'ﾁﾁﾌﾞｸﾞﾝ ｵｵﾀｷﾑﾗ',
      full_lower: 'チチブグン　オオタキムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '11207秩父市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '368',
    name: '秩父郡　荒川村',
    kana_name: {
      half_upper: 'ﾁﾁﾌﾞｸﾞﾝ ｱﾗｶﾜﾑﾗ',
      full_lower: 'チチブグン　アラカワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '11207秩父市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '369',
    name: '秩父郡　東秩父村',
    kana_name: {
      half_upper: 'ﾁﾁﾌﾞｸﾞﾝ ﾋｶﾞｼﾁﾁﾌﾞﾑﾗ',
      full_lower: 'チチブグン　ヒガシチチブムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '380',
    name: '児玉郡',
    kana_name: { half_upper: 'ｺﾀﾞﾏｸﾞﾝ', full_lower: 'コダマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '381',
    name: '児玉郡　美里町',
    kana_name: {
      half_upper: 'ｺﾀﾞﾏｸﾞﾝ ﾐｻﾄﾏﾁ',
      full_lower: 'コダマグン　ミサトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '382',
    name: '児玉郡　児玉町',
    kana_name: {
      half_upper: 'ｺﾀﾞﾏｸﾞﾝ ｺﾀﾞﾏﾏﾁ',
      full_lower: 'コダマグン　コダママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-10',
    remark: { start: null, end: '11211本庄市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '383',
    name: '児玉郡　神川町',
    kana_name: {
      half_upper: 'ｺﾀﾞﾏｸﾞﾝ ｶﾐｶﾜﾏﾁ',
      full_lower: 'コダマグン　カミカワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '384',
    name: '児玉郡　神泉村',
    kana_name: {
      half_upper: 'ｺﾀﾞﾏｸﾞﾝ ｶﾐｲｽﾞﾐﾑﾗ',
      full_lower: 'コダマグン　カミイズミムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '11383児玉郡　神川町', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '385',
    name: '児玉郡　上里町',
    kana_name: {
      half_upper: 'ｺﾀﾞﾏｸﾞﾝ ｶﾐｻﾄﾏﾁ',
      full_lower: 'コダマグン　カミサトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '400',
    name: '大里郡',
    kana_name: { half_upper: 'ｵｵｻﾄｸﾞﾝ', full_lower: 'オオサトグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '401',
    name: '大里郡　大里町',
    kana_name: {
      half_upper: 'ｵｵｻﾄｸﾞﾝ ｵｵｻﾄﾏﾁ',
      full_lower: 'オオサトグン　オオサトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '11202熊谷市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '402',
    name: '大里郡　江南町',
    kana_name: {
      half_upper: 'ｵｵｻﾄｸﾞﾝ ｺｳﾅﾝﾏﾁ',
      full_lower: 'オオサトグン　コウナンマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-02-13',
    remark: { start: null, end: '11202熊谷市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '403',
    name: '大里郡　妻沼町',
    kana_name: {
      half_upper: 'ｵｵｻﾄｸﾞﾝ ﾒﾇﾏﾏﾁ',
      full_lower: 'オオサトグン　メヌママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '11202熊谷市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '404',
    name: '大里郡　豊里村',
    kana_name: {
      half_upper: 'ｵｵｻﾄｸﾞﾝ ﾄﾖｻﾄﾑﾗ',
      full_lower: 'オオサトグン　トヨサトムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1973-04-01',
    remark: { start: null, end: '11218深谷市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '405',
    name: '大里郡　岡部町',
    kana_name: {
      half_upper: 'ｵｵｻﾄｸﾞﾝ ｵｶﾍﾞﾏﾁ',
      full_lower: 'オオサトグン　オカベマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '11218深谷市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '406',
    name: '大里郡　川本町',
    kana_name: {
      half_upper: 'ｵｵｻﾄｸﾞﾝ ｶﾜﾓﾄﾏﾁ',
      full_lower: 'オオサトグン　カワモトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '11218深谷市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '407',
    name: '大里郡　花園町',
    kana_name: {
      half_upper: 'ｵｵｻﾄｸﾞﾝ ﾊﾅｿﾞﾉﾏﾁ',
      full_lower: 'オオサトグン　ハナゾノマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '11218深谷市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '408',
    name: '大里郡　寄居町',
    kana_name: {
      half_upper: 'ｵｵｻﾄｸﾞﾝ ﾖﾘｲﾏﾁ',
      full_lower: 'オオサトグン　ヨリイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '420',
    name: '北埼玉郡',
    kana_name: { half_upper: 'ｷﾀｻｲﾀﾏｸﾞﾝ', full_lower: 'キタサイタマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-23',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '421',
    name: '北埼玉郡　騎西町',
    kana_name: {
      half_upper: 'ｷﾀｻｲﾀﾏｸﾞﾝ ｷｻｲﾏﾁ',
      full_lower: 'キタサイタマグン　キサイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-23',
    remark: { start: null, end: '11210加須市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '422',
    name: '北埼玉郡　南河原村',
    kana_name: {
      half_upper: 'ｷﾀｻｲﾀﾏｸﾞﾝ ﾐﾅﾐｶﾜﾗﾑﾗ',
      full_lower: 'キタサイタマグン　ミナミカワラムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '11206行田市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '423',
    name: '北埼玉郡　川里町',
    kana_name: {
      half_upper: 'ｷﾀｻｲﾀﾏｸﾞﾝ ｶﾜｻﾄﾏﾁ',
      full_lower: 'キタサイタマグン　カワサトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '11217鴻巣市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '424',
    name: '北埼玉郡　北川辺町',
    kana_name: {
      half_upper: 'ｷﾀｻｲﾀﾏｸﾞﾝ ｷﾀｶﾜﾍﾞﾏﾁ',
      full_lower: 'キタサイタマグン　キタカワベマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-23',
    remark: { start: null, end: '11210加須市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '425',
    name: '北埼玉郡　大利根町',
    kana_name: {
      half_upper: 'ｷﾀｻｲﾀﾏｸﾞﾝ ｵｵﾄﾈﾏﾁ',
      full_lower: 'キタサイタマグン　オオトネマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-23',
    remark: { start: null, end: '11210加須市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '440',
    name: '南埼玉郡',
    kana_name: { half_upper: 'ﾐﾅﾐｻｲﾀﾏｸﾞﾝ', full_lower: 'ミナミサイタマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '441',
    name: '南埼玉郡　八潮町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｻｲﾀﾏｸﾞﾝ ﾔｼｵﾏﾁ',
      full_lower: 'ミナミサイタマグン　ヤシオマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-01-15',
    remark: { start: null, end: '11234八潮市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '442',
    name: '南埼玉郡　宮代町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｻｲﾀﾏｸﾞﾝ ﾐﾔｼﾛﾏﾁ',
      full_lower: 'ミナミサイタマグン　ミヤシロマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '443',
    name: '南埼玉郡　久喜町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｻｲﾀﾏｸﾞﾝ ｸｷﾏﾁ',
      full_lower: 'ミナミサイタマグン　クキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-10-01',
    remark: { start: null, end: '11232久喜市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '444',
    name: '南埼玉郡　蓮田町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｻｲﾀﾏｸﾞﾝ ﾊｽﾀﾞﾏﾁ',
      full_lower: 'ミナミサイタマグン　ハスダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-10-01',
    remark: { start: null, end: '11238蓮田市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '445',
    name: '南埼玉郡　白岡町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｻｲﾀﾏｸﾞﾝ ｼﾗｵｶﾏﾁ',
      full_lower: 'ミナミサイタマグン　シラオカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2012-10-01',
    remark: { start: null, end: '11246白岡市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '446',
    name: '南埼玉郡　菖蒲町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｻｲﾀﾏｸﾞﾝ ｼﾖｳﾌﾞﾏﾁ',
      full_lower: 'ミナミサイタマグン　ショウブマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-23',
    remark: { start: null, end: '11232久喜市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '460',
    name: '北葛飾郡',
    kana_name: { half_upper: 'ｷﾀｶﾂｼｶｸﾞﾝ', full_lower: 'キタカツシカグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '461',
    name: '北葛飾郡　栗橋町',
    kana_name: {
      half_upper: 'ｷﾀｶﾂｼｶｸﾞﾝ ｸﾘﾊｼﾏﾁ',
      full_lower: 'キタカツシカグン　クリハシマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-23',
    remark: { start: null, end: '11232久喜市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '462',
    name: '北葛飾郡　鷲宮町',
    kana_name: {
      half_upper: 'ｷﾀｶﾂｼｶｸﾞﾝ ﾜｼﾐﾔﾏﾁ',
      full_lower: 'キタカツシカグン　ワシミヤマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-23',
    remark: { start: null, end: '11232久喜市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '463',
    name: '北葛飾郡　幸手町',
    kana_name: {
      half_upper: 'ｷﾀｶﾂｼｶｸﾞﾝ ｻﾂﾃﾏﾁ',
      full_lower: 'キタカツシカグン　サッテマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1986-10-01',
    remark: { start: null, end: '11240幸手市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '464',
    name: '北葛飾郡　杉戸町',
    kana_name: {
      half_upper: 'ｷﾀｶﾂｼｶｸﾞﾝ ｽｷﾞﾄﾏﾁ',
      full_lower: 'キタカツシカグン　スギトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '465',
    name: '北葛飾郡　松伏町',
    kana_name: {
      half_upper: 'ｷﾀｶﾂｼｶｸﾞﾝ ﾏﾂﾌﾞｼﾏﾁ',
      full_lower: 'キタカツシカグン　マツブシマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '466',
    name: '北葛飾郡　吉川町',
    kana_name: {
      half_upper: 'ｷﾀｶﾂｼｶｸﾞﾝ ﾖｼｶﾜﾏﾁ',
      full_lower: 'キタカツシカグン　ヨシカワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1996-04-01',
    remark: { start: null, end: '11243吉川市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '467',
    name: '北葛飾郡　三郷町',
    kana_name: {
      half_upper: 'ｷﾀｶﾂｼｶｸﾞﾝ ﾐｻﾄﾏﾁ',
      full_lower: 'キタカツシカグン　ミサトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-05-03',
    remark: { start: null, end: '11237三郷市', other: null },
    note: null,
  },
  {
    pref_code: '11',
    city_code: '468',
    name: '北葛飾郡　庄和町',
    kana_name: {
      half_upper: 'ｷﾀｶﾂｼｶｸﾞﾝ ｼﾖｳﾜﾏﾁ',
      full_lower: 'キタカツシカグン　ショウワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '11214春日部市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '100',
    name: '千葉市',
    kana_name: { half_upper: 'ﾁﾊﾞｼ', full_lower: 'チバシ' },
    en: null,
    start_date: '1992-04-01',
    end_date: null,
    remark: { start: 'H04/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '101',
    name: '千葉市　中央区',
    kana_name: {
      half_upper: 'ﾁﾊﾞｼ ﾁﾕｳｵｳｸ',
      full_lower: 'チバシ　チュウオウク',
    },
    en: null,
    start_date: '1992-04-01',
    end_date: null,
    remark: { start: 'H04/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '102',
    name: '千葉市　花見川区',
    kana_name: {
      half_upper: 'ﾁﾊﾞｼ ﾊﾅﾐｶﾞﾜｸ',
      full_lower: 'チバシ　ハナミガワク',
    },
    en: null,
    start_date: '1992-04-01',
    end_date: null,
    remark: { start: 'H04/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '103',
    name: '千葉市　稲毛区',
    kana_name: { half_upper: 'ﾁﾊﾞｼ ｲﾅｹﾞｸ', full_lower: 'チバシ　イナゲク' },
    en: null,
    start_date: '1992-04-01',
    end_date: null,
    remark: { start: 'H04/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '104',
    name: '千葉市　若葉区',
    kana_name: { half_upper: 'ﾁﾊﾞｼ ﾜｶﾊﾞｸ', full_lower: 'チバシ　ワカバク' },
    en: null,
    start_date: '1992-04-01',
    end_date: null,
    remark: { start: 'H04/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '105',
    name: '千葉市　緑区',
    kana_name: { half_upper: 'ﾁﾊﾞｼ ﾐﾄﾞﾘｸ', full_lower: 'チバシ　ミドリク' },
    en: null,
    start_date: '1992-04-01',
    end_date: null,
    remark: { start: 'H04/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '106',
    name: '千葉市　美浜区',
    kana_name: { half_upper: 'ﾁﾊﾞｼ ﾐﾊﾏｸ', full_lower: 'チバシ　ミハマク' },
    en: null,
    start_date: '1992-04-01',
    end_date: null,
    remark: { start: 'H04/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '201',
    name: '千葉市',
    kana_name: { half_upper: 'ﾁﾊﾞｼ', full_lower: 'チバシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '1992-04-01',
    remark: { start: null, end: '12100千葉市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '202',
    name: '銚子市',
    kana_name: { half_upper: 'ﾁﾖｳｼｼ', full_lower: 'チョウシシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '203',
    name: '市川市',
    kana_name: { half_upper: 'ｲﾁｶﾜｼ', full_lower: 'イチカワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '204',
    name: '船橋市',
    kana_name: { half_upper: 'ﾌﾅﾊﾞｼｼ', full_lower: 'フナバシシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '205',
    name: '館山市',
    kana_name: { half_upper: 'ﾀﾃﾔﾏｼ', full_lower: 'タテヤマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '206',
    name: '木更津市',
    kana_name: { half_upper: 'ｷｻﾗﾂﾞｼ', full_lower: 'キサラヅシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '207',
    name: '松戸市',
    kana_name: { half_upper: 'ﾏﾂﾄﾞｼ', full_lower: 'マツドシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '208',
    name: '野田市',
    kana_name: { half_upper: 'ﾉﾀﾞｼ', full_lower: 'ノダシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '209',
    name: '佐原市',
    kana_name: { half_upper: 'ｻﾜﾗｼ', full_lower: 'サワラシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '12236香取市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '210',
    name: '茂原市',
    kana_name: { half_upper: 'ﾓﾊﾞﾗｼ', full_lower: 'モバラシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '211',
    name: '成田市',
    kana_name: { half_upper: 'ﾅﾘﾀｼ', full_lower: 'ナリタシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '212',
    name: '佐倉市',
    kana_name: { half_upper: 'ｻｸﾗｼ', full_lower: 'サクラシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '213',
    name: '東金市',
    kana_name: { half_upper: 'ﾄｳｶﾞﾈｼ', full_lower: 'トウガネシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '214',
    name: '八日市場市',
    kana_name: { half_upper: 'ﾖｳｶｲﾁﾊﾞｼ', full_lower: 'ヨウカイチバシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-23',
    remark: { start: null, end: '12235匝瑳市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '215',
    name: '旭市',
    kana_name: { half_upper: 'ｱｻﾋｼ', full_lower: 'アサヒシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '216',
    name: '習志野市',
    kana_name: { half_upper: 'ﾅﾗｼﾉｼ', full_lower: 'ナラシノシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '217',
    name: '柏市',
    kana_name: { half_upper: 'ｶｼﾜｼ', full_lower: 'カシワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '218',
    name: '勝浦市',
    kana_name: { half_upper: 'ｶﾂｳﾗｼ', full_lower: 'カツウラシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '219',
    name: '市原市',
    kana_name: { half_upper: 'ｲﾁﾊﾗｼ', full_lower: 'イチハラシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '220',
    name: '流山市',
    kana_name: { half_upper: 'ﾅｶﾞﾚﾔﾏｼ', full_lower: 'ナガレヤマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '221',
    name: '八千代市',
    kana_name: { half_upper: 'ﾔﾁﾖｼ', full_lower: 'ヤチヨシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '222',
    name: '我孫子市',
    kana_name: { half_upper: 'ｱﾋﾞｺｼ', full_lower: 'アビコシ' },
    en: null,
    start_date: '1970-07-01',
    end_date: null,
    remark: { start: 'S45/07/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '223',
    name: '鴨川市',
    kana_name: { half_upper: 'ｶﾓｶﾞﾜｼ', full_lower: 'カモガワシ' },
    en: null,
    start_date: '1971-03-31',
    end_date: null,
    remark: { start: 'S46/03/31新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '224',
    name: '鎌ケ谷市',
    kana_name: { half_upper: 'ｶﾏｶﾞﾔｼ', full_lower: 'カマガヤシ' },
    en: null,
    start_date: '1971-09-01',
    end_date: null,
    remark: { start: 'S46/09/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '225',
    name: '君津市',
    kana_name: { half_upper: 'ｷﾐﾂｼ', full_lower: 'キミツシ' },
    en: null,
    start_date: '1971-09-01',
    end_date: null,
    remark: { start: 'S46/09/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '226',
    name: '富津市',
    kana_name: { half_upper: 'ﾌﾂﾂｼ', full_lower: 'フッツシ' },
    en: null,
    start_date: '1971-09-01',
    end_date: null,
    remark: { start: 'S46/09/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '227',
    name: '浦安市',
    kana_name: { half_upper: 'ｳﾗﾔｽｼ', full_lower: 'ウラヤスシ' },
    en: null,
    start_date: '1981-04-01',
    end_date: null,
    remark: { start: 'S56/04/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '228',
    name: '四街道市',
    kana_name: { half_upper: 'ﾖﾂｶｲﾄﾞｳｼ', full_lower: 'ヨツカイドウシ' },
    en: null,
    start_date: '1981-04-01',
    end_date: null,
    remark: { start: 'S56/04/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '229',
    name: '袖ケ浦市',
    kana_name: { half_upper: 'ｿﾃﾞｶﾞｳﾗｼ', full_lower: 'ソデガウラシ' },
    en: null,
    start_date: '1991-04-01',
    end_date: null,
    remark: { start: 'H03/04/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '230',
    name: '八街市',
    kana_name: { half_upper: 'ﾔﾁﾏﾀｼ', full_lower: 'ヤチマタシ' },
    en: null,
    start_date: '1992-04-01',
    end_date: null,
    remark: { start: 'H04/04/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '231',
    name: '印西市',
    kana_name: { half_upper: 'ｲﾝｻﾞｲｼ', full_lower: 'インザイシ' },
    en: null,
    start_date: '1996-04-01',
    end_date: null,
    remark: { start: 'H08/04/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '232',
    name: '白井市',
    kana_name: { half_upper: 'ｼﾛｲｼ', full_lower: 'シロイシ' },
    en: null,
    start_date: '2001-04-01',
    end_date: null,
    remark: { start: 'H13/04/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '233',
    name: '富里市',
    kana_name: { half_upper: 'ﾄﾐｻﾄｼ', full_lower: 'トミサトシ' },
    en: null,
    start_date: '2002-04-01',
    end_date: null,
    remark: { start: 'H14/04/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '234',
    name: '南房総市',
    kana_name: { half_upper: 'ﾐﾅﾐﾎﾞｳｿｳｼ', full_lower: 'ミナミボウソウシ' },
    en: null,
    start_date: '2006-03-20',
    end_date: null,
    remark: { start: 'H18/03/20新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '235',
    name: '匝瑳市',
    kana_name: { half_upper: 'ｿｳｻｼ', full_lower: 'ソウサシ' },
    en: null,
    start_date: '2006-01-23',
    end_date: null,
    remark: { start: 'H18/01/23新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '236',
    name: '香取市',
    kana_name: { half_upper: 'ｶﾄﾘｼ', full_lower: 'カトリシ' },
    en: null,
    start_date: '2006-03-27',
    end_date: null,
    remark: { start: 'H18/03/27新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '237',
    name: '山武市',
    kana_name: { half_upper: 'ｻﾝﾑｼ', full_lower: 'サンムシ' },
    en: null,
    start_date: '2006-03-27',
    end_date: null,
    remark: { start: 'H18/03/27新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '238',
    name: 'いすみ市',
    kana_name: { half_upper: 'ｲｽﾐｼ', full_lower: 'イスミシ' },
    en: null,
    start_date: '2005-12-05',
    end_date: null,
    remark: { start: 'H17/12/05新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '239',
    name: '大網白里市',
    kana_name: { half_upper: 'ｵｵｱﾐｼﾗｻﾄｼ', full_lower: 'オオアミシラサトシ' },
    en: null,
    start_date: '2013-01-01',
    end_date: null,
    remark: { start: 'H25/01/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '300',
    name: '東葛飾郡',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｶﾂｼｶｸﾞﾝ',
      full_lower: 'ヒガシカツシカグン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '301',
    name: '東葛飾郡　浦安町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｶﾂｼｶｸﾞﾝ ｳﾗﾔｽﾏﾁ',
      full_lower: 'ヒガシカツシカグン　ウラヤスマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1981-04-01',
    remark: { start: null, end: '12227浦安市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '302',
    name: '東葛飾郡　鎌ヶ谷町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｶﾂｼｶｸﾞﾝ ｶﾏｶﾞﾔﾏﾁ',
      full_lower: 'ヒガシカツシカグン　カマガヤマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-09-01',
    remark: { start: null, end: '12224鎌ケ谷市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '303',
    name: '東葛飾郡　関宿町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｶﾂｼｶｸﾞﾝ ｾｷﾔﾄﾞﾏﾁ',
      full_lower: 'ヒガシカツシカグン　セキヤドマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-06-06',
    remark: { start: null, end: '12208野田市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '304',
    name: '東葛飾郡　我孫子町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｶﾂｼｶｸﾞﾝ ｱﾋﾞｺﾏﾁ',
      full_lower: 'ヒガシカツシカグン　アビコマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-07-01',
    remark: { start: null, end: '12222我孫子市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '305',
    name: '東葛飾郡　沼南町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｶﾂｼｶｸﾞﾝ ｼﾖｳﾅﾝﾏﾁ',
      full_lower: 'ヒガシカツシカグン　ショウナンマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '12217柏市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '320',
    name: '印旛郡',
    kana_name: { half_upper: 'ｲﾝﾊﾞｸﾞﾝ', full_lower: 'インバグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '321',
    name: '印旛郡　四街道町',
    kana_name: {
      half_upper: 'ｲﾝﾊﾞｸﾞﾝ ﾖﾂｶｲﾄﾞｳﾏﾁ',
      full_lower: 'インバグン　ヨツカイドウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1981-04-01',
    remark: { start: null, end: '12228四街道市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '322',
    name: '印旛郡　酒々井町',
    kana_name: {
      half_upper: 'ｲﾝﾊﾞｸﾞﾝ ｼｽｲﾏﾁ',
      full_lower: 'インバグン　シスイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '323',
    name: '印旛郡　八街町',
    kana_name: {
      half_upper: 'ｲﾝﾊﾞｸﾞﾝ ﾔﾁﾏﾀﾏﾁ',
      full_lower: 'インバグン　ヤチマタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1992-04-01',
    remark: { start: null, end: '12230八街市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '324',
    name: '印旛郡　富里町',
    kana_name: {
      half_upper: 'ｲﾝﾊﾞｸﾞﾝ ﾄﾐｻﾄﾏﾁ',
      full_lower: 'インバグン　トミサトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2002-04-01',
    remark: { start: null, end: '12233富里市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '325',
    name: '印旛郡　印旛村',
    kana_name: {
      half_upper: 'ｲﾝﾊﾞｸﾞﾝ ｲﾝﾊﾞﾑﾗ',
      full_lower: 'インバグン　インバムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-23',
    remark: { start: null, end: '12231印西市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '326',
    name: '印旛郡　白井町',
    kana_name: {
      half_upper: 'ｲﾝﾊﾞｸﾞﾝ ｼﾛｲﾏﾁ',
      full_lower: 'インバグン　シロイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2001-04-01',
    remark: { start: null, end: '12232白井市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '327',
    name: '印旛郡　印西町',
    kana_name: {
      half_upper: 'ｲﾝﾊﾞｸﾞﾝ ｲﾝｻﾞｲﾏﾁ',
      full_lower: 'インバグン　インザイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1996-04-01',
    remark: { start: null, end: '12231印西市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '328',
    name: '印旛郡　本埜村',
    kana_name: {
      half_upper: 'ｲﾝﾊﾞｸﾞﾝ ﾓﾄﾉﾑﾗ',
      full_lower: 'インバグン　モトノムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-23',
    remark: { start: null, end: '12231印西市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '329',
    name: '印旛郡　栄町',
    kana_name: {
      half_upper: 'ｲﾝﾊﾞｸﾞﾝ ｻｶｴﾏﾁ',
      full_lower: 'インバグン　サカエマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '340',
    name: '香取郡',
    kana_name: { half_upper: 'ｶﾄﾘｸﾞﾝ', full_lower: 'カトリグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '341',
    name: '香取郡　下総町',
    kana_name: {
      half_upper: 'ｶﾄﾘｸﾞﾝ ｼﾓﾌｻﾏﾁ',
      full_lower: 'カトリグン　シモフサマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '12211成田市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '342',
    name: '香取郡　神崎町',
    kana_name: {
      half_upper: 'ｶﾄﾘｸﾞﾝ ｺｳｻﾞｷﾏﾁ',
      full_lower: 'カトリグン　コウザキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '343',
    name: '香取郡　大栄町',
    kana_name: {
      half_upper: 'ｶﾄﾘｸﾞﾝ ﾀｲｴｲﾏﾁ',
      full_lower: 'カトリグン　タイエイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '12211成田市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '344',
    name: '香取郡　小見川町',
    kana_name: {
      half_upper: 'ｶﾄﾘｸﾞﾝ ｵﾐｶﾞﾜﾏﾁ',
      full_lower: 'カトリグン　オミガワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '12236香取市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '345',
    name: '香取郡　山田町',
    kana_name: {
      half_upper: 'ｶﾄﾘｸﾞﾝ ﾔﾏﾀﾞﾏﾁ',
      full_lower: 'カトリグン　ヤマダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '12236香取市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '346',
    name: '香取郡　栗源町',
    kana_name: {
      half_upper: 'ｶﾄﾘｸﾞﾝ ｸﾘﾓﾄﾏﾁ',
      full_lower: 'カトリグン　クリモトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '12236香取市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '347',
    name: '香取郡　多古町',
    kana_name: {
      half_upper: 'ｶﾄﾘｸﾞﾝ ﾀｺﾏﾁ',
      full_lower: 'カトリグン　タコマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '348',
    name: '香取郡　干潟町',
    kana_name: {
      half_upper: 'ｶﾄﾘｸﾞﾝ ﾋｶﾀﾏﾁ',
      full_lower: 'カトリグン　ヒカタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-07-01',
    remark: { start: null, end: '12215旭市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '349',
    name: '香取郡　東庄町',
    kana_name: {
      half_upper: 'ｶﾄﾘｸﾞﾝ ﾄｳﾉｼﾖｳﾏﾁ',
      full_lower: 'カトリグン　トウノショウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '360',
    name: '海上郡',
    kana_name: { half_upper: 'ｶｲｼﾞﾖｳｸﾞﾝ', full_lower: 'カイジョウグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-07-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '361',
    name: '海上郡　海上町',
    kana_name: {
      half_upper: 'ｶｲｼﾞﾖｳｸﾞﾝ ｳﾅｶﾐﾏﾁ',
      full_lower: 'カイジョウグン　ウナカミマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-07-01',
    remark: { start: null, end: '12215旭市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '362',
    name: '海上郡　飯岡町',
    kana_name: {
      half_upper: 'ｶｲｼﾞﾖｳｸﾞﾝ ｲｲｵｶﾏﾁ',
      full_lower: 'カイジョウグン　イイオカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-07-01',
    remark: { start: null, end: '12215旭市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '380',
    name: '匝瑳郡',
    kana_name: { half_upper: 'ｿｳｻｸﾞﾝ', full_lower: 'ソウサグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '381',
    name: '匝瑳郡　光町',
    kana_name: {
      half_upper: 'ｿｳｻｸﾞﾝ ﾋｶﾘﾏﾁ',
      full_lower: 'ソウサグン　ヒカリマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '12410山武郡　横芝光町', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '382',
    name: '匝瑳郡　野栄町',
    kana_name: {
      half_upper: 'ｿｳｻｸﾞﾝ ﾉｻｶﾏﾁ',
      full_lower: 'ソウサグン　ノサカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-23',
    remark: { start: null, end: '12235匝瑳市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '400',
    name: '山武郡',
    kana_name: { half_upper: 'ｻﾝﾌﾞｸﾞﾝ', full_lower: 'サンブグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '401',
    name: '山武郡　土気町',
    kana_name: {
      half_upper: 'ｻﾝﾌﾞｸﾞﾝ ﾄｹﾁﾖｳ',
      full_lower: 'サンブグン　トケチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1969-07-15',
    remark: { start: null, end: '12201千葉市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '402',
    name: '山武郡　大網白里町',
    kana_name: {
      half_upper: 'ｻﾝﾌﾞｸﾞﾝ ｵｵｱﾐｼﾗｻﾄﾏﾁ',
      full_lower: 'サンブグン　オオアミシラサトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2013-01-01',
    remark: { start: null, end: '12239大網白里市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '403',
    name: '山武郡　九十九里町',
    kana_name: {
      half_upper: 'ｻﾝﾌﾞｸﾞﾝ ｸｼﾞﾕｳｸﾘﾏﾁ',
      full_lower: 'サンブグン　クジュウクリマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '404',
    name: '山武郡　成東町',
    kana_name: {
      half_upper: 'ｻﾝﾌﾞｸﾞﾝ ﾅﾙﾄｳﾏﾁ',
      full_lower: 'サンブグン　ナルトウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '12237山武市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '405',
    name: '山武郡　山武町',
    kana_name: {
      half_upper: 'ｻﾝﾌﾞｸﾞﾝ ｻﾝﾌﾞﾏﾁ',
      full_lower: 'サンブグン　サンブマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '12237山武市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '406',
    name: '山武郡　蓮沼村',
    kana_name: {
      half_upper: 'ｻﾝﾌﾞｸﾞﾝ ﾊｽﾇﾏﾑﾗ',
      full_lower: 'サンブグン　ハスヌマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '12237山武市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '407',
    name: '山武郡　松尾町',
    kana_name: {
      half_upper: 'ｻﾝﾌﾞｸﾞﾝ ﾏﾂｵﾏﾁ',
      full_lower: 'サンブグン　マツオマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '12237山武市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '408',
    name: '山武郡　横芝町',
    kana_name: {
      half_upper: 'ｻﾝﾌﾞｸﾞﾝ ﾖｺｼﾊﾞﾏﾁ',
      full_lower: 'サンブグン　ヨコシバマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '12410山武郡　横芝光町', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '409',
    name: '山武郡　芝山町',
    kana_name: {
      half_upper: 'ｻﾝﾌﾞｸﾞﾝ ｼﾊﾞﾔﾏﾏﾁ',
      full_lower: 'サンブグン　シバヤママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '410',
    name: '山武郡　横芝光町',
    kana_name: {
      half_upper: 'ｻﾝﾌﾞｸﾞﾝ ﾖｺｼﾊﾞﾋｶﾘﾏﾁ',
      full_lower: 'サンブグン　ヨコシバヒカリマチ',
    },
    en: null,
    start_date: '2006-03-27',
    end_date: null,
    remark: { start: 'H18/03/27新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '420',
    name: '長生郡',
    kana_name: { half_upper: 'ﾁﾖｳｾｲｸﾞﾝ', full_lower: 'チョウセイグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '421',
    name: '長生郡　一宮町',
    kana_name: {
      half_upper: 'ﾁﾖｳｾｲｸﾞﾝ ｲﾁﾉﾐﾔﾏﾁ',
      full_lower: 'チョウセイグン　イチノミヤマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '422',
    name: '長生郡　睦沢町',
    kana_name: {
      half_upper: 'ﾁﾖｳｾｲｸﾞﾝ ﾑﾂｻﾞﾜﾏﾁ',
      full_lower: 'チョウセイグン　ムツザワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '423',
    name: '長生郡　長生村',
    kana_name: {
      half_upper: 'ﾁﾖｳｾｲｸﾞﾝ ﾁﾖｳｾｲﾑﾗ',
      full_lower: 'チョウセイグン　チョウセイムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '424',
    name: '長生郡　白子町',
    kana_name: {
      half_upper: 'ﾁﾖｳｾｲｸﾞﾝ ｼﾗｺﾏﾁ',
      full_lower: 'チョウセイグン　シラコマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '425',
    name: '長生郡　本納町',
    kana_name: {
      half_upper: 'ﾁﾖｳｾｲｸﾞﾝ ﾎﾝﾉｳﾏﾁ',
      full_lower: 'チョウセイグン　ホンノウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-05-01',
    remark: { start: null, end: '12210茂原市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '426',
    name: '長生郡　長柄町',
    kana_name: {
      half_upper: 'ﾁﾖｳｾｲｸﾞﾝ ﾅｶﾞﾗﾏﾁ',
      full_lower: 'チョウセイグン　ナガラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '427',
    name: '長生郡　長南町',
    kana_name: {
      half_upper: 'ﾁﾖｳｾｲｸﾞﾝ ﾁﾖｳﾅﾝﾏﾁ',
      full_lower: 'チョウセイグン　チョウナンマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '440',
    name: '夷隅郡',
    kana_name: { half_upper: 'ｲｽﾐｸﾞﾝ', full_lower: 'イスミグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '441',
    name: '夷隅郡　大多喜町',
    kana_name: {
      half_upper: 'ｲｽﾐｸﾞﾝ ｵｵﾀｷﾏﾁ',
      full_lower: 'イスミグン　オオタキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '442',
    name: '夷隅郡　夷隅町',
    kana_name: {
      half_upper: 'ｲｽﾐｸﾞﾝ ｲｽﾐﾏﾁ',
      full_lower: 'イスミグン　イスミマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-12-05',
    remark: { start: null, end: '12238いすみ市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '443',
    name: '夷隅郡　御宿町',
    kana_name: {
      half_upper: 'ｲｽﾐｸﾞﾝ ｵﾝｼﾞﾕｸﾏﾁ',
      full_lower: 'イスミグン　オンジュクマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '444',
    name: '夷隅郡　大原町',
    kana_name: {
      half_upper: 'ｲｽﾐｸﾞﾝ ｵｵﾊﾗﾏﾁ',
      full_lower: 'イスミグン　オオハラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-12-05',
    remark: { start: null, end: '12238いすみ市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '445',
    name: '夷隅郡　岬町',
    kana_name: {
      half_upper: 'ｲｽﾐｸﾞﾝ ﾐｻｷﾏﾁ',
      full_lower: 'イスミグン　ミサキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-12-05',
    remark: { start: null, end: '12238いすみ市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '460',
    name: '安房郡',
    kana_name: { half_upper: 'ｱﾜｸﾞﾝ', full_lower: 'アワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '461',
    name: '安房郡　富浦町',
    kana_name: {
      half_upper: 'ｱﾜｸﾞﾝ ﾄﾐｳﾗﾏﾁ',
      full_lower: 'アワグン　トミウラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '12234南房総市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '462',
    name: '安房郡　富山町',
    kana_name: {
      half_upper: 'ｱﾜｸﾞﾝ ﾄﾐﾔﾏﾏﾁ',
      full_lower: 'アワグン　トミヤママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '12234南房総市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '463',
    name: '安房郡　鋸南町',
    kana_name: {
      half_upper: 'ｱﾜｸﾞﾝ ｷﾖﾅﾝﾏﾁ',
      full_lower: 'アワグン　キョナンマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '464',
    name: '安房郡　三芳村',
    kana_name: {
      half_upper: 'ｱﾜｸﾞﾝ ﾐﾖｼﾑﾗ',
      full_lower: 'アワグン　ミヨシムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '12234南房総市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '465',
    name: '安房郡　白浜町',
    kana_name: {
      half_upper: 'ｱﾜｸﾞﾝ ｼﾗﾊﾏﾏﾁ',
      full_lower: 'アワグン　シラハママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '12234南房総市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '466',
    name: '安房郡　千倉町',
    kana_name: {
      half_upper: 'ｱﾜｸﾞﾝ ﾁｸﾗﾏﾁ',
      full_lower: 'アワグン　チクラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '12234南房総市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '467',
    name: '安房郡　丸山町',
    kana_name: {
      half_upper: 'ｱﾜｸﾞﾝ ﾏﾙﾔﾏﾏﾁ',
      full_lower: 'アワグン　マルヤママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '12234南房総市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '468',
    name: '安房郡　和田町',
    kana_name: {
      half_upper: 'ｱﾜｸﾞﾝ ﾜﾀﾞﾏﾁ',
      full_lower: 'アワグン　ワダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '12234南房総市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '469',
    name: '安房郡　江見町',
    kana_name: { half_upper: 'ｱﾜｸﾞﾝ ｴﾐﾏﾁ', full_lower: 'アワグン　エミマチ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-03-31',
    remark: { start: null, end: '12223鴨川市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '470',
    name: '安房郡　長狭町',
    kana_name: {
      half_upper: 'ｱﾜｸﾞﾝ ﾅｶﾞｻﾏﾁ',
      full_lower: 'アワグン　ナガサマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-03-31',
    remark: { start: null, end: '12223鴨川市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '471',
    name: '安房郡　鴨川町',
    kana_name: {
      half_upper: 'ｱﾜｸﾞﾝ ｶﾓｶﾞﾜﾏﾁ',
      full_lower: 'アワグン　カモガワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-03-31',
    remark: { start: null, end: '12223鴨川市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '472',
    name: '安房郡　天津小湊町',
    kana_name: {
      half_upper: 'ｱﾜｸﾞﾝ ｱﾏﾂｺﾐﾅﾄﾏﾁ',
      full_lower: 'アワグン　アマツコミナトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-11',
    remark: { start: null, end: '12223鴨川市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '480',
    name: '君津郡',
    kana_name: { half_upper: 'ｷﾐﾂｸﾞﾝ', full_lower: 'キミツグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '1991-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '481',
    name: '君津郡　袖ケ浦町',
    kana_name: {
      half_upper: 'ｷﾐﾂｸﾞﾝ ｿﾃﾞｶﾞｳﾗﾏﾁ',
      full_lower: 'キミツグン　ソデガウラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1991-04-01',
    remark: { start: null, end: '12229袖ケ浦市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '482',
    name: '君津郡　平川町',
    kana_name: {
      half_upper: 'ｷﾐﾂｸﾞﾝ ﾋﾗｶﾜﾏﾁ',
      full_lower: 'キミツグン　ヒラカワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-11-03',
    remark: { start: null, end: '12481君津郡　袖ケ浦町', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '483',
    name: '君津郡　富来田町',
    kana_name: {
      half_upper: 'ｷﾐﾂｸﾞﾝ ﾌｸﾀﾏﾁ',
      full_lower: 'キミツグン　フクタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-09-30',
    remark: { start: null, end: '12206木更津市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '484',
    name: '君津郡　小櫃村',
    kana_name: {
      half_upper: 'ｷﾐﾂｸﾞﾝ ｵﾋﾞﾂﾑﾗ',
      full_lower: 'キミツグン　オビツムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-09-28',
    remark: { start: null, end: '12486君津郡　君津町', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '485',
    name: '君津郡　上総町',
    kana_name: {
      half_upper: 'ｷﾐﾂｸﾞﾝ ｶｽﾞｻﾏﾁ',
      full_lower: 'キミツグン　カズサマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-09-28',
    remark: { start: null, end: '12486君津郡　君津町', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '486',
    name: '君津郡　君津町',
    kana_name: {
      half_upper: 'ｷﾐﾂｸﾞﾝ ｷﾐﾂﾏﾁ',
      full_lower: 'キミツグン　キミツマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-09-01',
    remark: { start: null, end: '12225君津市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '487',
    name: '君津郡　小糸町',
    kana_name: {
      half_upper: 'ｷﾐﾂｸﾞﾝ ｺｲﾄﾏﾁ',
      full_lower: 'キミツグン　コイトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-09-28',
    remark: { start: null, end: '12486君津郡　君津町', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '488',
    name: '君津郡　清和村',
    kana_name: {
      half_upper: 'ｷﾐﾂｸﾞﾝ ｾｲﾜﾑﾗ',
      full_lower: 'キミツグン　セイワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-09-28',
    remark: { start: null, end: '12486君津郡　君津町', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '489',
    name: '君津郡　富津町',
    kana_name: {
      half_upper: 'ｷﾐﾂｸﾞﾝ ﾌﾂﾂﾏﾁ',
      full_lower: 'キミツグン　フッツマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-09-01',
    remark: { start: null, end: '12226富津市', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '490',
    name: '君津郡　大佐和町',
    kana_name: {
      half_upper: 'ｷﾐﾂｸﾞﾝ ｵｵｻﾜﾏﾁ',
      full_lower: 'キミツグン　オオサワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-04-25',
    remark: { start: null, end: '12489君津郡　富津町', other: null },
    note: null,
  },
  {
    pref_code: '12',
    city_code: '491',
    name: '君津郡　天羽町',
    kana_name: {
      half_upper: 'ｷﾐﾂｸﾞﾝ ｱﾏﾊﾏﾁ',
      full_lower: 'キミツグン　アマハマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-04-25',
    remark: { start: null, end: '12489君津郡　富津町', other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '100',
    name: '特別区',
    kana_name: { half_upper: 'ﾄｸﾍﾞﾂｸ', full_lower: 'トクベツク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '101',
    name: '千代田区',
    kana_name: { half_upper: 'ﾁﾖﾀﾞｸ', full_lower: 'チヨダク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '102',
    name: '中央区',
    kana_name: { half_upper: 'ﾁﾕｳｵｳｸ', full_lower: 'チュウオウク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '103',
    name: '港区',
    kana_name: { half_upper: 'ﾐﾅﾄｸ', full_lower: 'ミナトク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '104',
    name: '新宿区',
    kana_name: { half_upper: 'ｼﾝｼﾞﾕｸｸ', full_lower: 'シンジュクク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '105',
    name: '文京区',
    kana_name: { half_upper: 'ﾌﾞﾝｷﾖｳｸ', full_lower: 'ブンキョウク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '106',
    name: '台東区',
    kana_name: { half_upper: 'ﾀｲﾄｳｸ', full_lower: 'タイトウク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '107',
    name: '墨田区',
    kana_name: { half_upper: 'ｽﾐﾀﾞｸ', full_lower: 'スミダク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '108',
    name: '江東区',
    kana_name: { half_upper: 'ｺｳﾄｳｸ', full_lower: 'コウトウク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '109',
    name: '品川区',
    kana_name: { half_upper: 'ｼﾅｶﾞﾜｸ', full_lower: 'シナガワク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '110',
    name: '目黒区',
    kana_name: { half_upper: 'ﾒｸﾞﾛｸ', full_lower: 'メグロク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '111',
    name: '大田区',
    kana_name: { half_upper: 'ｵｵﾀｸ', full_lower: 'オオタク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '112',
    name: '世田谷区',
    kana_name: { half_upper: 'ｾﾀｶﾞﾔｸ', full_lower: 'セタガヤク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '113',
    name: '渋谷区',
    kana_name: { half_upper: 'ｼﾌﾞﾔｸ', full_lower: 'シブヤク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '114',
    name: '中野区',
    kana_name: { half_upper: 'ﾅｶﾉｸ', full_lower: 'ナカノク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '115',
    name: '杉並区',
    kana_name: { half_upper: 'ｽｷﾞﾅﾐｸ', full_lower: 'スギナミク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '116',
    name: '豊島区',
    kana_name: { half_upper: 'ﾄｼﾏｸ', full_lower: 'トシマク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '117',
    name: '北区',
    kana_name: { half_upper: 'ｷﾀｸ', full_lower: 'キタク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '118',
    name: '荒川区',
    kana_name: { half_upper: 'ｱﾗｶﾜｸ', full_lower: 'アラカワク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '119',
    name: '板橋区',
    kana_name: { half_upper: 'ｲﾀﾊﾞｼｸ', full_lower: 'イタバシク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '120',
    name: '練馬区',
    kana_name: { half_upper: 'ﾈﾘﾏｸ', full_lower: 'ネリマク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '121',
    name: '足立区',
    kana_name: { half_upper: 'ｱﾀﾞﾁｸ', full_lower: 'アダチク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '122',
    name: '葛飾区',
    kana_name: { half_upper: 'ｶﾂｼｶｸ', full_lower: 'カツシカク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '123',
    name: '江戸川区',
    kana_name: { half_upper: 'ｴﾄﾞｶﾞﾜｸ', full_lower: 'エドガワク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '201',
    name: '八王子市',
    kana_name: { half_upper: 'ﾊﾁｵｳｼﾞｼ', full_lower: 'ハチオウジシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '202',
    name: '立川市',
    kana_name: { half_upper: 'ﾀﾁｶﾜｼ', full_lower: 'タチカワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '203',
    name: '武蔵野市',
    kana_name: { half_upper: 'ﾑｻｼﾉｼ', full_lower: 'ムサシノシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '204',
    name: '三鷹市',
    kana_name: { half_upper: 'ﾐﾀｶｼ', full_lower: 'ミタカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '205',
    name: '青梅市',
    kana_name: { half_upper: 'ｵｳﾒｼ', full_lower: 'オウメシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '206',
    name: '府中市',
    kana_name: { half_upper: 'ﾌﾁﾕｳｼ', full_lower: 'フチュウシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '207',
    name: '昭島市',
    kana_name: { half_upper: 'ｱｷｼﾏｼ', full_lower: 'アキシマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '208',
    name: '調布市',
    kana_name: { half_upper: 'ﾁﾖｳﾌｼ', full_lower: 'チョウフシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '209',
    name: '町田市',
    kana_name: { half_upper: 'ﾏﾁﾀﾞｼ', full_lower: 'マチダシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '210',
    name: '小金井市',
    kana_name: { half_upper: 'ｺｶﾞﾈｲｼ', full_lower: 'コガネイシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '211',
    name: '小平市',
    kana_name: { half_upper: 'ｺﾀﾞｲﾗｼ', full_lower: 'コダイラシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '212',
    name: '日野市',
    kana_name: { half_upper: 'ﾋﾉｼ', full_lower: 'ヒノシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '213',
    name: '東村山市',
    kana_name: { half_upper: 'ﾋｶﾞｼﾑﾗﾔﾏｼ', full_lower: 'ヒガシムラヤマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '214',
    name: '国分寺市',
    kana_name: { half_upper: 'ｺｸﾌﾞﾝｼﾞｼ', full_lower: 'コクブンジシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '215',
    name: '国立市',
    kana_name: { half_upper: 'ｸﾆﾀﾁｼ', full_lower: 'クニタチシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '216',
    name: '田無市',
    kana_name: { half_upper: 'ﾀﾅｼｼ', full_lower: 'タナシシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2001-01-21',
    remark: { start: null, end: '13229西東京市', other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '217',
    name: '保谷市',
    kana_name: { half_upper: 'ﾎｳﾔｼ', full_lower: 'ホウヤシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2001-01-21',
    remark: { start: null, end: '13229西東京市', other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '218',
    name: '福生市',
    kana_name: { half_upper: 'ﾌﾂｻｼ', full_lower: 'フッサシ' },
    en: null,
    start_date: '1970-07-01',
    end_date: null,
    remark: { start: 'S45/07/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '219',
    name: '狛江市',
    kana_name: { half_upper: 'ｺﾏｴｼ', full_lower: 'コマエシ' },
    en: null,
    start_date: '1970-10-01',
    end_date: null,
    remark: { start: 'S45/10/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '220',
    name: '東大和市',
    kana_name: { half_upper: 'ﾋｶﾞｼﾔﾏﾄｼ', full_lower: 'ヒガシヤマトシ' },
    en: null,
    start_date: '1970-10-01',
    end_date: null,
    remark: { start: 'S45/10/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '221',
    name: '清瀬市',
    kana_name: { half_upper: 'ｷﾖｾｼ', full_lower: 'キヨセシ' },
    en: null,
    start_date: '1970-10-01',
    end_date: null,
    remark: { start: 'S45/10/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '222',
    name: '東久留米市',
    kana_name: { half_upper: 'ﾋｶﾞｼｸﾙﾒｼ', full_lower: 'ヒガシクルメシ' },
    en: null,
    start_date: '1970-10-01',
    end_date: null,
    remark: { start: 'S45/10/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '223',
    name: '武蔵村山市',
    kana_name: { half_upper: 'ﾑｻｼﾑﾗﾔﾏｼ', full_lower: 'ムサシムラヤマシ' },
    en: null,
    start_date: '1970-11-03',
    end_date: null,
    remark: { start: 'S45/11/03市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '224',
    name: '多摩市',
    kana_name: { half_upper: 'ﾀﾏｼ', full_lower: 'タマシ' },
    en: null,
    start_date: '1971-11-01',
    end_date: null,
    remark: { start: 'S46/11/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '225',
    name: '稲城市',
    kana_name: { half_upper: 'ｲﾅｷﾞｼ', full_lower: 'イナギシ' },
    en: null,
    start_date: '1971-11-01',
    end_date: null,
    remark: { start: 'S46/11/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '226',
    name: '秋川市',
    kana_name: { half_upper: 'ｱｷｶﾞﾜｼ', full_lower: 'アキガワシ' },
    en: null,
    start_date: '1972-05-05',
    end_date: '1995-09-01',
    remark: { start: 'S47/05/05市制', end: '13228あきる野市', other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '227',
    name: '羽村市',
    kana_name: { half_upper: 'ﾊﾑﾗｼ', full_lower: 'ハムラシ' },
    en: null,
    start_date: '1991-11-01',
    end_date: null,
    remark: { start: 'H03/11/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '228',
    name: 'あきる野市',
    kana_name: { half_upper: 'ｱｷﾙﾉｼ', full_lower: 'アキルノシ' },
    en: null,
    start_date: '1995-09-01',
    end_date: null,
    remark: { start: 'H07/09/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '229',
    name: '西東京市',
    kana_name: { half_upper: 'ﾆｼﾄｳｷﾖｳｼ', full_lower: 'ニシトウキョウシ' },
    en: null,
    start_date: '2001-01-21',
    end_date: null,
    remark: { start: 'H13/01/21新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '300',
    name: '西多摩郡',
    kana_name: { half_upper: 'ﾆｼﾀﾏｸﾞﾝ', full_lower: 'ニシタマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '301',
    name: '西多摩郡　福生町',
    kana_name: {
      half_upper: 'ﾆｼﾀﾏｸﾞﾝ ﾌﾂｻﾏﾁ',
      full_lower: 'ニシタマグン　フッサマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-07-01',
    remark: { start: null, end: '13218福生市', other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '302',
    name: '西多摩郡　羽村町',
    kana_name: {
      half_upper: 'ﾆｼﾀﾏｸﾞﾝ ﾊﾑﾗﾏﾁ',
      full_lower: 'ニシタマグン　ハムラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1991-11-01',
    remark: { start: null, end: '13227羽村市', other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '303',
    name: '西多摩郡　瑞穂町',
    kana_name: {
      half_upper: 'ﾆｼﾀﾏｸﾞﾝ ﾐｽﾞﾎﾏﾁ',
      full_lower: 'ニシタマグン　ミズホマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '304',
    name: '西多摩郡　秋多町',
    kana_name: {
      half_upper: 'ﾆｼﾀﾏｸﾞﾝ ｱｷﾀﾏﾁ',
      full_lower: 'ニシタマグン　アキタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-05-05',
    remark: { start: null, end: '13226秋川市', other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '305',
    name: '西多摩郡　日の出町',
    kana_name: {
      half_upper: 'ﾆｼﾀﾏｸﾞﾝ ﾋﾉﾃﾞﾏﾁ',
      full_lower: 'ニシタマグン　ヒノデマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '306',
    name: '西多摩郡　五日市町',
    kana_name: {
      half_upper: 'ﾆｼﾀﾏｸﾞﾝ ｲﾂｶｲﾁﾏﾁ',
      full_lower: 'ニシタマグン　イツカイチマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1995-09-01',
    remark: { start: null, end: '13228あきる野市', other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '307',
    name: '西多摩郡　檜原村',
    kana_name: {
      half_upper: 'ﾆｼﾀﾏｸﾞﾝ ﾋﾉﾊﾗﾑﾗ',
      full_lower: 'ニシタマグン　ヒノハラムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '308',
    name: '西多摩郡　奥多摩町',
    kana_name: {
      half_upper: 'ﾆｼﾀﾏｸﾞﾝ ｵｸﾀﾏﾏﾁ',
      full_lower: 'ニシタマグン　オクタママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '320',
    name: '南多摩郡',
    kana_name: { half_upper: 'ﾐﾅﾐﾀﾏｸﾞﾝ', full_lower: 'ミナミタマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-11-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '321',
    name: '南多摩郡　多摩町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾀﾏｸﾞﾝ ﾀﾏﾏﾁ',
      full_lower: 'ミナミタマグン　タママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-11-01',
    remark: { start: null, end: '13224多摩市', other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '322',
    name: '南多摩郡　稲城町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾀﾏｸﾞﾝ ｲﾅｷﾞﾏﾁ',
      full_lower: 'ミナミタマグン　イナギマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-11-01',
    remark: { start: null, end: '13225稲城市', other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '340',
    name: '北多摩郡',
    kana_name: { half_upper: 'ｷﾀﾀﾏｸﾞﾝ', full_lower: 'キタタマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-11-03',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '341',
    name: '北多摩郡　狛江町',
    kana_name: {
      half_upper: 'ｷﾀﾀﾏｸﾞﾝ ｺﾏｴﾏﾁ',
      full_lower: 'キタタマグン　コマエマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-10-01',
    remark: { start: null, end: '13219狛江市', other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '342',
    name: '北多摩郡　村山町',
    kana_name: {
      half_upper: 'ｷﾀﾀﾏｸﾞﾝ ﾑﾗﾔﾏﾏﾁ',
      full_lower: 'キタタマグン　ムラヤママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-11-03',
    remark: { start: null, end: '13223武蔵村山市', other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '343',
    name: '北多摩郡　大和町',
    kana_name: {
      half_upper: 'ｷﾀﾀﾏｸﾞﾝ ﾔﾏﾄﾏﾁ',
      full_lower: 'キタタマグン　ヤマトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-10-01',
    remark: { start: null, end: '13220東大和市', other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '344',
    name: '北多摩郡　清瀬町',
    kana_name: {
      half_upper: 'ｷﾀﾀﾏｸﾞﾝ ｷﾖｾﾏﾁ',
      full_lower: 'キタタマグン　キヨセマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-10-01',
    remark: { start: null, end: '13221清瀬市', other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '345',
    name: '北多摩郡　久留米町',
    kana_name: {
      half_upper: 'ｷﾀﾀﾏｸﾞﾝ ｸﾙﾒﾏﾁ',
      full_lower: 'キタタマグン　クルメマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-10-01',
    remark: { start: null, end: '13222東久留米市', other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '360',
    name: '大島支庁',
    kana_name: { half_upper: 'ｵｵｼﾏｼﾁﾖｳ', full_lower: 'オオシマシチョウ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '361',
    name: '大島町',
    kana_name: { half_upper: 'ｵｵｼﾏﾏﾁ', full_lower: 'オオシママチ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '362',
    name: '利島村',
    kana_name: { half_upper: 'ﾄｼﾏﾑﾗ', full_lower: 'トシマムラ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '363',
    name: '新島村',
    kana_name: { half_upper: 'ﾆｲｼﾞﾏﾑﾗ', full_lower: 'ニイジマムラ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '364',
    name: '神津島村',
    kana_name: { half_upper: 'ｺｳﾂﾞｼﾏﾑﾗ', full_lower: 'コウヅシマムラ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '380',
    name: '三宅支庁',
    kana_name: { half_upper: 'ﾐﾔｹｼﾁﾖｳ', full_lower: 'ミヤケシチョウ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '381',
    name: '三宅村',
    kana_name: { half_upper: 'ﾐﾔｹﾑﾗ', full_lower: 'ミヤケムラ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '382',
    name: '御蔵島村',
    kana_name: { half_upper: 'ﾐｸﾗｼﾞﾏﾑﾗ', full_lower: 'ミクラジマムラ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '400',
    name: '八丈支庁',
    kana_name: { half_upper: 'ﾊﾁｼﾞﾖｳｼﾁﾖｳ', full_lower: 'ハチジョウシチョウ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '401',
    name: '八丈町',
    kana_name: { half_upper: 'ﾊﾁｼﾞﾖｳﾏﾁ', full_lower: 'ハチジョウマチ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '402',
    name: '青ヶ島村',
    kana_name: { half_upper: 'ｱｵｶﾞｼﾏﾑﾗ', full_lower: 'アオガシマムラ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '420',
    name: '小笠原支庁',
    kana_name: { half_upper: 'ｵｶﾞｻﾜﾗｼﾁﾖｳ', full_lower: 'オガサワシチョウ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '13',
    city_code: '421',
    name: '小笠原村',
    kana_name: { half_upper: 'ｵｶﾞｻﾜﾗﾑﾗ', full_lower: 'オガサワラムラ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '100',
    name: '横浜市',
    kana_name: { half_upper: 'ﾖｺﾊﾏｼ', full_lower: 'ヨコハマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '101',
    name: '横浜市　鶴見区',
    kana_name: {
      half_upper: 'ﾖｺﾊﾏｼ ﾂﾙﾐｸ',
      full_lower: 'ヨコハマシ　ツルミク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '102',
    name: '横浜市　神奈川区',
    kana_name: {
      half_upper: 'ﾖｺﾊﾏｼ ｶﾅｶﾞﾜｸ',
      full_lower: 'ヨコハマシ　カナガワク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '103',
    name: '横浜市　西区',
    kana_name: { half_upper: 'ﾖｺﾊﾏｼ ﾆｼｸ', full_lower: 'ヨコハマシ　ニシク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '104',
    name: '横浜市　中区',
    kana_name: { half_upper: 'ﾖｺﾊﾏｼ ﾅｶｸ', full_lower: 'ヨコハマシ　ナカク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '105',
    name: '横浜市　南区',
    kana_name: {
      half_upper: 'ﾖｺﾊﾏｼ ﾐﾅﾐｸ',
      full_lower: 'ヨコハマシ　ミナミク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '106',
    name: '横浜市　保土ケ谷区',
    kana_name: {
      half_upper: 'ﾖｺﾊﾏｼ ﾎﾄﾞｶﾞﾔｸ',
      full_lower: 'ヨコハマシ　ホドガヤク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '107',
    name: '横浜市　磯子区',
    kana_name: {
      half_upper: 'ﾖｺﾊﾏｼ ｲｿｺﾞｸ',
      full_lower: 'ヨコハマシ　イソゴク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '108',
    name: '横浜市　金沢区',
    kana_name: {
      half_upper: 'ﾖｺﾊﾏｼ ｶﾅｻﾞﾜｸ',
      full_lower: 'ヨコハマシ　カナザワク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '109',
    name: '横浜市　港北区',
    kana_name: {
      half_upper: 'ﾖｺﾊﾏｼ ｺｳﾎｸｸ',
      full_lower: 'ヨコハマシ　コウホクク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '110',
    name: '横浜市　戸塚区',
    kana_name: {
      half_upper: 'ﾖｺﾊﾏｼ ﾄﾂｶｸ',
      full_lower: 'ヨコハマシ　トツカク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '111',
    name: '横浜市　港南区',
    kana_name: {
      half_upper: 'ﾖｺﾊﾏｼ ｺｳﾅﾝｸ',
      full_lower: 'ヨコハマシ　コウナンク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '112',
    name: '横浜市　旭区',
    kana_name: {
      half_upper: 'ﾖｺﾊﾏｼ ｱｻﾋｸ',
      full_lower: 'ヨコハマシ　アサヒク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '113',
    name: '横浜市　緑区',
    kana_name: {
      half_upper: 'ﾖｺﾊﾏｼ ﾐﾄﾞﾘｸ',
      full_lower: 'ヨコハマシ　ミドリク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '114',
    name: '横浜市　瀬谷区',
    kana_name: { half_upper: 'ﾖｺﾊﾏｼ ｾﾔｸ', full_lower: 'ヨコハマシ　セヤク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '115',
    name: '横浜市　栄区',
    kana_name: {
      half_upper: 'ﾖｺﾊﾏｼ ｻｶｴｸ',
      full_lower: 'ヨコハマシ　サカエク',
    },
    en: null,
    start_date: '1986-11-03',
    end_date: null,
    remark: {
      start: 'S61/11/03戸塚区(14110)から分離',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '116',
    name: '横浜市　泉区',
    kana_name: {
      half_upper: 'ﾖｺﾊﾏｼ ｲｽﾞﾐｸ',
      full_lower: 'ヨコハマシ　イズミク',
    },
    en: null,
    start_date: '1986-11-03',
    end_date: null,
    remark: {
      start: 'S61/11/03戸塚区(14110)から分離',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '117',
    name: '横浜市　青葉区',
    kana_name: {
      half_upper: 'ﾖｺﾊﾏｼ ｱｵﾊﾞｸ',
      full_lower: 'ヨコハマシ　アオバク',
    },
    en: null,
    start_date: '1994-11-06',
    end_date: null,
    remark: {
      start: 'H06/11/06港北区(14109)と緑区(14113)から分離',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '118',
    name: '横浜市　都筑区',
    kana_name: {
      half_upper: 'ﾖｺﾊﾏｼ ﾂﾂﾞｷｸ',
      full_lower: 'ヨコハマシ　ツヅキク',
    },
    en: null,
    start_date: '1994-11-06',
    end_date: null,
    remark: {
      start: 'H06/11/06港北区(14109)と緑区(14113)から分離',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '130',
    name: '川崎市',
    kana_name: { half_upper: 'ｶﾜｻｷｼ', full_lower: 'カワサキシ' },
    en: null,
    start_date: '1972-04-01',
    end_date: null,
    remark: { start: 'S47/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '131',
    name: '川崎市　川崎区',
    kana_name: {
      half_upper: 'ｶﾜｻｷｼ ｶﾜｻｷｸ',
      full_lower: 'カワサキシ　カワサキク',
    },
    en: null,
    start_date: '1972-04-01',
    end_date: null,
    remark: { start: 'S47/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '132',
    name: '川崎市　幸区',
    kana_name: {
      half_upper: 'ｶﾜｻｷｼ ｻｲﾜｲｸ',
      full_lower: 'カワサキシ　サイワイク',
    },
    en: null,
    start_date: '1972-04-01',
    end_date: null,
    remark: { start: 'S47/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '133',
    name: '川崎市　中原区',
    kana_name: {
      half_upper: 'ｶﾜｻｷｼ ﾅｶﾊﾗｸ',
      full_lower: 'カワサキシ　ナカハラク',
    },
    en: null,
    start_date: '1972-04-01',
    end_date: null,
    remark: { start: 'S47/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '134',
    name: '川崎市　高津区',
    kana_name: {
      half_upper: 'ｶﾜｻｷｼ ﾀｶﾂｸ',
      full_lower: 'カワサキシ　タカツク',
    },
    en: null,
    start_date: '1972-04-01',
    end_date: null,
    remark: { start: 'S47/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '135',
    name: '川崎市　多摩区',
    kana_name: { half_upper: 'ｶﾜｻｷｼ ﾀﾏｸ', full_lower: 'カワサキシ　タマク' },
    en: null,
    start_date: '1972-04-01',
    end_date: null,
    remark: { start: 'S47/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '136',
    name: '川崎市　宮前区',
    kana_name: {
      half_upper: 'ｶﾜｻｷｼ ﾐﾔﾏｴｸ',
      full_lower: 'カワサキシ　ミヤマエク',
    },
    en: null,
    start_date: '1982-07-01',
    end_date: null,
    remark: {
      start: 'S57/07/01高津区(14134)から分離',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '137',
    name: '川崎市　麻生区',
    kana_name: {
      half_upper: 'ｶﾜｻｷｼ ｱｻｵｸ',
      full_lower: 'カワサキシ　アサオク',
    },
    en: null,
    start_date: '1982-07-01',
    end_date: null,
    remark: {
      start: 'S57/07/01多摩区(14135)から分離',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '150',
    name: '相模原市',
    kana_name: { half_upper: 'ｻｶﾞﾐﾊﾗｼ', full_lower: 'サガミハラシ' },
    en: null,
    start_date: '2010-04-01',
    end_date: null,
    remark: { start: 'H22/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '151',
    name: '相模原市　緑区',
    kana_name: {
      half_upper: 'ｻｶﾞﾐﾊﾗｼ ﾐﾄﾞﾘｸ',
      full_lower: 'サガミハラシ　ミドリク',
    },
    en: null,
    start_date: '2010-04-01',
    end_date: null,
    remark: { start: 'H22/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '152',
    name: '相模原市　中央区',
    kana_name: {
      half_upper: 'ｻｶﾞﾐﾊﾗｼ ﾁﾕｳｵｳｸ',
      full_lower: 'サガミハラシ　チュウオウク',
    },
    en: null,
    start_date: '2010-04-01',
    end_date: null,
    remark: { start: 'H22/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '153',
    name: '相模原市　南区',
    kana_name: {
      half_upper: 'ｻｶﾞﾐﾊﾗｼ ﾐﾅﾐｸ',
      full_lower: 'サガミハラシ　ミナミク',
    },
    en: null,
    start_date: '2010-04-01',
    end_date: null,
    remark: { start: 'H22/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '201',
    name: '横須賀市',
    kana_name: { half_upper: 'ﾖｺｽｶｼ', full_lower: 'ヨコスカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '202',
    name: '川崎市',
    kana_name: { half_upper: 'ｶﾜｻｷｼ', full_lower: 'カワサキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-04-01',
    remark: { start: null, end: '14130川崎市', other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '203',
    name: '平塚市',
    kana_name: { half_upper: 'ﾋﾗﾂｶｼ', full_lower: 'ヒラツカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '204',
    name: '鎌倉市',
    kana_name: { half_upper: 'ｶﾏｸﾗｼ', full_lower: 'カマクラシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '205',
    name: '藤沢市',
    kana_name: { half_upper: 'ﾌｼﾞｻﾜｼ', full_lower: 'フジサワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '206',
    name: '小田原市',
    kana_name: { half_upper: 'ｵﾀﾞﾜﾗｼ', full_lower: 'オダワラシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '207',
    name: '茅ヶ崎市',
    kana_name: { half_upper: 'ﾁｶﾞｻｷｼ', full_lower: 'チガサキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '208',
    name: '逗子市',
    kana_name: { half_upper: 'ｽﾞｼｼ', full_lower: 'ズシシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '209',
    name: '相模原市',
    kana_name: { half_upper: 'ｻｶﾞﾐﾊﾗｼ', full_lower: 'サガミハラシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-04-01',
    remark: { start: null, end: '14150相模原市', other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '210',
    name: '三浦市',
    kana_name: { half_upper: 'ﾐｳﾗｼ', full_lower: 'ミウラシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '211',
    name: '秦野市',
    kana_name: { half_upper: 'ﾊﾀﾞﾉｼ', full_lower: 'ハダノシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '212',
    name: '厚木市',
    kana_name: { half_upper: 'ｱﾂｷﾞｼ', full_lower: 'アツギシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '213',
    name: '大和市',
    kana_name: { half_upper: 'ﾔﾏﾄｼ', full_lower: 'ヤマトシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '214',
    name: '伊勢原市',
    kana_name: { half_upper: 'ｲｾﾊﾗｼ', full_lower: 'イセハラシ' },
    en: null,
    start_date: '1971-03-01',
    end_date: null,
    remark: { start: 'S46/03/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '215',
    name: '海老名市',
    kana_name: { half_upper: 'ｴﾋﾞﾅｼ', full_lower: 'エビナシ' },
    en: null,
    start_date: '1971-11-01',
    end_date: null,
    remark: { start: 'S46/11/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '216',
    name: '座間市',
    kana_name: { half_upper: 'ｻﾞﾏｼ', full_lower: 'ザマシ' },
    en: null,
    start_date: '1971-11-01',
    end_date: null,
    remark: { start: 'S46/11/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '217',
    name: '南足柄市',
    kana_name: { half_upper: 'ﾐﾅﾐｱｼｶﾞﾗｼ', full_lower: 'ミナミアシガラシ' },
    en: null,
    start_date: '1972-04-01',
    end_date: null,
    remark: { start: 'S47/04/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '218',
    name: '綾瀬市',
    kana_name: { half_upper: 'ｱﾔｾｼ', full_lower: 'アヤセシ' },
    en: null,
    start_date: '1978-11-01',
    end_date: null,
    remark: { start: 'S53/11/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '300',
    name: '三浦郡',
    kana_name: { half_upper: 'ﾐｳﾗｸﾞﾝ', full_lower: 'ミウラグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '301',
    name: '三浦郡　葉山町',
    kana_name: {
      half_upper: 'ﾐｳﾗｸﾞﾝ ﾊﾔﾏﾏﾁ',
      full_lower: 'ミウラグン　ハヤママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '320',
    name: '高座郡',
    kana_name: { half_upper: 'ｺｳｻﾞｸﾞﾝ', full_lower: 'コウザグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '321',
    name: '高座郡　寒川町',
    kana_name: {
      half_upper: 'ｺｳｻﾞｸﾞﾝ ｻﾑｶﾜﾏﾁ',
      full_lower: 'コウザグン　サムカワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '322',
    name: '高座郡　海老名町',
    kana_name: {
      half_upper: 'ｺｳｻﾞｸﾞﾝ ｴﾋﾞﾅﾏﾁ',
      full_lower: 'コウザグン　エビナマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-11-01',
    remark: { start: null, end: '14215海老名市', other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '323',
    name: '高座郡　座間町',
    kana_name: {
      half_upper: 'ｺｳｻﾞｸﾞﾝ ｻﾞﾏﾏﾁ',
      full_lower: 'コウザグン　ザママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-11-01',
    remark: { start: null, end: '14216座間市', other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '324',
    name: '高座郡　綾瀬町',
    kana_name: {
      half_upper: 'ｺｳｻﾞｸﾞﾝ ｱﾔｾﾏﾁ',
      full_lower: 'コウザグン　アヤセマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1978-11-01',
    remark: { start: null, end: '14218綾瀬市', other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '340',
    name: '中郡',
    kana_name: { half_upper: 'ﾅｶｸﾞﾝ', full_lower: 'ナカグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '341',
    name: '中郡　大磯町',
    kana_name: {
      half_upper: 'ﾅｶｸﾞﾝ ｵｵｲｿﾏﾁ',
      full_lower: 'ナカグン　オオイソマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '342',
    name: '中郡　二宮町',
    kana_name: {
      half_upper: 'ﾅｶｸﾞﾝ ﾆﾉﾐﾔﾏﾁ',
      full_lower: 'ナカグン　ニノミヤマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '343',
    name: '中郡　伊勢原町',
    kana_name: {
      half_upper: 'ﾅｶｸﾞﾝ ｲｾﾊﾗﾏﾁ',
      full_lower: 'ナカグン　イセハラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-03-01',
    remark: { start: null, end: '14214伊勢原市', other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '360',
    name: '足柄上郡',
    kana_name: { half_upper: 'ｱｼｶﾞﾗｶﾐｸﾞﾝ', full_lower: 'アシガラカミグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '361',
    name: '足柄上郡　中井町',
    kana_name: {
      half_upper: 'ｱｼｶﾞﾗｶﾐｸﾞﾝ ﾅｶｲﾏﾁ',
      full_lower: 'アシガラカミグン　ナカイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '362',
    name: '足柄上郡　大井町',
    kana_name: {
      half_upper: 'ｱｼｶﾞﾗｶﾐｸﾞﾝ ｵｵｲﾏﾁ',
      full_lower: 'アシガラカミグン　オオイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '363',
    name: '足柄上郡　松田町',
    kana_name: {
      half_upper: 'ｱｼｶﾞﾗｶﾐｸﾞﾝ ﾏﾂﾀﾞﾏﾁ',
      full_lower: 'アシガラカミグン　マツダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '364',
    name: '足柄上郡　山北町',
    kana_name: {
      half_upper: 'ｱｼｶﾞﾗｶﾐｸﾞﾝ ﾔﾏｷﾀﾏﾁ',
      full_lower: 'アシガラカミグン　ヤマキタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '365',
    name: '足柄上郡　南足柄町',
    kana_name: {
      half_upper: 'ｱｼｶﾞﾗｶﾐｸﾞﾝ ﾐﾅﾐｱｼｶﾞﾗﾏﾁ',
      full_lower: 'アシガラカミグン　ミナミアシガラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-04-01',
    remark: { start: null, end: '14217南足柄市', other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '366',
    name: '足柄上郡　開成町',
    kana_name: {
      half_upper: 'ｱｼｶﾞﾗｶﾐｸﾞﾝ ｶｲｾｲﾏﾁ',
      full_lower: 'アシガラカミグン　カイセイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '380',
    name: '足柄下郡',
    kana_name: { half_upper: 'ｱｼｶﾞﾗｼﾓｸﾞﾝ', full_lower: 'アシガラシモグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '381',
    name: '足柄下郡　橘町',
    kana_name: {
      half_upper: 'ｱｼｶﾞﾗｼﾓｸﾞﾝ ﾀﾁﾊﾞﾅﾏﾁ',
      full_lower: 'アシガラシモグン　タチバナマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-04-01',
    remark: { start: null, end: '14206小田原市', other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '382',
    name: '足柄下郡　箱根町',
    kana_name: {
      half_upper: 'ｱｼｶﾞﾗｼﾓｸﾞﾝ ﾊｺﾈﾏﾁ',
      full_lower: 'アシガラシモグン　ハコネマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '383',
    name: '足柄下郡　真鶴町',
    kana_name: {
      half_upper: 'ｱｼｶﾞﾗｼﾓｸﾞﾝ ﾏﾅﾂﾞﾙﾏﾁ',
      full_lower: 'アシガラシモグン　マナヅルマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '384',
    name: '足柄下郡　湯河原町',
    kana_name: {
      half_upper: 'ｱｼｶﾞﾗｼﾓｸﾞﾝ ﾕｶﾞﾜﾗﾏﾁ',
      full_lower: 'アシガラシモグン　ユガワラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '400',
    name: '愛甲郡',
    kana_name: { half_upper: 'ｱｲｺｳｸﾞﾝ', full_lower: 'アイコウグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '401',
    name: '愛甲郡　愛川町',
    kana_name: {
      half_upper: 'ｱｲｺｳｸﾞﾝ ｱｲｶﾜﾏﾁ',
      full_lower: 'アイコウグン　アイカワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '402',
    name: '愛甲郡　清川村',
    kana_name: {
      half_upper: 'ｱｲｺｳｸﾞﾝ ｷﾖｶﾜﾑﾗ',
      full_lower: 'アイコウグン　キヨカワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '420',
    name: '津久井郡',
    kana_name: { half_upper: 'ﾂｸｲｸﾞﾝ', full_lower: 'ツクイグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-03-11',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '421',
    name: '津久井郡　城山町',
    kana_name: {
      half_upper: 'ﾂｸｲｸﾞﾝ ｼﾛﾔﾏﾏﾁ',
      full_lower: 'ツクイグン　シロヤママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-03-11',
    remark: { start: null, end: '14209相模原市', other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '422',
    name: '津久井郡　津久井町',
    kana_name: {
      half_upper: 'ﾂｸｲｸﾞﾝ ﾂｸｲﾏﾁ',
      full_lower: 'ツクイグン　ツクイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '14209相模原市', other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '423',
    name: '津久井郡　相模湖町',
    kana_name: {
      half_upper: 'ﾂｸｲｸﾞﾝ ｻｶﾞﾐｺﾏﾁ',
      full_lower: 'ツクイグン　サガミコマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '14209相模原市', other: null },
    note: null,
  },
  {
    pref_code: '14',
    city_code: '424',
    name: '津久井郡　藤野町',
    kana_name: {
      half_upper: 'ﾂｸｲｸﾞﾝ ﾌｼﾞﾉﾏﾁ',
      full_lower: 'ツクイグン　フジノマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-03-11',
    remark: { start: null, end: '14209相模原市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '100',
    name: '新潟市',
    kana_name: { half_upper: 'ﾆｲｶﾞﾀｼ', full_lower: 'ニイガタシ' },
    en: null,
    start_date: '2007-04-01',
    end_date: null,
    remark: { start: 'H19/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '101',
    name: '新潟市　北区',
    kana_name: { half_upper: 'ﾆｲｶﾞﾀｼ ｷﾀｸ', full_lower: 'ニイガタシ　キタク' },
    en: null,
    start_date: '2007-04-01',
    end_date: null,
    remark: { start: 'H19/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '102',
    name: '新潟市　東区',
    kana_name: {
      half_upper: 'ﾆｲｶﾞﾀｼ ﾋｶﾞｼｸ',
      full_lower: 'ニイガタシ　ヒガシク',
    },
    en: null,
    start_date: '2007-04-01',
    end_date: null,
    remark: { start: 'H19/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '103',
    name: '新潟市　中央区',
    kana_name: {
      half_upper: 'ﾆｲｶﾞﾀｼ ﾁﾕｳｵｳｸ',
      full_lower: 'ニイガタシ　チュウオウク',
    },
    en: null,
    start_date: '2007-04-01',
    end_date: null,
    remark: { start: 'H19/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '104',
    name: '新潟市　江南区',
    kana_name: {
      half_upper: 'ﾆｲｶﾞﾀｼ ｺｳﾅﾝｸ',
      full_lower: 'ニイガタシ　コウナンク',
    },
    en: null,
    start_date: '2007-04-01',
    end_date: null,
    remark: { start: 'H19/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '105',
    name: '新潟市　秋葉区',
    kana_name: {
      half_upper: 'ﾆｲｶﾞﾀｼ ｱｷﾊｸ',
      full_lower: 'ニイガタシ　アキハク',
    },
    en: null,
    start_date: '2007-04-01',
    end_date: null,
    remark: { start: 'H19/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '106',
    name: '新潟市　南区',
    kana_name: {
      half_upper: 'ﾆｲｶﾞﾀｼ ﾐﾅﾐｸ',
      full_lower: 'ニイガタシ　ミナミク',
    },
    en: null,
    start_date: '2007-04-01',
    end_date: null,
    remark: { start: 'H19/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '107',
    name: '新潟市　西区',
    kana_name: { half_upper: 'ﾆｲｶﾞﾀｼ ﾆｼｸ', full_lower: 'ニイガタシ　ニシク' },
    en: null,
    start_date: '2007-04-01',
    end_date: null,
    remark: { start: 'H19/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '108',
    name: '新潟市　西蒲区',
    kana_name: {
      half_upper: 'ﾆｲｶﾞﾀｼ ﾆｼｶﾝｸ',
      full_lower: 'ニイガタシ　ニシカンク',
    },
    en: null,
    start_date: '2007-04-01',
    end_date: null,
    remark: { start: 'H19/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '201',
    name: '新潟市',
    kana_name: { half_upper: 'ﾆｲｶﾞﾀｼ', full_lower: 'ニイガタシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-04-01',
    remark: { start: null, end: '15100新潟市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '202',
    name: '長岡市',
    kana_name: { half_upper: 'ﾅｶﾞｵｶｼ', full_lower: 'ナガオカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '203',
    name: '高田市',
    kana_name: { half_upper: 'ﾀｶﾀﾞｼ', full_lower: 'タカダシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-04-29',
    remark: { start: null, end: '15222上越市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '204',
    name: '三条市',
    kana_name: { half_upper: 'ｻﾝｼﾞﾖｳｼ', full_lower: 'サンジョウシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '205',
    name: '柏崎市',
    kana_name: { half_upper: 'ｶｼﾜｻﾞｷｼ', full_lower: 'カシワザキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '206',
    name: '新発田市',
    kana_name: { half_upper: 'ｼﾊﾞﾀｼ', full_lower: 'シバタシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '207',
    name: '新津市',
    kana_name: { half_upper: 'ﾆｲﾂｼ', full_lower: 'ニイツシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-21',
    remark: { start: null, end: '15201新潟市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '208',
    name: '小千谷市',
    kana_name: { half_upper: 'ｵﾁﾞﾔｼ', full_lower: 'オヂヤシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '209',
    name: '加茂市',
    kana_name: { half_upper: 'ｶﾓｼ', full_lower: 'カモシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '210',
    name: '十日町市',
    kana_name: { half_upper: 'ﾄｵｶﾏﾁｼ', full_lower: 'トオカマチシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '211',
    name: '見附市',
    kana_name: { half_upper: 'ﾐﾂｹｼ', full_lower: 'ミツケシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '212',
    name: '村上市',
    kana_name: { half_upper: 'ﾑﾗｶﾐｼ', full_lower: 'ムラカミシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '213',
    name: '燕市',
    kana_name: { half_upper: 'ﾂﾊﾞﾒｼ', full_lower: 'ツバメシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '214',
    name: '直江津市',
    kana_name: { half_upper: 'ﾅｵｴﾂｼ', full_lower: 'ナオエツシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-04-29',
    remark: { start: null, end: '15222上越市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '215',
    name: '栃尾市',
    kana_name: { half_upper: 'ﾄﾁｵｼ', full_lower: 'トチオシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '15202長岡市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '216',
    name: '糸魚川市',
    kana_name: { half_upper: 'ｲﾄｲｶﾞﾜｼ', full_lower: 'イトイガワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '217',
    name: '新井市',
    kana_name: { half_upper: 'ｱﾗｲｼ', full_lower: 'アライシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '15217妙高市', other: null },
    note: 'キー重複',
  },
  {
    pref_code: '15',
    city_code: '217',
    name: '妙高市',
    kana_name: { half_upper: 'ﾐﾖｳｺｳｼ', full_lower: 'ミョウコウシ' },
    en: null,
    start_date: '2005-04-01',
    end_date: null,
    remark: {
      start: 'H17/04/01名称変更：旧「新井市」',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '218',
    name: '五泉市',
    kana_name: { half_upper: 'ｺﾞｾﾝｼ', full_lower: 'ゴセンシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '219',
    name: '両津市',
    kana_name: { half_upper: 'ﾘﾖｳﾂｼ', full_lower: 'リョウツシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '15224佐渡市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '220',
    name: '白根市',
    kana_name: { half_upper: 'ｼﾛﾈｼ', full_lower: 'シロネシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-21',
    remark: { start: null, end: '15201新潟市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '221',
    name: '豊栄市',
    kana_name: { half_upper: 'ﾄﾖｻｶｼ', full_lower: 'トヨサカシ' },
    en: null,
    start_date: '1970-11-01',
    end_date: '2005-03-21',
    remark: { start: 'S45/11/01市制', end: '15201新潟市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '222',
    name: '上越市',
    kana_name: { half_upper: 'ｼﾞﾖｳｴﾂｼ', full_lower: 'ジョウエツシ' },
    en: null,
    start_date: '1971-04-29',
    end_date: null,
    remark: { start: 'S46/04/29新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '223',
    name: '阿賀野市',
    kana_name: { half_upper: 'ｱｶﾞﾉｼ', full_lower: 'アガノシ' },
    en: null,
    start_date: '2004-04-01',
    end_date: null,
    remark: { start: 'H16/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '224',
    name: '佐渡市',
    kana_name: { half_upper: 'ｻﾄﾞｼ', full_lower: 'サドシ' },
    en: null,
    start_date: '2004-03-01',
    end_date: null,
    remark: { start: 'H16/03/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '225',
    name: '魚沼市',
    kana_name: { half_upper: 'ｳｵﾇﾏｼ', full_lower: 'ウオヌマシ' },
    en: null,
    start_date: '2004-11-01',
    end_date: null,
    remark: { start: 'H16/11/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '226',
    name: '南魚沼市',
    kana_name: { half_upper: 'ﾐﾅﾐｳｵﾇﾏｼ', full_lower: 'ミナミウオヌマシ' },
    en: null,
    start_date: '2004-11-01',
    end_date: null,
    remark: { start: 'H16/11/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '227',
    name: '胎内市',
    kana_name: { half_upper: 'ﾀｲﾅｲｼ', full_lower: 'タイナイシ' },
    en: null,
    start_date: '2005-09-01',
    end_date: null,
    remark: { start: 'H17/09/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '300',
    name: '北蒲原郡',
    kana_name: { half_upper: 'ｷﾀｶﾝﾊﾞﾗｸﾞﾝ', full_lower: 'キタカンバラグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '301',
    name: '北蒲原郡　安田町',
    kana_name: {
      half_upper: 'ｷﾀｶﾝﾊﾞﾗｸﾞﾝ ﾔｽﾀﾞﾏﾁ',
      full_lower: 'キタカンバラグン　ヤスダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '15223阿賀野市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '302',
    name: '北蒲原郡　京ヶ瀬村',
    kana_name: {
      half_upper: 'ｷﾀｶﾝﾊﾞﾗｸﾞﾝ ｷﾖｳｶﾞｾﾑﾗ',
      full_lower: 'キタカンバラグン　キョウガセムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '15223阿賀野市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '303',
    name: '北蒲原郡　水原町',
    kana_name: {
      half_upper: 'ｷﾀｶﾝﾊﾞﾗｸﾞﾝ ｽｲﾊﾞﾗﾏﾁ',
      full_lower: 'キタカンバラグン　スイバラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '15223阿賀野市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '304',
    name: '北蒲原郡　笹神村',
    kana_name: {
      half_upper: 'ｷﾀｶﾝﾊﾞﾗｸﾞﾝ ｻｻｶﾐﾑﾗ',
      full_lower: 'キタカンバラグン　ササカミムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '15223阿賀野市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '305',
    name: '北蒲原郡　豊栄町',
    kana_name: {
      half_upper: 'ｷﾀｶﾝﾊﾞﾗｸﾞﾝ ﾄﾖｻｶﾏﾁ',
      full_lower: 'キタカンバラグン　トヨサカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-11-01',
    remark: { start: null, end: '15221豊栄市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '306',
    name: '北蒲原郡　豊浦町',
    kana_name: {
      half_upper: 'ｷﾀｶﾝﾊﾞﾗｸﾞﾝ ﾄﾖｳﾗﾏﾁ',
      full_lower: 'キタカンバラグン　トヨウラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-07-07',
    remark: { start: null, end: '15206新発田市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '307',
    name: '北蒲原郡　聖籠町',
    kana_name: {
      half_upper: 'ｷﾀｶﾝﾊﾞﾗｸﾞﾝ ｾｲﾛｳﾏﾁ',
      full_lower: 'キタカンバラグン　セイロウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '308',
    name: '北蒲原郡　加治川村',
    kana_name: {
      half_upper: 'ｷﾀｶﾝﾊﾞﾗｸﾞﾝ ｶｼﾞｶﾜﾑﾗ',
      full_lower: 'キタカンバラグン　カジカワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-05-01',
    remark: { start: null, end: '15206新発田市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '309',
    name: '北蒲原郡　紫雲寺町',
    kana_name: {
      half_upper: 'ｷﾀｶﾝﾊﾞﾗｸﾞﾝ ｼｳﾝｼﾞﾏﾁ',
      full_lower: 'キタカンバラグン　シウンジマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-05-01',
    remark: { start: null, end: '15206新発田市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '310',
    name: '北蒲原郡　中条町',
    kana_name: {
      half_upper: 'ｷﾀｶﾝﾊﾞﾗｸﾞﾝ ﾅｶｼﾞﾖｳﾏﾁ',
      full_lower: 'キタカンバラグン　ナカジョウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-01',
    remark: { start: null, end: '15227胎内市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '311',
    name: '北蒲原郡　黒川村',
    kana_name: {
      half_upper: 'ｷﾀｶﾝﾊﾞﾗｸﾞﾝ ｸﾛｶﾜﾑﾗ',
      full_lower: 'キタカンバラグン　クロカワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-01',
    remark: { start: null, end: '15227胎内市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '320',
    name: '中蒲原郡',
    kana_name: { half_upper: 'ﾅｶｶﾝﾊﾞﾗｸﾞﾝ', full_lower: 'ナカカンバラグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '321',
    name: '中蒲原郡　小須戸町',
    kana_name: {
      half_upper: 'ﾅｶｶﾝﾊﾞﾗｸﾞﾝ ｺｽﾄﾞﾏﾁ',
      full_lower: 'ナカカンバラグン　コスドマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-21',
    remark: { start: null, end: '15201新潟市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '322',
    name: '中蒲原郡　村松町',
    kana_name: {
      half_upper: 'ﾅｶｶﾝﾊﾞﾗｸﾞﾝ ﾑﾗﾏﾂﾏﾁ',
      full_lower: 'ナカカンバラグン　ムラマツマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '15218五泉市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '323',
    name: '中蒲原郡　横越町',
    kana_name: {
      half_upper: 'ﾅｶｶﾝﾊﾞﾗｸﾞﾝ ﾖｺｺﾞｼﾏﾁ',
      full_lower: 'ナカカンバラグン　ヨコゴシマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-21',
    remark: { start: null, end: '15201新潟市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '324',
    name: '中蒲原郡　亀田町',
    kana_name: {
      half_upper: 'ﾅｶｶﾝﾊﾞﾗｸﾞﾝ ｶﾒﾀﾞﾏﾁ',
      full_lower: 'ナカカンバラグン　カメダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-21',
    remark: { start: null, end: '15201新潟市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '340',
    name: '西蒲原郡',
    kana_name: { half_upper: 'ﾆｼｶﾝﾊﾞﾗｸﾞﾝ', full_lower: 'ニシカンバラグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '341',
    name: '西蒲原郡　岩室村',
    kana_name: {
      half_upper: 'ﾆｼｶﾝﾊﾞﾗｸﾞﾝ ｲﾜﾑﾛﾑﾗ',
      full_lower: 'ニシカンバラグン　イワムロムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-21',
    remark: { start: null, end: '15201新潟市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '342',
    name: '西蒲原郡　弥彦村',
    kana_name: {
      half_upper: 'ﾆｼｶﾝﾊﾞﾗｸﾞﾝ ﾔﾋｺﾑﾗ',
      full_lower: 'ニシカンバラグン　ヤヒコムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '343',
    name: '西蒲原郡　分水町',
    kana_name: {
      half_upper: 'ﾆｼｶﾝﾊﾞﾗｸﾞﾝ ﾌﾞﾝｽｲﾏﾁ',
      full_lower: 'ニシカンバラグン　ブンスイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '15213燕市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '344',
    name: '西蒲原郡　吉田町',
    kana_name: {
      half_upper: 'ﾆｼｶﾝﾊﾞﾗｸﾞﾝ ﾖｼﾀﾞﾏﾁ',
      full_lower: 'ニシカンバラグン　ヨシダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '15213燕市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '345',
    name: '西蒲原郡　巻町',
    kana_name: {
      half_upper: 'ﾆｼｶﾝﾊﾞﾗｸﾞﾝ ﾏｷﾏﾁ',
      full_lower: 'ニシカンバラグン　マキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-10',
    remark: { start: null, end: '15201新潟市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '346',
    name: '西蒲原郡　西川町',
    kana_name: {
      half_upper: 'ﾆｼｶﾝﾊﾞﾗｸﾞﾝ ﾆｼｶﾜﾏﾁ',
      full_lower: 'ニシカンバラグン　ニシカワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-21',
    remark: { start: null, end: '15201新潟市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '347',
    name: '西蒲原郡　黒埼町',
    kana_name: {
      half_upper: 'ﾆｼｶﾝﾊﾞﾗｸﾞﾝ ｸﾛｻｷﾏﾁ',
      full_lower: 'ニシカンバラグン　クロサキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2001-01-01',
    remark: { start: null, end: '15201新潟市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '348',
    name: '西蒲原郡　味方村',
    kana_name: {
      half_upper: 'ﾆｼｶﾝﾊﾞﾗｸﾞﾝ ｱｼﾞｶﾀﾑﾗ',
      full_lower: 'ニシカンバラグン　アジカタムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-21',
    remark: { start: null, end: '15201新潟市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '349',
    name: '西蒲原郡　潟東村',
    kana_name: {
      half_upper: 'ﾆｼｶﾝﾊﾞﾗｸﾞﾝ ｶﾀﾋｶﾞｼﾑﾗ',
      full_lower: 'ニシカンバラグン　カタヒガシムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-21',
    remark: { start: null, end: '15201新潟市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '350',
    name: '西蒲原郡　月潟村',
    kana_name: {
      half_upper: 'ﾆｼｶﾝﾊﾞﾗｸﾞﾝ ﾂｷｶﾞﾀﾑﾗ',
      full_lower: 'ニシカンバラグン　ツキガタムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-21',
    remark: { start: null, end: '15201新潟市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '351',
    name: '西蒲原郡　中之口村',
    kana_name: {
      half_upper: 'ﾆｼｶﾝﾊﾞﾗｸﾞﾝ ﾅｶﾉｸﾁﾑﾗ',
      full_lower: 'ニシカンバラグン　ナカノクチムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-21',
    remark: { start: null, end: '15201新潟市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '360',
    name: '南蒲原郡',
    kana_name: {
      half_upper: 'ﾐﾅﾐｶﾝﾊﾞﾗｸﾞﾝ',
      full_lower: 'ミナミカンバラグン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '361',
    name: '南蒲原郡　田上町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｶﾝﾊﾞﾗｸﾞﾝ ﾀｶﾞﾐﾏﾁ',
      full_lower: 'ミナミカンバラグン　タガミマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '362',
    name: '南蒲原郡　下田村',
    kana_name: {
      half_upper: 'ﾐﾅﾐｶﾝﾊﾞﾗｸﾞﾝ ｼﾀﾀﾞﾑﾗ',
      full_lower: 'ミナミカンバラグン　シタダムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-05-01',
    remark: { start: null, end: '15204三条市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '363',
    name: '南蒲原郡　栄町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｶﾝﾊﾞﾗｸﾞﾝ ｻｶｴﾏﾁ',
      full_lower: 'ミナミカンバラグン　サカエマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-05-01',
    remark: { start: null, end: '15204三条市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '364',
    name: '南蒲原郡　中之島町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｶﾝﾊﾞﾗｸﾞﾝ ﾅｶﾉｼﾏﾏﾁ',
      full_lower: 'ミナミカンバラグン　ナカノシママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '15202長岡市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '380',
    name: '東蒲原郡',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｶﾝﾊﾞﾗｸﾞﾝ',
      full_lower: 'ヒガシカンバラグン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '381',
    name: '東蒲原郡　津川町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｶﾝﾊﾞﾗｸﾞﾝ ﾂｶﾞﾜﾏﾁ',
      full_lower: 'ヒガシカンバラグン　ツガワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '15385東蒲原郡　阿賀町', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '382',
    name: '東蒲原郡　鹿瀬町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｶﾝﾊﾞﾗｸﾞﾝ ｶﾉｾﾏﾁ',
      full_lower: 'ヒガシカンバラグン　カノセマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '15385東蒲原郡　阿賀町', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '383',
    name: '東蒲原郡　上川村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｶﾝﾊﾞﾗｸﾞﾝ ｶﾐｶﾜﾑﾗ',
      full_lower: 'ヒガシカンバラグン　カミカワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '15385東蒲原郡　阿賀町', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '384',
    name: '東蒲原郡　三川村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｶﾝﾊﾞﾗｸﾞﾝ ﾐｶﾜﾑﾗ',
      full_lower: 'ヒガシカンバラグン　ミカワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '15385東蒲原郡　阿賀町', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '385',
    name: '東蒲原郡　阿賀町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｶﾝﾊﾞﾗｸﾞﾝ ｱｶﾞﾏﾁ',
      full_lower: 'ヒガシカンバラグン　アガマチ',
    },
    en: null,
    start_date: '2005-04-01',
    end_date: null,
    remark: { start: 'H17/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '400',
    name: '三島郡',
    kana_name: { half_upper: 'ｻﾝﾄｳｸﾞﾝ', full_lower: 'サントウグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '401',
    name: '三島郡　越路町',
    kana_name: {
      half_upper: 'ｻﾝﾄｳｸﾞﾝ ｺｼｼﾞﾏﾁ',
      full_lower: 'サントウグン　コシジマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '15202長岡市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '402',
    name: '三島郡　三島町',
    kana_name: {
      half_upper: 'ｻﾝﾄｳｸﾞﾝ ﾐｼﾏﾏﾁ',
      full_lower: 'サントウグン　ミシママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '15202長岡市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '403',
    name: '三島郡　与板町',
    kana_name: {
      half_upper: 'ｻﾝﾄｳｸﾞﾝ ﾖｲﾀﾏﾁ',
      full_lower: 'サントウグン　ヨイタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '15202長岡市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '404',
    name: '三島郡　和島村',
    kana_name: {
      half_upper: 'ｻﾝﾄｳｸﾞﾝ ﾜｼﾏﾑﾗ',
      full_lower: 'サントウグン　ワシマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '15202長岡市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '405',
    name: '三島郡　出雲崎町',
    kana_name: {
      half_upper: 'ｻﾝﾄｳｸﾞﾝ ｲｽﾞﾓｻﾞｷﾏﾁ',
      full_lower: 'サントウグン　イズモザキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '406',
    name: '三島郡　寺泊町',
    kana_name: {
      half_upper: 'ｻﾝﾄｳｸﾞﾝ ﾃﾗﾄﾞﾏﾘﾏﾁ',
      full_lower: 'サントウグン　テラドマリマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '15202長岡市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '420',
    name: '古志郡',
    kana_name: { half_upper: 'ｺｼｸﾞﾝ', full_lower: 'コシグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '421',
    name: '古志郡　山古志村',
    kana_name: {
      half_upper: 'ｺｼｸﾞﾝ ﾔﾏｺｼﾑﾗ',
      full_lower: 'コシグン　ヤマコシムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '15202長岡市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '440',
    name: '北魚沼郡',
    kana_name: { half_upper: 'ｷﾀｳｵﾇﾏｸﾞﾝ', full_lower: 'キタウオヌマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-31',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '441',
    name: '北魚沼郡　川口町',
    kana_name: {
      half_upper: 'ｷﾀｳｵﾇﾏｸﾞﾝ ｶﾜｸﾞﾁﾏﾁ',
      full_lower: 'キタウオヌマグン　カワグチマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-31',
    remark: { start: null, end: '15202長岡市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '442',
    name: '北魚沼郡　堀之内町',
    kana_name: {
      half_upper: 'ｷﾀｳｵﾇﾏｸﾞﾝ ﾎﾘﾉｳﾁﾏﾁ',
      full_lower: 'キタウオヌマグン　ホリノウチマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '15225魚沼市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '443',
    name: '北魚沼郡　小出町',
    kana_name: {
      half_upper: 'ｷﾀｳｵﾇﾏｸﾞﾝ ｺｲﾃﾞﾏﾁ',
      full_lower: 'キタウオヌマグン　コイデマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '15225魚沼市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '444',
    name: '北魚沼郡　湯之谷村',
    kana_name: {
      half_upper: 'ｷﾀｳｵﾇﾏｸﾞﾝ ﾕﾉﾀﾆﾑﾗ',
      full_lower: 'キタウオヌマグン　ユノタニムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '15225魚沼市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '445',
    name: '北魚沼郡　広神村',
    kana_name: {
      half_upper: 'ｷﾀｳｵﾇﾏｸﾞﾝ ﾋﾛｶﾐﾑﾗ',
      full_lower: 'キタウオヌマグン　ヒロカミムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '15225魚沼市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '446',
    name: '北魚沼郡　守門村',
    kana_name: {
      half_upper: 'ｷﾀｳｵﾇﾏｸﾞﾝ ｽﾓﾝﾑﾗ',
      full_lower: 'キタウオヌマグン　スモンムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '15225魚沼市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '447',
    name: '北魚沼郡　入広瀬村',
    kana_name: {
      half_upper: 'ｷﾀｳｵﾇﾏｸﾞﾝ ｲﾘﾋﾛｾﾑﾗ',
      full_lower: 'キタウオヌマグン　イリヒロセムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '15225魚沼市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '460',
    name: '南魚沼郡',
    kana_name: { half_upper: 'ﾐﾅﾐｳｵﾇﾏｸﾞﾝ', full_lower: 'ミナミウオヌマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '461',
    name: '南魚沼郡　湯沢町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｳｵﾇﾏｸﾞﾝ ﾕｻﾞﾜﾏﾁ',
      full_lower: 'ミナミウオヌマグン　ユザワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '462',
    name: '南魚沼郡　塩沢町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｳｵﾇﾏｸﾞﾝ ｼｵｻﾞﾜﾏﾁ',
      full_lower: 'ミナミウオヌマグン　シオザワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '15226南魚沼市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '463',
    name: '南魚沼郡　六日町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｳｵﾇﾏｸﾞﾝ ﾑｲｶﾏﾁ',
      full_lower: 'ミナミウオヌマグン　ムイカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '15226南魚沼市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '464',
    name: '南魚沼郡　大和町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｳｵﾇﾏｸﾞﾝ ﾔﾏﾄﾏﾁ',
      full_lower: 'ミナミウオヌマグン　ヤマトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '15226南魚沼市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '480',
    name: '中魚沼郡',
    kana_name: { half_upper: 'ﾅｶｳｵﾇﾏｸﾞﾝ', full_lower: 'ナカウオヌマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '481',
    name: '中魚沼郡　川西町',
    kana_name: {
      half_upper: 'ﾅｶｳｵﾇﾏｸﾞﾝ ｶﾜﾆｼﾏﾁ',
      full_lower: 'ナカウオヌマグン　カワニシマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '15210十日町市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '482',
    name: '中魚沼郡　津南町',
    kana_name: {
      half_upper: 'ﾅｶｳｵﾇﾏｸﾞﾝ ﾂﾅﾝﾏﾁ',
      full_lower: 'ナカウオヌマグン　ツナンマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '483',
    name: '中魚沼郡　中里村',
    kana_name: {
      half_upper: 'ﾅｶｳｵﾇﾏｸﾞﾝ ﾅｶｻﾄﾑﾗ',
      full_lower: 'ナカウオヌマグン　ナカサトムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '15210十日町市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '500',
    name: '刈羽郡',
    kana_name: { half_upper: 'ｶﾘﾜｸﾞﾝ', full_lower: 'カリワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '501',
    name: '刈羽郡　高柳町',
    kana_name: {
      half_upper: 'ｶﾘﾜｸﾞﾝ ﾀｶﾔﾅｷﾞﾏﾁ',
      full_lower: 'カリワグン　タカヤナギマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-05-01',
    remark: { start: null, end: '15205柏崎市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '502',
    name: '刈羽郡　小国町',
    kana_name: {
      half_upper: 'ｶﾘﾜｸﾞﾝ ｵｸﾞﾆﾏﾁ',
      full_lower: 'カリワグン　オグニマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '15202長岡市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '503',
    name: '刈羽郡　北条町',
    kana_name: {
      half_upper: 'ｶﾘﾜｸﾞﾝ ｷﾀｼﾞﾖｳﾏﾁ',
      full_lower: 'カリワグン　キタジョウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-05-01',
    remark: { start: null, end: '15205柏崎市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '504',
    name: '刈羽郡　刈羽村',
    kana_name: {
      half_upper: 'ｶﾘﾜｸﾞﾝ ｶﾘﾜﾑﾗ',
      full_lower: 'カリワグン　カリワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '505',
    name: '刈羽郡　西山町',
    kana_name: {
      half_upper: 'ｶﾘﾜｸﾞﾝ ﾆｼﾔﾏﾏﾁ',
      full_lower: 'カリワグン　ニシヤママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-05-01',
    remark: { start: null, end: '15205柏崎市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '520',
    name: '東頸城郡',
    kana_name: { half_upper: 'ﾋｶﾞｼｸﾋﾞｷｸﾞﾝ', full_lower: 'ヒガシクビキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '521',
    name: '東頸城郡　安塚町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｸﾋﾞｷｸﾞﾝ ﾔｽﾂﾞｶﾏﾁ',
      full_lower: 'ヒガシクビキグン　ヤスヅカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '15222上越市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '522',
    name: '東頸城郡　浦川原村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｸﾋﾞｷｸﾞﾝ ｳﾗｶﾞﾜﾗﾑﾗ',
      full_lower: 'ヒガシクビキグン　ウラガワラムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '15222上越市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '523',
    name: '東頸城郡　松代町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｸﾋﾞｷｸﾞﾝ ﾏﾂﾀﾞｲﾏﾁ',
      full_lower: 'ヒガシクビキグン　マツダイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '15210十日町市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '524',
    name: '東頸城郡　松之山町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｸﾋﾞｷｸﾞﾝ ﾏﾂﾉﾔﾏﾏﾁ',
      full_lower: 'ヒガシクビキグン　マツノヤママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '15210十日町市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '525',
    name: '東頸城郡　大島村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｸﾋﾞｷｸﾞﾝ ｵｵｼﾏﾑﾗ',
      full_lower: 'ヒガシクビキグン　オオシマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '15222上越市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '526',
    name: '東頸城郡　牧村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｸﾋﾞｷｸﾞﾝ ﾏｷﾑﾗ',
      full_lower: 'ヒガシクビキグン　マキムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '15222上越市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '540',
    name: '中頸城郡',
    kana_name: { half_upper: 'ﾅｶｸﾋﾞｷｸﾞﾝ', full_lower: 'ナカクビキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '541',
    name: '中頸城郡　柿崎町',
    kana_name: {
      half_upper: 'ﾅｶｸﾋﾞｷｸﾞﾝ ｶｷｻﾞｷﾏﾁ',
      full_lower: 'ナカクビキグン　カキザキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '15222上越市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '542',
    name: '中頸城郡　大潟町',
    kana_name: {
      half_upper: 'ﾅｶｸﾋﾞｷｸﾞﾝ ｵｵｶﾞﾀﾏﾁ',
      full_lower: 'ナカクビキグン　オオガタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '15222上越市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '543',
    name: '中頸城郡　頸城村',
    kana_name: {
      half_upper: 'ﾅｶｸﾋﾞｷｸﾞﾝ ｸﾋﾞｷﾑﾗ',
      full_lower: 'ナカクビキグン　クビキムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '15222上越市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '544',
    name: '中頸城郡　吉川町',
    kana_name: {
      half_upper: 'ﾅｶｸﾋﾞｷｸﾞﾝ ﾖｼｶﾜﾏﾁ',
      full_lower: 'ナカクビキグン　ヨシカワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '15222上越市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '545',
    name: '中頸城郡　妙高高原町',
    kana_name: {
      half_upper: 'ﾅｶｸﾋﾞｷｸﾞﾝ ﾐﾖｳｺｳｺｳｹﾞﾝﾏﾁ',
      full_lower: 'ナカクビキグン　ミョウコウコウゲンマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '15217新井市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '546',
    name: '中頸城郡　中郷村',
    kana_name: {
      half_upper: 'ﾅｶｸﾋﾞｷｸﾞﾝ ﾅｶｺﾞｳﾑﾗ',
      full_lower: 'ナカクビキグン　ナカゴウムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '15222上越市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '547',
    name: '中頸城郡　妙高村',
    kana_name: {
      half_upper: 'ﾅｶｸﾋﾞｷｸﾞﾝ ﾐﾖｳｺｳﾑﾗ',
      full_lower: 'ナカクビキグン　ミョウコウムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '15217新井市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '548',
    name: '中頸城郡　板倉町',
    kana_name: {
      half_upper: 'ﾅｶｸﾋﾞｷｸﾞﾝ ｲﾀｸﾗﾏﾁ',
      full_lower: 'ナカクビキグン　イタクラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '15222上越市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '549',
    name: '中頸城郡　清里村',
    kana_name: {
      half_upper: 'ﾅｶｸﾋﾞｷｸﾞﾝ ｷﾖｻﾄﾑﾗ',
      full_lower: 'ナカクビキグン　キヨサトムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '15222上越市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '550',
    name: '中頸城郡　三和村',
    kana_name: {
      half_upper: 'ﾅｶｸﾋﾞｷｸﾞﾝ ｻﾝﾜﾑﾗ',
      full_lower: 'ナカクビキグン　サンワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '15222上越市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '560',
    name: '西頸城郡',
    kana_name: { half_upper: 'ﾆｼｸﾋﾞｷｸﾞﾝ', full_lower: 'ニシクビキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-19',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '561',
    name: '西頸城郡　名立町',
    kana_name: {
      half_upper: 'ﾆｼｸﾋﾞｷｸﾞﾝ ﾅﾀﾞﾁﾏﾁ',
      full_lower: 'ニシクビキグン　ナダチマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '15222上越市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '562',
    name: '西頸城郡　能生町',
    kana_name: {
      half_upper: 'ﾆｼｸﾋﾞｷｸﾞﾝ ﾉｳﾏﾁ',
      full_lower: 'ニシクビキグン　ノウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-19',
    remark: { start: null, end: '15216糸魚川市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '563',
    name: '西頸城郡　青海町',
    kana_name: {
      half_upper: 'ﾆｼｸﾋﾞｷｸﾞﾝ ｵｳﾐﾏﾁ',
      full_lower: 'ニシクビキグン　オウミマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-19',
    remark: { start: null, end: '15216糸魚川市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '580',
    name: '岩船郡',
    kana_name: { half_upper: 'ｲﾜﾌﾈｸﾞﾝ', full_lower: 'イワフネグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '581',
    name: '岩船郡　関川村',
    kana_name: {
      half_upper: 'ｲﾜﾌﾈｸﾞﾝ ｾｷｶﾜﾑﾗ',
      full_lower: 'イワフネグン　セキカワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '582',
    name: '岩船郡　荒川町',
    kana_name: {
      half_upper: 'ｲﾜﾌﾈｸﾞﾝ ｱﾗｶﾜﾏﾁ',
      full_lower: 'イワフネグン　アラカワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2008-04-01',
    remark: { start: null, end: '15212村上市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '583',
    name: '岩船郡　神林村',
    kana_name: {
      half_upper: 'ｲﾜﾌﾈｸﾞﾝ ｶﾐﾊﾔｼﾑﾗ',
      full_lower: 'イワフネグン　カミハヤシムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2008-04-01',
    remark: { start: null, end: '15212村上市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '584',
    name: '岩船郡　朝日村',
    kana_name: {
      half_upper: 'ｲﾜﾌﾈｸﾞﾝ ｱｻﾋﾑﾗ',
      full_lower: 'イワフネグン　アサヒムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2008-04-01',
    remark: { start: null, end: '15212村上市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '585',
    name: '岩船郡　山北町',
    kana_name: {
      half_upper: 'ｲﾜﾌﾈｸﾞﾝ ｻﾝﾎﾟｸﾏﾁ',
      full_lower: 'イワフネグン　サンポクマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2008-04-01',
    remark: { start: null, end: '15212村上市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '586',
    name: '岩船郡　粟島浦村',
    kana_name: {
      half_upper: 'ｲﾜﾌﾈｸﾞﾝ ｱﾜｼﾏｳﾗﾑﾗ',
      full_lower: 'イワフネグン　アワシマウラムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '600',
    name: '佐渡郡',
    kana_name: { half_upper: 'ｻﾄﾞｸﾞﾝ', full_lower: 'サドグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '601',
    name: '佐渡郡　相川町',
    kana_name: {
      half_upper: 'ｻﾄﾞｸﾞﾝ ｱｲｶﾜﾏﾁ',
      full_lower: 'サドグン　アイカワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '15224佐渡市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '602',
    name: '佐渡郡　佐和田町',
    kana_name: {
      half_upper: 'ｻﾄﾞｸﾞﾝ ｻﾜﾀﾏﾁ',
      full_lower: 'サドグン　サワタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '15224佐渡市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '603',
    name: '佐渡郡　金井町',
    kana_name: {
      half_upper: 'ｻﾄﾞｸﾞﾝ ｶﾅｲﾏﾁ',
      full_lower: 'サドグン　カナイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '15224佐渡市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '604',
    name: '佐渡郡　新穂村',
    kana_name: {
      half_upper: 'ｻﾄﾞｸﾞﾝ ﾆｲﾎﾞﾑﾗ',
      full_lower: 'サドグン　ニイボムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '15224佐渡市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '605',
    name: '佐渡郡　畑野町',
    kana_name: {
      half_upper: 'ｻﾄﾞｸﾞﾝ ﾊﾀﾉﾏﾁ',
      full_lower: 'サドグン　ハタノマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '15224佐渡市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '606',
    name: '佐渡郡　真野町',
    kana_name: {
      half_upper: 'ｻﾄﾞｸﾞﾝ ﾏﾉﾏﾁ',
      full_lower: 'サドグン　マノマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '15224佐渡市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '607',
    name: '佐渡郡　小木町',
    kana_name: {
      half_upper: 'ｻﾄﾞｸﾞﾝ ｵｷﾞﾏﾁ',
      full_lower: 'サドグン　オギマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '15224佐渡市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '608',
    name: '佐渡郡　羽茂町',
    kana_name: {
      half_upper: 'ｻﾄﾞｸﾞﾝ ﾊﾓﾁﾏﾁ',
      full_lower: 'サドグン　ハモチマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '15224佐渡市', other: null },
    note: null,
  },
  {
    pref_code: '15',
    city_code: '609',
    name: '佐渡郡　赤泊村',
    kana_name: {
      half_upper: 'ｻﾄﾞｸﾞﾝ ｱｶﾄﾞﾏﾘﾑﾗ',
      full_lower: 'サドグン　アカドマリムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '15224佐渡市', other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '201',
    name: '富山市',
    kana_name: { half_upper: 'ﾄﾔﾏｼ', full_lower: 'トヤマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '202',
    name: '高岡市',
    kana_name: { half_upper: 'ﾀｶｵｶｼ', full_lower: 'タカオカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '203',
    name: '新湊市',
    kana_name: { half_upper: 'ｼﾝﾐﾅﾄｼ', full_lower: 'シンミナトシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-01',
    remark: { start: null, end: '16211射水市', other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '204',
    name: '魚津市',
    kana_name: { half_upper: 'ｳｵﾂﾞｼ', full_lower: 'ウオヅシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '205',
    name: '氷見市',
    kana_name: { half_upper: 'ﾋﾐｼ', full_lower: 'ヒミシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '206',
    name: '滑川市',
    kana_name: { half_upper: 'ﾅﾒﾘｶﾜｼ', full_lower: 'ナメリカワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '207',
    name: '黒部市',
    kana_name: { half_upper: 'ｸﾛﾍﾞｼ', full_lower: 'クロベシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '208',
    name: '砺波市',
    kana_name: { half_upper: 'ﾄﾅﾐｼ', full_lower: 'トナミシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '209',
    name: '小矢部市',
    kana_name: { half_upper: 'ｵﾔﾍﾞｼ', full_lower: 'オヤベシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '210',
    name: '南砺市',
    kana_name: { half_upper: 'ﾅﾝﾄｼ', full_lower: 'ナントシ' },
    en: null,
    start_date: '2004-11-01',
    end_date: null,
    remark: { start: 'H16/11/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '211',
    name: '射水市',
    kana_name: { half_upper: 'ｲﾐｽﾞｼ', full_lower: 'イミズシ' },
    en: null,
    start_date: '2005-11-01',
    end_date: null,
    remark: { start: 'H17/11/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '300',
    name: '上新川郡',
    kana_name: { half_upper: 'ｶﾐﾆｲｶﾜｸﾞﾝ', full_lower: 'カミニイカワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '301',
    name: '上新川郡　大沢野町',
    kana_name: {
      half_upper: 'ｶﾐﾆｲｶﾜｸﾞﾝ ｵｵｻﾜﾉﾏﾁ',
      full_lower: 'カミニイカワグン　オオサワノマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '16201富山市', other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '302',
    name: '上新川郡　大山町',
    kana_name: {
      half_upper: 'ｶﾐﾆｲｶﾜｸﾞﾝ ｵｵﾔﾏﾏﾁ',
      full_lower: 'カミニイカワグン　オオヤママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '16201富山市', other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '320',
    name: '中新川郡',
    kana_name: { half_upper: 'ﾅｶﾆｲｶﾜｸﾞﾝ', full_lower: 'ナカニイカワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '321',
    name: '中新川郡　舟橋村',
    kana_name: {
      half_upper: 'ﾅｶﾆｲｶﾜｸﾞﾝ ﾌﾅﾊｼﾑﾗ',
      full_lower: 'ナカニイカワグン　フナハシムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '322',
    name: '中新川郡　上市町',
    kana_name: {
      half_upper: 'ﾅｶﾆｲｶﾜｸﾞﾝ ｶﾐｲﾁﾏﾁ',
      full_lower: 'ナカニイカワグン　カミイチマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '323',
    name: '中新川郡　立山町',
    kana_name: {
      half_upper: 'ﾅｶﾆｲｶﾜｸﾞﾝ ﾀﾃﾔﾏﾏﾁ',
      full_lower: 'ナカニイカワグン　タテヤママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '340',
    name: '下新川郡',
    kana_name: { half_upper: 'ｼﾓﾆｲｶﾜｸﾞﾝ', full_lower: 'シモニイカワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '341',
    name: '下新川郡　宇奈月町',
    kana_name: {
      half_upper: 'ｼﾓﾆｲｶﾜｸﾞﾝ ｳﾅﾂﾞｷﾏﾁ',
      full_lower: 'シモニイカワグン　ウナヅキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '16207黒部市', other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '342',
    name: '下新川郡　入善町',
    kana_name: {
      half_upper: 'ｼﾓﾆｲｶﾜｸﾞﾝ ﾆﾕｳｾﾞﾝﾏﾁ',
      full_lower: 'シモニイカワグン　ニュウゼンマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '343',
    name: '下新川郡　朝日町',
    kana_name: {
      half_upper: 'ｼﾓﾆｲｶﾜｸﾞﾝ ｱｻﾋﾏﾁ',
      full_lower: 'シモニイカワグン　アサヒマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '360',
    name: '婦負郡',
    kana_name: { half_upper: 'ﾈｲｸﾞﾝ', full_lower: 'ネイグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '361',
    name: '婦負郡　八尾町',
    kana_name: {
      half_upper: 'ﾈｲｸﾞﾝ ﾔﾂｵﾏﾁ',
      full_lower: 'ネイグン　ヤツオマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '16201富山市', other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '362',
    name: '婦負郡　婦中町',
    kana_name: {
      half_upper: 'ﾈｲｸﾞﾝ ﾌﾁﾕｳﾏﾁ',
      full_lower: 'ネイグン　フチュウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '16201富山市', other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '363',
    name: '婦負郡　山田村',
    kana_name: {
      half_upper: 'ﾈｲｸﾞﾝ ﾔﾏﾀﾞﾑﾗ',
      full_lower: 'ネイグン　ヤマダムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '16201富山市', other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '364',
    name: '婦負郡　細入村',
    kana_name: {
      half_upper: 'ﾈｲｸﾞﾝ ﾎｿｲﾘﾑﾗ',
      full_lower: 'ネイグン　ホソイリムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '16201富山市', other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '380',
    name: '射水郡',
    kana_name: { half_upper: 'ｲﾐｽﾞｸﾞﾝ', full_lower: 'イミズグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '381',
    name: '射水郡　小杉町',
    kana_name: {
      half_upper: 'ｲﾐｽﾞｸﾞﾝ ｺｽｷﾞﾏﾁ',
      full_lower: 'イミズグン　コスギマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-01',
    remark: { start: null, end: '16211射水市', other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '382',
    name: '射水郡　大門町',
    kana_name: {
      half_upper: 'ｲﾐｽﾞｸﾞﾝ ﾀﾞｲﾓﾝﾏﾁ',
      full_lower: 'イミズグン　ダイモンマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-01',
    remark: { start: null, end: '16211射水市', other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '383',
    name: '射水郡　下村',
    kana_name: {
      half_upper: 'ｲﾐｽﾞｸﾞﾝ ｼﾓﾑﾗ',
      full_lower: 'イミズグン　シモムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-01',
    remark: { start: null, end: '16211射水市', other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '384',
    name: '射水郡　大島町',
    kana_name: {
      half_upper: 'ｲﾐｽﾞｸﾞﾝ ｵｵｼﾏﾏﾁ',
      full_lower: 'イミズグン　オオシママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-01',
    remark: { start: null, end: '16211射水市', other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '400',
    name: '東礪波郡',
    kana_name: { half_upper: 'ﾋｶﾞｼﾄﾅﾐｸﾞﾝ', full_lower: 'ヒガシトナミグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '401',
    name: '東礪波郡　城端町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾄﾅﾐｸﾞﾝ ｼﾞﾖｳﾊﾅﾏﾁ',
      full_lower: 'ヒガシトナミグン　ジョウハナマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '16210南砺市', other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '402',
    name: '東礪波郡　平村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾄﾅﾐｸﾞﾝ ﾀｲﾗﾑﾗ',
      full_lower: 'ヒガシトナミグン　タイラムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '16210南砺市', other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '403',
    name: '東礪波郡　上平村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾄﾅﾐｸﾞﾝ ｶﾐﾀｲﾗﾑﾗ',
      full_lower: 'ヒガシトナミグン　カミタイラムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '16210南砺市', other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '404',
    name: '東礪波郡　利賀村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾄﾅﾐｸﾞﾝ ﾄｶﾞﾑﾗ',
      full_lower: 'ヒガシトナミグン　トガムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '16210南砺市', other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '405',
    name: '東礪波郡　庄川町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾄﾅﾐｸﾞﾝ ｼﾖｳｶﾞﾜﾏﾁ',
      full_lower: 'ヒガシトナミグン　ショウガワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '16208砺波市', other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '406',
    name: '東礪波郡　井波町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾄﾅﾐｸﾞﾝ ｲﾅﾐﾏﾁ',
      full_lower: 'ヒガシトナミグン　イナミマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '16210南砺市', other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '407',
    name: '東礪波郡　井口村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾄﾅﾐｸﾞﾝ ｲﾉｸﾁﾑﾗ',
      full_lower: 'ヒガシトナミグン　イノクチムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '16210南砺市', other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '408',
    name: '東礪波郡　福野町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾄﾅﾐｸﾞﾝ ﾌｸﾉﾏﾁ',
      full_lower: 'ヒガシトナミグン　フクノマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '16210南砺市', other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '420',
    name: '西礪波郡',
    kana_name: { half_upper: 'ﾆｼﾄﾅﾐｸﾞﾝ', full_lower: 'ニシトナミグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '421',
    name: '西礪波郡　福光町',
    kana_name: {
      half_upper: 'ﾆｼﾄﾅﾐｸﾞﾝ ﾌｸﾐﾂﾏﾁ',
      full_lower: 'ニシトナミグン　フクミツマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '16210南砺市', other: null },
    note: null,
  },
  {
    pref_code: '16',
    city_code: '422',
    name: '西礪波郡　福岡町',
    kana_name: {
      half_upper: 'ﾆｼﾄﾅﾐｸﾞﾝ ﾌｸｵｶﾏﾁ',
      full_lower: 'ニシトナミグン　フクオカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-01',
    remark: { start: null, end: '16202高岡市', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '201',
    name: '金沢市',
    kana_name: { half_upper: 'ｶﾅｻﾞﾜｼ', full_lower: 'カナザワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '202',
    name: '七尾市',
    kana_name: { half_upper: 'ﾅﾅｵｼ', full_lower: 'ナナオシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '203',
    name: '小松市',
    kana_name: { half_upper: 'ｺﾏﾂｼ', full_lower: 'コマツシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '204',
    name: '輪島市',
    kana_name: { half_upper: 'ﾜｼﾞﾏｼ', full_lower: 'ワジマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '205',
    name: '珠洲市',
    kana_name: { half_upper: 'ｽｽﾞｼ', full_lower: 'スズシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '206',
    name: '加賀市',
    kana_name: { half_upper: 'ｶｶﾞｼ', full_lower: 'カガシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '207',
    name: '羽咋市',
    kana_name: { half_upper: 'ﾊｸｲｼ', full_lower: 'ハクイシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '208',
    name: '松任市',
    kana_name: { half_upper: 'ﾏﾂﾄｳｼ', full_lower: 'マットウシ' },
    en: null,
    start_date: '1970-10-10',
    end_date: '2005-02-01',
    remark: { start: 'S45/10/10市制', end: '17210白山市', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '209',
    name: 'かほく市',
    kana_name: { half_upper: 'ｶﾎｸｼ', full_lower: 'カホクシ' },
    en: null,
    start_date: '2004-03-01',
    end_date: null,
    remark: { start: 'H16/03/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '210',
    name: '白山市',
    kana_name: { half_upper: 'ﾊｸｻﾝｼ', full_lower: 'ハクサンシ' },
    en: null,
    start_date: '2005-02-01',
    end_date: null,
    remark: { start: 'H17/02/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '211',
    name: '能美市',
    kana_name: { half_upper: 'ﾉﾐｼ', full_lower: 'ノミシ' },
    en: null,
    start_date: '2005-02-01',
    end_date: null,
    remark: { start: 'H17/02/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '212',
    name: '野々市市',
    kana_name: { half_upper: 'ﾉﾉｲﾁｼ', full_lower: 'ノノイチシ' },
    en: null,
    start_date: '2011-11-11',
    end_date: null,
    remark: { start: 'H23/11/11市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '300',
    name: '江沼郡',
    kana_name: { half_upper: 'ｴﾇﾏｸﾞﾝ', full_lower: 'エヌマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '301',
    name: '江沼郡　山中町',
    kana_name: {
      half_upper: 'ｴﾇﾏｸﾞﾝ ﾔﾏﾅｶﾏﾁ',
      full_lower: 'エヌマグン　ヤマナカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '17206加賀市', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '320',
    name: '能美郡',
    kana_name: { half_upper: 'ﾉﾐｸﾞﾝ', full_lower: 'ノミグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '321',
    name: '能美郡　根上町',
    kana_name: {
      half_upper: 'ﾉﾐｸﾞﾝ ﾈｱｶﾞﾘﾏﾁ',
      full_lower: 'ノミグン　ネアガリマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '17211能美市', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '322',
    name: '能美郡　寺井町',
    kana_name: {
      half_upper: 'ﾉﾐｸﾞﾝ ﾃﾗｲﾏﾁ',
      full_lower: 'ノミグン　テライマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '17211能美市', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '323',
    name: '能美郡　辰口町',
    kana_name: {
      half_upper: 'ﾉﾐｸﾞﾝ ﾀﾂﾉｸﾁﾏﾁ',
      full_lower: 'ノミグン　タツノクチマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '17211能美市', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '324',
    name: '能美郡　川北町',
    kana_name: {
      half_upper: 'ﾉﾐｸﾞﾝ ｶﾜｷﾀﾏﾁ',
      full_lower: 'ノミグン　カワキタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '340',
    name: '石川郡',
    kana_name: { half_upper: 'ｲｼｶﾜｸﾞﾝ', full_lower: 'イシカワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2011-11-11',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '341',
    name: '石川郡　松任町',
    kana_name: {
      half_upper: 'ｲｼｶﾜｸﾞﾝ ﾏﾂﾄｳﾏﾁ',
      full_lower: 'イシカワグン　マツトウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-10-10',
    remark: { start: null, end: '17208松任市', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '342',
    name: '石川郡　美川町',
    kana_name: {
      half_upper: 'ｲｼｶﾜｸﾞﾝ ﾐｶﾜﾏﾁ',
      full_lower: 'イシカワグン　ミカワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '17210白山市', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '343',
    name: '石川郡　鶴来町',
    kana_name: {
      half_upper: 'ｲｼｶﾜｸﾞﾝ ﾂﾙｷﾞﾏﾁ',
      full_lower: 'イシカワグン　ツルギマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '17210白山市', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '344',
    name: '石川郡　野々市町',
    kana_name: {
      half_upper: 'ｲｼｶﾜｸﾞﾝ ﾉﾉｲﾁﾏﾁ',
      full_lower: 'イシカワグン　ノノイチマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2011-11-11',
    remark: { start: null, end: '17212野々市市', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '345',
    name: '石川郡　河内村',
    kana_name: {
      half_upper: 'ｲｼｶﾜｸﾞﾝ ｶﾜﾁﾑﾗ',
      full_lower: 'イシカワグン　カワチムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '17210白山市', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '346',
    name: '石川郡　吉野谷村',
    kana_name: {
      half_upper: 'ｲｼｶﾜｸﾞﾝ ﾖｼﾉﾀﾞﾆﾑﾗ',
      full_lower: 'イシカワグン　ヨシノダニムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '17210白山市', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '347',
    name: '石川郡　鳥越村',
    kana_name: {
      half_upper: 'ｲｼｶﾜｸﾞﾝ ﾄﾘｺﾞｴﾑﾗ',
      full_lower: 'イシカワグン　トリゴエムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '17210白山市', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '348',
    name: '石川郡　尾口村',
    kana_name: {
      half_upper: 'ｲｼｶﾜｸﾞﾝ ｵｸﾞﾁﾑﾗ',
      full_lower: 'イシカワグン　オグチムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '17210白山市', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '349',
    name: '石川郡　白峰村',
    kana_name: {
      half_upper: 'ｲｼｶﾜｸﾞﾝ ｼﾗﾐﾈﾑﾗ',
      full_lower: 'イシカワグン　シラミネムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '17210白山市', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '360',
    name: '河北郡',
    kana_name: { half_upper: 'ｶﾎｸｸﾞﾝ', full_lower: 'カホクグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '361',
    name: '河北郡　津幡町',
    kana_name: {
      half_upper: 'ｶﾎｸｸﾞﾝ ﾂﾊﾞﾀﾏﾁ',
      full_lower: 'カホクグン　ツバタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '362',
    name: '河北郡　高松町',
    kana_name: {
      half_upper: 'ｶﾎｸｸﾞﾝ ﾀｶﾏﾂﾏﾁ',
      full_lower: 'カホクグン　タカマツマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '17209かほく市', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '363',
    name: '河北郡　七塚町',
    kana_name: {
      half_upper: 'ｶﾎｸｸﾞﾝ ﾅﾅﾂｶﾏﾁ',
      full_lower: 'カホクグン　ナナツカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '17209かほく市', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '364',
    name: '河北郡　宇ノ気町',
    kana_name: {
      half_upper: 'ｶﾎｸｸﾞﾝ ｳﾉｹﾏﾁ',
      full_lower: 'カホクグン　ウノケマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '17209かほく市', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '365',
    name: '河北郡　内灘町',
    kana_name: {
      half_upper: 'ｶﾎｸｸﾞﾝ ｳﾁﾅﾀﾞﾏﾁ',
      full_lower: 'カホクグン　ウチナダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '380',
    name: '羽咋郡',
    kana_name: { half_upper: 'ﾊｸｲｸﾞﾝ', full_lower: 'ハクイグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '381',
    name: '羽咋郡　高浜町',
    kana_name: {
      half_upper: 'ﾊｸｲｸﾞﾝ ﾀｶﾊﾏﾏﾁ',
      full_lower: 'ハクイグン　タカハママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-11-01',
    remark: { start: null, end: '17384羽咋郡　志賀町', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '382',
    name: '羽咋郡　富来町',
    kana_name: {
      half_upper: 'ﾊｸｲｸﾞﾝ ﾄｷﾞﾏﾁ',
      full_lower: 'ハクイグン　トギマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-01',
    remark: { start: null, end: '17384羽咋郡　志賀町', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '383',
    name: '羽咋郡　志雄町',
    kana_name: {
      half_upper: 'ﾊｸｲｸﾞﾝ ｼｵﾏﾁ',
      full_lower: 'ハクイグン　シオマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '17386羽咋郡　宝達志水町', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '384',
    name: '羽咋郡　志賀町',
    kana_name: {
      half_upper: 'ﾊｸｲｸﾞﾝ ｼｶﾏﾁ',
      full_lower: 'ハクイグン　シカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '385',
    name: '羽咋郡　押水町',
    kana_name: {
      half_upper: 'ﾊｸｲｸﾞﾝ ｵｼﾐｽﾞﾏﾁ',
      full_lower: 'ハクイグン　オシミズマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '17386羽咋郡　宝達志水町', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '386',
    name: '羽咋郡　宝達志水町',
    kana_name: {
      half_upper: 'ﾊｸｲｸﾞﾝ ﾎｳﾀﾞﾂｼﾐｽﾞﾁﾖｳ',
      full_lower: 'ハクイグン　ホウダツシミズチョウ',
    },
    en: null,
    start_date: '2005-03-01',
    end_date: null,
    remark: { start: 'H17/03/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '400',
    name: '鹿島郡',
    kana_name: { half_upper: 'ｶｼﾏｸﾞﾝ', full_lower: 'カシマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '401',
    name: '鹿島郡　田鶴浜町',
    kana_name: {
      half_upper: 'ｶｼﾏｸﾞﾝ ﾀﾂﾙﾊﾏﾏﾁ',
      full_lower: 'カシマグン　タツルハママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '17202七尾市', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '402',
    name: '鹿島郡　鳥屋町',
    kana_name: {
      half_upper: 'ｶｼﾏｸﾞﾝ ﾄﾘﾔﾏﾁ',
      full_lower: 'カシマグン　トリヤマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '17407鹿島郡　中能登町', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '403',
    name: '鹿島郡　中島町',
    kana_name: {
      half_upper: 'ｶｼﾏｸﾞﾝ ﾅｶｼﾞﾏﾏﾁ',
      full_lower: 'カシマグン　ナカジママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '17202七尾市', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '404',
    name: '鹿島郡　鹿島町',
    kana_name: {
      half_upper: 'ｶｼﾏｸﾞﾝ ｶｼﾏﾏﾁ',
      full_lower: 'カシマグン　カシママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '17407鹿島郡　中能登町', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '405',
    name: '鹿島郡　能登島町',
    kana_name: {
      half_upper: 'ｶｼﾏｸﾞﾝ ﾉﾄｼﾞﾏﾏﾁ',
      full_lower: 'カシマグン　ノトジママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '17202七尾市', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '406',
    name: '鹿島郡　鹿西町',
    kana_name: {
      half_upper: 'ｶｼﾏｸﾞﾝ ﾛｸｾｲﾏﾁ',
      full_lower: 'カシマグン　ロクセイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '17407鹿島郡　中能登町', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '407',
    name: '鹿島郡　中能登町',
    kana_name: {
      half_upper: 'ｶｼﾏｸﾞﾝ ﾅｶﾉﾄﾏﾁ',
      full_lower: 'カシマグン　ナカノトマチ',
    },
    en: null,
    start_date: '2005-03-01',
    end_date: null,
    remark: { start: 'H17/03/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '420',
    name: '鳳至郡',
    kana_name: { half_upper: 'ﾌｹﾞｼｸﾞﾝ', full_lower: 'フゲシグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '421',
    name: '鳳至郡　穴水町',
    kana_name: {
      half_upper: 'ﾌｹﾞｼｸﾞﾝ ｱﾅﾐｽﾞﾏﾁ',
      full_lower: 'フゲシグン　アナミズマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '17461鳳珠郡　穴水町', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '422',
    name: '鳳至郡　門前町',
    kana_name: {
      half_upper: 'ﾌｹﾞｼｸﾞﾝ ﾓﾝｾﾞﾝﾏﾁ',
      full_lower: 'フゲシグン　モンゼンマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '17462鳳珠郡　門前町', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '423',
    name: '鳳至郡　能都町',
    kana_name: {
      half_upper: 'ﾌｹﾞｼｸﾞﾝ ﾉﾄﾏﾁ',
      full_lower: 'フゲシグン　ノトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '17463鳳珠郡　能登町', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '424',
    name: '鳳至郡　柳田村',
    kana_name: {
      half_upper: 'ﾌｹﾞｼｸﾞﾝ ﾔﾅｷﾞﾀﾞﾑﾗ',
      full_lower: 'フゲシグン　ヤナギダムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '17463鳳珠郡　能登町', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '440',
    name: '珠洲郡',
    kana_name: { half_upper: 'ｽｽﾞｸﾞﾝ', full_lower: 'スズグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '441',
    name: '珠洲郡　内浦町',
    kana_name: {
      half_upper: 'ｽｽﾞｸﾞﾝ ｳﾁｳﾗﾏﾁ',
      full_lower: 'スズグン　ウチウラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '17463鳳珠郡　能登町', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '460',
    name: '鳳珠郡',
    kana_name: { half_upper: 'ﾎｳｽｸﾞﾝ', full_lower: 'ホウスグン' },
    en: null,
    start_date: '2005-03-01',
    end_date: null,
    remark: {
      start: 'H17/03/01新設(鳳至郡・珠洲郡)',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '461',
    name: '鳳珠郡　穴水町',
    kana_name: {
      half_upper: 'ﾎｳｽｸﾞﾝ ｱﾅﾐｽﾞﾏﾁ',
      full_lower: 'ホウスグン　アナミズマチ',
    },
    en: null,
    start_date: '2005-03-01',
    end_date: null,
    remark: { start: 'H17/03/01郡変更', end: null, other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '462',
    name: '鳳珠郡　門前町',
    kana_name: {
      half_upper: 'ﾎｳｽｸﾞﾝ ﾓﾝｾﾞﾝﾏﾁ',
      full_lower: 'ホウスグン　モンゼンマチ',
    },
    en: null,
    start_date: '2005-03-01',
    end_date: '2006-02-01',
    remark: { start: 'H17/03/01郡変更', end: '17204輪島市', other: null },
    note: null,
  },
  {
    pref_code: '17',
    city_code: '463',
    name: '鳳珠郡　能登町',
    kana_name: {
      half_upper: 'ﾎｳｽｸﾞﾝ ﾉﾄﾁﾖｳ',
      full_lower: 'ホウスグン　ノトチョウ',
    },
    en: null,
    start_date: '2005-03-01',
    end_date: null,
    remark: { start: 'H17/03/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '201',
    name: '福井市',
    kana_name: { half_upper: 'ﾌｸｲｼ', full_lower: 'フクイシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '202',
    name: '敦賀市',
    kana_name: { half_upper: 'ﾂﾙｶﾞｼ', full_lower: 'ツルガシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '203',
    name: '武生市',
    kana_name: { half_upper: 'ﾀｹﾌｼ', full_lower: 'タケフシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '18209越前市', other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '204',
    name: '小浜市',
    kana_name: { half_upper: 'ｵﾊﾞﾏｼ', full_lower: 'オバマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '205',
    name: '大野市',
    kana_name: { half_upper: 'ｵｵﾉｼ', full_lower: 'オオノシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '206',
    name: '勝山市',
    kana_name: { half_upper: 'ｶﾂﾔﾏｼ', full_lower: 'カツヤマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '207',
    name: '鯖江市',
    kana_name: { half_upper: 'ｻﾊﾞｴｼ', full_lower: 'サバエシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '208',
    name: 'あわら市',
    kana_name: { half_upper: 'ｱﾜﾗｼ', full_lower: 'アワラシ' },
    en: null,
    start_date: '2004-03-01',
    end_date: null,
    remark: { start: 'H16/03/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '209',
    name: '越前市',
    kana_name: { half_upper: 'ｴﾁｾﾞﾝｼ', full_lower: 'エチゼンシ' },
    en: null,
    start_date: '2005-10-01',
    end_date: null,
    remark: { start: 'H17/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '210',
    name: '坂井市',
    kana_name: { half_upper: 'ｻｶｲｼ', full_lower: 'サカイシ' },
    en: null,
    start_date: '2006-03-20',
    end_date: null,
    remark: { start: 'H18/03/20新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '300',
    name: '足羽郡',
    kana_name: { half_upper: 'ｱｽﾜｸﾞﾝ', full_lower: 'アスワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '301',
    name: '足羽郡　足羽町',
    kana_name: {
      half_upper: 'ｱｽﾜｸﾞﾝ ｱｽﾜﾁﾖｳ',
      full_lower: 'アスワグン　アスワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-09-01',
    remark: { start: null, end: '18201福井市', other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '302',
    name: '足羽郡　美山町',
    kana_name: {
      half_upper: 'ｱｽﾜｸﾞﾝ ﾐﾔﾏﾁﾖｳ',
      full_lower: 'アスワグン　ミヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-01',
    remark: { start: null, end: '18201福井市', other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '320',
    name: '吉田郡',
    kana_name: { half_upper: 'ﾖｼﾀﾞｸﾞﾝ', full_lower: 'ヨシダグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '321',
    name: '吉田郡　松岡町',
    kana_name: {
      half_upper: 'ﾖｼﾀﾞｸﾞﾝ ﾏﾂｵｶﾁﾖｳ',
      full_lower: 'ヨシダグン　マツオカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-13',
    remark: { start: null, end: '18322吉田郡　永平寺町', other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '322',
    name: '吉田郡　永平寺町',
    kana_name: {
      half_upper: 'ﾖｼﾀﾞｸﾞﾝ ｴｲﾍｲｼﾞﾁﾖｳ',
      full_lower: 'ヨシダグン　エイヘイジチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '323',
    name: '吉田郡　上志比村',
    kana_name: {
      half_upper: 'ﾖｼﾀﾞｸﾞﾝ ｶﾐｼﾋﾑﾗ',
      full_lower: 'ヨシダグン　カミシヒムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-13',
    remark: { start: null, end: '18322吉田郡　永平寺町', other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '340',
    name: '大野郡',
    kana_name: { half_upper: 'ｵｵﾉｸﾞﾝ', full_lower: 'オオノグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-03',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '341',
    name: '大野郡　西谷村',
    kana_name: {
      half_upper: 'ｵｵﾉｸﾞﾝ ﾆｼﾀﾆﾑﾗ',
      full_lower: 'オオノグン　ニシタニムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-07-01',
    remark: { start: null, end: '18205大野市', other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '342',
    name: '大野郡　和泉村',
    kana_name: {
      half_upper: 'ｵｵﾉｸﾞﾝ ｲｽﾞﾐﾑﾗ',
      full_lower: 'オオノグン　イズミムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-03',
    remark: { start: null, end: '18205大野市', other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '360',
    name: '坂井郡',
    kana_name: { half_upper: 'ｻｶｲｸﾞﾝ', full_lower: 'サカイグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '361',
    name: '坂井郡　三国町',
    kana_name: {
      half_upper: 'ｻｶｲｸﾞﾝ ﾐｸﾆﾁﾖｳ',
      full_lower: 'サカイグン　ミクニチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '18210坂井市', other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '362',
    name: '坂井郡　芦原町',
    kana_name: {
      half_upper: 'ｻｶｲｸﾞﾝ ｱﾜﾗﾁﾖｳ',
      full_lower: 'サカイグン　アワラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '18208あわら市', other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '363',
    name: '坂井郡　金津町',
    kana_name: {
      half_upper: 'ｻｶｲｸﾞﾝ ｶﾅﾂﾞﾁﾖｳ',
      full_lower: 'サカイグン　カナヅチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '18208あわら市', other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '364',
    name: '坂井郡　丸岡町',
    kana_name: {
      half_upper: 'ｻｶｲｸﾞﾝ ﾏﾙｵｶﾁﾖｳ',
      full_lower: 'サカイグン　マルオカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '18210坂井市', other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '365',
    name: '坂井郡　春江町',
    kana_name: {
      half_upper: 'ｻｶｲｸﾞﾝ ﾊﾙｴﾁﾖｳ',
      full_lower: 'サカイグン　ハルエチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '18210坂井市', other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '366',
    name: '坂井郡　坂井町',
    kana_name: {
      half_upper: 'ｻｶｲｸﾞﾝ ｻｶｲﾁﾖｳ',
      full_lower: 'サカイグン　サカイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '18210坂井市', other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '380',
    name: '今立郡',
    kana_name: { half_upper: 'ｲﾏﾀﾞﾃｸﾞﾝ', full_lower: 'イマダテグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '381',
    name: '今立郡　今立町',
    kana_name: {
      half_upper: 'ｲﾏﾀﾞﾃｸﾞﾝ ｲﾏﾀﾞﾃﾁﾖｳ',
      full_lower: 'イマダテグン　イマダテチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '18209越前市', other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '382',
    name: '今立郡　池田町',
    kana_name: {
      half_upper: 'ｲﾏﾀﾞﾃｸﾞﾝ ｲｹﾀﾞﾁﾖｳ',
      full_lower: 'イマダテグン　イケダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '400',
    name: '南条郡',
    kana_name: { half_upper: 'ﾅﾝｼﾞﾖｳｸﾞﾝ', full_lower: 'ナンジョウグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '401',
    name: '南条郡　南条町',
    kana_name: {
      half_upper: 'ﾅﾝｼﾞﾖｳｸﾞﾝ ﾅﾝｼﾞﾖｳﾁﾖｳ',
      full_lower: 'ナンジョウグン　ナンジョウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '18404南条郡　南越前町', other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '402',
    name: '南条郡　今庄町',
    kana_name: {
      half_upper: 'ﾅﾝｼﾞﾖｳｸﾞﾝ ｲﾏｼﾞﾖｳﾁﾖｳ',
      full_lower: 'ナンジョウグン　イマジョウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '18404南条郡　南越前町', other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '403',
    name: '南条郡　河野村',
    kana_name: {
      half_upper: 'ﾅﾝｼﾞﾖｳｸﾞﾝ ｺｳﾉﾑﾗ',
      full_lower: 'ナンジョウグン　コウノムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '18404南条郡　南越前町', other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '404',
    name: '南条郡　南越前町',
    kana_name: {
      half_upper: 'ﾅﾝｼﾞﾖｳｸﾞﾝ ﾐﾅﾐｴﾁｾﾞﾝﾁﾖｳ',
      full_lower: 'ナンジョウグン　ミナミエチゼンチョウ',
    },
    en: null,
    start_date: '2005-01-01',
    end_date: null,
    remark: { start: 'H17/01/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '420',
    name: '丹生郡',
    kana_name: { half_upper: 'ﾆﾕｳｸﾞﾝ', full_lower: 'ニュウグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '421',
    name: '丹生郡　朝日町',
    kana_name: {
      half_upper: 'ﾆﾕｳｸﾞﾝ ｱｻﾋﾁﾖｳ',
      full_lower: 'ニュウグン　アサヒチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '18423丹生郡　越前町', other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '422',
    name: '丹生郡　宮崎村',
    kana_name: {
      half_upper: 'ﾆﾕｳｸﾞﾝ ﾐﾔｻﾞｷﾑﾗ',
      full_lower: 'ニュウグン　ミヤザキムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '18423丹生郡　越前町', other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '423',
    name: '丹生郡　越前町',
    kana_name: {
      half_upper: 'ﾆﾕｳｸﾞﾝ ｴﾁｾﾞﾝﾁﾖｳ',
      full_lower: 'ニュウグン　エチゼンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '424',
    name: '丹生郡　越廼村',
    kana_name: {
      half_upper: 'ﾆﾕｳｸﾞﾝ ｺｼﾉﾑﾗ',
      full_lower: 'ニュウグン　コシノムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-01',
    remark: { start: null, end: '18201福井市', other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '425',
    name: '丹生郡　織田町',
    kana_name: {
      half_upper: 'ﾆﾕｳｸﾞﾝ ｵﾀﾁﾖｳ',
      full_lower: 'ニュウグン　オタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '18423丹生郡　越前町', other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '426',
    name: '丹生郡　清水町',
    kana_name: {
      half_upper: 'ﾆﾕｳｸﾞﾝ ｼﾐｽﾞﾁﾖｳ',
      full_lower: 'ニュウグン　シミズチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-01',
    remark: { start: null, end: '18201福井市', other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '440',
    name: '三方郡',
    kana_name: { half_upper: 'ﾐｶﾀｸﾞﾝ', full_lower: 'ミカタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '441',
    name: '三方郡　三方町',
    kana_name: {
      half_upper: 'ﾐｶﾀｸﾞﾝ ﾐｶﾀﾁﾖｳ',
      full_lower: 'ミカタグン　ミカタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '18501三方上中郡　若狭町', other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '442',
    name: '三方郡　美浜町',
    kana_name: {
      half_upper: 'ﾐｶﾀｸﾞﾝ ﾐﾊﾏﾁﾖｳ',
      full_lower: 'ミカタグン　ミハマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '460',
    name: '遠敷郡',
    kana_name: { half_upper: 'ｵﾆﾕｳｸﾞﾝ', full_lower: 'オニュウグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-03',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '461',
    name: '遠敷郡　上中町',
    kana_name: {
      half_upper: 'ｵﾆﾕｳｸﾞﾝ ｶﾐﾅｶﾁﾖｳ',
      full_lower: 'オニュウグン　カミナカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '18501三方上中郡　若狭町', other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '462',
    name: '遠敷郡　名田庄村',
    kana_name: {
      half_upper: 'ｵﾆﾕｳｸﾞﾝ ﾅﾀｼﾖｳﾑﾗ',
      full_lower: 'オニュウグン　ナタショウムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-03',
    remark: { start: null, end: '18483大飯郡　おおい町', other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '480',
    name: '大飯郡',
    kana_name: { half_upper: 'ｵｵｲｸﾞﾝ', full_lower: 'オオイグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '481',
    name: '大飯郡　高浜町',
    kana_name: {
      half_upper: 'ｵｵｲｸﾞﾝ ﾀｶﾊﾏﾁﾖｳ',
      full_lower: 'オオイグン　タカハマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '482',
    name: '大飯郡　大飯町',
    kana_name: {
      half_upper: 'ｵｵｲｸﾞﾝ ｵｵｲﾁﾖｳ',
      full_lower: 'オオイグン　オオイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-03',
    remark: { start: null, end: '18483大飯郡　おおい町', other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '483',
    name: '大飯郡　おおい町',
    kana_name: {
      half_upper: 'ｵｵｲｸﾞﾝ ｵｵｲﾁﾖｳ',
      full_lower: 'オオイグン　オオイチョウ',
    },
    en: null,
    start_date: '2006-03-03',
    end_date: null,
    remark: { start: 'H18/03/03新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '500',
    name: '三方上中郡',
    kana_name: { half_upper: 'ﾐｶﾀｶﾐﾅｶｸﾞﾝ', full_lower: 'ミカタカミナカグン' },
    en: null,
    start_date: '2005-03-31',
    end_date: null,
    remark: {
      start: 'H17/03/31新設(三方郡・遠敷郡)',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '18',
    city_code: '501',
    name: '三方上中郡　若狭町',
    kana_name: {
      half_upper: 'ﾐｶﾀｶﾐﾅｶｸﾞﾝ ﾜｶｻﾁﾖｳ',
      full_lower: 'ミカタカミナカグン　ワカサチョウ',
    },
    en: null,
    start_date: '2005-03-31',
    end_date: null,
    remark: { start: 'H17/03/31新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '201',
    name: '甲府市',
    kana_name: { half_upper: 'ｺｳﾌｼ', full_lower: 'コウフシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '202',
    name: '富士吉田市',
    kana_name: { half_upper: 'ﾌｼﾞﾖｼﾀﾞｼ', full_lower: 'フジヨシダシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '203',
    name: '塩山市',
    kana_name: { half_upper: 'ｴﾝｻﾞﾝｼ', full_lower: 'エンザンシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-01',
    remark: { start: null, end: '19213甲州市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '204',
    name: '都留市',
    kana_name: { half_upper: 'ﾂﾙｼ', full_lower: 'ツルシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '205',
    name: '山梨市',
    kana_name: { half_upper: 'ﾔﾏﾅｼｼ', full_lower: 'ヤマナシシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '206',
    name: '大月市',
    kana_name: { half_upper: 'ｵｵﾂｷｼ', full_lower: 'オオツキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '207',
    name: '韮崎市',
    kana_name: { half_upper: 'ﾆﾗｻｷｼ', full_lower: 'ニラサキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '208',
    name: '南アルプス市',
    kana_name: { half_upper: 'ﾐﾅﾐｱﾙﾌﾟｽｼ', full_lower: 'ミナミアルプスシ' },
    en: null,
    start_date: '2003-04-01',
    end_date: null,
    remark: { start: 'H15/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '209',
    name: '北杜市',
    kana_name: { half_upper: 'ﾎｸﾄｼ', full_lower: 'ホクトシ' },
    en: null,
    start_date: '2004-11-01',
    end_date: null,
    remark: { start: 'H16/11/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '210',
    name: '甲斐市',
    kana_name: { half_upper: 'ｶｲｼ', full_lower: 'カイシ' },
    en: null,
    start_date: '2004-09-01',
    end_date: null,
    remark: { start: 'H16/09/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '211',
    name: '笛吹市',
    kana_name: { half_upper: 'ﾌｴﾌｷｼ', full_lower: 'フエフキシ' },
    en: null,
    start_date: '2004-10-12',
    end_date: null,
    remark: { start: 'H16/10/12新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '212',
    name: '上野原市',
    kana_name: { half_upper: 'ｳｴﾉﾊﾗｼ', full_lower: 'ウエノハラシ' },
    en: null,
    start_date: '2005-02-13',
    end_date: null,
    remark: { start: 'H17/02/13新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '213',
    name: '甲州市',
    kana_name: { half_upper: 'ｺｳｼﾕｳｼ', full_lower: 'コウシュウシ' },
    en: null,
    start_date: '2005-11-01',
    end_date: null,
    remark: { start: 'H17/11/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '214',
    name: '中央市',
    kana_name: { half_upper: 'ﾁﾕｳｵｳｼ', full_lower: 'チュウオウシ' },
    en: null,
    start_date: '2006-02-20',
    end_date: null,
    remark: { start: 'H18/02/20新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '300',
    name: '東山梨郡',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾔﾏﾅｼｸﾞﾝ',
      full_lower: 'ヒガシヤマナシグン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '301',
    name: '東山梨郡　春日居町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾔﾏﾅｼｸﾞﾝ ｶｽｶﾞｲﾁﾖｳ',
      full_lower: 'ヒガシヤマナシグン　カスガイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-12',
    remark: { start: null, end: '19211笛吹市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '302',
    name: '東山梨郡　牧丘町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾔﾏﾅｼｸﾞﾝ ﾏｷｵｶﾁﾖｳ',
      full_lower: 'ヒガシヤマナシグン　マキオカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '19205山梨市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '303',
    name: '東山梨郡　三富村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾔﾏﾅｼｸﾞﾝ ﾐﾄﾐﾑﾗ',
      full_lower: 'ヒガシヤマナシグン　ミトミムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '19205山梨市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '304',
    name: '東山梨郡　勝沼町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾔﾏﾅｼｸﾞﾝ ｶﾂﾇﾏﾁﾖｳ',
      full_lower: 'ヒガシヤマナシグン　カツヌマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-01',
    remark: { start: null, end: '19213甲州市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '305',
    name: '東山梨郡　大和村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾔﾏﾅｼｸﾞﾝ ﾔﾏﾄﾑﾗ',
      full_lower: 'ヒガシヤマナシグン　ヤマトムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-01',
    remark: { start: null, end: '19213甲州市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '320',
    name: '東八代郡',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾔﾂｼﾛｸﾞﾝ',
      full_lower: 'ヒガシヤツシログン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-08-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '321',
    name: '東八代郡　石和町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾔﾂｼﾛｸﾞﾝ ｲｻﾜﾁﾖｳ',
      full_lower: 'ヒガシヤツシログン　イサワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-12',
    remark: { start: null, end: '19211笛吹市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '322',
    name: '東八代郡　御坂町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾔﾂｼﾛｸﾞﾝ ﾐｻｶﾁﾖｳ',
      full_lower: 'ヒガシヤツシログン　ミサカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-12',
    remark: { start: null, end: '19211笛吹市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '323',
    name: '東八代郡　一宮町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾔﾂｼﾛｸﾞﾝ ｲﾁﾉﾐﾔﾁﾖｳ',
      full_lower: 'ヒガシヤツシログン　イチノミヤチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-12',
    remark: { start: null, end: '19211笛吹市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '324',
    name: '東八代郡　八代町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾔﾂｼﾛｸﾞﾝ ﾔﾂｼﾛﾁﾖｳ',
      full_lower: 'ヒガシヤツシログン　ヤツシロチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-12',
    remark: { start: null, end: '19211笛吹市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '325',
    name: '東八代郡　境川村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾔﾂｼﾛｸﾞﾝ ｻｶｲｶﾞﾜﾑﾗ',
      full_lower: 'ヒガシヤツシログン　サカイガワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-12',
    remark: { start: null, end: '19211笛吹市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '326',
    name: '東八代郡　中道町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾔﾂｼﾛｸﾞﾝ ﾅｶﾐﾁﾏﾁ',
      full_lower: 'ヒガシヤツシログン　ナカミチマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '19201甲府市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '327',
    name: '東八代郡　芦川村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾔﾂｼﾛｸﾞﾝ ｱｼｶﾞﾜﾑﾗ',
      full_lower: 'ヒガシヤツシログン　アシガワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-08-01',
    remark: { start: null, end: '19211笛吹市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '328',
    name: '東八代郡　豊富村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾔﾂｼﾛｸﾞﾝ ﾄﾖﾄﾐﾑﾗ',
      full_lower: 'ヒガシヤツシログン　トヨトミムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-20',
    remark: { start: null, end: '19214中央市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '340',
    name: '西八代郡',
    kana_name: { half_upper: 'ﾆｼﾔﾂｼﾛｸﾞﾝ', full_lower: 'ニシヤツシログン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '341',
    name: '西八代郡　上九一色村',
    kana_name: {
      half_upper: 'ﾆｼﾔﾂｼﾛｸﾞﾝ ｶﾐｸｲｼｷﾑﾗ',
      full_lower: 'ニシヤツシログン　カミクイシキムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '19430南都留郡　富士河口湖町', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '342',
    name: '西八代郡　三珠町',
    kana_name: {
      half_upper: 'ﾆｼﾔﾂｼﾛｸﾞﾝ ﾐﾀﾏﾁﾖｳ',
      full_lower: 'ニシヤツシログン　ミタマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '19346西八代郡　市川三郷町', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '343',
    name: '西八代郡　市川大門町',
    kana_name: {
      half_upper: 'ﾆｼﾔﾂｼﾛｸﾞﾝ ｲﾁｶﾜﾀﾞｲﾓﾝﾁﾖｳ',
      full_lower: 'ニシヤツシログン　イチカワダイモンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '19346西八代郡　市川三郷町', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '344',
    name: '西八代郡　六郷町',
    kana_name: {
      half_upper: 'ﾆｼﾔﾂｼﾛｸﾞﾝ ﾛｸｺﾞｳﾁﾖｳ',
      full_lower: 'ニシヤツシログン　ロクゴウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '19346西八代郡　市川三郷町', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '345',
    name: '西八代郡　下部町',
    kana_name: {
      half_upper: 'ﾆｼﾔﾂｼﾛｸﾞﾝ ｼﾓﾍﾞﾁﾖｳ',
      full_lower: 'ニシヤツシログン　シモベチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-09-13',
    remark: { start: null, end: '19365南巨摩郡　身延町', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '346',
    name: '西八代郡　市川三郷町',
    kana_name: {
      half_upper: 'ﾆｼﾔﾂｼﾛｸﾞﾝ ｲﾁｶﾜﾐｻﾄﾁﾖｳ',
      full_lower: 'ニシヤツシログン　イチカワミサトチョウ',
    },
    en: null,
    start_date: '2005-10-01',
    end_date: null,
    remark: { start: 'H17/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '360',
    name: '南巨摩郡',
    kana_name: { half_upper: 'ﾐﾅﾐｺﾏｸﾞﾝ', full_lower: 'ミナミコマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '361',
    name: '南巨摩郡　増穂町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｺﾏｸﾞﾝ ﾏｽﾎﾁﾖｳ',
      full_lower: 'ミナミコマグン　マスホチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-08',
    remark: { start: null, end: '19368南巨摩郡　富士川町', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '362',
    name: '南巨摩郡　鰍沢町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｺﾏｸﾞﾝ ｶｼﾞｶｻﾞﾜﾁﾖｳ',
      full_lower: 'ミナミコマグン　カジカザワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-08',
    remark: { start: null, end: '19368南巨摩郡　富士川町', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '363',
    name: '南巨摩郡　中富町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｺﾏｸﾞﾝ ﾅｶﾄﾐﾁﾖｳ',
      full_lower: 'ミナミコマグン　ナカトミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-09-13',
    remark: { start: null, end: '19365南巨摩郡　身延町', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '364',
    name: '南巨摩郡　早川町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｺﾏｸﾞﾝ ﾊﾔｶﾜﾁﾖｳ',
      full_lower: 'ミナミコマグン　ハヤカワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '365',
    name: '南巨摩郡　身延町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｺﾏｸﾞﾝ ﾐﾉﾌﾞﾁﾖｳ',
      full_lower: 'ミナミコマグン　ミノブチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '366',
    name: '南巨摩郡　南部町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｺﾏｸﾞﾝ ﾅﾝﾌﾞﾁﾖｳ',
      full_lower: 'ミナミコマグン　ナンブチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '367',
    name: '南巨摩郡　富沢町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｺﾏｸﾞﾝ ﾄﾐｻﾞﾜﾁﾖｳ',
      full_lower: 'ミナミコマグン　トミザワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-03-01',
    remark: { start: null, end: '19366南巨摩郡　南部町', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '368',
    name: '南巨摩郡　富士川町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｺﾏｸﾞﾝ ﾌｼﾞｶﾜﾁﾖｳ',
      full_lower: 'ミナミコマグン　フジカワチョウ',
    },
    en: null,
    start_date: '2010-03-08',
    end_date: null,
    remark: { start: 'H22/03/08新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '380',
    name: '中巨摩郡',
    kana_name: { half_upper: 'ﾅｶｺﾏｸﾞﾝ', full_lower: 'ナカコマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '381',
    name: '中巨摩郡　竜王町',
    kana_name: {
      half_upper: 'ﾅｶｺﾏｸﾞﾝ ﾘﾕｳｵｳﾁﾖｳ',
      full_lower: 'ナカコマグン　リュウオウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-09-01',
    remark: { start: null, end: '19210甲斐市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '382',
    name: '中巨摩郡　敷島町',
    kana_name: {
      half_upper: 'ﾅｶｺﾏｸﾞﾝ ｼｷｼﾏﾏﾁ',
      full_lower: 'ナカコマグン　シキシママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-09-01',
    remark: { start: null, end: '19210甲斐市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '383',
    name: '中巨摩郡　玉穂町',
    kana_name: {
      half_upper: 'ﾅｶｺﾏｸﾞﾝ ﾀﾏﾎﾁﾖｳ',
      full_lower: 'ナカコマグン　タマホチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-20',
    remark: { start: null, end: '19214中央市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '384',
    name: '中巨摩郡　昭和町',
    kana_name: {
      half_upper: 'ﾅｶｺﾏｸﾞﾝ ｼﾖｳﾜﾁﾖｳ',
      full_lower: 'ナカコマグン　ショウワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '385',
    name: '中巨摩郡　田富町',
    kana_name: {
      half_upper: 'ﾅｶｺﾏｸﾞﾝ ﾀﾄﾐﾁﾖｳ',
      full_lower: 'ナカコマグン　タトミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-20',
    remark: { start: null, end: '19214中央市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '386',
    name: '中巨摩郡　八田村',
    kana_name: {
      half_upper: 'ﾅｶｺﾏｸﾞﾝ ﾊﾂﾀﾑﾗ',
      full_lower: 'ナカコマグン　ハッタムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: '19208南アルプス市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '387',
    name: '中巨摩郡　白根町',
    kana_name: {
      half_upper: 'ﾅｶｺﾏｸﾞﾝ ｼﾗﾈﾏﾁ',
      full_lower: 'ナカコマグン　シラネマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: '19208南アルプス市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '388',
    name: '中巨摩郡　芦安村',
    kana_name: {
      half_upper: 'ﾅｶｺﾏｸﾞﾝ ｱｼﾔｽﾑﾗ',
      full_lower: 'ナカコマグン　アシヤスムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: '19208南アルプス市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '389',
    name: '中巨摩郡　若草町',
    kana_name: {
      half_upper: 'ﾅｶｺﾏｸﾞﾝ ﾜｶｸｻﾁﾖｳ',
      full_lower: 'ナカコマグン　ワカクサチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: '19208南アルプス市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '390',
    name: '中巨摩郡　櫛形町',
    kana_name: {
      half_upper: 'ﾅｶｺﾏｸﾞﾝ ｸｼｶﾞﾀﾏﾁ',
      full_lower: 'ナカコマグン　クシガタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: '19208南アルプス市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '391',
    name: '中巨摩郡　甲西町',
    kana_name: {
      half_upper: 'ﾅｶｺﾏｸﾞﾝ ｺｳｻｲﾏﾁ',
      full_lower: 'ナカコマグン　コウサイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: '19208南アルプス市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '400',
    name: '北巨摩郡',
    kana_name: { half_upper: 'ｷﾀｺﾏｸﾞﾝ', full_lower: 'キタコマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-15',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '401',
    name: '北巨摩郡　双葉町',
    kana_name: {
      half_upper: 'ｷﾀｺﾏｸﾞﾝ ﾌﾀﾊﾞﾁﾖｳ',
      full_lower: 'キタコマグン　フタバチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-09-01',
    remark: { start: null, end: '19210甲斐市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '402',
    name: '北巨摩郡　明野村',
    kana_name: {
      half_upper: 'ｷﾀｺﾏｸﾞﾝ ｱｹﾉﾑﾗ',
      full_lower: 'キタコマグン　アケノムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '19209北杜市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '403',
    name: '北巨摩郡　須玉町',
    kana_name: {
      half_upper: 'ｷﾀｺﾏｸﾞﾝ ｽﾀﾏﾁﾖｳ',
      full_lower: 'キタコマグン　スタマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '19209北杜市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '404',
    name: '北巨摩郡　高根町',
    kana_name: {
      half_upper: 'ｷﾀｺﾏｸﾞﾝ ﾀｶﾈﾁﾖｳ',
      full_lower: 'キタコマグン　タカネチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '19209北杜市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '405',
    name: '北巨摩郡　長坂町',
    kana_name: {
      half_upper: 'ｷﾀｺﾏｸﾞﾝ ﾅｶﾞｻｶﾁﾖｳ',
      full_lower: 'キタコマグン　ナガサカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '19209北杜市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '406',
    name: '北巨摩郡　大泉村',
    kana_name: {
      half_upper: 'ｷﾀｺﾏｸﾞﾝ ｵｵｲｽﾞﾐﾑﾗ',
      full_lower: 'キタコマグン　オオイズミムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '19209北杜市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '407',
    name: '北巨摩郡　小淵沢町',
    kana_name: {
      half_upper: 'ｷﾀｺﾏｸﾞﾝ ｺﾌﾞﾁｻﾜﾁﾖｳ',
      full_lower: 'キタコマグン　コブチサワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-15',
    remark: { start: null, end: '19209北杜市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '408',
    name: '北巨摩郡　白州町',
    kana_name: {
      half_upper: 'ｷﾀｺﾏｸﾞﾝ ﾊｸｼﾕｳﾏﾁ',
      full_lower: 'キタコマグン　ハクシュウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '19209北杜市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '409',
    name: '北巨摩郡　武川村',
    kana_name: {
      half_upper: 'ｷﾀｺﾏｸﾞﾝ ﾑｶﾜﾑﾗ',
      full_lower: 'キタコマグン　ムカワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '19209北杜市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '420',
    name: '南都留郡',
    kana_name: { half_upper: 'ﾐﾅﾐﾂﾙｸﾞﾝ', full_lower: 'ミナミツルグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '421',
    name: '南都留郡　秋山村',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾂﾙｸﾞﾝ ｱｷﾔﾏﾑﾗ',
      full_lower: 'ミナミツルグン　アキヤマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-13',
    remark: { start: null, end: '19212上野原市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '422',
    name: '南都留郡　道志村',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾂﾙｸﾞﾝ ﾄﾞｳｼﾑﾗ',
      full_lower: 'ミナミツルグン　ドウシムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '423',
    name: '南都留郡　西桂町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾂﾙｸﾞﾝ ﾆｼｶﾂﾗﾁﾖｳ',
      full_lower: 'ミナミツルグン　ニシカツラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '424',
    name: '南都留郡　忍野村',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾂﾙｸﾞﾝ ｵｼﾉﾑﾗ',
      full_lower: 'ミナミツルグン　オシノムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '425',
    name: '南都留郡　山中湖村',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾂﾙｸﾞﾝ ﾔﾏﾅｶｺﾑﾗ',
      full_lower: 'ミナミツルグン　ヤマナカコムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '426',
    name: '南都留郡　河口湖町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾂﾙｸﾞﾝ ｶﾜｸﾞﾁｺﾏﾁ',
      full_lower: 'ミナミツルグン　カワグチコマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-11-15',
    remark: { start: null, end: '19430南都留郡　富士河口湖町', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '427',
    name: '南都留郡　勝山村',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾂﾙｸﾞﾝ ｶﾂﾔﾏﾑﾗ',
      full_lower: 'ミナミツルグン　カツヤマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-11-15',
    remark: { start: null, end: '19430南都留郡　富士河口湖町', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '428',
    name: '南都留郡　足和田村',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾂﾙｸﾞﾝ ｱｼﾜﾀﾞﾑﾗ',
      full_lower: 'ミナミツルグン　アシワダムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-11-15',
    remark: { start: null, end: '19430南都留郡　富士河口湖町', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '429',
    name: '南都留郡　鳴沢村',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾂﾙｸﾞﾝ ﾅﾙｻﾜﾑﾗ',
      full_lower: 'ミナミツルグン　ナルサワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '430',
    name: '南都留郡　富士河口湖町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾂﾙｸﾞﾝ ﾌｼﾞｶﾜｸﾞﾁｺﾏﾁ',
      full_lower: 'ミナミツルグン　フジカワグチコマチ',
    },
    en: null,
    start_date: '2003-11-15',
    end_date: null,
    remark: { start: 'H15/11/15新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '440',
    name: '北都留郡',
    kana_name: { half_upper: 'ｷﾀﾂﾙｸﾞﾝ', full_lower: 'キタツルグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '441',
    name: '北都留郡　上野原町',
    kana_name: {
      half_upper: 'ｷﾀﾂﾙｸﾞﾝ ｳｴﾉﾊﾗﾏﾁ',
      full_lower: 'キタツルグン　ウエノハラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-13',
    remark: { start: null, end: '19212上野原市', other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '442',
    name: '北都留郡　小菅村',
    kana_name: {
      half_upper: 'ｷﾀﾂﾙｸﾞﾝ ｺｽｹﾞﾑﾗ',
      full_lower: 'キタツルグン　コスゲムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '19',
    city_code: '443',
    name: '北都留郡　丹波山村',
    kana_name: {
      half_upper: 'ｷﾀﾂﾙｸﾞﾝ ﾀﾊﾞﾔﾏﾑﾗ',
      full_lower: 'キタツルグン　タバヤマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '201',
    name: '長野市',
    kana_name: { half_upper: 'ﾅｶﾞﾉｼ', full_lower: 'ナガノシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '202',
    name: '松本市',
    kana_name: { half_upper: 'ﾏﾂﾓﾄｼ', full_lower: 'マツモトシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '203',
    name: '上田市',
    kana_name: { half_upper: 'ｳｴﾀﾞｼ', full_lower: 'ウエダシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '204',
    name: '岡谷市',
    kana_name: { half_upper: 'ｵｶﾔｼ', full_lower: 'オカヤシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '205',
    name: '飯田市',
    kana_name: { half_upper: 'ｲｲﾀﾞｼ', full_lower: 'イイダシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '206',
    name: '諏訪市',
    kana_name: { half_upper: 'ｽﾜｼ', full_lower: 'スワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '207',
    name: '須坂市',
    kana_name: { half_upper: 'ｽｻﾞｶｼ', full_lower: 'スザカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '208',
    name: '小諸市',
    kana_name: { half_upper: 'ｺﾓﾛｼ', full_lower: 'コモロシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '209',
    name: '伊那市',
    kana_name: { half_upper: 'ｲﾅｼ', full_lower: 'イナシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '210',
    name: '駒ヶ根市',
    kana_name: { half_upper: 'ｺﾏｶﾞﾈｼ', full_lower: 'コマガネシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '211',
    name: '中野市',
    kana_name: { half_upper: 'ﾅｶﾉｼ', full_lower: 'ナカノシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '212',
    name: '大町市',
    kana_name: { half_upper: 'ｵｵﾏﾁｼ', full_lower: 'オオマチシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '213',
    name: '飯山市',
    kana_name: { half_upper: 'ｲｲﾔﾏｼ', full_lower: 'イイヤマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '214',
    name: '茅野市',
    kana_name: { half_upper: 'ﾁﾉｼ', full_lower: 'チノシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '215',
    name: '塩尻市',
    kana_name: { half_upper: 'ｼｵｼﾞﾘｼ', full_lower: 'シオジリシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '216',
    name: '更埴市',
    kana_name: { half_upper: 'ｺｳｼﾖｸｼ', full_lower: 'コウショクシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-09-01',
    remark: { start: null, end: '20218千曲市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '217',
    name: '佐久市',
    kana_name: { half_upper: 'ｻｸｼ', full_lower: 'サクシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '218',
    name: '千曲市',
    kana_name: { half_upper: 'ﾁｸﾏｼ', full_lower: 'チクマシ' },
    en: null,
    start_date: '2003-09-01',
    end_date: null,
    remark: { start: 'H15/09/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '219',
    name: '東御市',
    kana_name: { half_upper: 'ﾄｳﾐｼ', full_lower: 'トウミシ' },
    en: null,
    start_date: '2004-04-01',
    end_date: null,
    remark: { start: 'H16/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '220',
    name: '安曇野市',
    kana_name: { half_upper: 'ｱﾂﾞﾐﾉｼ', full_lower: 'アヅミノシ' },
    en: null,
    start_date: '2005-10-01',
    end_date: null,
    remark: { start: 'H17/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '300',
    name: '南佐久郡',
    kana_name: { half_upper: 'ﾐﾅﾐｻｸｸﾞﾝ', full_lower: 'ミナミサクグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '301',
    name: '南佐久郡　臼田町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｻｸｸﾞﾝ ｳｽﾀﾞﾏﾁ',
      full_lower: 'ミナミサクグン　ウスダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '20217佐久市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '302',
    name: '南佐久郡　佐久町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｻｸｸﾞﾝ ｻｸﾏﾁ',
      full_lower: 'ミナミサクグン　サクマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-20',
    remark: { start: null, end: '20309南佐久郡　佐久穂町', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '303',
    name: '南佐久郡　小海町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｻｸｸﾞﾝ ｺｳﾐﾏﾁ',
      full_lower: 'ミナミサクグン　コウミマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '304',
    name: '南佐久郡　川上村',
    kana_name: {
      half_upper: 'ﾐﾅﾐｻｸｸﾞﾝ ｶﾜｶﾐﾑﾗ',
      full_lower: 'ミナミサクグン　カワカミムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '305',
    name: '南佐久郡　南牧村',
    kana_name: {
      half_upper: 'ﾐﾅﾐｻｸｸﾞﾝ ﾐﾅﾐﾏｷﾑﾗ',
      full_lower: 'ミナミサクグン　ミナミマキムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '306',
    name: '南佐久郡　南相木村',
    kana_name: {
      half_upper: 'ﾐﾅﾐｻｸｸﾞﾝ ﾐﾅﾐｱｲｷﾑﾗ',
      full_lower: 'ミナミサクグン　ミナミアイキムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '307',
    name: '南佐久郡　北相木村',
    kana_name: {
      half_upper: 'ﾐﾅﾐｻｸｸﾞﾝ ｷﾀｱｲｷﾑﾗ',
      full_lower: 'ミナミサクグン　キタアイキムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '308',
    name: '南佐久郡　八千穂村',
    kana_name: {
      half_upper: 'ﾐﾅﾐｻｸｸﾞﾝ ﾔﾁﾎﾑﾗ',
      full_lower: 'ミナミサクグン　ヤチホムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-20',
    remark: { start: null, end: '20309南佐久郡　佐久穂町', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '309',
    name: '南佐久郡　佐久穂町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｻｸｸﾞﾝ ｻｸﾎﾏﾁ',
      full_lower: 'ミナミサクグン　サクホマチ',
    },
    en: null,
    start_date: '2005-03-20',
    end_date: null,
    remark: { start: 'H17/03/20新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '320',
    name: '北佐久郡',
    kana_name: { half_upper: 'ｷﾀｻｸｸﾞﾝ', full_lower: 'キタサクグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '321',
    name: '北佐久郡　軽井沢町',
    kana_name: {
      half_upper: 'ｷﾀｻｸｸﾞﾝ ｶﾙｲｻﾞﾜﾏﾁ',
      full_lower: 'キタサクグン　カルイザワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '322',
    name: '北佐久郡　望月町',
    kana_name: {
      half_upper: 'ｷﾀｻｸｸﾞﾝ ﾓﾁﾂﾞｷﾏﾁ',
      full_lower: 'キタサクグン　モチヅキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '20217佐久市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '323',
    name: '北佐久郡　御代田町',
    kana_name: {
      half_upper: 'ｷﾀｻｸｸﾞﾝ ﾐﾖﾀﾏﾁ',
      full_lower: 'キタサクグン　ミヨタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '324',
    name: '北佐久郡　立科町',
    kana_name: {
      half_upper: 'ｷﾀｻｸｸﾞﾝ ﾀﾃｼﾅﾏﾁ',
      full_lower: 'キタサクグン　タテシナマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '325',
    name: '北佐久郡　浅科村',
    kana_name: {
      half_upper: 'ｷﾀｻｸｸﾞﾝ ｱｻｼﾅﾑﾗ',
      full_lower: 'キタサクグン　アサシナムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '20217佐久市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '326',
    name: '北佐久郡　北御牧村',
    kana_name: {
      half_upper: 'ｷﾀｻｸｸﾞﾝ ｷﾀﾐﾏｷﾑﾗ',
      full_lower: 'キタサクグン　キタミマキムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '20219東御市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '340',
    name: '小県郡',
    kana_name: { half_upper: 'ﾁｲｻｶﾞﾀｸﾞﾝ', full_lower: 'チイサガタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '341',
    name: '小県郡　丸子町',
    kana_name: {
      half_upper: 'ﾁｲｻｶﾞﾀｸﾞﾝ ﾏﾙｺﾏﾁ',
      full_lower: 'チイサガタグン　マルコマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-06',
    remark: { start: null, end: '20203上田市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '342',
    name: '小県郡　長門町',
    kana_name: {
      half_upper: 'ﾁｲｻｶﾞﾀｸﾞﾝ ﾅｶﾞﾄﾏﾁ',
      full_lower: 'チイサガタグン　ナガトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '20350小県郡　長和町', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '343',
    name: '小県郡　東部町',
    kana_name: {
      half_upper: 'ﾁｲｻｶﾞﾀｸﾞﾝ ﾄｳﾌﾞﾏﾁ',
      full_lower: 'チイサガタグン　トウブマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '20219東御市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '344',
    name: '小県郡　塩田町',
    kana_name: {
      half_upper: 'ﾁｲｻｶﾞﾀｸﾞﾝ ｼｵﾀﾞﾏﾁ',
      full_lower: 'チイサガタグン　シオダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-04-01',
    remark: { start: null, end: '20203上田市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '345',
    name: '小県郡　真田町',
    kana_name: {
      half_upper: 'ﾁｲｻｶﾞﾀｸﾞﾝ ｻﾅﾀﾞﾏﾁ',
      full_lower: 'チイサガタグン　サナダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-06',
    remark: { start: null, end: '20203上田市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '346',
    name: '小県郡　武石村',
    kana_name: {
      half_upper: 'ﾁｲｻｶﾞﾀｸﾞﾝ ﾀｹｼﾑﾗ',
      full_lower: 'チイサガタグン　タケシムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-06',
    remark: { start: null, end: '20203上田市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '347',
    name: '小県郡　和田村',
    kana_name: {
      half_upper: 'ﾁｲｻｶﾞﾀｸﾞﾝ ﾜﾀﾞﾑﾗ',
      full_lower: 'チイサガタグン　ワダムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '20350小県郡　長和町', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '348',
    name: '小県郡　川西村',
    kana_name: {
      half_upper: 'ﾁｲｻｶﾞﾀｸﾞﾝ ｶﾜﾆｼﾑﾗ',
      full_lower: 'チイサガタグン　カワニシムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1973-04-01',
    remark: { start: null, end: '20203上田市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '349',
    name: '小県郡　青木村',
    kana_name: {
      half_upper: 'ﾁｲｻｶﾞﾀｸﾞﾝ ｱｵｷﾑﾗ',
      full_lower: 'チイサガタグン　アオキムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '350',
    name: '小県郡　長和町',
    kana_name: {
      half_upper: 'ﾁｲｻｶﾞﾀｸﾞﾝ ﾅｶﾞﾜﾏﾁ',
      full_lower: 'チイサガタグン　ナガワマチ',
    },
    en: null,
    start_date: '2005-10-01',
    end_date: null,
    remark: { start: 'H17/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '360',
    name: '諏訪郡',
    kana_name: { half_upper: 'ｽﾜｸﾞﾝ', full_lower: 'スワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '361',
    name: '諏訪郡　下諏訪町',
    kana_name: {
      half_upper: 'ｽﾜｸﾞﾝ ｼﾓｽﾜﾏﾁ',
      full_lower: 'スワグン　シモスワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '362',
    name: '諏訪郡　富士見町',
    kana_name: {
      half_upper: 'ｽﾜｸﾞﾝ ﾌｼﾞﾐﾏﾁ',
      full_lower: 'スワグン　フジミマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '363',
    name: '諏訪郡　原村',
    kana_name: { half_upper: 'ｽﾜｸﾞﾝ ﾊﾗﾑﾗ', full_lower: 'スワグン　ハラムラ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '380',
    name: '上伊那郡',
    kana_name: { half_upper: 'ｶﾐｲﾅｸﾞﾝ', full_lower: 'カミイナグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '381',
    name: '上伊那郡　高遠町',
    kana_name: {
      half_upper: 'ｶﾐｲﾅｸﾞﾝ ﾀｶﾄｵﾏﾁ',
      full_lower: 'カミイナグン　タカトオマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '20209伊那市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '382',
    name: '上伊那郡　辰野町',
    kana_name: {
      half_upper: 'ｶﾐｲﾅｸﾞﾝ ﾀﾂﾉﾏﾁ',
      full_lower: 'カミイナグン　タツノマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '383',
    name: '上伊那郡　箕輪町',
    kana_name: {
      half_upper: 'ｶﾐｲﾅｸﾞﾝ ﾐﾉﾜﾏﾁ',
      full_lower: 'カミイナグン　ミノワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '384',
    name: '上伊那郡　飯島町',
    kana_name: {
      half_upper: 'ｶﾐｲﾅｸﾞﾝ ｲｲｼﾞﾏﾏﾁ',
      full_lower: 'カミイナグン　イイジママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '385',
    name: '上伊那郡　南箕輪村',
    kana_name: {
      half_upper: 'ｶﾐｲﾅｸﾞﾝ ﾐﾅﾐﾐﾉﾜﾑﾗ',
      full_lower: 'カミイナグン　ミナミミノワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '386',
    name: '上伊那郡　中川村',
    kana_name: {
      half_upper: 'ｶﾐｲﾅｸﾞﾝ ﾅｶｶﾞﾜﾑﾗ',
      full_lower: 'カミイナグン　ナカガワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '387',
    name: '上伊那郡　長谷村',
    kana_name: {
      half_upper: 'ｶﾐｲﾅｸﾞﾝ ﾊｾﾑﾗ',
      full_lower: 'カミイナグン　ハセムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '20209伊那市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '388',
    name: '上伊那郡　宮田村',
    kana_name: {
      half_upper: 'ｶﾐｲﾅｸﾞﾝ ﾐﾔﾀﾞﾑﾗ',
      full_lower: 'カミイナグン　ミヤダムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '400',
    name: '下伊那郡',
    kana_name: { half_upper: 'ｼﾓｲﾅｸﾞﾝ', full_lower: 'シモイナグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '401',
    name: '下伊那郡　鼎町',
    kana_name: {
      half_upper: 'ｼﾓｲﾅｸﾞﾝ ｶﾅｴﾏﾁ',
      full_lower: 'シモイナグン　カナエマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1984-12-01',
    remark: { start: null, end: '20205飯田市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '402',
    name: '下伊那郡　松川町',
    kana_name: {
      half_upper: 'ｼﾓｲﾅｸﾞﾝ ﾏﾂｶﾜﾏﾁ',
      full_lower: 'シモイナグン　マツカワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '403',
    name: '下伊那郡　高森町',
    kana_name: {
      half_upper: 'ｼﾓｲﾅｸﾞﾝ ﾀｶﾓﾘﾏﾁ',
      full_lower: 'シモイナグン　タカモリマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '404',
    name: '下伊那郡　阿南町',
    kana_name: {
      half_upper: 'ｼﾓｲﾅｸﾞﾝ ｱﾅﾝﾁﾖｳ',
      full_lower: 'シモイナグン　アナンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '405',
    name: '下伊那郡　上郷町',
    kana_name: {
      half_upper: 'ｼﾓｲﾅｸﾞﾝ ｶﾐｻﾄﾏﾁ',
      full_lower: 'シモイナグン　カミサトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1993-07-01',
    remark: { start: null, end: '20205飯田市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '406',
    name: '下伊那郡　清内路村',
    kana_name: {
      half_upper: 'ｼﾓｲﾅｸﾞﾝ ｾｲﾅｲｼﾞﾑﾗ',
      full_lower: 'シモイナグン　セイナイジムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2009-03-31',
    remark: { start: null, end: '20407下伊那郡　阿智村', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '407',
    name: '下伊那郡　阿智村',
    kana_name: {
      half_upper: 'ｼﾓｲﾅｸﾞﾝ ｱﾁﾑﾗ',
      full_lower: 'シモイナグン　アチムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '408',
    name: '下伊那郡　浪合村',
    kana_name: {
      half_upper: 'ｼﾓｲﾅｸﾞﾝ ﾅﾐｱｲﾑﾗ',
      full_lower: 'シモイナグン　ナミアイムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '20407下伊那郡　阿智村', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '409',
    name: '下伊那郡　平谷村',
    kana_name: {
      half_upper: 'ｼﾓｲﾅｸﾞﾝ ﾋﾗﾔﾑﾗ',
      full_lower: 'シモイナグン　ヒラヤムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '410',
    name: '下伊那郡　根羽村',
    kana_name: {
      half_upper: 'ｼﾓｲﾅｸﾞﾝ ﾈﾊﾞﾑﾗ',
      full_lower: 'シモイナグン　ネバムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '411',
    name: '下伊那郡　下條村',
    kana_name: {
      half_upper: 'ｼﾓｲﾅｸﾞﾝ ｼﾓｼﾞﾖｳﾑﾗ',
      full_lower: 'シモイナグン　シモジョウムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '412',
    name: '下伊那郡　売木村',
    kana_name: {
      half_upper: 'ｼﾓｲﾅｸﾞﾝ ｳﾙｷﾞﾑﾗ',
      full_lower: 'シモイナグン　ウルギムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '413',
    name: '下伊那郡　天龍村',
    kana_name: {
      half_upper: 'ｼﾓｲﾅｸﾞﾝ ﾃﾝﾘﾕｳﾑﾗ',
      full_lower: 'シモイナグン　テンリュウムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '414',
    name: '下伊那郡　泰阜村',
    kana_name: {
      half_upper: 'ｼﾓｲﾅｸﾞﾝ ﾔｽｵｶﾑﾗ',
      full_lower: 'シモイナグン　ヤスオカムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '415',
    name: '下伊那郡　喬木村',
    kana_name: {
      half_upper: 'ｼﾓｲﾅｸﾞﾝ ﾀｶｷﾞﾑﾗ',
      full_lower: 'シモイナグン　タカギムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '416',
    name: '下伊那郡　豊丘村',
    kana_name: {
      half_upper: 'ｼﾓｲﾅｸﾞﾝ ﾄﾖｵｶﾑﾗ',
      full_lower: 'シモイナグン　トヨオカムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '417',
    name: '下伊那郡　大鹿村',
    kana_name: {
      half_upper: 'ｼﾓｲﾅｸﾞﾝ ｵｵｼｶﾑﾗ',
      full_lower: 'シモイナグン　オオシカムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '418',
    name: '下伊那郡　上村',
    kana_name: {
      half_upper: 'ｼﾓｲﾅｸﾞﾝ ｶﾐﾑﾗ',
      full_lower: 'シモイナグン　カミムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '20205飯田市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '419',
    name: '下伊那郡　南信濃村',
    kana_name: {
      half_upper: 'ｼﾓｲﾅｸﾞﾝ ﾐﾅﾐｼﾅﾉﾑﾗ',
      full_lower: 'シモイナグン　ミナミシナノムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '20205飯田市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '420',
    name: '木曽郡',
    kana_name: { half_upper: 'ｷｿｸﾞﾝ', full_lower: 'キソグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '421',
    name: '木曽郡　木曽福島町',
    kana_name: {
      half_upper: 'ｷｿｸﾞﾝ ｷｿﾌｸｼﾏﾏﾁ',
      full_lower: 'キソグン　キソフクシママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-01',
    remark: { start: null, end: '20432木曽郡　木曽町', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '422',
    name: '木曽郡　上松町',
    kana_name: {
      half_upper: 'ｷｿｸﾞﾝ ｱｹﾞﾏﾂﾏﾁ',
      full_lower: 'キソグン　アゲマツマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '423',
    name: '木曽郡　南木曽町',
    kana_name: {
      half_upper: 'ｷｿｸﾞﾝ ﾅｷﾞｿﾏﾁ',
      full_lower: 'キソグン　ナギソマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '424',
    name: '木曽郡　楢川村',
    kana_name: {
      half_upper: 'ｷｿｸﾞﾝ ﾅﾗｶﾜﾑﾗ',
      full_lower: 'キソグン　ナラカワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '20215塩尻市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '425',
    name: '木曽郡　木祖村',
    kana_name: { half_upper: 'ｷｿｸﾞﾝ ｷｿﾑﾗ', full_lower: 'キソグン　キソムラ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '426',
    name: '木曽郡　日義村',
    kana_name: {
      half_upper: 'ｷｿｸﾞﾝ ﾋﾖｼﾑﾗ',
      full_lower: 'キソグン　ヒヨシムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-01',
    remark: { start: null, end: '20432木曽郡　木曽町', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '427',
    name: '木曽郡　開田村',
    kana_name: {
      half_upper: 'ｷｿｸﾞﾝ ｶｲﾀﾞﾑﾗ',
      full_lower: 'キソグン　カイダムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-01',
    remark: { start: null, end: '20432木曽郡　木曽町', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '428',
    name: '木曽郡　三岳村',
    kana_name: {
      half_upper: 'ｷｿｸﾞﾝ ﾐﾀｹﾑﾗ',
      full_lower: 'キソグン　ミタケムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-01',
    remark: { start: null, end: '20432木曽郡　木曽町', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '429',
    name: '木曽郡　王滝村',
    kana_name: {
      half_upper: 'ｷｿｸﾞﾝ ｵｳﾀｷﾑﾗ',
      full_lower: 'キソグン　オウタキムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '430',
    name: '木曽郡　大桑村',
    kana_name: {
      half_upper: 'ｷｿｸﾞﾝ ｵｵｸﾜﾑﾗ',
      full_lower: 'キソグン　オオクワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '431',
    name: '木曽郡　山口村',
    kana_name: {
      half_upper: 'ｷｿｸﾞﾝ ﾔﾏｸﾞﾁﾑﾗ',
      full_lower: 'キソグン　ヤマグチムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-13',
    remark: { start: null, end: '21206中津川市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '432',
    name: '木曽郡　木曽町',
    kana_name: { half_upper: 'ｷｿｸﾞﾝ ｷｿﾏﾁ', full_lower: 'キソグン　キソマチ' },
    en: null,
    start_date: '2005-11-01',
    end_date: null,
    remark: { start: 'H17/11/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '440',
    name: '東筑摩郡',
    kana_name: { half_upper: 'ﾋｶﾞｼﾁｸﾏｸﾞﾝ', full_lower: 'ヒガシチクマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '441',
    name: '東筑摩郡　明科町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾁｸﾏｸﾞﾝ ｱｶｼﾅﾏﾁ',
      full_lower: 'ヒガシチクマグン　アカシナマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '20220安曇野市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '442',
    name: '東筑摩郡　本郷村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾁｸﾏｸﾞﾝ ﾎﾝｺﾞｳﾑﾗ',
      full_lower: 'ヒガシチクマグン　ホンゴウムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1974-05-01',
    remark: { start: null, end: '20202松本市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '443',
    name: '東筑摩郡　四賀村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾁｸﾏｸﾞﾝ ｼｶﾞﾑﾗ',
      full_lower: 'ヒガシチクマグン　シガムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '20202松本市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '444',
    name: '東筑摩郡　本城村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾁｸﾏｸﾞﾝ ﾎﾝｼﾞﾖｳﾑﾗ',
      full_lower: 'ヒガシチクマグン　ホンジョウムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-11',
    remark: { start: null, end: '20452東筑摩郡　筑北村', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '445',
    name: '東筑摩郡　坂北村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾁｸﾏｸﾞﾝ ｻｶｷﾀﾑﾗ',
      full_lower: 'ヒガシチクマグン　サカキタムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-11',
    remark: { start: null, end: '20452東筑摩郡　筑北村', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '446',
    name: '東筑摩郡　麻績村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾁｸﾏｸﾞﾝ ｵﾐﾑﾗ',
      full_lower: 'ヒガシチクマグン　オミムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '447',
    name: '東筑摩郡　坂井村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾁｸﾏｸﾞﾝ ｻｶｲﾑﾗ',
      full_lower: 'ヒガシチクマグン　サカイムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-11',
    remark: { start: null, end: '20452東筑摩郡　筑北村', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '448',
    name: '東筑摩郡　生坂村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾁｸﾏｸﾞﾝ ｲｸｻｶﾑﾗ',
      full_lower: 'ヒガシチクマグン　イクサカムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '449',
    name: '東筑摩郡　波田町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾁｸﾏｸﾞﾝ ﾊﾀﾏﾁ',
      full_lower: 'ヒガシチクマグン　ハタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-31',
    remark: { start: null, end: '20202松本市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '450',
    name: '東筑摩郡　山形村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾁｸﾏｸﾞﾝ ﾔﾏｶﾞﾀﾑﾗ',
      full_lower: 'ヒガシチクマグン　ヤマガタムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '451',
    name: '東筑摩郡　朝日村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾁｸﾏｸﾞﾝ ｱｻﾋﾑﾗ',
      full_lower: 'ヒガシチクマグン　アサヒムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '452',
    name: '東筑摩郡　筑北村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾁｸﾏｸﾞﾝ ﾁｸﾎｸﾑﾗ',
      full_lower: 'ヒガシチクマグン　チクホクムラ',
    },
    en: null,
    start_date: '2005-10-11',
    end_date: null,
    remark: { start: 'H17/10/11新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '460',
    name: '南安曇郡',
    kana_name: { half_upper: 'ﾐﾅﾐｱﾂﾞﾐｸﾞﾝ', full_lower: 'ミナミアヅミグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '461',
    name: '南安曇郡　豊科町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱﾂﾞﾐｸﾞﾝ ﾄﾖｼﾅﾏﾁ',
      full_lower: 'ミナミアヅミグン　トヨシナマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '20220安曇野市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '462',
    name: '南安曇郡　穂高町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱﾂﾞﾐｸﾞﾝ ﾎﾀｶﾏﾁ',
      full_lower: 'ミナミアヅミグン　ホタカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '20220安曇野市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '463',
    name: '南安曇郡　奈川村',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱﾂﾞﾐｸﾞﾝ ﾅｶﾞﾜﾑﾗ',
      full_lower: 'ミナミアヅミグン　ナガワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '20202松本市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '464',
    name: '南安曇郡　安曇村',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱﾂﾞﾐｸﾞﾝ ｱﾂﾞﾐﾑﾗ',
      full_lower: 'ミナミアヅミグン　アヅミムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '20202松本市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '465',
    name: '南安曇郡　梓川村',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱﾂﾞﾐｸﾞﾝ ｱｽﾞｻｶﾞﾜﾑﾗ',
      full_lower: 'ミナミアヅミグン　アズサガワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '20202松本市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '466',
    name: '南安曇郡　三郷村',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱﾂﾞﾐｸﾞﾝ ﾐｻﾄﾑﾗ',
      full_lower: 'ミナミアヅミグン　ミサトムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '20220安曇野市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '467',
    name: '南安曇郡　堀金村',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱﾂﾞﾐｸﾞﾝ ﾎﾘｶﾞﾈﾑﾗ',
      full_lower: 'ミナミアヅミグン　ホリガネムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '20220安曇野市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '480',
    name: '北安曇郡',
    kana_name: { half_upper: 'ｷﾀｱﾂﾞﾐｸﾞﾝ', full_lower: 'キタアヅミグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '481',
    name: '北安曇郡　池田町',
    kana_name: {
      half_upper: 'ｷﾀｱﾂﾞﾐｸﾞﾝ ｲｹﾀﾞﾏﾁ',
      full_lower: 'キタアヅミグン　イケダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '482',
    name: '北安曇郡　松川村',
    kana_name: {
      half_upper: 'ｷﾀｱﾂﾞﾐｸﾞﾝ ﾏﾂｶﾜﾑﾗ',
      full_lower: 'キタアヅミグン　マツカワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '483',
    name: '北安曇郡　八坂村',
    kana_name: {
      half_upper: 'ｷﾀｱﾂﾞﾐｸﾞﾝ ﾔｻｶﾑﾗ',
      full_lower: 'キタアヅミグン　ヤサカムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '20212大町市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '484',
    name: '北安曇郡　美麻村',
    kana_name: {
      half_upper: 'ｷﾀｱﾂﾞﾐｸﾞﾝ ﾐｱｻﾑﾗ',
      full_lower: 'キタアヅミグン　ミアサムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '20212大町市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '485',
    name: '北安曇郡　白馬村',
    kana_name: {
      half_upper: 'ｷﾀｱﾂﾞﾐｸﾞﾝ ﾊｸﾊﾞﾑﾗ',
      full_lower: 'キタアヅミグン　ハクバムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '486',
    name: '北安曇郡　小谷村',
    kana_name: {
      half_upper: 'ｷﾀｱﾂﾞﾐｸﾞﾝ ｵﾀﾘﾑﾗ',
      full_lower: 'キタアヅミグン　オタリムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '500',
    name: '更級郡',
    kana_name: { half_upper: 'ｻﾗｼﾅｸﾞﾝ', full_lower: 'サラシナグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '501',
    name: '更級郡　上山田町',
    kana_name: {
      half_upper: 'ｻﾗｼﾅｸﾞﾝ ｶﾐﾔﾏﾀﾞﾏﾁ',
      full_lower: 'サラシナグン　カミヤマダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-09-01',
    remark: { start: null, end: '20218千曲市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '502',
    name: '更級郡　大岡村',
    kana_name: {
      half_upper: 'ｻﾗｼﾅｸﾞﾝ ｵｵｵｶﾑﾗ',
      full_lower: 'サラシナグン　オオオカムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '20201長野市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '520',
    name: '埴科郡',
    kana_name: { half_upper: 'ﾊﾆｼﾅｸﾞﾝ', full_lower: 'ハニシナグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '521',
    name: '埴科郡　坂城町',
    kana_name: {
      half_upper: 'ﾊﾆｼﾅｸﾞﾝ ｻｶｷﾏﾁ',
      full_lower: 'ハニシナグン　サカキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '522',
    name: '埴科郡　戸倉町',
    kana_name: {
      half_upper: 'ﾊﾆｼﾅｸﾞﾝ ﾄｸﾞﾗﾏﾁ',
      full_lower: 'ハニシナグン　トグラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-09-01',
    remark: { start: null, end: '20218千曲市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '540',
    name: '上高井郡',
    kana_name: { half_upper: 'ｶﾐﾀｶｲｸﾞﾝ', full_lower: 'カミタカイグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '541',
    name: '上高井郡　小布施町',
    kana_name: {
      half_upper: 'ｶﾐﾀｶｲｸﾞﾝ ｵﾌﾞｾﾏﾁ',
      full_lower: 'カミタカイグン　オブセマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '542',
    name: '上高井郡　東村',
    kana_name: {
      half_upper: 'ｶﾐﾀｶｲｸﾞﾝ ｱｽﾞﾏﾑﾗ',
      full_lower: 'カミタカイグン　アズマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-04-30',
    remark: { start: null, end: '20207須坂市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '543',
    name: '上高井郡　高山村',
    kana_name: {
      half_upper: 'ｶﾐﾀｶｲｸﾞﾝ ﾀｶﾔﾏﾑﾗ',
      full_lower: 'カミタカイグン　タカヤマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '560',
    name: '下高井郡',
    kana_name: { half_upper: 'ｼﾓﾀｶｲｸﾞﾝ', full_lower: 'シモタカイグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '561',
    name: '下高井郡　山ノ内町',
    kana_name: {
      half_upper: 'ｼﾓﾀｶｲｸﾞﾝ ﾔﾏﾉｳﾁﾏﾁ',
      full_lower: 'シモタカイグン　ヤマノウチマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '562',
    name: '下高井郡　木島平村',
    kana_name: {
      half_upper: 'ｼﾓﾀｶｲｸﾞﾝ ｷｼﾞﾏﾀﾞｲﾗﾑﾗ',
      full_lower: 'シモタカイグン　キジマダイラムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '563',
    name: '下高井郡　野沢温泉村',
    kana_name: {
      half_upper: 'ｼﾓﾀｶｲｸﾞﾝ ﾉｻﾞﾜｵﾝｾﾝﾑﾗ',
      full_lower: 'シモタカイグン　ノザワオンセンムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '580',
    name: '上水内郡',
    kana_name: { half_upper: 'ｶﾐﾐﾉﾁｸﾞﾝ', full_lower: 'カミミノチグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '581',
    name: '上水内郡　信州新町',
    kana_name: {
      half_upper: 'ｶﾐﾐﾉﾁｸﾞﾝ ｼﾝｼﾕｳｼﾝﾏﾁ',
      full_lower: 'カミミノチグン　シンシュウシンマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-01-01',
    remark: { start: null, end: '20201長野市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '582',
    name: '上水内郡　豊野町',
    kana_name: {
      half_upper: 'ｶﾐﾐﾉﾁｸﾞﾝ ﾄﾖﾉﾏﾁ',
      full_lower: 'カミミノチグン　トヨノマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '20201長野市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '583',
    name: '上水内郡　信濃町',
    kana_name: {
      half_upper: 'ｶﾐﾐﾉﾁｸﾞﾝ ｼﾅﾉﾏﾁ',
      full_lower: 'カミミノチグン　シナノマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '584',
    name: '上水内郡　牟礼村',
    kana_name: {
      half_upper: 'ｶﾐﾐﾉﾁｸﾞﾝ ﾑﾚﾑﾗ',
      full_lower: 'カミミノチグン　ムレムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '20590上水内郡　飯綱町', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '585',
    name: '上水内郡　三水村',
    kana_name: {
      half_upper: 'ｶﾐﾐﾉﾁｸﾞﾝ ｻﾐｽﾞﾑﾗ',
      full_lower: 'カミミノチグン　サミズムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '20590上水内郡　飯綱町', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '586',
    name: '上水内郡　戸隠村',
    kana_name: {
      half_upper: 'ｶﾐﾐﾉﾁｸﾞﾝ ﾄｶﾞｸｼﾑﾗ',
      full_lower: 'カミミノチグン　トガクシムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '20201長野市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '587',
    name: '上水内郡　鬼無里村',
    kana_name: {
      half_upper: 'ｶﾐﾐﾉﾁｸﾞﾝ ｷﾅｻﾑﾗ',
      full_lower: 'カミミノチグン　キナサムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '20201長野市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '588',
    name: '上水内郡　小川村',
    kana_name: {
      half_upper: 'ｶﾐﾐﾉﾁｸﾞﾝ ｵｶﾞﾜﾑﾗ',
      full_lower: 'カミミノチグン　オガワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '589',
    name: '上水内郡　中条村',
    kana_name: {
      half_upper: 'ｶﾐﾐﾉﾁｸﾞﾝ ﾅｶｼﾞﾖｳﾑﾗ',
      full_lower: 'カミミノチグン　ナカジョウムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-01-01',
    remark: { start: null, end: '20201長野市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '590',
    name: '上水内郡　飯綱町',
    kana_name: {
      half_upper: 'ｶﾐﾐﾉﾁｸﾞﾝ ｲｲﾂﾞﾅﾏﾁ',
      full_lower: 'カミミノチグン　イイヅナマチ',
    },
    en: null,
    start_date: '2005-10-01',
    end_date: null,
    remark: { start: 'H17/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '600',
    name: '下水内郡',
    kana_name: { half_upper: 'ｼﾓﾐﾉﾁｸﾞﾝ', full_lower: 'シモミノチグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '601',
    name: '下水内郡　豊田村',
    kana_name: {
      half_upper: 'ｼﾓﾐﾉﾁｸﾞﾝ ﾄﾖﾀﾑﾗ',
      full_lower: 'シモミノチグン　トヨタムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '20211中野市', other: null },
    note: null,
  },
  {
    pref_code: '20',
    city_code: '602',
    name: '下水内郡　栄村',
    kana_name: {
      half_upper: 'ｼﾓﾐﾉﾁｸﾞﾝ ｻｶｴﾑﾗ',
      full_lower: 'シモミノチグン　サカエムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '201',
    name: '岐阜市',
    kana_name: { half_upper: 'ｷﾞﾌｼ', full_lower: 'ギフシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '202',
    name: '大垣市',
    kana_name: { half_upper: 'ｵｵｶﾞｷｼ', full_lower: 'オオガキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '203',
    name: '高山市',
    kana_name: { half_upper: 'ﾀｶﾔﾏｼ', full_lower: 'タカヤマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '204',
    name: '多治見市',
    kana_name: { half_upper: 'ﾀｼﾞﾐｼ', full_lower: 'タジミシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '205',
    name: '関市',
    kana_name: { half_upper: 'ｾｷｼ', full_lower: 'セキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '206',
    name: '中津川市',
    kana_name: { half_upper: 'ﾅｶﾂｶﾞﾜｼ', full_lower: 'ナカツガワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '207',
    name: '美濃市',
    kana_name: { half_upper: 'ﾐﾉｼ', full_lower: 'ミノシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '208',
    name: '瑞浪市',
    kana_name: { half_upper: 'ﾐｽﾞﾅﾐｼ', full_lower: 'ミズナミシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '209',
    name: '羽島市',
    kana_name: { half_upper: 'ﾊｼﾏｼ', full_lower: 'ハシマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '210',
    name: '恵那市',
    kana_name: { half_upper: 'ｴﾅｼ', full_lower: 'エナシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '211',
    name: '美濃加茂市',
    kana_name: { half_upper: 'ﾐﾉｶﾓｼ', full_lower: 'ミノカモシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '212',
    name: '土岐市',
    kana_name: { half_upper: 'ﾄｷｼ', full_lower: 'トキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '213',
    name: '各務原市',
    kana_name: { half_upper: 'ｶｶﾐｶﾞﾊﾗｼ', full_lower: 'カカミガハラシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '214',
    name: '可児市',
    kana_name: { half_upper: 'ｶﾆｼ', full_lower: 'カニシ' },
    en: null,
    start_date: '1982-04-01',
    end_date: null,
    remark: { start: 'S57/04/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '215',
    name: '山県市',
    kana_name: { half_upper: 'ﾔﾏｶﾞﾀｼ', full_lower: 'ヤマガタシ' },
    en: null,
    start_date: '2003-04-01',
    end_date: null,
    remark: { start: 'H15/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '216',
    name: '瑞穂市',
    kana_name: { half_upper: 'ﾐｽﾞﾎｼ', full_lower: 'ミズホシ' },
    en: null,
    start_date: '2003-05-01',
    end_date: null,
    remark: { start: 'H15/05/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '217',
    name: '飛騨市',
    kana_name: { half_upper: 'ﾋﾀﾞｼ', full_lower: 'ヒダシ' },
    en: null,
    start_date: '2004-02-01',
    end_date: null,
    remark: { start: 'H16/02/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '218',
    name: '本巣市',
    kana_name: { half_upper: 'ﾓﾄｽｼ', full_lower: 'モトスシ' },
    en: null,
    start_date: '2004-02-01',
    end_date: null,
    remark: { start: 'H16/02/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '219',
    name: '郡上市',
    kana_name: { half_upper: 'ｸﾞｼﾞﾖｳｼ', full_lower: 'グジョウシ' },
    en: null,
    start_date: '2004-03-01',
    end_date: null,
    remark: { start: 'H16/03/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '220',
    name: '下呂市',
    kana_name: { half_upper: 'ｹﾞﾛｼ', full_lower: 'ゲロシ' },
    en: null,
    start_date: '2004-03-01',
    end_date: null,
    remark: { start: 'H16/03/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '221',
    name: '海津市',
    kana_name: { half_upper: 'ｶｲﾂﾞｼ', full_lower: 'カイヅシ' },
    en: null,
    start_date: '2005-03-28',
    end_date: null,
    remark: { start: 'H17/03/28新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '300',
    name: '羽島郡',
    kana_name: { half_upper: 'ﾊｼﾏｸﾞﾝ', full_lower: 'ハシマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '301',
    name: '羽島郡　川島町',
    kana_name: {
      half_upper: 'ﾊｼﾏｸﾞﾝ ｶﾜｼﾏﾁﾖｳ',
      full_lower: 'ハシマグン　カワシマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '21213各務原市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '302',
    name: '羽島郡　岐南町',
    kana_name: {
      half_upper: 'ﾊｼﾏｸﾞﾝ ｷﾞﾅﾝﾁﾖｳ',
      full_lower: 'ハシマグン　ギナンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '303',
    name: '羽島郡　笠松町',
    kana_name: {
      half_upper: 'ﾊｼﾏｸﾞﾝ ｶｻﾏﾂﾁﾖｳ',
      full_lower: 'ハシマグン　カサマツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '304',
    name: '羽島郡　柳津町',
    kana_name: {
      half_upper: 'ﾊｼﾏｸﾞﾝ ﾔﾅｲﾂﾞﾁﾖｳ',
      full_lower: 'ハシマグン　ヤナイヅチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '21201岐阜市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '320',
    name: '海津郡',
    kana_name: { half_upper: 'ｶｲﾂﾞｸﾞﾝ', full_lower: 'カイヅグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '321',
    name: '海津郡　海津町',
    kana_name: {
      half_upper: 'ｶｲﾂﾞｸﾞﾝ ｶｲﾂﾞﾁﾖｳ',
      full_lower: 'カイヅグン　カイヅチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '21221海津市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '322',
    name: '海津郡　平田町',
    kana_name: {
      half_upper: 'ｶｲﾂﾞｸﾞﾝ ﾋﾗﾀﾁﾖｳ',
      full_lower: 'カイヅグン　ヒラタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '21221海津市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '323',
    name: '海津郡　南濃町',
    kana_name: {
      half_upper: 'ｶｲﾂﾞｸﾞﾝ ﾅﾝﾉｳﾁﾖｳ',
      full_lower: 'カイヅグン　ナンノウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '21221海津市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '340',
    name: '養老郡',
    kana_name: { half_upper: 'ﾖｳﾛｳｸﾞﾝ', full_lower: 'ヨウロウグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '341',
    name: '養老郡　養老町',
    kana_name: {
      half_upper: 'ﾖｳﾛｳｸﾞﾝ ﾖｳﾛｳﾁﾖｳ',
      full_lower: 'ヨウロウグン　ヨウロウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '342',
    name: '養老郡　上石津町',
    kana_name: {
      half_upper: 'ﾖｳﾛｳｸﾞﾝ ｶﾐｲｼﾂﾞﾁﾖｳ',
      full_lower: 'ヨウロウグン　カミイシヅチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-26',
    remark: { start: null, end: '21202大垣市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '360',
    name: '不破郡',
    kana_name: { half_upper: 'ﾌﾜｸﾞﾝ', full_lower: 'フワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '361',
    name: '不破郡　垂井町',
    kana_name: {
      half_upper: 'ﾌﾜｸﾞﾝ ﾀﾙｲﾁﾖｳ',
      full_lower: 'フワグン　タルイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '362',
    name: '不破郡　関ケ原町',
    kana_name: {
      half_upper: 'ﾌﾜｸﾞﾝ ｾｷｶﾞﾊﾗﾁﾖｳ',
      full_lower: 'フワグン　セキガハラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '380',
    name: '安八郡',
    kana_name: { half_upper: 'ｱﾝﾊﾟﾁｸﾞﾝ', full_lower: 'アンパチグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '381',
    name: '安八郡　神戸町',
    kana_name: {
      half_upper: 'ｱﾝﾊﾟﾁｸﾞﾝ ｺﾞｳﾄﾞﾁﾖｳ',
      full_lower: 'アンパチグン　ゴウドチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '382',
    name: '安八郡　輪之内町',
    kana_name: {
      half_upper: 'ｱﾝﾊﾟﾁｸﾞﾝ ﾜﾉｳﾁﾁﾖｳ',
      full_lower: 'アンパチグン　ワノウチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '383',
    name: '安八郡　安八町',
    kana_name: {
      half_upper: 'ｱﾝﾊﾟﾁｸﾞﾝ ｱﾝﾊﾟﾁﾁﾖｳ',
      full_lower: 'アンパチグン　アンパチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '384',
    name: '安八郡　墨俣町',
    kana_name: {
      half_upper: 'ｱﾝﾊﾟﾁｸﾞﾝ ｽﾉﾏﾀﾁﾖｳ',
      full_lower: 'アンパチグン　スノマタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-26',
    remark: { start: null, end: '21202大垣市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '400',
    name: '揖斐郡',
    kana_name: { half_upper: 'ｲﾋﾞｸﾞﾝ', full_lower: 'イビグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '401',
    name: '揖斐郡　揖斐川町',
    kana_name: {
      half_upper: 'ｲﾋﾞｸﾞﾝ ｲﾋﾞｶﾞﾜﾁﾖｳ',
      full_lower: 'イビグン　イビガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '402',
    name: '揖斐郡　谷汲村',
    kana_name: {
      half_upper: 'ｲﾋﾞｸﾞﾝ ﾀﾆｸﾞﾐﾑﾗ',
      full_lower: 'イビグン　タニグミムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-31',
    remark: { start: null, end: '21401揖斐郡　揖斐川町', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '403',
    name: '揖斐郡　大野町',
    kana_name: {
      half_upper: 'ｲﾋﾞｸﾞﾝ ｵｵﾉﾁﾖｳ',
      full_lower: 'イビグン　オオノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '404',
    name: '揖斐郡　池田町',
    kana_name: {
      half_upper: 'ｲﾋﾞｸﾞﾝ ｲｹﾀﾞﾁﾖｳ',
      full_lower: 'イビグン　イケダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '405',
    name: '揖斐郡　春日村',
    kana_name: {
      half_upper: 'ｲﾋﾞｸﾞﾝ ｶｽｶﾞﾑﾗ',
      full_lower: 'イビグン　カスガムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-31',
    remark: { start: null, end: '21401揖斐郡　揖斐川町', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '406',
    name: '揖斐郡　久瀬村',
    kana_name: {
      half_upper: 'ｲﾋﾞｸﾞﾝ ｸｾﾞﾑﾗ',
      full_lower: 'イビグン　クゼムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-31',
    remark: { start: null, end: '21401揖斐郡　揖斐川町', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '407',
    name: '揖斐郡　藤橋村',
    kana_name: {
      half_upper: 'ｲﾋﾞｸﾞﾝ ﾌｼﾞﾊｼﾑﾗ',
      full_lower: 'イビグン　フジハシムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-31',
    remark: { start: null, end: '21401揖斐郡　揖斐川町', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '408',
    name: '揖斐郡　坂内村',
    kana_name: {
      half_upper: 'ｲﾋﾞｸﾞﾝ ｻｶｳﾁﾑﾗ',
      full_lower: 'イビグン　サカウチムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-31',
    remark: { start: null, end: '21401揖斐郡　揖斐川町', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '409',
    name: '揖斐郡　徳山村',
    kana_name: {
      half_upper: 'ｲﾋﾞｸﾞﾝ ﾄｸﾔﾏﾑﾗ',
      full_lower: 'イビグン　トクヤマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1987-03-31',
    remark: { start: null, end: '21407揖斐郡　藤橋村', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '420',
    name: '本巣郡',
    kana_name: { half_upper: 'ﾓﾄｽｸﾞﾝ', full_lower: 'モトスグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '421',
    name: '本巣郡　北方町',
    kana_name: {
      half_upper: 'ﾓﾄｽｸﾞﾝ ｷﾀｶﾞﾀﾁﾖｳ',
      full_lower: 'モトスグン　キタガタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '422',
    name: '本巣郡　本巣町',
    kana_name: {
      half_upper: 'ﾓﾄｽｸﾞﾝ ﾓﾄｽﾁﾖｳ',
      full_lower: 'モトスグン　モトスチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-02-01',
    remark: { start: null, end: '21218本巣市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '423',
    name: '本巣郡　穂積町',
    kana_name: {
      half_upper: 'ﾓﾄｽｸﾞﾝ ﾎﾂﾞﾐﾁﾖｳ',
      full_lower: 'モトスグン　ホヅミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-05-01',
    remark: { start: null, end: '21216瑞穂市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '424',
    name: '本巣郡　巣南町',
    kana_name: {
      half_upper: 'ﾓﾄｽｸﾞﾝ ｽﾅﾐﾁﾖｳ',
      full_lower: 'モトスグン　スナミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-05-01',
    remark: { start: null, end: '21216瑞穂市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '425',
    name: '本巣郡　真正町',
    kana_name: {
      half_upper: 'ﾓﾄｽｸﾞﾝ ｼﾝｾｲﾁﾖｳ',
      full_lower: 'モトスグン　シンセイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-02-01',
    remark: { start: null, end: '21218本巣市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '426',
    name: '本巣郡　糸貫町',
    kana_name: {
      half_upper: 'ﾓﾄｽｸﾞﾝ ｲﾄﾇｷﾁﾖｳ',
      full_lower: 'モトスグン　イトヌキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-02-01',
    remark: { start: null, end: '21218本巣市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '427',
    name: '本巣郡　根尾村',
    kana_name: {
      half_upper: 'ﾓﾄｽｸﾞﾝ ﾈｵﾑﾗ',
      full_lower: 'モトスグン　ネオムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-02-01',
    remark: { start: null, end: '21218本巣市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '440',
    name: '山県郡',
    kana_name: { half_upper: 'ﾔﾏｶﾞﾀｸﾞﾝ', full_lower: 'ヤマガタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '441',
    name: '山県郡　高富町',
    kana_name: {
      half_upper: 'ﾔﾏｶﾞﾀｸﾞﾝ ﾀｶﾄﾐﾁﾖｳ',
      full_lower: 'ヤマガタグン　タカトミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: '21215山県市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '442',
    name: '山県郡　伊自良村',
    kana_name: {
      half_upper: 'ﾔﾏｶﾞﾀｸﾞﾝ ｲｼﾞﾗﾑﾗ',
      full_lower: 'ヤマガタグン　イジラムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: '21215山県市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '443',
    name: '山県郡　美山町',
    kana_name: {
      half_upper: 'ﾔﾏｶﾞﾀｸﾞﾝ ﾐﾔﾏﾁﾖｳ',
      full_lower: 'ヤマガタグン　ミヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: '21215山県市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '460',
    name: '武儀郡',
    kana_name: { half_upper: 'ﾑｷﾞｸﾞﾝ', full_lower: 'ムギグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-07',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '461',
    name: '武儀郡　洞戸村',
    kana_name: {
      half_upper: 'ﾑｷﾞｸﾞﾝ ﾎﾗﾄﾞﾑﾗ',
      full_lower: 'ムギグン　ホラドムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-07',
    remark: { start: null, end: '21205関市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '462',
    name: '武儀郡　板取村',
    kana_name: {
      half_upper: 'ﾑｷﾞｸﾞﾝ ｲﾀﾄﾞﾘﾑﾗ',
      full_lower: 'ムギグン　イタドリムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-07',
    remark: { start: null, end: '21205関市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '463',
    name: '武儀郡　武芸川町',
    kana_name: {
      half_upper: 'ﾑｷﾞｸﾞﾝ ﾑｹﾞｶﾞﾜﾁﾖｳ',
      full_lower: 'ムギグン　ムゲガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-07',
    remark: { start: null, end: '21205関市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '464',
    name: '武儀郡　武儀町',
    kana_name: {
      half_upper: 'ﾑｷﾞｸﾞﾝ ﾑｷﾞﾁﾖｳ',
      full_lower: 'ムギグン　ムギチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-07',
    remark: { start: null, end: '21205関市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '465',
    name: '武儀郡　上之保村',
    kana_name: {
      half_upper: 'ﾑｷﾞｸﾞﾝ ｶﾐﾉﾎﾑﾗ',
      full_lower: 'ムギグン　カミノホムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-07',
    remark: { start: null, end: '21205関市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '480',
    name: '郡上郡',
    kana_name: { half_upper: 'ｸﾞｼﾞﾖｳｸﾞﾝ', full_lower: 'グジョウグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '481',
    name: '郡上郡　八幡町',
    kana_name: {
      half_upper: 'ｸﾞｼﾞﾖｳｸﾞﾝ ﾊﾁﾏﾝﾁﾖｳ',
      full_lower: 'グジョウグン　ハチマンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '21219郡上市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '482',
    name: '郡上郡　大和町',
    kana_name: {
      half_upper: 'ｸﾞｼﾞﾖｳｸﾞﾝ ﾔﾏﾄﾁﾖｳ',
      full_lower: 'グジョウグン　ヤマトチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '21219郡上市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '483',
    name: '郡上郡　白鳥町',
    kana_name: {
      half_upper: 'ｸﾞｼﾞﾖｳｸﾞﾝ ｼﾛﾄﾘﾁﾖｳ',
      full_lower: 'グジョウグン　シロトリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '21219郡上市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '484',
    name: '郡上郡　高鷲村',
    kana_name: {
      half_upper: 'ｸﾞｼﾞﾖｳｸﾞﾝ ﾀｶｽﾑﾗ',
      full_lower: 'グジョウグン　タカスムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '21219郡上市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '485',
    name: '郡上郡　美並村',
    kana_name: {
      half_upper: 'ｸﾞｼﾞﾖｳｸﾞﾝ ﾐﾅﾐﾑﾗ',
      full_lower: 'グジョウグン　ミナミムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '21219郡上市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '486',
    name: '郡上郡　明宝村',
    kana_name: {
      half_upper: 'ｸﾞｼﾞﾖｳｸﾞﾝ ﾒｲﾎｳﾑﾗ',
      full_lower: 'グジョウグン　メイホウムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '21219郡上市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '487',
    name: '郡上郡　和良村',
    kana_name: {
      half_upper: 'ｸﾞｼﾞﾖｳｸﾞﾝ ﾜﾗﾑﾗ',
      full_lower: 'グジョウグン　ワラムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '21219郡上市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '500',
    name: '加茂郡',
    kana_name: { half_upper: 'ｶﾓｸﾞﾝ', full_lower: 'カモグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '501',
    name: '加茂郡　坂祝町',
    kana_name: {
      half_upper: 'ｶﾓｸﾞﾝ ｻｶﾎｷﾞﾁﾖｳ',
      full_lower: 'カモグン　サカホギチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '502',
    name: '加茂郡　富加町',
    kana_name: {
      half_upper: 'ｶﾓｸﾞﾝ ﾄﾐｶﾁﾖｳ',
      full_lower: 'カモグン　トミカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '503',
    name: '加茂郡　川辺町',
    kana_name: {
      half_upper: 'ｶﾓｸﾞﾝ ｶﾜﾍﾞﾁﾖｳ',
      full_lower: 'カモグン　カワベチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '504',
    name: '加茂郡　七宗町',
    kana_name: {
      half_upper: 'ｶﾓｸﾞﾝ ﾋﾁｿｳﾁﾖｳ',
      full_lower: 'カモグン　ヒチソウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '505',
    name: '加茂郡　八百津町',
    kana_name: {
      half_upper: 'ｶﾓｸﾞﾝ ﾔｵﾂﾁﾖｳ',
      full_lower: 'カモグン　ヤオツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '506',
    name: '加茂郡　白川町',
    kana_name: {
      half_upper: 'ｶﾓｸﾞﾝ ｼﾗｶﾜﾁﾖｳ',
      full_lower: 'カモグン　シラカワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '507',
    name: '加茂郡　東白川村',
    kana_name: {
      half_upper: 'ｶﾓｸﾞﾝ ﾋｶﾞｼｼﾗｶﾜﾑﾗ',
      full_lower: 'カモグン　ヒガシシラカワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '520',
    name: '可児郡',
    kana_name: { half_upper: 'ｶﾆｸﾞﾝ', full_lower: 'カニグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '521',
    name: '可児郡　御嵩町',
    kana_name: {
      half_upper: 'ｶﾆｸﾞﾝ ﾐﾀｹﾁﾖｳ',
      full_lower: 'カニグン　ミタケチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '522',
    name: '可児郡　可児町',
    kana_name: {
      half_upper: 'ｶﾆｸﾞﾝ ｶﾆﾁﾖｳ',
      full_lower: 'カニグン　カニチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1982-04-01',
    remark: { start: null, end: '21214可児市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '523',
    name: '可児郡　兼山町',
    kana_name: {
      half_upper: 'ｶﾆｸﾞﾝ ｶﾈﾔﾏﾁﾖｳ',
      full_lower: 'カニグン　カネヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-05-01',
    remark: { start: null, end: '21214可児市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '540',
    name: '土岐郡',
    kana_name: { half_upper: 'ﾄｷｸﾞﾝ', full_lower: 'トキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-23',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '541',
    name: '土岐郡　笠原町',
    kana_name: {
      half_upper: 'ﾄｷｸﾞﾝ ｶｻﾊﾗﾁﾖｳ',
      full_lower: 'トキグン　カサハラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-23',
    remark: { start: null, end: '21204多治見市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '560',
    name: '恵那郡',
    kana_name: { half_upper: 'ｴﾅｸﾞﾝ', full_lower: 'エナグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-13',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '561',
    name: '恵那郡　坂下町',
    kana_name: {
      half_upper: 'ｴﾅｸﾞﾝ ｻｶｼﾀﾁﾖｳ',
      full_lower: 'エナグン　サカシタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-13',
    remark: { start: null, end: '21206中津川市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '562',
    name: '恵那郡　川上村',
    kana_name: {
      half_upper: 'ｴﾅｸﾞﾝ ｶﾜｳｴﾑﾗ',
      full_lower: 'エナグン　カワウエムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-13',
    remark: { start: null, end: '21206中津川市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '563',
    name: '恵那郡　加子母村',
    kana_name: {
      half_upper: 'ｴﾅｸﾞﾝ ｶｼﾓﾑﾗ',
      full_lower: 'エナグン　カシモムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-13',
    remark: { start: null, end: '21206中津川市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '564',
    name: '恵那郡　付知町',
    kana_name: {
      half_upper: 'ｴﾅｸﾞﾝ ﾂｹﾁﾁﾖｳ',
      full_lower: 'エナグン　ツケチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-13',
    remark: { start: null, end: '21206中津川市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '565',
    name: '恵那郡　福岡町',
    kana_name: {
      half_upper: 'ｴﾅｸﾞﾝ ﾌｸｵｶﾁﾖｳ',
      full_lower: 'エナグン　フクオカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-13',
    remark: { start: null, end: '21206中津川市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '566',
    name: '恵那郡　蛭川村',
    kana_name: {
      half_upper: 'ｴﾅｸﾞﾝ ﾋﾙｶﾜﾑﾗ',
      full_lower: 'エナグン　ヒルカワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-13',
    remark: { start: null, end: '21206中津川市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '567',
    name: '恵那郡　岩村町',
    kana_name: {
      half_upper: 'ｴﾅｸﾞﾝ ｲﾜﾑﾗﾁﾖｳ',
      full_lower: 'エナグン　イワムラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-25',
    remark: { start: null, end: '21210恵那市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '568',
    name: '恵那郡　山岡町',
    kana_name: {
      half_upper: 'ｴﾅｸﾞﾝ ﾔﾏｵｶﾁﾖｳ',
      full_lower: 'エナグン　ヤマオカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-25',
    remark: { start: null, end: '21210恵那市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '569',
    name: '恵那郡　明智町',
    kana_name: {
      half_upper: 'ｴﾅｸﾞﾝ ｱｹﾁﾁﾖｳ',
      full_lower: 'エナグン　アケチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-25',
    remark: { start: null, end: '21210恵那市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '570',
    name: '恵那郡　串原村',
    kana_name: {
      half_upper: 'ｴﾅｸﾞﾝ ｸｼﾊﾗﾑﾗ',
      full_lower: 'エナグン　クシハラムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-25',
    remark: { start: null, end: '21210恵那市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '571',
    name: '恵那郡　上矢作町',
    kana_name: {
      half_upper: 'ｴﾅｸﾞﾝ ｶﾐﾔﾊｷﾞﾁﾖｳ',
      full_lower: 'エナグン　カミヤハギチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-25',
    remark: { start: null, end: '21210恵那市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '580',
    name: '益田郡',
    kana_name: { half_upper: 'ﾏｼﾀｸﾞﾝ', full_lower: 'マシタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '581',
    name: '益田郡　萩原町',
    kana_name: {
      half_upper: 'ﾏｼﾀｸﾞﾝ ﾊｷﾞﾜﾗﾁﾖｳ',
      full_lower: 'マシタグン　ハギワラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '21220下呂市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '582',
    name: '益田郡　小坂町',
    kana_name: {
      half_upper: 'ﾏｼﾀｸﾞﾝ ｵｻｶﾁﾖｳ',
      full_lower: 'マシタグン　オサカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '21220下呂市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '583',
    name: '益田郡　下呂町',
    kana_name: {
      half_upper: 'ﾏｼﾀｸﾞﾝ ｹﾞﾛﾁﾖｳ',
      full_lower: 'マシタグン　ゲロチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '21220下呂市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '584',
    name: '益田郡　金山町',
    kana_name: {
      half_upper: 'ﾏｼﾀｸﾞﾝ ｶﾅﾔﾏﾁﾖｳ',
      full_lower: 'マシタグン　カナヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '21220下呂市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '585',
    name: '益田郡　馬瀬村',
    kana_name: {
      half_upper: 'ﾏｼﾀｸﾞﾝ ﾏｾﾞﾑﾗ',
      full_lower: 'マシタグン　マゼムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '21220下呂市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '600',
    name: '大野郡',
    kana_name: { half_upper: 'ｵｵﾉｸﾞﾝ', full_lower: 'オオノグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '601',
    name: '大野郡　丹生川村',
    kana_name: {
      half_upper: 'ｵｵﾉｸﾞﾝ ﾆﾕｳｶﾜﾑﾗ',
      full_lower: 'オオノグン　ニュウカワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '21203高山市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '602',
    name: '大野郡　清見村',
    kana_name: {
      half_upper: 'ｵｵﾉｸﾞﾝ ｷﾖﾐﾑﾗ',
      full_lower: 'オオノグン　キヨミムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '21203高山市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '603',
    name: '大野郡　荘川村',
    kana_name: {
      half_upper: 'ｵｵﾉｸﾞﾝ ｼﾖｳｶﾜﾑﾗ',
      full_lower: 'オオノグン　ショウカワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '21203高山市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '604',
    name: '大野郡　白川村',
    kana_name: {
      half_upper: 'ｵｵﾉｸﾞﾝ ｼﾗｶﾜﾑﾗ',
      full_lower: 'オオノグン　シラカワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '605',
    name: '大野郡　宮村',
    kana_name: {
      half_upper: 'ｵｵﾉｸﾞﾝ ﾐﾔﾑﾗ',
      full_lower: 'オオノグン　ミヤムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '21203高山市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '606',
    name: '大野郡　久々野町',
    kana_name: {
      half_upper: 'ｵｵﾉｸﾞﾝ ｸｸﾞﾉﾁﾖｳ',
      full_lower: 'オオノグン　クグノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '21203高山市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '607',
    name: '大野郡　朝日村',
    kana_name: {
      half_upper: 'ｵｵﾉｸﾞﾝ ｱｻﾋﾑﾗ',
      full_lower: 'オオノグン　アサヒムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '21203高山市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '608',
    name: '大野郡　高根村',
    kana_name: {
      half_upper: 'ｵｵﾉｸﾞﾝ ﾀｶﾈﾑﾗ',
      full_lower: 'オオノグン　タカネムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '21203高山市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '620',
    name: '吉城郡',
    kana_name: { half_upper: 'ﾖｼｷｸﾞﾝ', full_lower: 'ヨシキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '621',
    name: '吉城郡　古川町',
    kana_name: {
      half_upper: 'ﾖｼｷｸﾞﾝ ﾌﾙｶﾜﾁﾖｳ',
      full_lower: 'ヨシキグン　フルカワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-02-01',
    remark: { start: null, end: '21217飛騨市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '622',
    name: '吉城郡　国府町',
    kana_name: {
      half_upper: 'ﾖｼｷｸﾞﾝ ｺｸﾌﾁﾖｳ',
      full_lower: 'ヨシキグン　コクフチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '21203高山市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '623',
    name: '吉城郡　河合村',
    kana_name: {
      half_upper: 'ﾖｼｷｸﾞﾝ ｶﾜｲﾑﾗ',
      full_lower: 'ヨシキグン　カワイムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-02-01',
    remark: { start: null, end: '21217飛騨市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '624',
    name: '吉城郡　宮川村',
    kana_name: {
      half_upper: 'ﾖｼｷｸﾞﾝ ﾐﾔｶﾞﾜﾑﾗ',
      full_lower: 'ヨシキグン　ミヤガワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-02-01',
    remark: { start: null, end: '21217飛騨市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '625',
    name: '吉城郡　神岡町',
    kana_name: {
      half_upper: 'ﾖｼｷｸﾞﾝ ｶﾐｵｶﾁﾖｳ',
      full_lower: 'ヨシキグン　カミオカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-02-01',
    remark: { start: null, end: '21217飛騨市', other: null },
    note: null,
  },
  {
    pref_code: '21',
    city_code: '626',
    name: '吉城郡　上宝村',
    kana_name: {
      half_upper: 'ﾖｼｷｸﾞﾝ ｶﾐﾀｶﾗﾑﾗ',
      full_lower: 'ヨシキグン　カミタカラムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '21203高山市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '100',
    name: '静岡市',
    kana_name: { half_upper: 'ｼｽﾞｵｶｼ', full_lower: 'シズオカシ' },
    en: null,
    start_date: '2005-04-01',
    end_date: null,
    remark: { start: 'H17/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '101',
    name: '静岡市　葵区',
    kana_name: {
      half_upper: 'ｼｽﾞｵｶｼ ｱｵｲｸ',
      full_lower: 'シズオカシ　アオイク',
    },
    en: null,
    start_date: '2005-04-01',
    end_date: null,
    remark: { start: 'H17/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '102',
    name: '静岡市　駿河区',
    kana_name: {
      half_upper: 'ｼｽﾞｵｶｼ ｽﾙｶﾞｸ',
      full_lower: 'シズオカシ　スルガク',
    },
    en: null,
    start_date: '2005-04-01',
    end_date: null,
    remark: { start: 'H17/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '103',
    name: '静岡市　清水区',
    kana_name: {
      half_upper: 'ｼｽﾞｵｶｼ ｼﾐｽﾞｸ',
      full_lower: 'シズオカシ　シミズク',
    },
    en: null,
    start_date: '2005-04-01',
    end_date: null,
    remark: { start: 'H17/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '130',
    name: '浜松市',
    kana_name: { half_upper: 'ﾊﾏﾏﾂｼ', full_lower: 'ハママツシ' },
    en: null,
    start_date: '2007-04-01',
    end_date: null,
    remark: { start: 'H19/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '131',
    name: '浜松市　中区',
    kana_name: { half_upper: 'ﾊﾏﾏﾂｼ ﾅｶｸ', full_lower: 'ハママツシ　ナカク' },
    en: null,
    start_date: '2007-04-01',
    end_date: null,
    remark: { start: 'H19/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '132',
    name: '浜松市　東区',
    kana_name: {
      half_upper: 'ﾊﾏﾏﾂｼ ﾋｶﾞｼｸ',
      full_lower: 'ハママツシ　ヒガシク',
    },
    en: null,
    start_date: '2007-04-01',
    end_date: null,
    remark: { start: 'H19/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '133',
    name: '浜松市　西区',
    kana_name: { half_upper: 'ﾊﾏﾏﾂｼ ﾆｼｸ', full_lower: 'ハママツシ　ニシク' },
    en: null,
    start_date: '2007-04-01',
    end_date: null,
    remark: { start: 'H19/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '134',
    name: '浜松市　南区',
    kana_name: {
      half_upper: 'ﾊﾏﾏﾂｼ ﾐﾅﾐｸ',
      full_lower: 'ハママツシ　ミナミク',
    },
    en: null,
    start_date: '2007-04-01',
    end_date: null,
    remark: { start: 'H19/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '135',
    name: '浜松市　北区',
    kana_name: { half_upper: 'ﾊﾏﾏﾂｼ ｷﾀｸ', full_lower: 'ハママツシ　キタク' },
    en: null,
    start_date: '2007-04-01',
    end_date: null,
    remark: { start: 'H19/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '136',
    name: '浜松市　浜北区',
    kana_name: {
      half_upper: 'ﾊﾏﾏﾂｼ ﾊﾏｷﾀｸ',
      full_lower: 'ハママツシ　ハマキタク',
    },
    en: null,
    start_date: '2007-04-01',
    end_date: null,
    remark: { start: 'H19/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '137',
    name: '浜松市　天竜区',
    kana_name: {
      half_upper: 'ﾊﾏﾏﾂｼ ﾃﾝﾘﾕｳｸ',
      full_lower: 'ハママツシ　テンリュウク',
    },
    en: null,
    start_date: '2007-04-01',
    end_date: null,
    remark: { start: 'H19/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '201',
    name: '静岡市',
    kana_name: { half_upper: 'ｼｽﾞｵｶｼ', full_lower: 'シズオカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '22100静岡市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '202',
    name: '浜松市',
    kana_name: { half_upper: 'ﾊﾏﾏﾂｼ', full_lower: 'ハママツシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-04-01',
    remark: { start: null, end: '22130浜松市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '203',
    name: '沼津市',
    kana_name: { half_upper: 'ﾇﾏﾂﾞｼ', full_lower: 'ヌマヅシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '204',
    name: '清水市',
    kana_name: { half_upper: 'ｼﾐｽﾞｼ', full_lower: 'シミズシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: '22201静岡市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '205',
    name: '熱海市',
    kana_name: { half_upper: 'ｱﾀﾐｼ', full_lower: 'アタミシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '206',
    name: '三島市',
    kana_name: { half_upper: 'ﾐｼﾏｼ', full_lower: 'ミシマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '207',
    name: '富士宮市',
    kana_name: { half_upper: 'ﾌｼﾞﾉﾐﾔｼ', full_lower: 'フジノミヤシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '208',
    name: '伊東市',
    kana_name: { half_upper: 'ｲﾄｳｼ', full_lower: 'イトウシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '209',
    name: '島田市',
    kana_name: { half_upper: 'ｼﾏﾀﾞｼ', full_lower: 'シマダシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '210',
    name: '富士市',
    kana_name: { half_upper: 'ﾌｼﾞｼ', full_lower: 'フジシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '211',
    name: '磐田市',
    kana_name: { half_upper: 'ｲﾜﾀｼ', full_lower: 'イワタシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '212',
    name: '焼津市',
    kana_name: { half_upper: 'ﾔｲﾂﾞｼ', full_lower: 'ヤイヅシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '213',
    name: '掛川市',
    kana_name: { half_upper: 'ｶｹｶﾞﾜｼ', full_lower: 'カケガワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '214',
    name: '藤枝市',
    kana_name: { half_upper: 'ﾌｼﾞｴﾀﾞｼ', full_lower: 'フジエダシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '215',
    name: '御殿場市',
    kana_name: { half_upper: 'ｺﾞﾃﾝﾊﾞｼ', full_lower: 'ゴテンバシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '216',
    name: '袋井市',
    kana_name: { half_upper: 'ﾌｸﾛｲｼ', full_lower: 'フクロイシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '217',
    name: '天竜市',
    kana_name: { half_upper: 'ﾃﾝﾘﾕｳｼ', full_lower: 'テンリュウシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-07-01',
    remark: { start: null, end: '22202浜松市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '218',
    name: '浜北市',
    kana_name: { half_upper: 'ﾊﾏｷﾀｼ', full_lower: 'ハマキタシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-07-01',
    remark: { start: null, end: '22202浜松市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '219',
    name: '下田市',
    kana_name: { half_upper: 'ｼﾓﾀﾞｼ', full_lower: 'シモダシ' },
    en: null,
    start_date: '1971-01-01',
    end_date: null,
    remark: { start: 'S46/01/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '220',
    name: '裾野市',
    kana_name: { half_upper: 'ｽｿﾉｼ', full_lower: 'スソノシ' },
    en: null,
    start_date: '1971-01-01',
    end_date: null,
    remark: { start: 'S46/01/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '221',
    name: '湖西市',
    kana_name: { half_upper: 'ｺｻｲｼ', full_lower: 'コサイシ' },
    en: null,
    start_date: '1972-01-01',
    end_date: null,
    remark: { start: 'S47/01/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '222',
    name: '伊豆市',
    kana_name: { half_upper: 'ｲｽﾞｼ', full_lower: 'イズシ' },
    en: null,
    start_date: '2004-04-01',
    end_date: null,
    remark: { start: 'H16/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '223',
    name: '御前崎市',
    kana_name: { half_upper: 'ｵﾏｴｻﾞｷｼ', full_lower: 'オマエザキシ' },
    en: null,
    start_date: '2004-04-01',
    end_date: null,
    remark: { start: 'H16/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '224',
    name: '菊川市',
    kana_name: { half_upper: 'ｷｸｶﾞﾜｼ', full_lower: 'キクガワシ' },
    en: null,
    start_date: '2005-01-17',
    end_date: null,
    remark: { start: 'H17/01/17新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '225',
    name: '伊豆の国市',
    kana_name: { half_upper: 'ｲｽﾞﾉｸﾆｼ', full_lower: 'イズノクニシ' },
    en: null,
    start_date: '2005-04-01',
    end_date: null,
    remark: { start: 'H17/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '226',
    name: '牧之原市',
    kana_name: { half_upper: 'ﾏｷﾉﾊﾗｼ', full_lower: 'マキノハラシ' },
    en: null,
    start_date: '2005-10-11',
    end_date: null,
    remark: { start: 'H17/10/11新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '300',
    name: '賀茂郡',
    kana_name: { half_upper: 'ｶﾓｸﾞﾝ', full_lower: 'カモグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '301',
    name: '賀茂郡　東伊豆町',
    kana_name: {
      half_upper: 'ｶﾓｸﾞﾝ ﾋｶﾞｼｲｽﾞﾁﾖｳ',
      full_lower: 'カモグン　ヒガシイズチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '302',
    name: '賀茂郡　河津町',
    kana_name: {
      half_upper: 'ｶﾓｸﾞﾝ ｶﾜﾂﾞﾁﾖｳ',
      full_lower: 'カモグン　カワヅチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '303',
    name: '賀茂郡　下田町',
    kana_name: {
      half_upper: 'ｶﾓｸﾞﾝ ｼﾓﾀﾞﾏﾁ',
      full_lower: 'カモグン　シモダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-01-01',
    remark: { start: null, end: '22219下田市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '304',
    name: '賀茂郡　南伊豆町',
    kana_name: {
      half_upper: 'ｶﾓｸﾞﾝ ﾐﾅﾐｲｽﾞﾁﾖｳ',
      full_lower: 'カモグン　ミナミイズチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '305',
    name: '賀茂郡　松崎町',
    kana_name: {
      half_upper: 'ｶﾓｸﾞﾝ ﾏﾂｻﾞｷﾁﾖｳ',
      full_lower: 'カモグン　マツザキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '306',
    name: '賀茂郡　西伊豆町',
    kana_name: {
      half_upper: 'ｶﾓｸﾞﾝ ﾆｼｲｽﾞﾁﾖｳ',
      full_lower: 'カモグン　ニシイズチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '307',
    name: '賀茂郡　賀茂村',
    kana_name: { half_upper: 'ｶﾓｸﾞﾝ ｶﾓﾑﾗ', full_lower: 'カモグン　カモムラ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '22306賀茂郡　西伊豆町', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '320',
    name: '田方郡',
    kana_name: { half_upper: 'ﾀｶﾞﾀｸﾞﾝ', full_lower: 'タガタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '321',
    name: '田方郡　伊豆長岡町',
    kana_name: {
      half_upper: 'ﾀｶﾞﾀｸﾞﾝ ｲｽﾞﾅｶﾞｵｶﾁﾖｳ',
      full_lower: 'タガタグン　イズナガオカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '22225伊豆の国市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '322',
    name: '田方郡　修善寺町',
    kana_name: {
      half_upper: 'ﾀｶﾞﾀｸﾞﾝ ｼﾕｾﾞﾝｼﾞﾁﾖｳ',
      full_lower: 'タガタグン　シュゼンジチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '22222伊豆市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '323',
    name: '田方郡　戸田村',
    kana_name: {
      half_upper: 'ﾀｶﾞﾀｸﾞﾝ ﾍﾀﾞﾑﾗ',
      full_lower: 'タガタグン　ヘダムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '22203沼津市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '324',
    name: '田方郡　土肥町',
    kana_name: {
      half_upper: 'ﾀｶﾞﾀｸﾞﾝ ﾄｲﾁﾖｳ',
      full_lower: 'タガタグン　トイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '22222伊豆市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '325',
    name: '田方郡　函南町',
    kana_name: {
      half_upper: 'ﾀｶﾞﾀｸﾞﾝ ｶﾝﾅﾐﾁﾖｳ',
      full_lower: 'タガタグン　カンナミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '326',
    name: '田方郡　韮山町',
    kana_name: {
      half_upper: 'ﾀｶﾞﾀｸﾞﾝ ﾆﾗﾔﾏﾁﾖｳ',
      full_lower: 'タガタグン　ニラヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '22225伊豆の国市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '327',
    name: '田方郡　大仁町',
    kana_name: {
      half_upper: 'ﾀｶﾞﾀｸﾞﾝ ｵｵﾋﾄﾁﾖｳ',
      full_lower: 'タガタグン　オオヒトチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '22225伊豆の国市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '328',
    name: '田方郡　天城湯ケ島町',
    kana_name: {
      half_upper: 'ﾀｶﾞﾀｸﾞﾝ ｱﾏｷﾞﾕｶﾞｼﾏﾁﾖｳ',
      full_lower: 'タガタグン　アマギユガシマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '22222伊豆市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '329',
    name: '田方郡　中伊豆町',
    kana_name: {
      half_upper: 'ﾀｶﾞﾀｸﾞﾝ ﾅｶｲｽﾞﾁﾖｳ',
      full_lower: 'タガタグン　ナカイズチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '22222伊豆市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '340',
    name: '駿東郡',
    kana_name: { half_upper: 'ｽﾝﾄｳｸﾞﾝ', full_lower: 'スントウグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '341',
    name: '駿東郡　清水町',
    kana_name: {
      half_upper: 'ｽﾝﾄｳｸﾞﾝ ｼﾐｽﾞﾁﾖｳ',
      full_lower: 'スントウグン　シミズチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '342',
    name: '駿東郡　長泉町',
    kana_name: {
      half_upper: 'ｽﾝﾄｳｸﾞﾝ ﾅｶﾞｲｽﾞﾐﾁﾖｳ',
      full_lower: 'スントウグン　ナガイズミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '343',
    name: '駿東郡　裾野町',
    kana_name: {
      half_upper: 'ｽﾝﾄｳｸﾞﾝ ｽｿﾉﾁﾖｳ',
      full_lower: 'スントウグン　スソノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-01-01',
    remark: { start: null, end: '22220裾野市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '344',
    name: '駿東郡　小山町',
    kana_name: {
      half_upper: 'ｽﾝﾄｳｸﾞﾝ ｵﾔﾏﾁﾖｳ',
      full_lower: 'スントウグン　オヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '360',
    name: '富士郡',
    kana_name: { half_upper: 'ﾌｼﾞｸﾞﾝ', full_lower: 'フジグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-23',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '361',
    name: '富士郡　芝川町',
    kana_name: {
      half_upper: 'ﾌｼﾞｸﾞﾝ ｼﾊﾞｶﾜﾁﾖｳ',
      full_lower: 'フジグン　シバカワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-23',
    remark: { start: null, end: '22207富士宮市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '380',
    name: '庵原郡',
    kana_name: { half_upper: 'ｲﾊﾗｸﾞﾝ', full_lower: 'イハラグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2008-11-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '381',
    name: '庵原郡　富士川町',
    kana_name: {
      half_upper: 'ｲﾊﾗｸﾞﾝ ﾌｼﾞｶﾜﾁﾖｳ',
      full_lower: 'イハラグン　フジカワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2008-11-01',
    remark: { start: null, end: '22210富士市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '382',
    name: '庵原郡　蒲原町',
    kana_name: {
      half_upper: 'ｲﾊﾗｸﾞﾝ ｶﾝﾊﾞﾗﾁﾖｳ',
      full_lower: 'イハラグン　カンバラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '22103静岡市　清水区', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '383',
    name: '庵原郡　由比町',
    kana_name: {
      half_upper: 'ｲﾊﾗｸﾞﾝ ﾕｲﾁﾖｳ',
      full_lower: 'イハラグン　ユイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2008-11-01',
    remark: { start: null, end: '22103静岡市　清水区', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '400',
    name: '志太郡',
    kana_name: { half_upper: 'ｼﾀﾞｸﾞﾝ', full_lower: 'シダグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2009-01-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '401',
    name: '志太郡　岡部町',
    kana_name: {
      half_upper: 'ｼﾀﾞｸﾞﾝ ｵｶﾍﾞﾁﾖｳ',
      full_lower: 'シダグン　オカベチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2009-01-01',
    remark: { start: null, end: '22214藤枝市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '402',
    name: '志太郡　大井川町',
    kana_name: {
      half_upper: 'ｼﾀﾞｸﾞﾝ ｵｵｲｶﾞﾜﾁﾖｳ',
      full_lower: 'シダグン　オオイガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2008-11-01',
    remark: { start: null, end: '22212焼津市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '420',
    name: '榛原郡',
    kana_name: { half_upper: 'ﾊｲﾊﾞﾗｸﾞﾝ', full_lower: 'ハイバラグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '421',
    name: '榛原郡　御前崎町',
    kana_name: {
      half_upper: 'ﾊｲﾊﾞﾗｸﾞﾝ ｵﾏｴｻﾞｷﾁﾖｳ',
      full_lower: 'ハイバラグン　オマエザキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '22223御前崎市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '422',
    name: '榛原郡　相良町',
    kana_name: {
      half_upper: 'ﾊｲﾊﾞﾗｸﾞﾝ ｻｶﾞﾗﾁﾖｳ',
      full_lower: 'ハイバラグン　サガラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-11',
    remark: { start: null, end: '22226牧之原市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '423',
    name: '榛原郡　榛原町',
    kana_name: {
      half_upper: 'ﾊｲﾊﾞﾗｸﾞﾝ ﾊｲﾊﾞﾗﾁﾖｳ',
      full_lower: 'ハイバラグン　ハイバラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-11',
    remark: { start: null, end: '22226牧之原市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '424',
    name: '榛原郡　吉田町',
    kana_name: {
      half_upper: 'ﾊｲﾊﾞﾗｸﾞﾝ ﾖｼﾀﾞﾁﾖｳ',
      full_lower: 'ハイバラグン　ヨシダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '425',
    name: '榛原郡　金谷町',
    kana_name: {
      half_upper: 'ﾊｲﾊﾞﾗｸﾞﾝ ｶﾅﾔﾁﾖｳ',
      full_lower: 'ハイバラグン　カナヤチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-05-05',
    remark: { start: null, end: '22209島田市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '426',
    name: '榛原郡　川根町',
    kana_name: {
      half_upper: 'ﾊｲﾊﾞﾗｸﾞﾝ ｶﾜﾈﾁﾖｳ',
      full_lower: 'ハイバラグン　カワネチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2008-04-01',
    remark: { start: null, end: '22209島田市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '427',
    name: '榛原郡　中川根町',
    kana_name: {
      half_upper: 'ﾊｲﾊﾞﾗｸﾞﾝ ﾅｶｶﾜﾈﾁﾖｳ',
      full_lower: 'ハイバラグン　ナカカワネチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-20',
    remark: { start: null, end: '22429榛原郡　川根本町', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '428',
    name: '榛原郡　本川根町',
    kana_name: {
      half_upper: 'ﾊｲﾊﾞﾗｸﾞﾝ ﾎﾝｶﾜﾈﾁﾖｳ',
      full_lower: 'ハイバラグン　ホンカワネチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-20',
    remark: { start: null, end: '22429榛原郡　川根本町', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '429',
    name: '榛原郡　川根本町',
    kana_name: {
      half_upper: 'ﾊｲﾊﾞﾗｸﾞﾝ ｶﾜﾈﾎﾝﾁﾖｳ',
      full_lower: 'ハイバラグン　カワネホンチョウ',
    },
    en: null,
    start_date: '2005-09-20',
    end_date: null,
    remark: { start: 'H17/09/20新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '440',
    name: '小笠郡',
    kana_name: { half_upper: 'ｵｶﾞｻｸﾞﾝ', full_lower: 'オガサグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '441',
    name: '小笠郡　城東村',
    kana_name: {
      half_upper: 'ｵｶﾞｻｸﾞﾝ ｷﾄｳﾑﾗ',
      full_lower: 'オガサグン　キトウムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1973-04-01',
    remark: { start: null, end: '22447小笠郡　大東町', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '442',
    name: '小笠郡　大須賀町',
    kana_name: {
      half_upper: 'ｵｶﾞｻｸﾞﾝ ｵｵｽｶﾁﾖｳ',
      full_lower: 'オガサグン　オオスカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '22213掛川市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '443',
    name: '小笠郡　大浜町',
    kana_name: {
      half_upper: 'ｵｶﾞｻｸﾞﾝ ｵｵﾊﾏﾁﾖｳ',
      full_lower: 'オガサグン　オオハマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1973-04-01',
    remark: { start: null, end: '22447小笠郡　大東町', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '444',
    name: '小笠郡　浜岡町',
    kana_name: {
      half_upper: 'ｵｶﾞｻｸﾞﾝ ﾊﾏｵｶﾁﾖｳ',
      full_lower: 'オガサグン　ハマオカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '22223御前崎市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '445',
    name: '小笠郡　小笠町',
    kana_name: {
      half_upper: 'ｵｶﾞｻｸﾞﾝ ｵｶﾞｻﾁﾖｳ',
      full_lower: 'オガサグン　オガサチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-17',
    remark: { start: null, end: '22224菊川市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '446',
    name: '小笠郡　菊川町',
    kana_name: {
      half_upper: 'ｵｶﾞｻｸﾞﾝ ｷｸｶﾞﾜﾁﾖｳ',
      full_lower: 'オガサグン　キクガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-17',
    remark: { start: null, end: '22224菊川市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '447',
    name: '小笠郡　大東町',
    kana_name: {
      half_upper: 'ｵｶﾞｻｸﾞﾝ ﾀﾞｲﾄｳﾁﾖｳ',
      full_lower: 'オガサグン　ダイトウチョウ',
    },
    en: null,
    start_date: '1973-04-01',
    end_date: '2005-04-01',
    remark: { start: 'S48/04/01新設', end: '22213掛川市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '460',
    name: '周智郡',
    kana_name: { half_upper: 'ｼﾕｳﾁｸﾞﾝ', full_lower: 'シュウチグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '461',
    name: '周智郡　森町',
    kana_name: {
      half_upper: 'ｼﾕｳﾁｸﾞﾝ ﾓﾘﾏﾁ',
      full_lower: 'シュウチグン　モリマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '462',
    name: '周智郡　春野町',
    kana_name: {
      half_upper: 'ｼﾕｳﾁｸﾞﾝ ﾊﾙﾉﾁﾖｳ',
      full_lower: 'シュウチグン　ハルノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-07-01',
    remark: { start: null, end: '22202浜松市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '480',
    name: '磐田郡',
    kana_name: { half_upper: 'ｲﾜﾀｸﾞﾝ', full_lower: 'イワタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-07-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '481',
    name: '磐田郡　浅羽町',
    kana_name: {
      half_upper: 'ｲﾜﾀｸﾞﾝ ｱｻﾊﾞﾁﾖｳ',
      full_lower: 'イワタグン　アサバチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '22216袋井市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '482',
    name: '磐田郡　福田町',
    kana_name: {
      half_upper: 'ｲﾜﾀｸﾞﾝ ﾌｸﾃﾞﾁﾖｳ',
      full_lower: 'イワタグン　フクデチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '22211磐田市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '483',
    name: '磐田郡　竜洋町',
    kana_name: {
      half_upper: 'ｲﾜﾀｸﾞﾝ ﾘﾕｳﾖｳﾁﾖｳ',
      full_lower: 'イワタグン　リュウヨウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '22211磐田市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '484',
    name: '磐田郡　豊田町',
    kana_name: {
      half_upper: 'ｲﾜﾀｸﾞﾝ ﾄﾖﾀﾞﾁﾖｳ',
      full_lower: 'イワタグン　トヨダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '22211磐田市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '485',
    name: '磐田郡　豊岡村',
    kana_name: {
      half_upper: 'ｲﾜﾀｸﾞﾝ ﾄﾖｵｶﾑﾗ',
      full_lower: 'イワタグン　トヨオカムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '22211磐田市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '486',
    name: '磐田郡　龍山村',
    kana_name: {
      half_upper: 'ｲﾜﾀｸﾞﾝ ﾀﾂﾔﾏﾑﾗ',
      full_lower: 'イワタグン　タツヤマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-07-01',
    remark: { start: null, end: '22202浜松市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '487',
    name: '磐田郡　佐久間町',
    kana_name: {
      half_upper: 'ｲﾜﾀｸﾞﾝ ｻｸﾏﾁﾖｳ',
      full_lower: 'イワタグン　サクマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-07-01',
    remark: { start: null, end: '22202浜松市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '488',
    name: '磐田郡　水窪町',
    kana_name: {
      half_upper: 'ｲﾜﾀｸﾞﾝ ﾐｻｸﾎﾞﾁﾖｳ',
      full_lower: 'イワタグン　ミサクボチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-07-01',
    remark: { start: null, end: '22202浜松市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '500',
    name: '浜名郡',
    kana_name: { half_upper: 'ﾊﾏﾅｸﾞﾝ', full_lower: 'ハマナグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-23',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '501',
    name: '浜名郡　可美村',
    kana_name: {
      half_upper: 'ﾊﾏﾅｸﾞﾝ ｶﾐﾑﾗ',
      full_lower: 'ハマナグン　カミムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1991-05-01',
    remark: { start: null, end: '22202浜松市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '502',
    name: '浜名郡　舞阪町',
    kana_name: {
      half_upper: 'ﾊﾏﾅｸﾞﾝ ﾏｲｻｶﾁﾖｳ',
      full_lower: 'ハマナグン　マイサカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-07-01',
    remark: { start: null, end: '22202浜松市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '503',
    name: '浜名郡　新居町',
    kana_name: {
      half_upper: 'ﾊﾏﾅｸﾞﾝ ｱﾗｲﾁﾖｳ',
      full_lower: 'ハマナグン　アライチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-23',
    remark: { start: null, end: '22221湖西市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '504',
    name: '浜名郡　湖西町',
    kana_name: {
      half_upper: 'ﾊﾏﾅｸﾞﾝ ｺｻｲﾁﾖｳ',
      full_lower: 'ハマナグン　コサイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-01-01',
    remark: { start: null, end: '22221湖西市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '505',
    name: '浜名郡　雄踏町',
    kana_name: {
      half_upper: 'ﾊﾏﾅｸﾞﾝ ﾕｳﾄｳﾁﾖｳ',
      full_lower: 'ハマナグン　ユウトウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-07-01',
    remark: { start: null, end: '22202浜松市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '520',
    name: '引佐郡',
    kana_name: { half_upper: 'ｲﾅｻｸﾞﾝ', full_lower: 'イナサグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-07-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '521',
    name: '引佐郡　細江町',
    kana_name: {
      half_upper: 'ｲﾅｻｸﾞﾝ ﾎｿｴﾁﾖｳ',
      full_lower: 'イナサグン　ホソエチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-07-01',
    remark: { start: null, end: '22202浜松市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '522',
    name: '引佐郡　引佐町',
    kana_name: {
      half_upper: 'ｲﾅｻｸﾞﾝ ｲﾅｻﾁﾖｳ',
      full_lower: 'イナサグン　イナサチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-07-01',
    remark: { start: null, end: '22202浜松市', other: null },
    note: null,
  },
  {
    pref_code: '22',
    city_code: '523',
    name: '引佐郡　三ケ日町',
    kana_name: {
      half_upper: 'ｲﾅｻｸﾞﾝ ﾐﾂｶﾋﾞﾁﾖｳ',
      full_lower: 'イナサグン　ミッカビチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-07-01',
    remark: { start: null, end: '22202浜松市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '100',
    name: '名古屋市',
    kana_name: { half_upper: 'ﾅｺﾞﾔｼ', full_lower: 'ナゴヤシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '101',
    name: '名古屋市　千種区',
    kana_name: { half_upper: 'ﾅｺﾞﾔｼ ﾁｸｻｸ', full_lower: 'ナゴヤシ　チクサク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '102',
    name: '名古屋市　東区',
    kana_name: {
      half_upper: 'ﾅｺﾞﾔｼ ﾋｶﾞｼｸ',
      full_lower: 'ナゴヤシ　ヒガシク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '103',
    name: '名古屋市　北区',
    kana_name: { half_upper: 'ﾅｺﾞﾔｼ ｷﾀｸ', full_lower: 'ナゴヤシ　キタク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '104',
    name: '名古屋市　西区',
    kana_name: { half_upper: 'ﾅｺﾞﾔｼ ﾆｼｸ', full_lower: 'ナゴヤシ　ニシク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '105',
    name: '名古屋市　中村区',
    kana_name: {
      half_upper: 'ﾅｺﾞﾔｼ ﾅｶﾑﾗｸ',
      full_lower: 'ナゴヤシ　ナカムラク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '106',
    name: '名古屋市　中区',
    kana_name: { half_upper: 'ﾅｺﾞﾔｼ ﾅｶｸ', full_lower: 'ナゴヤシ　ナカク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '107',
    name: '名古屋市　昭和区',
    kana_name: {
      half_upper: 'ﾅｺﾞﾔｼ ｼﾖｳﾜｸ',
      full_lower: 'ナゴヤシ　ショウワク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '108',
    name: '名古屋市　瑞穂区',
    kana_name: {
      half_upper: 'ﾅｺﾞﾔｼ ﾐｽﾞﾎｸ',
      full_lower: 'ナゴヤシ　ミズホク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '109',
    name: '名古屋市　熱田区',
    kana_name: { half_upper: 'ﾅｺﾞﾔｼ ｱﾂﾀｸ', full_lower: 'ナゴヤシ　アツタク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '110',
    name: '名古屋市　中川区',
    kana_name: {
      half_upper: 'ﾅｺﾞﾔｼ ﾅｶｶﾞﾜｸ',
      full_lower: 'ナゴヤシ　ナカガワク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '111',
    name: '名古屋市　港区',
    kana_name: { half_upper: 'ﾅｺﾞﾔｼ ﾐﾅﾄｸ', full_lower: 'ナゴヤシ　ミナトク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '112',
    name: '名古屋市　南区',
    kana_name: { half_upper: 'ﾅｺﾞﾔｼ ﾐﾅﾐｸ', full_lower: 'ナゴヤシ　ミナミク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '113',
    name: '名古屋市　守山区',
    kana_name: {
      half_upper: 'ﾅｺﾞﾔｼ ﾓﾘﾔﾏｸ',
      full_lower: 'ナゴヤシ　モリヤマク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '114',
    name: '名古屋市　緑区',
    kana_name: {
      half_upper: 'ﾅｺﾞﾔｼ ﾐﾄﾞﾘｸ',
      full_lower: 'ナゴヤシ　ミドリク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '115',
    name: '名古屋市　名東区',
    kana_name: {
      half_upper: 'ﾅｺﾞﾔｼ ﾒｲﾄｳｸ',
      full_lower: 'ナゴヤシ　メイトウク',
    },
    en: null,
    start_date: '1975-02-01',
    end_date: null,
    remark: {
      start: 'S50/02/01千種区(23101)から分離',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '116',
    name: '名古屋市　天白区',
    kana_name: {
      half_upper: 'ﾅｺﾞﾔｼ ﾃﾝﾊﾟｸｸ',
      full_lower: 'ナゴヤシ　テンパクク',
    },
    en: null,
    start_date: '1975-02-01',
    end_date: null,
    remark: {
      start: 'S50/02/01昭和区(23107)から分離',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '201',
    name: '豊橋市',
    kana_name: { half_upper: 'ﾄﾖﾊｼｼ', full_lower: 'トヨハシシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '202',
    name: '岡崎市',
    kana_name: { half_upper: 'ｵｶｻﾞｷｼ', full_lower: 'オカザキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '203',
    name: '一宮市',
    kana_name: { half_upper: 'ｲﾁﾉﾐﾔｼ', full_lower: 'イチノミヤシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '204',
    name: '瀬戸市',
    kana_name: { half_upper: 'ｾﾄｼ', full_lower: 'セトシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '205',
    name: '半田市',
    kana_name: { half_upper: 'ﾊﾝﾀﾞｼ', full_lower: 'ハンダシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '206',
    name: '春日井市',
    kana_name: { half_upper: 'ｶｽｶﾞｲｼ', full_lower: 'カスガイシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '207',
    name: '豊川市',
    kana_name: { half_upper: 'ﾄﾖｶﾜｼ', full_lower: 'トヨカワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '208',
    name: '津島市',
    kana_name: { half_upper: 'ﾂｼﾏｼ', full_lower: 'ツシマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '209',
    name: '碧南市',
    kana_name: { half_upper: 'ﾍｷﾅﾝｼ', full_lower: 'ヘキナンシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '210',
    name: '刈谷市',
    kana_name: { half_upper: 'ｶﾘﾔｼ', full_lower: 'カリヤシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '211',
    name: '豊田市',
    kana_name: { half_upper: 'ﾄﾖﾀｼ', full_lower: 'トヨタシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '212',
    name: '安城市',
    kana_name: { half_upper: 'ｱﾝｼﾞﾖｳｼ', full_lower: 'アンジョウシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '213',
    name: '西尾市',
    kana_name: { half_upper: 'ﾆｼｵｼ', full_lower: 'ニシオシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '214',
    name: '蒲郡市',
    kana_name: { half_upper: 'ｶﾞﾏｺﾞｵﾘｼ', full_lower: 'ガマゴオリシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '215',
    name: '犬山市',
    kana_name: { half_upper: 'ｲﾇﾔﾏｼ', full_lower: 'イヌヤマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '216',
    name: '常滑市',
    kana_name: { half_upper: 'ﾄｺﾅﾒｼ', full_lower: 'トコナメシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '217',
    name: '江南市',
    kana_name: { half_upper: 'ｺｳﾅﾝｼ', full_lower: 'コウナンシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '218',
    name: '尾西市',
    kana_name: { half_upper: 'ﾋﾞｻｲｼ', full_lower: 'ビサイシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '23203一宮市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '219',
    name: '小牧市',
    kana_name: { half_upper: 'ｺﾏｷｼ', full_lower: 'コマキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '220',
    name: '稲沢市',
    kana_name: { half_upper: 'ｲﾅｻﾞﾜｼ', full_lower: 'イナザワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '221',
    name: '新城市',
    kana_name: { half_upper: 'ｼﾝｼﾛｼ', full_lower: 'シンシロシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '222',
    name: '東海市',
    kana_name: { half_upper: 'ﾄｳｶｲｼ', full_lower: 'トウカイシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '223',
    name: '大府市',
    kana_name: { half_upper: 'ｵｵﾌﾞｼ', full_lower: 'オオブシ' },
    en: null,
    start_date: '1970-09-01',
    end_date: null,
    remark: { start: 'S45/09/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '224',
    name: '知多市',
    kana_name: { half_upper: 'ﾁﾀｼ', full_lower: 'チタシ' },
    en: null,
    start_date: '1970-09-01',
    end_date: null,
    remark: { start: 'S45/09/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '225',
    name: '知立市',
    kana_name: { half_upper: 'ﾁﾘﾕｳｼ', full_lower: 'チリュウシ' },
    en: null,
    start_date: '1970-12-01',
    end_date: null,
    remark: { start: 'S45/12/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '226',
    name: '尾張旭市',
    kana_name: { half_upper: 'ｵﾜﾘｱｻﾋｼ', full_lower: 'オワリアサヒシ' },
    en: null,
    start_date: '1970-12-01',
    end_date: null,
    remark: { start: 'S45/12/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '227',
    name: '高浜市',
    kana_name: { half_upper: 'ﾀｶﾊﾏｼ', full_lower: 'タカハマシ' },
    en: null,
    start_date: '1970-12-01',
    end_date: null,
    remark: { start: 'S45/12/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '228',
    name: '岩倉市',
    kana_name: { half_upper: 'ｲﾜｸﾗｼ', full_lower: 'イワクラシ' },
    en: null,
    start_date: '1971-12-01',
    end_date: null,
    remark: { start: 'S46/12/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '229',
    name: '豊明市',
    kana_name: { half_upper: 'ﾄﾖｱｹｼ', full_lower: 'トヨアケシ' },
    en: null,
    start_date: '1972-08-01',
    end_date: null,
    remark: { start: 'S47/08/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '230',
    name: '日進市',
    kana_name: { half_upper: 'ﾆﾂｼﾝｼ', full_lower: 'ニッシンシ' },
    en: null,
    start_date: '1994-10-01',
    end_date: null,
    remark: { start: 'H06/10/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '231',
    name: '田原市',
    kana_name: { half_upper: 'ﾀﾊﾗｼ', full_lower: 'タハラシ' },
    en: null,
    start_date: '2003-08-20',
    end_date: null,
    remark: { start: 'H15/08/20市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '232',
    name: '愛西市',
    kana_name: { half_upper: 'ｱｲｻｲｼ', full_lower: 'アイサイシ' },
    en: null,
    start_date: '2005-04-01',
    end_date: null,
    remark: { start: 'H17/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '233',
    name: '清須市',
    kana_name: { half_upper: 'ｷﾖｽｼ', full_lower: 'キヨスシ' },
    en: null,
    start_date: '2005-07-07',
    end_date: null,
    remark: { start: 'H17/07/07新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '234',
    name: '北名古屋市',
    kana_name: { half_upper: 'ｷﾀﾅｺﾞﾔｼ', full_lower: 'キタナゴヤシ' },
    en: null,
    start_date: '2006-03-20',
    end_date: null,
    remark: { start: 'H18/03/20新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '235',
    name: '弥富市',
    kana_name: { half_upper: 'ﾔﾄﾐｼ', full_lower: 'ヤトミシ' },
    en: null,
    start_date: '2006-04-01',
    end_date: null,
    remark: { start: 'H18/04/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '236',
    name: 'みよし市',
    kana_name: { half_upper: 'ﾐﾖｼｼ', full_lower: 'ミヨシシ' },
    en: null,
    start_date: '2010-01-04',
    end_date: null,
    remark: { start: 'H22/01/04市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '237',
    name: 'あま市',
    kana_name: { half_upper: 'ｱﾏｼ', full_lower: 'アマシ' },
    en: null,
    start_date: '2010-03-22',
    end_date: null,
    remark: { start: 'H22/03/22新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '238',
    name: '長久手市',
    kana_name: { half_upper: 'ﾅｶﾞｸﾃｼ', full_lower: 'ナガクテシ' },
    en: null,
    start_date: '2012-01-04',
    end_date: null,
    remark: { start: 'H24/01/04市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '300',
    name: '愛知郡',
    kana_name: { half_upper: 'ｱｲﾁｸﾞﾝ', full_lower: 'アイチグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '301',
    name: '愛知郡　豊明町',
    kana_name: {
      half_upper: 'ｱｲﾁｸﾞﾝ ﾄﾖｱｹﾁﾖｳ',
      full_lower: 'アイチグン　トヨアケチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-08-01',
    remark: { start: null, end: '23229豊明市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '302',
    name: '愛知郡　東郷町',
    kana_name: {
      half_upper: 'ｱｲﾁｸﾞﾝ ﾄｳｺﾞｳﾁﾖｳ',
      full_lower: 'アイチグン　トウゴウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '303',
    name: '愛知郡　日進町',
    kana_name: {
      half_upper: 'ｱｲﾁｸﾞﾝ ﾆﾂｼﾝﾁﾖｳ',
      full_lower: 'アイチグン　ニッシンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1994-10-01',
    remark: { start: null, end: '23230日進市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '304',
    name: '愛知郡　長久手町',
    kana_name: {
      half_upper: 'ｱｲﾁｸﾞﾝ ﾅｶﾞｸﾃﾁﾖｳ',
      full_lower: 'アイチグン　ナガクテチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2012-01-04',
    remark: { start: null, end: '23238長久手市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '320',
    name: '東春日井郡',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｶｽｶﾞｲｸﾞﾝ',
      full_lower: 'ヒガシカスガイグン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-12-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '321',
    name: '東春日井郡　旭町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｶｽｶﾞｲｸﾞﾝ ｱｻﾋﾁﾖｳ',
      full_lower: 'ヒガシカスガイグン　アサヒチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-12-01',
    remark: { start: null, end: '23226尾張旭市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '340',
    name: '西春日井郡',
    kana_name: { half_upper: 'ﾆｼｶｽｶﾞｲｸﾞﾝ', full_lower: 'ニシカスガイグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '341',
    name: '西春日井郡　西枇杷島町',
    kana_name: {
      half_upper: 'ﾆｼｶｽｶﾞｲｸﾞﾝ ﾆｼﾋﾞﾜｼﾞﾏﾁﾖｳ',
      full_lower: 'ニシカスガイグン　ニシビワジマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-07-07',
    remark: { start: null, end: '23233清須市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '342',
    name: '西春日井郡　豊山町',
    kana_name: {
      half_upper: 'ﾆｼｶｽｶﾞｲｸﾞﾝ ﾄﾖﾔﾏﾁﾖｳ',
      full_lower: 'ニシカスガイグン　トヨヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '343',
    name: '西春日井郡　師勝町',
    kana_name: {
      half_upper: 'ﾆｼｶｽｶﾞｲｸﾞﾝ ｼｶﾂﾁﾖｳ',
      full_lower: 'ニシカスガイグン　シカツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '23234北名古屋市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '344',
    name: '西春日井郡　西春町',
    kana_name: {
      half_upper: 'ﾆｼｶｽｶﾞｲｸﾞﾝ ﾆｼﾊﾙﾁﾖｳ',
      full_lower: 'ニシカスガイグン　ニシハルチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '23234北名古屋市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '345',
    name: '西春日井郡　春日町',
    kana_name: {
      half_upper: 'ﾆｼｶｽｶﾞｲｸﾞﾝ ﾊﾙﾋﾁﾖｳ',
      full_lower: 'ニシカスガイグン　ハルヒチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2009-10-01',
    remark: { start: null, end: '23233清須市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '346',
    name: '西春日井郡　清洲町',
    kana_name: {
      half_upper: 'ﾆｼｶｽｶﾞｲｸﾞﾝ ｷﾖｽﾁﾖｳ',
      full_lower: 'ニシカスガイグン　キヨスチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-07-07',
    remark: { start: null, end: '23233清須市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '347',
    name: '西春日井郡　新川町',
    kana_name: {
      half_upper: 'ﾆｼｶｽｶﾞｲｸﾞﾝ ｼﾝｶﾜﾁﾖｳ',
      full_lower: 'ニシカスガイグン　シンカワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-07-07',
    remark: { start: null, end: '23233清須市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '360',
    name: '丹羽郡',
    kana_name: { half_upper: 'ﾆﾜｸﾞﾝ', full_lower: 'ニワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '361',
    name: '丹羽郡　大口町',
    kana_name: {
      half_upper: 'ﾆﾜｸﾞﾝ ｵｵｸﾞﾁﾁﾖｳ',
      full_lower: 'ニワグン　オオグチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '362',
    name: '丹羽郡　扶桑町',
    kana_name: {
      half_upper: 'ﾆﾜｸﾞﾝ ﾌｿｳﾁﾖｳ',
      full_lower: 'ニワグン　フソウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '363',
    name: '丹羽郡　岩倉町',
    kana_name: {
      half_upper: 'ﾆﾜｸﾞﾝ ｲﾜｸﾗﾁﾖｳ',
      full_lower: 'ニワグン　イワクラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-12-01',
    remark: { start: null, end: '23228岩倉市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '380',
    name: '葉栗郡',
    kana_name: { half_upper: 'ﾊｸﾞﾘｸﾞﾝ', full_lower: 'ハグリグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '381',
    name: '葉栗郡　木曽川町',
    kana_name: {
      half_upper: 'ﾊｸﾞﾘｸﾞﾝ ｷｿｶﾞﾜﾁﾖｳ',
      full_lower: 'ハグリグン　キソガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '23203一宮市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '400',
    name: '中島郡',
    kana_name: { half_upper: 'ﾅｶｼﾏｸﾞﾝ', full_lower: 'ナカシマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '401',
    name: '中島郡　祖父江町',
    kana_name: {
      half_upper: 'ﾅｶｼﾏｸﾞﾝ ｿﾌﾞｴﾁﾖｳ',
      full_lower: 'ナカシマグン　ソブエチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '23220稲沢市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '402',
    name: '中島郡　平和町',
    kana_name: {
      half_upper: 'ﾅｶｼﾏｸﾞﾝ ﾍｲﾜﾁﾖｳ',
      full_lower: 'ナカシマグン　ヘイワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '23220稲沢市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '420',
    name: '海部郡',
    kana_name: { half_upper: 'ｱﾏｸﾞﾝ', full_lower: 'アマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '421',
    name: '海部郡　七宝町',
    kana_name: {
      half_upper: 'ｱﾏｸﾞﾝ ｼﾂﾎﾟｳﾁﾖｳ',
      full_lower: 'アマグン　シッポウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-22',
    remark: { start: null, end: '23237あま市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '422',
    name: '海部郡　美和町',
    kana_name: {
      half_upper: 'ｱﾏｸﾞﾝ ﾐﾜﾁﾖｳ',
      full_lower: 'アマグン　ミワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-22',
    remark: { start: null, end: '23237あま市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '423',
    name: '海部郡　甚目寺町',
    kana_name: {
      half_upper: 'ｱﾏｸﾞﾝ ｼﾞﾓｸｼﾞﾁﾖｳ',
      full_lower: 'アマグン　ジモクジチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-22',
    remark: { start: null, end: '23237あま市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '424',
    name: '海部郡　大治町',
    kana_name: {
      half_upper: 'ｱﾏｸﾞﾝ ｵｵﾊﾙﾁﾖｳ',
      full_lower: 'アマグン　オオハルチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '425',
    name: '海部郡　蟹江町',
    kana_name: {
      half_upper: 'ｱﾏｸﾞﾝ ｶﾆｴﾁﾖｳ',
      full_lower: 'アマグン　カニエチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '426',
    name: '海部郡　十四山村',
    kana_name: {
      half_upper: 'ｱﾏｸﾞﾝ ｼﾞﾕｳｼﾔﾏﾑﾗ',
      full_lower: 'アマグン　ジュウシヤマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-04-01',
    remark: { start: null, end: '23235弥富市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '427',
    name: '海部郡　飛島村',
    kana_name: {
      half_upper: 'ｱﾏｸﾞﾝ ﾄﾋﾞｼﾏﾑﾗ',
      full_lower: 'アマグン　トビシマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '428',
    name: '海部郡　弥富町',
    kana_name: {
      half_upper: 'ｱﾏｸﾞﾝ ﾔﾄﾐﾁﾖｳ',
      full_lower: 'アマグン　ヤトミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-04-01',
    remark: { start: null, end: '23235弥富市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '429',
    name: '海部郡　佐屋町',
    kana_name: {
      half_upper: 'ｱﾏｸﾞﾝ ｻﾔﾁﾖｳ',
      full_lower: 'アマグン　サヤチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '23232愛西市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '430',
    name: '海部郡　立田村',
    kana_name: {
      half_upper: 'ｱﾏｸﾞﾝ ﾀﾂﾀﾑﾗ',
      full_lower: 'アマグン　タツタムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '23232愛西市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '431',
    name: '海部郡　八開村',
    kana_name: {
      half_upper: 'ｱﾏｸﾞﾝ ﾊﾁｶｲﾑﾗ',
      full_lower: 'アマグン　ハチカイムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '23232愛西市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '432',
    name: '海部郡　佐織町',
    kana_name: {
      half_upper: 'ｱﾏｸﾞﾝ ｻｵﾘﾁﾖｳ',
      full_lower: 'アマグン　サオリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '23232愛西市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '440',
    name: '知多郡',
    kana_name: { half_upper: 'ﾁﾀｸﾞﾝ', full_lower: 'チタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '441',
    name: '知多郡　阿久比町',
    kana_name: {
      half_upper: 'ﾁﾀｸﾞﾝ ｱｸﾞｲﾁﾖｳ',
      full_lower: 'チタグン　アグイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '442',
    name: '知多郡　東浦町',
    kana_name: {
      half_upper: 'ﾁﾀｸﾞﾝ ﾋｶﾞｼｳﾗﾁﾖｳ',
      full_lower: 'チタグン　ヒガシウラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '443',
    name: '知多郡　大府町',
    kana_name: {
      half_upper: 'ﾁﾀｸﾞﾝ ｵｵﾌﾞﾁﾖｳ',
      full_lower: 'チタグン　オオブチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-09-01',
    remark: { start: null, end: '23223大府市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '444',
    name: '知多郡　知多町',
    kana_name: {
      half_upper: 'ﾁﾀｸﾞﾝ ﾁﾀﾁﾖｳ',
      full_lower: 'チタグン　チタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-09-01',
    remark: { start: null, end: '23224知多市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '445',
    name: '知多郡　南知多町',
    kana_name: {
      half_upper: 'ﾁﾀｸﾞﾝ ﾐﾅﾐﾁﾀﾁﾖｳ',
      full_lower: 'チタグン　ミナミチタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '446',
    name: '知多郡　美浜町',
    kana_name: {
      half_upper: 'ﾁﾀｸﾞﾝ ﾐﾊﾏﾁﾖｳ',
      full_lower: 'チタグン　ミハマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '447',
    name: '知多郡　武豊町',
    kana_name: {
      half_upper: 'ﾁﾀｸﾞﾝ ﾀｹﾄﾖﾁﾖｳ',
      full_lower: 'チタグン　タケトヨチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '448',
    name: '知多郡　上野町',
    kana_name: {
      half_upper: 'ﾁﾀｸﾞﾝ ｳｴﾉﾁﾖｳ',
      full_lower: 'チタグン　ウエノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1969-04-01',
    remark: { start: null, end: '23222東海市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '449',
    name: '知多郡　横須賀町',
    kana_name: {
      half_upper: 'ﾁﾀｸﾞﾝ ﾖｺｽｶﾁﾖｳ',
      full_lower: 'チタグン　ヨコスカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1969-04-01',
    remark: { start: null, end: '23222東海市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '460',
    name: '碧海郡',
    kana_name: { half_upper: 'ﾍｷｶｲｸﾞﾝ', full_lower: 'ヘキカイグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-12-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '461',
    name: '碧海郡　高浜町',
    kana_name: {
      half_upper: 'ﾍｷｶｲｸﾞﾝ ﾀｶﾊﾏﾁﾖｳ',
      full_lower: 'ヘキカイグン　タカハマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-12-01',
    remark: { start: null, end: '23227高浜市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '462',
    name: '碧海郡　知立町',
    kana_name: {
      half_upper: 'ﾍｷｶｲｸﾞﾝ ﾁﾘﾕｳﾁﾖｳ',
      full_lower: 'ヘキカイグン　チリュウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-12-01',
    remark: { start: null, end: '23225知立市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '480',
    name: '幡豆郡',
    kana_name: { half_upper: 'ﾊｽﾞｸﾞﾝ', full_lower: 'ハズグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2011-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '481',
    name: '幡豆郡　一色町',
    kana_name: {
      half_upper: 'ﾊｽﾞｸﾞﾝ ｲﾂｼｷﾁﾖｳ',
      full_lower: 'ハズグン　イッシキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2011-04-01',
    remark: { start: null, end: '23213西尾市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '482',
    name: '幡豆郡　吉良町',
    kana_name: {
      half_upper: 'ﾊｽﾞｸﾞﾝ ｷﾗﾁﾖｳ',
      full_lower: 'ハズグン　キラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2011-04-01',
    remark: { start: null, end: '23213西尾市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '483',
    name: '幡豆郡　幡豆町',
    kana_name: {
      half_upper: 'ﾊｽﾞｸﾞﾝ ﾊｽﾞﾁﾖｳ',
      full_lower: 'ハズグン　ハズチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2011-04-01',
    remark: { start: null, end: '23213西尾市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '500',
    name: '額田郡',
    kana_name: { half_upper: 'ﾇｶﾀｸﾞﾝ', full_lower: 'ヌカタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '501',
    name: '額田郡　幸田町',
    kana_name: {
      half_upper: 'ﾇｶﾀｸﾞﾝ ｺｳﾀﾁﾖｳ',
      full_lower: 'ヌカタグン　コウタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '502',
    name: '額田郡　額田町',
    kana_name: {
      half_upper: 'ﾇｶﾀｸﾞﾝ ﾇｶﾀﾁﾖｳ',
      full_lower: 'ヌカタグン　ヌカタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '23202岡崎市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '520',
    name: '西加茂郡',
    kana_name: { half_upper: 'ﾆｼｶﾓｸﾞﾝ', full_lower: 'ニシカモグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-01-04',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '521',
    name: '西加茂郡　三好町',
    kana_name: {
      half_upper: 'ﾆｼｶﾓｸﾞﾝ ﾐﾖｼﾁﾖｳ',
      full_lower: 'ニシカモグン　ミヨシチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-01-04',
    remark: { start: null, end: '23236みよし市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '522',
    name: '西加茂郡　藤岡町',
    kana_name: {
      half_upper: 'ﾆｼｶﾓｸﾞﾝ ﾌｼﾞｵｶﾁﾖｳ',
      full_lower: 'ニシカモグン　フジオカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '23211豊田市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '523',
    name: '西加茂郡　小原村',
    kana_name: {
      half_upper: 'ﾆｼｶﾓｸﾞﾝ ｵﾊﾞﾗﾑﾗ',
      full_lower: 'ニシカモグン　オバラムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '23211豊田市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '540',
    name: '東加茂郡',
    kana_name: { half_upper: 'ﾋｶﾞｼｶﾓｸﾞﾝ', full_lower: 'ヒガシカモグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '541',
    name: '東加茂郡　足助町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｶﾓｸﾞﾝ ｱｽｹﾁﾖｳ',
      full_lower: 'ヒガシカモグン　アスケチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '23211豊田市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '542',
    name: '東加茂郡　松平町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｶﾓｸﾞﾝ ﾏﾂﾀﾞｲﾗﾁﾖｳ',
      full_lower: 'ヒガシカモグン　マツダイラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-04-01',
    remark: { start: null, end: '23211豊田市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '543',
    name: '東加茂郡　下山村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｶﾓｸﾞﾝ ｼﾓﾔﾏﾑﾗ',
      full_lower: 'ヒガシカモグン　シモヤマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '23211豊田市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '544',
    name: '東加茂郡　旭町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｶﾓｸﾞﾝ ｱｻﾋﾁﾖｳ',
      full_lower: 'ヒガシカモグン　アサヒチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '23211豊田市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '545',
    name: '東加茂郡　稲武町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｶﾓｸﾞﾝ ｲﾅﾌﾞﾁﾖｳ',
      full_lower: 'ヒガシカモグン　イナブチョウ',
    },
    en: null,
    start_date: '2003-10-01',
    end_date: '2005-04-01',
    remark: { start: 'H15/10/01郡変更', end: '23211豊田市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '560',
    name: '北設楽郡',
    kana_name: { half_upper: 'ｷﾀｼﾀﾗｸﾞﾝ', full_lower: 'キタシタラグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '561',
    name: '北設楽郡　設楽町',
    kana_name: {
      half_upper: 'ｷﾀｼﾀﾗｸﾞﾝ ｼﾀﾗﾁﾖｳ',
      full_lower: 'キタシタラグン　シタラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '562',
    name: '北設楽郡　東栄町',
    kana_name: {
      half_upper: 'ｷﾀｼﾀﾗｸﾞﾝ ﾄｳｴｲﾁﾖｳ',
      full_lower: 'キタシタラグン　トウエイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '563',
    name: '北設楽郡　豊根村',
    kana_name: {
      half_upper: 'ｷﾀｼﾀﾗｸﾞﾝ ﾄﾖﾈﾑﾗ',
      full_lower: 'キタシタラグン　トヨネムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '564',
    name: '北設楽郡　富山村',
    kana_name: {
      half_upper: 'ｷﾀｼﾀﾗｸﾞﾝ ﾄﾐﾔﾏﾑﾗ',
      full_lower: 'キタシタラグン　トミヤマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-27',
    remark: { start: null, end: '23563北設楽郡　豊根村', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '565',
    name: '北設楽郡　津具村',
    kana_name: {
      half_upper: 'ｷﾀｼﾀﾗｸﾞﾝ ﾂｸﾞﾑﾗ',
      full_lower: 'キタシタラグン　ツグムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '23561北設楽郡　設楽町', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '566',
    name: '北設楽郡　稲武町',
    kana_name: {
      half_upper: 'ｷﾀｼﾀﾗｸﾞﾝ ｲﾅﾌﾞﾁﾖｳ',
      full_lower: 'キタシタラグン　イナブチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-10-01',
    remark: { start: null, end: '23545東加茂郡　稲武町', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '580',
    name: '南設楽郡',
    kana_name: { half_upper: 'ﾐﾅﾐｼﾀﾗｸﾞﾝ', full_lower: 'ミナミシタラグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '581',
    name: '南設楽郡　鳳来町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｼﾀﾗｸﾞﾝ ﾎｳﾗｲﾁﾖｳ',
      full_lower: 'ミナミシタラグン　ホウライチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '23221新城市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '582',
    name: '南設楽郡　作手村',
    kana_name: {
      half_upper: 'ﾐﾅﾐｼﾀﾗｸﾞﾝ ﾂｸﾃﾞﾑﾗ',
      full_lower: 'ミナミシタラグン　ツクデムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '23221新城市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '600',
    name: '宝飯郡',
    kana_name: { half_upper: 'ﾎｲｸﾞﾝ', full_lower: 'ホイグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-02-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '601',
    name: '宝飯郡　音羽町',
    kana_name: {
      half_upper: 'ﾎｲｸﾞﾝ ｵﾄﾜﾁﾖｳ',
      full_lower: 'ホイグン　オトワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2008-01-15',
    remark: { start: null, end: '23207豊川市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '602',
    name: '宝飯郡　一宮町',
    kana_name: {
      half_upper: 'ﾎｲｸﾞﾝ ｲﾁﾉﾐﾔﾁﾖｳ',
      full_lower: 'ホイグン　イチノミヤチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-01',
    remark: { start: null, end: '23207豊川市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '603',
    name: '宝飯郡　小坂井町',
    kana_name: {
      half_upper: 'ﾎｲｸﾞﾝ ｺｻﾞｶｲﾁﾖｳ',
      full_lower: 'ホイグン　コザカイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-02-01',
    remark: { start: null, end: '23207豊川市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '604',
    name: '宝飯郡　御津町',
    kana_name: {
      half_upper: 'ﾎｲｸﾞﾝ ﾐﾄﾁﾖｳ',
      full_lower: 'ホイグン　ミトチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2008-01-15',
    remark: { start: null, end: '23207豊川市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '620',
    name: '渥美郡',
    kana_name: { half_upper: 'ｱﾂﾐｸﾞﾝ', full_lower: 'アツミグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '621',
    name: '渥美郡　田原町',
    kana_name: {
      half_upper: 'ｱﾂﾐｸﾞﾝ ﾀﾊﾗﾁﾖｳ',
      full_lower: 'アツミグン　タハラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-08-20',
    remark: { start: null, end: '23231田原市', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '622',
    name: '渥美郡　赤羽根町',
    kana_name: {
      half_upper: 'ｱﾂﾐｸﾞﾝ ｱｶﾊﾞﾈﾁﾖｳ',
      full_lower: 'アツミグン　アカバネチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-08-20',
    remark: { start: null, end: '23621渥美郡　田原町', other: null },
    note: null,
  },
  {
    pref_code: '23',
    city_code: '623',
    name: '渥美郡　渥美町',
    kana_name: {
      half_upper: 'ｱﾂﾐｸﾞﾝ ｱﾂﾐﾁﾖｳ',
      full_lower: 'アツミグン　アツミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '23231田原市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '201',
    name: '津市',
    kana_name: { half_upper: 'ﾂｼ', full_lower: 'ツシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '202',
    name: '四日市市',
    kana_name: { half_upper: 'ﾖﾂｶｲﾁｼ', full_lower: 'ヨッカイチシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '203',
    name: '伊勢市',
    kana_name: { half_upper: 'ｲｾｼ', full_lower: 'イセシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '204',
    name: '松阪市',
    kana_name: { half_upper: 'ﾏﾂｻｶｼ', full_lower: 'マツサカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '205',
    name: '桑名市',
    kana_name: { half_upper: 'ｸﾜﾅｼ', full_lower: 'クワナシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '206',
    name: '上野市',
    kana_name: { half_upper: 'ｳｴﾉｼ', full_lower: 'ウエノシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '24216伊賀市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '207',
    name: '鈴鹿市',
    kana_name: { half_upper: 'ｽｽﾞｶｼ', full_lower: 'スズカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '208',
    name: '名張市',
    kana_name: { half_upper: 'ﾅﾊﾞﾘｼ', full_lower: 'ナバリシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '209',
    name: '尾鷲市',
    kana_name: { half_upper: 'ｵﾜｾｼ', full_lower: 'オワセシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '210',
    name: '亀山市',
    kana_name: { half_upper: 'ｶﾒﾔﾏｼ', full_lower: 'カメヤマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '211',
    name: '鳥羽市',
    kana_name: { half_upper: 'ﾄﾊﾞｼ', full_lower: 'トバシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '212',
    name: '熊野市',
    kana_name: { half_upper: 'ｸﾏﾉｼ', full_lower: 'クマノシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '213',
    name: '久居市',
    kana_name: { half_upper: 'ﾋｻｲｼ', full_lower: 'ヒサイシ' },
    en: null,
    start_date: '1970-08-01',
    end_date: '2006-01-01',
    remark: { start: 'S45/08/01市制', end: '24201津市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '214',
    name: 'いなべ市',
    kana_name: { half_upper: 'ｲﾅﾍﾞｼ', full_lower: 'イナベシ' },
    en: null,
    start_date: '2003-12-01',
    end_date: null,
    remark: { start: 'H15/12/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '215',
    name: '志摩市',
    kana_name: { half_upper: 'ｼﾏｼ', full_lower: 'シマシ' },
    en: null,
    start_date: '2004-10-01',
    end_date: null,
    remark: { start: 'H16/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '216',
    name: '伊賀市',
    kana_name: { half_upper: 'ｲｶﾞｼ', full_lower: 'イガシ' },
    en: null,
    start_date: '2004-11-01',
    end_date: null,
    remark: { start: 'H16/11/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '300',
    name: '桑名郡',
    kana_name: { half_upper: 'ｸﾜﾅｸﾞﾝ', full_lower: 'クワナグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '301',
    name: '桑名郡　多度町',
    kana_name: {
      half_upper: 'ｸﾜﾅｸﾞﾝ ﾀﾄﾞﾁﾖｳ',
      full_lower: 'クワナグン　タドチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-12-06',
    remark: { start: null, end: '24205桑名市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '302',
    name: '桑名郡　長島町',
    kana_name: {
      half_upper: 'ｸﾜﾅｸﾞﾝ ﾅｶﾞｼﾏﾁﾖｳ',
      full_lower: 'クワナグン　ナガシマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-12-06',
    remark: { start: null, end: '24205桑名市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '303',
    name: '桑名郡　木曽岬町',
    kana_name: {
      half_upper: 'ｸﾜﾅｸﾞﾝ ｷｿｻｷﾁﾖｳ',
      full_lower: 'クワナグン　キソサキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '320',
    name: '員弁郡',
    kana_name: { half_upper: 'ｲﾅﾍﾞｸﾞﾝ', full_lower: 'イナベグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '321',
    name: '員弁郡　北勢町',
    kana_name: {
      half_upper: 'ｲﾅﾍﾞｸﾞﾝ ﾎｸｾｲﾁﾖｳ',
      full_lower: 'イナベグン　ホクセイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-12-01',
    remark: { start: null, end: '24214いなべ市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '322',
    name: '員弁郡　員弁町',
    kana_name: {
      half_upper: 'ｲﾅﾍﾞｸﾞﾝ ｲﾅﾍﾞﾁﾖｳ',
      full_lower: 'イナベグン　イナベチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-12-01',
    remark: { start: null, end: '24214いなべ市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '323',
    name: '員弁郡　大安町',
    kana_name: {
      half_upper: 'ｲﾅﾍﾞｸﾞﾝ ﾀﾞｲｱﾝﾁﾖｳ',
      full_lower: 'イナベグン　ダイアンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-12-01',
    remark: { start: null, end: '24214いなべ市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '324',
    name: '員弁郡　東員町',
    kana_name: {
      half_upper: 'ｲﾅﾍﾞｸﾞﾝ ﾄｳｲﾝﾁﾖｳ',
      full_lower: 'イナベグン　トウインチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '325',
    name: '員弁郡　藤原町',
    kana_name: {
      half_upper: 'ｲﾅﾍﾞｸﾞﾝ ﾌｼﾞﾜﾗﾁﾖｳ',
      full_lower: 'イナベグン　フジワラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-12-01',
    remark: { start: null, end: '24214いなべ市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '340',
    name: '三重郡',
    kana_name: { half_upper: 'ﾐｴｸﾞﾝ', full_lower: 'ミエグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '341',
    name: '三重郡　菰野町',
    kana_name: {
      half_upper: 'ﾐｴｸﾞﾝ ｺﾓﾉﾁﾖｳ',
      full_lower: 'ミエグン　コモノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '342',
    name: '三重郡　楠町',
    kana_name: {
      half_upper: 'ﾐｴｸﾞﾝ ｸｽﾁﾖｳ',
      full_lower: 'ミエグン　クスチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-07',
    remark: { start: null, end: '24202四日市市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '343',
    name: '三重郡　朝日町',
    kana_name: {
      half_upper: 'ﾐｴｸﾞﾝ ｱｻﾋﾁﾖｳ',
      full_lower: 'ミエグン　アサヒチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '344',
    name: '三重郡　川越町',
    kana_name: {
      half_upper: 'ﾐｴｸﾞﾝ ｶﾜｺﾞｴﾁﾖｳ',
      full_lower: 'ミエグン　カワゴエチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '360',
    name: '鈴鹿郡',
    kana_name: { half_upper: 'ｽｽﾞｶｸﾞﾝ', full_lower: 'スズカグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-11',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '361',
    name: '鈴鹿郡　関町',
    kana_name: {
      half_upper: 'ｽｽﾞｶｸﾞﾝ ｾｷﾁﾖｳ',
      full_lower: 'スズカグン　セキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-11',
    remark: { start: null, end: '24210亀山市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '380',
    name: '安芸郡',
    kana_name: { half_upper: 'ｱｹﾞｸﾞﾝ', full_lower: 'アゲグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '381',
    name: '安芸郡　河芸町',
    kana_name: {
      half_upper: 'ｱｹﾞｸﾞﾝ ｶﾜｹﾞﾁﾖｳ',
      full_lower: 'アゲグン　カワゲチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '24201津市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '382',
    name: '安芸郡　芸濃町',
    kana_name: {
      half_upper: 'ｱｹﾞｸﾞﾝ ｹﾞｲﾉｳﾁﾖｳ',
      full_lower: 'アゲグン　ゲイノウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '24201津市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '383',
    name: '安芸郡　美里村',
    kana_name: {
      half_upper: 'ｱｹﾞｸﾞﾝ ﾐｻﾄﾑﾗ',
      full_lower: 'アゲグン　ミサトムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '24201津市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '384',
    name: '安芸郡　安濃町',
    kana_name: {
      half_upper: 'ｱｹﾞｸﾞﾝ ｱﾉｳﾁﾖｳ',
      full_lower: 'アゲグン　アノウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '24201津市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '385',
    name: '安芸郡　豊里村',
    kana_name: {
      half_upper: 'ｱｹﾞｸﾞﾝ ﾄﾖｻﾄﾑﾗ',
      full_lower: 'アゲグン　トヨサトムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1973-02-01',
    remark: { start: null, end: '24201津市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '400',
    name: '一志郡',
    kana_name: { half_upper: 'ｲﾁｼｸﾞﾝ', full_lower: 'イチシグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '401',
    name: '一志郡　久居町',
    kana_name: {
      half_upper: 'ｲﾁｼｸﾞﾝ ﾋｻｲﾁﾖｳ',
      full_lower: 'イチシグン　ヒサイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-08-01',
    remark: { start: null, end: '24213久居市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '402',
    name: '一志郡　香良洲町',
    kana_name: {
      half_upper: 'ｲﾁｼｸﾞﾝ ｶﾗｽﾁﾖｳ',
      full_lower: 'イチシグン　カラスチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '24201津市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '403',
    name: '一志郡　一志町',
    kana_name: {
      half_upper: 'ｲﾁｼｸﾞﾝ ｲﾁｼﾁﾖｳ',
      full_lower: 'イチシグン　イチシチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '24201津市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '404',
    name: '一志郡　白山町',
    kana_name: {
      half_upper: 'ｲﾁｼｸﾞﾝ ﾊｸｻﾝﾁﾖｳ',
      full_lower: 'イチシグン　ハクサンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '24201津市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '405',
    name: '一志郡　嬉野町',
    kana_name: {
      half_upper: 'ｲﾁｼｸﾞﾝ ｳﾚｼﾉﾁﾖｳ',
      full_lower: 'イチシグン　ウレシノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '24204松阪市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '406',
    name: '一志郡　美杉村',
    kana_name: {
      half_upper: 'ｲﾁｼｸﾞﾝ ﾐｽｷﾞﾑﾗ',
      full_lower: 'イチシグン　ミスギムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '24201津市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '407',
    name: '一志郡　三雲町',
    kana_name: {
      half_upper: 'ｲﾁｼｸﾞﾝ ﾐｸﾓﾁﾖｳ',
      full_lower: 'イチシグン　ミクモチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '24204松阪市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '420',
    name: '飯南郡',
    kana_name: { half_upper: 'ｲｲﾅﾝｸﾞﾝ', full_lower: 'イイナングン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '421',
    name: '飯南郡　飯南町',
    kana_name: {
      half_upper: 'ｲｲﾅﾝｸﾞﾝ ｲｲﾅﾝﾁﾖｳ',
      full_lower: 'イイナングン　イイナンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '24204松阪市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '422',
    name: '飯南郡　飯高町',
    kana_name: {
      half_upper: 'ｲｲﾅﾝｸﾞﾝ ｲｲﾀｶﾁﾖｳ',
      full_lower: 'イイナングン　イイタカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '24204松阪市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '440',
    name: '多気郡',
    kana_name: { half_upper: 'ﾀｷｸﾞﾝ', full_lower: 'タキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '441',
    name: '多気郡　多気町',
    kana_name: {
      half_upper: 'ﾀｷｸﾞﾝ ﾀｷﾁﾖｳ',
      full_lower: 'タキグン　タキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '442',
    name: '多気郡　明和町',
    kana_name: {
      half_upper: 'ﾀｷｸﾞﾝ ﾒｲﾜﾁﾖｳ',
      full_lower: 'タキグン　メイワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '443',
    name: '多気郡　大台町',
    kana_name: {
      half_upper: 'ﾀｷｸﾞﾝ ｵｵﾀﾞｲﾁﾖｳ',
      full_lower: 'タキグン　オオダイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '444',
    name: '多気郡　勢和村',
    kana_name: {
      half_upper: 'ﾀｷｸﾞﾝ ｾｲﾜﾑﾗ',
      full_lower: 'タキグン　セイワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '24441多気郡　多気町', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '445',
    name: '多気郡　宮川村',
    kana_name: {
      half_upper: 'ﾀｷｸﾞﾝ ﾐﾔｶﾞﾜﾑﾗ',
      full_lower: 'タキグン　ミヤガワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-10',
    remark: { start: null, end: '24443多気郡　大台町', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '460',
    name: '度会郡',
    kana_name: { half_upper: 'ﾜﾀﾗｲｸﾞﾝ', full_lower: 'ワタライグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '461',
    name: '度会郡　玉城町',
    kana_name: {
      half_upper: 'ﾜﾀﾗｲｸﾞﾝ ﾀﾏｷﾁﾖｳ',
      full_lower: 'ワタライグン　タマキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '462',
    name: '度会郡　二見町',
    kana_name: {
      half_upper: 'ﾜﾀﾗｲｸﾞﾝ ﾌﾀﾐﾁﾖｳ',
      full_lower: 'ワタライグン　フタミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-01',
    remark: { start: null, end: '24203伊勢市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '463',
    name: '度会郡　小俣町',
    kana_name: {
      half_upper: 'ﾜﾀﾗｲｸﾞﾝ ｵﾊﾞﾀﾁﾖｳ',
      full_lower: 'ワタライグン　オバタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-01',
    remark: { start: null, end: '24203伊勢市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '464',
    name: '度会郡　南勢町',
    kana_name: {
      half_upper: 'ﾜﾀﾗｲｸﾞﾝ ﾅﾝｾｲﾁﾖｳ',
      full_lower: 'ワタライグン　ナンセイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '24472度会郡　南伊勢町', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '465',
    name: '度会郡　南島町',
    kana_name: {
      half_upper: 'ﾜﾀﾗｲｸﾞﾝ ﾅﾝﾄｳﾁﾖｳ',
      full_lower: 'ワタライグン　ナントウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '24472度会郡　南伊勢町', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '466',
    name: '度会郡　大宮町',
    kana_name: {
      half_upper: 'ﾜﾀﾗｲｸﾞﾝ ｵｵﾐﾔﾁﾖｳ',
      full_lower: 'ワタライグン　オオミヤチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-14',
    remark: { start: null, end: '24471度会郡　大紀町', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '467',
    name: '度会郡　紀勢町',
    kana_name: {
      half_upper: 'ﾜﾀﾗｲｸﾞﾝ ｷｾｲﾁﾖｳ',
      full_lower: 'ワタライグン　キセイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-14',
    remark: { start: null, end: '24471度会郡　大紀町', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '468',
    name: '度会郡　御薗村',
    kana_name: {
      half_upper: 'ﾜﾀﾗｲｸﾞﾝ ﾐｿﾉﾑﾗ',
      full_lower: 'ワタライグン　ミソノムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-01',
    remark: { start: null, end: '24203伊勢市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '469',
    name: '度会郡　大内山村',
    kana_name: {
      half_upper: 'ﾜﾀﾗｲｸﾞﾝ ｵｵｳﾁﾔﾏﾑﾗ',
      full_lower: 'ワタライグン　オオウチヤマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-14',
    remark: { start: null, end: '24471度会郡　大紀町', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '470',
    name: '度会郡　度会町',
    kana_name: {
      half_upper: 'ﾜﾀﾗｲｸﾞﾝ ﾜﾀﾗｲﾁﾖｳ',
      full_lower: 'ワタライグン　ワタライチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '471',
    name: '度会郡　大紀町',
    kana_name: {
      half_upper: 'ﾜﾀﾗｲｸﾞﾝ ﾀｲｷﾁﾖｳ',
      full_lower: 'ワタライグン　タイキチョウ',
    },
    en: null,
    start_date: '2005-02-14',
    end_date: null,
    remark: { start: 'H17/02/14新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '472',
    name: '度会郡　南伊勢町',
    kana_name: {
      half_upper: 'ﾜﾀﾗｲｸﾞﾝ ﾐﾅﾐｲｾﾁﾖｳ',
      full_lower: 'ワタライグン　ミナミイセチョウ',
    },
    en: null,
    start_date: '2005-10-01',
    end_date: null,
    remark: { start: 'H17/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '480',
    name: '阿山郡',
    kana_name: { half_upper: 'ｱﾔﾏｸﾞﾝ', full_lower: 'アヤマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '481',
    name: '阿山郡　伊賀町',
    kana_name: {
      half_upper: 'ｱﾔﾏｸﾞﾝ ｲｶﾞﾁﾖｳ',
      full_lower: 'アヤマグン　イガチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '24216伊賀市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '482',
    name: '阿山郡　島ケ原村',
    kana_name: {
      half_upper: 'ｱﾔﾏｸﾞﾝ ｼﾏｶﾞﾊﾗﾑﾗ',
      full_lower: 'アヤマグン　シマガハラムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '24216伊賀市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '483',
    name: '阿山郡　阿山町',
    kana_name: {
      half_upper: 'ｱﾔﾏｸﾞﾝ ｱﾔﾏﾁﾖｳ',
      full_lower: 'アヤマグン　アヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '24216伊賀市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '484',
    name: '阿山郡　大山田村',
    kana_name: {
      half_upper: 'ｱﾔﾏｸﾞﾝ ｵｵﾔﾏﾀﾞﾑﾗ',
      full_lower: 'アヤマグン　オオヤマダムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '24216伊賀市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '500',
    name: '名賀郡',
    kana_name: { half_upper: 'ﾅｶﾞｸﾞﾝ', full_lower: 'ナガグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '501',
    name: '名賀郡　青山町',
    kana_name: {
      half_upper: 'ﾅｶﾞｸﾞﾝ ｱｵﾔﾏﾁﾖｳ',
      full_lower: 'ナガグン　アオヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '24216伊賀市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '520',
    name: '志摩郡',
    kana_name: { half_upper: 'ｼﾏｸﾞﾝ', full_lower: 'シマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '521',
    name: '志摩郡　浜島町',
    kana_name: {
      half_upper: 'ｼﾏｸﾞﾝ ﾊﾏｼﾞﾏﾁﾖｳ',
      full_lower: 'シマグン　ハマジマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '24215志摩市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '522',
    name: '志摩郡　大王町',
    kana_name: {
      half_upper: 'ｼﾏｸﾞﾝ ﾀﾞｲｵｳﾁﾖｳ',
      full_lower: 'シマグン　ダイオウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '24215志摩市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '523',
    name: '志摩郡　志摩町',
    kana_name: {
      half_upper: 'ｼﾏｸﾞﾝ ｼﾏﾁﾖｳ',
      full_lower: 'シマグン　シマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '24215志摩市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '524',
    name: '志摩郡　阿児町',
    kana_name: {
      half_upper: 'ｼﾏｸﾞﾝ ｱｺﾞﾁﾖｳ',
      full_lower: 'シマグン　アゴチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '24215志摩市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '525',
    name: '志摩郡　磯部町',
    kana_name: {
      half_upper: 'ｼﾏｸﾞﾝ ｲｿﾍﾞﾁﾖｳ',
      full_lower: 'シマグン　イソベチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '24215志摩市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '540',
    name: '北牟婁郡',
    kana_name: { half_upper: 'ｷﾀﾑﾛｸﾞﾝ', full_lower: 'キタムログン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '541',
    name: '北牟婁郡　紀伊長島町',
    kana_name: {
      half_upper: 'ｷﾀﾑﾛｸﾞﾝ ｷｲﾅｶﾞｼﾏﾁﾖｳ',
      full_lower: 'キタムログン　キイナガシマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-11',
    remark: { start: null, end: '24543北牟婁郡　紀北町', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '542',
    name: '北牟婁郡　海山町',
    kana_name: {
      half_upper: 'ｷﾀﾑﾛｸﾞﾝ ﾐﾔﾏﾁﾖｳ',
      full_lower: 'キタムログン　ミヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-11',
    remark: { start: null, end: '24543北牟婁郡　紀北町', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '543',
    name: '北牟婁郡　紀北町',
    kana_name: {
      half_upper: 'ｷﾀﾑﾛｸﾞﾝ ｷﾎｸﾁﾖｳ',
      full_lower: 'キタムログン　キホクチョウ',
    },
    en: null,
    start_date: '2005-10-11',
    end_date: null,
    remark: { start: 'H17/10/11新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '560',
    name: '南牟婁郡',
    kana_name: { half_upper: 'ﾐﾅﾐﾑﾛｸﾞﾝ', full_lower: 'ミナミムログン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '561',
    name: '南牟婁郡　御浜町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾑﾛｸﾞﾝ ﾐﾊﾏﾁﾖｳ',
      full_lower: 'ミナミムログン　ミハマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '562',
    name: '南牟婁郡　紀宝町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾑﾛｸﾞﾝ ｷﾎｳﾁﾖｳ',
      full_lower: 'ミナミムログン　キホウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '563',
    name: '南牟婁郡　紀和町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾑﾛｸﾞﾝ ｷﾜﾁﾖｳ',
      full_lower: 'ミナミムログン　キワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-01',
    remark: { start: null, end: '24212熊野市', other: null },
    note: null,
  },
  {
    pref_code: '24',
    city_code: '564',
    name: '南牟婁郡　鵜殿村',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾑﾛｸﾞﾝ ｳﾄﾞﾉﾑﾗ',
      full_lower: 'ミナミムログン　ウドノムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-10',
    remark: { start: null, end: '24562南牟婁郡　紀宝町', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '201',
    name: '大津市',
    kana_name: { half_upper: 'ｵｵﾂｼ', full_lower: 'オオツシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '202',
    name: '彦根市',
    kana_name: { half_upper: 'ﾋｺﾈｼ', full_lower: 'ヒコネシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '203',
    name: '長浜市',
    kana_name: { half_upper: 'ﾅｶﾞﾊﾏｼ', full_lower: 'ナガハマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '204',
    name: '近江八幡市',
    kana_name: { half_upper: 'ｵｳﾐﾊﾁﾏﾝｼ', full_lower: 'オウミハチマンシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '205',
    name: '八日市市',
    kana_name: { half_upper: 'ﾖｳｶｲﾁｼ', full_lower: 'ヨウカイチシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-11',
    remark: { start: null, end: '25213東近江市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '206',
    name: '草津市',
    kana_name: { half_upper: 'ｸｻﾂｼ', full_lower: 'クサツシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '207',
    name: '守山市',
    kana_name: { half_upper: 'ﾓﾘﾔﾏｼ', full_lower: 'モリヤマシ' },
    en: null,
    start_date: '1970-07-01',
    end_date: null,
    remark: { start: 'S45/07/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '208',
    name: '栗東市',
    kana_name: { half_upper: 'ﾘﾂﾄｳｼ', full_lower: 'リットウシ' },
    en: null,
    start_date: '2001-10-01',
    end_date: null,
    remark: { start: 'H13/10/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '209',
    name: '甲賀市',
    kana_name: { half_upper: 'ｺｳｶｼ', full_lower: 'コウカシ' },
    en: null,
    start_date: '2004-10-01',
    end_date: null,
    remark: { start: 'H16/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '210',
    name: '野洲市',
    kana_name: { half_upper: 'ﾔｽｼ', full_lower: 'ヤスシ' },
    en: null,
    start_date: '2004-10-01',
    end_date: null,
    remark: { start: 'H16/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '211',
    name: '湖南市',
    kana_name: { half_upper: 'ｺﾅﾝｼ', full_lower: 'コナンシ' },
    en: null,
    start_date: '2004-10-01',
    end_date: null,
    remark: { start: 'H16/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '212',
    name: '高島市',
    kana_name: { half_upper: 'ﾀｶｼﾏｼ', full_lower: 'タカシマシ' },
    en: null,
    start_date: '2005-01-01',
    end_date: null,
    remark: { start: 'H17/01/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '213',
    name: '東近江市',
    kana_name: { half_upper: 'ﾋｶﾞｼｵｳﾐｼ', full_lower: 'ヒガシオウミシ' },
    en: null,
    start_date: '2005-02-11',
    end_date: null,
    remark: { start: 'H17/02/11新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '214',
    name: '米原市',
    kana_name: { half_upper: 'ﾏｲﾊﾞﾗｼ', full_lower: 'マイバラシ' },
    en: null,
    start_date: '2005-02-14',
    end_date: null,
    remark: { start: 'H17/02/14新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '300',
    name: '滋賀郡',
    kana_name: { half_upper: 'ｼｶﾞｸﾞﾝ', full_lower: 'シガグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '301',
    name: '滋賀郡　志賀町',
    kana_name: {
      half_upper: 'ｼｶﾞｸﾞﾝ ｼｶﾞﾁﾖｳ',
      full_lower: 'シガグン　シガチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '25201大津市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '320',
    name: '栗太郡',
    kana_name: { half_upper: 'ｸﾘﾀｸﾞﾝ', full_lower: 'クリタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2001-10-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '321',
    name: '栗太郡　栗東町',
    kana_name: {
      half_upper: 'ｸﾘﾀｸﾞﾝ ﾘﾂﾄｳﾁﾖｳ',
      full_lower: 'クリタグン　リットウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2001-10-01',
    remark: { start: null, end: '25208栗東市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '340',
    name: '野洲郡',
    kana_name: { half_upper: 'ﾔｽｸﾞﾝ', full_lower: 'ヤスグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '341',
    name: '野洲郡　守山町',
    kana_name: {
      half_upper: 'ﾔｽｸﾞﾝ ﾓﾘﾔﾏﾁﾖｳ',
      full_lower: 'ヤスグン　モリヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-07-01',
    remark: { start: null, end: '25207守山市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '342',
    name: '野洲郡　中主町',
    kana_name: {
      half_upper: 'ﾔｽｸﾞﾝ ﾁﾕｳｽﾞﾁﾖｳ',
      full_lower: 'ヤスグン　チュウズチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '25210野洲市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '343',
    name: '野洲郡　野洲町',
    kana_name: {
      half_upper: 'ﾔｽｸﾞﾝ ﾔｽﾁﾖｳ',
      full_lower: 'ヤスグン　ヤスチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '25210野洲市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '360',
    name: '甲賀郡',
    kana_name: { half_upper: 'ｺｳｶｸﾞﾝ', full_lower: 'コウカグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '361',
    name: '甲賀郡　石部町',
    kana_name: {
      half_upper: 'ｺｳｶｸﾞﾝ ｲｼﾍﾞﾁﾖｳ',
      full_lower: 'コウカグン　イシベチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '25211湖南市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '362',
    name: '甲賀郡　甲西町',
    kana_name: {
      half_upper: 'ｺｳｶｸﾞﾝ ｺｳｾｲﾁﾖｳ',
      full_lower: 'コウカグン　コウセイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '25211湖南市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '363',
    name: '甲賀郡　水口町',
    kana_name: {
      half_upper: 'ｺｳｶｸﾞﾝ ﾐﾅｸﾁﾁﾖｳ',
      full_lower: 'コウカグン　ミナクチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '25209甲賀市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '364',
    name: '甲賀郡　土山町',
    kana_name: {
      half_upper: 'ｺｳｶｸﾞﾝ ﾂﾁﾔﾏﾁﾖｳ',
      full_lower: 'コウカグン　ツチヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '25209甲賀市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '365',
    name: '甲賀郡　甲賀町',
    kana_name: {
      half_upper: 'ｺｳｶｸﾞﾝ ｺｳｶﾁﾖｳ',
      full_lower: 'コウカグン　コウカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '25209甲賀市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '366',
    name: '甲賀郡　甲南町',
    kana_name: {
      half_upper: 'ｺｳｶｸﾞﾝ ｺｳﾅﾝﾁﾖｳ',
      full_lower: 'コウカグン　コウナンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '25209甲賀市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '367',
    name: '甲賀郡　信楽町',
    kana_name: {
      half_upper: 'ｺｳｶｸﾞﾝ ｼｶﾞﾗｷﾁﾖｳ',
      full_lower: 'コウカグン　シガラキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '25209甲賀市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '380',
    name: '蒲生郡',
    kana_name: { half_upper: 'ｶﾞﾓｳｸﾞﾝ', full_lower: 'ガモウグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '381',
    name: '蒲生郡　安土町',
    kana_name: {
      half_upper: 'ｶﾞﾓｳｸﾞﾝ ｱﾂﾞﾁﾁﾖｳ',
      full_lower: 'ガモウグン　アヅチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-21',
    remark: { start: null, end: '25204近江八幡市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '382',
    name: '蒲生郡　蒲生町',
    kana_name: {
      half_upper: 'ｶﾞﾓｳｸﾞﾝ ｶﾞﾓｳﾁﾖｳ',
      full_lower: 'ガモウグン　ガモウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '25213東近江市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '383',
    name: '蒲生郡　日野町',
    kana_name: {
      half_upper: 'ｶﾞﾓｳｸﾞﾝ ﾋﾉﾁﾖｳ',
      full_lower: 'ガモウグン　ヒノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '384',
    name: '蒲生郡　竜王町',
    kana_name: {
      half_upper: 'ｶﾞﾓｳｸﾞﾝ ﾘﾕｳｵｳﾁﾖｳ',
      full_lower: 'ガモウグン　リュウオウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '400',
    name: '神崎郡',
    kana_name: { half_upper: 'ｶﾝｻﾞｷｸﾞﾝ', full_lower: 'カンザキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '401',
    name: '神崎郡　永源寺町',
    kana_name: {
      half_upper: 'ｶﾝｻﾞｷｸﾞﾝ ｴｲｹﾞﾝｼﾞﾁﾖｳ',
      full_lower: 'カンザキグン　エイゲンジチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-11',
    remark: { start: null, end: '25213東近江市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '402',
    name: '神崎郡　五個荘町',
    kana_name: {
      half_upper: 'ｶﾝｻﾞｷｸﾞﾝ ｺﾞｶｼﾖｳﾁﾖｳ',
      full_lower: 'カンザキグン　ゴカショウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-11',
    remark: { start: null, end: '25213東近江市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '403',
    name: '神崎郡　能登川町',
    kana_name: {
      half_upper: 'ｶﾝｻﾞｷｸﾞﾝ ﾉﾄｶﾞﾜﾁﾖｳ',
      full_lower: 'カンザキグン　ノトガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '25213東近江市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '420',
    name: '愛知郡',
    kana_name: { half_upper: 'ｴﾁｸﾞﾝ', full_lower: 'エチグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '421',
    name: '愛知郡　愛東町',
    kana_name: {
      half_upper: 'ｴﾁｸﾞﾝ ｱｲﾄｳﾁﾖｳ',
      full_lower: 'エチグン　アイトウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-11',
    remark: { start: null, end: '25213東近江市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '422',
    name: '愛知郡　湖東町',
    kana_name: {
      half_upper: 'ｴﾁｸﾞﾝ ｺﾄｳﾁﾖｳ',
      full_lower: 'エチグン　コトウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-11',
    remark: { start: null, end: '25213東近江市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '423',
    name: '愛知郡　秦荘町',
    kana_name: {
      half_upper: 'ｴﾁｸﾞﾝ ﾊﾀｼﾖｳﾁﾖｳ',
      full_lower: 'エチグン　ハタショウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-13',
    remark: { start: null, end: '25425愛知郡　愛荘町', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '424',
    name: '愛知郡　愛知川町',
    kana_name: {
      half_upper: 'ｴﾁｸﾞﾝ ｴﾁｶﾞﾜﾁﾖｳ',
      full_lower: 'エチグン　エチガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-13',
    remark: { start: null, end: '25425愛知郡　愛荘町', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '425',
    name: '愛知郡　愛荘町',
    kana_name: {
      half_upper: 'ｴﾁｸﾞﾝ ｱｲｼﾖｳﾁﾖｳ',
      full_lower: 'エチグン　アイショウチョウ',
    },
    en: null,
    start_date: '2006-02-13',
    end_date: null,
    remark: { start: 'H18/02/13新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '440',
    name: '犬上郡',
    kana_name: { half_upper: 'ｲﾇｶﾐｸﾞﾝ', full_lower: 'イヌカミグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '441',
    name: '犬上郡　豊郷町',
    kana_name: {
      half_upper: 'ｲﾇｶﾐｸﾞﾝ ﾄﾖｻﾄﾁﾖｳ',
      full_lower: 'イヌカミグン　トヨサトチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '442',
    name: '犬上郡　甲良町',
    kana_name: {
      half_upper: 'ｲﾇｶﾐｸﾞﾝ ｺｳﾗﾁﾖｳ',
      full_lower: 'イヌカミグン　コウラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '443',
    name: '犬上郡　多賀町',
    kana_name: {
      half_upper: 'ｲﾇｶﾐｸﾞﾝ ﾀｶﾞﾁﾖｳ',
      full_lower: 'イヌカミグン　タガチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '460',
    name: '坂田郡',
    kana_name: { half_upper: 'ｻｶﾀｸﾞﾝ', full_lower: 'サカタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '461',
    name: '坂田郡　山東町',
    kana_name: {
      half_upper: 'ｻｶﾀｸﾞﾝ ｻﾝﾄｳﾁﾖｳ',
      full_lower: 'サカタグン　サントウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-14',
    remark: { start: null, end: '25214米原市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '462',
    name: '坂田郡　伊吹町',
    kana_name: {
      half_upper: 'ｻｶﾀｸﾞﾝ ｲﾌﾞｷﾁﾖｳ',
      full_lower: 'サカタグン　イブキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-14',
    remark: { start: null, end: '25214米原市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '463',
    name: '坂田郡　米原町',
    kana_name: {
      half_upper: 'ｻｶﾀｸﾞﾝ ﾏｲﾊﾗﾁﾖｳ',
      full_lower: 'サカタグン　マイハラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-14',
    remark: { start: null, end: '25214米原市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '464',
    name: '坂田郡　近江町',
    kana_name: {
      half_upper: 'ｻｶﾀｸﾞﾝ ｵｳﾐﾁﾖｳ',
      full_lower: 'サカタグン　オウミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '25214米原市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '480',
    name: '東浅井郡',
    kana_name: { half_upper: 'ﾋｶﾞｼｱｻﾞｲｸﾞﾝ', full_lower: 'ヒガシアザイグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-01-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '481',
    name: '東浅井郡　浅井町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｱｻﾞｲｸﾞﾝ ｱｻﾞｲﾁﾖｳ',
      full_lower: 'ヒガシアザイグン　アザイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-13',
    remark: { start: null, end: '25203長浜市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '482',
    name: '東浅井郡　虎姫町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｱｻﾞｲｸﾞﾝ ﾄﾗﾋﾒﾁﾖｳ',
      full_lower: 'ヒガシアザイグン　トラヒメチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-01-01',
    remark: { start: null, end: '25203長浜市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '483',
    name: '東浅井郡　湖北町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｱｻﾞｲｸﾞﾝ ｺﾎｸﾁﾖｳ',
      full_lower: 'ヒガシアザイグン　コホクチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-01-01',
    remark: { start: null, end: '25203長浜市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '484',
    name: '東浅井郡　びわ町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｱｻﾞｲｸﾞﾝ ﾋﾞﾜﾁﾖｳ',
      full_lower: 'ヒガシアザイグン　ビワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-13',
    remark: { start: null, end: '25203長浜市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '500',
    name: '伊香郡',
    kana_name: { half_upper: 'ｲｶｸﾞﾝ', full_lower: 'イカグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-01-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '501',
    name: '伊香郡　高月町',
    kana_name: {
      half_upper: 'ｲｶｸﾞﾝ ﾀｶﾂｷﾁﾖｳ',
      full_lower: 'イカグン　タカツキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-01-01',
    remark: { start: null, end: '25203長浜市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '502',
    name: '伊香郡　木之本町',
    kana_name: {
      half_upper: 'ｲｶｸﾞﾝ ｷﾉﾓﾄﾁﾖｳ',
      full_lower: 'イカグン　キノモトチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-01-01',
    remark: { start: null, end: '25203長浜市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '503',
    name: '伊香郡　余呉町',
    kana_name: {
      half_upper: 'ｲｶｸﾞﾝ ﾖｺﾞﾁﾖｳ',
      full_lower: 'イカグン　ヨゴチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-01-01',
    remark: { start: null, end: '25203長浜市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '504',
    name: '伊香郡　西浅井町',
    kana_name: {
      half_upper: 'ｲｶｸﾞﾝ ﾆｼｱｻﾞｲﾁﾖｳ',
      full_lower: 'イカグン　ニシアザイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-01-01',
    remark: { start: null, end: '25203長浜市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '520',
    name: '高島郡',
    kana_name: { half_upper: 'ﾀｶｼﾏｸﾞﾝ', full_lower: 'タカシマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '521',
    name: '高島郡　マキノ町',
    kana_name: {
      half_upper: 'ﾀｶｼﾏｸﾞﾝ ﾏｷﾉﾁﾖｳ',
      full_lower: 'タカシマグン　マキノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '25212高島市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '522',
    name: '高島郡　今津町',
    kana_name: {
      half_upper: 'ﾀｶｼﾏｸﾞﾝ ｲﾏﾂﾞﾁﾖｳ',
      full_lower: 'タカシマグン　イマヅチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '25212高島市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '523',
    name: '高島郡　朽木村',
    kana_name: {
      half_upper: 'ﾀｶｼﾏｸﾞﾝ ｸﾂｷﾑﾗ',
      full_lower: 'タカシマグン　クツキムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '25212高島市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '524',
    name: '高島郡　安曇川町',
    kana_name: {
      half_upper: 'ﾀｶｼﾏｸﾞﾝ ｱﾄﾞｶﾞﾜﾁﾖｳ',
      full_lower: 'タカシマグン　アドガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '25212高島市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '525',
    name: '高島郡　高島町',
    kana_name: {
      half_upper: 'ﾀｶｼﾏｸﾞﾝ ﾀｶｼﾏﾁﾖｳ',
      full_lower: 'タカシマグン　タカシマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '25212高島市', other: null },
    note: null,
  },
  {
    pref_code: '25',
    city_code: '526',
    name: '高島郡　新旭町',
    kana_name: {
      half_upper: 'ﾀｶｼﾏｸﾞﾝ ｼﾝｱｻﾋﾁﾖｳ',
      full_lower: 'タカシマグン　シンアサヒチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '25212高島市', other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '100',
    name: '京都市',
    kana_name: { half_upper: 'ｷﾖｳﾄｼ', full_lower: 'キョウトシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '101',
    name: '京都市　北区',
    kana_name: { half_upper: 'ｷﾖｳﾄｼ ｷﾀｸ', full_lower: 'キョウトシ　キタク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '102',
    name: '京都市　上京区',
    kana_name: {
      half_upper: 'ｷﾖｳﾄｼ ｶﾐｷﾞﾖｳｸ',
      full_lower: 'キョウトシ　カミギョウク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '103',
    name: '京都市　左京区',
    kana_name: {
      half_upper: 'ｷﾖｳﾄｼ ｻｷﾖｳｸ',
      full_lower: 'キョウトシ　サキョウク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '104',
    name: '京都市　中京区',
    kana_name: {
      half_upper: 'ｷﾖｳﾄｼ ﾅｶｷﾞﾖｳｸ',
      full_lower: 'キョウトシ　ナカギョウク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '105',
    name: '京都市　東山区',
    kana_name: {
      half_upper: 'ｷﾖｳﾄｼ ﾋｶﾞｼﾔﾏｸ',
      full_lower: 'キョウトシ　ヒガシヤマク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '106',
    name: '京都市　下京区',
    kana_name: {
      half_upper: 'ｷﾖｳﾄｼ ｼﾓｷﾞﾖｳｸ',
      full_lower: 'キョウトシ　シモギョウク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '107',
    name: '京都市　南区',
    kana_name: {
      half_upper: 'ｷﾖｳﾄｼ ﾐﾅﾐｸ',
      full_lower: 'キョウトシ　ミナミク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '108',
    name: '京都市　右京区',
    kana_name: {
      half_upper: 'ｷﾖｳﾄｼ ｳｷﾖｳｸ',
      full_lower: 'キョウトシ　ウキョウク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '109',
    name: '京都市　伏見区',
    kana_name: {
      half_upper: 'ｷﾖｳﾄｼ ﾌｼﾐｸ',
      full_lower: 'キョウトシ　フシミク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '110',
    name: '京都市　山科区',
    kana_name: {
      half_upper: 'ｷﾖｳﾄｼ ﾔﾏｼﾅｸ',
      full_lower: 'キョウトシ　ヤマシナク',
    },
    en: null,
    start_date: '1976-10-01',
    end_date: null,
    remark: {
      start: 'S51/10/01東山区(26105)から分離',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '111',
    name: '京都市　西京区',
    kana_name: {
      half_upper: 'ｷﾖｳﾄｼ ﾆｼｷﾖｳｸ',
      full_lower: 'キョウトシ　ニシキョウク',
    },
    en: null,
    start_date: '1976-10-01',
    end_date: null,
    remark: {
      start: 'S51/10/01右京区(26108)から分離',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '201',
    name: '福知山市',
    kana_name: { half_upper: 'ﾌｸﾁﾔﾏｼ', full_lower: 'フクチヤマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '202',
    name: '舞鶴市',
    kana_name: { half_upper: 'ﾏｲﾂﾞﾙｼ', full_lower: 'マイヅルシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '203',
    name: '綾部市',
    kana_name: { half_upper: 'ｱﾔﾍﾞｼ', full_lower: 'アヤベシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '204',
    name: '宇治市',
    kana_name: { half_upper: 'ｳｼﾞｼ', full_lower: 'ウジシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '205',
    name: '宮津市',
    kana_name: { half_upper: 'ﾐﾔﾂﾞｼ', full_lower: 'ミヤヅシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '206',
    name: '亀岡市',
    kana_name: { half_upper: 'ｶﾒｵｶｼ', full_lower: 'カメオカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '207',
    name: '城陽市',
    kana_name: { half_upper: 'ｼﾞﾖｳﾖｳｼ', full_lower: 'ジョウヨウシ' },
    en: null,
    start_date: '1972-05-03',
    end_date: null,
    remark: { start: 'S47/05/03市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '208',
    name: '向日市',
    kana_name: { half_upper: 'ﾑｺｳｼ', full_lower: 'ムコウシ' },
    en: null,
    start_date: '1972-10-01',
    end_date: null,
    remark: { start: 'S47/10/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '209',
    name: '長岡京市',
    kana_name: { half_upper: 'ﾅｶﾞｵｶｷﾖｳｼ', full_lower: 'ナガオカキョウシ' },
    en: null,
    start_date: '1972-10-01',
    end_date: null,
    remark: { start: 'S47/10/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '210',
    name: '八幡市',
    kana_name: { half_upper: 'ﾔﾜﾀｼ', full_lower: 'ヤワタシ' },
    en: null,
    start_date: '1977-11-01',
    end_date: null,
    remark: { start: 'S52/11/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '211',
    name: '京田辺市',
    kana_name: { half_upper: 'ｷﾖｳﾀﾅﾍﾞｼ', full_lower: 'キョウタナベシ' },
    en: null,
    start_date: '1997-04-01',
    end_date: null,
    remark: { start: 'H09/04/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '212',
    name: '京丹後市',
    kana_name: { half_upper: 'ｷﾖｳﾀﾝｺﾞｼ', full_lower: 'キョウタンゴシ' },
    en: null,
    start_date: '2004-04-01',
    end_date: null,
    remark: { start: 'H16/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '213',
    name: '南丹市',
    kana_name: { half_upper: 'ﾅﾝﾀﾝｼ', full_lower: 'ナンタンシ' },
    en: null,
    start_date: '2006-01-01',
    end_date: null,
    remark: { start: 'H18/01/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '214',
    name: '木津川市',
    kana_name: { half_upper: 'ｷﾂﾞｶﾞﾜｼ', full_lower: 'キヅガワシ' },
    en: null,
    start_date: '2007-03-12',
    end_date: null,
    remark: { start: 'H19/03/12新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '300',
    name: '乙訓郡',
    kana_name: { half_upper: 'ｵﾄｸﾆｸﾞﾝ', full_lower: 'オトクニグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '301',
    name: '乙訓郡　向日町',
    kana_name: {
      half_upper: 'ｵﾄｸﾆｸﾞﾝ ﾑｺｳﾏﾁ',
      full_lower: 'オトクニグン　ムコウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-10-01',
    remark: { start: null, end: '26208向日市', other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '302',
    name: '乙訓郡　長岡町',
    kana_name: {
      half_upper: 'ｵﾄｸﾆｸﾞﾝ ﾅｶﾞｵｶﾁﾖｳ',
      full_lower: 'オトクニグン　ナガオカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-10-01',
    remark: { start: null, end: '26209長岡京市', other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '303',
    name: '乙訓郡　大山崎町',
    kana_name: {
      half_upper: 'ｵﾄｸﾆｸﾞﾝ ｵｵﾔﾏｻﾞｷﾁﾖｳ',
      full_lower: 'オトクニグン　オオヤマザキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '320',
    name: '久世郡',
    kana_name: { half_upper: 'ｸｾｸﾞﾝ', full_lower: 'クセグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '321',
    name: '久世郡　城陽町',
    kana_name: {
      half_upper: 'ｸｾｸﾞﾝ ｼﾞﾖｳﾖｳﾁﾖｳ',
      full_lower: 'クセグン　ジョウヨウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-05-03',
    remark: { start: null, end: '26207城陽市', other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '322',
    name: '久世郡　久御山町',
    kana_name: {
      half_upper: 'ｸｾｸﾞﾝ ｸﾐﾔﾏﾁﾖｳ',
      full_lower: 'クセグン　クミヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '340',
    name: '綴喜郡',
    kana_name: { half_upper: 'ﾂﾂﾞｷｸﾞﾝ', full_lower: 'ツヅキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '341',
    name: '綴喜郡　八幡町',
    kana_name: {
      half_upper: 'ﾂﾂﾞｷｸﾞﾝ ﾔﾜﾀﾁﾖｳ',
      full_lower: 'ツヅキグン　ヤワタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1977-11-01',
    remark: { start: null, end: '26210八幡市', other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '342',
    name: '綴喜郡　田辺町',
    kana_name: {
      half_upper: 'ﾂﾂﾞｷｸﾞﾝ ﾀﾅﾍﾞﾁﾖｳ',
      full_lower: 'ツヅキグン　タナベチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1997-04-01',
    remark: { start: null, end: '26211京田辺市', other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '343',
    name: '綴喜郡　井手町',
    kana_name: {
      half_upper: 'ﾂﾂﾞｷｸﾞﾝ ｲﾃﾞﾁﾖｳ',
      full_lower: 'ツヅキグン　イデチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '344',
    name: '綴喜郡　宇治田原町',
    kana_name: {
      half_upper: 'ﾂﾂﾞｷｸﾞﾝ ｳｼﾞﾀﾜﾗﾁﾖｳ',
      full_lower: 'ツヅキグン　ウジタワラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '360',
    name: '相楽郡',
    kana_name: { half_upper: 'ｿｳﾗｸｸﾞﾝ', full_lower: 'ソウラクグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '361',
    name: '相楽郡　山城町',
    kana_name: {
      half_upper: 'ｿｳﾗｸｸﾞﾝ ﾔﾏｼﾛﾁﾖｳ',
      full_lower: 'ソウラクグン　ヤマシロチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-03-12',
    remark: { start: null, end: '26214木津川市', other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '362',
    name: '相楽郡　木津町',
    kana_name: {
      half_upper: 'ｿｳﾗｸｸﾞﾝ ｷﾂﾞﾁﾖｳ',
      full_lower: 'ソウラクグン　キヅチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-03-12',
    remark: { start: null, end: '26214木津川市', other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '363',
    name: '相楽郡　加茂町',
    kana_name: {
      half_upper: 'ｿｳﾗｸｸﾞﾝ ｶﾓﾁﾖｳ',
      full_lower: 'ソウラクグン　カモチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-03-12',
    remark: { start: null, end: '26214木津川市', other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '364',
    name: '相楽郡　笠置町',
    kana_name: {
      half_upper: 'ｿｳﾗｸｸﾞﾝ ｶｻｷﾞﾁﾖｳ',
      full_lower: 'ソウラクグン　カサギチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '365',
    name: '相楽郡　和束町',
    kana_name: {
      half_upper: 'ｿｳﾗｸｸﾞﾝ ﾜﾂﾞｶﾁﾖｳ',
      full_lower: 'ソウラクグン　ワヅカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '366',
    name: '相楽郡　精華町',
    kana_name: {
      half_upper: 'ｿｳﾗｸｸﾞﾝ ｾｲｶﾁﾖｳ',
      full_lower: 'ソウラクグン　セイカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '367',
    name: '相楽郡　南山城村',
    kana_name: {
      half_upper: 'ｿｳﾗｸｸﾞﾝ ﾐﾅﾐﾔﾏｼﾛﾑﾗ',
      full_lower: 'ソウラクグン　ミナミヤマシロムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '380',
    name: '北桑田郡',
    kana_name: { half_upper: 'ｷﾀｸﾜﾀﾞｸﾞﾝ', full_lower: 'キタクワダグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '381',
    name: '北桑田郡　京北町',
    kana_name: {
      half_upper: 'ｷﾀｸﾜﾀﾞｸﾞﾝ ｹｲﾎｸﾁﾖｳ',
      full_lower: 'キタクワダグン　ケイホクチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '26108京都市　右京区', other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '382',
    name: '北桑田郡　美山町',
    kana_name: {
      half_upper: 'ｷﾀｸﾜﾀﾞｸﾞﾝ ﾐﾔﾏﾁﾖｳ',
      full_lower: 'キタクワダグン　ミヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '26213南丹市', other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '400',
    name: '船井郡',
    kana_name: { half_upper: 'ﾌﾅｲｸﾞﾝ', full_lower: 'フナイグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '401',
    name: '船井郡　園部町',
    kana_name: {
      half_upper: 'ﾌﾅｲｸﾞﾝ ｿﾉﾍﾞﾁﾖｳ',
      full_lower: 'フナイグン　ソノベチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '26213南丹市', other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '402',
    name: '船井郡　八木町',
    kana_name: {
      half_upper: 'ﾌﾅｲｸﾞﾝ ﾔｷﾞﾁﾖｳ',
      full_lower: 'フナイグン　ヤギチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '26213南丹市', other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '403',
    name: '船井郡　丹波町',
    kana_name: {
      half_upper: 'ﾌﾅｲｸﾞﾝ ﾀﾝﾊﾞﾁﾖｳ',
      full_lower: 'フナイグン　タンバチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-11',
    remark: { start: null, end: '26407船井郡　京丹波町', other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '404',
    name: '船井郡　日吉町',
    kana_name: {
      half_upper: 'ﾌﾅｲｸﾞﾝ ﾋﾖｼﾁﾖｳ',
      full_lower: 'フナイグン　ヒヨシチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '26213南丹市', other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '405',
    name: '船井郡　瑞穂町',
    kana_name: {
      half_upper: 'ﾌﾅｲｸﾞﾝ ﾐｽﾞﾎﾁﾖｳ',
      full_lower: 'フナイグン　ミズホチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-11',
    remark: { start: null, end: '26407船井郡　京丹波町', other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '406',
    name: '船井郡　和知町',
    kana_name: {
      half_upper: 'ﾌﾅｲｸﾞﾝ ﾜﾁﾁﾖｳ',
      full_lower: 'フナイグン　ワチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-11',
    remark: { start: null, end: '26407船井郡　京丹波町', other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '407',
    name: '船井郡　京丹波町',
    kana_name: {
      half_upper: 'ﾌﾅｲｸﾞﾝ ｷﾖｳﾀﾝﾊﾞﾁﾖｳ',
      full_lower: 'フナイグン　キョウタンバチョウ',
    },
    en: null,
    start_date: '2005-10-11',
    end_date: null,
    remark: { start: 'H17/10/11新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '420',
    name: '天田郡',
    kana_name: { half_upper: 'ｱﾏﾀｸﾞﾝ', full_lower: 'アマタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '421',
    name: '天田郡　三和町',
    kana_name: {
      half_upper: 'ｱﾏﾀｸﾞﾝ ﾐﾜﾁﾖｳ',
      full_lower: 'アマタグン　ミワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '26201福知山市', other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '422',
    name: '天田郡　夜久野町',
    kana_name: {
      half_upper: 'ｱﾏﾀｸﾞﾝ ﾔｸﾉﾁﾖｳ',
      full_lower: 'アマタグン　ヤクノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '26201福知山市', other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '440',
    name: '加佐郡',
    kana_name: { half_upper: 'ｶｻｸﾞﾝ', full_lower: 'カサグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '441',
    name: '加佐郡　大江町',
    kana_name: {
      half_upper: 'ｶｻｸﾞﾝ ｵｵｴﾁﾖｳ',
      full_lower: 'カサグン　オオエチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '26201福知山市', other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '460',
    name: '与謝郡',
    kana_name: { half_upper: 'ﾖｻｸﾞﾝ', full_lower: 'ヨサグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '461',
    name: '与謝郡　加悦町',
    kana_name: {
      half_upper: 'ﾖｻｸﾞﾝ ｶﾔﾁﾖｳ',
      full_lower: 'ヨサグン　カヤチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '26465与謝郡　与謝野町', other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '462',
    name: '与謝郡　岩滝町',
    kana_name: {
      half_upper: 'ﾖｻｸﾞﾝ ｲﾜﾀｷﾁﾖｳ',
      full_lower: 'ヨサグン　イワタキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '26465与謝郡　与謝野町', other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '463',
    name: '与謝郡　伊根町',
    kana_name: {
      half_upper: 'ﾖｻｸﾞﾝ ｲﾈﾁﾖｳ',
      full_lower: 'ヨサグン　イネチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '464',
    name: '与謝郡　野田川町',
    kana_name: {
      half_upper: 'ﾖｻｸﾞﾝ ﾉﾀﾞｶﾞﾜﾁﾖｳ',
      full_lower: 'ヨサグン　ノダガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '26465与謝郡　与謝野町', other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '465',
    name: '与謝郡　与謝野町',
    kana_name: {
      half_upper: 'ﾖｻｸﾞﾝ ﾖｻﾉﾁﾖｳ',
      full_lower: 'ヨサグン　ヨサノチョウ',
    },
    en: null,
    start_date: '2006-03-01',
    end_date: null,
    remark: { start: 'H18/03/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '480',
    name: '中郡',
    kana_name: { half_upper: 'ﾅｶｸﾞﾝ', full_lower: 'ナカグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '481',
    name: '中郡　峰山町',
    kana_name: {
      half_upper: 'ﾅｶｸﾞﾝ ﾐﾈﾔﾏﾁﾖｳ',
      full_lower: 'ナカグン　ミネヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '26212京丹後市', other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '482',
    name: '中郡　大宮町',
    kana_name: {
      half_upper: 'ﾅｶｸﾞﾝ ｵｵﾐﾔﾁﾖｳ',
      full_lower: 'ナカグン　オオミヤチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '26212京丹後市', other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '500',
    name: '竹野郡',
    kana_name: { half_upper: 'ﾀｹﾉｸﾞﾝ', full_lower: 'タケノグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '501',
    name: '竹野郡　網野町',
    kana_name: {
      half_upper: 'ﾀｹﾉｸﾞﾝ ｱﾐﾉﾁﾖｳ',
      full_lower: 'タケノグン　アミノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '26212京丹後市', other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '502',
    name: '竹野郡　丹後町',
    kana_name: {
      half_upper: 'ﾀｹﾉｸﾞﾝ ﾀﾝｺﾞﾁﾖｳ',
      full_lower: 'タケノグン　タンゴチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '26212京丹後市', other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '503',
    name: '竹野郡　弥栄町',
    kana_name: {
      half_upper: 'ﾀｹﾉｸﾞﾝ ﾔｻｶﾁﾖｳ',
      full_lower: 'タケノグン　ヤサカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '26212京丹後市', other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '520',
    name: '熊野郡',
    kana_name: { half_upper: 'ｸﾏﾉｸﾞﾝ', full_lower: 'クマノグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '26',
    city_code: '521',
    name: '熊野郡　久美浜町',
    kana_name: {
      half_upper: 'ｸﾏﾉｸﾞﾝ ｸﾐﾊﾏﾁﾖｳ',
      full_lower: 'クマノグン　クミハマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '26212京丹後市', other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '100',
    name: '大阪市',
    kana_name: { half_upper: 'ｵｵｻｶｼ', full_lower: 'オオサカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '101',
    name: '大阪市　北区',
    kana_name: { half_upper: 'ｵｵｻｶｼ ｷﾀｸ', full_lower: 'オオサカシ　キタク' },
    en: null,
    start_date: '0001-01-01',
    end_date: '1989-02-13',
    remark: { start: null, end: '27127大阪市　北区', other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '102',
    name: '大阪市　都島区',
    kana_name: {
      half_upper: 'ｵｵｻｶｼ ﾐﾔｺｼﾞﾏｸ',
      full_lower: 'オオサカシ　ミヤコジマク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '103',
    name: '大阪市　福島区',
    kana_name: {
      half_upper: 'ｵｵｻｶｼ ﾌｸｼﾏｸ',
      full_lower: 'オオサカシ　フクシマク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '104',
    name: '大阪市　此花区',
    kana_name: {
      half_upper: 'ｵｵｻｶｼ ｺﾉﾊﾅｸ',
      full_lower: 'オオサカシ　コノハナク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '105',
    name: '大阪市　東区',
    kana_name: {
      half_upper: 'ｵｵｻｶｼ ﾋｶﾞｼｸ',
      full_lower: 'オオサカシ　ヒガシク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1989-02-13',
    remark: { start: null, end: '27128大阪市　中央区', other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '106',
    name: '大阪市　西区',
    kana_name: { half_upper: 'ｵｵｻｶｼ ﾆｼｸ', full_lower: 'オオサカシ　ニシク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '107',
    name: '大阪市　港区',
    kana_name: {
      half_upper: 'ｵｵｻｶｼ ﾐﾅﾄｸ',
      full_lower: 'オオサカシ　ミナトク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '108',
    name: '大阪市　大正区',
    kana_name: {
      half_upper: 'ｵｵｻｶｼ ﾀｲｼﾖｳｸ',
      full_lower: 'オオサカシ　タイショウク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '109',
    name: '大阪市　天王寺区',
    kana_name: {
      half_upper: 'ｵｵｻｶｼ ﾃﾝﾉｳｼﾞｸ',
      full_lower: 'オオサカシ　テンノウジク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '110',
    name: '大阪市　南区',
    kana_name: {
      half_upper: 'ｵｵｻｶｼ ﾐﾅﾐｸ',
      full_lower: 'オオサカシ　ミナミク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1989-02-13',
    remark: { start: null, end: '27128大阪市　中央区', other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '111',
    name: '大阪市　浪速区',
    kana_name: {
      half_upper: 'ｵｵｻｶｼ ﾅﾆﾜｸ',
      full_lower: 'オオサカシ　ナニワク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '112',
    name: '大阪市　大淀区',
    kana_name: {
      half_upper: 'ｵｵｻｶｼ ｵｵﾖﾄﾞｸ',
      full_lower: 'オオサカシ　オオヨドク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1989-02-13',
    remark: { start: null, end: '27127大阪市　北区', other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '113',
    name: '大阪市　西淀川区',
    kana_name: {
      half_upper: 'ｵｵｻｶｼ ﾆｼﾖﾄﾞｶﾞﾜｸ',
      full_lower: 'オオサカシ　ニシヨドガワク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '114',
    name: '大阪市　東淀川区',
    kana_name: {
      half_upper: 'ｵｵｻｶｼ ﾋｶﾞｼﾖﾄﾞｶﾞﾜｸ',
      full_lower: 'オオサカシ　ヒガシヨドガワク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '115',
    name: '大阪市　東成区',
    kana_name: {
      half_upper: 'ｵｵｻｶｼ ﾋｶﾞｼﾅﾘｸ',
      full_lower: 'オオサカシ　ヒガシナリク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '116',
    name: '大阪市　生野区',
    kana_name: {
      half_upper: 'ｵｵｻｶｼ ｲｸﾉｸ',
      full_lower: 'オオサカシ　イクノク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '117',
    name: '大阪市　旭区',
    kana_name: {
      half_upper: 'ｵｵｻｶｼ ｱｻﾋｸ',
      full_lower: 'オオサカシ　アサヒク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '118',
    name: '大阪市　城東区',
    kana_name: {
      half_upper: 'ｵｵｻｶｼ ｼﾞﾖｳﾄｳｸ',
      full_lower: 'オオサカシ　ジョウトウク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '119',
    name: '大阪市　阿倍野区',
    kana_name: {
      half_upper: 'ｵｵｻｶｼ ｱﾍﾞﾉｸ',
      full_lower: 'オオサカシ　アベノク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '120',
    name: '大阪市　住吉区',
    kana_name: {
      half_upper: 'ｵｵｻｶｼ ｽﾐﾖｼｸ',
      full_lower: 'オオサカシ　スミヨシク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '121',
    name: '大阪市　東住吉区',
    kana_name: {
      half_upper: 'ｵｵｻｶｼ ﾋｶﾞｼｽﾐﾖｼｸ',
      full_lower: 'オオサカシ　ヒガシスミヨシク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '122',
    name: '大阪市　西成区',
    kana_name: {
      half_upper: 'ｵｵｻｶｼ ﾆｼﾅﾘｸ',
      full_lower: 'オオサカシ　ニシナリク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '123',
    name: '大阪市　淀川区',
    kana_name: {
      half_upper: 'ｵｵｻｶｼ ﾖﾄﾞｶﾞﾜｸ',
      full_lower: 'オオサカシ　ヨドガワク',
    },
    en: null,
    start_date: '1974-07-22',
    end_date: null,
    remark: {
      start: 'S49/07/22東淀川区(27114)から分離',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '124',
    name: '大阪市　鶴見区',
    kana_name: {
      half_upper: 'ｵｵｻｶｼ ﾂﾙﾐｸ',
      full_lower: 'オオサカシ　ツルミク',
    },
    en: null,
    start_date: '1974-07-22',
    end_date: null,
    remark: {
      start: 'S49/07/22城東区(27118)から分離',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '125',
    name: '大阪市　住之江区',
    kana_name: {
      half_upper: 'ｵｵｻｶｼ ｽﾐﾉｴｸ',
      full_lower: 'オオサカシ　スミノエク',
    },
    en: null,
    start_date: '1974-07-22',
    end_date: null,
    remark: {
      start: 'S49/07/22住吉区(27120)から分離',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '126',
    name: '大阪市　平野区',
    kana_name: {
      half_upper: 'ｵｵｻｶｼ ﾋﾗﾉｸ',
      full_lower: 'オオサカシ　ヒラノク',
    },
    en: null,
    start_date: '1974-07-22',
    end_date: null,
    remark: {
      start: 'S49/07/22東住吉区(27121)から分離',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '127',
    name: '大阪市　北区',
    kana_name: { half_upper: 'ｵｵｻｶｼ ｷﾀｸ', full_lower: 'オオサカシ　キタク' },
    en: null,
    start_date: '1989-02-13',
    end_date: null,
    remark: {
      start: 'H01/02/13北区(27101)と大淀区(27112)が統合',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '128',
    name: '大阪市　中央区',
    kana_name: {
      half_upper: 'ｵｵｻｶｼ ﾁﾕｳｵｳｸ',
      full_lower: 'オオサカシ　チュウオウク',
    },
    en: null,
    start_date: '1989-02-13',
    end_date: null,
    remark: {
      start: 'H01/02/13東区(27105)と南区(27110)が統合',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '140',
    name: '堺市',
    kana_name: { half_upper: 'ｻｶｲｼ', full_lower: 'サカイシ' },
    en: null,
    start_date: '2006-04-01',
    end_date: null,
    remark: { start: 'H18/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '141',
    name: '堺市　堺区',
    kana_name: { half_upper: 'ｻｶｲｼ ｻｶｲｸ', full_lower: 'サカイシ　サカイク' },
    en: null,
    start_date: '2006-04-01',
    end_date: null,
    remark: { start: 'H18/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '142',
    name: '堺市　中区',
    kana_name: { half_upper: 'ｻｶｲｼ ﾅｶｸ', full_lower: 'サカイシ　ナカク' },
    en: null,
    start_date: '2006-04-01',
    end_date: null,
    remark: { start: 'H18/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '143',
    name: '堺市　東区',
    kana_name: { half_upper: 'ｻｶｲｼ ﾋｶﾞｼｸ', full_lower: 'サカイシ　ヒガシク' },
    en: null,
    start_date: '2006-04-01',
    end_date: null,
    remark: { start: 'H18/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '144',
    name: '堺市　西区',
    kana_name: { half_upper: 'ｻｶｲｼ ﾆｼｸ', full_lower: 'サカイシ　ニシク' },
    en: null,
    start_date: '2006-04-01',
    end_date: null,
    remark: { start: 'H18/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '145',
    name: '堺市　南区',
    kana_name: { half_upper: 'ｻｶｲｼ ﾐﾅﾐｸ', full_lower: 'サカイシ　ミナミク' },
    en: null,
    start_date: '2006-04-01',
    end_date: null,
    remark: { start: 'H18/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '146',
    name: '堺市　北区',
    kana_name: { half_upper: 'ｻｶｲｼ ｷﾀｸ', full_lower: 'サカイシ　キタク' },
    en: null,
    start_date: '2006-04-01',
    end_date: null,
    remark: { start: 'H18/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '147',
    name: '堺市　美原区',
    kana_name: { half_upper: 'ｻｶｲｼ ﾐﾊﾗｸ', full_lower: 'サカイシ　ミハラク' },
    en: null,
    start_date: '2006-04-01',
    end_date: null,
    remark: { start: 'H18/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '201',
    name: '堺市',
    kana_name: { half_upper: 'ｻｶｲｼ', full_lower: 'サカイシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-04-01',
    remark: { start: null, end: '27140堺市', other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '202',
    name: '岸和田市',
    kana_name: { half_upper: 'ｷｼﾜﾀﾞｼ', full_lower: 'キシワダシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '203',
    name: '豊中市',
    kana_name: { half_upper: 'ﾄﾖﾅｶｼ', full_lower: 'トヨナカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '204',
    name: '池田市',
    kana_name: { half_upper: 'ｲｹﾀﾞｼ', full_lower: 'イケダシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '205',
    name: '吹田市',
    kana_name: { half_upper: 'ｽｲﾀｼ', full_lower: 'スイタシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '206',
    name: '泉大津市',
    kana_name: { half_upper: 'ｲｽﾞﾐｵｵﾂｼ', full_lower: 'イズミオオツシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '207',
    name: '高槻市',
    kana_name: { half_upper: 'ﾀｶﾂｷｼ', full_lower: 'タカツキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '208',
    name: '貝塚市',
    kana_name: { half_upper: 'ｶｲﾂﾞｶｼ', full_lower: 'カイヅカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '209',
    name: '守口市',
    kana_name: { half_upper: 'ﾓﾘｸﾞﾁｼ', full_lower: 'モリグチシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '210',
    name: '枚方市',
    kana_name: { half_upper: 'ﾋﾗｶﾀｼ', full_lower: 'ヒラカタシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '211',
    name: '茨木市',
    kana_name: { half_upper: 'ｲﾊﾞﾗｷｼ', full_lower: 'イバラキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '212',
    name: '八尾市',
    kana_name: { half_upper: 'ﾔｵｼ', full_lower: 'ヤオシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '213',
    name: '泉佐野市',
    kana_name: { half_upper: 'ｲｽﾞﾐｻﾉｼ', full_lower: 'イズミサノシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '214',
    name: '富田林市',
    kana_name: { half_upper: 'ﾄﾝﾀﾞﾊﾞﾔｼｼ', full_lower: 'トンダバヤシシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '215',
    name: '寝屋川市',
    kana_name: { half_upper: 'ﾈﾔｶﾞﾜｼ', full_lower: 'ネヤガワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '216',
    name: '河内長野市',
    kana_name: { half_upper: 'ｶﾜﾁﾅｶﾞﾉｼ', full_lower: 'カワチナガノシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '217',
    name: '松原市',
    kana_name: { half_upper: 'ﾏﾂﾊﾞﾗｼ', full_lower: 'マツバラシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '218',
    name: '大東市',
    kana_name: { half_upper: 'ﾀﾞｲﾄｳｼ', full_lower: 'ダイトウシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '219',
    name: '和泉市',
    kana_name: { half_upper: 'ｲｽﾞﾐｼ', full_lower: 'イズミシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '220',
    name: '箕面市',
    kana_name: { half_upper: 'ﾐﾉｵｼ', full_lower: 'ミノオシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '221',
    name: '柏原市',
    kana_name: { half_upper: 'ｶｼﾜﾗｼ', full_lower: 'カシワラシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '222',
    name: '羽曳野市',
    kana_name: { half_upper: 'ﾊﾋﾞｷﾉｼ', full_lower: 'ハビキノシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '223',
    name: '門真市',
    kana_name: { half_upper: 'ｶﾄﾞﾏｼ', full_lower: 'カドマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '224',
    name: '摂津市',
    kana_name: { half_upper: 'ｾﾂﾂｼ', full_lower: 'セッツシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '225',
    name: '高石市',
    kana_name: { half_upper: 'ﾀｶｲｼｼ', full_lower: 'タカイシシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '226',
    name: '藤井寺市',
    kana_name: { half_upper: 'ﾌｼﾞｲﾃﾞﾗｼ', full_lower: 'フジイデラシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '227',
    name: '東大阪市',
    kana_name: { half_upper: 'ﾋｶﾞｼｵｵｻｶｼ', full_lower: 'ヒガシオオサカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '228',
    name: '泉南市',
    kana_name: { half_upper: 'ｾﾝﾅﾝｼ', full_lower: 'センナンシ' },
    en: null,
    start_date: '1970-07-01',
    end_date: null,
    remark: { start: 'S45/07/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '229',
    name: '四條畷市',
    kana_name: { half_upper: 'ｼｼﾞﾖｳﾅﾜﾃｼ', full_lower: 'シジョウナワテシ' },
    en: null,
    start_date: '1970-07-01',
    end_date: null,
    remark: { start: 'S45/07/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '230',
    name: '交野市',
    kana_name: { half_upper: 'ｶﾀﾉｼ', full_lower: 'カタノシ' },
    en: null,
    start_date: '1971-11-03',
    end_date: null,
    remark: { start: 'S46/11/03市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '231',
    name: '大阪狭山市',
    kana_name: { half_upper: 'ｵｵｻｶｻﾔﾏｼ', full_lower: 'オオサカサヤマシ' },
    en: null,
    start_date: '1987-10-01',
    end_date: null,
    remark: { start: 'S62/10/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '232',
    name: '阪南市',
    kana_name: { half_upper: 'ﾊﾝﾅﾝｼ', full_lower: 'ハンナンシ' },
    en: null,
    start_date: '1991-10-01',
    end_date: null,
    remark: { start: 'H03/10/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '300',
    name: '三島郡',
    kana_name: { half_upper: 'ﾐｼﾏｸﾞﾝ', full_lower: 'ミシマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '301',
    name: '三島郡　島本町',
    kana_name: {
      half_upper: 'ﾐｼﾏｸﾞﾝ ｼﾏﾓﾄﾁﾖｳ',
      full_lower: 'ミシマグン　シマモトチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '320',
    name: '豊能郡',
    kana_name: { half_upper: 'ﾄﾖﾉｸﾞﾝ', full_lower: 'トヨノグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '321',
    name: '豊能郡　豊能町',
    kana_name: {
      half_upper: 'ﾄﾖﾉｸﾞﾝ ﾄﾖﾉﾁﾖｳ',
      full_lower: 'トヨノグン　トヨノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '322',
    name: '豊能郡　能勢町',
    kana_name: {
      half_upper: 'ﾄﾖﾉｸﾞﾝ ﾉｾﾁﾖｳ',
      full_lower: 'トヨノグン　ノセチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '340',
    name: '泉北郡',
    kana_name: { half_upper: 'ｾﾝﾎﾞｸｸﾞﾝ', full_lower: 'センボクグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '341',
    name: '泉北郡　忠岡町',
    kana_name: {
      half_upper: 'ｾﾝﾎﾞｸｸﾞﾝ ﾀﾀﾞｵｶﾁﾖｳ',
      full_lower: 'センボクグン　タダオカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '360',
    name: '泉南郡',
    kana_name: { half_upper: 'ｾﾝﾅﾝｸﾞﾝ', full_lower: 'センナングン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '361',
    name: '泉南郡　熊取町',
    kana_name: {
      half_upper: 'ｾﾝﾅﾝｸﾞﾝ ｸﾏﾄﾘﾁﾖｳ',
      full_lower: 'センナングン　クマトリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '362',
    name: '泉南郡　田尻町',
    kana_name: {
      half_upper: 'ｾﾝﾅﾝｸﾞﾝ ﾀｼﾞﾘﾁﾖｳ',
      full_lower: 'センナングン　タジリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '363',
    name: '泉南郡　泉南町',
    kana_name: {
      half_upper: 'ｾﾝﾅﾝｸﾞﾝ ｾﾝﾅﾝﾁﾖｳ',
      full_lower: 'センナングン　センナンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-07-01',
    remark: { start: null, end: '27228泉南市', other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '364',
    name: '泉南郡　南海町',
    kana_name: {
      half_upper: 'ｾﾝﾅﾝｸﾞﾝ ﾅﾝｶｲﾁﾖｳ',
      full_lower: 'センナングン　ナンカイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-10-20',
    remark: { start: null, end: '27367泉南郡　阪南町', other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '365',
    name: '泉南郡　東鳥取町',
    kana_name: {
      half_upper: 'ｾﾝﾅﾝｸﾞﾝ ﾋｶﾞｼﾄﾂﾄﾘﾁﾖｳ',
      full_lower: 'センナングン　ヒガシトットリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-10-20',
    remark: { start: null, end: '27367泉南郡　阪南町', other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '366',
    name: '泉南郡　岬町',
    kana_name: {
      half_upper: 'ｾﾝﾅﾝｸﾞﾝ ﾐｻｷﾁﾖｳ',
      full_lower: 'センナングン　ミサキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '367',
    name: '泉南郡　阪南町',
    kana_name: {
      half_upper: 'ｾﾝﾅﾝｸﾞﾝ ﾊﾝﾅﾝﾁﾖｳ',
      full_lower: 'センナングン　ハンナンチョウ',
    },
    en: null,
    start_date: '1972-10-20',
    end_date: '1991-10-01',
    remark: { start: 'S47/10/20新設', end: '27232阪南市', other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '380',
    name: '南河内郡',
    kana_name: { half_upper: 'ﾐﾅﾐｶﾜﾁｸﾞﾝ', full_lower: 'ミナミカワチグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '381',
    name: '南河内郡　太子町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｶﾜﾁｸﾞﾝ ﾀｲｼﾁﾖｳ',
      full_lower: 'ミナミカワチグン　タイシチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '382',
    name: '南河内郡　河南町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｶﾜﾁｸﾞﾝ ｶﾅﾝﾁﾖｳ',
      full_lower: 'ミナミカワチグン　カナンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '383',
    name: '南河内郡　千早赤阪村',
    kana_name: {
      half_upper: 'ﾐﾅﾐｶﾜﾁｸﾞﾝ ﾁﾊﾔｱｶｻｶﾑﾗ',
      full_lower: 'ミナミカワチグン　チハヤアカサカムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '384',
    name: '南河内郡　狭山町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｶﾜﾁｸﾞﾝ ｻﾔﾏﾁﾖｳ',
      full_lower: 'ミナミカワチグン　サヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1987-10-01',
    remark: { start: null, end: '27231大阪狭山市', other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '385',
    name: '南河内郡　美原町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｶﾜﾁｸﾞﾝ ﾐﾊﾗﾁﾖｳ',
      full_lower: 'ミナミカワチグン　ミハラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '27201堺市', other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '400',
    name: '北河内郡',
    kana_name: { half_upper: 'ｷﾀｶﾜﾁｸﾞﾝ', full_lower: 'キタカワチグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-11-03',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '401',
    name: '北河内郡　交野町',
    kana_name: {
      half_upper: 'ｷﾀｶﾜﾁｸﾞﾝ ｶﾀﾉﾁﾖｳ',
      full_lower: 'キタカワチグン　カタノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-11-03',
    remark: { start: null, end: '27230交野市', other: null },
    note: null,
  },
  {
    pref_code: '27',
    city_code: '402',
    name: '北河内郡　四條畷町',
    kana_name: {
      half_upper: 'ｷﾀｶﾜﾁｸﾞﾝ ｼｼﾞﾖｳﾅﾜﾃﾁﾖｳ',
      full_lower: 'キタカワチグン　シジョウナワテチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-07-01',
    remark: { start: null, end: '27229四條畷市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '100',
    name: '神戸市',
    kana_name: { half_upper: 'ｺｳﾍﾞｼ', full_lower: 'コウベシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '101',
    name: '神戸市　東灘区',
    kana_name: {
      half_upper: 'ｺｳﾍﾞｼ ﾋｶﾞｼﾅﾀﾞｸ',
      full_lower: 'コウベシ　ヒガシナダク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '102',
    name: '神戸市　灘区',
    kana_name: { half_upper: 'ｺｳﾍﾞｼ ﾅﾀﾞｸ', full_lower: 'コウベシ　ナダク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '103',
    name: '神戸市　葺合区',
    kana_name: {
      half_upper: 'ｺｳﾍﾞｼ ﾌｷｱｲｸ',
      full_lower: 'コウベシ　フキアイク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1980-12-01',
    remark: { start: null, end: '28110神戸市　中央区', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '104',
    name: '神戸市　生田区',
    kana_name: { half_upper: 'ｺｳﾍﾞｼ ｲｸﾀｸ', full_lower: 'コウベシ　イクタク' },
    en: null,
    start_date: '0001-01-01',
    end_date: '1980-12-01',
    remark: { start: null, end: '28110神戸市　中央区', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '105',
    name: '神戸市　兵庫区',
    kana_name: {
      half_upper: 'ｺｳﾍﾞｼ ﾋﾖｳｺﾞｸ',
      full_lower: 'コウベシ　ヒョウゴク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '106',
    name: '神戸市　長田区',
    kana_name: {
      half_upper: 'ｺｳﾍﾞｼ ﾅｶﾞﾀｸ',
      full_lower: 'コウベシ　ナガタク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '107',
    name: '神戸市　須磨区',
    kana_name: { half_upper: 'ｺｳﾍﾞｼ ｽﾏｸ', full_lower: 'コウベシ　スマク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '108',
    name: '神戸市　垂水区',
    kana_name: { half_upper: 'ｺｳﾍﾞｼ ﾀﾙﾐｸ', full_lower: 'コウベシ　タルミク' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '109',
    name: '神戸市　北区',
    kana_name: { half_upper: 'ｺｳﾍﾞｼ ｷﾀｸ', full_lower: 'コウベシ　キタク' },
    en: null,
    start_date: '1973-08-01',
    end_date: null,
    remark: {
      start: 'S48/08/01兵庫区(28105)から分離',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '110',
    name: '神戸市　中央区',
    kana_name: {
      half_upper: 'ｺｳﾍﾞｼ ﾁﾕｳｵｳｸ',
      full_lower: 'コウベシ　チュウオウク',
    },
    en: null,
    start_date: '1980-12-01',
    end_date: null,
    remark: {
      start: 'S55/12/01葺合区(28103)と生田区(28104)が合併',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '111',
    name: '神戸市　西区',
    kana_name: { half_upper: 'ｺｳﾍﾞｼ ﾆｼｸ', full_lower: 'コウベシ　ニシク' },
    en: null,
    start_date: '1982-08-01',
    end_date: null,
    remark: {
      start: 'S57/08/01垂水区(28108)から分離',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '201',
    name: '姫路市',
    kana_name: { half_upper: 'ﾋﾒｼﾞｼ', full_lower: 'ヒメジシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '202',
    name: '尼崎市',
    kana_name: { half_upper: 'ｱﾏｶﾞｻｷｼ', full_lower: 'アマガサキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '203',
    name: '明石市',
    kana_name: { half_upper: 'ｱｶｼｼ', full_lower: 'アカシシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '204',
    name: '西宮市',
    kana_name: { half_upper: 'ﾆｼﾉﾐﾔｼ', full_lower: 'ニシノミヤシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '205',
    name: '洲本市',
    kana_name: { half_upper: 'ｽﾓﾄｼ', full_lower: 'スモトシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '206',
    name: '芦屋市',
    kana_name: { half_upper: 'ｱｼﾔｼ', full_lower: 'アシヤシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '207',
    name: '伊丹市',
    kana_name: { half_upper: 'ｲﾀﾐｼ', full_lower: 'イタミシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '208',
    name: '相生市',
    kana_name: { half_upper: 'ｱｲｵｲｼ', full_lower: 'アイオイシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '209',
    name: '豊岡市',
    kana_name: { half_upper: 'ﾄﾖｵｶｼ', full_lower: 'トヨオカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '210',
    name: '加古川市',
    kana_name: { half_upper: 'ｶｺｶﾞﾜｼ', full_lower: 'カコガワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '211',
    name: '龍野市',
    kana_name: { half_upper: 'ﾀﾂﾉｼ', full_lower: 'タツノシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '28229たつの市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '212',
    name: '赤穂市',
    kana_name: { half_upper: 'ｱｺｳｼ', full_lower: 'アコウシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '213',
    name: '西脇市',
    kana_name: { half_upper: 'ﾆｼﾜｷｼ', full_lower: 'ニシワキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '214',
    name: '宝塚市',
    kana_name: { half_upper: 'ﾀｶﾗﾂﾞｶｼ', full_lower: 'タカラヅカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '215',
    name: '三木市',
    kana_name: { half_upper: 'ﾐｷｼ', full_lower: 'ミキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '216',
    name: '高砂市',
    kana_name: { half_upper: 'ﾀｶｻｺﾞｼ', full_lower: 'タカサゴシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '217',
    name: '川西市',
    kana_name: { half_upper: 'ｶﾜﾆｼｼ', full_lower: 'カワニシシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '218',
    name: '小野市',
    kana_name: { half_upper: 'ｵﾉｼ', full_lower: 'オノシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '219',
    name: '三田市',
    kana_name: { half_upper: 'ｻﾝﾀﾞｼ', full_lower: 'サンダシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '220',
    name: '加西市',
    kana_name: { half_upper: 'ｶｻｲｼ', full_lower: 'カサイシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '221',
    name: '篠山市',
    kana_name: { half_upper: 'ｻｻﾔﾏｼ', full_lower: 'ササヤマシ' },
    en: null,
    start_date: '1999-04-01',
    end_date: '2019-05-01',
    remark: { start: 'H11/04/01新設', end: '28221丹波篠山市', other: null },
    note: 'キー重複',
  },
  {
    pref_code: '28',
    city_code: '221',
    name: '丹波篠山市',
    kana_name: { half_upper: 'ﾀﾝﾊﾞｻｻﾔﾏｼ', full_lower: 'タンバササヤマシ' },
    en: null,
    start_date: '2019-05-01',
    end_date: null,
    remark: {
      start: 'R01/05/01名称変更：旧「篠山市」',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '222',
    name: '養父市',
    kana_name: { half_upper: 'ﾔﾌﾞｼ', full_lower: 'ヤブシ' },
    en: null,
    start_date: '2004-04-01',
    end_date: null,
    remark: { start: 'H16/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '223',
    name: '丹波市',
    kana_name: { half_upper: 'ﾀﾝﾊﾞｼ', full_lower: 'タンバシ' },
    en: null,
    start_date: '2004-11-01',
    end_date: null,
    remark: { start: 'H16/11/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '224',
    name: '南あわじ市',
    kana_name: { half_upper: 'ﾐﾅﾐｱﾜｼﾞｼ', full_lower: 'ミナミアワジシ' },
    en: null,
    start_date: '2005-01-11',
    end_date: null,
    remark: { start: 'H17/01/11新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '225',
    name: '朝来市',
    kana_name: { half_upper: 'ｱｻｺﾞｼ', full_lower: 'アサゴシ' },
    en: null,
    start_date: '2005-04-01',
    end_date: null,
    remark: { start: 'H17/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '226',
    name: '淡路市',
    kana_name: { half_upper: 'ｱﾜｼﾞｼ', full_lower: 'アワジシ' },
    en: null,
    start_date: '2005-04-01',
    end_date: null,
    remark: { start: 'H17/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '227',
    name: '宍粟市',
    kana_name: { half_upper: 'ｼｿｳｼ', full_lower: 'シソウシ' },
    en: null,
    start_date: '2005-04-01',
    end_date: null,
    remark: { start: 'H17/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '228',
    name: '加東市',
    kana_name: { half_upper: 'ｶﾄｳｼ', full_lower: 'カトウシ' },
    en: null,
    start_date: '2006-03-20',
    end_date: null,
    remark: { start: 'H18/03/20新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '229',
    name: 'たつの市',
    kana_name: { half_upper: 'ﾀﾂﾉｼ', full_lower: 'タツノシ' },
    en: null,
    start_date: '2005-10-01',
    end_date: null,
    remark: { start: 'H17/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '300',
    name: '川辺郡',
    kana_name: { half_upper: 'ｶﾜﾍﾞｸﾞﾝ', full_lower: 'カワベグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '301',
    name: '川辺郡　猪名川町',
    kana_name: {
      half_upper: 'ｶﾜﾍﾞｸﾞﾝ ｲﾅｶﾞﾜﾁﾖｳ',
      full_lower: 'カワベグン　イナガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '320',
    name: '美嚢郡',
    kana_name: { half_upper: 'ﾐﾉｳｸﾞﾝ', full_lower: 'ミノウグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-24',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '321',
    name: '美嚢郡　吉川町',
    kana_name: {
      half_upper: 'ﾐﾉｳｸﾞﾝ ﾖｶﾜﾁﾖｳ',
      full_lower: 'ミノウグン　ヨカワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-24',
    remark: { start: null, end: '28215三木市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '340',
    name: '加東郡',
    kana_name: { half_upper: 'ｶﾄｳｸﾞﾝ', full_lower: 'カトウグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '341',
    name: '加東郡　社町',
    kana_name: {
      half_upper: 'ｶﾄｳｸﾞﾝ ﾔｼﾛﾁﾖｳ',
      full_lower: 'カトウグン　ヤシロチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '28228加東市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '342',
    name: '加東郡　滝野町',
    kana_name: {
      half_upper: 'ｶﾄｳｸﾞﾝ ﾀｷﾉﾁﾖｳ',
      full_lower: 'カトウグン　タキノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '28228加東市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '343',
    name: '加東郡　東条町',
    kana_name: {
      half_upper: 'ｶﾄｳｸﾞﾝ ﾄｳｼﾞﾖｳﾁﾖｳ',
      full_lower: 'カトウグン　トウジョウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '28228加東市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '360',
    name: '多可郡',
    kana_name: { half_upper: 'ﾀｶｸﾞﾝ', full_lower: 'タカグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '361',
    name: '多可郡　中町',
    kana_name: {
      half_upper: 'ﾀｶｸﾞﾝ ﾅｶﾁﾖｳ',
      full_lower: 'タカグン　ナカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-01',
    remark: { start: null, end: '28365多可郡　多可町', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '362',
    name: '多可郡　加美町',
    kana_name: {
      half_upper: 'ﾀｶｸﾞﾝ ｶﾐﾁﾖｳ',
      full_lower: 'タカグン　カミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-01',
    remark: { start: null, end: '28365多可郡　多可町', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '363',
    name: '多可郡　八千代町',
    kana_name: {
      half_upper: 'ﾀｶｸﾞﾝ ﾔﾁﾖﾁﾖｳ',
      full_lower: 'タカグン　ヤチヨチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-01',
    remark: { start: null, end: '28365多可郡　多可町', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '364',
    name: '多可郡　黒田庄町',
    kana_name: {
      half_upper: 'ﾀｶｸﾞﾝ ｸﾛﾀﾞｼﾖｳﾁﾖｳ',
      full_lower: 'タカグン　クロダショウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '28213西脇市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '365',
    name: '多可郡　多可町',
    kana_name: {
      half_upper: 'ﾀｶｸﾞﾝ ﾀｶﾁﾖｳ',
      full_lower: 'タカグン　タカチョウ',
    },
    en: null,
    start_date: '2005-11-01',
    end_date: null,
    remark: { start: 'H17/11/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '380',
    name: '加古郡',
    kana_name: { half_upper: 'ｶｺｸﾞﾝ', full_lower: 'カコグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '381',
    name: '加古郡　稲美町',
    kana_name: {
      half_upper: 'ｶｺｸﾞﾝ ｲﾅﾐﾁﾖｳ',
      full_lower: 'カコグン　イナミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '382',
    name: '加古郡　播磨町',
    kana_name: {
      half_upper: 'ｶｺｸﾞﾝ ﾊﾘﾏﾁﾖｳ',
      full_lower: 'カコグン　ハリマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '400',
    name: '印南郡',
    kana_name: { half_upper: 'ｲﾝﾅﾐｸﾞﾝ', full_lower: 'インナミグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '1979-02-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '401',
    name: '印南郡　志方町',
    kana_name: {
      half_upper: 'ｲﾝﾅﾐｸﾞﾝ ｼｶﾀﾁﾖｳ',
      full_lower: 'インナミグン　シカタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1979-02-01',
    remark: { start: null, end: '28210加古川市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '420',
    name: '飾磨郡',
    kana_name: { half_upper: 'ｼｶﾏｸﾞﾝ', full_lower: 'シカマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '421',
    name: '飾磨郡　家島町',
    kana_name: {
      half_upper: 'ｼｶﾏｸﾞﾝ ｲｴｼﾏﾁﾖｳ',
      full_lower: 'シカマグン　イエシマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '28201姫路市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '422',
    name: '飾磨郡　夢前町',
    kana_name: {
      half_upper: 'ｼｶﾏｸﾞﾝ ﾕﾒｻｷﾁﾖｳ',
      full_lower: 'シカマグン　ユメサキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '28201姫路市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '440',
    name: '神崎郡',
    kana_name: { half_upper: 'ｶﾝｻﾞｷｸﾞﾝ', full_lower: 'カンザキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '441',
    name: '神崎郡　神崎町',
    kana_name: {
      half_upper: 'ｶﾝｻﾞｷｸﾞﾝ ｶﾝｻﾞｷﾁﾖｳ',
      full_lower: 'カンザキグン　カンザキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-07',
    remark: { start: null, end: '28446神崎郡　神河町', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '442',
    name: '神崎郡　市川町',
    kana_name: {
      half_upper: 'ｶﾝｻﾞｷｸﾞﾝ ｲﾁｶﾜﾁﾖｳ',
      full_lower: 'カンザキグン　イチカワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '443',
    name: '神崎郡　福崎町',
    kana_name: {
      half_upper: 'ｶﾝｻﾞｷｸﾞﾝ ﾌｸｻｷﾁﾖｳ',
      full_lower: 'カンザキグン　フクサキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '444',
    name: '神崎郡　香寺町',
    kana_name: {
      half_upper: 'ｶﾝｻﾞｷｸﾞﾝ ｺｳﾃﾞﾗﾁﾖｳ',
      full_lower: 'カンザキグン　コウデラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '28201姫路市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '445',
    name: '神崎郡　大河内町',
    kana_name: {
      half_upper: 'ｶﾝｻﾞｷｸﾞﾝ ｵｵｶﾜﾁﾁﾖｳ',
      full_lower: 'カンザキグン　オオカワチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-07',
    remark: { start: null, end: '28446神崎郡　神河町', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '446',
    name: '神崎郡　神河町',
    kana_name: {
      half_upper: 'ｶﾝｻﾞｷｸﾞﾝ ｶﾐｶﾜﾁﾖｳ',
      full_lower: 'カンザキグン　カミカワチョウ',
    },
    en: null,
    start_date: '2005-11-07',
    end_date: null,
    remark: { start: 'H17/11/07新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '460',
    name: '揖保郡',
    kana_name: { half_upper: 'ｲﾎﾞｸﾞﾝ', full_lower: 'イボグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '461',
    name: '揖保郡　新宮町',
    kana_name: {
      half_upper: 'ｲﾎﾞｸﾞﾝ ｼﾝｸﾞｳﾁﾖｳ',
      full_lower: 'イボグン　シングウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '28229たつの市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '462',
    name: '揖保郡　揖保川町',
    kana_name: {
      half_upper: 'ｲﾎﾞｸﾞﾝ ｲﾎﾞｶﾞﾜﾁﾖｳ',
      full_lower: 'イボグン　イボガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '28229たつの市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '463',
    name: '揖保郡　御津町',
    kana_name: {
      half_upper: 'ｲﾎﾞｸﾞﾝ ﾐﾂﾁﾖｳ',
      full_lower: 'イボグン　ミツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '28229たつの市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '464',
    name: '揖保郡　太子町',
    kana_name: {
      half_upper: 'ｲﾎﾞｸﾞﾝ ﾀｲｼﾁﾖｳ',
      full_lower: 'イボグン　タイシチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '480',
    name: '赤穂郡',
    kana_name: { half_upper: 'ｱｺｳｸﾞﾝ', full_lower: 'アコウグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '481',
    name: '赤穂郡　上郡町',
    kana_name: {
      half_upper: 'ｱｺｳｸﾞﾝ ｶﾐｺﾞｵﾘﾁﾖｳ',
      full_lower: 'アコウグン　カミゴオリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '500',
    name: '佐用郡',
    kana_name: { half_upper: 'ｻﾖｳｸﾞﾝ', full_lower: 'サヨウグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '501',
    name: '佐用郡　佐用町',
    kana_name: {
      half_upper: 'ｻﾖｳｸﾞﾝ ｻﾖｳﾁﾖｳ',
      full_lower: 'サヨウグン　サヨウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '502',
    name: '佐用郡　上月町',
    kana_name: {
      half_upper: 'ｻﾖｳｸﾞﾝ ｺｳﾂﾞｷﾁﾖｳ',
      full_lower: 'サヨウグン　コウヅキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '28501佐用郡　佐用町', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '503',
    name: '佐用郡　南光町',
    kana_name: {
      half_upper: 'ｻﾖｳｸﾞﾝ ﾅﾝｺｳﾁﾖｳ',
      full_lower: 'サヨウグン　ナンコウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '28501佐用郡　佐用町', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '504',
    name: '佐用郡　三日月町',
    kana_name: {
      half_upper: 'ｻﾖｳｸﾞﾝ ﾐｶﾂﾞｷﾁﾖｳ',
      full_lower: 'サヨウグン　ミカヅキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '28501佐用郡　佐用町', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '520',
    name: '宍粟郡',
    kana_name: { half_upper: 'ｼｿｳｸﾞﾝ', full_lower: 'シソウグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '521',
    name: '宍粟郡　山崎町',
    kana_name: {
      half_upper: 'ｼｿｳｸﾞﾝ ﾔﾏｻｷﾁﾖｳ',
      full_lower: 'シソウグン　ヤマサキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '28227宍粟市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '522',
    name: '宍粟郡　安富町',
    kana_name: {
      half_upper: 'ｼｿｳｸﾞﾝ ﾔｽﾄﾐﾁﾖｳ',
      full_lower: 'シソウグン　ヤストミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '28201姫路市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '523',
    name: '宍粟郡　一宮町',
    kana_name: {
      half_upper: 'ｼｿｳｸﾞﾝ ｲﾁﾉﾐﾔﾁﾖｳ',
      full_lower: 'シソウグン　イチノミヤチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '28227宍粟市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '524',
    name: '宍粟郡　波賀町',
    kana_name: {
      half_upper: 'ｼｿｳｸﾞﾝ ﾊｶﾞﾁﾖｳ',
      full_lower: 'シソウグン　ハガチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '28227宍粟市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '525',
    name: '宍粟郡　千種町',
    kana_name: {
      half_upper: 'ｼｿｳｸﾞﾝ ﾁｸｻﾁﾖｳ',
      full_lower: 'シソウグン　チクサチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '28227宍粟市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '540',
    name: '城崎郡',
    kana_name: { half_upper: 'ｷﾉｻｷｸﾞﾝ', full_lower: 'キノサキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '541',
    name: '城崎郡　城崎町',
    kana_name: {
      half_upper: 'ｷﾉｻｷｸﾞﾝ ｷﾉｻｷﾁﾖｳ',
      full_lower: 'キノサキグン　キノサキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '28209豊岡市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '542',
    name: '城崎郡　竹野町',
    kana_name: {
      half_upper: 'ｷﾉｻｷｸﾞﾝ ﾀｹﾉﾁﾖｳ',
      full_lower: 'キノサキグン　タケノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '28209豊岡市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '543',
    name: '城崎郡　香住町',
    kana_name: {
      half_upper: 'ｷﾉｻｷｸﾞﾝ ｶｽﾐﾁﾖｳ',
      full_lower: 'キノサキグン　カスミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '28585美方郡　香美町', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '544',
    name: '城崎郡　日高町',
    kana_name: {
      half_upper: 'ｷﾉｻｷｸﾞﾝ ﾋﾀﾞｶﾁﾖｳ',
      full_lower: 'キノサキグン　ヒダカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '28209豊岡市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '560',
    name: '出石郡',
    kana_name: { half_upper: 'ｲｽﾞｼｸﾞﾝ', full_lower: 'イズシグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '561',
    name: '出石郡　出石町',
    kana_name: {
      half_upper: 'ｲｽﾞｼｸﾞﾝ ｲｽﾞｼﾁﾖｳ',
      full_lower: 'イズシグン　イズシチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '28209豊岡市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '562',
    name: '出石郡　但東町',
    kana_name: {
      half_upper: 'ｲｽﾞｼｸﾞﾝ ﾀﾝﾄｳﾁﾖｳ',
      full_lower: 'イズシグン　タントウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '28209豊岡市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '580',
    name: '美方郡',
    kana_name: { half_upper: 'ﾐｶﾀｸﾞﾝ', full_lower: 'ミカタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '581',
    name: '美方郡　村岡町',
    kana_name: {
      half_upper: 'ﾐｶﾀｸﾞﾝ ﾑﾗｵｶﾁﾖｳ',
      full_lower: 'ミカタグン　ムラオカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '28585美方郡　香美町', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '582',
    name: '美方郡　浜坂町',
    kana_name: {
      half_upper: 'ﾐｶﾀｸﾞﾝ ﾊﾏｻｶﾁﾖｳ',
      full_lower: 'ミカタグン　ハマサカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '28586美方郡　新温泉町', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '583',
    name: '美方郡　美方町',
    kana_name: {
      half_upper: 'ﾐｶﾀｸﾞﾝ ﾐｶﾀﾁﾖｳ',
      full_lower: 'ミカタグン　ミカタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '28585美方郡　香美町', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '584',
    name: '美方郡　温泉町',
    kana_name: {
      half_upper: 'ﾐｶﾀｸﾞﾝ ｵﾝｾﾝﾁﾖｳ',
      full_lower: 'ミカタグン　オンセンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '28586美方郡　新温泉町', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '585',
    name: '美方郡　香美町',
    kana_name: {
      half_upper: 'ﾐｶﾀｸﾞﾝ ｶﾐﾁﾖｳ',
      full_lower: 'ミカタグン　カミチョウ',
    },
    en: null,
    start_date: '2005-04-01',
    end_date: null,
    remark: { start: 'H17/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '586',
    name: '美方郡　新温泉町',
    kana_name: {
      half_upper: 'ﾐｶﾀｸﾞﾝ ｼﾝｵﾝｾﾝﾁﾖｳ',
      full_lower: 'ミカタグン　シンオンセンチョウ',
    },
    en: null,
    start_date: '2005-10-01',
    end_date: null,
    remark: { start: 'H17/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '600',
    name: '養父郡',
    kana_name: { half_upper: 'ﾔﾌﾞｸﾞﾝ', full_lower: 'ヤブグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '601',
    name: '養父郡　八鹿町',
    kana_name: {
      half_upper: 'ﾔﾌﾞｸﾞﾝ ﾖｳｶﾁﾖｳ',
      full_lower: 'ヤブグン　ヨウカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '28222養父市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '602',
    name: '養父郡　養父町',
    kana_name: {
      half_upper: 'ﾔﾌﾞｸﾞﾝ ﾔﾌﾞﾁﾖｳ',
      full_lower: 'ヤブグン　ヤブチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '28222養父市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '603',
    name: '養父郡　大屋町',
    kana_name: {
      half_upper: 'ﾔﾌﾞｸﾞﾝ ｵｵﾔﾁﾖｳ',
      full_lower: 'ヤブグン　オオヤチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '28222養父市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '604',
    name: '養父郡　関宮町',
    kana_name: {
      half_upper: 'ﾔﾌﾞｸﾞﾝ ｾｷﾉﾐﾔﾁﾖｳ',
      full_lower: 'ヤブグン　セキノミヤチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '28222養父市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '620',
    name: '朝来郡',
    kana_name: { half_upper: 'ｱｻｺﾞｸﾞﾝ', full_lower: 'アサゴグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '621',
    name: '朝来郡　生野町',
    kana_name: {
      half_upper: 'ｱｻｺﾞｸﾞﾝ ｲｸﾉﾁﾖｳ',
      full_lower: 'アサゴグン　イクノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '28225朝来市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '622',
    name: '朝来郡　和田山町',
    kana_name: {
      half_upper: 'ｱｻｺﾞｸﾞﾝ ﾜﾀﾞﾔﾏﾁﾖｳ',
      full_lower: 'アサゴグン　ワダヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '28225朝来市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '623',
    name: '朝来郡　山東町',
    kana_name: {
      half_upper: 'ｱｻｺﾞｸﾞﾝ ｻﾝﾄｳﾁﾖｳ',
      full_lower: 'アサゴグン　サントウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '28225朝来市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '624',
    name: '朝来郡　朝来町',
    kana_name: {
      half_upper: 'ｱｻｺﾞｸﾞﾝ ｱｻｺﾞﾁﾖｳ',
      full_lower: 'アサゴグン　アサゴチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '28225朝来市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '640',
    name: '氷上郡',
    kana_name: { half_upper: 'ﾋｶﾐｸﾞﾝ', full_lower: 'ヒカミグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '641',
    name: '氷上郡　柏原町',
    kana_name: {
      half_upper: 'ﾋｶﾐｸﾞﾝ ｶｲﾊﾞﾗﾁﾖｳ',
      full_lower: 'ヒカミグン　カイバラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '28223丹波市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '642',
    name: '氷上郡　氷上町',
    kana_name: {
      half_upper: 'ﾋｶﾐｸﾞﾝ ﾋｶﾐﾁﾖｳ',
      full_lower: 'ヒカミグン　ヒカミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '28223丹波市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '643',
    name: '氷上郡　青垣町',
    kana_name: {
      half_upper: 'ﾋｶﾐｸﾞﾝ ｱｵｶﾞｷﾁﾖｳ',
      full_lower: 'ヒカミグン　アオガキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '28223丹波市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '644',
    name: '氷上郡　春日町',
    kana_name: {
      half_upper: 'ﾋｶﾐｸﾞﾝ ｶｽｶﾞﾁﾖｳ',
      full_lower: 'ヒカミグン　カスガチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '28223丹波市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '645',
    name: '氷上郡　山南町',
    kana_name: {
      half_upper: 'ﾋｶﾐｸﾞﾝ ｻﾝﾅﾝﾁﾖｳ',
      full_lower: 'ヒカミグン　サンナンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '28223丹波市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '646',
    name: '氷上郡　市島町',
    kana_name: {
      half_upper: 'ﾋｶﾐｸﾞﾝ ｲﾁｼﾞﾏﾁﾖｳ',
      full_lower: 'ヒカミグン　イチジマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '28223丹波市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '660',
    name: '多紀郡',
    kana_name: { half_upper: 'ﾀｷｸﾞﾝ', full_lower: 'タキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '1999-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '661',
    name: '多紀郡　篠山町',
    kana_name: {
      half_upper: 'ﾀｷｸﾞﾝ ｻｻﾔﾏﾁﾖｳ',
      full_lower: 'タキグン　ササヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1999-04-01',
    remark: { start: null, end: '28221篠山市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '662',
    name: '多紀郡　城東町',
    kana_name: {
      half_upper: 'ﾀｷｸﾞﾝ ｼﾞﾖｳﾄｳﾁﾖｳ',
      full_lower: 'タキグン　ジョウトウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1975-03-28',
    remark: { start: null, end: '28661多紀郡　篠山町', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '663',
    name: '多紀郡　多紀町',
    kana_name: {
      half_upper: 'ﾀｷｸﾞﾝ ﾀｷﾁﾖｳ',
      full_lower: 'タキグン　タキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1975-03-28',
    remark: { start: null, end: '28661多紀郡　篠山町', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '664',
    name: '多紀郡　西紀町',
    kana_name: {
      half_upper: 'ﾀｷｸﾞﾝ ﾆｼｷﾁﾖｳ',
      full_lower: 'タキグン　ニシキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1999-04-01',
    remark: { start: null, end: '28221篠山市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '665',
    name: '多紀郡　丹南町',
    kana_name: {
      half_upper: 'ﾀｷｸﾞﾝ ﾀﾝﾅﾝﾁﾖｳ',
      full_lower: 'タキグン　タンナンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1999-04-01',
    remark: { start: null, end: '28221篠山市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '666',
    name: '多紀郡　今田町',
    kana_name: {
      half_upper: 'ﾀｷｸﾞﾝ ｺﾝﾀﾞﾁﾖｳ',
      full_lower: 'タキグン　コンダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1999-04-01',
    remark: { start: null, end: '28221篠山市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '680',
    name: '津名郡',
    kana_name: { half_upper: 'ﾂﾅｸﾞﾝ', full_lower: 'ツナグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-11',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '681',
    name: '津名郡　津名町',
    kana_name: {
      half_upper: 'ﾂﾅｸﾞﾝ ﾂﾅﾁﾖｳ',
      full_lower: 'ツナグン　ツナチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '28226淡路市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '682',
    name: '津名郡　淡路町',
    kana_name: {
      half_upper: 'ﾂﾅｸﾞﾝ ｱﾜｼﾞﾁﾖｳ',
      full_lower: 'ツナグン　アワジチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '28226淡路市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '683',
    name: '津名郡　北淡町',
    kana_name: {
      half_upper: 'ﾂﾅｸﾞﾝ ﾎｸﾀﾞﾝﾁﾖｳ',
      full_lower: 'ツナグン　ホクダンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '28226淡路市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '684',
    name: '津名郡　一宮町',
    kana_name: {
      half_upper: 'ﾂﾅｸﾞﾝ ｲﾁﾉﾐﾔﾁﾖｳ',
      full_lower: 'ツナグン　イチノミヤチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '28226淡路市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '685',
    name: '津名郡　五色町',
    kana_name: {
      half_upper: 'ﾂﾅｸﾞﾝ ｺﾞｼｷﾁﾖｳ',
      full_lower: 'ツナグン　ゴシキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-11',
    remark: { start: null, end: '28205洲本市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '686',
    name: '津名郡　東浦町',
    kana_name: {
      half_upper: 'ﾂﾅｸﾞﾝ ﾋｶﾞｼｳﾗﾁﾖｳ',
      full_lower: 'ツナグン　ヒガシウラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '28226淡路市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '700',
    name: '三原郡',
    kana_name: { half_upper: 'ﾐﾊﾗｸﾞﾝ', full_lower: 'ミハラグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-11',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '701',
    name: '三原郡　緑町',
    kana_name: {
      half_upper: 'ﾐﾊﾗｸﾞﾝ ﾐﾄﾞﾘﾁﾖｳ',
      full_lower: 'ミハラグン　ミドリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-11',
    remark: { start: null, end: '28224南あわじ市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '702',
    name: '三原郡　西淡町',
    kana_name: {
      half_upper: 'ﾐﾊﾗｸﾞﾝ ｾｲﾀﾞﾝﾁﾖｳ',
      full_lower: 'ミハラグン　セイダンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-11',
    remark: { start: null, end: '28224南あわじ市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '703',
    name: '三原郡　三原町',
    kana_name: {
      half_upper: 'ﾐﾊﾗｸﾞﾝ ﾐﾊﾗﾁﾖｳ',
      full_lower: 'ミハラグン　ミハラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-11',
    remark: { start: null, end: '28224南あわじ市', other: null },
    note: null,
  },
  {
    pref_code: '28',
    city_code: '704',
    name: '三原郡　南淡町',
    kana_name: {
      half_upper: 'ﾐﾊﾗｸﾞﾝ ﾅﾝﾀﾞﾝﾁﾖｳ',
      full_lower: 'ミハラグン　ナンダンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-11',
    remark: { start: null, end: '28224南あわじ市', other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '201',
    name: '奈良市',
    kana_name: { half_upper: 'ﾅﾗｼ', full_lower: 'ナラシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '202',
    name: '大和高田市',
    kana_name: { half_upper: 'ﾔﾏﾄﾀｶﾀﾞｼ', full_lower: 'ヤマトタカダシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '203',
    name: '大和郡山市',
    kana_name: { half_upper: 'ﾔﾏﾄｺｵﾘﾔﾏｼ', full_lower: 'ヤマトコオリヤマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '204',
    name: '天理市',
    kana_name: { half_upper: 'ﾃﾝﾘｼ', full_lower: 'テンリシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '205',
    name: '橿原市',
    kana_name: { half_upper: 'ｶｼﾊﾗｼ', full_lower: 'カシハラシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '206',
    name: '桜井市',
    kana_name: { half_upper: 'ｻｸﾗｲｼ', full_lower: 'サクライシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '207',
    name: '五條市',
    kana_name: { half_upper: 'ｺﾞｼﾞﾖｳｼ', full_lower: 'ゴジョウシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '208',
    name: '御所市',
    kana_name: { half_upper: 'ｺﾞｾｼ', full_lower: 'ゴセシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '209',
    name: '生駒市',
    kana_name: { half_upper: 'ｲｺﾏｼ', full_lower: 'イコマシ' },
    en: null,
    start_date: '1971-11-01',
    end_date: null,
    remark: { start: 'S46/11/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '210',
    name: '香芝市',
    kana_name: { half_upper: 'ｶｼﾊﾞｼ', full_lower: 'カシバシ' },
    en: null,
    start_date: '1991-10-01',
    end_date: null,
    remark: { start: 'H03/10/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '211',
    name: '葛城市',
    kana_name: { half_upper: 'ｶﾂﾗｷﾞｼ', full_lower: 'カツラギシ' },
    en: null,
    start_date: '2004-10-01',
    end_date: null,
    remark: { start: 'H16/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '212',
    name: '宇陀市',
    kana_name: { half_upper: 'ｳﾀﾞｼ', full_lower: 'ウダシ' },
    en: null,
    start_date: '2006-01-01',
    end_date: null,
    remark: { start: 'H18/01/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '300',
    name: '添上郡',
    kana_name: { half_upper: 'ｿｴｶﾐｸﾞﾝ', full_lower: 'ソエカミグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '301',
    name: '添上郡　月ヶ瀬村',
    kana_name: {
      half_upper: 'ｿｴｶﾐｸﾞﾝ ﾂｷｶﾞｾﾑﾗ',
      full_lower: 'ソエカミグン　ツキガセムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '29201奈良市', other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '320',
    name: '山辺郡',
    kana_name: { half_upper: 'ﾔﾏﾍﾞｸﾞﾝ', full_lower: 'ヤマベグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '321',
    name: '山辺郡　都祁村',
    kana_name: {
      half_upper: 'ﾔﾏﾍﾞｸﾞﾝ ﾂｹﾞﾑﾗ',
      full_lower: 'ヤマベグン　ツゲムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '29201奈良市', other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '322',
    name: '山辺郡　山添村',
    kana_name: {
      half_upper: 'ﾔﾏﾍﾞｸﾞﾝ ﾔﾏｿﾞｴﾑﾗ',
      full_lower: 'ヤマベグン　ヤマゾエムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '340',
    name: '生駒郡',
    kana_name: { half_upper: 'ｲｺﾏｸﾞﾝ', full_lower: 'イコマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '341',
    name: '生駒郡　生駒町',
    kana_name: {
      half_upper: 'ｲｺﾏｸﾞﾝ ｲｺﾏﾁﾖｳ',
      full_lower: 'イコマグン　イコマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-11-01',
    remark: { start: null, end: '29209生駒市', other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '342',
    name: '生駒郡　平群町',
    kana_name: {
      half_upper: 'ｲｺﾏｸﾞﾝ ﾍｸﾞﾘﾁﾖｳ',
      full_lower: 'イコマグン　ヘグリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '343',
    name: '生駒郡　三郷町',
    kana_name: {
      half_upper: 'ｲｺﾏｸﾞﾝ ｻﾝｺﾞｳﾁﾖｳ',
      full_lower: 'イコマグン　サンゴウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '344',
    name: '生駒郡　斑鳩町',
    kana_name: {
      half_upper: 'ｲｺﾏｸﾞﾝ ｲｶﾙｶﾞﾁﾖｳ',
      full_lower: 'イコマグン　イカルガチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '345',
    name: '生駒郡　安堵町',
    kana_name: {
      half_upper: 'ｲｺﾏｸﾞﾝ ｱﾝﾄﾞﾁﾖｳ',
      full_lower: 'イコマグン　アンドチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '360',
    name: '磯城郡',
    kana_name: { half_upper: 'ｼｷｸﾞﾝ', full_lower: 'シキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '361',
    name: '磯城郡　川西町',
    kana_name: {
      half_upper: 'ｼｷｸﾞﾝ ｶﾜﾆｼﾁﾖｳ',
      full_lower: 'シキグン　カワニシチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '362',
    name: '磯城郡　三宅町',
    kana_name: {
      half_upper: 'ｼｷｸﾞﾝ ﾐﾔｹﾁﾖｳ',
      full_lower: 'シキグン　ミヤケチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '363',
    name: '磯城郡　田原本町',
    kana_name: {
      half_upper: 'ｼｷｸﾞﾝ ﾀﾜﾗﾓﾄﾁﾖｳ',
      full_lower: 'シキグン　タワラモトチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '380',
    name: '宇陀郡',
    kana_name: { half_upper: 'ｳﾀﾞｸﾞﾝ', full_lower: 'ウダグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '381',
    name: '宇陀郡　大宇陀町',
    kana_name: {
      half_upper: 'ｳﾀﾞｸﾞﾝ ｵｵｳﾀﾞﾁﾖｳ',
      full_lower: 'ウダグン　オオウダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '29212宇陀市', other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '382',
    name: '宇陀郡　菟田野町',
    kana_name: {
      half_upper: 'ｳﾀﾞｸﾞﾝ ｳﾀﾉﾁﾖｳ',
      full_lower: 'ウダグン　ウタノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '29212宇陀市', other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '383',
    name: '宇陀郡　榛原町',
    kana_name: {
      half_upper: 'ｳﾀﾞｸﾞﾝ ﾊｲﾊﾞﾗﾁﾖｳ',
      full_lower: 'ウダグン　ハイバラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '29212宇陀市', other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '384',
    name: '宇陀郡　室生村',
    kana_name: {
      half_upper: 'ｳﾀﾞｸﾞﾝ ﾑﾛｳﾑﾗ',
      full_lower: 'ウダグン　ムロウムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '29212宇陀市', other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '385',
    name: '宇陀郡　曽爾村',
    kana_name: {
      half_upper: 'ｳﾀﾞｸﾞﾝ ｿﾆﾑﾗ',
      full_lower: 'ウダグン　ソニムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '386',
    name: '宇陀郡　御杖村',
    kana_name: {
      half_upper: 'ｳﾀﾞｸﾞﾝ ﾐﾂｴﾑﾗ',
      full_lower: 'ウダグン　ミツエムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '400',
    name: '高市郡',
    kana_name: { half_upper: 'ﾀｶｲﾁｸﾞﾝ', full_lower: 'タカイチグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '401',
    name: '高市郡　高取町',
    kana_name: {
      half_upper: 'ﾀｶｲﾁｸﾞﾝ ﾀｶﾄﾘﾁﾖｳ',
      full_lower: 'タカイチグン　タカトリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '402',
    name: '高市郡　明日香村',
    kana_name: {
      half_upper: 'ﾀｶｲﾁｸﾞﾝ ｱｽｶﾑﾗ',
      full_lower: 'タカイチグン　アスカムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '420',
    name: '北葛城郡',
    kana_name: { half_upper: 'ｷﾀｶﾂﾗｷﾞｸﾞﾝ', full_lower: 'キタカツラギグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '421',
    name: '北葛城郡　新庄町',
    kana_name: {
      half_upper: 'ｷﾀｶﾂﾗｷﾞｸﾞﾝ ｼﾝｼﾞﾖｳﾁﾖｳ',
      full_lower: 'キタカツラギグン　シンジョウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '29211葛城市', other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '422',
    name: '北葛城郡　當麻町',
    kana_name: {
      half_upper: 'ｷﾀｶﾂﾗｷﾞｸﾞﾝ ﾀｲﾏﾁﾖｳ',
      full_lower: 'キタカツラギグン　タイマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '29211葛城市', other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '423',
    name: '北葛城郡　香芝町',
    kana_name: {
      half_upper: 'ｷﾀｶﾂﾗｷﾞｸﾞﾝ ｶｼﾊﾞﾁﾖｳ',
      full_lower: 'キタカツラギグン　カシバチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1991-10-01',
    remark: { start: null, end: '29210香芝市', other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '424',
    name: '北葛城郡　上牧町',
    kana_name: {
      half_upper: 'ｷﾀｶﾂﾗｷﾞｸﾞﾝ ｶﾝﾏｷﾁﾖｳ',
      full_lower: 'キタカツラギグン　カンマキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '425',
    name: '北葛城郡　王寺町',
    kana_name: {
      half_upper: 'ｷﾀｶﾂﾗｷﾞｸﾞﾝ ｵｳｼﾞﾁﾖｳ',
      full_lower: 'キタカツラギグン　オウジチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '426',
    name: '北葛城郡　広陵町',
    kana_name: {
      half_upper: 'ｷﾀｶﾂﾗｷﾞｸﾞﾝ ｺｳﾘﾖｳﾁﾖｳ',
      full_lower: 'キタカツラギグン　コウリョウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '427',
    name: '北葛城郡　河合町',
    kana_name: {
      half_upper: 'ｷﾀｶﾂﾗｷﾞｸﾞﾝ ｶﾜｲﾁﾖｳ',
      full_lower: 'キタカツラギグン　カワイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '440',
    name: '吉野郡',
    kana_name: { half_upper: 'ﾖｼﾉｸﾞﾝ', full_lower: 'ヨシノグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '441',
    name: '吉野郡　吉野町',
    kana_name: {
      half_upper: 'ﾖｼﾉｸﾞﾝ ﾖｼﾉﾁﾖｳ',
      full_lower: 'ヨシノグン　ヨシノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '442',
    name: '吉野郡　大淀町',
    kana_name: {
      half_upper: 'ﾖｼﾉｸﾞﾝ ｵｵﾖﾄﾞﾁﾖｳ',
      full_lower: 'ヨシノグン　オオヨドチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '443',
    name: '吉野郡　下市町',
    kana_name: {
      half_upper: 'ﾖｼﾉｸﾞﾝ ｼﾓｲﾁﾁﾖｳ',
      full_lower: 'ヨシノグン　シモイチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '444',
    name: '吉野郡　黒滝村',
    kana_name: {
      half_upper: 'ﾖｼﾉｸﾞﾝ ｸﾛﾀｷﾑﾗ',
      full_lower: 'ヨシノグン　クロタキムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '445',
    name: '吉野郡　西吉野村',
    kana_name: {
      half_upper: 'ﾖｼﾉｸﾞﾝ ﾆｼﾖｼﾉﾑﾗ',
      full_lower: 'ヨシノグン　ニシヨシノムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-25',
    remark: { start: null, end: '29207五條市', other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '446',
    name: '吉野郡　天川村',
    kana_name: {
      half_upper: 'ﾖｼﾉｸﾞﾝ ﾃﾝｶﾜﾑﾗ',
      full_lower: 'ヨシノグン　テンカワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '447',
    name: '吉野郡　野迫川村',
    kana_name: {
      half_upper: 'ﾖｼﾉｸﾞﾝ ﾉｾｶﾞﾜﾑﾗ',
      full_lower: 'ヨシノグン　ノセガワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '448',
    name: '吉野郡　大塔村',
    kana_name: {
      half_upper: 'ﾖｼﾉｸﾞﾝ ｵｵﾄｳﾑﾗ',
      full_lower: 'ヨシノグン　オオトウムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-25',
    remark: { start: null, end: '29207五條市', other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '449',
    name: '吉野郡　十津川村',
    kana_name: {
      half_upper: 'ﾖｼﾉｸﾞﾝ ﾄﾂｶﾜﾑﾗ',
      full_lower: 'ヨシノグン　トツカワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '450',
    name: '吉野郡　下北山村',
    kana_name: {
      half_upper: 'ﾖｼﾉｸﾞﾝ ｼﾓｷﾀﾔﾏﾑﾗ',
      full_lower: 'ヨシノグン　シモキタヤマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '451',
    name: '吉野郡　上北山村',
    kana_name: {
      half_upper: 'ﾖｼﾉｸﾞﾝ ｶﾐｷﾀﾔﾏﾑﾗ',
      full_lower: 'ヨシノグン　カミキタヤマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '452',
    name: '吉野郡　川上村',
    kana_name: {
      half_upper: 'ﾖｼﾉｸﾞﾝ ｶﾜｶﾐﾑﾗ',
      full_lower: 'ヨシノグン　カワカミムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '29',
    city_code: '453',
    name: '吉野郡　東吉野村',
    kana_name: {
      half_upper: 'ﾖｼﾉｸﾞﾝ ﾋｶﾞｼﾖｼﾉﾑﾗ',
      full_lower: 'ヨシノグン　ヒガシヨシノムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '201',
    name: '和歌山市',
    kana_name: { half_upper: 'ﾜｶﾔﾏｼ', full_lower: 'ワカヤマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '202',
    name: '海南市',
    kana_name: { half_upper: 'ｶｲﾅﾝｼ', full_lower: 'カイナンシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '203',
    name: '橋本市',
    kana_name: { half_upper: 'ﾊｼﾓﾄｼ', full_lower: 'ハシモトシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '204',
    name: '有田市',
    kana_name: { half_upper: 'ｱﾘﾀﾞｼ', full_lower: 'アリダシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '205',
    name: '御坊市',
    kana_name: { half_upper: 'ｺﾞﾎﾞｳｼ', full_lower: 'ゴボウシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '206',
    name: '田辺市',
    kana_name: { half_upper: 'ﾀﾅﾍﾞｼ', full_lower: 'タナベシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '207',
    name: '新宮市',
    kana_name: { half_upper: 'ｼﾝｸﾞｳｼ', full_lower: 'シングウシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '208',
    name: '紀の川市',
    kana_name: { half_upper: 'ｷﾉｶﾜｼ', full_lower: 'キノカワシ' },
    en: null,
    start_date: '2005-11-07',
    end_date: null,
    remark: { start: 'H17/11/07新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '209',
    name: '岩出市',
    kana_name: { half_upper: 'ｲﾜﾃﾞｼ', full_lower: 'イワデシ' },
    en: null,
    start_date: '2006-04-01',
    end_date: null,
    remark: { start: 'H18/04/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '300',
    name: '海草郡',
    kana_name: { half_upper: 'ｶｲｿｳｸﾞﾝ', full_lower: 'カイソウグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '301',
    name: '海草郡　下津町',
    kana_name: {
      half_upper: 'ｶｲｿｳｸﾞﾝ ｼﾓﾂﾁﾖｳ',
      full_lower: 'カイソウグン　シモツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '30202海南市', other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '302',
    name: '海草郡　野上町',
    kana_name: {
      half_upper: 'ｶｲｿｳｸﾞﾝ ﾉｶﾐﾁﾖｳ',
      full_lower: 'カイソウグン　ノカミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '30304海草郡　紀美野町', other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '303',
    name: '海草郡　美里町',
    kana_name: {
      half_upper: 'ｶｲｿｳｸﾞﾝ ﾐｻﾄﾁﾖｳ',
      full_lower: 'カイソウグン　ミサトチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '30304海草郡　紀美野町', other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '304',
    name: '海草郡　紀美野町',
    kana_name: {
      half_upper: 'ｶｲｿｳｸﾞﾝ ｷﾐﾉﾁﾖｳ',
      full_lower: 'カイソウグン　キミノチョウ',
    },
    en: null,
    start_date: '2006-01-01',
    end_date: null,
    remark: { start: 'H18/01/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '320',
    name: '那賀郡',
    kana_name: { half_upper: 'ﾅｶﾞｸﾞﾝ', full_lower: 'ナガグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '321',
    name: '那賀郡　打田町',
    kana_name: {
      half_upper: 'ﾅｶﾞｸﾞﾝ ｳﾁﾀﾁﾖｳ',
      full_lower: 'ナガグン　ウチタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-07',
    remark: { start: null, end: '30208紀の川市', other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '322',
    name: '那賀郡　粉河町',
    kana_name: {
      half_upper: 'ﾅｶﾞｸﾞﾝ ｺｶﾜﾁﾖｳ',
      full_lower: 'ナガグン　コカワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-07',
    remark: { start: null, end: '30208紀の川市', other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '323',
    name: '那賀郡　那賀町',
    kana_name: {
      half_upper: 'ﾅｶﾞｸﾞﾝ ﾅｶﾞﾁﾖｳ',
      full_lower: 'ナガグン　ナガチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-07',
    remark: { start: null, end: '30208紀の川市', other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '324',
    name: '那賀郡　桃山町',
    kana_name: {
      half_upper: 'ﾅｶﾞｸﾞﾝ ﾓﾓﾔﾏﾁﾖｳ',
      full_lower: 'ナガグン　モモヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-07',
    remark: { start: null, end: '30208紀の川市', other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '325',
    name: '那賀郡　貴志川町',
    kana_name: {
      half_upper: 'ﾅｶﾞｸﾞﾝ ｷｼｶﾞﾜﾁﾖｳ',
      full_lower: 'ナガグン　キシガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-07',
    remark: { start: null, end: '30208紀の川市', other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '326',
    name: '那賀郡　岩出町',
    kana_name: {
      half_upper: 'ﾅｶﾞｸﾞﾝ ｲﾜﾃﾞﾁﾖｳ',
      full_lower: 'ナガグン　イワデチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-04-01',
    remark: { start: null, end: '30209岩出市', other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '340',
    name: '伊都郡',
    kana_name: { half_upper: 'ｲﾄｸﾞﾝ', full_lower: 'イトグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '341',
    name: '伊都郡　かつらぎ町',
    kana_name: {
      half_upper: 'ｲﾄｸﾞﾝ ｶﾂﾗｷﾞﾁﾖｳ',
      full_lower: 'イトグン　カツラギチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '342',
    name: '伊都郡　高野口町',
    kana_name: {
      half_upper: 'ｲﾄｸﾞﾝ ｺｳﾔｸﾞﾁﾁﾖｳ',
      full_lower: 'イトグン　コウヤグチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '30203橋本市', other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '343',
    name: '伊都郡　九度山町',
    kana_name: {
      half_upper: 'ｲﾄｸﾞﾝ ｸﾄﾞﾔﾏﾁﾖｳ',
      full_lower: 'イトグン　クドヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '344',
    name: '伊都郡　高野町',
    kana_name: {
      half_upper: 'ｲﾄｸﾞﾝ ｺｳﾔﾁﾖｳ',
      full_lower: 'イトグン　コウヤチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '345',
    name: '伊都郡　花園村',
    kana_name: {
      half_upper: 'ｲﾄｸﾞﾝ ﾊﾅｿﾞﾉﾑﾗ',
      full_lower: 'イトグン　ハナゾノムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '30341伊都郡　かつらぎ町', other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '360',
    name: '有田郡',
    kana_name: { half_upper: 'ｱﾘﾀﾞｸﾞﾝ', full_lower: 'アリダグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '361',
    name: '有田郡　湯浅町',
    kana_name: {
      half_upper: 'ｱﾘﾀﾞｸﾞﾝ ﾕｱｻﾁﾖｳ',
      full_lower: 'アリダグン　ユアサチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '362',
    name: '有田郡　広川町',
    kana_name: {
      half_upper: 'ｱﾘﾀﾞｸﾞﾝ ﾋﾛｶﾞﾜﾁﾖｳ',
      full_lower: 'アリダグン　ヒロガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '363',
    name: '有田郡　吉備町',
    kana_name: {
      half_upper: 'ｱﾘﾀﾞｸﾞﾝ ｷﾋﾞﾁﾖｳ',
      full_lower: 'アリダグン　キビチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '30366有田郡　有田川町', other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '364',
    name: '有田郡　金屋町',
    kana_name: {
      half_upper: 'ｱﾘﾀﾞｸﾞﾝ ｶﾅﾔﾁﾖｳ',
      full_lower: 'アリダグン　カナヤチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '30366有田郡　有田川町', other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '365',
    name: '有田郡　清水町',
    kana_name: {
      half_upper: 'ｱﾘﾀﾞｸﾞﾝ ｼﾐｽﾞﾁﾖｳ',
      full_lower: 'アリダグン　シミズチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '30366有田郡　有田川町', other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '366',
    name: '有田郡　有田川町',
    kana_name: {
      half_upper: 'ｱﾘﾀﾞｸﾞﾝ ｱﾘﾀﾞｶﾞﾜﾁﾖｳ',
      full_lower: 'アリダグン　アリダガワチョウ',
    },
    en: null,
    start_date: '2006-01-01',
    end_date: null,
    remark: { start: 'H18/01/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '380',
    name: '日高郡',
    kana_name: { half_upper: 'ﾋﾀﾞｶｸﾞﾝ', full_lower: 'ヒダカグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '381',
    name: '日高郡　美浜町',
    kana_name: {
      half_upper: 'ﾋﾀﾞｶｸﾞﾝ ﾐﾊﾏﾁﾖｳ',
      full_lower: 'ヒダカグン　ミハマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '382',
    name: '日高郡　日高町',
    kana_name: {
      half_upper: 'ﾋﾀﾞｶｸﾞﾝ ﾋﾀﾞｶﾁﾖｳ',
      full_lower: 'ヒダカグン　ヒダカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '383',
    name: '日高郡　由良町',
    kana_name: {
      half_upper: 'ﾋﾀﾞｶｸﾞﾝ ﾕﾗﾁﾖｳ',
      full_lower: 'ヒダカグン　ユラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '384',
    name: '日高郡　川辺町',
    kana_name: {
      half_upper: 'ﾋﾀﾞｶｸﾞﾝ ｶﾜﾍﾞﾁﾖｳ',
      full_lower: 'ヒダカグン　カワベチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-05-01',
    remark: { start: null, end: '30392日高郡　日高川町', other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '385',
    name: '日高郡　中津村',
    kana_name: {
      half_upper: 'ﾋﾀﾞｶｸﾞﾝ ﾅｶﾂﾑﾗ',
      full_lower: 'ヒダカグン　ナカツムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-05-01',
    remark: { start: null, end: '30392日高郡　日高川町', other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '386',
    name: '日高郡　美山村',
    kana_name: {
      half_upper: 'ﾋﾀﾞｶｸﾞﾝ ﾐﾔﾏﾑﾗ',
      full_lower: 'ヒダカグン　ミヤマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-05-01',
    remark: { start: null, end: '30392日高郡　日高川町', other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '387',
    name: '日高郡　龍神村',
    kana_name: {
      half_upper: 'ﾋﾀﾞｶｸﾞﾝ ﾘﾕｳｼﾞﾝﾑﾗ',
      full_lower: 'ヒダカグン　リュウジンムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-05-01',
    remark: { start: null, end: '30206田辺市', other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '388',
    name: '日高郡　南部川村',
    kana_name: {
      half_upper: 'ﾋﾀﾞｶｸﾞﾝ ﾐﾅﾍﾞｶﾞﾜﾑﾗ',
      full_lower: 'ヒダカグン　ミナベガワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '30391日高郡　みなべ町', other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '389',
    name: '日高郡　南部町',
    kana_name: {
      half_upper: 'ﾋﾀﾞｶｸﾞﾝ ﾐﾅﾍﾞﾁﾖｳ',
      full_lower: 'ヒダカグン　ミナベチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '30391日高郡　みなべ町', other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '390',
    name: '日高郡　印南町',
    kana_name: {
      half_upper: 'ﾋﾀﾞｶｸﾞﾝ ｲﾅﾐﾁﾖｳ',
      full_lower: 'ヒダカグン　イナミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '391',
    name: '日高郡　みなべ町',
    kana_name: {
      half_upper: 'ﾋﾀﾞｶｸﾞﾝ ﾐﾅﾍﾞﾁﾖｳ',
      full_lower: 'ヒダカグン　ミナベチョウ',
    },
    en: null,
    start_date: '2004-10-01',
    end_date: null,
    remark: { start: 'H16/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '392',
    name: '日高郡　日高川町',
    kana_name: {
      half_upper: 'ﾋﾀﾞｶｸﾞﾝ ﾋﾀﾞｶｶﾞﾜﾁﾖｳ',
      full_lower: 'ヒダカグン　ヒダカガワチョウ',
    },
    en: null,
    start_date: '2005-05-01',
    end_date: null,
    remark: { start: 'H17/05/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '400',
    name: '西牟婁郡',
    kana_name: { half_upper: 'ﾆｼﾑﾛｸﾞﾝ', full_lower: 'ニシムログン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '401',
    name: '西牟婁郡　白浜町',
    kana_name: {
      half_upper: 'ﾆｼﾑﾛｸﾞﾝ ｼﾗﾊﾏﾁﾖｳ',
      full_lower: 'ニシムログン　シラハマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '402',
    name: '西牟婁郡　中辺路町',
    kana_name: {
      half_upper: 'ﾆｼﾑﾛｸﾞﾝ ﾅｶﾍﾁﾁﾖｳ',
      full_lower: 'ニシムログン　ナカヘチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-05-01',
    remark: { start: null, end: '30206田辺市', other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '403',
    name: '西牟婁郡　大塔村',
    kana_name: {
      half_upper: 'ﾆｼﾑﾛｸﾞﾝ ｵｵﾄｳﾑﾗ',
      full_lower: 'ニシムログン　オオトウムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-05-01',
    remark: { start: null, end: '30206田辺市', other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '404',
    name: '西牟婁郡　上富田町',
    kana_name: {
      half_upper: 'ﾆｼﾑﾛｸﾞﾝ ｶﾐﾄﾝﾀﾞﾁﾖｳ',
      full_lower: 'ニシムログン　カミトンダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '405',
    name: '西牟婁郡　日置川町',
    kana_name: {
      half_upper: 'ﾆｼﾑﾛｸﾞﾝ ﾋｷｶﾞﾜﾁﾖｳ',
      full_lower: 'ニシムログン　ヒキガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '30401西牟婁郡　白浜町', other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '406',
    name: '西牟婁郡　すさみ町',
    kana_name: {
      half_upper: 'ﾆｼﾑﾛｸﾞﾝ ｽｻﾐﾁﾖｳ',
      full_lower: 'ニシムログン　スサミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '407',
    name: '西牟婁郡　串本町',
    kana_name: {
      half_upper: 'ﾆｼﾑﾛｸﾞﾝ ｸｼﾓﾄﾁﾖｳ',
      full_lower: 'ニシムログン　クシモトチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '30428東牟婁郡　串本町', other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '420',
    name: '東牟婁郡',
    kana_name: { half_upper: 'ﾋｶﾞｼﾑﾛｸﾞﾝ', full_lower: 'ヒガシムログン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '421',
    name: '東牟婁郡　那智勝浦町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾑﾛｸﾞﾝ ﾅﾁｶﾂｳﾗﾁﾖｳ',
      full_lower: 'ヒガシムログン　ナチカツウラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '422',
    name: '東牟婁郡　太地町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾑﾛｸﾞﾝ ﾀｲｼﾞﾁﾖｳ',
      full_lower: 'ヒガシムログン　タイジチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '423',
    name: '東牟婁郡　古座町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾑﾛｸﾞﾝ ｺｻﾞﾁﾖｳ',
      full_lower: 'ヒガシムログン　コザチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '30428東牟婁郡　串本町', other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '424',
    name: '東牟婁郡　古座川町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾑﾛｸﾞﾝ ｺｻﾞｶﾞﾜﾁﾖｳ',
      full_lower: 'ヒガシムログン　コザガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '425',
    name: '東牟婁郡　熊野川町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾑﾛｸﾞﾝ ｸﾏﾉｶﾞﾜﾁﾖｳ',
      full_lower: 'ヒガシムログン　クマノガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '30207新宮市', other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '426',
    name: '東牟婁郡　本宮町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾑﾛｸﾞﾝ ﾎﾝｸﾞｳﾁﾖｳ',
      full_lower: 'ヒガシムログン　ホングウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-05-01',
    remark: { start: null, end: '30206田辺市', other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '427',
    name: '東牟婁郡　北山村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾑﾛｸﾞﾝ ｷﾀﾔﾏﾑﾗ',
      full_lower: 'ヒガシムログン　キタヤマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '30',
    city_code: '428',
    name: '東牟婁郡　串本町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾑﾛｸﾞﾝ ｸｼﾓﾄﾁﾖｳ',
      full_lower: 'ヒガシムログン　クシモトチョウ',
    },
    en: null,
    start_date: '2005-04-01',
    end_date: null,
    remark: { start: 'H17/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '201',
    name: '鳥取市',
    kana_name: { half_upper: 'ﾄﾂﾄﾘｼ', full_lower: 'トットリシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '202',
    name: '米子市',
    kana_name: { half_upper: 'ﾖﾅｺﾞｼ', full_lower: 'ヨナゴシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '203',
    name: '倉吉市',
    kana_name: { half_upper: 'ｸﾗﾖｼｼ', full_lower: 'クラヨシシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '204',
    name: '境港市',
    kana_name: { half_upper: 'ｻｶｲﾐﾅﾄｼ', full_lower: 'サカイミナトシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '300',
    name: '岩美郡',
    kana_name: { half_upper: 'ｲﾜﾐｸﾞﾝ', full_lower: 'イワミグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '301',
    name: '岩美郡　国府町',
    kana_name: {
      half_upper: 'ｲﾜﾐｸﾞﾝ ｺｸﾌﾁﾖｳ',
      full_lower: 'イワミグン　コクフチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '31201鳥取市', other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '302',
    name: '岩美郡　岩美町',
    kana_name: {
      half_upper: 'ｲﾜﾐｸﾞﾝ ｲﾜﾐﾁﾖｳ',
      full_lower: 'イワミグン　イワミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '303',
    name: '岩美郡　福部村',
    kana_name: {
      half_upper: 'ｲﾜﾐｸﾞﾝ ﾌｸﾍﾞｿﾝ',
      full_lower: 'イワミグン　フクベソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '31201鳥取市', other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '320',
    name: '八頭郡',
    kana_name: { half_upper: 'ﾔｽﾞｸﾞﾝ', full_lower: 'ヤズグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '321',
    name: '八頭郡　郡家町',
    kana_name: {
      half_upper: 'ﾔｽﾞｸﾞﾝ ｺｵｹﾞﾁﾖｳ',
      full_lower: 'ヤズグン　コオゲチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '31329八頭郡　八頭町', other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '322',
    name: '八頭郡　船岡町',
    kana_name: {
      half_upper: 'ﾔｽﾞｸﾞﾝ ﾌﾅｵｶﾁﾖｳ',
      full_lower: 'ヤズグン　フナオカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '31329八頭郡　八頭町', other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '323',
    name: '八頭郡　河原町',
    kana_name: {
      half_upper: 'ﾔｽﾞｸﾞﾝ ｶﾜﾊﾗﾁﾖｳ',
      full_lower: 'ヤズグン　カワハラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '31201鳥取市', other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '324',
    name: '八頭郡　八東町',
    kana_name: {
      half_upper: 'ﾔｽﾞｸﾞﾝ ﾊﾂﾄｳﾁﾖｳ',
      full_lower: 'ヤズグン　ハットウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '31329八頭郡　八頭町', other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '325',
    name: '八頭郡　若桜町',
    kana_name: {
      half_upper: 'ﾔｽﾞｸﾞﾝ ﾜｶｻﾁﾖｳ',
      full_lower: 'ヤズグン　ワカサチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '326',
    name: '八頭郡　用瀬町',
    kana_name: {
      half_upper: 'ﾔｽﾞｸﾞﾝ ﾓﾁｶﾞｾﾁﾖｳ',
      full_lower: 'ヤズグン　モチガセチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '31201鳥取市', other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '327',
    name: '八頭郡　佐治村',
    kana_name: {
      half_upper: 'ﾔｽﾞｸﾞﾝ ｻｼﾞｿﾝ',
      full_lower: 'ヤズグン　サジソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '31201鳥取市', other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '328',
    name: '八頭郡　智頭町',
    kana_name: {
      half_upper: 'ﾔｽﾞｸﾞﾝ ﾁﾂﾞﾁﾖｳ',
      full_lower: 'ヤズグン　チヅチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '329',
    name: '八頭郡　八頭町',
    kana_name: {
      half_upper: 'ﾔｽﾞｸﾞﾝ ﾔｽﾞﾁﾖｳ',
      full_lower: 'ヤズグン　ヤズチョウ',
    },
    en: null,
    start_date: '2005-03-31',
    end_date: null,
    remark: { start: 'H17/03/31新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '340',
    name: '気高郡',
    kana_name: { half_upper: 'ｹﾀｶｸﾞﾝ', full_lower: 'ケタカグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '341',
    name: '気高郡　気高町',
    kana_name: {
      half_upper: 'ｹﾀｶｸﾞﾝ ｹﾀｶﾁﾖｳ',
      full_lower: 'ケタカグン　ケタカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '31201鳥取市', other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '342',
    name: '気高郡　鹿野町',
    kana_name: {
      half_upper: 'ｹﾀｶｸﾞﾝ ｼｶﾉﾁﾖｳ',
      full_lower: 'ケタカグン　シカノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '31201鳥取市', other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '343',
    name: '気高郡　青谷町',
    kana_name: {
      half_upper: 'ｹﾀｶｸﾞﾝ ｱｵﾔﾁﾖｳ',
      full_lower: 'ケタカグン　アオヤチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '31201鳥取市', other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '360',
    name: '東伯郡',
    kana_name: { half_upper: 'ﾄｳﾊｸｸﾞﾝ', full_lower: 'トウハクグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '361',
    name: '東伯郡　羽合町',
    kana_name: {
      half_upper: 'ﾄｳﾊｸｸﾞﾝ ﾊﾜｲﾁﾖｳ',
      full_lower: 'トウハクグン　ハワイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '31370東伯郡　湯梨浜町', other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '362',
    name: '東伯郡　泊村',
    kana_name: {
      half_upper: 'ﾄｳﾊｸｸﾞﾝ ﾄﾏﾘｿﾝ',
      full_lower: 'トウハクグン　トマリソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '31370東伯郡　湯梨浜町', other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '363',
    name: '東伯郡　東郷町',
    kana_name: {
      half_upper: 'ﾄｳﾊｸｸﾞﾝ ﾄｳｺﾞｳﾁﾖｳ',
      full_lower: 'トウハクグン　トウゴウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '31370東伯郡　湯梨浜町', other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '364',
    name: '東伯郡　三朝町',
    kana_name: {
      half_upper: 'ﾄｳﾊｸｸﾞﾝ ﾐｻｻﾁﾖｳ',
      full_lower: 'トウハクグン　ミササチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '365',
    name: '東伯郡　関金町',
    kana_name: {
      half_upper: 'ﾄｳﾊｸｸﾞﾝ ｾｷｶﾞﾈﾁﾖｳ',
      full_lower: 'トウハクグン　セキガネチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '31203倉吉市', other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '366',
    name: '東伯郡　北条町',
    kana_name: {
      half_upper: 'ﾄｳﾊｸｸﾞﾝ ﾎｳｼﾞﾖｳﾁﾖｳ',
      full_lower: 'トウハクグン　ホウジョウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '31372東伯郡　北栄町', other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '367',
    name: '東伯郡　大栄町',
    kana_name: {
      half_upper: 'ﾄｳﾊｸｸﾞﾝ ﾀﾞｲｴｲﾁﾖｳ',
      full_lower: 'トウハクグン　ダイエイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '31372東伯郡　北栄町', other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '368',
    name: '東伯郡　東伯町',
    kana_name: {
      half_upper: 'ﾄｳﾊｸｸﾞﾝ ﾄｳﾊｸﾁﾖｳ',
      full_lower: 'トウハクグン　トウハクチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-09-01',
    remark: { start: null, end: '31371東伯郡　琴浦町', other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '369',
    name: '東伯郡　赤碕町',
    kana_name: {
      half_upper: 'ﾄｳﾊｸｸﾞﾝ ｱｶｻｷﾁﾖｳ',
      full_lower: 'トウハクグン　アカサキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-09-01',
    remark: { start: null, end: '31371東伯郡　琴浦町', other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '370',
    name: '東伯郡　湯梨浜町',
    kana_name: {
      half_upper: 'ﾄｳﾊｸｸﾞﾝ ﾕﾘﾊﾏﾁﾖｳ',
      full_lower: 'トウハクグン　ユリハマチョウ',
    },
    en: null,
    start_date: '2004-10-01',
    end_date: null,
    remark: { start: 'H16/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '371',
    name: '東伯郡　琴浦町',
    kana_name: {
      half_upper: 'ﾄｳﾊｸｸﾞﾝ ｺﾄｳﾗﾁﾖｳ',
      full_lower: 'トウハクグン　コトウラチョウ',
    },
    en: null,
    start_date: '2004-09-01',
    end_date: null,
    remark: { start: 'H16/09/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '372',
    name: '東伯郡　北栄町',
    kana_name: {
      half_upper: 'ﾄｳﾊｸｸﾞﾝ ﾎｸｴｲﾁﾖｳ',
      full_lower: 'トウハクグン　ホクエイチョウ',
    },
    en: null,
    start_date: '2005-10-01',
    end_date: null,
    remark: { start: 'H17/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '380',
    name: '西伯郡',
    kana_name: { half_upper: 'ｻｲﾊｸｸﾞﾝ', full_lower: 'サイハクグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '381',
    name: '西伯郡　西伯町',
    kana_name: {
      half_upper: 'ｻｲﾊｸｸﾞﾝ ｻｲﾊｸﾁﾖｳ',
      full_lower: 'サイハクグン　サイハクチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '31389西伯郡　南部町', other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '382',
    name: '西伯郡　会見町',
    kana_name: {
      half_upper: 'ｻｲﾊｸｸﾞﾝ ｱｲﾐﾁﾖｳ',
      full_lower: 'サイハクグン　アイミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '31389西伯郡　南部町', other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '383',
    name: '西伯郡　岸本町',
    kana_name: {
      half_upper: 'ｻｲﾊｸｸﾞﾝ ｷｼﾓﾄﾁﾖｳ',
      full_lower: 'サイハクグン　キシモトチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '31390西伯郡　伯耆町', other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '384',
    name: '西伯郡　日吉津村',
    kana_name: {
      half_upper: 'ｻｲﾊｸｸﾞﾝ ﾋｴﾂﾞｿﾝ',
      full_lower: 'サイハクグン　ヒエヅソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '385',
    name: '西伯郡　淀江町',
    kana_name: {
      half_upper: 'ｻｲﾊｸｸﾞﾝ ﾖﾄﾞｴﾁﾖｳ',
      full_lower: 'サイハクグン　ヨドエチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '31202米子市', other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '386',
    name: '西伯郡　大山町',
    kana_name: {
      half_upper: 'ｻｲﾊｸｸﾞﾝ ﾀﾞｲｾﾝﾁﾖｳ',
      full_lower: 'サイハクグン　ダイセンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '387',
    name: '西伯郡　名和町',
    kana_name: {
      half_upper: 'ｻｲﾊｸｸﾞﾝ ﾅﾜﾁﾖｳ',
      full_lower: 'サイハクグン　ナワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '31386西伯郡　大山町', other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '388',
    name: '西伯郡　中山町',
    kana_name: {
      half_upper: 'ｻｲﾊｸｸﾞﾝ ﾅｶﾔﾏﾁﾖｳ',
      full_lower: 'サイハクグン　ナカヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '31386西伯郡　大山町', other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '389',
    name: '西伯郡　南部町',
    kana_name: {
      half_upper: 'ｻｲﾊｸｸﾞﾝ ﾅﾝﾌﾞﾁﾖｳ',
      full_lower: 'サイハクグン　ナンブチョウ',
    },
    en: null,
    start_date: '2004-10-01',
    end_date: null,
    remark: { start: 'H16/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '390',
    name: '西伯郡　伯耆町',
    kana_name: {
      half_upper: 'ｻｲﾊｸｸﾞﾝ ﾎｳｷﾁﾖｳ',
      full_lower: 'サイハクグン　ホウキチョウ',
    },
    en: null,
    start_date: '2005-01-01',
    end_date: null,
    remark: { start: 'H17/01/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '400',
    name: '日野郡',
    kana_name: { half_upper: 'ﾋﾉｸﾞﾝ', full_lower: 'ヒノグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '401',
    name: '日野郡　日南町',
    kana_name: {
      half_upper: 'ﾋﾉｸﾞﾝ ﾆﾁﾅﾝﾁﾖｳ',
      full_lower: 'ヒノグン　ニチナンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '402',
    name: '日野郡　日野町',
    kana_name: {
      half_upper: 'ﾋﾉｸﾞﾝ ﾋﾉﾁﾖｳ',
      full_lower: 'ヒノグン　ヒノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '403',
    name: '日野郡　江府町',
    kana_name: {
      half_upper: 'ﾋﾉｸﾞﾝ ｺｳﾌﾁﾖｳ',
      full_lower: 'ヒノグン　コウフチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '31',
    city_code: '404',
    name: '日野郡　溝口町',
    kana_name: {
      half_upper: 'ﾋﾉｸﾞﾝ ﾐｿﾞｸﾁﾁﾖｳ',
      full_lower: 'ヒノグン　ミゾクチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '31390西伯郡　伯耆町', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '201',
    name: '松江市',
    kana_name: { half_upper: 'ﾏﾂｴｼ', full_lower: 'マツエシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '202',
    name: '浜田市',
    kana_name: { half_upper: 'ﾊﾏﾀﾞｼ', full_lower: 'ハマダシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '203',
    name: '出雲市',
    kana_name: { half_upper: 'ｲｽﾞﾓｼ', full_lower: 'イズモシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '204',
    name: '益田市',
    kana_name: { half_upper: 'ﾏｽﾀﾞｼ', full_lower: 'マスダシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '205',
    name: '大田市',
    kana_name: { half_upper: 'ｵｵﾀﾞｼ', full_lower: 'オオダシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '206',
    name: '安来市',
    kana_name: { half_upper: 'ﾔｽｷﾞｼ', full_lower: 'ヤスギシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '207',
    name: '江津市',
    kana_name: { half_upper: 'ｺﾞｳﾂｼ', full_lower: 'ゴウツシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '208',
    name: '平田市',
    kana_name: { half_upper: 'ﾋﾗﾀｼ', full_lower: 'ヒラタシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '32203出雲市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '209',
    name: '雲南市',
    kana_name: { half_upper: 'ｳﾝﾅﾝｼ', full_lower: 'ウンナンシ' },
    en: null,
    start_date: '2004-11-01',
    end_date: null,
    remark: { start: 'H16/11/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '300',
    name: '八束郡',
    kana_name: { half_upper: 'ﾔﾂｶｸﾞﾝ', full_lower: 'ヤツカグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2011-08-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '301',
    name: '八束郡　鹿島町',
    kana_name: {
      half_upper: 'ﾔﾂｶｸﾞﾝ ｶｼﾏﾁﾖｳ',
      full_lower: 'ヤツカグン　カシマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '32201松江市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '302',
    name: '八束郡　島根町',
    kana_name: {
      half_upper: 'ﾔﾂｶｸﾞﾝ ｼﾏﾈﾁﾖｳ',
      full_lower: 'ヤツカグン　シマネチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '32201松江市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '303',
    name: '八束郡　美保関町',
    kana_name: {
      half_upper: 'ﾔﾂｶｸﾞﾝ ﾐﾎﾉｾｷﾁﾖｳ',
      full_lower: 'ヤツカグン　ミホノセキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '32201松江市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '304',
    name: '八束郡　東出雲町',
    kana_name: {
      half_upper: 'ﾔﾂｶｸﾞﾝ ﾋｶﾞｼｲｽﾞﾓﾁﾖｳ',
      full_lower: 'ヤツカグン　ヒガシイズモチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2011-08-01',
    remark: { start: null, end: '32201松江市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '305',
    name: '八束郡　八雲村',
    kana_name: {
      half_upper: 'ﾔﾂｶｸﾞﾝ ﾔｸﾓﾑﾗ',
      full_lower: 'ヤツカグン　ヤクモムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '32201松江市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '306',
    name: '八束郡　玉湯町',
    kana_name: {
      half_upper: 'ﾔﾂｶｸﾞﾝ ﾀﾏﾕﾁﾖｳ',
      full_lower: 'ヤツカグン　タマユチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '32201松江市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '307',
    name: '八束郡　宍道町',
    kana_name: {
      half_upper: 'ﾔﾂｶｸﾞﾝ ｼﾝｼﾞﾁﾖｳ',
      full_lower: 'ヤツカグン　シンジチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '32201松江市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '308',
    name: '八束郡　八束町',
    kana_name: {
      half_upper: 'ﾔﾂｶｸﾞﾝ ﾔﾂｶﾁﾖｳ',
      full_lower: 'ヤツカグン　ヤツカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '32201松江市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '320',
    name: '能義郡',
    kana_name: { half_upper: 'ﾉｷﾞｸﾞﾝ', full_lower: 'ノギグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '321',
    name: '能義郡　広瀬町',
    kana_name: {
      half_upper: 'ﾉｷﾞｸﾞﾝ ﾋﾛｾﾏﾁ',
      full_lower: 'ノギグン　ヒロセマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '32206安来市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '322',
    name: '能義郡　伯太町',
    kana_name: {
      half_upper: 'ﾉｷﾞｸﾞﾝ ﾊｸﾀﾁﾖｳ',
      full_lower: 'ノギグン　ハクタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '32206安来市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '340',
    name: '仁多郡',
    kana_name: { half_upper: 'ﾆﾀｸﾞﾝ', full_lower: 'ニタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '341',
    name: '仁多郡　仁多町',
    kana_name: {
      half_upper: 'ﾆﾀｸﾞﾝ ﾆﾀﾁﾖｳ',
      full_lower: 'ニタグン　ニタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '32343仁多郡　奥出雲町', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '342',
    name: '仁多郡　横田町',
    kana_name: {
      half_upper: 'ﾆﾀｸﾞﾝ ﾖｺﾀﾁﾖｳ',
      full_lower: 'ニタグン　ヨコタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '32343仁多郡　奥出雲町', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '343',
    name: '仁多郡　奥出雲町',
    kana_name: {
      half_upper: 'ﾆﾀｸﾞﾝ ｵｸｲｽﾞﾓﾁﾖｳ',
      full_lower: 'ニタグン　オクイズモチョウ',
    },
    en: null,
    start_date: '2005-03-31',
    end_date: null,
    remark: { start: 'H17/03/31新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '360',
    name: '大原郡',
    kana_name: { half_upper: 'ｵｵﾊﾗｸﾞﾝ', full_lower: 'オオハラグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '361',
    name: '大原郡　大東町',
    kana_name: {
      half_upper: 'ｵｵﾊﾗｸﾞﾝ ﾀﾞｲﾄｳﾁﾖｳ',
      full_lower: 'オオハラグン　ダイトウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '32209雲南市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '362',
    name: '大原郡　加茂町',
    kana_name: {
      half_upper: 'ｵｵﾊﾗｸﾞﾝ ｶﾓﾏﾁ',
      full_lower: 'オオハラグン　カモマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '32209雲南市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '363',
    name: '大原郡　木次町',
    kana_name: {
      half_upper: 'ｵｵﾊﾗｸﾞﾝ ｷｽｷﾁﾖｳ',
      full_lower: 'オオハラグン　キスキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '32209雲南市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '380',
    name: '飯石郡',
    kana_name: { half_upper: 'ｲｲｼｸﾞﾝ', full_lower: 'イイシグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '381',
    name: '飯石郡　三刀屋町',
    kana_name: {
      half_upper: 'ｲｲｼｸﾞﾝ ﾐﾄﾔﾁﾖｳ',
      full_lower: 'イイシグン　ミトヤチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '32209雲南市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '382',
    name: '飯石郡　吉田村',
    kana_name: {
      half_upper: 'ｲｲｼｸﾞﾝ ﾖｼﾀﾞﾑﾗ',
      full_lower: 'イイシグン　ヨシダムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '32209雲南市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '383',
    name: '飯石郡　掛合町',
    kana_name: {
      half_upper: 'ｲｲｼｸﾞﾝ ｶｹﾔﾏﾁ',
      full_lower: 'イイシグン　カケヤマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '32209雲南市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '384',
    name: '飯石郡　頓原町',
    kana_name: {
      half_upper: 'ｲｲｼｸﾞﾝ ﾄﾝﾊﾞﾗﾁﾖｳ',
      full_lower: 'イイシグン　トンバラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '32386飯石郡　飯南町', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '385',
    name: '飯石郡　赤来町',
    kana_name: {
      half_upper: 'ｲｲｼｸﾞﾝ ｱｶｷﾞﾁﾖｳ',
      full_lower: 'イイシグン　アカギチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '32386飯石郡　飯南町', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '386',
    name: '飯石郡　飯南町',
    kana_name: {
      half_upper: 'ｲｲｼｸﾞﾝ ｲｲﾅﾝﾁﾖｳ',
      full_lower: 'イイシグン　イイナンチョウ',
    },
    en: null,
    start_date: '2005-01-01',
    end_date: null,
    remark: { start: 'H17/01/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '400',
    name: '簸川郡',
    kana_name: { half_upper: 'ﾋｶﾜｸﾞﾝ', full_lower: 'ヒカワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2011-10-11',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '401',
    name: '簸川郡　斐川町',
    kana_name: {
      half_upper: 'ﾋｶﾜｸﾞﾝ ﾋｶﾜﾁﾖｳ',
      full_lower: 'ヒカワグン　ヒカワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2011-10-11',
    remark: { start: null, end: '32203出雲市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '402',
    name: '簸川郡　佐田町',
    kana_name: {
      half_upper: 'ﾋｶﾜｸﾞﾝ ｻﾀﾞﾁﾖｳ',
      full_lower: 'ヒカワグン　サダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '32203出雲市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '403',
    name: '簸川郡　多伎町',
    kana_name: {
      half_upper: 'ﾋｶﾜｸﾞﾝ ﾀｷﾁﾖｳ',
      full_lower: 'ヒカワグン　タキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '32203出雲市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '404',
    name: '簸川郡　湖陵町',
    kana_name: {
      half_upper: 'ﾋｶﾜｸﾞﾝ ｺﾘﾖｳﾁﾖｳ',
      full_lower: 'ヒカワグン　コリョウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '32203出雲市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '405',
    name: '簸川郡　大社町',
    kana_name: {
      half_upper: 'ﾋｶﾜｸﾞﾝ ﾀｲｼﾔﾏﾁ',
      full_lower: 'ヒカワグン　タイシャマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '32203出雲市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '420',
    name: '邇摩郡',
    kana_name: { half_upper: 'ﾆﾏｸﾞﾝ', full_lower: 'ニマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '421',
    name: '邇摩郡　温泉津町',
    kana_name: {
      half_upper: 'ﾆﾏｸﾞﾝ ﾕﾉﾂﾏﾁ',
      full_lower: 'ニマグン　ユノツマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '32205大田市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '422',
    name: '邇摩郡　仁摩町',
    kana_name: {
      half_upper: 'ﾆﾏｸﾞﾝ ﾆﾏﾁﾖｳ',
      full_lower: 'ニマグン　ニマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '32205大田市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '440',
    name: '邑智郡',
    kana_name: { half_upper: 'ｵｵﾁｸﾞﾝ', full_lower: 'オオチグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '441',
    name: '邑智郡　川本町',
    kana_name: {
      half_upper: 'ｵｵﾁｸﾞﾝ ｶﾜﾓﾄﾏﾁ',
      full_lower: 'オオチグン　カワモトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '442',
    name: '邑智郡　邑智町',
    kana_name: {
      half_upper: 'ｵｵﾁｸﾞﾝ ｵｵﾁﾁﾖｳ',
      full_lower: 'オオチグン　オオチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '32448邑智郡　美郷町', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '443',
    name: '邑智郡　大和村',
    kana_name: {
      half_upper: 'ｵｵﾁｸﾞﾝ ﾀﾞｲﾜﾑﾗ',
      full_lower: 'オオチグン　ダイワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '32448邑智郡　美郷町', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '444',
    name: '邑智郡　羽須美村',
    kana_name: {
      half_upper: 'ｵｵﾁｸﾞﾝ ﾊｽﾐﾑﾗ',
      full_lower: 'オオチグン　ハスミムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '32449邑智郡　邑南町', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '445',
    name: '邑智郡　瑞穂町',
    kana_name: {
      half_upper: 'ｵｵﾁｸﾞﾝ ﾐｽﾞﾎﾁﾖｳ',
      full_lower: 'オオチグン　ミズホチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '32449邑智郡　邑南町', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '446',
    name: '邑智郡　石見町',
    kana_name: {
      half_upper: 'ｵｵﾁｸﾞﾝ ｲﾜﾐﾁﾖｳ',
      full_lower: 'オオチグン　イワミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '32449邑智郡　邑南町', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '447',
    name: '邑智郡　桜江町',
    kana_name: {
      half_upper: 'ｵｵﾁｸﾞﾝ ｻｸﾗｴﾁﾖｳ',
      full_lower: 'オオチグン　サクラエチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '32207江津市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '448',
    name: '邑智郡　美郷町',
    kana_name: {
      half_upper: 'ｵｵﾁｸﾞﾝ ﾐｻﾄﾁﾖｳ',
      full_lower: 'オオチグン　ミサトチョウ',
    },
    en: null,
    start_date: '2004-10-01',
    end_date: null,
    remark: { start: 'H16/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '449',
    name: '邑智郡　邑南町',
    kana_name: {
      half_upper: 'ｵｵﾁｸﾞﾝ ｵｵﾅﾝﾁﾖｳ',
      full_lower: 'オオチグン　オオナンチョウ',
    },
    en: null,
    start_date: '2004-10-01',
    end_date: null,
    remark: { start: 'H16/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '460',
    name: '那賀郡',
    kana_name: { half_upper: 'ﾅｶｸﾞﾝ', full_lower: 'ナカグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '461',
    name: '那賀郡　国府町',
    kana_name: {
      half_upper: 'ﾅｶｸﾞﾝ ｺｸﾌﾏﾁ',
      full_lower: 'ナカグン　コクフマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1969-03-01',
    remark: { start: null, end: '32202浜田市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '462',
    name: '那賀郡　金城町',
    kana_name: {
      half_upper: 'ﾅｶｸﾞﾝ ｶﾅｷﾞﾁﾖｳ',
      full_lower: 'ナカグン　カナギチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '32202浜田市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '463',
    name: '那賀郡　旭町',
    kana_name: {
      half_upper: 'ﾅｶｸﾞﾝ ｱｻﾋﾁﾖｳ',
      full_lower: 'ナカグン　アサヒチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '32202浜田市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '464',
    name: '那賀郡　弥栄村',
    kana_name: {
      half_upper: 'ﾅｶｸﾞﾝ ﾔｻｶﾑﾗ',
      full_lower: 'ナカグン　ヤサカムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '32202浜田市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '465',
    name: '那賀郡　三隅町',
    kana_name: {
      half_upper: 'ﾅｶｸﾞﾝ ﾐｽﾐﾁﾖｳ',
      full_lower: 'ナカグン　ミスミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '32202浜田市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '480',
    name: '美濃郡',
    kana_name: { half_upper: 'ﾐﾉｸﾞﾝ', full_lower: 'ミノグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '481',
    name: '美濃郡　美都町',
    kana_name: {
      half_upper: 'ﾐﾉｸﾞﾝ ﾐﾄﾁﾖｳ',
      full_lower: 'ミノグン　ミトチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '32204益田市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '482',
    name: '美濃郡　匹見町',
    kana_name: {
      half_upper: 'ﾐﾉｸﾞﾝ ﾋｷﾐﾁﾖｳ',
      full_lower: 'ミノグン　ヒキミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '32204益田市', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '500',
    name: '鹿足郡',
    kana_name: { half_upper: 'ｶﾉｱｼｸﾞﾝ', full_lower: 'カノアシグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '501',
    name: '鹿足郡　津和野町',
    kana_name: {
      half_upper: 'ｶﾉｱｼｸﾞﾝ ﾂﾜﾉﾁﾖｳ',
      full_lower: 'カノアシグン　ツワノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '502',
    name: '鹿足郡　日原町',
    kana_name: {
      half_upper: 'ｶﾉｱｼｸﾞﾝ ﾆﾁﾊﾗﾁﾖｳ',
      full_lower: 'カノアシグン　ニチハラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-25',
    remark: { start: null, end: '32501鹿足郡　津和野町', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '503',
    name: '鹿足郡　柿木村',
    kana_name: {
      half_upper: 'ｶﾉｱｼｸﾞﾝ ｶｷﾉｷﾑﾗ',
      full_lower: 'カノアシグン　カキノキムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '32505鹿足郡　吉賀町', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '504',
    name: '鹿足郡　六日市町',
    kana_name: {
      half_upper: 'ｶﾉｱｼｸﾞﾝ ﾑｲｶｲﾁﾁﾖｳ',
      full_lower: 'カノアシグン　ムイカイチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '32505鹿足郡　吉賀町', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '505',
    name: '鹿足郡　吉賀町',
    kana_name: {
      half_upper: 'ｶﾉｱｼｸﾞﾝ ﾖｼｶﾁﾖｳ',
      full_lower: 'カノアシグン　ヨシカチョウ',
    },
    en: null,
    start_date: '2005-10-01',
    end_date: null,
    remark: { start: 'H17/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '520',
    name: '隠岐郡',
    kana_name: { half_upper: 'ｵｷｸﾞﾝ', full_lower: 'オキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '521',
    name: '隠岐郡　西郷町',
    kana_name: {
      half_upper: 'ｵｷｸﾞﾝ ｻｲｺﾞｳﾁﾖｳ',
      full_lower: 'オキグン　サイゴウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '32528隠岐郡　隠岐の島町', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '522',
    name: '隠岐郡　布施村',
    kana_name: { half_upper: 'ｵｷｸﾞﾝ ﾌｾﾑﾗ', full_lower: 'オキグン　フセムラ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '32528隠岐郡　隠岐の島町', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '523',
    name: '隠岐郡　五箇村',
    kana_name: {
      half_upper: 'ｵｷｸﾞﾝ ｺﾞｶﾑﾗ',
      full_lower: 'オキグン　ゴカムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '32528隠岐郡　隠岐の島町', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '524',
    name: '隠岐郡　都万村',
    kana_name: { half_upper: 'ｵｷｸﾞﾝ ﾂﾏﾑﾗ', full_lower: 'オキグン　ツマムラ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '32528隠岐郡　隠岐の島町', other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '525',
    name: '隠岐郡　海士町',
    kana_name: {
      half_upper: 'ｵｷｸﾞﾝ ｱﾏﾁﾖｳ',
      full_lower: 'オキグン　アマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '526',
    name: '隠岐郡　西ノ島町',
    kana_name: {
      half_upper: 'ｵｷｸﾞﾝ ﾆｼﾉｼﾏﾁﾖｳ',
      full_lower: 'オキグン　ニシノシマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '527',
    name: '隠岐郡　知夫村',
    kana_name: {
      half_upper: 'ｵｷｸﾞﾝ ﾁﾌﾞﾑﾗ',
      full_lower: 'オキグン　チブムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '32',
    city_code: '528',
    name: '隠岐郡　隠岐の島町',
    kana_name: {
      half_upper: 'ｵｷｸﾞﾝ ｵｷﾉｼﾏﾁﾖｳ',
      full_lower: 'オキグン　オキノシマチョウ',
    },
    en: null,
    start_date: '2004-10-01',
    end_date: null,
    remark: { start: 'H16/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '100',
    name: '岡山市',
    kana_name: { half_upper: 'ｵｶﾔﾏｼ', full_lower: 'オカヤマシ' },
    en: null,
    start_date: '2009-04-01',
    end_date: null,
    remark: { start: 'H21/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '101',
    name: '岡山市　北区',
    kana_name: { half_upper: 'ｵｶﾔﾏｼ ｷﾀｸ', full_lower: 'オカヤマシ　キタク' },
    en: null,
    start_date: '2009-04-01',
    end_date: null,
    remark: { start: 'H21/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '102',
    name: '岡山市　中区',
    kana_name: { half_upper: 'ｵｶﾔﾏｼ ﾅｶｸ', full_lower: 'オカヤマシ　ナカク' },
    en: null,
    start_date: '2009-04-01',
    end_date: null,
    remark: { start: 'H21/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '103',
    name: '岡山市　東区',
    kana_name: {
      half_upper: 'ｵｶﾔﾏｼ ﾋｶﾞｼｸ',
      full_lower: 'オカヤマシ　ヒガシク',
    },
    en: null,
    start_date: '2009-04-01',
    end_date: null,
    remark: { start: 'H21/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '104',
    name: '岡山市　南区',
    kana_name: {
      half_upper: 'ｵｶﾔﾏｼ ﾐﾅﾐｸ',
      full_lower: 'オカヤマシ　ミナミク',
    },
    en: null,
    start_date: '2009-04-01',
    end_date: null,
    remark: { start: 'H21/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '201',
    name: '岡山市',
    kana_name: { half_upper: 'ｵｶﾔﾏｼ', full_lower: 'オカヤマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2009-04-01',
    remark: { start: null, end: '33100岡山市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '202',
    name: '倉敷市',
    kana_name: { half_upper: 'ｸﾗｼｷｼ', full_lower: 'クラシキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '203',
    name: '津山市',
    kana_name: { half_upper: 'ﾂﾔﾏｼ', full_lower: 'ツヤマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '204',
    name: '玉野市',
    kana_name: { half_upper: 'ﾀﾏﾉｼ', full_lower: 'タマノシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '205',
    name: '笠岡市',
    kana_name: { half_upper: 'ｶｻｵｶｼ', full_lower: 'カサオカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '206',
    name: '西大寺市',
    kana_name: { half_upper: 'ｻｲﾀﾞｲｼﾞｼ', full_lower: 'サイダイジシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '1969-02-18',
    remark: { start: null, end: '33201岡山市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '207',
    name: '井原市',
    kana_name: { half_upper: 'ｲﾊﾞﾗｼ', full_lower: 'イバラシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '208',
    name: '総社市',
    kana_name: { half_upper: 'ｿｳｼﾞﾔｼ', full_lower: 'ソウジャシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '209',
    name: '高梁市',
    kana_name: { half_upper: 'ﾀｶﾊｼｼ', full_lower: 'タカハシシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '210',
    name: '新見市',
    kana_name: { half_upper: 'ﾆｲﾐｼ', full_lower: 'ニイミシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '211',
    name: '備前市',
    kana_name: { half_upper: 'ﾋﾞｾﾞﾝｼ', full_lower: 'ビゼンシ' },
    en: null,
    start_date: '1971-04-01',
    end_date: null,
    remark: { start: 'S46/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '212',
    name: '瀬戸内市',
    kana_name: { half_upper: 'ｾﾄｳﾁｼ', full_lower: 'セトウチシ' },
    en: null,
    start_date: '2004-11-01',
    end_date: null,
    remark: { start: 'H16/11/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '213',
    name: '赤磐市',
    kana_name: { half_upper: 'ｱｶｲﾜｼ', full_lower: 'アカイワシ' },
    en: null,
    start_date: '2005-03-07',
    end_date: null,
    remark: { start: 'H17/03/07新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '214',
    name: '真庭市',
    kana_name: { half_upper: 'ﾏﾆﾜｼ', full_lower: 'マニワシ' },
    en: null,
    start_date: '2005-03-31',
    end_date: null,
    remark: { start: 'H17/03/31新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '215',
    name: '美作市',
    kana_name: { half_upper: 'ﾐﾏｻｶｼ', full_lower: 'ミマサカシ' },
    en: null,
    start_date: '2005-03-31',
    end_date: null,
    remark: { start: 'H17/03/31新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '216',
    name: '浅口市',
    kana_name: { half_upper: 'ｱｻｸﾁｼ', full_lower: 'アサクチシ' },
    en: null,
    start_date: '2006-03-21',
    end_date: null,
    remark: { start: 'H18/03/21新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '300',
    name: '御津郡',
    kana_name: { half_upper: 'ﾐﾂｸﾞﾝ', full_lower: 'ミツグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-01-22',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '301',
    name: '御津郡　御津町',
    kana_name: {
      half_upper: 'ﾐﾂｸﾞﾝ ﾐﾂﾁﾖｳ',
      full_lower: 'ミツグン　ミツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '33201岡山市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '302',
    name: '御津郡　一宮町',
    kana_name: {
      half_upper: 'ﾐﾂｸﾞﾝ ｲﾁﾉﾐﾔﾁﾖｳ',
      full_lower: 'ミツグン　イチノミヤチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-01-08',
    remark: { start: null, end: '33201岡山市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '303',
    name: '御津郡　建部町',
    kana_name: {
      half_upper: 'ﾐﾂｸﾞﾝ ﾀｹﾍﾞﾁﾖｳ',
      full_lower: 'ミツグン　タケベチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-01-22',
    remark: { start: null, end: '33201岡山市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '304',
    name: '御津郡　津高町',
    kana_name: {
      half_upper: 'ﾐﾂｸﾞﾝ ﾂﾀﾞｶﾁﾖｳ',
      full_lower: 'ミツグン　ツダカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-01-08',
    remark: { start: null, end: '33201岡山市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '305',
    name: '御津郡　加茂川町',
    kana_name: {
      half_upper: 'ﾐﾂｸﾞﾝ ｶﾓｶﾞﾜﾁﾖｳ',
      full_lower: 'ミツグン　カモガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '33681加賀郡　吉備中央町', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '320',
    name: '赤磐郡',
    kana_name: { half_upper: 'ｱｶｲﾜｸﾞﾝ', full_lower: 'アカイワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-01-22',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '321',
    name: '赤磐郡　瀬戸町',
    kana_name: {
      half_upper: 'ｱｶｲﾜｸﾞﾝ ｾﾄﾁﾖｳ',
      full_lower: 'アカイワグン　セトチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-01-22',
    remark: { start: null, end: '33201岡山市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '322',
    name: '赤磐郡　山陽町',
    kana_name: {
      half_upper: 'ｱｶｲﾜｸﾞﾝ ｻﾝﾖｳﾁﾖｳ',
      full_lower: 'アカイワグン　サンヨウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-07',
    remark: { start: null, end: '33213赤磐市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '323',
    name: '赤磐郡　赤坂町',
    kana_name: {
      half_upper: 'ｱｶｲﾜｸﾞﾝ ｱｶｻｶﾁﾖｳ',
      full_lower: 'アカイワグン　アカサカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-07',
    remark: { start: null, end: '33213赤磐市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '324',
    name: '赤磐郡　熊山町',
    kana_name: {
      half_upper: 'ｱｶｲﾜｸﾞﾝ ｸﾏﾔﾏﾁﾖｳ',
      full_lower: 'アカイワグン　クマヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-07',
    remark: { start: null, end: '33213赤磐市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '325',
    name: '赤磐郡　吉井町',
    kana_name: {
      half_upper: 'ｱｶｲﾜｸﾞﾝ ﾖｼｲﾁﾖｳ',
      full_lower: 'アカイワグン　ヨシイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-07',
    remark: { start: null, end: '33213赤磐市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '340',
    name: '和気郡',
    kana_name: { half_upper: 'ﾜｹｸﾞﾝ', full_lower: 'ワケグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '341',
    name: '和気郡　備前町',
    kana_name: {
      half_upper: 'ﾜｹｸﾞﾝ ﾋﾞｾﾞﾝﾁﾖｳ',
      full_lower: 'ワケグン　ビゼンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-04-01',
    remark: { start: null, end: '33211備前市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '342',
    name: '和気郡　日生町',
    kana_name: {
      half_upper: 'ﾜｹｸﾞﾝ ﾋﾅｾﾁﾖｳ',
      full_lower: 'ワケグン　ヒナセチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '33211備前市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '343',
    name: '和気郡　三石町',
    kana_name: {
      half_upper: 'ﾜｹｸﾞﾝ ﾐﾂｲｼﾁﾖｳ',
      full_lower: 'ワケグン　ミツイシチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-04-01',
    remark: { start: null, end: '33211備前市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '344',
    name: '和気郡　吉永町',
    kana_name: {
      half_upper: 'ﾜｹｸﾞﾝ ﾖｼﾅｶﾞﾁﾖｳ',
      full_lower: 'ワケグン　ヨシナガチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '33211備前市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '345',
    name: '和気郡　佐伯町',
    kana_name: {
      half_upper: 'ﾜｹｸﾞﾝ ｻｴｷﾁﾖｳ',
      full_lower: 'ワケグン　サエキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '33346和気郡　和気町', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '346',
    name: '和気郡　和気町',
    kana_name: {
      half_upper: 'ﾜｹｸﾞﾝ ﾜｹﾁﾖｳ',
      full_lower: 'ワケグン　ワケチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '360',
    name: '邑久郡',
    kana_name: { half_upper: 'ｵｸｸﾞﾝ', full_lower: 'オクグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '361',
    name: '邑久郡　牛窓町',
    kana_name: {
      half_upper: 'ｵｸｸﾞﾝ ｳｼﾏﾄﾞﾁﾖｳ',
      full_lower: 'オクグン　ウシマドチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '33212瀬戸内市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '362',
    name: '邑久郡　邑久町',
    kana_name: {
      half_upper: 'ｵｸｸﾞﾝ ｵｸﾁﾖｳ',
      full_lower: 'オクグン　オクチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '33212瀬戸内市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '363',
    name: '邑久郡　長船町',
    kana_name: {
      half_upper: 'ｵｸｸﾞﾝ ｵｻﾌﾈﾁﾖｳ',
      full_lower: 'オクグン　オサフネチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '33212瀬戸内市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '380',
    name: '上道郡',
    kana_name: { half_upper: 'ｼﾞﾖｳﾄｳｸﾞﾝ', full_lower: 'ジョウトウグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-05-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '381',
    name: '上道郡　上道町',
    kana_name: {
      half_upper: 'ｼﾞﾖｳﾄｳｸﾞﾝ ｼﾞﾖｳﾄｳﾁﾖｳ',
      full_lower: 'ジョウトウグン　ジョウトウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-05-01',
    remark: { start: null, end: '33201岡山市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '400',
    name: '児島郡',
    kana_name: { half_upper: 'ｺｼﾞﾏｸﾞﾝ', full_lower: 'コジマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '401',
    name: '児島郡　灘崎町',
    kana_name: {
      half_upper: 'ｺｼﾞﾏｸﾞﾝ ﾅﾀﾞｻｷﾁﾖｳ',
      full_lower: 'コジマグン　ナダサキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '33201岡山市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '402',
    name: '児島郡　興除村',
    kana_name: {
      half_upper: 'ｺｼﾞﾏｸﾞﾝ ｺｳｼﾞﾖｿﾝ',
      full_lower: 'コジマグン　コウジョソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-05-01',
    remark: { start: null, end: '33201岡山市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '403',
    name: '児島郡　藤田村',
    kana_name: {
      half_upper: 'ｺｼﾞﾏｸﾞﾝ ﾌｼﾞﾀｿﾝ',
      full_lower: 'コジマグン　フジタソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1975-05-01',
    remark: { start: null, end: '33201岡山市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '404',
    name: '児島郡　東児町',
    kana_name: {
      half_upper: 'ｺｼﾞﾏｸﾞﾝ ﾄｳｼﾞﾁﾖｳ',
      full_lower: 'コジマグン　トウジチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1974-03-20',
    remark: { start: null, end: '33204玉野市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '420',
    name: '都窪郡',
    kana_name: { half_upper: 'ﾂｸﾎﾞｸﾞﾝ', full_lower: 'ツクボグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '421',
    name: '都窪郡　吉備町',
    kana_name: {
      half_upper: 'ﾂｸﾎﾞｸﾞﾝ ｷﾋﾞﾁﾖｳ',
      full_lower: 'ツクボグン　キビチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-03-08',
    remark: { start: null, end: '33201岡山市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '422',
    name: '都窪郡　茶屋町',
    kana_name: {
      half_upper: 'ﾂｸﾎﾞｸﾞﾝ ﾁﾔﾔﾁﾖｳ',
      full_lower: 'ツクボグン　チャヤチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-05-01',
    remark: { start: null, end: '33202倉敷市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '423',
    name: '都窪郡　早島町',
    kana_name: {
      half_upper: 'ﾂｸﾎﾞｸﾞﾝ ﾊﾔｼﾏﾁﾖｳ',
      full_lower: 'ツクボグン　ハヤシマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '424',
    name: '都窪郡　妹尾町',
    kana_name: {
      half_upper: 'ﾂｸﾎﾞｸﾞﾝ ｾﾉｵﾁﾖｳ',
      full_lower: 'ツクボグン　セノオチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-03-08',
    remark: { start: null, end: '33201岡山市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '425',
    name: '都窪郡　庄村',
    kana_name: {
      half_upper: 'ﾂｸﾎﾞｸﾞﾝ ｼﾖｳｿﾝ',
      full_lower: 'ツクボグン　ショウソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-03-08',
    remark: { start: null, end: '33202倉敷市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '426',
    name: '都窪郡　福田村',
    kana_name: {
      half_upper: 'ﾂｸﾎﾞｸﾞﾝ ﾌｸﾀﾞｿﾝ',
      full_lower: 'ツクボグン　フクダソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-03-08',
    remark: { start: null, end: '33201岡山市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '427',
    name: '都窪郡　山手村',
    kana_name: {
      half_upper: 'ﾂｸﾎﾞｸﾞﾝ ﾔﾏﾃｿﾝ',
      full_lower: 'ツクボグン　ヤマテソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '33208総社市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '428',
    name: '都窪郡　清音村',
    kana_name: {
      half_upper: 'ﾂｸﾎﾞｸﾞﾝ ｷﾖﾈｿﾝ',
      full_lower: 'ツクボグン　キヨネソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '33208総社市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '440',
    name: '浅口郡',
    kana_name: { half_upper: 'ｱｻｸﾁｸﾞﾝ', full_lower: 'アサクチグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '441',
    name: '浅口郡　船穂町',
    kana_name: {
      half_upper: 'ｱｻｸﾁｸﾞﾝ ﾌﾅｵﾁﾖｳ',
      full_lower: 'アサクチグン　フナオチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-08-01',
    remark: { start: null, end: '33202倉敷市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '442',
    name: '浅口郡　金光町',
    kana_name: {
      half_upper: 'ｱｻｸﾁｸﾞﾝ ｺﾝｺｳﾁﾖｳ',
      full_lower: 'アサクチグン　コンコウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-21',
    remark: { start: null, end: '33216浅口市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '443',
    name: '浅口郡　鴨方町',
    kana_name: {
      half_upper: 'ｱｻｸﾁｸﾞﾝ ｶﾓｶﾞﾀﾁﾖｳ',
      full_lower: 'アサクチグン　カモガタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-21',
    remark: { start: null, end: '33216浅口市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '444',
    name: '浅口郡　寄島町',
    kana_name: {
      half_upper: 'ｱｻｸﾁｸﾞﾝ ﾖﾘｼﾏﾁﾖｳ',
      full_lower: 'アサクチグン　ヨリシマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-21',
    remark: { start: null, end: '33216浅口市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '445',
    name: '浅口郡　里庄町',
    kana_name: {
      half_upper: 'ｱｻｸﾁｸﾞﾝ ｻﾄｼﾖｳﾁﾖｳ',
      full_lower: 'アサクチグン　サトショウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '460',
    name: '小田郡',
    kana_name: { half_upper: 'ｵﾀﾞｸﾞﾝ', full_lower: 'オダグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '461',
    name: '小田郡　矢掛町',
    kana_name: {
      half_upper: 'ｵﾀﾞｸﾞﾝ ﾔｶｹﾞﾁﾖｳ',
      full_lower: 'オダグン　ヤカゲチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '462',
    name: '小田郡　美星町',
    kana_name: {
      half_upper: 'ｵﾀﾞｸﾞﾝ ﾋﾞｾｲﾁﾖｳ',
      full_lower: 'オダグン　ビセイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '33207井原市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '480',
    name: '後月郡',
    kana_name: { half_upper: 'ｼﾂｷｸﾞﾝ', full_lower: 'シツキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '481',
    name: '後月郡　芳井町',
    kana_name: {
      half_upper: 'ｼﾂｷｸﾞﾝ ﾖｼｲﾁﾖｳ',
      full_lower: 'シツキグン　ヨシイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '33207井原市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '500',
    name: '吉備郡',
    kana_name: { half_upper: 'ｷﾋﾞｸﾞﾝ', full_lower: 'キビグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-08-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '501',
    name: '吉備郡　高松町',
    kana_name: {
      half_upper: 'ｷﾋﾞｸﾞﾝ ﾀｶﾏﾂﾁﾖｳ',
      full_lower: 'キビグン　タカマツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-01-08',
    remark: { start: null, end: '33201岡山市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '502',
    name: '吉備郡　足守町',
    kana_name: {
      half_upper: 'ｷﾋﾞｸﾞﾝ ｱｼﾓﾘﾁﾖｳ',
      full_lower: 'キビグン　アシモリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-05-01',
    remark: { start: null, end: '33201岡山市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '503',
    name: '吉備郡　真備町',
    kana_name: {
      half_upper: 'ｷﾋﾞｸﾞﾝ ﾏﾋﾞﾁﾖｳ',
      full_lower: 'キビグン　マビチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-08-01',
    remark: { start: null, end: '33202倉敷市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '504',
    name: '吉備郡　昭和町',
    kana_name: {
      half_upper: 'ｷﾋﾞｸﾞﾝ ｼﾖｳﾜﾁﾖｳ',
      full_lower: 'キビグン　ショウワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-04-22',
    remark: { start: null, end: '33208総社市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '520',
    name: '上房郡',
    kana_name: { half_upper: 'ｼﾞﾖｳﾎﾞｳｸﾞﾝ', full_lower: 'ジョウボウグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '521',
    name: '上房郡　有漢町',
    kana_name: {
      half_upper: 'ｼﾞﾖｳﾎﾞｳｸﾞﾝ ｳｶﾝﾁﾖｳ',
      full_lower: 'ジョウボウグン　ウカンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '33209高梁市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '522',
    name: '上房郡　北房町',
    kana_name: {
      half_upper: 'ｼﾞﾖｳﾎﾞｳｸﾞﾝ ﾎｸﾎﾞｳﾁﾖｳ',
      full_lower: 'ジョウボウグン　ホクボウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '33214真庭市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '523',
    name: '上房郡　賀陽町',
    kana_name: {
      half_upper: 'ｼﾞﾖｳﾎﾞｳｸﾞﾝ ｶﾖｳﾁﾖｳ',
      full_lower: 'ジョウボウグン　カヨウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '33681加賀郡　吉備中央町', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '540',
    name: '川上郡',
    kana_name: { half_upper: 'ｶﾜｶﾐｸﾞﾝ', full_lower: 'カワカミグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '541',
    name: '川上郡　成羽町',
    kana_name: {
      half_upper: 'ｶﾜｶﾐｸﾞﾝ ﾅﾘﾜﾁﾖｳ',
      full_lower: 'カワカミグン　ナリワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '33209高梁市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '542',
    name: '川上郡　川上町',
    kana_name: {
      half_upper: 'ｶﾜｶﾐｸﾞﾝ ｶﾜｶﾐﾁﾖｳ',
      full_lower: 'カワカミグン　カワカミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '33209高梁市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '543',
    name: '川上郡　備中町',
    kana_name: {
      half_upper: 'ｶﾜｶﾐｸﾞﾝ ﾋﾞﾂﾁﾕｳﾁﾖｳ',
      full_lower: 'カワカミグン　ビッチュウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '33209高梁市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '560',
    name: '阿哲郡',
    kana_name: { half_upper: 'ｱﾃﾂｸﾞﾝ', full_lower: 'アテツグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '561',
    name: '阿哲郡　大佐町',
    kana_name: {
      half_upper: 'ｱﾃﾂｸﾞﾝ ｵｵｻﾁﾖｳ',
      full_lower: 'アテツグン　オオサチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '33210新見市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '562',
    name: '阿哲郡　神郷町',
    kana_name: {
      half_upper: 'ｱﾃﾂｸﾞﾝ ｼﾝｺﾞｳﾁﾖｳ',
      full_lower: 'アテツグン　シンゴウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '33210新見市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '563',
    name: '阿哲郡　哲多町',
    kana_name: {
      half_upper: 'ｱﾃﾂｸﾞﾝ ﾃﾂﾀﾁﾖｳ',
      full_lower: 'アテツグン　テッタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '33210新見市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '564',
    name: '阿哲郡　哲西町',
    kana_name: {
      half_upper: 'ｱﾃﾂｸﾞﾝ ﾃﾂｾｲﾁﾖｳ',
      full_lower: 'アテツグン　テッセイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '33210新見市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '580',
    name: '真庭郡',
    kana_name: { half_upper: 'ﾏﾆﾜｸﾞﾝ', full_lower: 'マニワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '581',
    name: '真庭郡　勝山町',
    kana_name: {
      half_upper: 'ﾏﾆﾜｸﾞﾝ ｶﾂﾔﾏﾁﾖｳ',
      full_lower: 'マニワグン　カツヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '33214真庭市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '582',
    name: '真庭郡　落合町',
    kana_name: {
      half_upper: 'ﾏﾆﾜｸﾞﾝ ｵﾁｱｲﾁﾖｳ',
      full_lower: 'マニワグン　オチアイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '33214真庭市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '583',
    name: '真庭郡　湯原町',
    kana_name: {
      half_upper: 'ﾏﾆﾜｸﾞﾝ ﾕﾊﾞﾗﾁﾖｳ',
      full_lower: 'マニワグン　ユバラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '33214真庭市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '584',
    name: '真庭郡　久世町',
    kana_name: {
      half_upper: 'ﾏﾆﾜｸﾞﾝ ｸｾﾁﾖｳ',
      full_lower: 'マニワグン　クセチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '33214真庭市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '585',
    name: '真庭郡　美甘村',
    kana_name: {
      half_upper: 'ﾏﾆﾜｸﾞﾝ ﾐｶﾓｿﾝ',
      full_lower: 'マニワグン　ミカモソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '33214真庭市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '586',
    name: '真庭郡　新庄村',
    kana_name: {
      half_upper: 'ﾏﾆﾜｸﾞﾝ ｼﾝｼﾞﾖｳｿﾝ',
      full_lower: 'マニワグン　シンジョウソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '587',
    name: '真庭郡　川上村',
    kana_name: {
      half_upper: 'ﾏﾆﾜｸﾞﾝ ｶﾜｶﾐｿﾝ',
      full_lower: 'マニワグン　カワカミソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '33214真庭市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '588',
    name: '真庭郡　八束村',
    kana_name: {
      half_upper: 'ﾏﾆﾜｸﾞﾝ ﾔﾂｶｿﾝ',
      full_lower: 'マニワグン　ヤツカソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '33214真庭市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '589',
    name: '真庭郡　中和村',
    kana_name: {
      half_upper: 'ﾏﾆﾜｸﾞﾝ ﾁﾕｳｶｿﾝ',
      full_lower: 'マニワグン　チュウカソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '33214真庭市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '600',
    name: '苫田郡',
    kana_name: { half_upper: 'ﾄﾏﾀｸﾞﾝ', full_lower: 'トマタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '601',
    name: '苫田郡　加茂町',
    kana_name: {
      half_upper: 'ﾄﾏﾀｸﾞﾝ ｶﾓﾁﾖｳ',
      full_lower: 'トマタグン　カモチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-28',
    remark: { start: null, end: '33203津山市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '602',
    name: '苫田郡　富村',
    kana_name: {
      half_upper: 'ﾄﾏﾀｸﾞﾝ ﾄﾐｿﾝ',
      full_lower: 'トマタグン　トミソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '33606苫田郡　鏡野町', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '603',
    name: '苫田郡　奥津町',
    kana_name: {
      half_upper: 'ﾄﾏﾀｸﾞﾝ ｵｸﾂﾁﾖｳ',
      full_lower: 'トマタグン　オクツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '33606苫田郡　鏡野町', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '604',
    name: '苫田郡　上齋原村',
    kana_name: {
      half_upper: 'ﾄﾏﾀｸﾞﾝ ｶﾐｻｲﾊﾞﾗｿﾝ',
      full_lower: 'トマタグン　カミサイバラソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '33606苫田郡　鏡野町', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '605',
    name: '苫田郡　阿波村',
    kana_name: {
      half_upper: 'ﾄﾏﾀｸﾞﾝ ｱﾊﾞｿﾝ',
      full_lower: 'トマタグン　アバソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-28',
    remark: { start: null, end: '33203津山市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '606',
    name: '苫田郡　鏡野町',
    kana_name: {
      half_upper: 'ﾄﾏﾀｸﾞﾝ ｶｶﾞﾐﾉﾁﾖｳ',
      full_lower: 'トマタグン　カガミノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '620',
    name: '勝田郡',
    kana_name: { half_upper: 'ｶﾂﾀｸﾞﾝ', full_lower: 'カツタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '621',
    name: '勝田郡　勝田町',
    kana_name: {
      half_upper: 'ｶﾂﾀｸﾞﾝ ｶﾂﾀﾁﾖｳ',
      full_lower: 'カツタグン　カツタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '33215美作市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '622',
    name: '勝田郡　勝央町',
    kana_name: {
      half_upper: 'ｶﾂﾀｸﾞﾝ ｼﾖｳｵｳﾁﾖｳ',
      full_lower: 'カツタグン　ショウオウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '623',
    name: '勝田郡　奈義町',
    kana_name: {
      half_upper: 'ｶﾂﾀｸﾞﾝ ﾅｷﾞﾁﾖｳ',
      full_lower: 'カツタグン　ナギチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '624',
    name: '勝田郡　勝北町',
    kana_name: {
      half_upper: 'ｶﾂﾀｸﾞﾝ ｼﾖｳﾎﾞｸﾁﾖｳ',
      full_lower: 'カツタグン　ショウボクチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-28',
    remark: { start: null, end: '33203津山市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '640',
    name: '英田郡',
    kana_name: { half_upper: 'ｱｲﾀﾞｸﾞﾝ', full_lower: 'アイダグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '641',
    name: '英田郡　大原町',
    kana_name: {
      half_upper: 'ｱｲﾀﾞｸﾞﾝ ｵｵﾊﾗﾁﾖｳ',
      full_lower: 'アイダグン　オオハラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '33215美作市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '642',
    name: '英田郡　東粟倉村',
    kana_name: {
      half_upper: 'ｱｲﾀﾞｸﾞﾝ ﾋｶﾞｼｱﾜｸﾗｿﾝ',
      full_lower: 'アイダグン　ヒガシアワクラソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '33215美作市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '643',
    name: '英田郡　西粟倉村',
    kana_name: {
      half_upper: 'ｱｲﾀﾞｸﾞﾝ ﾆｼｱﾜｸﾗｿﾝ',
      full_lower: 'アイダグン　ニシアワクラソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '644',
    name: '英田郡　美作町',
    kana_name: {
      half_upper: 'ｱｲﾀﾞｸﾞﾝ ﾐﾏｻｶﾁﾖｳ',
      full_lower: 'アイダグン　ミマサカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '33215美作市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '645',
    name: '英田郡　作東町',
    kana_name: {
      half_upper: 'ｱｲﾀﾞｸﾞﾝ ｻｸﾄｳﾁﾖｳ',
      full_lower: 'アイダグン　サクトウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '33215美作市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '646',
    name: '英田郡　英田町',
    kana_name: {
      half_upper: 'ｱｲﾀﾞｸﾞﾝ ｱｲﾀﾞﾁﾖｳ',
      full_lower: 'アイダグン　アイダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '33215美作市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '660',
    name: '久米郡',
    kana_name: { half_upper: 'ｸﾒｸﾞﾝ', full_lower: 'クメグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '661',
    name: '久米郡　中央町',
    kana_name: {
      half_upper: 'ｸﾒｸﾞﾝ ﾁﾕｳｵｳﾁﾖｳ',
      full_lower: 'クメグン　チュウオウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '33666久米郡　美咲町', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '662',
    name: '久米郡　旭町',
    kana_name: {
      half_upper: 'ｸﾒｸﾞﾝ ｱｻﾋﾁﾖｳ',
      full_lower: 'クメグン　アサヒチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '33666久米郡　美咲町', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '663',
    name: '久米郡　久米南町',
    kana_name: {
      half_upper: 'ｸﾒｸﾞﾝ ｸﾒﾅﾝﾁﾖｳ',
      full_lower: 'クメグン　クメナンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '664',
    name: '久米郡　久米町',
    kana_name: {
      half_upper: 'ｸﾒｸﾞﾝ ｸﾒﾁﾖｳ',
      full_lower: 'クメグン　クメチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-28',
    remark: { start: null, end: '33203津山市', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '665',
    name: '久米郡　柵原町',
    kana_name: {
      half_upper: 'ｸﾒｸﾞﾝ ﾔﾅﾊﾗﾁﾖｳ',
      full_lower: 'クメグン　ヤナハラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '33666久米郡　美咲町', other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '666',
    name: '久米郡　美咲町',
    kana_name: {
      half_upper: 'ｸﾒｸﾞﾝ ﾐｻｷﾁﾖｳ',
      full_lower: 'クメグン　ミサキチョウ',
    },
    en: null,
    start_date: '2005-03-22',
    end_date: null,
    remark: { start: 'H17/03/22新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '680',
    name: '加賀郡',
    kana_name: { half_upper: 'ｶｶﾞｸﾞﾝ', full_lower: 'カガグン' },
    en: null,
    start_date: '2004-10-01',
    end_date: null,
    remark: {
      start: 'H16/10/01新設(上房郡・御津郡)',
      end: null,
      other: null,
    },
    note: null,
  },
  {
    pref_code: '33',
    city_code: '681',
    name: '加賀郡　吉備中央町',
    kana_name: {
      half_upper: 'ｶｶﾞｸﾞﾝ ｷﾋﾞﾁﾕｳｵｳﾁﾖｳ',
      full_lower: 'カガグン　キビチュウオウチョウ',
    },
    en: null,
    start_date: '2004-10-01',
    end_date: null,
    remark: { start: 'H16/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '100',
    name: '広島市',
    kana_name: { half_upper: 'ﾋﾛｼﾏｼ', full_lower: 'ヒロシマシ' },
    en: null,
    start_date: '1980-04-01',
    end_date: null,
    remark: { start: 'S55/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '101',
    name: '広島市　中区',
    kana_name: { half_upper: 'ﾋﾛｼﾏｼ ﾅｶｸ', full_lower: 'ヒロシマシ　ナカク' },
    en: null,
    start_date: '1980-04-01',
    end_date: null,
    remark: { start: 'S55/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '102',
    name: '広島市　東区',
    kana_name: {
      half_upper: 'ﾋﾛｼﾏｼ ﾋｶﾞｼｸ',
      full_lower: 'ヒロシマシ　ヒガシク',
    },
    en: null,
    start_date: '1980-04-01',
    end_date: null,
    remark: { start: 'S55/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '103',
    name: '広島市　南区',
    kana_name: {
      half_upper: 'ﾋﾛｼﾏｼ ﾐﾅﾐｸ',
      full_lower: 'ヒロシマシ　ミナミク',
    },
    en: null,
    start_date: '1980-04-01',
    end_date: null,
    remark: { start: 'S55/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '104',
    name: '広島市　西区',
    kana_name: { half_upper: 'ﾋﾛｼﾏｼ ﾆｼｸ', full_lower: 'ヒロシマシ　ニシク' },
    en: null,
    start_date: '1980-04-01',
    end_date: null,
    remark: { start: 'S55/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '105',
    name: '広島市　安佐南区',
    kana_name: {
      half_upper: 'ﾋﾛｼﾏｼ ｱｻﾐﾅﾐｸ',
      full_lower: 'ヒロシマシ　アサミナミク',
    },
    en: null,
    start_date: '1980-04-01',
    end_date: null,
    remark: { start: 'S55/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '106',
    name: '広島市　安佐北区',
    kana_name: {
      half_upper: 'ﾋﾛｼﾏｼ ｱｻｷﾀｸ',
      full_lower: 'ヒロシマシ　アサキタク',
    },
    en: null,
    start_date: '1980-04-01',
    end_date: null,
    remark: { start: 'S55/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '107',
    name: '広島市　安芸区',
    kana_name: { half_upper: 'ﾋﾛｼﾏｼ ｱｷｸ', full_lower: 'ヒロシマシ　アキク' },
    en: null,
    start_date: '1980-04-01',
    end_date: null,
    remark: { start: 'S55/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '108',
    name: '広島市　佐伯区',
    kana_name: {
      half_upper: 'ﾋﾛｼﾏｼ ｻｴｷｸ',
      full_lower: 'ヒロシマシ　サエキク',
    },
    en: null,
    start_date: '1985-03-20',
    end_date: null,
    remark: { start: 'S60/03/20新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '201',
    name: '広島市',
    kana_name: { half_upper: 'ﾋﾛｼﾏｼ', full_lower: 'ヒロシマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '1980-04-01',
    remark: { start: null, end: '34100広島市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '202',
    name: '呉市',
    kana_name: { half_upper: 'ｸﾚｼ', full_lower: 'クレシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '203',
    name: '竹原市',
    kana_name: { half_upper: 'ﾀｹﾊﾗｼ', full_lower: 'タケハラシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '204',
    name: '三原市',
    kana_name: { half_upper: 'ﾐﾊﾗｼ', full_lower: 'ミハラシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '205',
    name: '尾道市',
    kana_name: { half_upper: 'ｵﾉﾐﾁｼ', full_lower: 'オノミチシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '206',
    name: '因島市',
    kana_name: { half_upper: 'ｲﾝﾉｼﾏｼ', full_lower: 'インノシマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-10',
    remark: { start: null, end: '34205尾道市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '207',
    name: '福山市',
    kana_name: { half_upper: 'ﾌｸﾔﾏｼ', full_lower: 'フクヤマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '208',
    name: '府中市',
    kana_name: { half_upper: 'ﾌﾁﾕｳｼ', full_lower: 'フチュウシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '209',
    name: '三次市',
    kana_name: { half_upper: 'ﾐﾖｼｼ', full_lower: 'ミヨシシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '210',
    name: '庄原市',
    kana_name: { half_upper: 'ｼﾖｳﾊﾞﾗｼ', full_lower: 'ショウバラシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '211',
    name: '大竹市',
    kana_name: { half_upper: 'ｵｵﾀｹｼ', full_lower: 'オオタケシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '212',
    name: '東広島市',
    kana_name: { half_upper: 'ﾋｶﾞｼﾋﾛｼﾏｼ', full_lower: 'ヒガシヒロシマシ' },
    en: null,
    start_date: '1974-04-20',
    end_date: null,
    remark: { start: 'S49/04/20新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '213',
    name: '廿日市市',
    kana_name: { half_upper: 'ﾊﾂｶｲﾁｼ', full_lower: 'ハツカイチシ' },
    en: null,
    start_date: '1988-04-01',
    end_date: null,
    remark: { start: 'S63/04/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '214',
    name: '安芸高田市',
    kana_name: { half_upper: 'ｱｷﾀｶﾀｼ', full_lower: 'アキタカタシ' },
    en: null,
    start_date: '2004-03-01',
    end_date: null,
    remark: { start: 'H16/03/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '215',
    name: '江田島市',
    kana_name: { half_upper: 'ｴﾀｼﾞﾏｼ', full_lower: 'エタジマシ' },
    en: null,
    start_date: '2004-11-01',
    end_date: null,
    remark: { start: 'H16/11/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '300',
    name: '安芸郡',
    kana_name: { half_upper: 'ｱｷｸﾞﾝ', full_lower: 'アキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '301',
    name: '安芸郡　安芸町',
    kana_name: {
      half_upper: 'ｱｷｸﾞﾝ ｱｷﾁﾖｳ',
      full_lower: 'アキグン　アキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1974-11-01',
    remark: { start: null, end: '34201広島市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '302',
    name: '安芸郡　府中町',
    kana_name: {
      half_upper: 'ｱｷｸﾞﾝ ﾌﾁﾕｳﾁﾖｳ',
      full_lower: 'アキグン　フチュウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '303',
    name: '安芸郡　船越町',
    kana_name: {
      half_upper: 'ｱｷｸﾞﾝ ﾌﾅｺｼﾁﾖｳ',
      full_lower: 'アキグン　フナコシチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1975-03-20',
    remark: { start: null, end: '34201広島市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '304',
    name: '安芸郡　海田町',
    kana_name: {
      half_upper: 'ｱｷｸﾞﾝ ｶｲﾀﾁﾖｳ',
      full_lower: 'アキグン　カイタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '305',
    name: '安芸郡　瀬野川町',
    kana_name: {
      half_upper: 'ｱｷｸﾞﾝ ｾﾉｶﾞﾜﾁﾖｳ',
      full_lower: 'アキグン　セノガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1973-03-20',
    remark: { start: null, end: '34201広島市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '306',
    name: '安芸郡　熊野跡村',
    kana_name: {
      half_upper: 'ｱｷｸﾞﾝ ｸﾏﾉｱﾄﾑﾗ',
      full_lower: 'アキグン　クマノアトムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1974-11-01',
    remark: { start: null, end: '34201広島市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '307',
    name: '安芸郡　熊野町',
    kana_name: {
      half_upper: 'ｱｷｸﾞﾝ ｸﾏﾉﾁﾖｳ',
      full_lower: 'アキグン　クマノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '308',
    name: '安芸郡　矢野町',
    kana_name: {
      half_upper: 'ｱｷｸﾞﾝ ﾔﾉﾁﾖｳ',
      full_lower: 'アキグン　ヤノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1975-03-20',
    remark: { start: null, end: '34201広島市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '309',
    name: '安芸郡　坂町',
    kana_name: {
      half_upper: 'ｱｷｸﾞﾝ ｻｶﾁﾖｳ',
      full_lower: 'アキグン　サカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '310',
    name: '安芸郡　江田島町',
    kana_name: {
      half_upper: 'ｱｷｸﾞﾝ ｴﾀｼﾞﾏﾁﾖｳ',
      full_lower: 'アキグン　エタジマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '34215江田島市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '311',
    name: '安芸郡　音戸町',
    kana_name: {
      half_upper: 'ｱｷｸﾞﾝ ｵﾝﾄﾞﾁﾖｳ',
      full_lower: 'アキグン　オンドチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-20',
    remark: { start: null, end: '34202呉市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '312',
    name: '安芸郡　倉橋町',
    kana_name: {
      half_upper: 'ｱｷｸﾞﾝ ｸﾗﾊｼﾁﾖｳ',
      full_lower: 'アキグン　クラハシチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-20',
    remark: { start: null, end: '34202呉市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '313',
    name: '安芸郡　下蒲刈町',
    kana_name: {
      half_upper: 'ｱｷｸﾞﾝ ｼﾓｶﾏｶﾞﾘﾁﾖｳ',
      full_lower: 'アキグン　シモカマガリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: '34202呉市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '314',
    name: '安芸郡　蒲刈町',
    kana_name: {
      half_upper: 'ｱｷｸﾞﾝ ｶﾏｶﾞﾘﾁﾖｳ',
      full_lower: 'アキグン　カマガリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-20',
    remark: { start: null, end: '34202呉市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '320',
    name: '佐伯郡',
    kana_name: { half_upper: 'ｻｴｷｸﾞﾝ', full_lower: 'サエキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-03',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '321',
    name: '佐伯郡　五日市町',
    kana_name: {
      half_upper: 'ｻｴｷｸﾞﾝ ｲﾂｶｲﾁﾁﾖｳ',
      full_lower: 'サエキグン　イツカイチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1985-03-20',
    remark: { start: null, end: '34100広島市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '322',
    name: '佐伯郡　廿日市町',
    kana_name: {
      half_upper: 'ｻｴｷｸﾞﾝ ﾊﾂｶｲﾁﾁﾖｳ',
      full_lower: 'サエキグン　ハツカイチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1988-04-01',
    remark: { start: null, end: '34213廿日市市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '323',
    name: '佐伯郡　大野町',
    kana_name: {
      half_upper: 'ｻｴｷｸﾞﾝ ｵｵﾉﾁﾖｳ',
      full_lower: 'サエキグン　オオノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-03',
    remark: { start: null, end: '34213廿日市市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '324',
    name: '佐伯郡　湯来町',
    kana_name: {
      half_upper: 'ｻｴｷｸﾞﾝ ﾕｷﾁﾖｳ',
      full_lower: 'サエキグン　ユキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-25',
    remark: { start: null, end: '34108広島市　佐伯区', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '325',
    name: '佐伯郡　佐伯町',
    kana_name: {
      half_upper: 'ｻｴｷｸﾞﾝ ｻｲｷﾁﾖｳ',
      full_lower: 'サエキグン　サイキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-03-01',
    remark: { start: null, end: '34213廿日市市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '326',
    name: '佐伯郡　吉和村',
    kana_name: {
      half_upper: 'ｻｴｷｸﾞﾝ ﾖｼﾜﾑﾗ',
      full_lower: 'サエキグン　ヨシワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-03-01',
    remark: { start: null, end: '34213廿日市市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '327',
    name: '佐伯郡　宮島町',
    kana_name: {
      half_upper: 'ｻｴｷｸﾞﾝ ﾐﾔｼﾞﾏﾁﾖｳ',
      full_lower: 'サエキグン　ミヤジマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-03',
    remark: { start: null, end: '34213廿日市市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '328',
    name: '佐伯郡　能美町',
    kana_name: {
      half_upper: 'ｻｴｷｸﾞﾝ ﾉｳﾐﾁﾖｳ',
      full_lower: 'サエキグン　ノウミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '34215江田島市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '329',
    name: '佐伯郡　沖美町',
    kana_name: {
      half_upper: 'ｻｴｷｸﾞﾝ ｵｷﾐﾁﾖｳ',
      full_lower: 'サエキグン　オキミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '34215江田島市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '330',
    name: '佐伯郡　大柿町',
    kana_name: {
      half_upper: 'ｻｴｷｸﾞﾝ ｵｵｶﾞｷﾁﾖｳ',
      full_lower: 'サエキグン　オオガキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '34215江田島市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '340',
    name: '安佐郡',
    kana_name: { half_upper: 'ｱｻｸﾞﾝ', full_lower: 'アサグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '1973-03-20',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '341',
    name: '安佐郡　祇園町',
    kana_name: {
      half_upper: 'ｱｻｸﾞﾝ ｷﾞｵﾝﾁﾖｳ',
      full_lower: 'アサグン　ギオンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-08-27',
    remark: { start: null, end: '34201広島市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '342',
    name: '安佐郡　安古市町',
    kana_name: {
      half_upper: 'ｱｻｸﾞﾝ ﾔｽﾌﾙｲﾁﾁﾖｳ',
      full_lower: 'アサグン　ヤスフルイチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1973-03-20',
    remark: { start: null, end: '34201広島市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '343',
    name: '安佐郡　佐東町',
    kana_name: {
      half_upper: 'ｱｻｸﾞﾝ ｻﾄｳﾁﾖｳ',
      full_lower: 'アサグン　サトウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1973-03-20',
    remark: { start: null, end: '34201広島市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '344',
    name: '安佐郡　沼田町',
    kana_name: {
      half_upper: 'ｱｻｸﾞﾝ ﾇﾏﾀﾁﾖｳ',
      full_lower: 'アサグン　ヌマタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-04-01',
    remark: { start: null, end: '34201広島市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '345',
    name: '安佐郡　安佐町',
    kana_name: {
      half_upper: 'ｱｻｸﾞﾝ ｱｻﾁﾖｳ',
      full_lower: 'アサグン　アサチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-05-20',
    remark: { start: null, end: '34201広島市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '346',
    name: '安佐郡　可部町',
    kana_name: {
      half_upper: 'ｱｻｸﾞﾝ ｶﾍﾞﾁﾖｳ',
      full_lower: 'アサグン　カベチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-04-01',
    remark: { start: null, end: '34201広島市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '347',
    name: '安佐郡　高陽町',
    kana_name: {
      half_upper: 'ｱｻｸﾞﾝ ｺｳﾖｳﾁﾖｳ',
      full_lower: 'アサグン　コウヨウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1973-03-20',
    remark: { start: null, end: '34201広島市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '360',
    name: '山県郡',
    kana_name: { half_upper: 'ﾔﾏｶﾞﾀｸﾞﾝ', full_lower: 'ヤマガタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '361',
    name: '山県郡　加計町',
    kana_name: {
      half_upper: 'ﾔﾏｶﾞﾀｸﾞﾝ ｶｹﾁﾖｳ',
      full_lower: 'ヤマガタグン　カケチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '34368山県郡　安芸太田町', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '362',
    name: '山県郡　筒賀村',
    kana_name: {
      half_upper: 'ﾔﾏｶﾞﾀｸﾞﾝ ﾂﾂｶﾞｿﾝ',
      full_lower: 'ヤマガタグン　ツツガソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '34368山県郡　安芸太田町', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '363',
    name: '山県郡　戸河内町',
    kana_name: {
      half_upper: 'ﾔﾏｶﾞﾀｸﾞﾝ ﾄｺﾞｳﾁﾁﾖｳ',
      full_lower: 'ヤマガタグン　トゴウチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '34368山県郡　安芸太田町', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '364',
    name: '山県郡　芸北町',
    kana_name: {
      half_upper: 'ﾔﾏｶﾞﾀｸﾞﾝ ｹﾞｲﾎｸﾁﾖｳ',
      full_lower: 'ヤマガタグン　ゲイホクチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '34369山県郡　北広島町', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '365',
    name: '山県郡　大朝町',
    kana_name: {
      half_upper: 'ﾔﾏｶﾞﾀｸﾞﾝ ｵｵｱｻﾁﾖｳ',
      full_lower: 'ヤマガタグン　オオアサチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '34369山県郡　北広島町', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '366',
    name: '山県郡　千代田町',
    kana_name: {
      half_upper: 'ﾔﾏｶﾞﾀｸﾞﾝ ﾁﾖﾀﾞﾁﾖｳ',
      full_lower: 'ヤマガタグン　チヨダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '34369山県郡　北広島町', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '367',
    name: '山県郡　豊平町',
    kana_name: {
      half_upper: 'ﾔﾏｶﾞﾀｸﾞﾝ ﾄﾖﾋﾗﾁﾖｳ',
      full_lower: 'ヤマガタグン　トヨヒラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '34369山県郡　北広島町', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '368',
    name: '山県郡　安芸太田町',
    kana_name: {
      half_upper: 'ﾔﾏｶﾞﾀｸﾞﾝ ｱｷｵｵﾀﾁﾖｳ',
      full_lower: 'ヤマガタグン　アキオオタチョウ',
    },
    en: null,
    start_date: '2004-10-01',
    end_date: null,
    remark: { start: 'H16/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '369',
    name: '山県郡　北広島町',
    kana_name: {
      half_upper: 'ﾔﾏｶﾞﾀｸﾞﾝ ｷﾀﾋﾛｼﾏﾁﾖｳ',
      full_lower: 'ヤマガタグン　キタヒロシマチョウ',
    },
    en: null,
    start_date: '2005-02-01',
    end_date: null,
    remark: { start: 'H17/02/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '380',
    name: '高田郡',
    kana_name: { half_upper: 'ﾀｶﾀｸﾞﾝ', full_lower: 'タカタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '381',
    name: '高田郡　吉田町',
    kana_name: {
      half_upper: 'ﾀｶﾀｸﾞﾝ ﾖｼﾀﾞﾁﾖｳ',
      full_lower: 'タカタグン　ヨシダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '34214安芸高田市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '382',
    name: '高田郡　八千代町',
    kana_name: {
      half_upper: 'ﾀｶﾀｸﾞﾝ ﾔﾁﾖﾁﾖｳ',
      full_lower: 'タカタグン　ヤチヨチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '34214安芸高田市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '383',
    name: '高田郡　美土里町',
    kana_name: {
      half_upper: 'ﾀｶﾀｸﾞﾝ ﾐﾄﾞﾘﾁﾖｳ',
      full_lower: 'タカタグン　ミドリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '34214安芸高田市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '384',
    name: '高田郡　高宮町',
    kana_name: {
      half_upper: 'ﾀｶﾀｸﾞﾝ ﾀｶﾐﾔﾁﾖｳ',
      full_lower: 'タカタグン　タカミヤチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '34214安芸高田市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '385',
    name: '高田郡　甲田町',
    kana_name: {
      half_upper: 'ﾀｶﾀｸﾞﾝ ｺｳﾀﾞﾁﾖｳ',
      full_lower: 'タカタグン　コウダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '34214安芸高田市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '386',
    name: '高田郡　向原町',
    kana_name: {
      half_upper: 'ﾀｶﾀｸﾞﾝ ﾑｶｲﾊﾗﾁﾖｳ',
      full_lower: 'タカタグン　ムカイハラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '34214安芸高田市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '387',
    name: '高田郡　白木町',
    kana_name: {
      half_upper: 'ﾀｶﾀｸﾞﾝ ｼﾗｷﾁﾖｳ',
      full_lower: 'タカタグン　シラキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1973-10-22',
    remark: { start: null, end: '34201広島市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '400',
    name: '賀茂郡',
    kana_name: { half_upper: 'ｶﾓｸﾞﾝ', full_lower: 'カモグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '401',
    name: '賀茂郡　西条町',
    kana_name: {
      half_upper: 'ｶﾓｸﾞﾝ ｻｲｼﾞﾖｳﾁﾖｳ',
      full_lower: 'カモグン　サイジョウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1974-04-20',
    remark: { start: null, end: '34212東広島市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '402',
    name: '賀茂郡　黒瀬町',
    kana_name: {
      half_upper: 'ｶﾓｸﾞﾝ ｸﾛｾﾁﾖｳ',
      full_lower: 'カモグン　クロセチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-07',
    remark: { start: null, end: '34212東広島市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '403',
    name: '賀茂郡　八本松町',
    kana_name: {
      half_upper: 'ｶﾓｸﾞﾝ ﾊﾁﾎﾝﾏﾂﾁﾖｳ',
      full_lower: 'カモグン　ハチホンマツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1974-04-20',
    remark: { start: null, end: '34212東広島市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '404',
    name: '賀茂郡　志和町',
    kana_name: {
      half_upper: 'ｶﾓｸﾞﾝ ｼﾜﾁﾖｳ',
      full_lower: 'カモグン　シワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1974-04-20',
    remark: { start: null, end: '34212東広島市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '405',
    name: '賀茂郡　福富町',
    kana_name: {
      half_upper: 'ｶﾓｸﾞﾝ ﾌｸﾄﾐﾁﾖｳ',
      full_lower: 'カモグン　フクトミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-07',
    remark: { start: null, end: '34212東広島市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '406',
    name: '賀茂郡　豊栄町',
    kana_name: {
      half_upper: 'ｶﾓｸﾞﾝ ﾄﾖｻｶﾁﾖｳ',
      full_lower: 'カモグン　トヨサカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-07',
    remark: { start: null, end: '34212東広島市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '407',
    name: '賀茂郡　大和町',
    kana_name: {
      half_upper: 'ｶﾓｸﾞﾝ ﾀﾞｲﾜﾁﾖｳ',
      full_lower: 'カモグン　ダイワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '34204三原市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '408',
    name: '賀茂郡　河内町',
    kana_name: {
      half_upper: 'ｶﾓｸﾞﾝ ｺｳﾁﾁﾖｳ',
      full_lower: 'カモグン　コウチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-07',
    remark: { start: null, end: '34212東広島市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '409',
    name: '賀茂郡　高屋町',
    kana_name: {
      half_upper: 'ｶﾓｸﾞﾝ ﾀｶﾔﾁﾖｳ',
      full_lower: 'カモグン　タカヤチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1974-04-20',
    remark: { start: null, end: '34212東広島市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '420',
    name: '豊田郡',
    kana_name: { half_upper: 'ﾄﾖﾀｸﾞﾝ', full_lower: 'トヨタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '421',
    name: '豊田郡　本郷町',
    kana_name: {
      half_upper: 'ﾄﾖﾀｸﾞﾝ ﾎﾝｺﾞｳﾁﾖｳ',
      full_lower: 'トヨタグン　ホンゴウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '34204三原市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '422',
    name: '豊田郡　安芸津町',
    kana_name: {
      half_upper: 'ﾄﾖﾀｸﾞﾝ ｱｷﾂﾁﾖｳ',
      full_lower: 'トヨタグン　アキツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-07',
    remark: { start: null, end: '34212東広島市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '423',
    name: '豊田郡　安浦町',
    kana_name: {
      half_upper: 'ﾄﾖﾀｸﾞﾝ ﾔｽｳﾗﾁﾖｳ',
      full_lower: 'トヨタグン　ヤスウラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-20',
    remark: { start: null, end: '34202呉市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '424',
    name: '豊田郡　川尻町',
    kana_name: {
      half_upper: 'ﾄﾖﾀｸﾞﾝ ｶﾜｼﾞﾘﾁﾖｳ',
      full_lower: 'トヨタグン　カワジリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '34202呉市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '425',
    name: '豊田郡　豊浜町',
    kana_name: {
      half_upper: 'ﾄﾖﾀｸﾞﾝ ﾄﾖﾊﾏﾁﾖｳ',
      full_lower: 'トヨタグン　トヨハマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-20',
    remark: { start: null, end: '34202呉市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '426',
    name: '豊田郡　豊町',
    kana_name: {
      half_upper: 'ﾄﾖﾀｸﾞﾝ ﾕﾀｶﾏﾁ',
      full_lower: 'トヨタグン　ユタカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-20',
    remark: { start: null, end: '34202呉市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '427',
    name: '豊田郡　大崎町',
    kana_name: {
      half_upper: 'ﾄﾖﾀｸﾞﾝ ｵｵｻｷﾁﾖｳ',
      full_lower: 'トヨタグン　オオサキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: '34431豊田郡　大崎上島町', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '428',
    name: '豊田郡　東野町',
    kana_name: {
      half_upper: 'ﾄﾖﾀｸﾞﾝ ﾋｶﾞｼﾉﾁﾖｳ',
      full_lower: 'トヨタグン　ヒガシノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: '34431豊田郡　大崎上島町', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '429',
    name: '豊田郡　木江町',
    kana_name: {
      half_upper: 'ﾄﾖﾀｸﾞﾝ ｷﾉｴﾁﾖｳ',
      full_lower: 'トヨタグン　キノエチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: '34431豊田郡　大崎上島町', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '430',
    name: '豊田郡　瀬戸田町',
    kana_name: {
      half_upper: 'ﾄﾖﾀｸﾞﾝ ｾﾄﾀﾞﾁﾖｳ',
      full_lower: 'トヨタグン　セトダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-10',
    remark: { start: null, end: '34205尾道市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '431',
    name: '豊田郡　大崎上島町',
    kana_name: {
      half_upper: 'ﾄﾖﾀｸﾞﾝ ｵｵｻｷｶﾐｼﾞﾏﾁﾖｳ',
      full_lower: 'トヨタグン　オオサキカミジマチョウ',
    },
    en: null,
    start_date: '2003-04-01',
    end_date: null,
    remark: { start: 'H15/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '440',
    name: '御調郡',
    kana_name: { half_upper: 'ﾐﾂｷﾞｸﾞﾝ', full_lower: 'ミツギグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '441',
    name: '御調郡　御調町',
    kana_name: {
      half_upper: 'ﾐﾂｷﾞｸﾞﾝ ﾐﾂｷﾞﾁﾖｳ',
      full_lower: 'ミツギグン　ミツギチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '34205尾道市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '442',
    name: '御調郡　久井町',
    kana_name: {
      half_upper: 'ﾐﾂｷﾞｸﾞﾝ ｸｲﾁﾖｳ',
      full_lower: 'ミツギグン　クイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '34204三原市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '443',
    name: '御調郡　向東町',
    kana_name: {
      half_upper: 'ﾐﾂｷﾞｸﾞﾝ ﾑｶｲﾋｶﾞｼﾁﾖｳ',
      full_lower: 'ミツギグン　ムカイヒガシチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-04-01',
    remark: { start: null, end: '34205尾道市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '444',
    name: '御調郡　向島町',
    kana_name: {
      half_upper: 'ﾐﾂｷﾞｸﾞﾝ ﾑｶｲｼﾏﾁﾖｳ',
      full_lower: 'ミツギグン　ムカイシマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '34205尾道市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '460',
    name: '世羅郡',
    kana_name: { half_upper: 'ｾﾗｸﾞﾝ', full_lower: 'セラグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '461',
    name: '世羅郡　甲山町',
    kana_name: {
      half_upper: 'ｾﾗｸﾞﾝ ｺｳｻﾞﾝﾁﾖｳ',
      full_lower: 'セラグン　コウザンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '34462世羅郡　世羅町', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '462',
    name: '世羅郡　世羅町',
    kana_name: {
      half_upper: 'ｾﾗｸﾞﾝ ｾﾗﾁﾖｳ',
      full_lower: 'セラグン　セラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '463',
    name: '世羅郡　世羅西町',
    kana_name: {
      half_upper: 'ｾﾗｸﾞﾝ ｾﾗﾆｼﾁﾖｳ',
      full_lower: 'セラグン　セラニシチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '34462世羅郡　世羅町', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '480',
    name: '沼隈郡',
    kana_name: { half_upper: 'ﾇﾏｸﾏｸﾞﾝ', full_lower: 'ヌマクマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '481',
    name: '沼隈郡　内海町',
    kana_name: {
      half_upper: 'ﾇﾏｸﾏｸﾞﾝ ｳﾂﾐﾁﾖｳ',
      full_lower: 'ヌマクマグン　ウツミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-02-03',
    remark: { start: null, end: '34207福山市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '482',
    name: '沼隈郡　沼隈町',
    kana_name: {
      half_upper: 'ﾇﾏｸﾏｸﾞﾝ ﾇﾏｸﾏﾁﾖｳ',
      full_lower: 'ヌマクマグン　ヌマクマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '34207福山市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '500',
    name: '深安郡',
    kana_name: { half_upper: 'ﾌｶﾔｽｸﾞﾝ', full_lower: 'フカヤスグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '501',
    name: '深安郡　神辺町',
    kana_name: {
      half_upper: 'ﾌｶﾔｽｸﾞﾝ ｶﾝﾅﾍﾞﾁﾖｳ',
      full_lower: 'フカヤスグン　カンナベチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '34207福山市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '502',
    name: '深安郡　加茂町',
    kana_name: {
      half_upper: 'ﾌｶﾔｽｸﾞﾝ ｶﾓﾁﾖｳ',
      full_lower: 'フカヤスグン　カモチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1975-02-01',
    remark: { start: null, end: '34207福山市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '520',
    name: '芦品郡',
    kana_name: { half_upper: 'ｱｼﾅｸﾞﾝ', full_lower: 'アシナグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-02-03',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '521',
    name: '芦品郡　協和村',
    kana_name: {
      half_upper: 'ｱｼﾅｸﾞﾝ ｷﾖｳﾜｿﾝ',
      full_lower: 'アシナグン　キョウワソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1975-02-01',
    remark: { start: null, end: '34208府中市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '522',
    name: '芦品郡　芦田町',
    kana_name: {
      half_upper: 'ｱｼﾅｸﾞﾝ ｱｼﾀﾞﾁﾖｳ',
      full_lower: 'アシナグン　アシダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1974-04-01',
    remark: { start: null, end: '34207福山市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '523',
    name: '芦品郡　駅家町',
    kana_name: {
      half_upper: 'ｱｼﾅｸﾞﾝ ｴｷﾔﾁﾖｳ',
      full_lower: 'アシナグン　エキヤチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1975-02-01',
    remark: { start: null, end: '34207福山市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '524',
    name: '芦品郡　新市町',
    kana_name: {
      half_upper: 'ｱｼﾅｸﾞﾝ ｼﾝｲﾁﾁﾖｳ',
      full_lower: 'アシナグン　シンイチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-02-03',
    remark: { start: null, end: '34207福山市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '540',
    name: '神石郡',
    kana_name: { half_upper: 'ｼﾞﾝｾｷｸﾞﾝ', full_lower: 'ジンセキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '541',
    name: '神石郡　油木町',
    kana_name: {
      half_upper: 'ｼﾞﾝｾｷｸﾞﾝ ﾕｷﾁﾖｳ',
      full_lower: 'ジンセキグン　ユキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-05',
    remark: { start: null, end: '34545神石郡　神石高原町', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '542',
    name: '神石郡　神石町',
    kana_name: {
      half_upper: 'ｼﾞﾝｾｷｸﾞﾝ ｼﾞﾝｾｷﾁﾖｳ',
      full_lower: 'ジンセキグン　ジンセキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-05',
    remark: { start: null, end: '34545神石郡　神石高原町', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '543',
    name: '神石郡　豊松村',
    kana_name: {
      half_upper: 'ｼﾞﾝｾｷｸﾞﾝ ﾄﾖﾏﾂｿﾝ',
      full_lower: 'ジンセキグン　トヨマツソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-05',
    remark: { start: null, end: '34545神石郡　神石高原町', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '544',
    name: '神石郡　三和町',
    kana_name: {
      half_upper: 'ｼﾞﾝｾｷｸﾞﾝ ｻﾝﾜﾁﾖｳ',
      full_lower: 'ジンセキグン　サンワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-05',
    remark: { start: null, end: '34545神石郡　神石高原町', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '545',
    name: '神石郡　神石高原町',
    kana_name: {
      half_upper: 'ｼﾞﾝｾｷｸﾞﾝ ｼﾞﾝｾｷｺｳｹﾞﾝﾁﾖｳ',
      full_lower: 'ジンセキグン　ジンセキコウゲンチョウ',
    },
    en: null,
    start_date: '2004-11-05',
    end_date: null,
    remark: { start: 'H16/11/05新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '560',
    name: '甲奴郡',
    kana_name: { half_upper: 'ｺｳﾇｸﾞﾝ', full_lower: 'コウヌグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '561',
    name: '甲奴郡　上下町',
    kana_name: {
      half_upper: 'ｺｳﾇｸﾞﾝ ｼﾞﾖｳｹﾞﾁﾖｳ',
      full_lower: 'コウヌグン　ジョウゲチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '34208府中市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '562',
    name: '甲奴郡　総領町',
    kana_name: {
      half_upper: 'ｺｳﾇｸﾞﾝ ｿｳﾘﾖｳﾁﾖｳ',
      full_lower: 'コウヌグン　ソウリョウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '34210庄原市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '563',
    name: '甲奴郡　甲奴町',
    kana_name: {
      half_upper: 'ｺｳﾇｸﾞﾝ ｺｳﾇﾁﾖｳ',
      full_lower: 'コウヌグン　コウヌチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '34209三次市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '580',
    name: '双三郡',
    kana_name: { half_upper: 'ﾌﾀﾐｸﾞﾝ', full_lower: 'フタミグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '581',
    name: '双三郡　君田村',
    kana_name: {
      half_upper: 'ﾌﾀﾐｸﾞﾝ ｷﾐﾀｿﾝ',
      full_lower: 'フタミグン　キミタソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '34209三次市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '582',
    name: '双三郡　布野村',
    kana_name: {
      half_upper: 'ﾌﾀﾐｸﾞﾝ ﾌﾉｿﾝ',
      full_lower: 'フタミグン　フノソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '34209三次市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '583',
    name: '双三郡　作木村',
    kana_name: {
      half_upper: 'ﾌﾀﾐｸﾞﾝ ｻｸｷﾞｿﾝ',
      full_lower: 'フタミグン　サクギソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '34209三次市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '584',
    name: '双三郡　吉舎町',
    kana_name: {
      half_upper: 'ﾌﾀﾐｸﾞﾝ ｷｻﾁﾖｳ',
      full_lower: 'フタミグン　キサチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '34209三次市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '585',
    name: '双三郡　三良坂町',
    kana_name: {
      half_upper: 'ﾌﾀﾐｸﾞﾝ ﾐﾗｻｶﾁﾖｳ',
      full_lower: 'フタミグン　ミラサカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '34209三次市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '586',
    name: '双三郡　三和町',
    kana_name: {
      half_upper: 'ﾌﾀﾐｸﾞﾝ ﾐﾜﾁﾖｳ',
      full_lower: 'フタミグン　ミワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '34209三次市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '600',
    name: '比婆郡',
    kana_name: { half_upper: 'ﾋﾊﾞｸﾞﾝ', full_lower: 'ヒバグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '601',
    name: '比婆郡　西城町',
    kana_name: {
      half_upper: 'ﾋﾊﾞｸﾞﾝ ｻｲｼﾞﾖｳﾁﾖｳ',
      full_lower: 'ヒバグン　サイジョウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '34210庄原市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '602',
    name: '比婆郡　東城町',
    kana_name: {
      half_upper: 'ﾋﾊﾞｸﾞﾝ ﾄｳｼﾞﾖｳﾁﾖｳ',
      full_lower: 'ヒバグン　トウジョウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '34210庄原市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '603',
    name: '比婆郡　口和町',
    kana_name: {
      half_upper: 'ﾋﾊﾞｸﾞﾝ ｸﾁﾜﾁﾖｳ',
      full_lower: 'ヒバグン　クチワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '34210庄原市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '604',
    name: '比婆郡　高野町',
    kana_name: {
      half_upper: 'ﾋﾊﾞｸﾞﾝ ﾀｶﾉﾁﾖｳ',
      full_lower: 'ヒバグン　タカノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '34210庄原市', other: null },
    note: null,
  },
  {
    pref_code: '34',
    city_code: '605',
    name: '比婆郡　比和町',
    kana_name: {
      half_upper: 'ﾋﾊﾞｸﾞﾝ ﾋﾜﾁﾖｳ',
      full_lower: 'ヒバグン　ヒワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '34210庄原市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '201',
    name: '下関市',
    kana_name: { half_upper: 'ｼﾓﾉｾｷｼ', full_lower: 'シモノセキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '202',
    name: '宇部市',
    kana_name: { half_upper: 'ｳﾍﾞｼ', full_lower: 'ウベシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '203',
    name: '山口市',
    kana_name: { half_upper: 'ﾔﾏｸﾞﾁｼ', full_lower: 'ヤマグチシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '204',
    name: '萩市',
    kana_name: { half_upper: 'ﾊｷﾞｼ', full_lower: 'ハギシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '205',
    name: '徳山市',
    kana_name: { half_upper: 'ﾄｸﾔﾏｼ', full_lower: 'トクヤマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-21',
    remark: { start: null, end: '35215周南市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '206',
    name: '防府市',
    kana_name: { half_upper: 'ﾎｳﾌｼ', full_lower: 'ホウフシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '207',
    name: '下松市',
    kana_name: { half_upper: 'ｸﾀﾞﾏﾂｼ', full_lower: 'クダマツシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '208',
    name: '岩国市',
    kana_name: { half_upper: 'ｲﾜｸﾆｼ', full_lower: 'イワクニシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '209',
    name: '小野田市',
    kana_name: { half_upper: 'ｵﾉﾀﾞｼ', full_lower: 'オノダシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '35216山陽小野田市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '210',
    name: '光市',
    kana_name: { half_upper: 'ﾋｶﾘｼ', full_lower: 'ヒカリシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '211',
    name: '長門市',
    kana_name: { half_upper: 'ﾅｶﾞﾄｼ', full_lower: 'ナガトシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '212',
    name: '柳井市',
    kana_name: { half_upper: 'ﾔﾅｲｼ', full_lower: 'ヤナイシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '213',
    name: '美祢市',
    kana_name: { half_upper: 'ﾐﾈｼ', full_lower: 'ミネシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '214',
    name: '新南陽市',
    kana_name: { half_upper: 'ｼﾝﾅﾝﾖｳｼ', full_lower: 'シンナンヨウシ' },
    en: null,
    start_date: '1970-11-01',
    end_date: '2003-04-21',
    remark: { start: 'S45/11/01市制', end: '35215周南市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '215',
    name: '周南市',
    kana_name: { half_upper: 'ｼﾕｳﾅﾝｼ', full_lower: 'シュウナンシ' },
    en: null,
    start_date: '2003-04-21',
    end_date: null,
    remark: { start: 'H15/04/21新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '216',
    name: '山陽小野田市',
    kana_name: { half_upper: 'ｻﾝﾖｳｵﾉﾀﾞｼ', full_lower: 'サンヨウオノダシ' },
    en: null,
    start_date: '2005-03-22',
    end_date: null,
    remark: { start: 'H17/03/22新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '300',
    name: '大島郡',
    kana_name: { half_upper: 'ｵｵｼﾏｸﾞﾝ', full_lower: 'オオシマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '301',
    name: '大島郡　久賀町',
    kana_name: {
      half_upper: 'ｵｵｼﾏｸﾞﾝ ｸｶﾁﾖｳ',
      full_lower: 'オオシマグン　クカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '35305大島郡　周防大島町', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '302',
    name: '大島郡　大島町',
    kana_name: {
      half_upper: 'ｵｵｼﾏｸﾞﾝ ｵｵｼﾏﾁﾖｳ',
      full_lower: 'オオシマグン　オオシマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '35305大島郡　周防大島町', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '303',
    name: '大島郡　東和町',
    kana_name: {
      half_upper: 'ｵｵｼﾏｸﾞﾝ ﾄｳﾜﾁﾖｳ',
      full_lower: 'オオシマグン　トウワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '35305大島郡　周防大島町', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '304',
    name: '大島郡　橘町',
    kana_name: {
      half_upper: 'ｵｵｼﾏｸﾞﾝ ﾀﾁﾊﾞﾅﾁﾖｳ',
      full_lower: 'オオシマグン　タチバナチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '35305大島郡　周防大島町', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '305',
    name: '大島郡　周防大島町',
    kana_name: {
      half_upper: 'ｵｵｼﾏｸﾞﾝ ｽｵｳｵｵｼﾏﾁﾖｳ',
      full_lower: 'オオシマグン　スオウオオシマチョウ',
    },
    en: null,
    start_date: '2004-10-01',
    end_date: null,
    remark: { start: 'H16/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '320',
    name: '玖珂郡',
    kana_name: { half_upper: 'ｸｶﾞｸﾞﾝ', full_lower: 'クガグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '321',
    name: '玖珂郡　和木町',
    kana_name: {
      half_upper: 'ｸｶﾞｸﾞﾝ ﾜｷﾁﾖｳ',
      full_lower: 'クガグン　ワキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '322',
    name: '玖珂郡　由宇町',
    kana_name: {
      half_upper: 'ｸｶﾞｸﾞﾝ ﾕｳﾁﾖｳ',
      full_lower: 'クガグン　ユウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '35208岩国市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '323',
    name: '玖珂郡　玖珂町',
    kana_name: {
      half_upper: 'ｸｶﾞｸﾞﾝ ｸｶﾞﾁﾖｳ',
      full_lower: 'クガグン　クガチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '35208岩国市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '324',
    name: '玖珂郡　本郷村',
    kana_name: {
      half_upper: 'ｸｶﾞｸﾞﾝ ﾎﾝｺﾞｳｿﾝ',
      full_lower: 'クガグン　ホンゴウソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '35208岩国市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '325',
    name: '玖珂郡　周東町',
    kana_name: {
      half_upper: 'ｸｶﾞｸﾞﾝ ｼﾕｳﾄｳﾁﾖｳ',
      full_lower: 'クガグン　シュウトウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '35208岩国市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '326',
    name: '玖珂郡　錦町',
    kana_name: {
      half_upper: 'ｸｶﾞｸﾞﾝ ﾆｼｷﾁﾖｳ',
      full_lower: 'クガグン　ニシキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '35208岩国市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '327',
    name: '玖珂郡　大畠町',
    kana_name: {
      half_upper: 'ｸｶﾞｸﾞﾝ ｵｵﾊﾞﾀｹﾁﾖｳ',
      full_lower: 'クガグン　オオバタケチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-21',
    remark: { start: null, end: '35212柳井市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '328',
    name: '玖珂郡　美川町',
    kana_name: {
      half_upper: 'ｸｶﾞｸﾞﾝ ﾐｶﾜﾁﾖｳ',
      full_lower: 'クガグン　ミカワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '35208岩国市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '329',
    name: '玖珂郡　美和町',
    kana_name: {
      half_upper: 'ｸｶﾞｸﾞﾝ ﾐﾜﾁﾖｳ',
      full_lower: 'クガグン　ミワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '35208岩国市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '340',
    name: '熊毛郡',
    kana_name: { half_upper: 'ｸﾏｹﾞｸﾞﾝ', full_lower: 'クマゲグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '341',
    name: '熊毛郡　上関町',
    kana_name: {
      half_upper: 'ｸﾏｹﾞｸﾞﾝ ｶﾐﾉｾｷﾁﾖｳ',
      full_lower: 'クマゲグン　カミノセキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '342',
    name: '熊毛郡　大和町',
    kana_name: {
      half_upper: 'ｸﾏｹﾞｸﾞﾝ ﾔﾏﾄﾁﾖｳ',
      full_lower: 'クマゲグン　ヤマトチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-04',
    remark: { start: null, end: '35210光市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '343',
    name: '熊毛郡　田布施町',
    kana_name: {
      half_upper: 'ｸﾏｹﾞｸﾞﾝ ﾀﾌﾞｾﾁﾖｳ',
      full_lower: 'クマゲグン　タブセチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '344',
    name: '熊毛郡　平生町',
    kana_name: {
      half_upper: 'ｸﾏｹﾞｸﾞﾝ ﾋﾗｵﾁﾖｳ',
      full_lower: 'クマゲグン　ヒラオチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '345',
    name: '熊毛郡　熊毛町',
    kana_name: {
      half_upper: 'ｸﾏｹﾞｸﾞﾝ ｸﾏｹﾞﾁﾖｳ',
      full_lower: 'クマゲグン　クマゲチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-21',
    remark: { start: null, end: '35215周南市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '360',
    name: '都濃郡',
    kana_name: { half_upper: 'ﾂﾉｸﾞﾝ', full_lower: 'ツノグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-21',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '361',
    name: '都濃郡　鹿野町',
    kana_name: {
      half_upper: 'ﾂﾉｸﾞﾝ ｶﾉﾁﾖｳ',
      full_lower: 'ツノグン　カノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-21',
    remark: { start: null, end: '35215周南市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '362',
    name: '都濃郡　南陽町',
    kana_name: {
      half_upper: 'ﾂﾉｸﾞﾝ ﾅﾝﾖｳﾁﾖｳ',
      full_lower: 'ツノグン　ナンヨウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-11-01',
    remark: { start: null, end: '35214新南陽市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '380',
    name: '佐波郡',
    kana_name: { half_upper: 'ｻﾊﾞｸﾞﾝ', full_lower: 'サバグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '381',
    name: '佐波郡　徳地町',
    kana_name: {
      half_upper: 'ｻﾊﾞｸﾞﾝ ﾄｸﾁﾞﾁﾖｳ',
      full_lower: 'サバグン　トクヂチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '35203山口市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '400',
    name: '吉敷郡',
    kana_name: { half_upper: 'ﾖｼｷｸﾞﾝ', full_lower: 'ヨシキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '401',
    name: '吉敷郡　秋穂町',
    kana_name: {
      half_upper: 'ﾖｼｷｸﾞﾝ ｱｲｵﾁﾖｳ',
      full_lower: 'ヨシキグン　アイオチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '35203山口市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '402',
    name: '吉敷郡　小郡町',
    kana_name: {
      half_upper: 'ﾖｼｷｸﾞﾝ ｵｺﾞｵﾘﾁﾖｳ',
      full_lower: 'ヨシキグン　オゴオリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '35203山口市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '403',
    name: '吉敷郡　阿知須町',
    kana_name: {
      half_upper: 'ﾖｼｷｸﾞﾝ ｱｼﾞｽﾁﾖｳ',
      full_lower: 'ヨシキグン　アジスチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '35203山口市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '420',
    name: '厚狭郡',
    kana_name: { half_upper: 'ｱｻｸﾞﾝ', full_lower: 'アサグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '421',
    name: '厚狭郡　楠町',
    kana_name: {
      half_upper: 'ｱｻｸﾞﾝ ｸｽﾉｷﾁﾖｳ',
      full_lower: 'アサグン　クスノキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '35202宇部市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '422',
    name: '厚狭郡　山陽町',
    kana_name: {
      half_upper: 'ｱｻｸﾞﾝ ｻﾝﾖｳﾁﾖｳ',
      full_lower: 'アサグン　サンヨウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '35216山陽小野田市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '440',
    name: '豊浦郡',
    kana_name: { half_upper: 'ﾄﾖｳﾗｸﾞﾝ', full_lower: 'トヨウラグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-13',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '441',
    name: '豊浦郡　菊川町',
    kana_name: {
      half_upper: 'ﾄﾖｳﾗｸﾞﾝ ｷｸｶﾞﾜﾁﾖｳ',
      full_lower: 'トヨウラグン　キクガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-13',
    remark: { start: null, end: '35201下関市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '442',
    name: '豊浦郡　豊田町',
    kana_name: {
      half_upper: 'ﾄﾖｳﾗｸﾞﾝ ﾄﾖﾀﾁﾖｳ',
      full_lower: 'トヨウラグン　トヨタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-13',
    remark: { start: null, end: '35201下関市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '443',
    name: '豊浦郡　豊浦町',
    kana_name: {
      half_upper: 'ﾄﾖｳﾗｸﾞﾝ ﾄﾖｳﾗﾁﾖｳ',
      full_lower: 'トヨウラグン　トヨウラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-13',
    remark: { start: null, end: '35201下関市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '444',
    name: '豊浦郡　豊北町',
    kana_name: {
      half_upper: 'ﾄﾖｳﾗｸﾞﾝ ﾎｳﾎｸﾁﾖｳ',
      full_lower: 'トヨウラグン　ホウホクチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-13',
    remark: { start: null, end: '35201下関市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '460',
    name: '美祢郡',
    kana_name: { half_upper: 'ﾐﾈｸﾞﾝ', full_lower: 'ミネグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2008-03-21',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '461',
    name: '美祢郡　美東町',
    kana_name: {
      half_upper: 'ﾐﾈｸﾞﾝ ﾐﾄｳﾁﾖｳ',
      full_lower: 'ミネグン　ミトウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2008-03-21',
    remark: { start: null, end: '35213美祢市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '462',
    name: '美祢郡　秋芳町',
    kana_name: {
      half_upper: 'ﾐﾈｸﾞﾝ ｼﾕｳﾎｳﾁﾖｳ',
      full_lower: 'ミネグン　シュウホウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2008-03-21',
    remark: { start: null, end: '35213美祢市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '480',
    name: '大津郡',
    kana_name: { half_upper: 'ｵｵﾂｸﾞﾝ', full_lower: 'オオツグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '481',
    name: '大津郡　三隅町',
    kana_name: {
      half_upper: 'ｵｵﾂｸﾞﾝ ﾐｽﾐﾁﾖｳ',
      full_lower: 'オオツグン　ミスミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '35211長門市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '482',
    name: '大津郡　日置町',
    kana_name: {
      half_upper: 'ｵｵﾂｸﾞﾝ ﾍｷﾁﾖｳ',
      full_lower: 'オオツグン　ヘキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '35211長門市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '483',
    name: '大津郡　油谷町',
    kana_name: {
      half_upper: 'ｵｵﾂｸﾞﾝ ﾕﾔﾁﾖｳ',
      full_lower: 'オオツグン　ユヤチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '35211長門市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '500',
    name: '阿武郡',
    kana_name: { half_upper: 'ｱﾌﾞｸﾞﾝ', full_lower: 'アブグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '501',
    name: '阿武郡　川上村',
    kana_name: {
      half_upper: 'ｱﾌﾞｸﾞﾝ ｶﾜｶﾐｿﾝ',
      full_lower: 'アブグン　カワカミソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-06',
    remark: { start: null, end: '35204萩市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '502',
    name: '阿武郡　阿武町',
    kana_name: {
      half_upper: 'ｱﾌﾞｸﾞﾝ ｱﾌﾞﾁﾖｳ',
      full_lower: 'アブグン　アブチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '503',
    name: '阿武郡　田万川町',
    kana_name: {
      half_upper: 'ｱﾌﾞｸﾞﾝ ﾀﾏｶﾞﾜﾁﾖｳ',
      full_lower: 'アブグン　タマガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-06',
    remark: { start: null, end: '35204萩市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '504',
    name: '阿武郡　阿東町',
    kana_name: {
      half_upper: 'ｱﾌﾞｸﾞﾝ ｱﾄｳﾁﾖｳ',
      full_lower: 'アブグン　アトウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-01-16',
    remark: { start: null, end: '35203山口市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '505',
    name: '阿武郡　むつみ村',
    kana_name: {
      half_upper: 'ｱﾌﾞｸﾞﾝ ﾑﾂﾐｿﾝ',
      full_lower: 'アブグン　ムツミソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-06',
    remark: { start: null, end: '35204萩市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '506',
    name: '阿武郡　須佐町',
    kana_name: {
      half_upper: 'ｱﾌﾞｸﾞﾝ ｽｻﾁﾖｳ',
      full_lower: 'アブグン　スサチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-06',
    remark: { start: null, end: '35204萩市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '507',
    name: '阿武郡　旭村',
    kana_name: {
      half_upper: 'ｱﾌﾞｸﾞﾝ ｱｻﾋｿﾝ',
      full_lower: 'アブグン　アサヒソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-06',
    remark: { start: null, end: '35204萩市', other: null },
    note: null,
  },
  {
    pref_code: '35',
    city_code: '508',
    name: '阿武郡　福栄村',
    kana_name: {
      half_upper: 'ｱﾌﾞｸﾞﾝ ﾌｸｴｿﾝ',
      full_lower: 'アブグン　フクエソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-06',
    remark: { start: null, end: '35204萩市', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '201',
    name: '徳島市',
    kana_name: { half_upper: 'ﾄｸｼﾏｼ', full_lower: 'トクシマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '202',
    name: '鳴門市',
    kana_name: { half_upper: 'ﾅﾙﾄｼ', full_lower: 'ナルトシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '203',
    name: '小松島市',
    kana_name: { half_upper: 'ｺﾏﾂｼﾏｼ', full_lower: 'コマツシマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '204',
    name: '阿南市',
    kana_name: { half_upper: 'ｱﾅﾝｼ', full_lower: 'アナンシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '205',
    name: '吉野川市',
    kana_name: { half_upper: 'ﾖｼﾉｶﾞﾜｼ', full_lower: 'ヨシノガワシ' },
    en: null,
    start_date: '2004-10-01',
    end_date: null,
    remark: { start: 'H16/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '206',
    name: '阿波市',
    kana_name: { half_upper: 'ｱﾜｼ', full_lower: 'アワシ' },
    en: null,
    start_date: '2005-04-01',
    end_date: null,
    remark: { start: 'H17/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '207',
    name: '美馬市',
    kana_name: { half_upper: 'ﾐﾏｼ', full_lower: 'ミマシ' },
    en: null,
    start_date: '2005-03-01',
    end_date: null,
    remark: { start: 'H17/03/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '208',
    name: '三好市',
    kana_name: { half_upper: 'ﾐﾖｼｼ', full_lower: 'ミヨシシ' },
    en: null,
    start_date: '2006-03-01',
    end_date: null,
    remark: { start: 'H18/03/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '300',
    name: '勝浦郡',
    kana_name: { half_upper: 'ｶﾂｳﾗｸﾞﾝ', full_lower: 'カツウラグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '301',
    name: '勝浦郡　勝浦町',
    kana_name: {
      half_upper: 'ｶﾂｳﾗｸﾞﾝ ｶﾂｳﾗﾁﾖｳ',
      full_lower: 'カツウラグン　カツウラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '302',
    name: '勝浦郡　上勝町',
    kana_name: {
      half_upper: 'ｶﾂｳﾗｸﾞﾝ ｶﾐｶﾂﾁﾖｳ',
      full_lower: 'カツウラグン　カミカツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '320',
    name: '名東郡',
    kana_name: { half_upper: 'ﾐﾖｳﾄﾞｳｸﾞﾝ', full_lower: 'ミョウドウグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '321',
    name: '名東郡　佐那河内村',
    kana_name: {
      half_upper: 'ﾐﾖｳﾄﾞｳｸﾞﾝ ｻﾅｺﾞｳﾁｿﾝ',
      full_lower: 'ミョウドウグン　サナゴウチソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '340',
    name: '名西郡',
    kana_name: { half_upper: 'ﾐﾖｳｻﾞｲｸﾞﾝ', full_lower: 'ミョウザイグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '341',
    name: '名西郡　石井町',
    kana_name: {
      half_upper: 'ﾐﾖｳｻﾞｲｸﾞﾝ ｲｼｲﾁﾖｳ',
      full_lower: 'ミョウザイグン　イシイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '342',
    name: '名西郡　神山町',
    kana_name: {
      half_upper: 'ﾐﾖｳｻﾞｲｸﾞﾝ ｶﾐﾔﾏﾁﾖｳ',
      full_lower: 'ミョウザイグン　カミヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '360',
    name: '那賀郡',
    kana_name: { half_upper: 'ﾅｶｸﾞﾝ', full_lower: 'ナカグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '361',
    name: '那賀郡　那賀川町',
    kana_name: {
      half_upper: 'ﾅｶｸﾞﾝ ﾅｶｶﾞﾜﾁﾖｳ',
      full_lower: 'ナカグン　ナカガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '36204阿南市', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '362',
    name: '那賀郡　羽ノ浦町',
    kana_name: {
      half_upper: 'ﾅｶｸﾞﾝ ﾊﾉｳﾗﾁﾖｳ',
      full_lower: 'ナカグン　ハノウラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '36204阿南市', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '363',
    name: '那賀郡　鷲敷町',
    kana_name: {
      half_upper: 'ﾅｶｸﾞﾝ ﾜｼﾞｷﾁﾖｳ',
      full_lower: 'ナカグン　ワジキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '36368那賀郡　那賀町', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '364',
    name: '那賀郡　相生町',
    kana_name: {
      half_upper: 'ﾅｶｸﾞﾝ ｱｲｵｲﾁﾖｳ',
      full_lower: 'ナカグン　アイオイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '36368那賀郡　那賀町', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '365',
    name: '那賀郡　上那賀町',
    kana_name: {
      half_upper: 'ﾅｶｸﾞﾝ ｶﾐﾅｶﾁﾖｳ',
      full_lower: 'ナカグン　カミナカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '36368那賀郡　那賀町', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '366',
    name: '那賀郡　木沢村',
    kana_name: {
      half_upper: 'ﾅｶｸﾞﾝ ｷｻﾜｿﾝ',
      full_lower: 'ナカグン　キサワソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '36368那賀郡　那賀町', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '367',
    name: '那賀郡　木頭村',
    kana_name: {
      half_upper: 'ﾅｶｸﾞﾝ ｷﾄｳｿﾝ',
      full_lower: 'ナカグン　キトウソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '36368那賀郡　那賀町', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '368',
    name: '那賀郡　那賀町',
    kana_name: {
      half_upper: 'ﾅｶｸﾞﾝ ﾅｶﾁﾖｳ',
      full_lower: 'ナカグン　ナカチョウ',
    },
    en: null,
    start_date: '2005-03-01',
    end_date: null,
    remark: { start: 'H17/03/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '380',
    name: '海部郡',
    kana_name: { half_upper: 'ｶｲﾌｸﾞﾝ', full_lower: 'カイフグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '381',
    name: '海部郡　由岐町',
    kana_name: {
      half_upper: 'ｶｲﾌｸﾞﾝ ﾕｷﾁﾖｳ',
      full_lower: 'カイフグン　ユキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '36387海部郡　美波町', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '382',
    name: '海部郡　日和佐町',
    kana_name: {
      half_upper: 'ｶｲﾌｸﾞﾝ ﾋﾜｻﾁﾖｳ',
      full_lower: 'カイフグン　ヒワサチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '36387海部郡　美波町', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '383',
    name: '海部郡　牟岐町',
    kana_name: {
      half_upper: 'ｶｲﾌｸﾞﾝ ﾑｷﾞﾁﾖｳ',
      full_lower: 'カイフグン　ムギチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '384',
    name: '海部郡　海南町',
    kana_name: {
      half_upper: 'ｶｲﾌｸﾞﾝ ｶｲﾅﾝﾁﾖｳ',
      full_lower: 'カイフグン　カイナンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '36388海部郡　海陽町', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '385',
    name: '海部郡　海部町',
    kana_name: {
      half_upper: 'ｶｲﾌｸﾞﾝ ｶｲﾌﾁﾖｳ',
      full_lower: 'カイフグン　カイフチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '36388海部郡　海陽町', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '386',
    name: '海部郡　宍喰町',
    kana_name: {
      half_upper: 'ｶｲﾌｸﾞﾝ ｼｼｸｲﾁﾖｳ',
      full_lower: 'カイフグン　シシクイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '36388海部郡　海陽町', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '387',
    name: '海部郡　美波町',
    kana_name: {
      half_upper: 'ｶｲﾌｸﾞﾝ ﾐﾅﾐﾁﾖｳ',
      full_lower: 'カイフグン　ミナミチョウ',
    },
    en: null,
    start_date: '2006-03-31',
    end_date: null,
    remark: { start: 'H18/03/31新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '388',
    name: '海部郡　海陽町',
    kana_name: {
      half_upper: 'ｶｲﾌｸﾞﾝ ｶｲﾖｳﾁﾖｳ',
      full_lower: 'カイフグン　カイヨウチョウ',
    },
    en: null,
    start_date: '2006-03-31',
    end_date: null,
    remark: { start: 'H18/03/31新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '400',
    name: '板野郡',
    kana_name: { half_upper: 'ｲﾀﾉｸﾞﾝ', full_lower: 'イタノグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '401',
    name: '板野郡　松茂町',
    kana_name: {
      half_upper: 'ｲﾀﾉｸﾞﾝ ﾏﾂｼｹﾞﾁﾖｳ',
      full_lower: 'イタノグン　マツシゲチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '402',
    name: '板野郡　北島町',
    kana_name: {
      half_upper: 'ｲﾀﾉｸﾞﾝ ｷﾀｼﾞﾏﾁﾖｳ',
      full_lower: 'イタノグン　キタジマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '403',
    name: '板野郡　藍住町',
    kana_name: {
      half_upper: 'ｲﾀﾉｸﾞﾝ ｱｲｽﾞﾐﾁﾖｳ',
      full_lower: 'イタノグン　アイズミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '404',
    name: '板野郡　板野町',
    kana_name: {
      half_upper: 'ｲﾀﾉｸﾞﾝ ｲﾀﾉﾁﾖｳ',
      full_lower: 'イタノグン　イタノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '405',
    name: '板野郡　上板町',
    kana_name: {
      half_upper: 'ｲﾀﾉｸﾞﾝ ｶﾐｲﾀﾁﾖｳ',
      full_lower: 'イタノグン　カミイタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '406',
    name: '板野郡　吉野町',
    kana_name: {
      half_upper: 'ｲﾀﾉｸﾞﾝ ﾖｼﾉﾁﾖｳ',
      full_lower: 'イタノグン　ヨシノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '36206阿波市', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '407',
    name: '板野郡　土成町',
    kana_name: {
      half_upper: 'ｲﾀﾉｸﾞﾝ ﾄﾞﾅﾘﾁﾖｳ',
      full_lower: 'イタノグン　ドナリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '36206阿波市', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '420',
    name: '阿波郡',
    kana_name: { half_upper: 'ｱﾜｸﾞﾝ', full_lower: 'アワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '421',
    name: '阿波郡　市場町',
    kana_name: {
      half_upper: 'ｱﾜｸﾞﾝ ｲﾁﾊﾞﾁﾖｳ',
      full_lower: 'アワグン　イチバチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '36206阿波市', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '422',
    name: '阿波郡　阿波町',
    kana_name: {
      half_upper: 'ｱﾜｸﾞﾝ ｱﾜﾁﾖｳ',
      full_lower: 'アワグン　アワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '36206阿波市', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '440',
    name: '麻植郡',
    kana_name: { half_upper: 'ｵｴｸﾞﾝ', full_lower: 'オエグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '441',
    name: '麻植郡　鴨島町',
    kana_name: {
      half_upper: 'ｵｴｸﾞﾝ ｶﾓｼﾞﾏﾁﾖｳ',
      full_lower: 'オエグン　カモジマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '36205吉野川市', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '442',
    name: '麻植郡　川島町',
    kana_name: {
      half_upper: 'ｵｴｸﾞﾝ ｶﾜｼﾏﾁﾖｳ',
      full_lower: 'オエグン　カワシマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '36205吉野川市', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '443',
    name: '麻植郡　山川町',
    kana_name: {
      half_upper: 'ｵｴｸﾞﾝ ﾔﾏｶﾜﾁﾖｳ',
      full_lower: 'オエグン　ヤマカワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '36205吉野川市', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '444',
    name: '麻植郡　美郷村',
    kana_name: {
      half_upper: 'ｵｴｸﾞﾝ ﾐｻﾄｿﾝ',
      full_lower: 'オエグン　ミサトソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '36205吉野川市', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '445',
    name: '麻植郡　木屋平村',
    kana_name: {
      half_upper: 'ｵｴｸﾞﾝ ｺﾔﾀﾞｲﾗｿﾝ',
      full_lower: 'オエグン　コヤダイラソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1973-07-01',
    remark: { start: null, end: '36467美馬郡　木屋平村', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '460',
    name: '美馬郡',
    kana_name: { half_upper: 'ﾐﾏｸﾞﾝ', full_lower: 'ミマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '461',
    name: '美馬郡　脇町',
    kana_name: { half_upper: 'ﾐﾏｸﾞﾝ ﾜｷﾏﾁ', full_lower: 'ミマグン　ワキマチ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '36207美馬市', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '462',
    name: '美馬郡　美馬町',
    kana_name: {
      half_upper: 'ﾐﾏｸﾞﾝ ﾐﾏﾁﾖｳ',
      full_lower: 'ミマグン　ミマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '36207美馬市', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '463',
    name: '美馬郡　半田町',
    kana_name: {
      half_upper: 'ﾐﾏｸﾞﾝ ﾊﾝﾀﾞﾁﾖｳ',
      full_lower: 'ミマグン　ハンダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '36468美馬郡　つるぎ町', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '464',
    name: '美馬郡　貞光町',
    kana_name: {
      half_upper: 'ﾐﾏｸﾞﾝ ｻﾀﾞﾐﾂﾁﾖｳ',
      full_lower: 'ミマグン　サダミツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '36468美馬郡　つるぎ町', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '465',
    name: '美馬郡　一宇村',
    kana_name: {
      half_upper: 'ﾐﾏｸﾞﾝ ｲﾁｳｿﾝ',
      full_lower: 'ミマグン　イチウソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '36468美馬郡　つるぎ町', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '466',
    name: '美馬郡　穴吹町',
    kana_name: {
      half_upper: 'ﾐﾏｸﾞﾝ ｱﾅﾌﾞｷﾁﾖｳ',
      full_lower: 'ミマグン　アナブキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '36207美馬市', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '467',
    name: '美馬郡　木屋平村',
    kana_name: {
      half_upper: 'ﾐﾏｸﾞﾝ ｺﾔﾀﾞｲﾗｿﾝ',
      full_lower: 'ミマグン　コヤダイラソン',
    },
    en: null,
    start_date: '1973-07-01',
    end_date: '2005-03-01',
    remark: { start: 'S48/07/01郡変更', end: '36207美馬市', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '468',
    name: '美馬郡　つるぎ町',
    kana_name: {
      half_upper: 'ﾐﾏｸﾞﾝ ﾂﾙｷﾞﾁﾖｳ',
      full_lower: 'ミマグン　ツルギチョウ',
    },
    en: null,
    start_date: '2005-03-01',
    end_date: null,
    remark: { start: 'H17/03/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '480',
    name: '三好郡',
    kana_name: { half_upper: 'ﾐﾖｼｸﾞﾝ', full_lower: 'ミヨシグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '481',
    name: '三好郡　三野町',
    kana_name: {
      half_upper: 'ﾐﾖｼｸﾞﾝ ﾐﾉﾁﾖｳ',
      full_lower: 'ミヨシグン　ミノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '36208三好市', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '482',
    name: '三好郡　三好町',
    kana_name: {
      half_upper: 'ﾐﾖｼｸﾞﾝ ﾐﾖｼﾁﾖｳ',
      full_lower: 'ミヨシグン　ミヨシチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '36489三好郡　東みよし町', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '483',
    name: '三好郡　池田町',
    kana_name: {
      half_upper: 'ﾐﾖｼｸﾞﾝ ｲｹﾀﾞﾁﾖｳ',
      full_lower: 'ミヨシグン　イケダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '36208三好市', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '484',
    name: '三好郡　山城町',
    kana_name: {
      half_upper: 'ﾐﾖｼｸﾞﾝ ﾔﾏｼﾛﾁﾖｳ',
      full_lower: 'ミヨシグン　ヤマシロチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '36208三好市', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '485',
    name: '三好郡　井川町',
    kana_name: {
      half_upper: 'ﾐﾖｼｸﾞﾝ ｲｶﾜﾁﾖｳ',
      full_lower: 'ミヨシグン　イカワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '36208三好市', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '486',
    name: '三好郡　三加茂町',
    kana_name: {
      half_upper: 'ﾐﾖｼｸﾞﾝ ﾐｶﾓﾁﾖｳ',
      full_lower: 'ミヨシグン　ミカモチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '36489三好郡　東みよし町', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '487',
    name: '三好郡　東祖谷山村',
    kana_name: {
      half_upper: 'ﾐﾖｼｸﾞﾝ ﾋｶﾞｼｲﾔﾔﾏｿﾝ',
      full_lower: 'ミヨシグン　ヒガシイヤヤマソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '36208三好市', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '488',
    name: '三好郡　西祖谷山村',
    kana_name: {
      half_upper: 'ﾐﾖｼｸﾞﾝ ﾆｼｲﾔﾔﾏｿﾝ',
      full_lower: 'ミヨシグン　ニシイヤヤマソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '36208三好市', other: null },
    note: null,
  },
  {
    pref_code: '36',
    city_code: '489',
    name: '三好郡　東みよし町',
    kana_name: {
      half_upper: 'ﾐﾖｼｸﾞﾝ ﾋｶﾞｼﾐﾖｼﾁﾖｳ',
      full_lower: 'ミヨシグン　ヒガシミヨシチョウ',
    },
    en: null,
    start_date: '2006-03-01',
    end_date: null,
    remark: { start: 'H18/03/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '201',
    name: '高松市',
    kana_name: { half_upper: 'ﾀｶﾏﾂｼ', full_lower: 'タカマツシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '202',
    name: '丸亀市',
    kana_name: { half_upper: 'ﾏﾙｶﾞﾒｼ', full_lower: 'マルガメシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '203',
    name: '坂出市',
    kana_name: { half_upper: 'ｻｶｲﾃﾞｼ', full_lower: 'サカイデシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '204',
    name: '善通寺市',
    kana_name: { half_upper: 'ｾﾞﾝﾂｳｼﾞｼ', full_lower: 'ゼンツウジシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '205',
    name: '観音寺市',
    kana_name: { half_upper: 'ｶﾝｵﾝｼﾞｼ', full_lower: 'カンオンジシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '206',
    name: 'さぬき市',
    kana_name: { half_upper: 'ｻﾇｷｼ', full_lower: 'サヌキシ' },
    en: null,
    start_date: '2002-04-01',
    end_date: null,
    remark: { start: 'H14/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '207',
    name: '東かがわ市',
    kana_name: { half_upper: 'ﾋｶﾞｼｶｶﾞﾜｼ', full_lower: 'ヒガシカガワシ' },
    en: null,
    start_date: '2003-04-01',
    end_date: null,
    remark: { start: 'H15/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '208',
    name: '三豊市',
    kana_name: { half_upper: 'ﾐﾄﾖｼ', full_lower: 'ミトヨシ' },
    en: null,
    start_date: '2006-01-01',
    end_date: null,
    remark: { start: 'H18/01/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '300',
    name: '大川郡',
    kana_name: { half_upper: 'ｵｵｶﾜｸﾞﾝ', full_lower: 'オオカワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '301',
    name: '大川郡　引田町',
    kana_name: {
      half_upper: 'ｵｵｶﾜｸﾞﾝ ﾋｹﾀﾁﾖｳ',
      full_lower: 'オオカワグン　ヒケタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: '37207東かがわ市', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '302',
    name: '大川郡　白鳥町',
    kana_name: {
      half_upper: 'ｵｵｶﾜｸﾞﾝ ｼﾛﾄﾘﾁﾖｳ',
      full_lower: 'オオカワグン　シロトリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: '37207東かがわ市', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '303',
    name: '大川郡　大内町',
    kana_name: {
      half_upper: 'ｵｵｶﾜｸﾞﾝ ｵｵﾁﾁﾖｳ',
      full_lower: 'オオカワグン　オオチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: '37207東かがわ市', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '304',
    name: '大川郡　津田町',
    kana_name: {
      half_upper: 'ｵｵｶﾜｸﾞﾝ ﾂﾀﾞﾁﾖｳ',
      full_lower: 'オオカワグン　ツダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2002-04-01',
    remark: { start: null, end: '37206さぬき市', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '305',
    name: '大川郡　大川町',
    kana_name: {
      half_upper: 'ｵｵｶﾜｸﾞﾝ ｵｵｶﾜﾁﾖｳ',
      full_lower: 'オオカワグン　オオカワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2002-04-01',
    remark: { start: null, end: '37206さぬき市', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '306',
    name: '大川郡　志度町',
    kana_name: {
      half_upper: 'ｵｵｶﾜｸﾞﾝ ｼﾄﾞﾁﾖｳ',
      full_lower: 'オオカワグン　シドチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2002-04-01',
    remark: { start: null, end: '37206さぬき市', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '307',
    name: '大川郡　寒川町',
    kana_name: {
      half_upper: 'ｵｵｶﾜｸﾞﾝ ｻﾝｶﾞﾜﾁﾖｳ',
      full_lower: 'オオカワグン　サンガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2002-04-01',
    remark: { start: null, end: '37206さぬき市', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '308',
    name: '大川郡　長尾町',
    kana_name: {
      half_upper: 'ｵｵｶﾜｸﾞﾝ ﾅｶﾞｵﾁﾖｳ',
      full_lower: 'オオカワグン　ナガオチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2002-04-01',
    remark: { start: null, end: '37206さぬき市', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '320',
    name: '小豆郡',
    kana_name: { half_upper: 'ｼﾖｳｽﾞｸﾞﾝ', full_lower: 'ショウズグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '321',
    name: '小豆郡　内海町',
    kana_name: {
      half_upper: 'ｼﾖｳｽﾞｸﾞﾝ ｳﾁﾉﾐﾁﾖｳ',
      full_lower: 'ショウズグン　ウチノミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-21',
    remark: { start: null, end: '37324小豆郡　小豆島町', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '322',
    name: '小豆郡　土庄町',
    kana_name: {
      half_upper: 'ｼﾖｳｽﾞｸﾞﾝ ﾄﾉｼﾖｳﾁﾖｳ',
      full_lower: 'ショウズグン　トノショウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '323',
    name: '小豆郡　池田町',
    kana_name: {
      half_upper: 'ｼﾖｳｽﾞｸﾞﾝ ｲｹﾀﾞﾁﾖｳ',
      full_lower: 'ショウズグン　イケダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-21',
    remark: { start: null, end: '37324小豆郡　小豆島町', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '324',
    name: '小豆郡　小豆島町',
    kana_name: {
      half_upper: 'ｼﾖｳｽﾞｸﾞﾝ ｼﾖｳﾄﾞｼﾏﾁﾖｳ',
      full_lower: 'ショウズグン　ショウドシマチョウ',
    },
    en: null,
    start_date: '2006-03-21',
    end_date: null,
    remark: { start: 'H18/03/21新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '340',
    name: '木田郡',
    kana_name: { half_upper: 'ｷﾀｸﾞﾝ', full_lower: 'キタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '341',
    name: '木田郡　三木町',
    kana_name: {
      half_upper: 'ｷﾀｸﾞﾝ ﾐｷﾁﾖｳ',
      full_lower: 'キタグン　ミキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '342',
    name: '木田郡　牟礼町',
    kana_name: {
      half_upper: 'ｷﾀｸﾞﾝ ﾑﾚﾁﾖｳ',
      full_lower: 'キタグン　ムレチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-10',
    remark: { start: null, end: '37201高松市', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '343',
    name: '木田郡　庵治町',
    kana_name: {
      half_upper: 'ｷﾀｸﾞﾝ ｱｼﾞﾁﾖｳ',
      full_lower: 'キタグン　アジチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-10',
    remark: { start: null, end: '37201高松市', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '360',
    name: '香川郡',
    kana_name: { half_upper: 'ｶｶﾞﾜｸﾞﾝ', full_lower: 'カガワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '361',
    name: '香川郡　塩江町',
    kana_name: {
      half_upper: 'ｶｶﾞﾜｸﾞﾝ ｼｵﾉｴﾁﾖｳ',
      full_lower: 'カガワグン　シオノエチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-09-26',
    remark: { start: null, end: '37201高松市', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '362',
    name: '香川郡　香川町',
    kana_name: {
      half_upper: 'ｶｶﾞﾜｸﾞﾝ ｶｶﾞﾜﾁﾖｳ',
      full_lower: 'カガワグン　カガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-10',
    remark: { start: null, end: '37201高松市', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '363',
    name: '香川郡　香南町',
    kana_name: {
      half_upper: 'ｶｶﾞﾜｸﾞﾝ ｺｳﾅﾝﾁﾖｳ',
      full_lower: 'カガワグン　コウナンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-10',
    remark: { start: null, end: '37201高松市', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '364',
    name: '香川郡　直島町',
    kana_name: {
      half_upper: 'ｶｶﾞﾜｸﾞﾝ ﾅｵｼﾏﾁﾖｳ',
      full_lower: 'カガワグン　ナオシマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '380',
    name: '綾歌郡',
    kana_name: { half_upper: 'ｱﾔｳﾀｸﾞﾝ', full_lower: 'アヤウタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '381',
    name: '綾歌郡　綾上町',
    kana_name: {
      half_upper: 'ｱﾔｳﾀｸﾞﾝ ｱﾔｶﾐﾁﾖｳ',
      full_lower: 'アヤウタグン　アヤカミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-21',
    remark: { start: null, end: '37387綾歌郡　綾川町', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '382',
    name: '綾歌郡　綾南町',
    kana_name: {
      half_upper: 'ｱﾔｳﾀｸﾞﾝ ﾘﾖｳﾅﾝﾁﾖｳ',
      full_lower: 'アヤウタグン　リョウナンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-21',
    remark: { start: null, end: '37387綾歌郡　綾川町', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '383',
    name: '綾歌郡　国分寺町',
    kana_name: {
      half_upper: 'ｱﾔｳﾀｸﾞﾝ ｺｸﾌﾞﾝｼﾞﾁﾖｳ',
      full_lower: 'アヤウタグン　コクブンジチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-10',
    remark: { start: null, end: '37201高松市', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '384',
    name: '綾歌郡　綾歌町',
    kana_name: {
      half_upper: 'ｱﾔｳﾀｸﾞﾝ ｱﾔｳﾀﾁﾖｳ',
      full_lower: 'アヤウタグン　アヤウタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '37202丸亀市', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '385',
    name: '綾歌郡　飯山町',
    kana_name: {
      half_upper: 'ｱﾔｳﾀｸﾞﾝ ﾊﾝｻﾞﾝﾁﾖｳ',
      full_lower: 'アヤウタグン　ハンザンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '37202丸亀市', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '386',
    name: '綾歌郡　宇多津町',
    kana_name: {
      half_upper: 'ｱﾔｳﾀｸﾞﾝ ｳﾀﾂﾞﾁﾖｳ',
      full_lower: 'アヤウタグン　ウタヅチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '387',
    name: '綾歌郡　綾川町',
    kana_name: {
      half_upper: 'ｱﾔｳﾀｸﾞﾝ ｱﾔｶﾞﾜﾁﾖｳ',
      full_lower: 'アヤウタグン　アヤガワチョウ',
    },
    en: null,
    start_date: '2006-03-21',
    end_date: null,
    remark: { start: 'H18/03/21新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '400',
    name: '仲多度郡',
    kana_name: { half_upper: 'ﾅｶﾀﾄﾞｸﾞﾝ', full_lower: 'ナカタドグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '401',
    name: '仲多度郡　琴南町',
    kana_name: {
      half_upper: 'ﾅｶﾀﾄﾞｸﾞﾝ ｺﾄﾅﾐﾁﾖｳ',
      full_lower: 'ナカタドグン　コトナミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '37406仲多度郡　まんのう町', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '402',
    name: '仲多度郡　満濃町',
    kana_name: {
      half_upper: 'ﾅｶﾀﾄﾞｸﾞﾝ ﾏﾝﾉｳﾁﾖｳ',
      full_lower: 'ナカタドグン　マンノウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '37406仲多度郡　まんのう町', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '403',
    name: '仲多度郡　琴平町',
    kana_name: {
      half_upper: 'ﾅｶﾀﾄﾞｸﾞﾝ ｺﾄﾋﾗﾁﾖｳ',
      full_lower: 'ナカタドグン　コトヒラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '404',
    name: '仲多度郡　多度津町',
    kana_name: {
      half_upper: 'ﾅｶﾀﾄﾞｸﾞﾝ ﾀﾄﾞﾂﾁﾖｳ',
      full_lower: 'ナカタドグン　タドツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '405',
    name: '仲多度郡　仲南町',
    kana_name: {
      half_upper: 'ﾅｶﾀﾄﾞｸﾞﾝ ﾁﾕｳﾅﾝﾁﾖｳ',
      full_lower: 'ナカタドグン　チュウナンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '37406仲多度郡　まんのう町', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '406',
    name: '仲多度郡　まんのう町',
    kana_name: {
      half_upper: 'ﾅｶﾀﾄﾞｸﾞﾝ ﾏﾝﾉｳﾁﾖｳ',
      full_lower: 'ナカタドグン　マンノウチョウ',
    },
    en: null,
    start_date: '2006-03-20',
    end_date: null,
    remark: { start: 'H18/03/20新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '420',
    name: '三豊郡',
    kana_name: { half_upper: 'ﾐﾄﾖｸﾞﾝ', full_lower: 'ミトヨグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '421',
    name: '三豊郡　高瀬町',
    kana_name: {
      half_upper: 'ﾐﾄﾖｸﾞﾝ ﾀｶｾﾁﾖｳ',
      full_lower: 'ミトヨグン　タカセチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '37208三豊市', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '422',
    name: '三豊郡　山本町',
    kana_name: {
      half_upper: 'ﾐﾄﾖｸﾞﾝ ﾔﾏﾓﾄﾁﾖｳ',
      full_lower: 'ミトヨグン　ヤマモトチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '37208三豊市', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '423',
    name: '三豊郡　三野町',
    kana_name: {
      half_upper: 'ﾐﾄﾖｸﾞﾝ ﾐﾉﾁﾖｳ',
      full_lower: 'ミトヨグン　ミノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '37208三豊市', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '424',
    name: '三豊郡　大野原町',
    kana_name: {
      half_upper: 'ﾐﾄﾖｸﾞﾝ ｵｵﾉﾊﾗﾁﾖｳ',
      full_lower: 'ミトヨグン　オオノハラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-11',
    remark: { start: null, end: '37205観音寺市', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '425',
    name: '三豊郡　豊中町',
    kana_name: {
      half_upper: 'ﾐﾄﾖｸﾞﾝ ﾄﾖﾅｶﾁﾖｳ',
      full_lower: 'ミトヨグン　トヨナカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '37208三豊市', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '426',
    name: '三豊郡　詫間町',
    kana_name: {
      half_upper: 'ﾐﾄﾖｸﾞﾝ ﾀｸﾏﾁﾖｳ',
      full_lower: 'ミトヨグン　タクマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '37208三豊市', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '427',
    name: '三豊郡　仁尾町',
    kana_name: {
      half_upper: 'ﾐﾄﾖｸﾞﾝ ﾆｵﾁﾖｳ',
      full_lower: 'ミトヨグン　ニオチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '37208三豊市', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '428',
    name: '三豊郡　豊浜町',
    kana_name: {
      half_upper: 'ﾐﾄﾖｸﾞﾝ ﾄﾖﾊﾏﾁﾖｳ',
      full_lower: 'ミトヨグン　トヨハマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-11',
    remark: { start: null, end: '37205観音寺市', other: null },
    note: null,
  },
  {
    pref_code: '37',
    city_code: '429',
    name: '三豊郡　財田町',
    kana_name: {
      half_upper: 'ﾐﾄﾖｸﾞﾝ ｻｲﾀﾁﾖｳ',
      full_lower: 'ミトヨグン　サイタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '37208三豊市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '201',
    name: '松山市',
    kana_name: { half_upper: 'ﾏﾂﾔﾏｼ', full_lower: 'マツヤマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '202',
    name: '今治市',
    kana_name: { half_upper: 'ｲﾏﾊﾞﾘｼ', full_lower: 'イマバリシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '203',
    name: '宇和島市',
    kana_name: { half_upper: 'ｳﾜｼﾞﾏｼ', full_lower: 'ウワジマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '204',
    name: '八幡浜市',
    kana_name: { half_upper: 'ﾔﾜﾀﾊﾏｼ', full_lower: 'ヤワタハマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '205',
    name: '新居浜市',
    kana_name: { half_upper: 'ﾆｲﾊﾏｼ', full_lower: 'ニイハマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '206',
    name: '西条市',
    kana_name: { half_upper: 'ｻｲｼﾞﾖｳｼ', full_lower: 'サイジョウシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '207',
    name: '大洲市',
    kana_name: { half_upper: 'ｵｵｽﾞｼ', full_lower: 'オオズシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '208',
    name: '川之江市',
    kana_name: { half_upper: 'ｶﾜﾉｴｼ', full_lower: 'カワノエシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '38213四国中央市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '209',
    name: '伊予三島市',
    kana_name: { half_upper: 'ｲﾖﾐｼﾏｼ', full_lower: 'イヨミシマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '38213四国中央市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '210',
    name: '伊予市',
    kana_name: { half_upper: 'ｲﾖｼ', full_lower: 'イヨシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '211',
    name: '北条市',
    kana_name: { half_upper: 'ﾎｳｼﾞﾖｳｼ', full_lower: 'ホウジョウシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '38201松山市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '212',
    name: '東予市',
    kana_name: { half_upper: 'ﾄｳﾖｼ', full_lower: 'トウヨシ' },
    en: null,
    start_date: '1972-10-01',
    end_date: '2004-11-01',
    remark: { start: 'S47/10/01市制', end: '38206西条市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '213',
    name: '四国中央市',
    kana_name: { half_upper: 'ｼｺｸﾁﾕｳｵｳｼ', full_lower: 'シコクチュウオウシ' },
    en: null,
    start_date: '2004-04-01',
    end_date: null,
    remark: { start: 'H16/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '214',
    name: '西予市',
    kana_name: { half_upper: 'ｾｲﾖｼ', full_lower: 'セイヨシ' },
    en: null,
    start_date: '2004-04-01',
    end_date: null,
    remark: { start: 'H16/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '215',
    name: '東温市',
    kana_name: { half_upper: 'ﾄｳｵﾝｼ', full_lower: 'トウオンシ' },
    en: null,
    start_date: '2004-09-21',
    end_date: null,
    remark: { start: 'H16/09/21新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '300',
    name: '宇摩郡',
    kana_name: { half_upper: 'ｳﾏｸﾞﾝ', full_lower: 'ウマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '301',
    name: '宇摩郡　新宮村',
    kana_name: {
      half_upper: 'ｳﾏｸﾞﾝ ｼﾝｸﾞｳﾑﾗ',
      full_lower: 'ウマグン　シングウムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '38213四国中央市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '302',
    name: '宇摩郡　土居町',
    kana_name: {
      half_upper: 'ｳﾏｸﾞﾝ ﾄﾞｲﾁﾖｳ',
      full_lower: 'ウマグン　ドイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '38213四国中央市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '303',
    name: '宇摩郡　別子山村',
    kana_name: {
      half_upper: 'ｳﾏｸﾞﾝ ﾍﾞﾂｼﾔﾏﾑﾗ',
      full_lower: 'ウマグン　ベッシヤマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: '38205新居浜市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '320',
    name: '周桑郡',
    kana_name: { half_upper: 'ｼﾕｳｿｳｸﾞﾝ', full_lower: 'シュウソウグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '321',
    name: '周桑郡　小松町',
    kana_name: {
      half_upper: 'ｼﾕｳｿｳｸﾞﾝ ｺﾏﾂﾁﾖｳ',
      full_lower: 'シュウソウグン　コマツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '38206西条市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '322',
    name: '周桑郡　壬生川町',
    kana_name: {
      half_upper: 'ｼﾕｳｿｳｸﾞﾝ ﾆﾕｳｶﾞﾜﾁﾖｳ',
      full_lower: 'シュウソウグン　ニュウガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-01-01',
    remark: { start: null, end: '38325周桑郡　東予町', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '323',
    name: '周桑郡　丹原町',
    kana_name: {
      half_upper: 'ｼﾕｳｿｳｸﾞﾝ ﾀﾝﾊﾞﾗﾁﾖｳ',
      full_lower: 'シュウソウグン　タンバラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '38206西条市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '324',
    name: '周桑郡　三芳町',
    kana_name: {
      half_upper: 'ｼﾕｳｿｳｸﾞﾝ ﾐﾖｼﾁﾖｳ',
      full_lower: 'シュウソウグン　ミヨシチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-01-01',
    remark: { start: null, end: '38325周桑郡　東予町', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '325',
    name: '周桑郡　東予町',
    kana_name: {
      half_upper: 'ｼﾕｳｿｳｸﾞﾝ ﾄｳﾖﾁﾖｳ',
      full_lower: 'シュウソウグン　トウヨチョウ',
    },
    en: null,
    start_date: '1971-01-01',
    end_date: '1972-10-01',
    remark: { start: 'S46/01/01新設', end: '38212東予市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '340',
    name: '越智郡',
    kana_name: { half_upper: 'ｵﾁｸﾞﾝ', full_lower: 'オチグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '341',
    name: '越智郡　朝倉村',
    kana_name: {
      half_upper: 'ｵﾁｸﾞﾝ ｱｻｸﾗﾑﾗ',
      full_lower: 'オチグン　アサクラムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-16',
    remark: { start: null, end: '38202今治市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '342',
    name: '越智郡　玉川町',
    kana_name: {
      half_upper: 'ｵﾁｸﾞﾝ ﾀﾏｶﾞﾜﾁﾖｳ',
      full_lower: 'オチグン　タマガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-16',
    remark: { start: null, end: '38202今治市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '343',
    name: '越智郡　波方町',
    kana_name: {
      half_upper: 'ｵﾁｸﾞﾝ ﾅﾐｶﾀﾁﾖｳ',
      full_lower: 'オチグン　ナミカタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-16',
    remark: { start: null, end: '38202今治市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '344',
    name: '越智郡　大西町',
    kana_name: {
      half_upper: 'ｵﾁｸﾞﾝ ｵｵﾆｼﾁﾖｳ',
      full_lower: 'オチグン　オオニシチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-16',
    remark: { start: null, end: '38202今治市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '345',
    name: '越智郡　菊間町',
    kana_name: {
      half_upper: 'ｵﾁｸﾞﾝ ｷｸﾏﾁﾖｳ',
      full_lower: 'オチグン　キクマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-16',
    remark: { start: null, end: '38202今治市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '346',
    name: '越智郡　吉海町',
    kana_name: {
      half_upper: 'ｵﾁｸﾞﾝ ﾖｼｳﾐﾁﾖｳ',
      full_lower: 'オチグン　ヨシウミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-16',
    remark: { start: null, end: '38202今治市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '347',
    name: '越智郡　宮窪町',
    kana_name: {
      half_upper: 'ｵﾁｸﾞﾝ ﾐﾔｸﾎﾞﾁﾖｳ',
      full_lower: 'オチグン　ミヤクボチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-16',
    remark: { start: null, end: '38202今治市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '348',
    name: '越智郡　伯方町',
    kana_name: {
      half_upper: 'ｵﾁｸﾞﾝ ﾊｶﾀﾁﾖｳ',
      full_lower: 'オチグン　ハカタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-16',
    remark: { start: null, end: '38202今治市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '349',
    name: '越智郡　魚島村',
    kana_name: {
      half_upper: 'ｵﾁｸﾞﾝ ｳｵｼﾏﾑﾗ',
      full_lower: 'オチグン　ウオシマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '38356越智郡　上島町', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '350',
    name: '越智郡　弓削町',
    kana_name: {
      half_upper: 'ｵﾁｸﾞﾝ ﾕｹﾞﾁﾖｳ',
      full_lower: 'オチグン　ユゲチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '38356越智郡　上島町', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '351',
    name: '越智郡　生名村',
    kana_name: {
      half_upper: 'ｵﾁｸﾞﾝ ｲｷﾅﾑﾗ',
      full_lower: 'オチグン　イキナムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '38356越智郡　上島町', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '352',
    name: '越智郡　岩城村',
    kana_name: {
      half_upper: 'ｵﾁｸﾞﾝ ｲﾜｷﾞﾑﾗ',
      full_lower: 'オチグン　イワギムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '38356越智郡　上島町', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '353',
    name: '越智郡　上浦町',
    kana_name: {
      half_upper: 'ｵﾁｸﾞﾝ ｶﾐｳﾗﾁﾖｳ',
      full_lower: 'オチグン　カミウラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-16',
    remark: { start: null, end: '38202今治市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '354',
    name: '越智郡　大三島町',
    kana_name: {
      half_upper: 'ｵﾁｸﾞﾝ ｵｵﾐｼﾏﾁﾖｳ',
      full_lower: 'オチグン　オオミシマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-16',
    remark: { start: null, end: '38202今治市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '355',
    name: '越智郡　関前村',
    kana_name: {
      half_upper: 'ｵﾁｸﾞﾝ ｾｷｾﾞﾝﾑﾗ',
      full_lower: 'オチグン　セキゼンムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-16',
    remark: { start: null, end: '38202今治市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '356',
    name: '越智郡　上島町',
    kana_name: {
      half_upper: 'ｵﾁｸﾞﾝ ｶﾐｼﾞﾏﾁﾖｳ',
      full_lower: 'オチグン　カミジマチョウ',
    },
    en: null,
    start_date: '2004-10-01',
    end_date: null,
    remark: { start: 'H16/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '360',
    name: '温泉郡',
    kana_name: { half_upper: 'ｵﾝｾﾝｸﾞﾝ', full_lower: 'オンセングン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '361',
    name: '温泉郡　重信町',
    kana_name: {
      half_upper: 'ｵﾝｾﾝｸﾞﾝ ｼｹﾞﾉﾌﾞﾁﾖｳ',
      full_lower: 'オンセングン　シゲノブチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-09-21',
    remark: { start: null, end: '38215東温市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '362',
    name: '温泉郡　川内町',
    kana_name: {
      half_upper: 'ｵﾝｾﾝｸﾞﾝ ｶﾜｳﾁﾁﾖｳ',
      full_lower: 'オンセングン　カワウチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-09-21',
    remark: { start: null, end: '38215東温市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '363',
    name: '温泉郡　中島町',
    kana_name: {
      half_upper: 'ｵﾝｾﾝｸﾞﾝ ﾅｶｼﾞﾏﾁﾖｳ',
      full_lower: 'オンセングン　ナカジマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '38201松山市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '380',
    name: '上浮穴郡',
    kana_name: { half_upper: 'ｶﾐｳｹﾅｸﾞﾝ', full_lower: 'カミウケナグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '381',
    name: '上浮穴郡　久万町',
    kana_name: {
      half_upper: 'ｶﾐｳｹﾅｸﾞﾝ ｸﾏﾁﾖｳ',
      full_lower: 'カミウケナグン　クマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-08-01',
    remark: { start: null, end: '38386上浮穴郡　久万高原町', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '382',
    name: '上浮穴郡　面河村',
    kana_name: {
      half_upper: 'ｶﾐｳｹﾅｸﾞﾝ ｵﾓｺﾞﾑﾗ',
      full_lower: 'カミウケナグン　オモゴムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-08-01',
    remark: { start: null, end: '38386上浮穴郡　久万高原町', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '383',
    name: '上浮穴郡　美川村',
    kana_name: {
      half_upper: 'ｶﾐｳｹﾅｸﾞﾝ ﾐｶﾜﾑﾗ',
      full_lower: 'カミウケナグン　ミカワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-08-01',
    remark: { start: null, end: '38386上浮穴郡　久万高原町', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '384',
    name: '上浮穴郡　柳谷村',
    kana_name: {
      half_upper: 'ｶﾐｳｹﾅｸﾞﾝ ﾔﾅﾀﾞﾆﾑﾗ',
      full_lower: 'カミウケナグン　ヤナダニムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-08-01',
    remark: { start: null, end: '38386上浮穴郡　久万高原町', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '385',
    name: '上浮穴郡　小田町',
    kana_name: {
      half_upper: 'ｶﾐｳｹﾅｸﾞﾝ ｵﾀﾞﾁﾖｳ',
      full_lower: 'カミウケナグン　オダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '38422喜多郡　内子町', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '386',
    name: '上浮穴郡　久万高原町',
    kana_name: {
      half_upper: 'ｶﾐｳｹﾅｸﾞﾝ ｸﾏｺｳｹﾞﾝﾁﾖｳ',
      full_lower: 'カミウケナグン　クマコウゲンチョウ',
    },
    en: null,
    start_date: '2004-08-01',
    end_date: null,
    remark: { start: 'H16/08/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '400',
    name: '伊予郡',
    kana_name: { half_upper: 'ｲﾖｸﾞﾝ', full_lower: 'イヨグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '401',
    name: '伊予郡　松前町',
    kana_name: {
      half_upper: 'ｲﾖｸﾞﾝ ﾏｻｷﾁﾖｳ',
      full_lower: 'イヨグン　マサキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '402',
    name: '伊予郡　砥部町',
    kana_name: {
      half_upper: 'ｲﾖｸﾞﾝ ﾄﾍﾞﾁﾖｳ',
      full_lower: 'イヨグン　トベチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '403',
    name: '伊予郡　広田村',
    kana_name: {
      half_upper: 'ｲﾖｸﾞﾝ ﾋﾛﾀﾑﾗ',
      full_lower: 'イヨグン　ヒロタムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '38402伊予郡　砥部町', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '404',
    name: '伊予郡　中山町',
    kana_name: {
      half_upper: 'ｲﾖｸﾞﾝ ﾅｶﾔﾏﾁﾖｳ',
      full_lower: 'イヨグン　ナカヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '38210伊予市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '405',
    name: '伊予郡　双海町',
    kana_name: {
      half_upper: 'ｲﾖｸﾞﾝ ﾌﾀﾐﾁﾖｳ',
      full_lower: 'イヨグン　フタミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '38210伊予市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '420',
    name: '喜多郡',
    kana_name: { half_upper: 'ｷﾀｸﾞﾝ', full_lower: 'キタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '421',
    name: '喜多郡　長浜町',
    kana_name: {
      half_upper: 'ｷﾀｸﾞﾝ ﾅｶﾞﾊﾏﾁﾖｳ',
      full_lower: 'キタグン　ナガハマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-11',
    remark: { start: null, end: '38207大洲市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '422',
    name: '喜多郡　内子町',
    kana_name: {
      half_upper: 'ｷﾀｸﾞﾝ ｳﾁｺﾁﾖｳ',
      full_lower: 'キタグン　ウチコチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '423',
    name: '喜多郡　五十崎町',
    kana_name: {
      half_upper: 'ｷﾀｸﾞﾝ ｲｶｻﾞｷﾁﾖｳ',
      full_lower: 'キタグン　イカザキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '38422喜多郡　内子町', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '424',
    name: '喜多郡　肱川町',
    kana_name: {
      half_upper: 'ｷﾀｸﾞﾝ ﾋｼﾞｶﾜﾁﾖｳ',
      full_lower: 'キタグン　ヒジカワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-11',
    remark: { start: null, end: '38207大洲市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '425',
    name: '喜多郡　河辺村',
    kana_name: {
      half_upper: 'ｷﾀｸﾞﾝ ｶﾜﾍﾞﾑﾗ',
      full_lower: 'キタグン　カワベムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-11',
    remark: { start: null, end: '38207大洲市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '440',
    name: '西宇和郡',
    kana_name: { half_upper: 'ﾆｼｳﾜｸﾞﾝ', full_lower: 'ニシウワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '441',
    name: '西宇和郡　保内町',
    kana_name: {
      half_upper: 'ﾆｼｳﾜｸﾞﾝ ﾎﾅｲﾁﾖｳ',
      full_lower: 'ニシウワグン　ホナイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '38204八幡浜市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '442',
    name: '西宇和郡　伊方町',
    kana_name: {
      half_upper: 'ﾆｼｳﾜｸﾞﾝ ｲｶﾀﾁﾖｳ',
      full_lower: 'ニシウワグン　イカタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '443',
    name: '西宇和郡　瀬戸町',
    kana_name: {
      half_upper: 'ﾆｼｳﾜｸﾞﾝ ｾﾄﾁﾖｳ',
      full_lower: 'ニシウワグン　セトチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '38442西宇和郡　伊方町', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '444',
    name: '西宇和郡　三崎町',
    kana_name: {
      half_upper: 'ﾆｼｳﾜｸﾞﾝ ﾐｻｷﾁﾖｳ',
      full_lower: 'ニシウワグン　ミサキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '38442西宇和郡　伊方町', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '445',
    name: '西宇和郡　三瓶町',
    kana_name: {
      half_upper: 'ﾆｼｳﾜｸﾞﾝ ﾐｶﾒﾁﾖｳ',
      full_lower: 'ニシウワグン　ミカメチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '38214西予市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '460',
    name: '東宇和郡',
    kana_name: { half_upper: 'ﾋｶﾞｼｳﾜｸﾞﾝ', full_lower: 'ヒガシウワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '461',
    name: '東宇和郡　明浜町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｳﾜｸﾞﾝ ｱｹﾊﾏﾁﾖｳ',
      full_lower: 'ヒガシウワグン　アケハマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '38214西予市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '462',
    name: '東宇和郡　宇和町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｳﾜｸﾞﾝ ｳﾜﾁﾖｳ',
      full_lower: 'ヒガシウワグン　ウワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '38214西予市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '463',
    name: '東宇和郡　野村町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｳﾜｸﾞﾝ ﾉﾑﾗﾁﾖｳ',
      full_lower: 'ヒガシウワグン　ノムラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '38214西予市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '464',
    name: '東宇和郡　城川町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｳﾜｸﾞﾝ ｼﾛｶﾜﾁﾖｳ',
      full_lower: 'ヒガシウワグン　シロカワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-04-01',
    remark: { start: null, end: '38214西予市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '480',
    name: '北宇和郡',
    kana_name: { half_upper: 'ｷﾀｳﾜｸﾞﾝ', full_lower: 'キタウワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '481',
    name: '北宇和郡　吉田町',
    kana_name: {
      half_upper: 'ｷﾀｳﾜｸﾞﾝ ﾖｼﾀﾞﾁﾖｳ',
      full_lower: 'キタウワグン　ヨシダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-08-01',
    remark: { start: null, end: '38203宇和島市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '482',
    name: '北宇和郡　三間町',
    kana_name: {
      half_upper: 'ｷﾀｳﾜｸﾞﾝ ﾐﾏﾁﾖｳ',
      full_lower: 'キタウワグン　ミマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-08-01',
    remark: { start: null, end: '38203宇和島市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '483',
    name: '北宇和郡　広見町',
    kana_name: {
      half_upper: 'ｷﾀｳﾜｸﾞﾝ ﾋﾛﾐﾁﾖｳ',
      full_lower: 'キタウワグン　ヒロミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '38488北宇和郡　鬼北町', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '484',
    name: '北宇和郡　松野町',
    kana_name: {
      half_upper: 'ｷﾀｳﾜｸﾞﾝ ﾏﾂﾉﾁﾖｳ',
      full_lower: 'キタウワグン　マツノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '485',
    name: '北宇和郡　日吉村',
    kana_name: {
      half_upper: 'ｷﾀｳﾜｸﾞﾝ ﾋﾖｼﾑﾗ',
      full_lower: 'キタウワグン　ヒヨシムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '38488北宇和郡　鬼北町', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '486',
    name: '北宇和郡　津島町',
    kana_name: {
      half_upper: 'ｷﾀｳﾜｸﾞﾝ ﾂｼﾏﾁﾖｳ',
      full_lower: 'キタウワグン　ツシマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-08-01',
    remark: { start: null, end: '38203宇和島市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '487',
    name: '北宇和郡　宇和海村',
    kana_name: {
      half_upper: 'ｷﾀｳﾜｸﾞﾝ ｳﾜｳﾐﾑﾗ',
      full_lower: 'キタウワグン　ウワウミムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1974-04-01',
    remark: { start: null, end: '38203宇和島市', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '488',
    name: '北宇和郡　鬼北町',
    kana_name: {
      half_upper: 'ｷﾀｳﾜｸﾞﾝ ｷﾎｸﾁﾖｳ',
      full_lower: 'キタウワグン　キホクチョウ',
    },
    en: null,
    start_date: '2005-01-01',
    end_date: null,
    remark: { start: 'H17/01/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '500',
    name: '南宇和郡',
    kana_name: { half_upper: 'ﾐﾅﾐｳﾜｸﾞﾝ', full_lower: 'ミナミウワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '501',
    name: '南宇和郡　内海村',
    kana_name: {
      half_upper: 'ﾐﾅﾐｳﾜｸﾞﾝ ｳﾁｳﾐﾑﾗ',
      full_lower: 'ミナミウワグン　ウチウミムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '38506南宇和郡　愛南町', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '502',
    name: '南宇和郡　御荘町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｳﾜｸﾞﾝ ﾐｼﾖｳﾁﾖｳ',
      full_lower: 'ミナミウワグン　ミショウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '38506南宇和郡　愛南町', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '503',
    name: '南宇和郡　城辺町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｳﾜｸﾞﾝ ｼﾞﾖｳﾍﾝﾁﾖｳ',
      full_lower: 'ミナミウワグン　ジョウヘンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '38506南宇和郡　愛南町', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '504',
    name: '南宇和郡　一本松町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｳﾜｸﾞﾝ ｲﾂﾎﾟﾝﾏﾂﾁﾖｳ',
      full_lower: 'ミナミウワグン　イッポンマツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '38506南宇和郡　愛南町', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '505',
    name: '南宇和郡　西海町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｳﾜｸﾞﾝ ﾆｼｳﾐﾁﾖｳ',
      full_lower: 'ミナミウワグン　ニシウミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '38506南宇和郡　愛南町', other: null },
    note: null,
  },
  {
    pref_code: '38',
    city_code: '506',
    name: '南宇和郡　愛南町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｳﾜｸﾞﾝ ｱｲﾅﾝﾁﾖｳ',
      full_lower: 'ミナミウワグン　アイナンチョウ',
    },
    en: null,
    start_date: '2004-10-01',
    end_date: null,
    remark: { start: 'H16/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '201',
    name: '高知市',
    kana_name: { half_upper: 'ｺｳﾁｼ', full_lower: 'コウチシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '202',
    name: '室戸市',
    kana_name: { half_upper: 'ﾑﾛﾄｼ', full_lower: 'ムロトシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '203',
    name: '安芸市',
    kana_name: { half_upper: 'ｱｷｼ', full_lower: 'アキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '204',
    name: '南国市',
    kana_name: { half_upper: 'ﾅﾝｺｸｼ', full_lower: 'ナンコクシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '205',
    name: '土佐市',
    kana_name: { half_upper: 'ﾄｻｼ', full_lower: 'トサシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '206',
    name: '須崎市',
    kana_name: { half_upper: 'ｽｻｷｼ', full_lower: 'スサキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '207',
    name: '中村市',
    kana_name: { half_upper: 'ﾅｶﾑﾗｼ', full_lower: 'ナカムラシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-10',
    remark: { start: null, end: '39210四万十市', other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '208',
    name: '宿毛市',
    kana_name: { half_upper: 'ｽｸﾓｼ', full_lower: 'スクモシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '209',
    name: '土佐清水市',
    kana_name: { half_upper: 'ﾄｻｼﾐｽﾞｼ', full_lower: 'トサシミズシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '210',
    name: '四万十市',
    kana_name: { half_upper: 'ｼﾏﾝﾄｼ', full_lower: 'シマントシ' },
    en: null,
    start_date: '2005-04-10',
    end_date: null,
    remark: { start: 'H17/04/10新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '211',
    name: '香南市',
    kana_name: { half_upper: 'ｺｳﾅﾝｼ', full_lower: 'コウナンシ' },
    en: null,
    start_date: '2006-03-01',
    end_date: null,
    remark: { start: 'H18/03/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '212',
    name: '香美市',
    kana_name: { half_upper: 'ｶﾐｼ', full_lower: 'カミシ' },
    en: null,
    start_date: '2006-03-01',
    end_date: null,
    remark: { start: 'H18/03/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '300',
    name: '安芸郡',
    kana_name: { half_upper: 'ｱｷｸﾞﾝ', full_lower: 'アキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '301',
    name: '安芸郡　東洋町',
    kana_name: {
      half_upper: 'ｱｷｸﾞﾝ ﾄｳﾖｳﾁﾖｳ',
      full_lower: 'アキグン　トウヨウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '302',
    name: '安芸郡　奈半利町',
    kana_name: {
      half_upper: 'ｱｷｸﾞﾝ ﾅﾊﾘﾁﾖｳ',
      full_lower: 'アキグン　ナハリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '303',
    name: '安芸郡　田野町',
    kana_name: {
      half_upper: 'ｱｷｸﾞﾝ ﾀﾉﾁﾖｳ',
      full_lower: 'アキグン　タノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '304',
    name: '安芸郡　安田町',
    kana_name: {
      half_upper: 'ｱｷｸﾞﾝ ﾔｽﾀﾞﾁﾖｳ',
      full_lower: 'アキグン　ヤスダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '305',
    name: '安芸郡　北川村',
    kana_name: {
      half_upper: 'ｱｷｸﾞﾝ ｷﾀｶﾞﾜﾑﾗ',
      full_lower: 'アキグン　キタガワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '306',
    name: '安芸郡　馬路村',
    kana_name: {
      half_upper: 'ｱｷｸﾞﾝ ｳﾏｼﾞﾑﾗ',
      full_lower: 'アキグン　ウマジムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '307',
    name: '安芸郡　芸西村',
    kana_name: {
      half_upper: 'ｱｷｸﾞﾝ ｹﾞｲｾｲﾑﾗ',
      full_lower: 'アキグン　ゲイセイムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '320',
    name: '香美郡',
    kana_name: { half_upper: 'ｶﾐｸﾞﾝ', full_lower: 'カミグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '321',
    name: '香美郡　赤岡町',
    kana_name: {
      half_upper: 'ｶﾐｸﾞﾝ ｱｶｵｶﾁﾖｳ',
      full_lower: 'カミグン　アカオカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '39211香南市', other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '322',
    name: '香美郡　香我美町',
    kana_name: {
      half_upper: 'ｶﾐｸﾞﾝ ｶｶﾞﾐﾁﾖｳ',
      full_lower: 'カミグン　カガミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '39211香南市', other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '323',
    name: '香美郡　土佐山田町',
    kana_name: {
      half_upper: 'ｶﾐｸﾞﾝ ﾄｻﾔﾏﾀﾞﾁﾖｳ',
      full_lower: 'カミグン　トサヤマダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '39212香美市', other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '324',
    name: '香美郡　野市町',
    kana_name: {
      half_upper: 'ｶﾐｸﾞﾝ ﾉｲﾁﾁﾖｳ',
      full_lower: 'カミグン　ノイチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '39211香南市', other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '325',
    name: '香美郡　夜須町',
    kana_name: {
      half_upper: 'ｶﾐｸﾞﾝ ﾔｽﾁﾖｳ',
      full_lower: 'カミグン　ヤスチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '39211香南市', other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '326',
    name: '香美郡　香北町',
    kana_name: {
      half_upper: 'ｶﾐｸﾞﾝ ｶﾎｸﾁﾖｳ',
      full_lower: 'カミグン　カホクチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '39212香美市', other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '327',
    name: '香美郡　吉川村',
    kana_name: {
      half_upper: 'ｶﾐｸﾞﾝ ﾖｼｶﾜﾑﾗ',
      full_lower: 'カミグン　ヨシカワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '39211香南市', other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '328',
    name: '香美郡　物部村',
    kana_name: {
      half_upper: 'ｶﾐｸﾞﾝ ﾓﾉﾍﾞｿﾝ',
      full_lower: 'カミグン　モノベソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '39212香美市', other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '340',
    name: '長岡郡',
    kana_name: { half_upper: 'ﾅｶﾞｵｶｸﾞﾝ', full_lower: 'ナガオカグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '341',
    name: '長岡郡　本山町',
    kana_name: {
      half_upper: 'ﾅｶﾞｵｶｸﾞﾝ ﾓﾄﾔﾏﾁﾖｳ',
      full_lower: 'ナガオカグン　モトヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '342',
    name: '長岡郡　大津村',
    kana_name: {
      half_upper: 'ﾅｶﾞｵｶｸﾞﾝ ｵｵﾂﾑﾗ',
      full_lower: 'ナガオカグン　オオツムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-02-01',
    remark: { start: null, end: '39201高知市', other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '343',
    name: '長岡郡　介良村',
    kana_name: {
      half_upper: 'ﾅｶﾞｵｶｸﾞﾝ ｹﾗﾑﾗ',
      full_lower: 'ナガオカグン　ケラムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-02-01',
    remark: { start: null, end: '39201高知市', other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '344',
    name: '長岡郡　大豊町',
    kana_name: {
      half_upper: 'ﾅｶﾞｵｶｸﾞﾝ ｵｵﾄﾖﾁﾖｳ',
      full_lower: 'ナガオカグン　オオトヨチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '360',
    name: '土佐郡',
    kana_name: { half_upper: 'ﾄｻｸﾞﾝ', full_lower: 'トサグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '361',
    name: '土佐郡　鏡村',
    kana_name: {
      half_upper: 'ﾄｻｸﾞﾝ ｶｶﾞﾐﾑﾗ',
      full_lower: 'トサグン　カガミムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '39201高知市', other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '362',
    name: '土佐郡　土佐山村',
    kana_name: {
      half_upper: 'ﾄｻｸﾞﾝ ﾄｻﾔﾏﾑﾗ',
      full_lower: 'トサグン　トサヤマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '39201高知市', other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '363',
    name: '土佐郡　土佐町',
    kana_name: {
      half_upper: 'ﾄｻｸﾞﾝ ﾄｻﾁﾖｳ',
      full_lower: 'トサグン　トサチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '364',
    name: '土佐郡　大川村',
    kana_name: {
      half_upper: 'ﾄｻｸﾞﾝ ｵｵｶﾜﾑﾗ',
      full_lower: 'トサグン　オオカワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '365',
    name: '土佐郡　本川村',
    kana_name: {
      half_upper: 'ﾄｻｸﾞﾝ ﾎﾝｶﾞﾜﾑﾗ',
      full_lower: 'トサグン　ホンガワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '39386吾川郡　いの町', other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '380',
    name: '吾川郡',
    kana_name: { half_upper: 'ｱｶﾞﾜｸﾞﾝ', full_lower: 'アガワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '381',
    name: '吾川郡　伊野町',
    kana_name: {
      half_upper: 'ｱｶﾞﾜｸﾞﾝ ｲﾉﾁﾖｳ',
      full_lower: 'アガワグン　イノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '39386吾川郡　いの町', other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '382',
    name: '吾川郡　池川町',
    kana_name: {
      half_upper: 'ｱｶﾞﾜｸﾞﾝ ｲｹｶﾞﾜﾁﾖｳ',
      full_lower: 'アガワグン　イケガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-08-01',
    remark: { start: null, end: '39387吾川郡　仁淀川町', other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '383',
    name: '吾川郡　春野町',
    kana_name: {
      half_upper: 'ｱｶﾞﾜｸﾞﾝ ﾊﾙﾉﾁﾖｳ',
      full_lower: 'アガワグン　ハルノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2008-01-01',
    remark: { start: null, end: '39201高知市', other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '384',
    name: '吾川郡　吾川村',
    kana_name: {
      half_upper: 'ｱｶﾞﾜｸﾞﾝ ｱｶﾞﾜﾑﾗ',
      full_lower: 'アガワグン　アガワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-08-01',
    remark: { start: null, end: '39387吾川郡　仁淀川町', other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '385',
    name: '吾川郡　吾北村',
    kana_name: {
      half_upper: 'ｱｶﾞﾜｸﾞﾝ ｺﾞﾎｸｿﾝ',
      full_lower: 'アガワグン　ゴホクソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-01',
    remark: { start: null, end: '39386吾川郡　いの町', other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '386',
    name: '吾川郡　いの町',
    kana_name: {
      half_upper: 'ｱｶﾞﾜｸﾞﾝ ｲﾉﾁﾖｳ',
      full_lower: 'アガワグン　イノチョウ',
    },
    en: null,
    start_date: '2004-10-01',
    end_date: null,
    remark: { start: 'H16/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '387',
    name: '吾川郡　仁淀川町',
    kana_name: {
      half_upper: 'ｱｶﾞﾜｸﾞﾝ ﾆﾖﾄﾞｶﾞﾜﾁﾖｳ',
      full_lower: 'アガワグン　ニヨドガワチョウ',
    },
    en: null,
    start_date: '2005-08-01',
    end_date: null,
    remark: { start: 'H17/08/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '400',
    name: '高岡郡',
    kana_name: { half_upper: 'ﾀｶｵｶｸﾞﾝ', full_lower: 'タカオカグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '401',
    name: '高岡郡　中土佐町',
    kana_name: {
      half_upper: 'ﾀｶｵｶｸﾞﾝ ﾅｶﾄｻﾁﾖｳ',
      full_lower: 'タカオカグン　ナカトサチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '402',
    name: '高岡郡　佐川町',
    kana_name: {
      half_upper: 'ﾀｶｵｶｸﾞﾝ ｻｶﾜﾁﾖｳ',
      full_lower: 'タカオカグン　サカワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '403',
    name: '高岡郡　越知町',
    kana_name: {
      half_upper: 'ﾀｶｵｶｸﾞﾝ ｵﾁﾁﾖｳ',
      full_lower: 'タカオカグン　オチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '404',
    name: '高岡郡　窪川町',
    kana_name: {
      half_upper: 'ﾀｶｵｶｸﾞﾝ ｸﾎﾞｶﾜﾁﾖｳ',
      full_lower: 'タカオカグン　クボカワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '39412高岡郡　四万十町', other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '405',
    name: '高岡郡　梼原町',
    kana_name: {
      half_upper: 'ﾀｶｵｶｸﾞﾝ ﾕｽﾊﾗﾁﾖｳ',
      full_lower: 'タカオカグン　ユスハラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '406',
    name: '高岡郡　大野見村',
    kana_name: {
      half_upper: 'ﾀｶｵｶｸﾞﾝ ｵｵﾉﾐｿﾝ',
      full_lower: 'タカオカグン　オオノミソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '39401高岡郡　中土佐町', other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '407',
    name: '高岡郡　東津野村',
    kana_name: {
      half_upper: 'ﾀｶｵｶｸﾞﾝ ﾋｶﾞｼﾂﾉﾑﾗ',
      full_lower: 'タカオカグン　ヒガシツノムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '39411高岡郡　津野町', other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '408',
    name: '高岡郡　葉山村',
    kana_name: {
      half_upper: 'ﾀｶｵｶｸﾞﾝ ﾊﾔﾏﾑﾗ',
      full_lower: 'タカオカグン　ハヤマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-01',
    remark: { start: null, end: '39411高岡郡　津野町', other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '409',
    name: '高岡郡　仁淀村',
    kana_name: {
      half_upper: 'ﾀｶｵｶｸﾞﾝ ﾆﾖﾄﾞﾑﾗ',
      full_lower: 'タカオカグン　ニヨドムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-08-01',
    remark: { start: null, end: '39387吾川郡　仁淀川町', other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '410',
    name: '高岡郡　日高村',
    kana_name: {
      half_upper: 'ﾀｶｵｶｸﾞﾝ ﾋﾀﾞｶﾑﾗ',
      full_lower: 'タカオカグン　ヒダカムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '411',
    name: '高岡郡　津野町',
    kana_name: {
      half_upper: 'ﾀｶｵｶｸﾞﾝ ﾂﾉﾁﾖｳ',
      full_lower: 'タカオカグン　ツノチョウ',
    },
    en: null,
    start_date: '2005-02-01',
    end_date: null,
    remark: { start: 'H17/02/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '412',
    name: '高岡郡　四万十町',
    kana_name: {
      half_upper: 'ﾀｶｵｶｸﾞﾝ ｼﾏﾝﾄﾁﾖｳ',
      full_lower: 'タカオカグン　シマントチョウ',
    },
    en: null,
    start_date: '2006-03-20',
    end_date: null,
    remark: { start: 'H18/03/20新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '420',
    name: '幡多郡',
    kana_name: { half_upper: 'ﾊﾀｸﾞﾝ', full_lower: 'ハタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '421',
    name: '幡多郡　佐賀町',
    kana_name: {
      half_upper: 'ﾊﾀｸﾞﾝ ｻｶﾞﾁﾖｳ',
      full_lower: 'ハタグン　サガチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '39428幡多郡　黒潮町', other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '422',
    name: '幡多郡　大正町',
    kana_name: {
      half_upper: 'ﾊﾀｸﾞﾝ ﾀｲｼﾖｳﾁﾖｳ',
      full_lower: 'ハタグン　タイショウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '39412高岡郡　四万十町', other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '423',
    name: '幡多郡　大方町',
    kana_name: {
      half_upper: 'ﾊﾀｸﾞﾝ ｵｵｶﾞﾀﾁﾖｳ',
      full_lower: 'ハタグン　オオガタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '39428幡多郡　黒潮町', other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '424',
    name: '幡多郡　大月町',
    kana_name: {
      half_upper: 'ﾊﾀｸﾞﾝ ｵｵﾂｷﾁﾖｳ',
      full_lower: 'ハタグン　オオツキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '425',
    name: '幡多郡　十和村',
    kana_name: {
      half_upper: 'ﾊﾀｸﾞﾝ ﾄｵﾜｿﾝ',
      full_lower: 'ハタグン　トオワソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '39412高岡郡　四万十町', other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '426',
    name: '幡多郡　西土佐村',
    kana_name: {
      half_upper: 'ﾊﾀｸﾞﾝ ﾆｼﾄｻﾑﾗ',
      full_lower: 'ハタグン　ニシトサムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-10',
    remark: { start: null, end: '39210四万十市', other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '427',
    name: '幡多郡　三原村',
    kana_name: {
      half_upper: 'ﾊﾀｸﾞﾝ ﾐﾊﾗﾑﾗ',
      full_lower: 'ハタグン　ミハラムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '39',
    city_code: '428',
    name: '幡多郡　黒潮町',
    kana_name: {
      half_upper: 'ﾊﾀｸﾞﾝ ｸﾛｼｵﾁﾖｳ',
      full_lower: 'ハタグン　クロシオチョウ',
    },
    en: null,
    start_date: '2006-03-20',
    end_date: null,
    remark: { start: 'H18/03/20新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '100',
    name: '北九州市',
    kana_name: { half_upper: 'ｷﾀｷﾕｳｼﾕｳｼ', full_lower: 'キタキュウシュウシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '101',
    name: '北九州市　門司区',
    kana_name: {
      half_upper: 'ｷﾀｷﾕｳｼﾕｳｼ ﾓｼﾞｸ',
      full_lower: 'キタキュウシュウシ　モジク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '102',
    name: '北九州市　小倉区',
    kana_name: {
      half_upper: 'ｷﾀｷﾕｳｼﾕｳｼ ｺｸﾗｸ',
      full_lower: 'キタキュウシュウシ　コクラク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1974-04-01',
    remark: {
      start: null,
      end: '40106北九州市　小倉北区と90107北九州市　小倉南区に分割',
      other: null,
    },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '103',
    name: '北九州市　若松区',
    kana_name: {
      half_upper: 'ｷﾀｷﾕｳｼﾕｳｼ ﾜｶﾏﾂｸ',
      full_lower: 'キタキュウシュウシ　ワカマツク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '104',
    name: '北九州市　八幡区',
    kana_name: {
      half_upper: 'ｷﾀｷﾕｳｼﾕｳｼ ﾔﾊﾀｸ',
      full_lower: 'キタキュウシュウシ　ヤハタク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1974-04-01',
    remark: {
      start: null,
      end: '40108北九州市　八幡東区と90109北九州市　八幡西区に分割',
      other: null,
    },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '105',
    name: '北九州市　戸畑区',
    kana_name: {
      half_upper: 'ｷﾀｷﾕｳｼﾕｳｼ ﾄﾊﾞﾀｸ',
      full_lower: 'キタキュウシュウシ　トバタク',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '106',
    name: '北九州市　小倉北区',
    kana_name: {
      half_upper: 'ｷﾀｷﾕｳｼﾕｳｼ ｺｸﾗｷﾀｸ',
      full_lower: 'キタキュウシュウシ　コクラキタク',
    },
    en: null,
    start_date: '1974-04-01',
    end_date: null,
    remark: { start: 'S49/04/01小倉区(40102)を分割', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '107',
    name: '北九州市　小倉南区',
    kana_name: {
      half_upper: 'ｷﾀｷﾕｳｼﾕｳｼ ｺｸﾗﾐﾅﾐｸ',
      full_lower: 'キタキュウシュウシ　コクラミナミク',
    },
    en: null,
    start_date: '1974-04-01',
    end_date: null,
    remark: { start: 'S49/04/01小倉区(40102)を分割', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '108',
    name: '北九州市　八幡東区',
    kana_name: {
      half_upper: 'ｷﾀｷﾕｳｼﾕｳｼ ﾔﾊﾀﾋｶﾞｼｸ',
      full_lower: 'キタキュウシュウシ　ヤハタヒガシク',
    },
    en: null,
    start_date: '1974-04-01',
    end_date: null,
    remark: { start: 'S49/04/01八幡区(40104)を分割', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '109',
    name: '北九州市　八幡西区',
    kana_name: {
      half_upper: 'ｷﾀｷﾕｳｼﾕｳｼ ﾔﾊﾀﾆｼｸ',
      full_lower: 'キタキュウシュウシ　ヤハタニシク',
    },
    en: null,
    start_date: '1974-04-01',
    end_date: null,
    remark: { start: 'S49/04/01八幡区(40104)を分割', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '130',
    name: '福岡市',
    kana_name: { half_upper: 'ﾌｸｵｶｼ', full_lower: 'フクオカシ' },
    en: null,
    start_date: '1972-04-01',
    end_date: null,
    remark: { start: 'S47/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '131',
    name: '福岡市　東区',
    kana_name: {
      half_upper: 'ﾌｸｵｶｼ ﾋｶﾞｼｸ',
      full_lower: 'フクオカシ　ヒガシク',
    },
    en: null,
    start_date: '1972-04-01',
    end_date: null,
    remark: { start: 'S47/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '132',
    name: '福岡市　博多区',
    kana_name: {
      half_upper: 'ﾌｸｵｶｼ ﾊｶﾀｸ',
      full_lower: 'フクオカシ　ハカタク',
    },
    en: null,
    start_date: '1972-04-01',
    end_date: null,
    remark: { start: 'S47/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '133',
    name: '福岡市　中央区',
    kana_name: {
      half_upper: 'ﾌｸｵｶｼ ﾁﾕｳｵｳｸ',
      full_lower: 'フクオカシ　チュウオウク',
    },
    en: null,
    start_date: '1972-04-01',
    end_date: null,
    remark: { start: 'S47/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '134',
    name: '福岡市　南区',
    kana_name: {
      half_upper: 'ﾌｸｵｶｼ ﾐﾅﾐｸ',
      full_lower: 'フクオカシ　ミナミク',
    },
    en: null,
    start_date: '1972-04-01',
    end_date: null,
    remark: { start: 'S47/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '135',
    name: '福岡市　西区',
    kana_name: { half_upper: 'ﾌｸｵｶｼ ﾆｼｸ', full_lower: 'フクオカシ　ニシク' },
    en: null,
    start_date: '1972-04-01',
    end_date: null,
    remark: { start: 'S47/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '136',
    name: '福岡市　城南区',
    kana_name: {
      half_upper: 'ﾌｸｵｶｼ ｼﾞﾖｳﾅﾝｸ',
      full_lower: 'フクオカシ　ジョウナンク',
    },
    en: null,
    start_date: '1982-05-10',
    end_date: null,
    remark: { start: 'S57/05/10西区(40135)から分離', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '137',
    name: '福岡市　早良区',
    kana_name: {
      half_upper: 'ﾌｸｵｶｼ ｻﾜﾗｸ',
      full_lower: 'フクオカシ　サワラク',
    },
    en: null,
    start_date: '1982-05-10',
    end_date: null,
    remark: { start: 'S57/05/10西区(40135)から分離', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '201',
    name: '福岡市',
    kana_name: { half_upper: 'ﾌｸｵｶｼ', full_lower: 'フクオカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-04-01',
    remark: { start: null, end: '40130福岡市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '202',
    name: '大牟田市',
    kana_name: { half_upper: 'ｵｵﾑﾀｼ', full_lower: 'オオムタシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '203',
    name: '久留米市',
    kana_name: { half_upper: 'ｸﾙﾒｼ', full_lower: 'クルメシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '204',
    name: '直方市',
    kana_name: { half_upper: 'ﾉｵｶﾞﾀｼ', full_lower: 'ノオガタシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '205',
    name: '飯塚市',
    kana_name: { half_upper: 'ｲｲﾂﾞｶｼ', full_lower: 'イイヅカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '206',
    name: '田川市',
    kana_name: { half_upper: 'ﾀｶﾞﾜｼ', full_lower: 'タガワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '207',
    name: '柳川市',
    kana_name: { half_upper: 'ﾔﾅｶﾞﾜｼ', full_lower: 'ヤナガワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '208',
    name: '山田市',
    kana_name: { half_upper: 'ﾔﾏﾀﾞｼ', full_lower: 'ヤマダシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '40227嘉麻市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '209',
    name: '甘木市',
    kana_name: { half_upper: 'ｱﾏｷﾞｼ', full_lower: 'アマギシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '40228朝倉市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '210',
    name: '八女市',
    kana_name: { half_upper: 'ﾔﾒｼ', full_lower: 'ヤメシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '211',
    name: '筑後市',
    kana_name: { half_upper: 'ﾁｸｺﾞｼ', full_lower: 'チクゴシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '212',
    name: '大川市',
    kana_name: { half_upper: 'ｵｵｶﾜｼ', full_lower: 'オオカワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '213',
    name: '行橋市',
    kana_name: { half_upper: 'ﾕｸﾊｼｼ', full_lower: 'ユクハシシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '214',
    name: '豊前市',
    kana_name: { half_upper: 'ﾌﾞｾﾞﾝｼ', full_lower: 'ブゼンシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '215',
    name: '中間市',
    kana_name: { half_upper: 'ﾅｶﾏｼ', full_lower: 'ナカマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '216',
    name: '小郡市',
    kana_name: { half_upper: 'ｵｺﾞｵﾘｼ', full_lower: 'オゴオリシ' },
    en: null,
    start_date: '1972-04-01',
    end_date: null,
    remark: { start: 'S47/04/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '217',
    name: '筑紫野市',
    kana_name: { half_upper: 'ﾁｸｼﾉｼ', full_lower: 'チクシノシ' },
    en: null,
    start_date: '1972-04-01',
    end_date: null,
    remark: { start: 'S47/04/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '218',
    name: '春日市',
    kana_name: { half_upper: 'ｶｽｶﾞｼ', full_lower: 'カスガシ' },
    en: null,
    start_date: '1972-04-01',
    end_date: null,
    remark: { start: 'S47/04/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '219',
    name: '大野城市',
    kana_name: { half_upper: 'ｵｵﾉｼﾞﾖｳｼ', full_lower: 'オオノジョウシ' },
    en: null,
    start_date: '1972-04-01',
    end_date: null,
    remark: { start: 'S47/04/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '220',
    name: '宗像市',
    kana_name: { half_upper: 'ﾑﾅｶﾀｼ', full_lower: 'ムナカタシ' },
    en: null,
    start_date: '1981-04-01',
    end_date: null,
    remark: { start: 'S56/04/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '221',
    name: '太宰府市',
    kana_name: { half_upper: 'ﾀﾞｻﾞｲﾌｼ', full_lower: 'ダザイフシ' },
    en: null,
    start_date: '1982-04-01',
    end_date: null,
    remark: { start: 'S57/04/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '222',
    name: '前原市',
    kana_name: { half_upper: 'ﾏｴﾊﾞﾙｼ', full_lower: 'マエバルシ' },
    en: null,
    start_date: '1992-10-01',
    end_date: '2010-01-01',
    remark: { start: 'H04/10/01市制', end: '40230糸島市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '223',
    name: '古賀市',
    kana_name: { half_upper: 'ｺｶﾞｼ', full_lower: 'コガシ' },
    en: null,
    start_date: '1997-10-01',
    end_date: null,
    remark: { start: 'H09/10/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '224',
    name: '福津市',
    kana_name: { half_upper: 'ﾌｸﾂｼ', full_lower: 'フクツシ' },
    en: null,
    start_date: '2005-01-24',
    end_date: null,
    remark: { start: 'H17/01/24新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '225',
    name: 'うきは市',
    kana_name: { half_upper: 'ｳｷﾊｼ', full_lower: 'ウキハシ' },
    en: null,
    start_date: '2005-03-20',
    end_date: null,
    remark: { start: 'H17/03/20新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '226',
    name: '宮若市',
    kana_name: { half_upper: 'ﾐﾔﾜｶｼ', full_lower: 'ミヤワカシ' },
    en: null,
    start_date: '2006-02-11',
    end_date: null,
    remark: { start: 'H18/02/11新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '227',
    name: '嘉麻市',
    kana_name: { half_upper: 'ｶﾏｼ', full_lower: 'カマシ' },
    en: null,
    start_date: '2006-03-27',
    end_date: null,
    remark: { start: 'H18/03/27新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '228',
    name: '朝倉市',
    kana_name: { half_upper: 'ｱｻｸﾗｼ', full_lower: 'アサクラシ' },
    en: null,
    start_date: '2006-03-20',
    end_date: null,
    remark: { start: 'H18/03/20新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '229',
    name: 'みやま市',
    kana_name: { half_upper: 'ﾐﾔﾏｼ', full_lower: 'ミヤマシ' },
    en: null,
    start_date: '2007-01-29',
    end_date: null,
    remark: { start: 'H19/01/29新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '230',
    name: '糸島市',
    kana_name: { half_upper: 'ｲﾄｼﾏｼ', full_lower: 'イトシマシ' },
    en: null,
    start_date: '2010-01-01',
    end_date: null,
    remark: { start: 'H22/01/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '231',
    name: '那珂川市',
    kana_name: { half_upper: 'ﾅｶｶﾞﾜｼ', full_lower: 'ナカガワシ' },
    en: null,
    start_date: '2018-10-01',
    end_date: null,
    remark: { start: 'H30/10/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '300',
    name: '筑紫郡',
    kana_name: { half_upper: 'ﾁｸｼｸﾞﾝ', full_lower: 'チクシグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2018-10-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '301',
    name: '筑紫郡　筑紫野町',
    kana_name: {
      half_upper: 'ﾁｸｼｸﾞﾝ ﾁｸｼﾉﾏﾁ',
      full_lower: 'チクシグン　チクシノマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-04-01',
    remark: { start: null, end: '40217筑紫野市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '302',
    name: '筑紫郡　太宰府町',
    kana_name: {
      half_upper: 'ﾁｸｼｸﾞﾝ ﾀﾞｻﾞｲﾌﾏﾁ',
      full_lower: 'チクシグン　ダザイフマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1982-04-01',
    remark: { start: null, end: '40221太宰府市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '303',
    name: '筑紫郡　春日町',
    kana_name: {
      half_upper: 'ﾁｸｼｸﾞﾝ ｶｽｶﾞﾏﾁ',
      full_lower: 'チクシグン　カスガマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-04-01',
    remark: { start: null, end: '40218春日市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '304',
    name: '筑紫郡　大野町',
    kana_name: {
      half_upper: 'ﾁｸｼｸﾞﾝ ｵｵﾉﾏﾁ',
      full_lower: 'チクシグン　オオノマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-04-01',
    remark: { start: null, end: '40219大野城市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '305',
    name: '筑紫郡　那珂川町',
    kana_name: {
      half_upper: 'ﾁｸｼｸﾞﾝ ﾅｶｶﾞﾜﾏﾁ',
      full_lower: 'チクシグン　ナカガワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2018-10-01',
    remark: { start: null, end: '40231那珂川市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '320',
    name: '早良郡',
    kana_name: { half_upper: 'ｻﾜﾗｸﾞﾝ', full_lower: 'サワラグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '1975-03-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '321',
    name: '早良郡　早良町',
    kana_name: {
      half_upper: 'ｻﾜﾗｸﾞﾝ ｻﾜﾗﾏﾁ',
      full_lower: 'サワラグン　サワラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1975-03-01',
    remark: { start: null, end: '40135福岡市　西区', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '340',
    name: '糟屋郡',
    kana_name: { half_upper: 'ｶｽﾔｸﾞﾝ', full_lower: 'カスヤグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '341',
    name: '糟屋郡　宇美町',
    kana_name: {
      half_upper: 'ｶｽﾔｸﾞﾝ ｳﾐﾏﾁ',
      full_lower: 'カスヤグン　ウミマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '342',
    name: '糟屋郡　篠栗町',
    kana_name: {
      half_upper: 'ｶｽﾔｸﾞﾝ ｻｻｸﾞﾘﾏﾁ',
      full_lower: 'カスヤグン　ササグリマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '343',
    name: '糟屋郡　志免町',
    kana_name: {
      half_upper: 'ｶｽﾔｸﾞﾝ ｼﾒﾏﾁ',
      full_lower: 'カスヤグン　シメマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '344',
    name: '糟屋郡　須恵町',
    kana_name: {
      half_upper: 'ｶｽﾔｸﾞﾝ ｽｴﾏﾁ',
      full_lower: 'カスヤグン　スエマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '345',
    name: '糟屋郡　新宮町',
    kana_name: {
      half_upper: 'ｶｽﾔｸﾞﾝ ｼﾝｸﾞｳﾏﾁ',
      full_lower: 'カスヤグン　シングウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '346',
    name: '糟屋郡　志賀町',
    kana_name: {
      half_upper: 'ｶｽﾔｸﾞﾝ ｼｶﾏﾁ',
      full_lower: 'カスヤグン　シカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-04-05',
    remark: { start: null, end: '40201福岡市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '347',
    name: '糟屋郡　古賀町',
    kana_name: {
      half_upper: 'ｶｽﾔｸﾞﾝ ｺｶﾞﾏﾁ',
      full_lower: 'カスヤグン　コガマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1997-10-01',
    remark: { start: null, end: '40223古賀市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '348',
    name: '糟屋郡　久山町',
    kana_name: {
      half_upper: 'ｶｽﾔｸﾞﾝ ﾋｻﾔﾏﾏﾁ',
      full_lower: 'カスヤグン　ヒサヤママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '349',
    name: '糟屋郡　粕屋町',
    kana_name: {
      half_upper: 'ｶｽﾔｸﾞﾝ ｶｽﾔﾏﾁ',
      full_lower: 'カスヤグン　カスヤマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '360',
    name: '宗像郡',
    kana_name: { half_upper: 'ﾑﾅｶﾀｸﾞﾝ', full_lower: 'ムナカタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '361',
    name: '宗像郡　宗像町',
    kana_name: {
      half_upper: 'ﾑﾅｶﾀｸﾞﾝ ﾑﾅｶﾀﾏﾁ',
      full_lower: 'ムナカタグン　ムナカタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1981-04-01',
    remark: { start: null, end: '40220宗像市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '362',
    name: '宗像郡　福間町',
    kana_name: {
      half_upper: 'ﾑﾅｶﾀｸﾞﾝ ﾌｸﾏﾏﾁ',
      full_lower: 'ムナカタグン　フクママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-24',
    remark: { start: null, end: '40224福津市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '363',
    name: '宗像郡　津屋崎町',
    kana_name: {
      half_upper: 'ﾑﾅｶﾀｸﾞﾝ ﾂﾔｻﾞｷﾏﾁ',
      full_lower: 'ムナカタグン　ツヤザキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-24',
    remark: { start: null, end: '40224福津市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '364',
    name: '宗像郡　玄海町',
    kana_name: {
      half_upper: 'ﾑﾅｶﾀｸﾞﾝ ｹﾞﾝｶｲﾏﾁ',
      full_lower: 'ムナカタグン　ゲンカイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: '40220宗像市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '365',
    name: '宗像郡　大島村',
    kana_name: {
      half_upper: 'ﾑﾅｶﾀｸﾞﾝ ｵｵｼﾏﾑﾗ',
      full_lower: 'ムナカタグン　オオシマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '40220宗像市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '380',
    name: '遠賀郡',
    kana_name: { half_upper: 'ｵﾝｶﾞｸﾞﾝ', full_lower: 'オンガグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '381',
    name: '遠賀郡　芦屋町',
    kana_name: {
      half_upper: 'ｵﾝｶﾞｸﾞﾝ ｱｼﾔﾏﾁ',
      full_lower: 'オンガグン　アシヤマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '382',
    name: '遠賀郡　水巻町',
    kana_name: {
      half_upper: 'ｵﾝｶﾞｸﾞﾝ ﾐｽﾞﾏｷﾏﾁ',
      full_lower: 'オンガグン　ミズマキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '383',
    name: '遠賀郡　岡垣町',
    kana_name: {
      half_upper: 'ｵﾝｶﾞｸﾞﾝ ｵｶｶﾞｷﾏﾁ',
      full_lower: 'オンガグン　オカガキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '384',
    name: '遠賀郡　遠賀町',
    kana_name: {
      half_upper: 'ｵﾝｶﾞｸﾞﾝ ｵﾝｶﾞﾁﾖｳ',
      full_lower: 'オンガグン　オンガチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '400',
    name: '鞍手郡',
    kana_name: { half_upper: 'ｸﾗﾃｸﾞﾝ', full_lower: 'クラテグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '401',
    name: '鞍手郡　小竹町',
    kana_name: {
      half_upper: 'ｸﾗﾃｸﾞﾝ ｺﾀｹﾏﾁ',
      full_lower: 'クラテグン　コタケマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '402',
    name: '鞍手郡　鞍手町',
    kana_name: {
      half_upper: 'ｸﾗﾃｸﾞﾝ ｸﾗﾃﾏﾁ',
      full_lower: 'クラテグン　クラテマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '403',
    name: '鞍手郡　宮田町',
    kana_name: {
      half_upper: 'ｸﾗﾃｸﾞﾝ ﾐﾔﾀﾏﾁ',
      full_lower: 'クラテグン　ミヤタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-11',
    remark: { start: null, end: '40226宮若市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '404',
    name: '鞍手郡　若宮町',
    kana_name: {
      half_upper: 'ｸﾗﾃｸﾞﾝ ﾜｶﾐﾔﾏﾁ',
      full_lower: 'クラテグン　ワカミヤマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-11',
    remark: { start: null, end: '40226宮若市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '420',
    name: '嘉穂郡',
    kana_name: { half_upper: 'ｶﾎｸﾞﾝ', full_lower: 'カホグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '421',
    name: '嘉穂郡　桂川町',
    kana_name: {
      half_upper: 'ｶﾎｸﾞﾝ ｹｲｾﾝﾏﾁ',
      full_lower: 'カホグン　ケイセンマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '422',
    name: '嘉穂郡　稲築町',
    kana_name: {
      half_upper: 'ｶﾎｸﾞﾝ ｲﾅﾂｷﾏﾁ',
      full_lower: 'カホグン　イナツキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '40227嘉麻市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '423',
    name: '嘉穂郡　碓井町',
    kana_name: {
      half_upper: 'ｶﾎｸﾞﾝ ｳｽｲﾏﾁ',
      full_lower: 'カホグン　ウスイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '40227嘉麻市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '424',
    name: '嘉穂郡　嘉穂町',
    kana_name: { half_upper: 'ｶﾎｸﾞﾝ ｶﾎﾏﾁ', full_lower: 'カホグン　カホマチ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '40227嘉麻市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '425',
    name: '嘉穂郡　筑穂町',
    kana_name: {
      half_upper: 'ｶﾎｸﾞﾝ ﾁｸﾎﾏﾁ',
      full_lower: 'カホグン　チクホマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-26',
    remark: { start: null, end: '40205飯塚市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '426',
    name: '嘉穂郡　穂波町',
    kana_name: {
      half_upper: 'ｶﾎｸﾞﾝ ﾎﾅﾐﾏﾁ',
      full_lower: 'カホグン　ホナミマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-26',
    remark: { start: null, end: '40205飯塚市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '427',
    name: '嘉穂郡　庄内町',
    kana_name: {
      half_upper: 'ｶﾎｸﾞﾝ ｼﾖｳﾅｲﾏﾁ',
      full_lower: 'カホグン　ショウナイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-26',
    remark: { start: null, end: '40205飯塚市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '428',
    name: '嘉穂郡　頴田町',
    kana_name: {
      half_upper: 'ｶﾎｸﾞﾝ ｶｲﾀﾏﾁ',
      full_lower: 'カホグン　カイタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-26',
    remark: { start: null, end: '40205飯塚市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '440',
    name: '朝倉郡',
    kana_name: { half_upper: 'ｱｻｸﾗｸﾞﾝ', full_lower: 'アサクラグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '441',
    name: '朝倉郡　杷木町',
    kana_name: {
      half_upper: 'ｱｻｸﾗｸﾞﾝ ﾊｷﾏﾁ',
      full_lower: 'アサクラグン　ハキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '40228朝倉市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '442',
    name: '朝倉郡　朝倉町',
    kana_name: {
      half_upper: 'ｱｻｸﾗｸﾞﾝ ｱｻｸﾗﾏﾁ',
      full_lower: 'アサクラグン　アサクラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '40228朝倉市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '443',
    name: '朝倉郡　三輪町',
    kana_name: {
      half_upper: 'ｱｻｸﾗｸﾞﾝ ﾐﾜﾏﾁ',
      full_lower: 'アサクラグン　ミワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '40447朝倉郡　筑前町', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '444',
    name: '朝倉郡　夜須町',
    kana_name: {
      half_upper: 'ｱｻｸﾗｸﾞﾝ ﾔｽﾏﾁ',
      full_lower: 'アサクラグン　ヤスマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '40447朝倉郡　筑前町', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '445',
    name: '朝倉郡　小石原村',
    kana_name: {
      half_upper: 'ｱｻｸﾗｸﾞﾝ ｺｲｼﾜﾗﾑﾗ',
      full_lower: 'アサクラグン　コイシワラムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '40448朝倉郡　東峰村', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '446',
    name: '朝倉郡　宝珠山村',
    kana_name: {
      half_upper: 'ｱｻｸﾗｸﾞﾝ ﾎｳｼﾕﾔﾏﾑﾗ',
      full_lower: 'アサクラグン　ホウシュヤマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-28',
    remark: { start: null, end: '40448朝倉郡　東峰村', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '447',
    name: '朝倉郡　筑前町',
    kana_name: {
      half_upper: 'ｱｻｸﾗｸﾞﾝ ﾁｸｾﾞﾝﾏﾁ',
      full_lower: 'アサクラグン　チクゼンマチ',
    },
    en: null,
    start_date: '2005-03-22',
    end_date: null,
    remark: { start: 'H17/03/22新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '448',
    name: '朝倉郡　東峰村',
    kana_name: {
      half_upper: 'ｱｻｸﾗｸﾞﾝ ﾄｳﾎｳﾑﾗ',
      full_lower: 'アサクラグン　トウホウムラ',
    },
    en: null,
    start_date: '2005-03-28',
    end_date: null,
    remark: { start: 'H17/03/28新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '460',
    name: '糸島郡',
    kana_name: { half_upper: 'ｲﾄｼﾏｸﾞﾝ', full_lower: 'イトシマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-01-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '461',
    name: '糸島郡　前原町',
    kana_name: {
      half_upper: 'ｲﾄｼﾏｸﾞﾝ ﾏｴﾊﾞﾙﾏﾁ',
      full_lower: 'イトシマグン　マエバルマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1992-10-01',
    remark: { start: null, end: '40222前原市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '462',
    name: '糸島郡　二丈町',
    kana_name: {
      half_upper: 'ｲﾄｼﾏｸﾞﾝ ﾆｼﾞﾖｳﾏﾁ',
      full_lower: 'イトシマグン　ニジョウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-01-01',
    remark: { start: null, end: '40230糸島市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '463',
    name: '糸島郡　志摩町',
    kana_name: {
      half_upper: 'ｲﾄｼﾏｸﾞﾝ ｼﾏﾏﾁ',
      full_lower: 'イトシマグン　シママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-01-01',
    remark: { start: null, end: '40230糸島市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '480',
    name: '浮羽郡',
    kana_name: { half_upper: 'ｳｷﾊｸﾞﾝ', full_lower: 'ウキハグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-20',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '481',
    name: '浮羽郡　吉井町',
    kana_name: {
      half_upper: 'ｳｷﾊｸﾞﾝ ﾖｼｲﾏﾁ',
      full_lower: 'ウキハグン　ヨシイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-20',
    remark: { start: null, end: '40225うきは市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '482',
    name: '浮羽郡　田主丸町',
    kana_name: {
      half_upper: 'ｳｷﾊｸﾞﾝ ﾀﾇｼﾏﾙﾏﾁ',
      full_lower: 'ウキハグン　タヌシマルマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-05',
    remark: { start: null, end: '40203久留米市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '483',
    name: '浮羽郡　浮羽町',
    kana_name: {
      half_upper: 'ｳｷﾊｸﾞﾝ ｳｷﾊﾏﾁ',
      full_lower: 'ウキハグン　ウキハマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-20',
    remark: { start: null, end: '40225うきは市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '500',
    name: '三井郡',
    kana_name: { half_upper: 'ﾐｲｸﾞﾝ', full_lower: 'ミイグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '501',
    name: '三井郡　北野町',
    kana_name: {
      half_upper: 'ﾐｲｸﾞﾝ ｷﾀﾉﾏﾁ',
      full_lower: 'ミイグン　キタノマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-05',
    remark: { start: null, end: '40203久留米市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '502',
    name: '三井郡　小郡町',
    kana_name: {
      half_upper: 'ﾐｲｸﾞﾝ ｵｺﾞｵﾘﾏﾁ',
      full_lower: 'ミイグン　オゴオリマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1972-04-01',
    remark: { start: null, end: '40216小郡市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '503',
    name: '三井郡　大刀洗町',
    kana_name: {
      half_upper: 'ﾐｲｸﾞﾝ ﾀﾁｱﾗｲﾏﾁ',
      full_lower: 'ミイグン　タチアライマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '520',
    name: '三潴郡',
    kana_name: { half_upper: 'ﾐﾂﾞﾏｸﾞﾝ', full_lower: 'ミヅマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: 'カナは、「ミズマ」ではなく「ミヅマ」',
  },
  {
    pref_code: '40',
    city_code: '521',
    name: '三潴郡　城島町',
    kana_name: {
      half_upper: 'ﾐﾂﾞﾏｸﾞﾝ ｼﾞﾖｳｼﾞﾏﾏﾁ',
      full_lower: 'ミヅマグン　ジョウジママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-05',
    remark: { start: null, end: '40203久留米市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '522',
    name: '三潴郡　大木町',
    kana_name: {
      half_upper: 'ﾐﾂﾞﾏｸﾞﾝ ｵｵｷﾏﾁ',
      full_lower: 'ミヅマグン　オオキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '523',
    name: '三潴郡　三潴町',
    kana_name: {
      half_upper: 'ﾐﾂﾞﾏｸﾞﾝ ﾐﾂﾞﾏﾏﾁ',
      full_lower: 'ミヅマグン　ミヅママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-05',
    remark: { start: null, end: '40203久留米市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '540',
    name: '八女郡',
    kana_name: { half_upper: 'ﾔﾒｸﾞﾝ', full_lower: 'ヤメグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '541',
    name: '八女郡　黒木町',
    kana_name: {
      half_upper: 'ﾔﾒｸﾞﾝ ｸﾛｷﾞﾏﾁ',
      full_lower: 'ヤメグン　クロギマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-02-01',
    remark: { start: null, end: '40210八女市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '542',
    name: '八女郡　上陽町',
    kana_name: {
      half_upper: 'ﾔﾒｸﾞﾝ ｼﾞﾖｳﾖｳﾏﾁ',
      full_lower: 'ヤメグン　ジョウヨウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-10-01',
    remark: { start: null, end: '40210八女市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '543',
    name: '八女郡　立花町',
    kana_name: {
      half_upper: 'ﾔﾒｸﾞﾝ ﾀﾁﾊﾞﾅﾏﾁ',
      full_lower: 'ヤメグン　タチバナマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-02-01',
    remark: { start: null, end: '40210八女市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '544',
    name: '八女郡　広川町',
    kana_name: {
      half_upper: 'ﾔﾒｸﾞﾝ ﾋﾛｶﾜﾏﾁ',
      full_lower: 'ヤメグン　ヒロカワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '545',
    name: '八女郡　矢部村',
    kana_name: {
      half_upper: 'ﾔﾒｸﾞﾝ ﾔﾍﾞﾑﾗ',
      full_lower: 'ヤメグン　ヤベムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-02-01',
    remark: { start: null, end: '40210八女市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '546',
    name: '八女郡　星野村',
    kana_name: {
      half_upper: 'ﾔﾒｸﾞﾝ ﾎｼﾉﾑﾗ',
      full_lower: 'ヤメグン　ホシノムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-02-01',
    remark: { start: null, end: '40210八女市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '560',
    name: '山門郡',
    kana_name: { half_upper: 'ﾔﾏﾄｸﾞﾝ', full_lower: 'ヤマトグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-01-29',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '561',
    name: '山門郡　瀬高町',
    kana_name: {
      half_upper: 'ﾔﾏﾄｸﾞﾝ ｾﾀｶﾏﾁ',
      full_lower: 'ヤマトグン　セタカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-01-29',
    remark: { start: null, end: '40229みやま市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '562',
    name: '山門郡　大和町',
    kana_name: {
      half_upper: 'ﾔﾏﾄｸﾞﾝ ﾔﾏﾄﾏﾁ',
      full_lower: 'ヤマトグン　ヤマトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-21',
    remark: { start: null, end: '40207柳川市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '563',
    name: '山門郡　三橋町',
    kana_name: {
      half_upper: 'ﾔﾏﾄｸﾞﾝ ﾐﾂﾊｼﾏﾁ',
      full_lower: 'ヤマトグン　ミツハシマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-21',
    remark: { start: null, end: '40207柳川市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '564',
    name: '山門郡　山川町',
    kana_name: {
      half_upper: 'ﾔﾏﾄｸﾞﾝ ﾔﾏｶﾜﾏﾁ',
      full_lower: 'ヤマトグン　ヤマカワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-01-29',
    remark: { start: null, end: '40229みやま市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '580',
    name: '三池郡',
    kana_name: { half_upper: 'ﾐｲｹｸﾞﾝ', full_lower: 'ミイケグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-01-29',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '581',
    name: '三池郡　高田町',
    kana_name: {
      half_upper: 'ﾐｲｹｸﾞﾝ ﾀｶﾀﾏﾁ',
      full_lower: 'ミイケグン　タカタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-01-29',
    remark: { start: null, end: '40229みやま市', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '600',
    name: '田川郡',
    kana_name: { half_upper: 'ﾀｶﾞﾜｸﾞﾝ', full_lower: 'タガワグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '601',
    name: '田川郡　香春町',
    kana_name: {
      half_upper: 'ﾀｶﾞﾜｸﾞﾝ ｶﾜﾗﾏﾁ',
      full_lower: 'タガワグン　カワラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '602',
    name: '田川郡　添田町',
    kana_name: {
      half_upper: 'ﾀｶﾞﾜｸﾞﾝ ｿｴﾀﾞﾏﾁ',
      full_lower: 'タガワグン　ソエダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '603',
    name: '田川郡　金田町',
    kana_name: {
      half_upper: 'ﾀｶﾞﾜｸﾞﾝ ｶﾅﾀﾞﾏﾁ',
      full_lower: 'タガワグン　カナダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-06',
    remark: { start: null, end: '40610田川郡　福智町', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '604',
    name: '田川郡　糸田町',
    kana_name: {
      half_upper: 'ﾀｶﾞﾜｸﾞﾝ ｲﾄﾀﾞﾏﾁ',
      full_lower: 'タガワグン　イトダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '605',
    name: '田川郡　川崎町',
    kana_name: {
      half_upper: 'ﾀｶﾞﾜｸﾞﾝ ｶﾜｻｷﾏﾁ',
      full_lower: 'タガワグン　カワサキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '606',
    name: '田川郡　赤池町',
    kana_name: {
      half_upper: 'ﾀｶﾞﾜｸﾞﾝ ｱｶｲｹﾏﾁ',
      full_lower: 'タガワグン　アカイケマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-06',
    remark: { start: null, end: '40610田川郡　福智町', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '607',
    name: '田川郡　方城町',
    kana_name: {
      half_upper: 'ﾀｶﾞﾜｸﾞﾝ ﾎｳｼﾞﾖｳﾏﾁ',
      full_lower: 'タガワグン　ホウジョウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-06',
    remark: { start: null, end: '40610田川郡　福智町', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '608',
    name: '田川郡　大任町',
    kana_name: {
      half_upper: 'ﾀｶﾞﾜｸﾞﾝ ｵｵﾄｳﾏﾁ',
      full_lower: 'タガワグン　オオトウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '609',
    name: '田川郡　赤村',
    kana_name: {
      half_upper: 'ﾀｶﾞﾜｸﾞﾝ ｱｶﾑﾗ',
      full_lower: 'タガワグン　アカムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '610',
    name: '田川郡　福智町',
    kana_name: {
      half_upper: 'ﾀｶﾞﾜｸﾞﾝ ﾌｸﾁﾏﾁ',
      full_lower: 'タガワグン　フクチマチ',
    },
    en: null,
    start_date: '2006-03-06',
    end_date: null,
    remark: { start: 'H18/03/06新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '620',
    name: '京都郡',
    kana_name: { half_upper: 'ﾐﾔｺｸﾞﾝ', full_lower: 'ミヤコグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '621',
    name: '京都郡　苅田町',
    kana_name: {
      half_upper: 'ﾐﾔｺｸﾞﾝ ｶﾝﾀﾞﾏﾁ',
      full_lower: 'ミヤコグン　カンダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '622',
    name: '京都郡　犀川町',
    kana_name: {
      half_upper: 'ﾐﾔｺｸﾞﾝ ｻｲｶﾞﾜﾏﾁ',
      full_lower: 'ミヤコグン　サイガワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '40625京都郡　みやこ町', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '623',
    name: '京都郡　勝山町',
    kana_name: {
      half_upper: 'ﾐﾔｺｸﾞﾝ ｶﾂﾔﾏﾏﾁ',
      full_lower: 'ミヤコグン　カツヤママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '40625京都郡　みやこ町', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '624',
    name: '京都郡　豊津町',
    kana_name: {
      half_upper: 'ﾐﾔｺｸﾞﾝ ﾄﾖﾂﾏﾁ',
      full_lower: 'ミヤコグン　トヨツマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '40625京都郡　みやこ町', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '625',
    name: '京都郡　みやこ町',
    kana_name: {
      half_upper: 'ﾐﾔｺｸﾞﾝ ﾐﾔｺﾏﾁ',
      full_lower: 'ミヤコグン　ミヤコマチ',
    },
    en: null,
    start_date: '2006-03-20',
    end_date: null,
    remark: { start: 'H18/03/20新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '640',
    name: '築上郡',
    kana_name: { half_upper: 'ﾁｸｼﾞﾖｳｸﾞﾝ', full_lower: 'チクジョウグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '641',
    name: '築上郡　椎田町',
    kana_name: {
      half_upper: 'ﾁｸｼﾞﾖｳｸﾞﾝ ｼｲﾀﾞﾏﾁ',
      full_lower: 'チクジョウグン　シイダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-10',
    remark: { start: null, end: '40647築上郡　築上町', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '642',
    name: '築上郡　吉富町',
    kana_name: {
      half_upper: 'ﾁｸｼﾞﾖｳｸﾞﾝ ﾖｼﾄﾐﾏﾁ',
      full_lower: 'チクジョウグン　ヨシトミマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '643',
    name: '築上郡　築城町',
    kana_name: {
      half_upper: 'ﾁｸｼﾞﾖｳｸﾞﾝ ﾂｲｷﾏﾁ',
      full_lower: 'チクジョウグン　ツイキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-10',
    remark: { start: null, end: '40647築上郡　築上町', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '644',
    name: '築上郡　新吉富村',
    kana_name: {
      half_upper: 'ﾁｸｼﾞﾖｳｸﾞﾝ ｼﾝﾖｼﾄﾐﾑﾗ',
      full_lower: 'チクジョウグン　シンヨシトミムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-11',
    remark: { start: null, end: '40646築上郡　上毛町', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '645',
    name: '築上郡　大平村',
    kana_name: {
      half_upper: 'ﾁｸｼﾞﾖｳｸﾞﾝ ﾀｲﾍｲﾑﾗ',
      full_lower: 'チクジョウグン　タイヘイムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-11',
    remark: { start: null, end: '40646築上郡　上毛町', other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '646',
    name: '築上郡　上毛町',
    kana_name: {
      half_upper: 'ﾁｸｼﾞﾖｳｸﾞﾝ ｺｳｹﾞﾏﾁ',
      full_lower: 'チクジョウグン　コウゲマチ',
    },
    en: null,
    start_date: '2005-10-11',
    end_date: null,
    remark: { start: 'H17/10/11新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '40',
    city_code: '647',
    name: '築上郡　築上町',
    kana_name: {
      half_upper: 'ﾁｸｼﾞﾖｳｸﾞﾝ ﾁｸｼﾞﾖｳﾏﾁ',
      full_lower: 'チクジョウグン　チクジョウマチ',
    },
    en: null,
    start_date: '2006-01-10',
    end_date: null,
    remark: { start: 'H18/01/10新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '201',
    name: '佐賀市',
    kana_name: { half_upper: 'ｻｶﾞｼ', full_lower: 'サガシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '202',
    name: '唐津市',
    kana_name: { half_upper: 'ｶﾗﾂｼ', full_lower: 'カラツシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '203',
    name: '鳥栖市',
    kana_name: { half_upper: 'ﾄｽｼ', full_lower: 'トスシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '204',
    name: '多久市',
    kana_name: { half_upper: 'ﾀｸｼ', full_lower: 'タクシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '205',
    name: '伊万里市',
    kana_name: { half_upper: 'ｲﾏﾘｼ', full_lower: 'イマリシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '206',
    name: '武雄市',
    kana_name: { half_upper: 'ﾀｹｵｼ', full_lower: 'タケオシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '207',
    name: '鹿島市',
    kana_name: { half_upper: 'ｶｼﾏｼ', full_lower: 'カシマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '208',
    name: '小城市',
    kana_name: { half_upper: 'ｵｷﾞｼ', full_lower: 'オギシ' },
    en: null,
    start_date: '2005-03-01',
    end_date: null,
    remark: { start: 'H17/03/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '209',
    name: '嬉野市',
    kana_name: { half_upper: 'ｳﾚｼﾉｼ', full_lower: 'ウレシノシ' },
    en: null,
    start_date: '2006-01-01',
    end_date: null,
    remark: { start: 'H18/01/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '210',
    name: '神埼市',
    kana_name: { half_upper: 'ｶﾝｻﾞｷｼ', full_lower: 'カンザキシ' },
    en: null,
    start_date: '2006-03-20',
    end_date: null,
    remark: { start: 'H18/03/20新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '300',
    name: '佐賀郡',
    kana_name: { half_upper: 'ｻｶﾞｸﾞﾝ', full_lower: 'サガグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-10-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '301',
    name: '佐賀郡　諸富町',
    kana_name: {
      half_upper: 'ｻｶﾞｸﾞﾝ ﾓﾛﾄﾞﾐﾁﾖｳ',
      full_lower: 'サガグン　モロドミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '41201佐賀市', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '302',
    name: '佐賀郡　川副町',
    kana_name: {
      half_upper: 'ｻｶﾞｸﾞﾝ ｶﾜｿｴﾏﾁ',
      full_lower: 'サガグン　カワソエマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-10-01',
    remark: { start: null, end: '41201佐賀市', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '303',
    name: '佐賀郡　東与賀町',
    kana_name: {
      half_upper: 'ｻｶﾞｸﾞﾝ ﾋｶﾞｼﾖｶﾁﾖｳ',
      full_lower: 'サガグン　ヒガシヨカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-10-01',
    remark: { start: null, end: '41201佐賀市', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '304',
    name: '佐賀郡　久保田町',
    kana_name: {
      half_upper: 'ｻｶﾞｸﾞﾝ ｸﾎﾞﾀﾁﾖｳ',
      full_lower: 'サガグン　クボタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-10-01',
    remark: { start: null, end: '41201佐賀市', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '305',
    name: '佐賀郡　大和町',
    kana_name: {
      half_upper: 'ｻｶﾞｸﾞﾝ ﾔﾏﾄﾁﾖｳ',
      full_lower: 'サガグン　ヤマトチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '41201佐賀市', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '306',
    name: '佐賀郡　富士町',
    kana_name: {
      half_upper: 'ｻｶﾞｸﾞﾝ ﾌｼﾞﾁﾖｳ',
      full_lower: 'サガグン　フジチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '41201佐賀市', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '320',
    name: '神埼郡',
    kana_name: { half_upper: 'ｶﾝｻﾞｷｸﾞﾝ', full_lower: 'カンザキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '321',
    name: '神埼郡　神埼町',
    kana_name: {
      half_upper: 'ｶﾝｻﾞｷｸﾞﾝ ｶﾝｻﾞｷﾏﾁ',
      full_lower: 'カンザキグン　カンザキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '41210神埼市', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '322',
    name: '神埼郡　千代田町',
    kana_name: {
      half_upper: 'ｶﾝｻﾞｷｸﾞﾝ ﾁﾖﾀﾞﾁﾖｳ',
      full_lower: 'カンザキグン　チヨダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '41210神埼市', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '323',
    name: '神埼郡　三田川町',
    kana_name: {
      half_upper: 'ｶﾝｻﾞｷｸﾞﾝ ﾐﾀｶﾞﾜﾁﾖｳ',
      full_lower: 'カンザキグン　ミタガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '41327神埼郡　吉野ヶ里町', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '324',
    name: '神埼郡　東脊振村',
    kana_name: {
      half_upper: 'ｶﾝｻﾞｷｸﾞﾝ ﾋｶﾞｼｾﾌﾘｿﾝ',
      full_lower: 'カンザキグン　ヒガシセフリソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '41327神埼郡　吉野ヶ里町', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '325',
    name: '神埼郡　脊振村',
    kana_name: {
      half_upper: 'ｶﾝｻﾞｷｸﾞﾝ ｾﾌﾘﾑﾗ',
      full_lower: 'カンザキグン　セフリムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '41210神埼市', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '326',
    name: '神埼郡　三瀬村',
    kana_name: {
      half_upper: 'ｶﾝｻﾞｷｸﾞﾝ ﾐﾂｾﾑﾗ',
      full_lower: 'カンザキグン　ミツセムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '41201佐賀市', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '327',
    name: '神埼郡　吉野ヶ里町',
    kana_name: {
      half_upper: 'ｶﾝｻﾞｷｸﾞﾝ ﾖｼﾉｶﾞﾘﾁﾖｳ',
      full_lower: 'カンザキグン　ヨシノガリチョウ',
    },
    en: null,
    start_date: '2006-03-01',
    end_date: null,
    remark: { start: 'H18/03/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '340',
    name: '三養基郡',
    kana_name: { half_upper: 'ﾐﾔｷｸﾞﾝ', full_lower: 'ミヤキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '341',
    name: '三養基郡　基山町',
    kana_name: {
      half_upper: 'ﾐﾔｷｸﾞﾝ ｷﾔﾏﾁﾖｳ',
      full_lower: 'ミヤキグン　キヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '342',
    name: '三養基郡　中原町',
    kana_name: {
      half_upper: 'ﾐﾔｷｸﾞﾝ ﾅｶﾊﾞﾙﾁﾖｳ',
      full_lower: 'ミヤキグン　ナカバルチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '41346三養基郡　みやき町', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '343',
    name: '三養基郡　北茂安町',
    kana_name: {
      half_upper: 'ﾐﾔｷｸﾞﾝ ｷﾀｼｹﾞﾔｽﾁﾖｳ',
      full_lower: 'ミヤキグン　キタシゲヤスチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '41346三養基郡　みやき町', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '344',
    name: '三養基郡　三根町',
    kana_name: {
      half_upper: 'ﾐﾔｷｸﾞﾝ ﾐﾈﾁﾖｳ',
      full_lower: 'ミヤキグン　ミネチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '41346三養基郡　みやき町', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '345',
    name: '三養基郡　上峰町',
    kana_name: {
      half_upper: 'ﾐﾔｷｸﾞﾝ ｶﾐﾐﾈﾁﾖｳ',
      full_lower: 'ミヤキグン　カミミネチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '346',
    name: '三養基郡　みやき町',
    kana_name: {
      half_upper: 'ﾐﾔｷｸﾞﾝ ﾐﾔｷﾁﾖｳ',
      full_lower: 'ミヤキグン　ミヤキチョウ',
    },
    en: null,
    start_date: '2005-03-01',
    end_date: null,
    remark: { start: 'H17/03/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '360',
    name: '小城郡',
    kana_name: { half_upper: 'ｵｷﾞｸﾞﾝ', full_lower: 'オギグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '361',
    name: '小城郡　小城町',
    kana_name: {
      half_upper: 'ｵｷﾞｸﾞﾝ ｵｷﾞﾏﾁ',
      full_lower: 'オギグン　オギマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '41208小城市', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '362',
    name: '小城郡　三日月町',
    kana_name: {
      half_upper: 'ｵｷﾞｸﾞﾝ ﾐｶﾂﾞｷﾁﾖｳ',
      full_lower: 'オギグン　ミカヅキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '41208小城市', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '363',
    name: '小城郡　牛津町',
    kana_name: {
      half_upper: 'ｵｷﾞｸﾞﾝ ｳｼﾂﾞﾁﾖｳ',
      full_lower: 'オギグン　ウシヅチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '41208小城市', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '364',
    name: '小城郡　芦刈町',
    kana_name: {
      half_upper: 'ｵｷﾞｸﾞﾝ ｱｼｶﾘﾁﾖｳ',
      full_lower: 'オギグン　アシカリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '41208小城市', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '380',
    name: '東松浦郡',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾏﾂｳﾗｸﾞﾝ',
      full_lower: 'ヒガシマツウラグン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '381',
    name: '東松浦郡　浜玉町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾏﾂｳﾗｸﾞﾝ ﾊﾏﾀﾏﾁﾖｳ',
      full_lower: 'ヒガシマツウラグン　ハマタマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '41202唐津市', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '382',
    name: '東松浦郡　七山村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾏﾂｳﾗｸﾞﾝ ﾅﾅﾔﾏﾑﾗ',
      full_lower: 'ヒガシマツウラグン　ナナヤマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '41202唐津市', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '383',
    name: '東松浦郡　厳木町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾏﾂｳﾗｸﾞﾝ ｷﾕｳﾗｷﾞﾏﾁ',
      full_lower: 'ヒガシマツウラグン　キュウラギマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '41202唐津市', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '384',
    name: '東松浦郡　相知町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾏﾂｳﾗｸﾞﾝ ｵｳﾁﾁﾖｳ',
      full_lower: 'ヒガシマツウラグン　オウチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '41202唐津市', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '385',
    name: '東松浦郡　北波多村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾏﾂｳﾗｸﾞﾝ ｷﾀﾊﾀﾑﾗ',
      full_lower: 'ヒガシマツウラグン　キタハタムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '41202唐津市', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '386',
    name: '東松浦郡　肥前町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾏﾂｳﾗｸﾞﾝ ﾋｾﾞﾝﾁﾖｳ',
      full_lower: 'ヒガシマツウラグン　ヒゼンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '41202唐津市', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '387',
    name: '東松浦郡　玄海町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾏﾂｳﾗｸﾞﾝ ｹﾞﾝｶｲﾁﾖｳ',
      full_lower: 'ヒガシマツウラグン　ゲンカイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '388',
    name: '東松浦郡　鎮西町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾏﾂｳﾗｸﾞﾝ ﾁﾝｾﾞｲﾁﾖｳ',
      full_lower: 'ヒガシマツウラグン　チンゼイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '41202唐津市', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '389',
    name: '東松浦郡　呼子町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾏﾂｳﾗｸﾞﾝ ﾖﾌﾞｺﾁﾖｳ',
      full_lower: 'ヒガシマツウラグン　ヨブコチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '41202唐津市', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '400',
    name: '西松浦郡',
    kana_name: { half_upper: 'ﾆｼﾏﾂｳﾗｸﾞﾝ', full_lower: 'ニシマツウラグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '401',
    name: '西松浦郡　有田町',
    kana_name: {
      half_upper: 'ﾆｼﾏﾂｳﾗｸﾞﾝ ｱﾘﾀﾁﾖｳ',
      full_lower: 'ニシマツウラグン　アリタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '402',
    name: '西松浦郡　西有田町',
    kana_name: {
      half_upper: 'ﾆｼﾏﾂｳﾗｸﾞﾝ ﾆｼｱﾘﾀﾁﾖｳ',
      full_lower: 'ニシマツウラグン　ニシアリタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '41401西松浦郡　有田町', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '420',
    name: '杵島郡',
    kana_name: { half_upper: 'ｷｼﾏｸﾞﾝ', full_lower: 'キシマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '421',
    name: '杵島郡　山内町',
    kana_name: {
      half_upper: 'ｷｼﾏｸﾞﾝ ﾔﾏｳﾁﾁﾖｳ',
      full_lower: 'キシマグン　ヤマウチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '41206武雄市', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '422',
    name: '杵島郡　北方町',
    kana_name: {
      half_upper: 'ｷｼﾏｸﾞﾝ ｷﾀｶﾞﾀﾏﾁ',
      full_lower: 'キシマグン　キタガタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '41206武雄市', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '423',
    name: '杵島郡　大町町',
    kana_name: {
      half_upper: 'ｷｼﾏｸﾞﾝ ｵｵﾏﾁﾁﾖｳ',
      full_lower: 'キシマグン　オオマチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '424',
    name: '杵島郡　江北町',
    kana_name: {
      half_upper: 'ｷｼﾏｸﾞﾝ ｺｳﾎｸﾏﾁ',
      full_lower: 'キシマグン　コウホクマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '425',
    name: '杵島郡　白石町',
    kana_name: {
      half_upper: 'ｷｼﾏｸﾞﾝ ｼﾛｲｼﾁﾖｳ',
      full_lower: 'キシマグン　シロイシチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '426',
    name: '杵島郡　福富町',
    kana_name: {
      half_upper: 'ｷｼﾏｸﾞﾝ ﾌｸﾄﾞﾐﾏﾁ',
      full_lower: 'キシマグン　フクドミマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '41425杵島郡　白石町', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '427',
    name: '杵島郡　有明町',
    kana_name: {
      half_upper: 'ｷｼﾏｸﾞﾝ ｱﾘｱｹﾁﾖｳ',
      full_lower: 'キシマグン　アリアケチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '41425杵島郡　白石町', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '440',
    name: '藤津郡',
    kana_name: { half_upper: 'ﾌｼﾞﾂｸﾞﾝ', full_lower: 'フジツグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '441',
    name: '藤津郡　太良町',
    kana_name: {
      half_upper: 'ﾌｼﾞﾂｸﾞﾝ ﾀﾗﾁﾖｳ',
      full_lower: 'フジツグン　タラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '442',
    name: '藤津郡　塩田町',
    kana_name: {
      half_upper: 'ﾌｼﾞﾂｸﾞﾝ ｼｵﾀﾁﾖｳ',
      full_lower: 'フジツグン　シオタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '41209嬉野市', other: null },
    note: null,
  },
  {
    pref_code: '41',
    city_code: '443',
    name: '藤津郡　嬉野町',
    kana_name: {
      half_upper: 'ﾌｼﾞﾂｸﾞﾝ ｳﾚｼﾉﾏﾁ',
      full_lower: 'フジツグン　ウレシノマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '41209嬉野市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '201',
    name: '長崎市',
    kana_name: { half_upper: 'ﾅｶﾞｻｷｼ', full_lower: 'ナガサキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '202',
    name: '佐世保市',
    kana_name: { half_upper: 'ｻｾﾎﾞｼ', full_lower: 'サセボシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '203',
    name: '島原市',
    kana_name: { half_upper: 'ｼﾏﾊﾞﾗｼ', full_lower: 'シマバラシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '204',
    name: '諫早市',
    kana_name: { half_upper: 'ｲｻﾊﾔｼ', full_lower: 'イサハヤシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '205',
    name: '大村市',
    kana_name: { half_upper: 'ｵｵﾑﾗｼ', full_lower: 'オオムラシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '206',
    name: '福江市',
    kana_name: { half_upper: 'ﾌｸｴｼ', full_lower: 'フクエシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-08-01',
    remark: { start: null, end: '42211五島市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '207',
    name: '平戸市',
    kana_name: { half_upper: 'ﾋﾗﾄﾞｼ', full_lower: 'ヒラドシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '208',
    name: '松浦市',
    kana_name: { half_upper: 'ﾏﾂｳﾗｼ', full_lower: 'マツウラシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '209',
    name: '対馬市',
    kana_name: { half_upper: 'ﾂｼﾏｼ', full_lower: 'ツシマシ' },
    en: null,
    start_date: '2004-03-01',
    end_date: null,
    remark: { start: 'H16/03/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '210',
    name: '壱岐市',
    kana_name: { half_upper: 'ｲｷｼ', full_lower: 'イキシ' },
    en: null,
    start_date: '2004-03-01',
    end_date: null,
    remark: { start: 'H16/03/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '211',
    name: '五島市',
    kana_name: { half_upper: 'ｺﾞﾄｳｼ', full_lower: 'ゴトウシ' },
    en: null,
    start_date: '2004-08-01',
    end_date: null,
    remark: { start: 'H16/08/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '212',
    name: '西海市',
    kana_name: { half_upper: 'ｻｲｶｲｼ', full_lower: 'サイカイシ' },
    en: null,
    start_date: '2005-04-01',
    end_date: null,
    remark: { start: 'H17/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '213',
    name: '雲仙市',
    kana_name: { half_upper: 'ｳﾝｾﾞﾝｼ', full_lower: 'ウンゼンシ' },
    en: null,
    start_date: '2005-10-11',
    end_date: null,
    remark: { start: 'H17/10/11新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '214',
    name: '南島原市',
    kana_name: { half_upper: 'ﾐﾅﾐｼﾏﾊﾞﾗｼ', full_lower: 'ミナミシマバラシ' },
    en: null,
    start_date: '2006-03-31',
    end_date: null,
    remark: { start: 'H18/03/31新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '300',
    name: '西彼杵郡',
    kana_name: { half_upper: 'ﾆｼｿﾉｷﾞｸﾞﾝ', full_lower: 'ニシソノギグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '301',
    name: '西彼杵郡　香焼町',
    kana_name: {
      half_upper: 'ﾆｼｿﾉｷﾞｸﾞﾝ ｺｳﾔｷﾞﾁﾖｳ',
      full_lower: 'ニシソノギグン　コウヤギチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-04',
    remark: { start: null, end: '42201長崎市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '302',
    name: '西彼杵郡　伊王島町',
    kana_name: {
      half_upper: 'ﾆｼｿﾉｷﾞｸﾞﾝ ｲｵｳｼﾞﾏﾁﾖｳ',
      full_lower: 'ニシソノギグン　イオウジマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-04',
    remark: { start: null, end: '42201長崎市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '303',
    name: '西彼杵郡　高島町',
    kana_name: {
      half_upper: 'ﾆｼｿﾉｷﾞｸﾞﾝ ﾀｶｼﾏﾁﾖｳ',
      full_lower: 'ニシソノギグン　タカシマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-04',
    remark: { start: null, end: '42201長崎市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '304',
    name: '西彼杵郡　野母崎町',
    kana_name: {
      half_upper: 'ﾆｼｿﾉｷﾞｸﾞﾝ ﾉﾓｻﾞｷﾁﾖｳ',
      full_lower: 'ニシソノギグン　ノモザキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-04',
    remark: { start: null, end: '42201長崎市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '305',
    name: '西彼杵郡　三和町',
    kana_name: {
      half_upper: 'ﾆｼｿﾉｷﾞｸﾞﾝ ｻﾝﾜﾁﾖｳ',
      full_lower: 'ニシソノギグン　サンワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-04',
    remark: { start: null, end: '42201長崎市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '306',
    name: '西彼杵郡　多良見町',
    kana_name: {
      half_upper: 'ﾆｼｿﾉｷﾞｸﾞﾝ ﾀﾗﾐﾁﾖｳ',
      full_lower: 'ニシソノギグン　タラミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '42204諫早市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '307',
    name: '西彼杵郡　長与町',
    kana_name: {
      half_upper: 'ﾆｼｿﾉｷﾞｸﾞﾝ ﾅｶﾞﾖﾁﾖｳ',
      full_lower: 'ニシソノギグン　ナガヨチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '308',
    name: '西彼杵郡　時津町',
    kana_name: {
      half_upper: 'ﾆｼｿﾉｷﾞｸﾞﾝ ﾄｷﾞﾂﾁﾖｳ',
      full_lower: 'ニシソノギグン　トギツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '309',
    name: '西彼杵郡　琴海町',
    kana_name: {
      half_upper: 'ﾆｼｿﾉｷﾞｸﾞﾝ ｷﾝｶｲﾁﾖｳ',
      full_lower: 'ニシソノギグン　キンカイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-04',
    remark: { start: null, end: '42201長崎市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '310',
    name: '西彼杵郡　西彼町',
    kana_name: {
      half_upper: 'ﾆｼｿﾉｷﾞｸﾞﾝ ｾｲﾋﾁﾖｳ',
      full_lower: 'ニシソノギグン　セイヒチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '42212西海市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '311',
    name: '西彼杵郡　西海町',
    kana_name: {
      half_upper: 'ﾆｼｿﾉｷﾞｸﾞﾝ ｻｲｶｲﾁﾖｳ',
      full_lower: 'ニシソノギグン　サイカイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '42212西海市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '312',
    name: '西彼杵郡　大島町',
    kana_name: {
      half_upper: 'ﾆｼｿﾉｷﾞｸﾞﾝ ｵｵｼﾏﾁﾖｳ',
      full_lower: 'ニシソノギグン　オオシマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '42212西海市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '313',
    name: '西彼杵郡　崎戸町',
    kana_name: {
      half_upper: 'ﾆｼｿﾉｷﾞｸﾞﾝ ｻｷﾄﾁﾖｳ',
      full_lower: 'ニシソノギグン　サキトチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '42212西海市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '314',
    name: '西彼杵郡　大瀬戸町',
    kana_name: {
      half_upper: 'ﾆｼｿﾉｷﾞｸﾞﾝ ｵｵｾﾄﾁﾖｳ',
      full_lower: 'ニシソノギグン　オオセトチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '42212西海市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '315',
    name: '西彼杵郡　外海町',
    kana_name: {
      half_upper: 'ﾆｼｿﾉｷﾞｸﾞﾝ ｿﾄﾒﾁﾖｳ',
      full_lower: 'ニシソノギグン　ソトメチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-04',
    remark: { start: null, end: '42201長崎市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '316',
    name: '西彼杵郡　三重村',
    kana_name: {
      half_upper: 'ﾆｼｿﾉｷﾞｸﾞﾝ ﾐｴｿﾝ',
      full_lower: 'ニシソノギグン　ミエソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1973-03-31',
    remark: { start: null, end: '42201長崎市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '320',
    name: '東彼杵郡',
    kana_name: { half_upper: 'ﾋｶﾞｼｿﾉｷﾞｸﾞﾝ', full_lower: 'ヒガシソノギグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '321',
    name: '東彼杵郡　東彼杵町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｿﾉｷﾞｸﾞﾝ ﾋｶﾞｼｿﾉｷﾞﾁﾖｳ',
      full_lower: 'ヒガシソノギグン　ヒガシソノギチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '322',
    name: '東彼杵郡　川棚町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｿﾉｷﾞｸﾞﾝ ｶﾜﾀﾅﾁﾖｳ',
      full_lower: 'ヒガシソノギグン　カワタナチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '323',
    name: '東彼杵郡　波佐見町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｿﾉｷﾞｸﾞﾝ ﾊｻﾐﾁﾖｳ',
      full_lower: 'ヒガシソノギグン　ハサミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '340',
    name: '北高来郡',
    kana_name: { half_upper: 'ｷﾀﾀｶｷｸﾞﾝ', full_lower: 'キタタカキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '341',
    name: '北高来郡　森山町',
    kana_name: {
      half_upper: 'ｷﾀﾀｶｷｸﾞﾝ ﾓﾘﾔﾏﾁﾖｳ',
      full_lower: 'キタタカキグン　モリヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '42204諫早市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '342',
    name: '北高来郡　飯盛町',
    kana_name: {
      half_upper: 'ｷﾀﾀｶｷｸﾞﾝ ｲｲﾓﾘﾁﾖｳ',
      full_lower: 'キタタカキグン　イイモリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '42204諫早市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '343',
    name: '北高来郡　高来町',
    kana_name: {
      half_upper: 'ｷﾀﾀｶｷｸﾞﾝ ﾀｶｷﾁﾖｳ',
      full_lower: 'キタタカキグン　タカキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '42204諫早市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '344',
    name: '北高来郡　小長井町',
    kana_name: {
      half_upper: 'ｷﾀﾀｶｷｸﾞﾝ ｺﾅｶﾞｲﾁﾖｳ',
      full_lower: 'キタタカキグン　コナガイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '42204諫早市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '360',
    name: '南高来郡',
    kana_name: { half_upper: 'ﾐﾅﾐﾀｶｷｸﾞﾝ', full_lower: 'ミナミタカキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '361',
    name: '南高来郡　有明町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾀｶｷｸﾞﾝ ｱﾘｱｹﾁﾖｳ',
      full_lower: 'ミナミタカキグン　アリアケチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '42203島原市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '362',
    name: '南高来郡　国見町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾀｶｷｸﾞﾝ ｸﾆﾐﾁﾖｳ',
      full_lower: 'ミナミタカキグン　クニミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-11',
    remark: { start: null, end: '42213雲仙市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '363',
    name: '南高来郡　瑞穂町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾀｶｷｸﾞﾝ ﾐｽﾞﾎﾁﾖｳ',
      full_lower: 'ミナミタカキグン　ミズホチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-11',
    remark: { start: null, end: '42213雲仙市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '364',
    name: '南高来郡　吾妻町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾀｶｷｸﾞﾝ ｱﾂﾞﾏﾁﾖｳ',
      full_lower: 'ミナミタカキグン　アヅマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-11',
    remark: { start: null, end: '42213雲仙市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '365',
    name: '南高来郡　愛野町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾀｶｷｸﾞﾝ ｱｲﾉﾏﾁ',
      full_lower: 'ミナミタカキグン　アイノマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-11',
    remark: { start: null, end: '42213雲仙市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '366',
    name: '南高来郡　千々石町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾀｶｷｸﾞﾝ ﾁﾁﾞﾜﾁﾖｳ',
      full_lower: 'ミナミタカキグン　チヂワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-11',
    remark: { start: null, end: '42213雲仙市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '367',
    name: '南高来郡　小浜町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾀｶｷｸﾞﾝ ｵﾊﾞﾏﾁﾖｳ',
      full_lower: 'ミナミタカキグン　オバマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-11',
    remark: { start: null, end: '42213雲仙市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '368',
    name: '南高来郡　南串山町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾀｶｷｸﾞﾝ ﾐﾅﾐｸｼﾔﾏﾁﾖｳ',
      full_lower: 'ミナミタカキグン　ミナミクシヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-11',
    remark: { start: null, end: '42213雲仙市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '369',
    name: '南高来郡　加津佐町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾀｶｷｸﾞﾝ ｶﾂﾞｻﾏﾁ',
      full_lower: 'ミナミタカキグン　カヅサマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '42214南島原市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '370',
    name: '南高来郡　口之津町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾀｶｷｸﾞﾝ ｸﾁﾉﾂﾁﾖｳ',
      full_lower: 'ミナミタカキグン　クチノツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '42214南島原市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '371',
    name: '南高来郡　南有馬町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾀｶｷｸﾞﾝ ﾐﾅﾐｱﾘﾏﾁﾖｳ',
      full_lower: 'ミナミタカキグン　ミナミアリマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '42214南島原市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '372',
    name: '南高来郡　北有馬町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾀｶｷｸﾞﾝ ｷﾀｱﾘﾏﾁﾖｳ',
      full_lower: 'ミナミタカキグン　キタアリマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '42214南島原市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '373',
    name: '南高来郡　西有家町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾀｶｷｸﾞﾝ ﾆｼｱﾘｴﾁﾖｳ',
      full_lower: 'ミナミタカキグン　ニシアリエチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '42214南島原市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '374',
    name: '南高来郡　有家町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾀｶｷｸﾞﾝ ｱﾘｴﾁﾖｳ',
      full_lower: 'ミナミタカキグン　アリエチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '42214南島原市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '375',
    name: '南高来郡　布津町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾀｶｷｸﾞﾝ ﾌﾂﾁﾖｳ',
      full_lower: 'ミナミタカキグン　フツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '42214南島原市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '376',
    name: '南高来郡　深江町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾀｶｷｸﾞﾝ ﾌｶｴﾁﾖｳ',
      full_lower: 'ミナミタカキグン　フカエチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '42214南島原市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '380',
    name: '北松浦郡',
    kana_name: { half_upper: 'ｷﾀﾏﾂｳﾗｸﾞﾝ', full_lower: 'キタマツウラグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '381',
    name: '北松浦郡　大島村',
    kana_name: {
      half_upper: 'ｷﾀﾏﾂｳﾗｸﾞﾝ ｵｵｼﾏﾑﾗ',
      full_lower: 'キタマツウラグン　オオシマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '42207平戸市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '382',
    name: '北松浦郡　生月町',
    kana_name: {
      half_upper: 'ｷﾀﾏﾂｳﾗｸﾞﾝ ｲｷﾂｷﾁﾖｳ',
      full_lower: 'キタマツウラグン　イキツキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '42207平戸市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '383',
    name: '北松浦郡　小値賀町',
    kana_name: {
      half_upper: 'ｷﾀﾏﾂｳﾗｸﾞﾝ ｵﾁﾞｶﾁﾖｳ',
      full_lower: 'キタマツウラグン　オヂカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '384',
    name: '北松浦郡　宇久町',
    kana_name: {
      half_upper: 'ｷﾀﾏﾂｳﾗｸﾞﾝ ｳｸﾏﾁ',
      full_lower: 'キタマツウラグン　ウクマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '42202佐世保市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '385',
    name: '北松浦郡　田平町',
    kana_name: {
      half_upper: 'ｷﾀﾏﾂｳﾗｸﾞﾝ ﾀﾋﾞﾗﾁﾖｳ',
      full_lower: 'キタマツウラグン　タビラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '42207平戸市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '386',
    name: '北松浦郡　福島町',
    kana_name: {
      half_upper: 'ｷﾀﾏﾂｳﾗｸﾞﾝ ﾌｸｼﾏﾁﾖｳ',
      full_lower: 'キタマツウラグン　フクシマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '42208松浦市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '387',
    name: '北松浦郡　鷹島町',
    kana_name: {
      half_upper: 'ｷﾀﾏﾂｳﾗｸﾞﾝ ﾀｶｼﾏﾁﾖｳ',
      full_lower: 'キタマツウラグン　タカシマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '42208松浦市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '388',
    name: '北松浦郡　江迎町',
    kana_name: {
      half_upper: 'ｷﾀﾏﾂｳﾗｸﾞﾝ ｴﾑｶｴﾁﾖｳ',
      full_lower: 'キタマツウラグン　エムカエチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-31',
    remark: { start: null, end: '42202佐世保市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '389',
    name: '北松浦郡　鹿町町',
    kana_name: {
      half_upper: 'ｷﾀﾏﾂｳﾗｸﾞﾝ ｼｶﾏﾁﾁﾖｳ',
      full_lower: 'キタマツウラグン　シカマチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-31',
    remark: { start: null, end: '42202佐世保市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '390',
    name: '北松浦郡　小佐々町',
    kana_name: {
      half_upper: 'ｷﾀﾏﾂｳﾗｸﾞﾝ ｺｻｻﾞﾁﾖｳ',
      full_lower: 'キタマツウラグン　コサザチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '42202佐世保市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '391',
    name: '北松浦郡　佐々町',
    kana_name: {
      half_upper: 'ｷﾀﾏﾂｳﾗｸﾞﾝ ｻｻﾞﾁﾖｳ',
      full_lower: 'キタマツウラグン　サザチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '392',
    name: '北松浦郡　吉井町',
    kana_name: {
      half_upper: 'ｷﾀﾏﾂｳﾗｸﾞﾝ ﾖｼｲﾁﾖｳ',
      full_lower: 'キタマツウラグン　ヨシイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '42202佐世保市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '393',
    name: '北松浦郡　世知原町',
    kana_name: {
      half_upper: 'ｷﾀﾏﾂｳﾗｸﾞﾝ ｾﾁﾊﾞﾙﾁﾖｳ',
      full_lower: 'キタマツウラグン　セチバルチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '42202佐世保市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '400',
    name: '南松浦郡',
    kana_name: { half_upper: 'ﾐﾅﾐﾏﾂｳﾗｸﾞﾝ', full_lower: 'ミナミマツウラグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '401',
    name: '南松浦郡　富江町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾏﾂｳﾗｸﾞﾝ ﾄﾐｴﾁﾖｳ',
      full_lower: 'ミナミマツウラグン　トミエチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-08-01',
    remark: { start: null, end: '42211五島市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '402',
    name: '南松浦郡　玉之浦町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾏﾂｳﾗｸﾞﾝ ﾀﾏﾉｳﾗﾁﾖｳ',
      full_lower: 'ミナミマツウラグン　タマノウラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-08-01',
    remark: { start: null, end: '42211五島市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '403',
    name: '南松浦郡　三井楽町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾏﾂｳﾗｸﾞﾝ ﾐｲﾗｸﾁﾖｳ',
      full_lower: 'ミナミマツウラグン　ミイラクチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-08-01',
    remark: { start: null, end: '42211五島市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '404',
    name: '南松浦郡　岐宿町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾏﾂｳﾗｸﾞﾝ ｷｼｸﾁﾖｳ',
      full_lower: 'ミナミマツウラグン　キシクチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-08-01',
    remark: { start: null, end: '42211五島市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '405',
    name: '南松浦郡　奈留町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾏﾂｳﾗｸﾞﾝ ﾅﾙﾁﾖｳ',
      full_lower: 'ミナミマツウラグン　ナルチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-08-01',
    remark: { start: null, end: '42211五島市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '406',
    name: '南松浦郡　若松町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾏﾂｳﾗｸﾞﾝ ﾜｶﾏﾂﾁﾖｳ',
      full_lower: 'ミナミマツウラグン　ワカマツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-08-01',
    remark: { start: null, end: '42411南松浦郡　新上五島町', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '407',
    name: '南松浦郡　上五島町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾏﾂｳﾗｸﾞﾝ ｶﾐｺﾞﾄｳﾁﾖｳ',
      full_lower: 'ミナミマツウラグン　カミゴトウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-08-01',
    remark: { start: null, end: '42411南松浦郡　新上五島町', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '408',
    name: '南松浦郡　新魚目町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾏﾂｳﾗｸﾞﾝ ｼﾝｳｵﾉﾒﾁﾖｳ',
      full_lower: 'ミナミマツウラグン　シンウオノメチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-08-01',
    remark: { start: null, end: '42411南松浦郡　新上五島町', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '409',
    name: '南松浦郡　有川町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾏﾂｳﾗｸﾞﾝ ｱﾘｶﾜﾁﾖｳ',
      full_lower: 'ミナミマツウラグン　アリカワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-08-01',
    remark: { start: null, end: '42411南松浦郡　新上五島町', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '410',
    name: '南松浦郡　奈良尾町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾏﾂｳﾗｸﾞﾝ ﾅﾗｵﾁﾖｳ',
      full_lower: 'ミナミマツウラグン　ナラオチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-08-01',
    remark: { start: null, end: '42411南松浦郡　新上五島町', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '411',
    name: '南松浦郡　新上五島町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾏﾂｳﾗｸﾞﾝ ｼﾝｶﾐｺﾞﾄｳﾁﾖｳ',
      full_lower: 'ミナミマツウラグン　シンカミゴトウチョウ',
    },
    en: null,
    start_date: '2004-08-01',
    end_date: null,
    remark: { start: 'H16/08/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '420',
    name: '壱岐郡',
    kana_name: { half_upper: 'ｲｷｸﾞﾝ', full_lower: 'イキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '421',
    name: '壱岐郡　郷ノ浦町',
    kana_name: {
      half_upper: 'ｲｷｸﾞﾝ ｺﾞｳﾉｳﾗﾁﾖｳ',
      full_lower: 'イキグン　ゴウノウラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '42210壱岐市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '422',
    name: '壱岐郡　勝本町',
    kana_name: {
      half_upper: 'ｲｷｸﾞﾝ ｶﾂﾓﾄﾁﾖｳ',
      full_lower: 'イキグン　カツモトチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '42210壱岐市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '423',
    name: '壱岐郡　芦辺町',
    kana_name: {
      half_upper: 'ｲｷｸﾞﾝ ｱｼﾍﾞﾁﾖｳ',
      full_lower: 'イキグン　アシベチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '42210壱岐市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '424',
    name: '壱岐郡　石田町',
    kana_name: {
      half_upper: 'ｲｷｸﾞﾝ ｲｼﾀﾞﾁﾖｳ',
      full_lower: 'イキグン　イシダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '42210壱岐市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '440',
    name: '対馬支庁(下県郡・上県郡)',
    kana_name: { half_upper: 'ﾂｼﾏｼﾁﾖｳ', full_lower: 'ツシマシチョウ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '441',
    name: '下県郡　厳原町',
    kana_name: {
      half_upper: 'ｼﾓｱｶﾞﾀｸﾞﾝ ｲﾂﾞﾊﾗﾏﾁ',
      full_lower: 'シモアガタグン　イヅハラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '42209対馬市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '442',
    name: '下県郡　美津島町',
    kana_name: {
      half_upper: 'ｼﾓｱｶﾞﾀｸﾞﾝ ﾐﾂｼﾏﾁﾖｳ',
      full_lower: 'シモアガタグン　ミツシマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '42209対馬市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '443',
    name: '下県郡　豊玉町',
    kana_name: {
      half_upper: 'ｼﾓｱｶﾞﾀｸﾞﾝ ﾄﾖﾀﾏﾁﾖｳ',
      full_lower: 'シモアガタグン　トヨタマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '42209対馬市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '444',
    name: '上県郡　峰町',
    kana_name: {
      half_upper: 'ｶﾐｱｶﾞﾀｸﾞﾝ ﾐﾈﾁﾖｳ',
      full_lower: 'カミアガタグン　ミネチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '42209対馬市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '445',
    name: '上県郡　上県町',
    kana_name: {
      half_upper: 'ｶﾐｱｶﾞﾀｸﾞﾝ ｶﾐｱｶﾞﾀﾁﾖｳ',
      full_lower: 'カミアガタグン　カミアガタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '42209対馬市', other: null },
    note: null,
  },
  {
    pref_code: '42',
    city_code: '446',
    name: '上県郡　上対馬町',
    kana_name: {
      half_upper: 'ｶﾐｱｶﾞﾀｸﾞﾝ ｶﾐﾂｼﾏﾁﾖｳ',
      full_lower: 'カミアガタグン　カミツシマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-01',
    remark: { start: null, end: '42209対馬市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '100',
    name: '熊本市',
    kana_name: { half_upper: 'ｸﾏﾓﾄｼ', full_lower: 'クマモトシ' },
    en: null,
    start_date: '2012-04-01',
    end_date: null,
    remark: { start: 'H24/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '101',
    name: '熊本市　中央区',
    kana_name: {
      half_upper: 'ｸﾏﾓﾄｼ ﾁﾕｳｵｳｸ',
      full_lower: 'クマモトシ　チュウオウク',
    },
    en: null,
    start_date: '2012-04-01',
    end_date: null,
    remark: { start: 'H24/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '102',
    name: '熊本市　東区',
    kana_name: {
      half_upper: 'ｸﾏﾓﾄｼ ﾋｶﾞｼｸ',
      full_lower: 'クマモトシ　ヒガシク',
    },
    en: null,
    start_date: '2012-04-01',
    end_date: null,
    remark: { start: 'H24/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '103',
    name: '熊本市　西区',
    kana_name: { half_upper: 'ｸﾏﾓﾄｼ ﾆｼｸ', full_lower: 'クマモトシ　ニシク' },
    en: null,
    start_date: '2012-04-01',
    end_date: null,
    remark: { start: 'H24/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '104',
    name: '熊本市　南区',
    kana_name: {
      half_upper: 'ｸﾏﾓﾄｼ ﾐﾅﾐｸ',
      full_lower: 'クマモトシ　ミナミク',
    },
    en: null,
    start_date: '2012-04-01',
    end_date: null,
    remark: { start: 'H24/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '105',
    name: '熊本市　北区',
    kana_name: { half_upper: 'ｸﾏﾓﾄｼ ｷﾀｸ', full_lower: 'クマモトシ　キタク' },
    en: null,
    start_date: '2012-04-01',
    end_date: null,
    remark: { start: 'H24/04/01政令指定都市', end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '201',
    name: '熊本市',
    kana_name: { half_upper: 'ｸﾏﾓﾄｼ', full_lower: 'クマモトシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2012-04-01',
    remark: { start: null, end: '43100熊本市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '202',
    name: '八代市',
    kana_name: { half_upper: 'ﾔﾂｼﾛｼ', full_lower: 'ヤツシロシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '203',
    name: '人吉市',
    kana_name: { half_upper: 'ﾋﾄﾖｼｼ', full_lower: 'ヒトヨシシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '204',
    name: '荒尾市',
    kana_name: { half_upper: 'ｱﾗｵｼ', full_lower: 'アラオシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '205',
    name: '水俣市',
    kana_name: { half_upper: 'ﾐﾅﾏﾀｼ', full_lower: 'ミナマタシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '206',
    name: '玉名市',
    kana_name: { half_upper: 'ﾀﾏﾅｼ', full_lower: 'タマナシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '207',
    name: '本渡市',
    kana_name: { half_upper: 'ﾎﾝﾄﾞｼ', full_lower: 'ホンドシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '43215天草市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '208',
    name: '山鹿市',
    kana_name: { half_upper: 'ﾔﾏｶﾞｼ', full_lower: 'ヤマガシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '209',
    name: '牛深市',
    kana_name: { half_upper: 'ｳｼﾌﾞｶｼ', full_lower: 'ウシブカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '43215天草市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '210',
    name: '菊池市',
    kana_name: { half_upper: 'ｷｸﾁｼ', full_lower: 'キクチシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '211',
    name: '宇土市',
    kana_name: { half_upper: 'ｳﾄｼ', full_lower: 'ウトシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '212',
    name: '上天草市',
    kana_name: { half_upper: 'ｶﾐｱﾏｸｻｼ', full_lower: 'カミアマクサシ' },
    en: null,
    start_date: '2004-03-31',
    end_date: null,
    remark: { start: 'H16/03/31新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '213',
    name: '宇城市',
    kana_name: { half_upper: 'ｳｷｼ', full_lower: 'ウキシ' },
    en: null,
    start_date: '2005-01-15',
    end_date: null,
    remark: { start: 'H17/01/15新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '214',
    name: '阿蘇市',
    kana_name: { half_upper: 'ｱｿｼ', full_lower: 'アソシ' },
    en: null,
    start_date: '2005-02-11',
    end_date: null,
    remark: { start: 'H17/02/11新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '215',
    name: '天草市',
    kana_name: { half_upper: 'ｱﾏｸｻｼ', full_lower: 'アマクサシ' },
    en: null,
    start_date: '2006-03-27',
    end_date: null,
    remark: { start: 'H18/03/27新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '216',
    name: '合志市',
    kana_name: { half_upper: 'ｺｳｼｼ', full_lower: 'コウシシ' },
    en: null,
    start_date: '2006-02-27',
    end_date: null,
    remark: { start: 'H18/02/27新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '300',
    name: '飽託郡',
    kana_name: { half_upper: 'ﾎｳﾀｸｸﾞﾝ', full_lower: 'ホウタクグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '1991-02-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '301',
    name: '飽託郡　北部町',
    kana_name: {
      half_upper: 'ﾎｳﾀｸｸﾞﾝ ﾎｸﾌﾞﾏﾁ',
      full_lower: 'ホウタクグン　ホクブマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1991-02-01',
    remark: { start: null, end: '43201熊本市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '302',
    name: '飽託郡　河内町',
    kana_name: {
      half_upper: 'ﾎｳﾀｸｸﾞﾝ ｶﾜﾁﾏﾁ',
      full_lower: 'ホウタクグン　カワチマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1991-02-01',
    remark: { start: null, end: '43201熊本市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '303',
    name: '飽託郡　飽田町',
    kana_name: {
      half_upper: 'ﾎｳﾀｸｸﾞﾝ ｱｷﾀﾏﾁ',
      full_lower: 'ホウタクグン　アキタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1991-02-01',
    remark: { start: null, end: '43201熊本市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '304',
    name: '飽託郡　天明町',
    kana_name: {
      half_upper: 'ﾎｳﾀｸｸﾞﾝ ﾃﾝﾒｲﾏﾁ',
      full_lower: 'ホウタクグン　テンメイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1991-02-01',
    remark: { start: null, end: '43201熊本市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '305',
    name: '飽託郡　詫麻村',
    kana_name: {
      half_upper: 'ﾎｳﾀｸｸﾞﾝ ﾀｸﾏﾑﾗ',
      full_lower: 'ホウタクグン　タクマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-11-01',
    remark: { start: null, end: '43201熊本市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '320',
    name: '宇土郡',
    kana_name: { half_upper: 'ｳﾄｸﾞﾝ', full_lower: 'ウトグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-15',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '321',
    name: '宇土郡　三角町',
    kana_name: {
      half_upper: 'ｳﾄｸﾞﾝ ﾐｽﾐﾏﾁ',
      full_lower: 'ウトグン　ミスミマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-15',
    remark: { start: null, end: '43213宇城市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '322',
    name: '宇土郡　不知火町',
    kana_name: {
      half_upper: 'ｳﾄｸﾞﾝ ｼﾗﾇﾋﾏﾁ',
      full_lower: 'ウトグン　シラヌヒマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-15',
    remark: { start: null, end: '43213宇城市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '340',
    name: '下益城郡',
    kana_name: { half_upper: 'ｼﾓﾏｼｷｸﾞﾝ', full_lower: 'シモマシキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '341',
    name: '下益城郡　城南町',
    kana_name: {
      half_upper: 'ｼﾓﾏｼｷｸﾞﾝ ｼﾞﾖｳﾅﾝﾏﾁ',
      full_lower: 'シモマシキグン　ジョウナンマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-23',
    remark: { start: null, end: '43201熊本市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '342',
    name: '下益城郡　富合町',
    kana_name: {
      half_upper: 'ｼﾓﾏｼｷｸﾞﾝ ﾄﾐｱｲﾏﾁ',
      full_lower: 'シモマシキグン　トミアイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2008-10-06',
    remark: { start: null, end: '43201熊本市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '343',
    name: '下益城郡　松橋町',
    kana_name: {
      half_upper: 'ｼﾓﾏｼｷｸﾞﾝ ﾏﾂﾊﾞｾﾏﾁ',
      full_lower: 'シモマシキグン　マツバセマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-15',
    remark: { start: null, end: '43213宇城市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '344',
    name: '下益城郡　小川町',
    kana_name: {
      half_upper: 'ｼﾓﾏｼｷｸﾞﾝ ｵｶﾞﾜﾏﾁ',
      full_lower: 'シモマシキグン　オガワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-15',
    remark: { start: null, end: '43213宇城市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '345',
    name: '下益城郡　豊野町',
    kana_name: {
      half_upper: 'ｼﾓﾏｼｷｸﾞﾝ ﾄﾖﾉﾏﾁ',
      full_lower: 'シモマシキグン　トヨノマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-15',
    remark: { start: null, end: '43213宇城市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '346',
    name: '下益城郡　中央町',
    kana_name: {
      half_upper: 'ｼﾓﾏｼｷｸﾞﾝ ﾁﾕｳｵｳﾏﾁ',
      full_lower: 'シモマシキグン　チュウオウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '43348下益城郡　美里町', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '347',
    name: '下益城郡　砥用町',
    kana_name: {
      half_upper: 'ｼﾓﾏｼｷｸﾞﾝ ﾄﾓﾁﾏﾁ',
      full_lower: 'シモマシキグン　トモチマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '43348下益城郡　美里町', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '348',
    name: '下益城郡　美里町',
    kana_name: {
      half_upper: 'ｼﾓﾏｼｷｸﾞﾝ ﾐｻﾄﾏﾁ',
      full_lower: 'シモマシキグン　ミサトマチ',
    },
    en: null,
    start_date: '2004-11-01',
    end_date: null,
    remark: { start: 'H16/11/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '360',
    name: '玉名郡',
    kana_name: { half_upper: 'ﾀﾏﾅｸﾞﾝ', full_lower: 'タマナグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '361',
    name: '玉名郡　岱明町',
    kana_name: {
      half_upper: 'ﾀﾏﾅｸﾞﾝ ﾀｲﾒｲﾏﾁ',
      full_lower: 'タマナグン　タイメイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-03',
    remark: { start: null, end: '43206玉名市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '362',
    name: '玉名郡　横島町',
    kana_name: {
      half_upper: 'ﾀﾏﾅｸﾞﾝ ﾖｺｼﾏﾏﾁ',
      full_lower: 'タマナグン　ヨコシママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-03',
    remark: { start: null, end: '43206玉名市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '363',
    name: '玉名郡　天水町',
    kana_name: {
      half_upper: 'ﾀﾏﾅｸﾞﾝ ﾃﾝｽｲﾏﾁ',
      full_lower: 'タマナグン　テンスイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-03',
    remark: { start: null, end: '43206玉名市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '364',
    name: '玉名郡　玉東町',
    kana_name: {
      half_upper: 'ﾀﾏﾅｸﾞﾝ ｷﾞﾖｸﾄｳﾏﾁ',
      full_lower: 'タマナグン　ギョクトウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '365',
    name: '玉名郡　菊水町',
    kana_name: {
      half_upper: 'ﾀﾏﾅｸﾞﾝ ｷｸｽｲﾏﾁ',
      full_lower: 'タマナグン　キクスイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '43369玉名郡　和水町', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '366',
    name: '玉名郡　三加和町',
    kana_name: {
      half_upper: 'ﾀﾏﾅｸﾞﾝ ﾐｶﾜﾏﾁ',
      full_lower: 'タマナグン　ミカワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-01',
    remark: { start: null, end: '43369玉名郡　和水町', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '367',
    name: '玉名郡　南関町',
    kana_name: {
      half_upper: 'ﾀﾏﾅｸﾞﾝ ﾅﾝｶﾝﾏﾁ',
      full_lower: 'タマナグン　ナンカンマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '368',
    name: '玉名郡　長洲町',
    kana_name: {
      half_upper: 'ﾀﾏﾅｸﾞﾝ ﾅｶﾞｽﾏﾁ',
      full_lower: 'タマナグン　ナガスマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '369',
    name: '玉名郡　和水町',
    kana_name: {
      half_upper: 'ﾀﾏﾅｸﾞﾝ ﾅｺﾞﾐﾏﾁ',
      full_lower: 'タマナグン　ナゴミマチ',
    },
    en: null,
    start_date: '2006-03-01',
    end_date: null,
    remark: { start: 'H18/03/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '380',
    name: '鹿本郡',
    kana_name: { half_upper: 'ｶﾓﾄｸﾞﾝ', full_lower: 'カモトグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-23',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '381',
    name: '鹿本郡　鹿北町',
    kana_name: {
      half_upper: 'ｶﾓﾄｸﾞﾝ ｶﾎｸﾏﾁ',
      full_lower: 'カモトグン　カホクマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-15',
    remark: { start: null, end: '43208山鹿市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '382',
    name: '鹿本郡　菊鹿町',
    kana_name: {
      half_upper: 'ｶﾓﾄｸﾞﾝ ｷｸｶﾏﾁ',
      full_lower: 'カモトグン　キクカマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-15',
    remark: { start: null, end: '43208山鹿市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '383',
    name: '鹿本郡　鹿本町',
    kana_name: {
      half_upper: 'ｶﾓﾄｸﾞﾝ ｶﾓﾄﾏﾁ',
      full_lower: 'カモトグン　カモトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-15',
    remark: { start: null, end: '43208山鹿市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '384',
    name: '鹿本郡　鹿央町',
    kana_name: {
      half_upper: 'ｶﾓﾄｸﾞﾝ ｶｵｳﾏﾁ',
      full_lower: 'カモトグン　カオウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-15',
    remark: { start: null, end: '43208山鹿市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '385',
    name: '鹿本郡　植木町',
    kana_name: {
      half_upper: 'ｶﾓﾄｸﾞﾝ ｳｴｷﾏﾁ',
      full_lower: 'カモトグン　ウエキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-23',
    remark: { start: null, end: '43201熊本市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '386',
    name: '鹿本郡　田底村',
    kana_name: {
      half_upper: 'ｶﾓﾄｸﾞﾝ ﾀｿｺﾑﾗ',
      full_lower: 'カモトグン　タソコムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1969-04-01',
    remark: { start: null, end: '43385鹿本郡　植木町', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '400',
    name: '菊池郡',
    kana_name: { half_upper: 'ｷｸﾁｸﾞﾝ', full_lower: 'キクチグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '401',
    name: '菊池郡　七城町',
    kana_name: {
      half_upper: 'ｷｸﾁｸﾞﾝ ｼﾁｼﾞﾖｳﾏﾁ',
      full_lower: 'キクチグン　シチジョウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '43210菊池市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '402',
    name: '菊池郡　旭志村',
    kana_name: {
      half_upper: 'ｷｸﾁｸﾞﾝ ｷﾖｸｼﾑﾗ',
      full_lower: 'キクチグン　キョクシムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '43210菊池市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '403',
    name: '菊池郡　大津町',
    kana_name: {
      half_upper: 'ｷｸﾁｸﾞﾝ ｵｵﾂﾞﾏﾁ',
      full_lower: 'キクチグン　オオヅマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '404',
    name: '菊池郡　菊陽町',
    kana_name: {
      half_upper: 'ｷｸﾁｸﾞﾝ ｷｸﾖｳﾏﾁ',
      full_lower: 'キクチグン　キクヨウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '405',
    name: '菊池郡　合志町',
    kana_name: {
      half_upper: 'ｷｸﾁｸﾞﾝ ｺｳｼﾏﾁ',
      full_lower: 'キクチグン　コウシマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-27',
    remark: { start: null, end: '43216合志市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '406',
    name: '菊池郡　泗水町',
    kana_name: {
      half_upper: 'ｷｸﾁｸﾞﾝ ｼｽｲﾏﾁ',
      full_lower: 'キクチグン　シスイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '43210菊池市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '407',
    name: '菊池郡　西合志町',
    kana_name: {
      half_upper: 'ｷｸﾁｸﾞﾝ ﾆｼｺﾞｳｼﾏﾁ',
      full_lower: 'キクチグン　ニシゴウシマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-27',
    remark: { start: null, end: '43216合志市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '420',
    name: '阿蘇郡',
    kana_name: { half_upper: 'ｱｿｸﾞﾝ', full_lower: 'アソグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '421',
    name: '阿蘇郡　一の宮町',
    kana_name: {
      half_upper: 'ｱｿｸﾞﾝ ｲﾁﾉﾐﾔﾏﾁ',
      full_lower: 'アソグン　イチノミヤマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-11',
    remark: { start: null, end: '43214阿蘇市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '422',
    name: '阿蘇郡　阿蘇町',
    kana_name: { half_upper: 'ｱｿｸﾞﾝ ｱｿﾏﾁ', full_lower: 'アソグン　アソマチ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-11',
    remark: { start: null, end: '43214阿蘇市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '423',
    name: '阿蘇郡　南小国町',
    kana_name: {
      half_upper: 'ｱｿｸﾞﾝ ﾐﾅﾐｵｸﾞﾆﾏﾁ',
      full_lower: 'アソグン　ミナミオグニマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '424',
    name: '阿蘇郡　小国町',
    kana_name: {
      half_upper: 'ｱｿｸﾞﾝ ｵｸﾞﾆﾏﾁ',
      full_lower: 'アソグン　オグニマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '425',
    name: '阿蘇郡　産山村',
    kana_name: {
      half_upper: 'ｱｿｸﾞﾝ ｳﾌﾞﾔﾏﾑﾗ',
      full_lower: 'アソグン　ウブヤマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '426',
    name: '阿蘇郡　波野村',
    kana_name: {
      half_upper: 'ｱｿｸﾞﾝ ﾅﾐﾉｿﾝ',
      full_lower: 'アソグン　ナミノソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-11',
    remark: { start: null, end: '43214阿蘇市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '427',
    name: '阿蘇郡　蘇陽町',
    kana_name: {
      half_upper: 'ｱｿｸﾞﾝ ｿﾖｳﾏﾁ',
      full_lower: 'アソグン　ソヨウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-11',
    remark: { start: null, end: '43447上益城郡　山都町', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '428',
    name: '阿蘇郡　高森町',
    kana_name: {
      half_upper: 'ｱｿｸﾞﾝ ﾀｶﾓﾘﾏﾁ',
      full_lower: 'アソグン　タカモリマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '429',
    name: '阿蘇郡　白水村',
    kana_name: {
      half_upper: 'ｱｿｸﾞﾝ ﾊｸｽｲﾑﾗ',
      full_lower: 'アソグン　ハクスイムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-13',
    remark: { start: null, end: '43433阿蘇郡　南阿蘇村', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '430',
    name: '阿蘇郡　久木野村',
    kana_name: {
      half_upper: 'ｱｿｸﾞﾝ ｸｷﾞﾉﾑﾗ',
      full_lower: 'アソグン　クギノムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-13',
    remark: { start: null, end: '43433阿蘇郡　南阿蘇村', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '431',
    name: '阿蘇郡　長陽村',
    kana_name: {
      half_upper: 'ｱｿｸﾞﾝ ﾁﾖｳﾖｳﾑﾗ',
      full_lower: 'アソグン　チョウヨウムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-13',
    remark: { start: null, end: '43433阿蘇郡　南阿蘇村', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '432',
    name: '阿蘇郡　西原村',
    kana_name: {
      half_upper: 'ｱｿｸﾞﾝ ﾆｼﾊﾗﾑﾗ',
      full_lower: 'アソグン　ニシハラムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '433',
    name: '阿蘇郡　南阿蘇村',
    kana_name: {
      half_upper: 'ｱｿｸﾞﾝ ﾐﾅﾐｱｿﾑﾗ',
      full_lower: 'アソグン　ミナミアソムラ',
    },
    en: null,
    start_date: '2005-02-13',
    end_date: null,
    remark: { start: 'H17/02/13新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '440',
    name: '上益城郡',
    kana_name: { half_upper: 'ｶﾐﾏｼｷｸﾞﾝ', full_lower: 'カミマシキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '441',
    name: '上益城郡　御船町',
    kana_name: {
      half_upper: 'ｶﾐﾏｼｷｸﾞﾝ ﾐﾌﾈﾏﾁ',
      full_lower: 'カミマシキグン　ミフネマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '442',
    name: '上益城郡　嘉島町',
    kana_name: {
      half_upper: 'ｶﾐﾏｼｷｸﾞﾝ ｶｼﾏﾏﾁ',
      full_lower: 'カミマシキグン　カシママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '443',
    name: '上益城郡　益城町',
    kana_name: {
      half_upper: 'ｶﾐﾏｼｷｸﾞﾝ ﾏｼｷﾏﾁ',
      full_lower: 'カミマシキグン　マシキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '444',
    name: '上益城郡　甲佐町',
    kana_name: {
      half_upper: 'ｶﾐﾏｼｷｸﾞﾝ ｺｳｻﾏﾁ',
      full_lower: 'カミマシキグン　コウサマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '445',
    name: '上益城郡　矢部町',
    kana_name: {
      half_upper: 'ｶﾐﾏｼｷｸﾞﾝ ﾔﾍﾞﾏﾁ',
      full_lower: 'カミマシキグン　ヤベマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-11',
    remark: { start: null, end: '43447上益城郡　山都町', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '446',
    name: '上益城郡　清和村',
    kana_name: {
      half_upper: 'ｶﾐﾏｼｷｸﾞﾝ ｾｲﾜｿﾝ',
      full_lower: 'カミマシキグン　セイワソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-02-11',
    remark: { start: null, end: '43447上益城郡　山都町', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '447',
    name: '上益城郡　山都町',
    kana_name: {
      half_upper: 'ｶﾐﾏｼｷｸﾞﾝ ﾔﾏﾄﾁﾖｳ',
      full_lower: 'カミマシキグン　ヤマトチョウ',
    },
    en: null,
    start_date: '2005-02-11',
    end_date: null,
    remark: { start: 'H17/02/11新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '460',
    name: '八代郡',
    kana_name: { half_upper: 'ﾔﾂｼﾛｸﾞﾝ', full_lower: 'ヤツシログン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '461',
    name: '八代郡　坂本村',
    kana_name: {
      half_upper: 'ﾔﾂｼﾛｸﾞﾝ ｻｶﾓﾄﾑﾗ',
      full_lower: 'ヤツシログン　サカモトムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-08-01',
    remark: { start: null, end: '43202八代市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '462',
    name: '八代郡　千丁町',
    kana_name: {
      half_upper: 'ﾔﾂｼﾛｸﾞﾝ ｾﾝﾁﾖｳﾏﾁ',
      full_lower: 'ヤツシログン　センチョウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-08-01',
    remark: { start: null, end: '43202八代市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '463',
    name: '八代郡　鏡町',
    kana_name: {
      half_upper: 'ﾔﾂｼﾛｸﾞﾝ ｶｶﾞﾐﾏﾁ',
      full_lower: 'ヤツシログン　カガミマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-08-01',
    remark: { start: null, end: '43202八代市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '464',
    name: '八代郡　竜北町',
    kana_name: {
      half_upper: 'ﾔﾂｼﾛｸﾞﾝ ﾘﾕｳﾎｸﾏﾁ',
      full_lower: 'ヤツシログン　リュウホクマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '43468八代郡　氷川町', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '465',
    name: '八代郡　宮原町',
    kana_name: {
      half_upper: 'ﾔﾂｼﾛｸﾞﾝ ﾐﾔﾊﾗﾏﾁ',
      full_lower: 'ヤツシログン　ミヤハラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '43468八代郡　氷川町', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '466',
    name: '八代郡　東陽村',
    kana_name: {
      half_upper: 'ﾔﾂｼﾛｸﾞﾝ ﾄｳﾖｳﾑﾗ',
      full_lower: 'ヤツシログン　トウヨウムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-08-01',
    remark: { start: null, end: '43202八代市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '467',
    name: '八代郡　泉村',
    kana_name: {
      half_upper: 'ﾔﾂｼﾛｸﾞﾝ ｲｽﾞﾐﾑﾗ',
      full_lower: 'ヤツシログン　イズミムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-08-01',
    remark: { start: null, end: '43202八代市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '468',
    name: '八代郡　氷川町',
    kana_name: {
      half_upper: 'ﾔﾂｼﾛｸﾞﾝ ﾋｶﾜﾁﾖｳ',
      full_lower: 'ヤツシログン　ヒカワチョウ',
    },
    en: null,
    start_date: '2005-10-01',
    end_date: null,
    remark: { start: 'H17/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '480',
    name: '葦北郡',
    kana_name: { half_upper: 'ｱｼｷﾀｸﾞﾝ', full_lower: 'アシキタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '481',
    name: '葦北郡　田浦町',
    kana_name: {
      half_upper: 'ｱｼｷﾀｸﾞﾝ ﾀﾉｳﾗﾏﾁ',
      full_lower: 'アシキタグン　タノウラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '43482葦北郡　芦北町', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '482',
    name: '葦北郡　芦北町',
    kana_name: {
      half_upper: 'ｱｼｷﾀｸﾞﾝ ｱｼｷﾀﾏﾁ',
      full_lower: 'アシキタグン　アシキタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '483',
    name: '葦北郡　湯浦町',
    kana_name: {
      half_upper: 'ｱｼｷﾀｸﾞﾝ ﾕﾉｳﾗﾏﾁ',
      full_lower: 'アシキタグン　ユノウラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-11-01',
    remark: { start: null, end: '43482葦北郡　芦北町', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '484',
    name: '葦北郡　津奈木町',
    kana_name: {
      half_upper: 'ｱｼｷﾀｸﾞﾝ ﾂﾅｷﾞﾏﾁ',
      full_lower: 'アシキタグン　ツナギマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '500',
    name: '球磨郡',
    kana_name: { half_upper: 'ｸﾏｸﾞﾝ', full_lower: 'クマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '501',
    name: '球磨郡　錦町',
    kana_name: {
      half_upper: 'ｸﾏｸﾞﾝ ﾆｼｷﾏﾁ',
      full_lower: 'クマグン　ニシキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '502',
    name: '球磨郡　上村',
    kana_name: { half_upper: 'ｸﾏｸﾞﾝ ｳｴﾑﾗ', full_lower: 'クマグン　ウエムラ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: '43514球磨郡　あさぎり町', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '503',
    name: '球磨郡　免田町',
    kana_name: {
      half_upper: 'ｸﾏｸﾞﾝ ﾒﾝﾀﾞﾏﾁ',
      full_lower: 'クマグン　メンダマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: '43514球磨郡　あさぎり町', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '504',
    name: '球磨郡　岡原村',
    kana_name: {
      half_upper: 'ｸﾏｸﾞﾝ ｵｶﾊﾙﾑﾗ',
      full_lower: 'クマグン　オカハルムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: '43514球磨郡　あさぎり町', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '505',
    name: '球磨郡　多良木町',
    kana_name: {
      half_upper: 'ｸﾏｸﾞﾝ ﾀﾗｷﾞﾏﾁ',
      full_lower: 'クマグン　タラギマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '506',
    name: '球磨郡　湯前町',
    kana_name: {
      half_upper: 'ｸﾏｸﾞﾝ ﾕﾉﾏｴﾏﾁ',
      full_lower: 'クマグン　ユノマエマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '507',
    name: '球磨郡　水上村',
    kana_name: {
      half_upper: 'ｸﾏｸﾞﾝ ﾐｽﾞｶﾐﾑﾗ',
      full_lower: 'クマグン　ミズカミムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '508',
    name: '球磨郡　須恵村',
    kana_name: { half_upper: 'ｸﾏｸﾞﾝ ｽｴﾑﾗ', full_lower: 'クマグン　スエムラ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: '43514球磨郡　あさぎり町', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '509',
    name: '球磨郡　深田村',
    kana_name: {
      half_upper: 'ｸﾏｸﾞﾝ ﾌｶﾀﾞﾑﾗ',
      full_lower: 'クマグン　フカダムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2003-04-01',
    remark: { start: null, end: '43514球磨郡　あさぎり町', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '510',
    name: '球磨郡　相良村',
    kana_name: {
      half_upper: 'ｸﾏｸﾞﾝ ｻｶﾞﾗﾑﾗ',
      full_lower: 'クマグン　サガラムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '511',
    name: '球磨郡　五木村',
    kana_name: {
      half_upper: 'ｸﾏｸﾞﾝ ｲﾂｷﾑﾗ',
      full_lower: 'クマグン　イツキムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '512',
    name: '球磨郡　山江村',
    kana_name: {
      half_upper: 'ｸﾏｸﾞﾝ ﾔﾏｴﾑﾗ',
      full_lower: 'クマグン　ヤマエムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '513',
    name: '球磨郡　球磨村',
    kana_name: { half_upper: 'ｸﾏｸﾞﾝ ｸﾏﾑﾗ', full_lower: 'クマグン　クマムラ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '514',
    name: '球磨郡　あさぎり町',
    kana_name: {
      half_upper: 'ｸﾏｸﾞﾝ ｱｻｷﾞﾘﾁﾖｳ',
      full_lower: 'クマグン　アサギリチョウ',
    },
    en: null,
    start_date: '2003-04-01',
    end_date: null,
    remark: { start: 'H15/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '520',
    name: '天草郡',
    kana_name: { half_upper: 'ｱﾏｸｻｸﾞﾝ', full_lower: 'アマクサグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '521',
    name: '天草郡　大矢野町',
    kana_name: {
      half_upper: 'ｱﾏｸｻｸﾞﾝ ｵｵﾔﾉﾏﾁ',
      full_lower: 'アマクサグン　オオヤノマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-31',
    remark: { start: null, end: '43212上天草市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '522',
    name: '天草郡　松島町',
    kana_name: {
      half_upper: 'ｱﾏｸｻｸﾞﾝ ﾏﾂｼﾏﾏﾁ',
      full_lower: 'アマクサグン　マツシママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-31',
    remark: { start: null, end: '43212上天草市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '523',
    name: '天草郡　有明町',
    kana_name: {
      half_upper: 'ｱﾏｸｻｸﾞﾝ ｱﾘｱｹﾏﾁ',
      full_lower: 'アマクサグン　アリアケマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '43215天草市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '524',
    name: '天草郡　姫戸町',
    kana_name: {
      half_upper: 'ｱﾏｸｻｸﾞﾝ ﾋﾒﾄﾞﾏﾁ',
      full_lower: 'アマクサグン　ヒメドマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-31',
    remark: { start: null, end: '43212上天草市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '525',
    name: '天草郡　龍ヶ岳町',
    kana_name: {
      half_upper: 'ｱﾏｸｻｸﾞﾝ ﾘﾕｳｶﾞﾀｹﾏﾁ',
      full_lower: 'アマクサグン　リュウガタケマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-03-31',
    remark: { start: null, end: '43212上天草市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '526',
    name: '天草郡　御所浦町',
    kana_name: {
      half_upper: 'ｱﾏｸｻｸﾞﾝ ｺﾞｼﾖｳﾗﾏﾁ',
      full_lower: 'アマクサグン　ゴショウラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '43215天草市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '527',
    name: '天草郡　倉岳町',
    kana_name: {
      half_upper: 'ｱﾏｸｻｸﾞﾝ ｸﾗﾀｹﾏﾁ',
      full_lower: 'アマクサグン　クラタケマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '43215天草市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '528',
    name: '天草郡　栖本町',
    kana_name: {
      half_upper: 'ｱﾏｸｻｸﾞﾝ ｽﾓﾄﾏﾁ',
      full_lower: 'アマクサグン　スモトマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '43215天草市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '529',
    name: '天草郡　新和町',
    kana_name: {
      half_upper: 'ｱﾏｸｻｸﾞﾝ ｼﾝﾜﾏﾁ',
      full_lower: 'アマクサグン　シンワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '43215天草市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '530',
    name: '天草郡　五和町',
    kana_name: {
      half_upper: 'ｱﾏｸｻｸﾞﾝ ｲﾂﾜﾏﾁ',
      full_lower: 'アマクサグン　イツワマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '43215天草市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '531',
    name: '天草郡　苓北町',
    kana_name: {
      half_upper: 'ｱﾏｸｻｸﾞﾝ ﾚｲﾎｸﾏﾁ',
      full_lower: 'アマクサグン　レイホクマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '532',
    name: '天草郡　天草町',
    kana_name: {
      half_upper: 'ｱﾏｸｻｸﾞﾝ ｱﾏｸｻﾏﾁ',
      full_lower: 'アマクサグン　アマクサマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '43215天草市', other: null },
    note: null,
  },
  {
    pref_code: '43',
    city_code: '533',
    name: '天草郡　河浦町',
    kana_name: {
      half_upper: 'ｱﾏｸｻｸﾞﾝ ｶﾜｳﾗﾏﾁ',
      full_lower: 'アマクサグン　カワウラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-27',
    remark: { start: null, end: '43215天草市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '201',
    name: '大分市',
    kana_name: { half_upper: 'ｵｵｲﾀｼ', full_lower: 'オオイタシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '202',
    name: '別府市',
    kana_name: { half_upper: 'ﾍﾞﾂﾌﾟｼ', full_lower: 'ベップシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '203',
    name: '中津市',
    kana_name: { half_upper: 'ﾅｶﾂｼ', full_lower: 'ナカツシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '204',
    name: '日田市',
    kana_name: { half_upper: 'ﾋﾀｼ', full_lower: 'ヒタシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '205',
    name: '佐伯市',
    kana_name: { half_upper: 'ｻｲｷｼ', full_lower: 'サイキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '206',
    name: '臼杵市',
    kana_name: { half_upper: 'ｳｽｷｼ', full_lower: 'ウスキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '207',
    name: '津久見市',
    kana_name: { half_upper: 'ﾂｸﾐｼ', full_lower: 'ツクミシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '208',
    name: '竹田市',
    kana_name: { half_upper: 'ﾀｹﾀｼ', full_lower: 'タケタシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '209',
    name: '豊後高田市',
    kana_name: { half_upper: 'ﾌﾞﾝｺﾞﾀｶﾀﾞｼ', full_lower: 'ブンゴタカダシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '210',
    name: '杵築市',
    kana_name: { half_upper: 'ｷﾂｷｼ', full_lower: 'キツキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '211',
    name: '宇佐市',
    kana_name: { half_upper: 'ｳｻｼ', full_lower: 'ウサシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '212',
    name: '豊後大野市',
    kana_name: { half_upper: 'ﾌﾞﾝｺﾞｵｵﾉｼ', full_lower: 'ブンゴオオノシ' },
    en: null,
    start_date: '2005-03-31',
    end_date: null,
    remark: { start: 'H17/03/31新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '213',
    name: '由布市',
    kana_name: { half_upper: 'ﾕﾌｼ', full_lower: 'ユフシ' },
    en: null,
    start_date: '2005-10-01',
    end_date: null,
    remark: { start: 'H17/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '214',
    name: '国東市',
    kana_name: { half_upper: 'ｸﾆｻｷｼ', full_lower: 'クニサキシ' },
    en: null,
    start_date: '2006-03-31',
    end_date: null,
    remark: { start: 'H18/03/31新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '300',
    name: '西国東郡',
    kana_name: { half_upper: 'ﾆｼｸﾆｻｷｸﾞﾝ', full_lower: 'ニシクニサキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '301',
    name: '西国東郡　大田村',
    kana_name: {
      half_upper: 'ﾆｼｸﾆｻｷｸﾞﾝ ｵｵﾀﾑﾗ',
      full_lower: 'ニシクニサキグン　オオタムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '44210杵築市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '302',
    name: '西国東郡　真玉町',
    kana_name: {
      half_upper: 'ﾆｼｸﾆｻｷｸﾞﾝ ﾏﾀﾏﾏﾁ',
      full_lower: 'ニシクニサキグン　マタママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '44209豊後高田市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '303',
    name: '西国東郡　香々地町',
    kana_name: {
      half_upper: 'ﾆｼｸﾆｻｷｸﾞﾝ ｶｶﾁﾞﾁﾖｳ',
      full_lower: 'ニシクニサキグン　カカヂチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '44209豊後高田市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '320',
    name: '東国東郡',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｸﾆｻｷｸﾞﾝ',
      full_lower: 'ヒガシクニサキグン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '321',
    name: '東国東郡　国見町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｸﾆｻｷｸﾞﾝ ｸﾆﾐﾁﾖｳ',
      full_lower: 'ヒガシクニサキグン　クニミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '44214国東市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '322',
    name: '東国東郡　姫島村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｸﾆｻｷｸﾞﾝ ﾋﾒｼﾏﾑﾗ',
      full_lower: 'ヒガシクニサキグン　ヒメシマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '323',
    name: '東国東郡　国東町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｸﾆｻｷｸﾞﾝ ｸﾆｻｷﾏﾁ',
      full_lower: 'ヒガシクニサキグン　クニサキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '44214国東市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '324',
    name: '東国東郡　武蔵町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｸﾆｻｷｸﾞﾝ ﾑｻｼﾏﾁ',
      full_lower: 'ヒガシクニサキグン　ムサシマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '44214国東市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '325',
    name: '東国東郡　安岐町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｸﾆｻｷｸﾞﾝ ｱｷﾏﾁ',
      full_lower: 'ヒガシクニサキグン　アキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-31',
    remark: { start: null, end: '44214国東市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '340',
    name: '速見郡',
    kana_name: { half_upper: 'ﾊﾔﾐｸﾞﾝ', full_lower: 'ハヤミグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '341',
    name: '速見郡　日出町',
    kana_name: {
      half_upper: 'ﾊﾔﾐｸﾞﾝ ﾋｼﾞﾏﾁ',
      full_lower: 'ハヤミグン　ヒジマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '342',
    name: '速見郡　山香町',
    kana_name: {
      half_upper: 'ﾊﾔﾐｸﾞﾝ ﾔﾏｶﾞﾏﾁ',
      full_lower: 'ハヤミグン　ヤマガマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '44210杵築市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '360',
    name: '大分郡',
    kana_name: { half_upper: 'ｵｵｲﾀｸﾞﾝ', full_lower: 'オオイタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '361',
    name: '大分郡　野津原町',
    kana_name: {
      half_upper: 'ｵｵｲﾀｸﾞﾝ ﾉﾂﾊﾙﾏﾁ',
      full_lower: 'オオイタグン　ノツハルマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '44201大分市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '362',
    name: '大分郡　挾間町',
    kana_name: {
      half_upper: 'ｵｵｲﾀｸﾞﾝ ﾊｻﾏﾏﾁ',
      full_lower: 'オオイタグン　ハサママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '44213由布市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '363',
    name: '大分郡　庄内町',
    kana_name: {
      half_upper: 'ｵｵｲﾀｸﾞﾝ ｼﾖｳﾅｲﾁﾖｳ',
      full_lower: 'オオイタグン　ショウナイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '44213由布市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '364',
    name: '大分郡　湯布院町',
    kana_name: {
      half_upper: 'ｵｵｲﾀｸﾞﾝ ﾕﾌｲﾝﾁﾖｳ',
      full_lower: 'オオイタグン　ユフインチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '44213由布市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '380',
    name: '北海部郡',
    kana_name: { half_upper: 'ｷﾀｱﾏﾍﾞｸﾞﾝ', full_lower: 'キタアマベグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '381',
    name: '北海部郡　佐賀関町',
    kana_name: {
      half_upper: 'ｷﾀｱﾏﾍﾞｸﾞﾝ ｻｶﾞﾉｾｷﾏﾁ',
      full_lower: 'キタアマベグン　サガノセキマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '44201大分市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '400',
    name: '南海部郡',
    kana_name: { half_upper: 'ﾐﾅﾐｱﾏﾍﾞｸﾞﾝ', full_lower: 'ミナミアマベグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-03',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '401',
    name: '南海部郡　上浦町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱﾏﾍﾞｸﾞﾝ ｶﾐｳﾗﾏﾁ',
      full_lower: 'ミナミアマベグン　カミウラマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-03',
    remark: { start: null, end: '44205佐伯市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '402',
    name: '南海部郡　弥生町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱﾏﾍﾞｸﾞﾝ ﾔﾖｲﾏﾁ',
      full_lower: 'ミナミアマベグン　ヤヨイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-03',
    remark: { start: null, end: '44205佐伯市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '403',
    name: '南海部郡　本匠村',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱﾏﾍﾞｸﾞﾝ ﾎﾝｼﾞﾖｳﾑﾗ',
      full_lower: 'ミナミアマベグン　ホンジョウムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-03',
    remark: { start: null, end: '44205佐伯市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '404',
    name: '南海部郡　宇目町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱﾏﾍﾞｸﾞﾝ ｳﾒﾏﾁ',
      full_lower: 'ミナミアマベグン　ウメマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-03',
    remark: { start: null, end: '44205佐伯市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '405',
    name: '南海部郡　直川村',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱﾏﾍﾞｸﾞﾝ ﾅｵｶﾜｿﾝ',
      full_lower: 'ミナミアマベグン　ナオカワソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-03',
    remark: { start: null, end: '44205佐伯市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '406',
    name: '南海部郡　鶴見町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱﾏﾍﾞｸﾞﾝ ﾂﾙﾐﾏﾁ',
      full_lower: 'ミナミアマベグン　ツルミマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-03',
    remark: { start: null, end: '44205佐伯市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '407',
    name: '南海部郡　米水津村',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱﾏﾍﾞｸﾞﾝ ﾖﾉｳﾂﾞﾑﾗ',
      full_lower: 'ミナミアマベグン　ヨノウヅムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-03',
    remark: { start: null, end: '44205佐伯市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '408',
    name: '南海部郡　蒲江町',
    kana_name: {
      half_upper: 'ﾐﾅﾐｱﾏﾍﾞｸﾞﾝ ｶﾏｴﾁﾖｳ',
      full_lower: 'ミナミアマベグン　カマエチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-03',
    remark: { start: null, end: '44205佐伯市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '420',
    name: '大野郡',
    kana_name: { half_upper: 'ｵｵﾉｸﾞﾝ', full_lower: 'オオノグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '421',
    name: '大野郡　野津町',
    kana_name: {
      half_upper: 'ｵｵﾉｸﾞﾝ ﾉﾂﾏﾁ',
      full_lower: 'オオノグン　ノツマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-01-01',
    remark: { start: null, end: '44206臼杵市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '422',
    name: '大野郡　三重町',
    kana_name: {
      half_upper: 'ｵｵﾉｸﾞﾝ ﾐｴﾏﾁ',
      full_lower: 'オオノグン　ミエマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '44212豊後大野市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '423',
    name: '大野郡　清川村',
    kana_name: {
      half_upper: 'ｵｵﾉｸﾞﾝ ｷﾖｶﾜﾑﾗ',
      full_lower: 'オオノグン　キヨカワムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '44212豊後大野市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '424',
    name: '大野郡　緒方町',
    kana_name: {
      half_upper: 'ｵｵﾉｸﾞﾝ ｵｶﾞﾀﾏﾁ',
      full_lower: 'オオノグン　オガタマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '44212豊後大野市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '425',
    name: '大野郡　朝地町',
    kana_name: {
      half_upper: 'ｵｵﾉｸﾞﾝ ｱｻｼﾞﾏﾁ',
      full_lower: 'オオノグン　アサジマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '44212豊後大野市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '426',
    name: '大野郡　大野町',
    kana_name: {
      half_upper: 'ｵｵﾉｸﾞﾝ ｵｵﾉﾏﾁ',
      full_lower: 'オオノグン　オオノマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '44212豊後大野市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '427',
    name: '大野郡　千歳村',
    kana_name: {
      half_upper: 'ｵｵﾉｸﾞﾝ ﾁﾄｾﾑﾗ',
      full_lower: 'オオノグン　チトセムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '44212豊後大野市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '428',
    name: '大野郡　犬飼町',
    kana_name: {
      half_upper: 'ｵｵﾉｸﾞﾝ ｲﾇｶｲﾏﾁ',
      full_lower: 'オオノグン　イヌカイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '44212豊後大野市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '440',
    name: '直入郡',
    kana_name: { half_upper: 'ﾅｵｲﾘｸﾞﾝ', full_lower: 'ナオイリグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '441',
    name: '直入郡　荻町',
    kana_name: {
      half_upper: 'ﾅｵｲﾘｸﾞﾝ ｵｷﾞﾏﾁ',
      full_lower: 'ナオイリグン　オギマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '44208竹田市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '442',
    name: '直入郡　久住町',
    kana_name: {
      half_upper: 'ﾅｵｲﾘｸﾞﾝ ｸｼﾞﾕｳﾏﾁ',
      full_lower: 'ナオイリグン　クジュウマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '44208竹田市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '443',
    name: '直入郡　直入町',
    kana_name: {
      half_upper: 'ﾅｵｲﾘｸﾞﾝ ﾅｵｲﾘﾏﾁ',
      full_lower: 'ナオイリグン　ナオイリマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '44208竹田市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '460',
    name: '玖珠郡',
    kana_name: { half_upper: 'ｸｽｸﾞﾝ', full_lower: 'クスグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '461',
    name: '玖珠郡　九重町',
    kana_name: {
      half_upper: 'ｸｽｸﾞﾝ ｺｺﾉｴﾏﾁ',
      full_lower: 'クスグン　ココノエマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '462',
    name: '玖珠郡　玖珠町',
    kana_name: { half_upper: 'ｸｽｸﾞﾝ ｸｽﾏﾁ', full_lower: 'クスグン　クスマチ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '480',
    name: '日田郡',
    kana_name: { half_upper: 'ﾋﾀｸﾞﾝ', full_lower: 'ヒタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '481',
    name: '日田郡　前津江村',
    kana_name: {
      half_upper: 'ﾋﾀｸﾞﾝ ﾏｴﾂｴﾑﾗ',
      full_lower: 'ヒタグン　マエツエムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '44204日田市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '482',
    name: '日田郡　中津江村',
    kana_name: {
      half_upper: 'ﾋﾀｸﾞﾝ ﾅｶﾂｴﾑﾗ',
      full_lower: 'ヒタグン　ナカツエムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '44204日田市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '483',
    name: '日田郡　上津江村',
    kana_name: {
      half_upper: 'ﾋﾀｸﾞﾝ ｶﾐﾂｴﾑﾗ',
      full_lower: 'ヒタグン　カミツエムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '44204日田市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '484',
    name: '日田郡　大山町',
    kana_name: {
      half_upper: 'ﾋﾀｸﾞﾝ ｵｵﾔﾏﾏﾁ',
      full_lower: 'ヒタグン　オオヤママチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '44204日田市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '485',
    name: '日田郡　天瀬町',
    kana_name: {
      half_upper: 'ﾋﾀｸﾞﾝ ｱﾏｶﾞｾﾏﾁ',
      full_lower: 'ヒタグン　アマガセマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '44204日田市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '500',
    name: '下毛郡',
    kana_name: { half_upper: 'ｼﾓｹﾞｸﾞﾝ', full_lower: 'シモゲグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '501',
    name: '下毛郡　三光村',
    kana_name: {
      half_upper: 'ｼﾓｹﾞｸﾞﾝ ｻﾝｺｳﾑﾗ',
      full_lower: 'シモゲグン　サンコウムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '44203中津市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '502',
    name: '下毛郡　本耶馬溪町',
    kana_name: {
      half_upper: 'ｼﾓｹﾞｸﾞﾝ ﾎﾝﾔﾊﾞｹｲﾏﾁ',
      full_lower: 'シモゲグン　ホンヤバケイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '44203中津市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '503',
    name: '下毛郡　耶馬溪町',
    kana_name: {
      half_upper: 'ｼﾓｹﾞｸﾞﾝ ﾔﾊﾞｹｲﾏﾁ',
      full_lower: 'シモゲグン　ヤバケイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '44203中津市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '504',
    name: '下毛郡　山国町',
    kana_name: {
      half_upper: 'ｼﾓｹﾞｸﾞﾝ ﾔﾏｸﾆﾏﾁ',
      full_lower: 'シモゲグン　ヤマクニマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-01',
    remark: { start: null, end: '44203中津市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '520',
    name: '宇佐郡',
    kana_name: { half_upper: 'ｳｻｸﾞﾝ', full_lower: 'ウサグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '521',
    name: '宇佐郡　院内町',
    kana_name: {
      half_upper: 'ｳｻｸﾞﾝ ｲﾝﾅｲﾏﾁ',
      full_lower: 'ウサグン　インナイマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '44211宇佐市', other: null },
    note: null,
  },
  {
    pref_code: '44',
    city_code: '522',
    name: '宇佐郡　安心院町',
    kana_name: {
      half_upper: 'ｳｻｸﾞﾝ ｱｼﾞﾑﾏﾁ',
      full_lower: 'ウサグン　アジムマチ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '44211宇佐市', other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '201',
    name: '宮崎市',
    kana_name: { half_upper: 'ﾐﾔｻﾞｷｼ', full_lower: 'ミヤザキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '202',
    name: '都城市',
    kana_name: { half_upper: 'ﾐﾔｺﾉｼﾞﾖｳｼ', full_lower: 'ミヤコノジョウシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '203',
    name: '延岡市',
    kana_name: { half_upper: 'ﾉﾍﾞｵｶｼ', full_lower: 'ノベオカシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '204',
    name: '日南市',
    kana_name: { half_upper: 'ﾆﾁﾅﾝｼ', full_lower: 'ニチナンシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '205',
    name: '小林市',
    kana_name: { half_upper: 'ｺﾊﾞﾔｼｼ', full_lower: 'コバヤシシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '206',
    name: '日向市',
    kana_name: { half_upper: 'ﾋﾕｳｶﾞｼ', full_lower: 'ヒュウガシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '207',
    name: '串間市',
    kana_name: { half_upper: 'ｸｼﾏｼ', full_lower: 'クシマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '208',
    name: '西都市',
    kana_name: { half_upper: 'ｻｲﾄｼ', full_lower: 'サイトシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '209',
    name: 'えびの市',
    kana_name: { half_upper: 'ｴﾋﾞﾉｼ', full_lower: 'エビノシ' },
    en: null,
    start_date: '1970-12-01',
    end_date: null,
    remark: { start: 'S45/12/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '300',
    name: '宮崎郡',
    kana_name: { half_upper: 'ﾐﾔｻﾞｷｸﾞﾝ', full_lower: 'ミヤザキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-23',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '301',
    name: '宮崎郡　清武町',
    kana_name: {
      half_upper: 'ﾐﾔｻﾞｷｸﾞﾝ ｷﾖﾀｹﾁﾖｳ',
      full_lower: 'ミヤザキグン　キヨタケチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-23',
    remark: { start: null, end: '45201宮崎市', other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '302',
    name: '宮崎郡　田野町',
    kana_name: {
      half_upper: 'ﾐﾔｻﾞｷｸﾞﾝ ﾀﾉﾁﾖｳ',
      full_lower: 'ミヤザキグン　タノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '45201宮崎市', other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '303',
    name: '宮崎郡　佐土原町',
    kana_name: {
      half_upper: 'ﾐﾔｻﾞｷｸﾞﾝ ｻﾄﾞﾜﾗﾁﾖｳ',
      full_lower: 'ミヤザキグン　サドワラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '45201宮崎市', other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '320',
    name: '南那珂郡',
    kana_name: { half_upper: 'ﾐﾅﾐﾅｶｸﾞﾝ', full_lower: 'ミナミナカグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2009-03-30',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '321',
    name: '南那珂郡　北郷町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾅｶｸﾞﾝ ｷﾀｺﾞｳﾁﾖｳ',
      full_lower: 'ミナミナカグン　キタゴウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2009-03-30',
    remark: { start: null, end: '45204日南市', other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '322',
    name: '南那珂郡　南郷町',
    kana_name: {
      half_upper: 'ﾐﾅﾐﾅｶｸﾞﾝ ﾅﾝｺﾞｳﾁﾖｳ',
      full_lower: 'ミナミナカグン　ナンゴウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2009-03-30',
    remark: { start: null, end: '45204日南市', other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '340',
    name: '北諸県郡',
    kana_name: { half_upper: 'ｷﾀﾓﾛｶﾀｸﾞﾝ', full_lower: 'キタモロカタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '341',
    name: '北諸県郡　三股町',
    kana_name: {
      half_upper: 'ｷﾀﾓﾛｶﾀｸﾞﾝ ﾐﾏﾀﾁﾖｳ',
      full_lower: 'キタモロカタグン　ミマタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '342',
    name: '北諸県郡　山之口町',
    kana_name: {
      half_upper: 'ｷﾀﾓﾛｶﾀｸﾞﾝ ﾔﾏﾉｸﾁﾁﾖｳ',
      full_lower: 'キタモロカタグン　ヤマノクチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '45202都城市', other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '343',
    name: '北諸県郡　高城町',
    kana_name: {
      half_upper: 'ｷﾀﾓﾛｶﾀｸﾞﾝ ﾀｶｼﾞﾖｳﾁﾖｳ',
      full_lower: 'キタモロカタグン　タカジョウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '45202都城市', other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '344',
    name: '北諸県郡　山田町',
    kana_name: {
      half_upper: 'ｷﾀﾓﾛｶﾀｸﾞﾝ ﾔﾏﾀﾞﾁﾖｳ',
      full_lower: 'キタモロカタグン　ヤマダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '45202都城市', other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '345',
    name: '北諸県郡　高崎町',
    kana_name: {
      half_upper: 'ｷﾀﾓﾛｶﾀｸﾞﾝ ﾀｶｻﾞｷﾁﾖｳ',
      full_lower: 'キタモロカタグン　タカザキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '45202都城市', other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '360',
    name: '西諸県郡',
    kana_name: { half_upper: 'ﾆｼﾓﾛｶﾀｸﾞﾝ', full_lower: 'ニシモロカタグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '361',
    name: '西諸県郡　高原町',
    kana_name: {
      half_upper: 'ﾆｼﾓﾛｶﾀｸﾞﾝ ﾀｶﾊﾙﾁﾖｳ',
      full_lower: 'ニシモロカタグン　タカハルチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '362',
    name: '西諸県郡　野尻町',
    kana_name: {
      half_upper: 'ﾆｼﾓﾛｶﾀｸﾞﾝ ﾉｼﾞﾘﾁﾖｳ',
      full_lower: 'ニシモロカタグン　ノジリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-23',
    remark: { start: null, end: '45205小林市', other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '363',
    name: '西諸県郡　須木村',
    kana_name: {
      half_upper: 'ﾆｼﾓﾛｶﾀｸﾞﾝ ｽｷｿﾝ',
      full_lower: 'ニシモロカタグン　スキソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '45205小林市', other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '364',
    name: '西諸県郡　えびの町',
    kana_name: {
      half_upper: 'ﾆｼﾓﾛｶﾀｸﾞﾝ ｴﾋﾞﾉﾁﾖｳ',
      full_lower: 'ニシモロカタグン　エビノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-12-01',
    remark: { start: null, end: '45209えびの市', other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '380',
    name: '東諸県郡',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾓﾛｶﾀｸﾞﾝ',
      full_lower: 'ヒガシモロカタグン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '381',
    name: '東諸県郡　高岡町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾓﾛｶﾀｸﾞﾝ ﾀｶｵｶﾁﾖｳ',
      full_lower: 'ヒガシモロカタグン　タカオカチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '45201宮崎市', other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '382',
    name: '東諸県郡　国富町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾓﾛｶﾀｸﾞﾝ ｸﾆﾄﾐﾁﾖｳ',
      full_lower: 'ヒガシモロカタグン　クニトミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '383',
    name: '東諸県郡　綾町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼﾓﾛｶﾀｸﾞﾝ ｱﾔﾁﾖｳ',
      full_lower: 'ヒガシモロカタグン　アヤチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '400',
    name: '児湯郡',
    kana_name: { half_upper: 'ｺﾕｸﾞﾝ', full_lower: 'コユグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '401',
    name: '児湯郡　高鍋町',
    kana_name: {
      half_upper: 'ｺﾕｸﾞﾝ ﾀｶﾅﾍﾞﾁﾖｳ',
      full_lower: 'コユグン　タカナベチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '402',
    name: '児湯郡　新富町',
    kana_name: {
      half_upper: 'ｺﾕｸﾞﾝ ｼﾝﾄﾐﾁﾖｳ',
      full_lower: 'コユグン　シントミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '403',
    name: '児湯郡　西米良村',
    kana_name: {
      half_upper: 'ｺﾕｸﾞﾝ ﾆｼﾒﾗｿﾝ',
      full_lower: 'コユグン　ニシメラソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '404',
    name: '児湯郡　木城町',
    kana_name: {
      half_upper: 'ｺﾕｸﾞﾝ ｷｼﾞﾖｳﾁﾖｳ',
      full_lower: 'コユグン　キジョウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '405',
    name: '児湯郡　川南町',
    kana_name: {
      half_upper: 'ｺﾕｸﾞﾝ ｶﾜﾐﾅﾐﾁﾖｳ',
      full_lower: 'コユグン　カワミナミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '406',
    name: '児湯郡　都農町',
    kana_name: {
      half_upper: 'ｺﾕｸﾞﾝ ﾂﾉﾁﾖｳ',
      full_lower: 'コユグン　ツノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '420',
    name: '東臼杵郡',
    kana_name: { half_upper: 'ﾋｶﾞｼｳｽｷｸﾞﾝ', full_lower: 'ヒガシウスキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '421',
    name: '東臼杵郡　門川町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｳｽｷｸﾞﾝ ｶﾄﾞｶﾞﾜﾁﾖｳ',
      full_lower: 'ヒガシウスキグン　カドガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '422',
    name: '東臼杵郡　東郷町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｳｽｷｸﾞﾝ ﾄｳｺﾞｳﾁﾖｳ',
      full_lower: 'ヒガシウスキグン　トウゴウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-25',
    remark: { start: null, end: '45206日向市', other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '423',
    name: '東臼杵郡　南郷村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｳｽｷｸﾞﾝ ﾅﾝｺﾞｳｿﾝ',
      full_lower: 'ヒガシウスキグン　ナンゴウソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '45431東臼杵郡　美郷町', other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '424',
    name: '東臼杵郡　西郷村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｳｽｷｸﾞﾝ ｻｲｺﾞｳｿﾝ',
      full_lower: 'ヒガシウスキグン　サイゴウソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '45431東臼杵郡　美郷町', other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '425',
    name: '東臼杵郡　北郷村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｳｽｷｸﾞﾝ ｷﾀｺﾞｳｿﾝ',
      full_lower: 'ヒガシウスキグン　キタゴウソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '45431東臼杵郡　美郷町', other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '426',
    name: '東臼杵郡　北方町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｳｽｷｸﾞﾝ ｷﾀｶﾀﾁﾖｳ',
      full_lower: 'ヒガシウスキグン　キタカタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-20',
    remark: { start: null, end: '45203延岡市', other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '427',
    name: '東臼杵郡　北川町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｳｽｷｸﾞﾝ ｷﾀｶﾞﾜﾁﾖｳ',
      full_lower: 'ヒガシウスキグン　キタガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-03-31',
    remark: { start: null, end: '45203延岡市', other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '428',
    name: '東臼杵郡　北浦町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｳｽｷｸﾞﾝ ｷﾀｳﾗﾁﾖｳ',
      full_lower: 'ヒガシウスキグン　キタウラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-02-20',
    remark: { start: null, end: '45203延岡市', other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '429',
    name: '東臼杵郡　諸塚村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｳｽｷｸﾞﾝ ﾓﾛﾂｶｿﾝ',
      full_lower: 'ヒガシウスキグン　モロツカソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '430',
    name: '東臼杵郡　椎葉村',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｳｽｷｸﾞﾝ ｼｲﾊﾞｿﾝ',
      full_lower: 'ヒガシウスキグン　シイバソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '431',
    name: '東臼杵郡　美郷町',
    kana_name: {
      half_upper: 'ﾋｶﾞｼｳｽｷｸﾞﾝ ﾐｻﾄﾁﾖｳ',
      full_lower: 'ヒガシウスキグン　ミサトチョウ',
    },
    en: null,
    start_date: '2006-01-01',
    end_date: null,
    remark: { start: 'H18/01/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '440',
    name: '西臼杵郡',
    kana_name: { half_upper: 'ﾆｼｳｽｷｸﾞﾝ', full_lower: 'ニシウスキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '441',
    name: '西臼杵郡　高千穂町',
    kana_name: {
      half_upper: 'ﾆｼｳｽｷｸﾞﾝ ﾀｶﾁﾎﾁﾖｳ',
      full_lower: 'ニシウスキグン　タカチホチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '442',
    name: '西臼杵郡　日之影町',
    kana_name: {
      half_upper: 'ﾆｼｳｽｷｸﾞﾝ ﾋﾉｶｹﾞﾁﾖｳ',
      full_lower: 'ニシウスキグン　ヒノカゲチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '443',
    name: '西臼杵郡　五ヶ瀬町',
    kana_name: {
      half_upper: 'ﾆｼｳｽｷｸﾞﾝ ｺﾞｶｾﾁﾖｳ',
      full_lower: 'ニシウスキグン　ゴカセチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '45',
    city_code: '444',
    name: '西臼杵郡　上野村',
    kana_name: {
      half_upper: 'ﾆｼｳｽｷｸﾞﾝ ｳｴﾉｿﾝ',
      full_lower: 'ニシウスキグン　ウエノソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1969-04-01',
    remark: { start: null, end: '45441西臼杵郡　高千穂町', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '201',
    name: '鹿児島市',
    kana_name: { half_upper: 'ｶｺﾞｼﾏｼ', full_lower: 'カゴシマシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '202',
    name: '川内市',
    kana_name: { half_upper: 'ｾﾝﾀﾞｲｼ', full_lower: 'センダイシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-12',
    remark: { start: null, end: '46215薩摩川内市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '203',
    name: '鹿屋市',
    kana_name: { half_upper: 'ｶﾉﾔｼ', full_lower: 'カノヤシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '204',
    name: '枕崎市',
    kana_name: { half_upper: 'ﾏｸﾗｻﾞｷｼ', full_lower: 'マクラザキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '205',
    name: '串木野市',
    kana_name: { half_upper: 'ｸｼｷﾉｼ', full_lower: 'クシキノシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-11',
    remark: { start: null, end: '46219いちき串木野市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '206',
    name: '阿久根市',
    kana_name: { half_upper: 'ｱｸﾈｼ', full_lower: 'アクネシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '207',
    name: '名瀬市',
    kana_name: { half_upper: 'ﾅｾﾞｼ', full_lower: 'ナゼシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '46222奄美市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '208',
    name: '出水市',
    kana_name: { half_upper: 'ｲｽﾞﾐｼ', full_lower: 'イズミシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '209',
    name: '大口市',
    kana_name: { half_upper: 'ｵｵｸﾁｼ', full_lower: 'オオクチシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2008-11-01',
    remark: { start: null, end: '46224伊佐市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '210',
    name: '指宿市',
    kana_name: { half_upper: 'ｲﾌﾞｽｷｼ', full_lower: 'イブスキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '211',
    name: '加世田市',
    kana_name: { half_upper: 'ｶｾﾀﾞｼ', full_lower: 'カセダシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-07',
    remark: { start: null, end: '46220南さつま市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '212',
    name: '国分市',
    kana_name: { half_upper: 'ｺｸﾌﾞｼ', full_lower: 'コクブシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-07',
    remark: { start: null, end: '46218霧島市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '213',
    name: '西之表市',
    kana_name: { half_upper: 'ﾆｼﾉｵﾓﾃｼ', full_lower: 'ニシノオモテシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '214',
    name: '垂水市',
    kana_name: { half_upper: 'ﾀﾙﾐｽﾞｼ', full_lower: 'タルミズシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '215',
    name: '薩摩川内市',
    kana_name: { half_upper: 'ｻﾂﾏｾﾝﾀﾞｲｼ', full_lower: 'サツマセンダイシ' },
    en: null,
    start_date: '2004-10-12',
    end_date: null,
    remark: { start: 'H16/10/12新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '216',
    name: '日置市',
    kana_name: { half_upper: 'ﾋｵｷｼ', full_lower: 'ヒオキシ' },
    en: null,
    start_date: '2005-05-01',
    end_date: null,
    remark: { start: 'H17/05/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '217',
    name: '曽於市',
    kana_name: { half_upper: 'ｿｵｼ', full_lower: 'ソオシ' },
    en: null,
    start_date: '2005-07-01',
    end_date: null,
    remark: { start: 'H17/07/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '218',
    name: '霧島市',
    kana_name: { half_upper: 'ｷﾘｼﾏｼ', full_lower: 'キリシマシ' },
    en: null,
    start_date: '2005-11-07',
    end_date: null,
    remark: { start: 'H17/11/07新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '219',
    name: 'いちき串木野市',
    kana_name: { half_upper: 'ｲﾁｷｸｼｷﾉｼ', full_lower: 'イチキクシキノシ' },
    en: null,
    start_date: '2005-10-11',
    end_date: null,
    remark: { start: 'H17/10/11新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '220',
    name: '南さつま市',
    kana_name: { half_upper: 'ﾐﾅﾐｻﾂﾏｼ', full_lower: 'ミナミサツマシ' },
    en: null,
    start_date: '2005-11-07',
    end_date: null,
    remark: { start: 'H17/11/07新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '221',
    name: '志布志市',
    kana_name: { half_upper: 'ｼﾌﾞｼｼ', full_lower: 'シブシシ' },
    en: null,
    start_date: '2006-01-01',
    end_date: null,
    remark: { start: 'H18/01/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '222',
    name: '奄美市',
    kana_name: { half_upper: 'ｱﾏﾐｼ', full_lower: 'アマミシ' },
    en: null,
    start_date: '2006-03-20',
    end_date: null,
    remark: { start: 'H18/03/20新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '223',
    name: '南九州市',
    kana_name: {
      half_upper: 'ﾐﾅﾐｷﾕｳｼﾕｳｼ',
      full_lower: 'ミナミキュウシュウシ',
    },
    en: null,
    start_date: '2007-12-01',
    end_date: null,
    remark: { start: 'H19/12/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '224',
    name: '伊佐市',
    kana_name: { half_upper: 'ｲｻｼ', full_lower: 'イサシ' },
    en: null,
    start_date: '2008-11-01',
    end_date: null,
    remark: { start: 'H20/11/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '225',
    name: '姶良市',
    kana_name: { half_upper: 'ｱｲﾗｼ', full_lower: 'アイラシ' },
    en: null,
    start_date: '2010-03-23',
    end_date: null,
    remark: { start: 'H22/03/23新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '300',
    name: '鹿児島郡',
    kana_name: { half_upper: 'ｶｺﾞｼﾏｸﾞﾝ', full_lower: 'カゴシマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '301',
    name: '鹿児島郡　吉田町',
    kana_name: {
      half_upper: 'ｶｺﾞｼﾏｸﾞﾝ ﾖｼﾀﾞﾁﾖｳ',
      full_lower: 'カゴシマグン　ヨシダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '46201鹿児島市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '302',
    name: '鹿児島郡　桜島町',
    kana_name: {
      half_upper: 'ｶｺﾞｼﾏｸﾞﾝ ｻｸﾗｼﾞﾏﾁﾖｳ',
      full_lower: 'カゴシマグン　サクラジマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '46201鹿児島市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '303',
    name: '鹿児島郡　三島村',
    kana_name: {
      half_upper: 'ｶｺﾞｼﾏｸﾞﾝ ﾐｼﾏﾑﾗ',
      full_lower: 'カゴシマグン　ミシマムラ',
    },
    en: null,
    start_date: '1973-04-01',
    end_date: null,
    remark: { start: 'S48/04/01郡変更', end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '304',
    name: '鹿児島郡　十島村',
    kana_name: {
      half_upper: 'ｶｺﾞｼﾏｸﾞﾝ ﾄｼﾏﾑﾗ',
      full_lower: 'カゴシマグン　トシマムラ',
    },
    en: null,
    start_date: '1973-04-01',
    end_date: null,
    remark: { start: 'S48/04/01郡変更', end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '320',
    name: '揖宿郡',
    kana_name: { half_upper: 'ｲﾌﾞｽｷｸﾞﾝ', full_lower: 'イブスキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-12-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '321',
    name: '揖宿郡　喜入町',
    kana_name: {
      half_upper: 'ｲﾌﾞｽｷｸﾞﾝ ｷｲﾚﾁﾖｳ',
      full_lower: 'イブスキグン　キイレチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '46201鹿児島市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '322',
    name: '揖宿郡　山川町',
    kana_name: {
      half_upper: 'ｲﾌﾞｽｷｸﾞﾝ ﾔﾏｶﾞﾜﾁﾖｳ',
      full_lower: 'イブスキグン　ヤマガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '46210指宿市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '323',
    name: '揖宿郡　頴娃町',
    kana_name: {
      half_upper: 'ｲﾌﾞｽｷｸﾞﾝ ｴｲﾁﾖｳ',
      full_lower: 'イブスキグン　エイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-12-01',
    remark: { start: null, end: '46223南九州市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '324',
    name: '揖宿郡　開聞町',
    kana_name: {
      half_upper: 'ｲﾌﾞｽｷｸﾞﾝ ｶｲﾓﾝﾁﾖｳ',
      full_lower: 'イブスキグン　カイモンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '46210指宿市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '340',
    name: '川辺郡',
    kana_name: { half_upper: 'ｶﾜﾅﾍﾞｸﾞﾝ', full_lower: 'カワナベグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-12-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '341',
    name: '川辺郡　笠沙町',
    kana_name: {
      half_upper: 'ｶﾜﾅﾍﾞｸﾞﾝ ｶｻｻﾁﾖｳ',
      full_lower: 'カワナベグン　カササチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-07',
    remark: { start: null, end: '46220南さつま市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '342',
    name: '川辺郡　大浦町',
    kana_name: {
      half_upper: 'ｶﾜﾅﾍﾞｸﾞﾝ ｵｵｳﾗﾁﾖｳ',
      full_lower: 'カワナベグン　オオウラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-07',
    remark: { start: null, end: '46220南さつま市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '343',
    name: '川辺郡　坊津町',
    kana_name: {
      half_upper: 'ｶﾜﾅﾍﾞｸﾞﾝ ﾎﾞｳﾉﾂﾁﾖｳ',
      full_lower: 'カワナベグン　ボウノツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-07',
    remark: { start: null, end: '46220南さつま市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '344',
    name: '川辺郡　知覧町',
    kana_name: {
      half_upper: 'ｶﾜﾅﾍﾞｸﾞﾝ ﾁﾗﾝﾁﾖｳ',
      full_lower: 'カワナベグン　チランチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-12-01',
    remark: { start: null, end: '46223南九州市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '345',
    name: '川辺郡　川辺町',
    kana_name: {
      half_upper: 'ｶﾜﾅﾍﾞｸﾞﾝ ｶﾜﾅﾍﾞﾁﾖｳ',
      full_lower: 'カワナベグン　カワナベチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-12-01',
    remark: { start: null, end: '46223南九州市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '360',
    name: '日置郡',
    kana_name: { half_upper: 'ﾋｵｷｸﾞﾝ', full_lower: 'ヒオキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-07',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '361',
    name: '日置郡　市来町',
    kana_name: {
      half_upper: 'ﾋｵｷｸﾞﾝ ｲﾁｷﾁﾖｳ',
      full_lower: 'ヒオキグン　イチキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-11',
    remark: { start: null, end: '46219いちき串木野市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '362',
    name: '日置郡　東市来町',
    kana_name: {
      half_upper: 'ﾋｵｷｸﾞﾝ ﾋｶﾞｼｲﾁｷﾁﾖｳ',
      full_lower: 'ヒオキグン　ヒガシイチキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-05-01',
    remark: { start: null, end: '46216日置市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '363',
    name: '日置郡　伊集院町',
    kana_name: {
      half_upper: 'ﾋｵｷｸﾞﾝ ｲｼﾞﾕｳｲﾝﾁﾖｳ',
      full_lower: 'ヒオキグン　イジュウインチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-05-01',
    remark: { start: null, end: '46216日置市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '364',
    name: '日置郡　松元町',
    kana_name: {
      half_upper: 'ﾋｵｷｸﾞﾝ ﾏﾂﾓﾄﾁﾖｳ',
      full_lower: 'ヒオキグン　マツモトチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '46201鹿児島市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '365',
    name: '日置郡　郡山町',
    kana_name: {
      half_upper: 'ﾋｵｷｸﾞﾝ ｺｵﾘﾔﾏﾁﾖｳ',
      full_lower: 'ヒオキグン　コオリヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-11-01',
    remark: { start: null, end: '46201鹿児島市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '366',
    name: '日置郡　日吉町',
    kana_name: {
      half_upper: 'ﾋｵｷｸﾞﾝ ﾋﾖｼﾁﾖｳ',
      full_lower: 'ヒオキグン　ヒヨシチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-05-01',
    remark: { start: null, end: '46216日置市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '367',
    name: '日置郡　吹上町',
    kana_name: {
      half_upper: 'ﾋｵｷｸﾞﾝ ﾌｷｱｹﾞﾁﾖｳ',
      full_lower: 'ヒオキグン　フキアゲチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-05-01',
    remark: { start: null, end: '46216日置市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '368',
    name: '日置郡　金峰町',
    kana_name: {
      half_upper: 'ﾋｵｷｸﾞﾝ ｷﾝﾎﾟｳﾁﾖｳ',
      full_lower: 'ヒオキグン　キンポウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-07',
    remark: { start: null, end: '46220南さつま市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '380',
    name: '薩摩郡',
    kana_name: { half_upper: 'ｻﾂﾏｸﾞﾝ', full_lower: 'サツマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '381',
    name: '薩摩郡　樋脇町',
    kana_name: {
      half_upper: 'ｻﾂﾏｸﾞﾝ ﾋﾜｷﾁﾖｳ',
      full_lower: 'サツマグン　ヒワキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-12',
    remark: { start: null, end: '46215薩摩川内市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '382',
    name: '薩摩郡　入来町',
    kana_name: {
      half_upper: 'ｻﾂﾏｸﾞﾝ ｲﾘｷﾁﾖｳ',
      full_lower: 'サツマグン　イリキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-12',
    remark: { start: null, end: '46215薩摩川内市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '383',
    name: '薩摩郡　東郷町',
    kana_name: {
      half_upper: 'ｻﾂﾏｸﾞﾝ ﾄｳｺﾞｳﾁﾖｳ',
      full_lower: 'サツマグン　トウゴウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-12',
    remark: { start: null, end: '46215薩摩川内市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '384',
    name: '薩摩郡　宮之城町',
    kana_name: {
      half_upper: 'ｻﾂﾏｸﾞﾝ ﾐﾔﾉｼﾞﾖｳﾁﾖｳ',
      full_lower: 'サツマグン　ミヤノジョウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '46392薩摩郡　さつま町', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '385',
    name: '薩摩郡　鶴田町',
    kana_name: {
      half_upper: 'ｻﾂﾏｸﾞﾝ ﾂﾙﾀﾞﾁﾖｳ',
      full_lower: 'サツマグン　ツルダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '46392薩摩郡　さつま町', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '386',
    name: '薩摩郡　薩摩町',
    kana_name: {
      half_upper: 'ｻﾂﾏｸﾞﾝ ｻﾂﾏﾁﾖｳ',
      full_lower: 'サツマグン　サツマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '46392薩摩郡　さつま町', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '387',
    name: '薩摩郡　祁答院町',
    kana_name: {
      half_upper: 'ｻﾂﾏｸﾞﾝ ｹﾄﾞｳｲﾝﾁﾖｳ',
      full_lower: 'サツマグン　ケドウインチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-12',
    remark: { start: null, end: '46215薩摩川内市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '388',
    name: '薩摩郡　里村',
    kana_name: {
      half_upper: 'ｻﾂﾏｸﾞﾝ ｻﾄﾑﾗ',
      full_lower: 'サツマグン　サトムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-12',
    remark: { start: null, end: '46215薩摩川内市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '389',
    name: '薩摩郡　上甑村',
    kana_name: {
      half_upper: 'ｻﾂﾏｸﾞﾝ ｶﾐｺｼｷﾑﾗ',
      full_lower: 'サツマグン　カミコシキムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-12',
    remark: { start: null, end: '46215薩摩川内市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '390',
    name: '薩摩郡　下甑村',
    kana_name: {
      half_upper: 'ｻﾂﾏｸﾞﾝ ｼﾓｺｼｷｿﾝ',
      full_lower: 'サツマグン　シモコシキソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-12',
    remark: { start: null, end: '46215薩摩川内市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '391',
    name: '薩摩郡　鹿島村',
    kana_name: {
      half_upper: 'ｻﾂﾏｸﾞﾝ ｶｼﾏﾑﾗ',
      full_lower: 'サツマグン　カシマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2004-10-12',
    remark: { start: null, end: '46215薩摩川内市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '392',
    name: '薩摩郡　さつま町',
    kana_name: {
      half_upper: 'ｻﾂﾏｸﾞﾝ ｻﾂﾏﾁﾖｳ',
      full_lower: 'サツマグン　サツマチョウ',
    },
    en: null,
    start_date: '2005-03-22',
    end_date: null,
    remark: { start: 'H17/03/22新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '400',
    name: '出水郡',
    kana_name: { half_upper: 'ｲｽﾞﾐｸﾞﾝ', full_lower: 'イズミグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '401',
    name: '出水郡　野田町',
    kana_name: {
      half_upper: 'ｲｽﾞﾐｸﾞﾝ ﾉﾀﾞﾁﾖｳ',
      full_lower: 'イズミグン　ノダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-13',
    remark: { start: null, end: '46208出水市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '402',
    name: '出水郡　高尾野町',
    kana_name: {
      half_upper: 'ｲｽﾞﾐｸﾞﾝ ﾀｶｵﾉﾁﾖｳ',
      full_lower: 'イズミグン　タカオノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-13',
    remark: { start: null, end: '46208出水市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '403',
    name: '出水郡　東町',
    kana_name: {
      half_upper: 'ｲｽﾞﾐｸﾞﾝ ｱｽﾞﾏﾁﾖｳ',
      full_lower: 'イズミグン　アズマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '46404出水郡　長島町', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '404',
    name: '出水郡　長島町',
    kana_name: {
      half_upper: 'ｲｽﾞﾐｸﾞﾝ ﾅｶﾞｼﾏﾁﾖｳ',
      full_lower: 'イズミグン　ナガシマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '420',
    name: '伊佐郡',
    kana_name: { half_upper: 'ｲｻｸﾞﾝ', full_lower: 'イサグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2008-11-01',
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '421',
    name: '伊佐郡　菱刈町',
    kana_name: {
      half_upper: 'ｲｻｸﾞﾝ ﾋｼｶﾘﾁﾖｳ',
      full_lower: 'イサグン　ヒシカリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2008-11-01',
    remark: { start: null, end: '46224伊佐市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '440',
    name: '姶良郡',
    kana_name: { half_upper: 'ｱｲﾗｸﾞﾝ', full_lower: 'アイラグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '441',
    name: '姶良郡　加治木町',
    kana_name: {
      half_upper: 'ｱｲﾗｸﾞﾝ ｶｼﾞｷﾁﾖｳ',
      full_lower: 'アイラグン　カジキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-23',
    remark: { start: null, end: '46225姶良市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '442',
    name: '姶良郡　姶良町',
    kana_name: {
      half_upper: 'ｱｲﾗｸﾞﾝ ｱｲﾗﾁﾖｳ',
      full_lower: 'アイラグン　アイラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-23',
    remark: { start: null, end: '46225姶良市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '443',
    name: '姶良郡　蒲生町',
    kana_name: {
      half_upper: 'ｱｲﾗｸﾞﾝ ｶﾓｳﾁﾖｳ',
      full_lower: 'アイラグン　カモウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2010-03-23',
    remark: { start: null, end: '46225姶良市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '444',
    name: '姶良郡　溝辺町',
    kana_name: {
      half_upper: 'ｱｲﾗｸﾞﾝ ﾐｿﾞﾍﾞﾁﾖｳ',
      full_lower: 'アイラグン　ミゾベチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-07',
    remark: { start: null, end: '46218霧島市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '445',
    name: '姶良郡　横川町',
    kana_name: {
      half_upper: 'ｱｲﾗｸﾞﾝ ﾖｺｶﾞﾜﾁﾖｳ',
      full_lower: 'アイラグン　ヨコガワチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-07',
    remark: { start: null, end: '46218霧島市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '446',
    name: '姶良郡　栗野町',
    kana_name: {
      half_upper: 'ｱｲﾗｸﾞﾝ ｸﾘﾉﾁﾖｳ',
      full_lower: 'アイラグン　クリノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '46452姶良郡　湧水町', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '447',
    name: '姶良郡　吉松町',
    kana_name: {
      half_upper: 'ｱｲﾗｸﾞﾝ ﾖｼﾏﾂﾁﾖｳ',
      full_lower: 'アイラグン　ヨシマツチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '46452姶良郡　湧水町', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '448',
    name: '姶良郡　牧園町',
    kana_name: {
      half_upper: 'ｱｲﾗｸﾞﾝ ﾏｷｿﾞﾉﾁﾖｳ',
      full_lower: 'アイラグン　マキゾノチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-07',
    remark: { start: null, end: '46218霧島市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '449',
    name: '姶良郡　霧島町',
    kana_name: {
      half_upper: 'ｱｲﾗｸﾞﾝ ｷﾘｼﾏﾁﾖｳ',
      full_lower: 'アイラグン　キリシマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-07',
    remark: { start: null, end: '46218霧島市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '450',
    name: '姶良郡　隼人町',
    kana_name: {
      half_upper: 'ｱｲﾗｸﾞﾝ ﾊﾔﾄﾁﾖｳ',
      full_lower: 'アイラグン　ハヤトチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-07',
    remark: { start: null, end: '46218霧島市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '451',
    name: '姶良郡　福山町',
    kana_name: {
      half_upper: 'ｱｲﾗｸﾞﾝ ﾌｸﾔﾏﾁﾖｳ',
      full_lower: 'アイラグン　フクヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-11-07',
    remark: { start: null, end: '46218霧島市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '452',
    name: '姶良郡　湧水町',
    kana_name: {
      half_upper: 'ｱｲﾗｸﾞﾝ ﾕｳｽｲﾁﾖｳ',
      full_lower: 'アイラグン　ユウスイチョウ',
    },
    en: null,
    start_date: '2005-03-22',
    end_date: null,
    remark: { start: 'H17/03/22新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '460',
    name: '曽於郡',
    kana_name: { half_upper: 'ｿｵｸﾞﾝ', full_lower: 'ソオグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '461',
    name: '曽於郡　大隅町',
    kana_name: {
      half_upper: 'ｿｵｸﾞﾝ ｵｵｽﾐﾁﾖｳ',
      full_lower: 'ソオグン　オオスミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-07-01',
    remark: { start: null, end: '46217曽於市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '462',
    name: '曽於郡　輝北町',
    kana_name: {
      half_upper: 'ｿｵｸﾞﾝ ｷﾎｸﾁﾖｳ',
      full_lower: 'ソオグン　キホクチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '46203鹿屋市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '463',
    name: '曽於郡　財部町',
    kana_name: {
      half_upper: 'ｿｵｸﾞﾝ ﾀｶﾗﾍﾞﾁﾖｳ',
      full_lower: 'ソオグン　タカラベチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-07-01',
    remark: { start: null, end: '46217曽於市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '464',
    name: '曽於郡　末吉町',
    kana_name: {
      half_upper: 'ｿｵｸﾞﾝ ｽｴﾖｼﾁﾖｳ',
      full_lower: 'ソオグン　スエヨシチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-07-01',
    remark: { start: null, end: '46217曽於市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '465',
    name: '曽於郡　松山町',
    kana_name: {
      half_upper: 'ｿｵｸﾞﾝ ﾏﾂﾔﾏﾁﾖｳ',
      full_lower: 'ソオグン　マツヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '46221志布志市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '466',
    name: '曽於郡　志布志町',
    kana_name: {
      half_upper: 'ｿｵｸﾞﾝ ｼﾌﾞｼﾁﾖｳ',
      full_lower: 'ソオグン　シブシチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '46221志布志市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '467',
    name: '曽於郡　有明町',
    kana_name: {
      half_upper: 'ｿｵｸﾞﾝ ｱﾘｱｹﾁﾖｳ',
      full_lower: 'ソオグン　アリアケチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '46221志布志市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '468',
    name: '曽於郡　大崎町',
    kana_name: {
      half_upper: 'ｿｵｸﾞﾝ ｵｵｻｷﾁﾖｳ',
      full_lower: 'ソオグン　オオサキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '480',
    name: '肝属郡',
    kana_name: { half_upper: 'ｷﾓﾂｷｸﾞﾝ', full_lower: 'キモツキグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '481',
    name: '肝属郡　串良町',
    kana_name: {
      half_upper: 'ｷﾓﾂｷｸﾞﾝ ｸｼﾗﾁﾖｳ',
      full_lower: 'キモツキグン　クシラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '46203鹿屋市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '482',
    name: '肝属郡　東串良町',
    kana_name: {
      half_upper: 'ｷﾓﾂｷｸﾞﾝ ﾋｶﾞｼｸｼﾗﾁﾖｳ',
      full_lower: 'キモツキグン　ヒガシクシラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '483',
    name: '肝属郡　内之浦町',
    kana_name: {
      half_upper: 'ｷﾓﾂｷｸﾞﾝ ｳﾁﾉｳﾗﾁﾖｳ',
      full_lower: 'キモツキグン　ウチノウラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-07-01',
    remark: { start: null, end: '46492肝属郡　肝付町', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '484',
    name: '肝属郡　高山町',
    kana_name: {
      half_upper: 'ｷﾓﾂｷｸﾞﾝ ｺｳﾔﾏﾁﾖｳ',
      full_lower: 'キモツキグン　コウヤマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-07-01',
    remark: { start: null, end: '46492肝属郡　肝付町', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '485',
    name: '肝属郡　吾平町',
    kana_name: {
      half_upper: 'ｷﾓﾂｷｸﾞﾝ ｱｲﾗﾁﾖｳ',
      full_lower: 'キモツキグン　アイラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '46203鹿屋市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '486',
    name: '肝属郡　大根占町',
    kana_name: {
      half_upper: 'ｷﾓﾂｷｸﾞﾝ ｵｵﾈｼﾞﾒﾁﾖｳ',
      full_lower: 'キモツキグン　オオネジメチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '46490肝属郡　錦江町', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '487',
    name: '肝属郡　根占町',
    kana_name: {
      half_upper: 'ｷﾓﾂｷｸﾞﾝ ﾈｼﾞﾒﾁﾖｳ',
      full_lower: 'キモツキグン　ネジメチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '46491肝属郡　南大隅町', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '488',
    name: '肝属郡　田代町',
    kana_name: {
      half_upper: 'ｷﾓﾂｷｸﾞﾝ ﾀｼﾛﾁﾖｳ',
      full_lower: 'キモツキグン　タシロチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-22',
    remark: { start: null, end: '46490肝属郡　錦江町', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '489',
    name: '肝属郡　佐多町',
    kana_name: {
      half_upper: 'ｷﾓﾂｷｸﾞﾝ ｻﾀﾁﾖｳ',
      full_lower: 'キモツキグン　サタチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-03-31',
    remark: { start: null, end: '46491肝属郡　南大隅町', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '490',
    name: '肝属郡　錦江町',
    kana_name: {
      half_upper: 'ｷﾓﾂｷｸﾞﾝ ｷﾝｺｳﾁﾖｳ',
      full_lower: 'キモツキグン　キンコウチョウ',
    },
    en: null,
    start_date: '2005-03-22',
    end_date: null,
    remark: { start: 'H17/03/22新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '491',
    name: '肝属郡　南大隅町',
    kana_name: {
      half_upper: 'ｷﾓﾂｷｸﾞﾝ ﾐﾅﾐｵｵｽﾐﾁﾖｳ',
      full_lower: 'キモツキグン　ミナミオオスミチョウ',
    },
    en: null,
    start_date: '2005-03-31',
    end_date: null,
    remark: { start: 'H17/03/31新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '492',
    name: '肝属郡　肝付町',
    kana_name: {
      half_upper: 'ｷﾓﾂｷｸﾞﾝ ｷﾓﾂｷﾁﾖｳ',
      full_lower: 'キモツキグン　キモツキチョウ',
    },
    en: null,
    start_date: '2005-07-01',
    end_date: null,
    remark: { start: 'H17/07/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '500',
    name: '熊毛郡',
    kana_name: { half_upper: 'ｸﾏｹﾞｸﾞﾝ', full_lower: 'クマゲグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '501',
    name: '熊毛郡　中種子町',
    kana_name: {
      half_upper: 'ｸﾏｹﾞｸﾞﾝ ﾅｶﾀﾈﾁﾖｳ',
      full_lower: 'クマゲグン　ナカタネチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '502',
    name: '熊毛郡　南種子町',
    kana_name: {
      half_upper: 'ｸﾏｹﾞｸﾞﾝ ﾐﾅﾐﾀﾈﾁﾖｳ',
      full_lower: 'クマゲグン　ミナミタネチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '503',
    name: '熊毛郡　上屋久町',
    kana_name: {
      half_upper: 'ｸﾏｹﾞｸﾞﾝ ｶﾐﾔｸﾁﾖｳ',
      full_lower: 'クマゲグン　カミヤクチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-10-01',
    remark: { start: null, end: '46505熊毛郡　屋久島町', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '504',
    name: '熊毛郡　屋久町',
    kana_name: {
      half_upper: 'ｸﾏｹﾞｸﾞﾝ ﾔｸﾁﾖｳ',
      full_lower: 'クマゲグン　ヤクチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2007-10-01',
    remark: { start: null, end: '46505熊毛郡　屋久島町', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '505',
    name: '熊毛郡　屋久島町',
    kana_name: {
      half_upper: 'ｸﾏｹﾞｸﾞﾝ ﾔｸｼﾏﾁﾖｳ',
      full_lower: 'クマゲグン　ヤクシマチョウ',
    },
    en: null,
    start_date: '2007-10-01',
    end_date: null,
    remark: { start: 'H19/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '520',
    name: '大島郡',
    kana_name: { half_upper: 'ｵｵｼﾏｸﾞﾝ', full_lower: 'オオシマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '521',
    name: '大島郡　三島村',
    kana_name: {
      half_upper: 'ｵｵｼﾏｸﾞﾝ ﾐｼﾏﾑﾗ',
      full_lower: 'オオシマグン　ミシマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1973-04-01',
    remark: { start: null, end: '46303鹿児島郡　三島村', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '522',
    name: '大島郡　十島村',
    kana_name: {
      half_upper: 'ｵｵｼﾏｸﾞﾝ ﾄｼﾏﾑﾗ',
      full_lower: 'オオシマグン　トシマムラ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1973-04-01',
    remark: { start: null, end: '46304鹿児島郡　十島村', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '523',
    name: '大島郡　大和村',
    kana_name: {
      half_upper: 'ｵｵｼﾏｸﾞﾝ ﾔﾏﾄｿﾝ',
      full_lower: 'オオシマグン　ヤマトソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '524',
    name: '大島郡　宇検村',
    kana_name: {
      half_upper: 'ｵｵｼﾏｸﾞﾝ ｳｹﾝｿﾝ',
      full_lower: 'オオシマグン　ウケンソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '525',
    name: '大島郡　瀬戸内町',
    kana_name: {
      half_upper: 'ｵｵｼﾏｸﾞﾝ ｾﾄｳﾁﾁﾖｳ',
      full_lower: 'オオシマグン　セトウチチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '526',
    name: '大島郡　住用村',
    kana_name: {
      half_upper: 'ｵｵｼﾏｸﾞﾝ ｽﾐﾖｳｿﾝ',
      full_lower: 'オオシマグン　スミヨウソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '46222奄美市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '527',
    name: '大島郡　龍郷町',
    kana_name: {
      half_upper: 'ｵｵｼﾏｸﾞﾝ ﾀﾂｺﾞｳﾁﾖｳ',
      full_lower: 'オオシマグン　タツゴウチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '528',
    name: '大島郡　笠利町',
    kana_name: {
      half_upper: 'ｵｵｼﾏｸﾞﾝ ｶｻﾘﾁﾖｳ',
      full_lower: 'オオシマグン　カサリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-03-20',
    remark: { start: null, end: '46222奄美市', other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '529',
    name: '大島郡　喜界町',
    kana_name: {
      half_upper: 'ｵｵｼﾏｸﾞﾝ ｷｶｲﾁﾖｳ',
      full_lower: 'オオシマグン　キカイチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '530',
    name: '大島郡　徳之島町',
    kana_name: {
      half_upper: 'ｵｵｼﾏｸﾞﾝ ﾄｸﾉｼﾏﾁﾖｳ',
      full_lower: 'オオシマグン　トクノシマチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '531',
    name: '大島郡　天城町',
    kana_name: {
      half_upper: 'ｵｵｼﾏｸﾞﾝ ｱﾏｷﾞﾁﾖｳ',
      full_lower: 'オオシマグン　アマギチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '532',
    name: '大島郡　伊仙町',
    kana_name: {
      half_upper: 'ｵｵｼﾏｸﾞﾝ ｲｾﾝﾁﾖｳ',
      full_lower: 'オオシマグン　イセンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '533',
    name: '大島郡　和泊町',
    kana_name: {
      half_upper: 'ｵｵｼﾏｸﾞﾝ ﾜﾄﾞﾏﾘﾁﾖｳ',
      full_lower: 'オオシマグン　ワドマリチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '534',
    name: '大島郡　知名町',
    kana_name: {
      half_upper: 'ｵｵｼﾏｸﾞﾝ ﾁﾅﾁﾖｳ',
      full_lower: 'オオシマグン　チナチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '46',
    city_code: '535',
    name: '大島郡　与論町',
    kana_name: {
      half_upper: 'ｵｵｼﾏｸﾞﾝ ﾖﾛﾝﾁﾖｳ',
      full_lower: 'オオシマグン　ヨロンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '201',
    name: '那覇市',
    kana_name: { half_upper: 'ﾅﾊｼ', full_lower: 'ナハシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '202',
    name: '石川市',
    kana_name: { half_upper: 'ｲｼｶﾜｼ', full_lower: 'イシカワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '47213うるま市', other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '203',
    name: '具志川市',
    kana_name: { half_upper: 'ｸﾞｼｶﾜｼ', full_lower: 'グシカワシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '47213うるま市', other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '204',
    name: 'コザ市',
    kana_name: { half_upper: 'ｺｻﾞｼ', full_lower: 'コザシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '1974-04-01',
    remark: { start: null, end: '47211沖縄市', other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '205',
    name: '宜野湾市',
    kana_name: { half_upper: 'ｷﾞﾉﾜﾝｼ', full_lower: 'ギノワンシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '206',
    name: '平良市',
    kana_name: { half_upper: 'ﾋﾗﾗｼ', full_lower: 'ヒララシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '47214宮古島市', other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '207',
    name: '石垣市',
    kana_name: { half_upper: 'ｲｼｶﾞｷｼ', full_lower: 'イシガキシ' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '208',
    name: '浦添市',
    kana_name: { half_upper: 'ｳﾗｿｴｼ', full_lower: 'ウラソエシ' },
    en: null,
    start_date: '1970-07-01',
    end_date: null,
    remark: { start: 'S45/07/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '209',
    name: '名護市',
    kana_name: { half_upper: 'ﾅｺﾞｼ', full_lower: 'ナゴシ' },
    en: null,
    start_date: '1970-08-01',
    end_date: null,
    remark: { start: 'S45/08/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '210',
    name: '糸満市',
    kana_name: { half_upper: 'ｲﾄﾏﾝｼ', full_lower: 'イトマンシ' },
    en: null,
    start_date: '1971-12-01',
    end_date: null,
    remark: { start: 'S46/12/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '211',
    name: '沖縄市',
    kana_name: { half_upper: 'ｵｷﾅﾜｼ', full_lower: 'オキナワシ' },
    en: null,
    start_date: '1974-04-01',
    end_date: null,
    remark: { start: 'S49/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '212',
    name: '豊見城市',
    kana_name: { half_upper: 'ﾄﾐｸﾞｽｸｼ', full_lower: 'トミグスクシ' },
    en: null,
    start_date: '2002-04-01',
    end_date: null,
    remark: { start: 'H14/04/01市制', end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '213',
    name: 'うるま市',
    kana_name: { half_upper: 'ｳﾙﾏｼ', full_lower: 'ウルマシ' },
    en: null,
    start_date: '2005-04-01',
    end_date: null,
    remark: { start: 'H17/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '214',
    name: '宮古島市',
    kana_name: { half_upper: 'ﾐﾔｺｼﾞﾏｼ', full_lower: 'ミヤコジマシ' },
    en: null,
    start_date: '2005-10-01',
    end_date: null,
    remark: { start: 'H17/10/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '215',
    name: '南城市',
    kana_name: { half_upper: 'ﾅﾝｼﾞﾖｳｼ', full_lower: 'ナンジョウシ' },
    en: null,
    start_date: '2006-01-01',
    end_date: null,
    remark: { start: 'H18/01/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '300',
    name: '国頭郡',
    kana_name: { half_upper: 'ｸﾆｶﾞﾐｸﾞﾝ', full_lower: 'クニガミグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '301',
    name: '国頭郡　国頭村',
    kana_name: {
      half_upper: 'ｸﾆｶﾞﾐｸﾞﾝ ｸﾆｶﾞﾐｿﾝ',
      full_lower: 'クニガミグン　クニガミソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '302',
    name: '国頭郡　大宜味村',
    kana_name: {
      half_upper: 'ｸﾆｶﾞﾐｸﾞﾝ ｵｵｷﾞﾐｿﾝ',
      full_lower: 'クニガミグン　オオギミソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '303',
    name: '国頭郡　東村',
    kana_name: {
      half_upper: 'ｸﾆｶﾞﾐｸﾞﾝ ﾋｶﾞｼｿﾝ',
      full_lower: 'クニガミグン　ヒガシソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '304',
    name: '国頭郡　羽地村',
    kana_name: {
      half_upper: 'ｸﾆｶﾞﾐｸﾞﾝ ﾊﾈｼﾞｿﾝ',
      full_lower: 'クニガミグン　ハネジソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-08-01',
    remark: { start: null, end: '47209名護市', other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '305',
    name: '国頭郡　屋我地村',
    kana_name: {
      half_upper: 'ｸﾆｶﾞﾐｸﾞﾝ ﾔｶﾞｼﾞｿﾝ',
      full_lower: 'クニガミグン　ヤガジソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-08-01',
    remark: { start: null, end: '47209名護市', other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '306',
    name: '国頭郡　今帰仁村',
    kana_name: {
      half_upper: 'ｸﾆｶﾞﾐｸﾞﾝ ﾅｷｼﾞﾝｿﾝ',
      full_lower: 'クニガミグン　ナキジンソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '307',
    name: '国頭郡　上本部村',
    kana_name: {
      half_upper: 'ｸﾆｶﾞﾐｸﾞﾝ ｶﾐﾓﾄﾌﾞｿﾝ',
      full_lower: 'クニガミグン　カミモトブソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-11-01',
    remark: { start: null, end: '47308国頭郡　本部町', other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '308',
    name: '国頭郡　本部町',
    kana_name: {
      half_upper: 'ｸﾆｶﾞﾐｸﾞﾝ ﾓﾄﾌﾞﾁﾖｳ',
      full_lower: 'クニガミグン　モトブチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '309',
    name: '国頭郡　屋部村',
    kana_name: {
      half_upper: 'ｸﾆｶﾞﾐｸﾞﾝ ﾔﾌﾞｿﾝ',
      full_lower: 'クニガミグン　ヤブソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-08-01',
    remark: { start: null, end: '47209名護市', other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '310',
    name: '国頭郡　名護町',
    kana_name: {
      half_upper: 'ｸﾆｶﾞﾐｸﾞﾝ ﾅｺﾞﾁﾖｳ',
      full_lower: 'クニガミグン　ナゴチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-08-01',
    remark: { start: null, end: '47209名護市', other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '311',
    name: '国頭郡　恩納村',
    kana_name: {
      half_upper: 'ｸﾆｶﾞﾐｸﾞﾝ ｵﾝﾅｿﾝ',
      full_lower: 'クニガミグン　オンナソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '312',
    name: '国頭郡　久志村',
    kana_name: {
      half_upper: 'ｸﾆｶﾞﾐｸﾞﾝ ｸｼｿﾝ',
      full_lower: 'クニガミグン　クシソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-08-01',
    remark: { start: null, end: '47209名護市', other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '313',
    name: '国頭郡　宜野座村',
    kana_name: {
      half_upper: 'ｸﾆｶﾞﾐｸﾞﾝ ｷﾞﾉｻﾞｿﾝ',
      full_lower: 'クニガミグン　ギノザソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '314',
    name: '国頭郡　金武町',
    kana_name: {
      half_upper: 'ｸﾆｶﾞﾐｸﾞﾝ ｷﾝﾁﾖｳ',
      full_lower: 'クニガミグン　キンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '315',
    name: '国頭郡　伊江村',
    kana_name: {
      half_upper: 'ｸﾆｶﾞﾐｸﾞﾝ ｲｴｿﾝ',
      full_lower: 'クニガミグン　イエソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '320',
    name: '中頭郡',
    kana_name: { half_upper: 'ﾅｶｶﾞﾐｸﾞﾝ', full_lower: 'ナカガミグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '321',
    name: '中頭郡　美里村',
    kana_name: {
      half_upper: 'ﾅｶｶﾞﾐｸﾞﾝ ﾐｻﾄｿﾝ',
      full_lower: 'ナカガミグン　ミサトソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1974-04-01',
    remark: { start: null, end: '47211沖縄市', other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '322',
    name: '中頭郡　与那城町',
    kana_name: {
      half_upper: 'ﾅｶｶﾞﾐｸﾞﾝ ﾖﾅｼﾛﾁﾖｳ',
      full_lower: 'ナカガミグン　ヨナシロチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '47213うるま市', other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '323',
    name: '中頭郡　勝連町',
    kana_name: {
      half_upper: 'ﾅｶｶﾞﾐｸﾞﾝ ｶﾂﾚﾝﾁﾖｳ',
      full_lower: 'ナカガミグン　カツレンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-04-01',
    remark: { start: null, end: '47213うるま市', other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '324',
    name: '中頭郡　読谷村',
    kana_name: {
      half_upper: 'ﾅｶｶﾞﾐｸﾞﾝ ﾖﾐﾀﾝｿﾝ',
      full_lower: 'ナカガミグン　ヨミタンソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '325',
    name: '中頭郡　嘉手納町',
    kana_name: {
      half_upper: 'ﾅｶｶﾞﾐｸﾞﾝ ｶﾃﾞﾅﾁﾖｳ',
      full_lower: 'ナカガミグン　カデナチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '326',
    name: '中頭郡　北谷町',
    kana_name: {
      half_upper: 'ﾅｶｶﾞﾐｸﾞﾝ ﾁﾔﾀﾝﾁﾖｳ',
      full_lower: 'ナカガミグン　チャタンチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '327',
    name: '中頭郡　北中城村',
    kana_name: {
      half_upper: 'ﾅｶｶﾞﾐｸﾞﾝ ｷﾀﾅｶｸﾞｽｸｿﾝ',
      full_lower: 'ナカガミグン　キタナカグスクソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '328',
    name: '中頭郡　中城村',
    kana_name: {
      half_upper: 'ﾅｶｶﾞﾐｸﾞﾝ ﾅｶｸﾞｽｸｿﾝ',
      full_lower: 'ナカガミグン　ナカグスクソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '329',
    name: '中頭郡　西原町',
    kana_name: {
      half_upper: 'ﾅｶｶﾞﾐｸﾞﾝ ﾆｼﾊﾗﾁﾖｳ',
      full_lower: 'ナカガミグン　ニシハラチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '330',
    name: '中頭郡　浦添村',
    kana_name: {
      half_upper: 'ﾅｶｶﾞﾐｸﾞﾝ ｳﾗｿｴｿﾝ',
      full_lower: 'ナカガミグン　ウラソエソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1970-07-01',
    remark: { start: null, end: '47208浦添市', other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '340',
    name: '島尻郡',
    kana_name: { half_upper: 'ｼﾏｼﾞﾘｸﾞﾝ', full_lower: 'シマジリグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '341',
    name: '島尻郡　豊見城村',
    kana_name: {
      half_upper: 'ｼﾏｼﾞﾘｸﾞﾝ ﾄﾐｸﾞｽｸｿﾝ',
      full_lower: 'シマジリグン　トミグスクソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2002-04-01',
    remark: { start: null, end: '47212豊見城市', other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '342',
    name: '島尻郡　糸満町',
    kana_name: {
      half_upper: 'ｼﾏｼﾞﾘｸﾞﾝ ｲﾄﾏﾝｼ',
      full_lower: 'シマジリグン　イトマンシ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '1971-12-01',
    remark: { start: null, end: '47210糸満市', other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '343',
    name: '島尻郡　東風平町',
    kana_name: {
      half_upper: 'ｼﾏｼﾞﾘｸﾞﾝ ｺﾁﾝﾀﾞﾁﾖｳ',
      full_lower: 'シマジリグン　コチンダチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '47362島尻郡　八重瀬町', other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '344',
    name: '島尻郡　具志頭村',
    kana_name: {
      half_upper: 'ｼﾏｼﾞﾘｸﾞﾝ ｸﾞｼｶﾐｿﾝ',
      full_lower: 'シマジリグン　グシカミソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '47362島尻郡　八重瀬町', other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '345',
    name: '島尻郡　玉城村',
    kana_name: {
      half_upper: 'ｼﾏｼﾞﾘｸﾞﾝ ﾀﾏｸﾞｽｸｿﾝ',
      full_lower: 'シマジリグン　タマグスクソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '47215南城市', other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '346',
    name: '島尻郡　知念村',
    kana_name: {
      half_upper: 'ｼﾏｼﾞﾘｸﾞﾝ ﾁﾈﾝｿﾝ',
      full_lower: 'シマジリグン　チネンソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '47215南城市', other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '347',
    name: '島尻郡　佐敷町',
    kana_name: {
      half_upper: 'ｼﾏｼﾞﾘｸﾞﾝ ｻｼｷﾁﾖｳ',
      full_lower: 'シマジリグン　サシキチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '47215南城市', other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '348',
    name: '島尻郡　与那原町',
    kana_name: {
      half_upper: 'ｼﾏｼﾞﾘｸﾞﾝ ﾖﾅﾊﾞﾙﾁﾖｳ',
      full_lower: 'シマジリグン　ヨナバルチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '349',
    name: '島尻郡　大里村',
    kana_name: {
      half_upper: 'ｼﾏｼﾞﾘｸﾞﾝ ｵｵｻﾞﾄｿﾝ',
      full_lower: 'シマジリグン　オオザトソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2006-01-01',
    remark: { start: null, end: '47215南城市', other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '350',
    name: '島尻郡　南風原町',
    kana_name: {
      half_upper: 'ｼﾏｼﾞﾘｸﾞﾝ ﾊｴﾊﾞﾙﾁﾖｳ',
      full_lower: 'シマジリグン　ハエバルチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '351',
    name: '島尻郡　仲里村',
    kana_name: {
      half_upper: 'ｼﾏｼﾞﾘｸﾞﾝ ﾅｶｻﾞﾄｿﾝ',
      full_lower: 'シマジリグン　ナカザトソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2002-04-01',
    remark: { start: null, end: '47361島尻郡　久米島町', other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '352',
    name: '島尻郡　具志川村',
    kana_name: {
      half_upper: 'ｼﾏｼﾞﾘｸﾞﾝ ｸﾞｼｶﾜｿﾝ',
      full_lower: 'シマジリグン　グシカワソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2002-04-01',
    remark: { start: null, end: '47361島尻郡　久米島町', other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '353',
    name: '島尻郡　渡嘉敷村',
    kana_name: {
      half_upper: 'ｼﾏｼﾞﾘｸﾞﾝ ﾄｶｼｷｿﾝ',
      full_lower: 'シマジリグン　トカシキソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '354',
    name: '島尻郡　座間味村',
    kana_name: {
      half_upper: 'ｼﾏｼﾞﾘｸﾞﾝ ｻﾞﾏﾐｿﾝ',
      full_lower: 'シマジリグン　ザマミソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '355',
    name: '島尻郡　粟国村',
    kana_name: {
      half_upper: 'ｼﾏｼﾞﾘｸﾞﾝ ｱｸﾞﾆｿﾝ',
      full_lower: 'シマジリグン　アグニソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '356',
    name: '島尻郡　渡名喜村',
    kana_name: {
      half_upper: 'ｼﾏｼﾞﾘｸﾞﾝ ﾄﾅｷｿﾝ',
      full_lower: 'シマジリグン　トナキソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '357',
    name: '島尻郡　南大東村',
    kana_name: {
      half_upper: 'ｼﾏｼﾞﾘｸﾞﾝ ﾐﾅﾐﾀﾞｲﾄｳｿﾝ',
      full_lower: 'シマジリグン　ミナミダイトウソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '358',
    name: '島尻郡　北大東村',
    kana_name: {
      half_upper: 'ｼﾏｼﾞﾘｸﾞﾝ ｷﾀﾀﾞｲﾄｳｿﾝ',
      full_lower: 'シマジリグン　キタダイトウソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '359',
    name: '島尻郡　伊平屋村',
    kana_name: {
      half_upper: 'ｼﾏｼﾞﾘｸﾞﾝ ｲﾍﾔｿﾝ',
      full_lower: 'シマジリグン　イヘヤソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '360',
    name: '島尻郡　伊是名村',
    kana_name: {
      half_upper: 'ｼﾏｼﾞﾘｸﾞﾝ ｲｾﾞﾅｿﾝ',
      full_lower: 'シマジリグン　イゼナソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '361',
    name: '島尻郡　久米島町',
    kana_name: {
      half_upper: 'ｼﾏｼﾞﾘｸﾞﾝ ｸﾒｼﾞﾏﾁﾖｳ',
      full_lower: 'シマジリグン　クメジマチョウ',
    },
    en: null,
    start_date: '2002-04-01',
    end_date: null,
    remark: { start: 'H14/04/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '362',
    name: '島尻郡　八重瀬町',
    kana_name: {
      half_upper: 'ｼﾏｼﾞﾘｸﾞﾝ ﾔｴｾﾁﾖｳ',
      full_lower: 'シマジリグン　ヤエセチョウ',
    },
    en: null,
    start_date: '2006-01-01',
    end_date: null,
    remark: { start: 'H18/01/01新設', end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '370',
    name: '宮古郡',
    kana_name: { half_upper: 'ﾐﾔｺｸﾞﾝ', full_lower: 'ミヤコグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '371',
    name: '宮古郡　城辺町',
    kana_name: {
      half_upper: 'ﾐﾔｺｸﾞﾝ ｸﾞｽｸﾍﾞﾁﾖｳ',
      full_lower: 'ミヤコグン　グスクベチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '47214宮古島市', other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '372',
    name: '宮古郡　下地町',
    kana_name: {
      half_upper: 'ﾐﾔｺｸﾞﾝ ｼﾓｼﾞﾁﾖｳ',
      full_lower: 'ミヤコグン　シモジチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '47214宮古島市', other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '373',
    name: '宮古郡　上野村',
    kana_name: {
      half_upper: 'ﾐﾔｺｸﾞﾝ ｳｴﾉｿﾝ',
      full_lower: 'ミヤコグン　ウエノソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '47214宮古島市', other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '374',
    name: '宮古郡　伊良部町',
    kana_name: {
      half_upper: 'ﾐﾔｺｸﾞﾝ ｲﾗﾌﾞﾁﾖｳ',
      full_lower: 'ミヤコグン　イラブチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: '2005-10-01',
    remark: { start: null, end: '47214宮古島市', other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '375',
    name: '宮古郡　多良間村',
    kana_name: {
      half_upper: 'ﾐﾔｺｸﾞﾝ ﾀﾗﾏｿﾝ',
      full_lower: 'ミヤコグン　タラマソン',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '380',
    name: '八重山郡',
    kana_name: { half_upper: 'ﾔｴﾔﾏｸﾞﾝ', full_lower: 'ヤエヤマグン' },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '381',
    name: '八重山郡　竹富町',
    kana_name: {
      half_upper: 'ﾔｴﾔﾏｸﾞﾝ ﾀｹﾄﾐﾁﾖｳ',
      full_lower: 'ヤエヤマグン　タケトミチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
  {
    pref_code: '47',
    city_code: '382',
    name: '八重山郡　与那国町',
    kana_name: {
      half_upper: 'ﾔｴﾔﾏｸﾞﾝ ﾖﾅｸﾞﾆﾁﾖｳ',
      full_lower: 'ヤエヤマグン　ヨナグニチョウ',
    },
    en: null,
    start_date: '0001-01-01',
    end_date: null,
    remark: { start: null, end: null, other: null },
    note: null,
  },
]
