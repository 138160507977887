import { FC, useEffect, useState, useMemo } from 'react'
import { ColumnDef, SortingState } from '@tanstack/react-table'
import { 
  Button, 
  Text, 
  Grid, 
  GridItem,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import CustomTable from '../../../components/CustomTable'
import IndeterminateCheckbox from '../../../components/IndeterminateCheckbox'
import Empty from '../../../components/Empty'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'

import tableStore from '../../../lib/store/admin_affiliate_table'
import TableFilter, { ITableFilter } from '../../../components/TableFilter'
import { IDataResponse, serialize } from '../../../network/request'
import Gaps from '../../../components/Gaps'
import AdminAffiliateService, {
  Affiliate,
  affiliateStatusText,
} from '../../../network/services/adminAffiliate'

const AdminAffiliateTable: FC<{ total: number; overridePage?: number }> = ({
  total,
  overridePage,
}) => {
  const navigate = useNavigate()
  const { state } = useSnapshot(tableStore)
  const [currentFilters, setCurrentFilters] = useState<any>(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([])
  const [visible, setVisible] = useState(false)

  const setPage = (page: number) => tableStore.state.currentPage = page
  const setSize = (size: number) => tableStore.state.pageSize = size

  const [sorting, setSorting] = useState<SortingState>([])
  if (sorting.length > 0) 
    tableStore.state.sort = `${sorting[0].id}:${sorting[0].desc ? 'desc': 'asc'}`

  const { data: response } = useSWR<IDataResponse<Affiliate>>(
    serialize(AdminAffiliateService.getAll, {
      page: overridePage ?? state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters,
    })
  )
  const rows = response ? AdminAffiliateService.toRow(response) : []
  const lastPage = response?.meta?.last_page ? response?.meta?.last_page : 1

  const columns = useMemo<ColumnDef<Affiliate, any>[]>(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        ),
      },
      {
        id: 'id',
        accessorKey: 'id',
        header: 'ID',
      },
      {
        id: 'name',
        accessorKey: 'name',
        header: '名前',
        cell: (info) => `${info.row.original.last_name ?? ''} ${info.row.original.first_name ?? ''}`,
        enableSorting: false,
      },
      {
        id: 'status',
        accessorKey: 'status',
        header: 'アカウント状態',
        cell: (info) => (
          <Text style={{ 
            color: affiliateStatusText[info.getValue() as keyof typeof affiliateStatusText].color
          }}>
            {affiliateStatusText[info.getValue() as keyof typeof affiliateStatusText].text}
          </Text>
        ),
        enableSorting: false,
      },
      {
        id: 'email',
        accessorKey: 'email',
        header: 'メールアドレス',
        cell: (info) => info.row.original.user?.email ?? '',
      },
      {
        id: 'type',
        accessorKey: 'type',
        header: 'タイプ',
      },
      {
        id: 'total_sales',
        accessorKey: 'total_sales',
        header: '売上総額',
        cell: (info) => `￥${info.getValue()}`,
      },
      {
        id: 'total_commission',
        accessorKey: 'total_commission',
        header: '紹介料総額',
        cell: (info) => `￥${info.getValue()}`,
      },
      {
        id: 'total_completed_orders',
        accessorKey: 'total_completed_orders',
        header: '連携した注文数',
      },
    ],
    []
  )

  // content={
  //   initialValue.variants.length > 0
  //     ? initialValue.variants.map((item) => item.name).join(',')
  //     : '-'
  // }

  const filterColumns: ITableFilter[] = [
    { key: 'username', name: 'Username' },
    { key: 'user_id', name: 'User ID' },
    { key: 'role', name: 'Role' },
  ]
  filterColumns.forEach((item) => {
    if (
      tableStore.state?.filters &&
      Object.keys(tableStore.state.filters).includes(item.key)
    ) {
      item.default_value = tableStore.state.filters[item.key]
      item.default = true
    }
  })

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state.filters = { ...currentFilters }
      tableStore.state.currentPage = 1
    }
  }, [currentFilters])

  const handleMenuClick = (key: string) => {
    setVisible(true)
    // setModalType(key)
  }

  const batchDeleteRows = async () => {
    // try {
    //   for (const id of selectedRowKeys) {
    //     const { data } = await ReviewService.remove(id)
    //     if (data?.success === true) {
    //       message.success(`Delete source success`)
    //     } else {
    //       message.error(`Delete source ${id} failed`)
    //     }
    //     tableStore.state.refresh = Math.random()
    //   }
    //   setVisible(false)
    // } catch (e) {
    //   message.error({ content: e.message, className: 'message-error' })
    // }
  }

  // const ActionModal: FC = () => {
  //   return (
  //     <Modal
  //       title={`Remove ${selectedRowKeys.length} reviews`}
  //       open={visible}
  //       onOk={() => {
  //         switch(modalType){
  //           case 'delete':
  //             batchDeleteRows()
  //             break;
  //           case 'publish':

  //         }
  //       }}
  //       onCancel={() => {
  //         setVisible(false)
  //       }}
  //       okText='Delete'
  //       cancelText='Cancel'
  //     >
  //       This can't be undone
  //     </Modal>
  //   )
  // }

  return (
    <>
      {/* <ActionModal /> */}

      <Grid templateColumns='repeat(2, 1fr)' gap={2}>
        <GridItem>
          {selectedRowKeys?.length > 0 ? (
            <Menu>
              <MenuButton
              as={Button} 
              rightIcon={<Icon as={ChevronDownIcon} />}
            >
                More Actions
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => handleMenuClick('delete')}>Delete</MenuItem>
              </MenuList>
            </Menu>
          ) : (
            <></>
          )}
        </GridItem>
        <GridItem>
          <TableFilter
            dropdowns={[]}
            columns={filterColumns}
            hasDate={false}
            setCurrentFilters={setCurrentFilters}
          />
          <Gaps level={3} />
        </GridItem>
      </Grid>

      {rows?.length > 0 ? (
        <CustomTable
          {...{
            data: rows,
            columns,
            lastPage,
            page: state.currentPage,
            setPage,
            size: state.pageSize,
            selectedRowKeys,
            setSize,
            sorting,
            setSorting,
            setSelectedRowKeys,
            onClickRow: (record) => {
              if (selectedRowKeys?.length > 0) {
                if (selectedRowKeys.includes(record.id)) {
                  const filterKeys = _.filter(
                    [...selectedRowKeys],
                    function (o) {
                      return o !== record.id
                    }
                  )
                  setSelectedRowKeys([...filterKeys])
                } else {
                  setSelectedRowKeys([...selectedRowKeys, record.id])
                }
              } else {
                navigate(`${record.id}`)
              }
            }, // click row
          }}
        />
      ) : (
        <Empty description={'データが見つかりませんでした'} />
      )}
    </>
  )
}

export default AdminAffiliateTable
